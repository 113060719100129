import styled, { css } from 'styled-components'

export const Container = styled.div<{ bold?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  z-index: 100;
  label {
    ${({ bold }) =>
      bold &&
      css`
        font-weight: bold;
      `}
  }
  > div {
    margin-left: 15px;
    cursor: help;
    span {
      cursor: help;
    }
    > div {
      max-width: 700px !important;
    }
  }
  .my-tooltip .multi-line {
    text-align: left !important;
  }
`
