import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, nameEntity, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { FormSegment } from '../components/Form'
import Modal from '../../../../components/Modal'
import { subSegmentHeaders } from '../domain/headers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useUpdateDataTable } from '../../../../hooks/dataTable'

interface SegmentData {
  id: number
  name: string
  created_at: string
  updated_at: string
}

const SegmentsView: React.FC = () => {
  const { updateDataTable } = useUpdateDataTable()
  const { id } = useParams<{ id: string }>()
  const refModal = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const [segment, setSegment] = useState<SegmentData | null>(null)
  const [subSegment, setSubSegment] = useState<SegmentData | null>(null)
  const [modalCreate, setModalCreate] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [idParentCreate, setIdParentCreate] = useState<number>()
  const [currentItemUpdate, setCurrentItemUpdate] = useState<any | null>()
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadSegment(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<SegmentData>(apiList(id))
        const { data } = response
        setSegment(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a origem',
          description:
            'Houve um error ao carregar a origem, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadSegment()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)
  const [alertRemoveSubSegment, setAlertRemoveSubSegment] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handleRemoveSubSegment = useCallback(
    async (id: string | number) => {
      try {
        await api.delete(apiDelete(String(id)))
        addToast({
          type: 'success',
          title: 'Sub segmento removido com sucesso'
        })
        updateDataTable()
      } catch (err: any) {
        if (err.response.status === 403) {
          addToast({
            type: 'error',
            title: err.response.data.message
          })
          return
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover sub segmento'
        })
      } finally {
        setAlertRemoveSubSegment(false)
        setSubSegment(null)
      }
    },
    [addToast, updateDataTable]
  )

  const handlerOnClickButtonEditInCurrentRow = (currentValue: any) => {
    setCurrentItemUpdate(currentValue)
    setModalEdit(true)
  }

  const handlerOnClickButtonConfirmRemove = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Seguimento removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Seguimento não removido, pois ainda está sendo usada.'
      })
    }
  }
  const handleClickOnOpenModalCreate = (id: number) => {
    setIdParentCreate(id)
    setModalCreate(true)
  }

  const handlerOnClickButtonCancelRemove = () => {
    setAlertRemoveParent(false)
    setAlertRemoveSubSegment(false)
  }

  const handleClickOnClose = () => {
    setModalCreate(false)
    setModalEdit(false)
    // updateDataTable()
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{segment?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{segment?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{segment?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{segment?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab key={0} title="Sub segmentos">
                  <div className="portlet light">
                    <div className="portlet-title">
                      <div className="caption">Listagem</div>
                      <div className="tools">
                        <div
                          onClick={() => {
                            handleClickOnOpenModalCreate(Number(id))
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="fa fa-plus" /> Adicionar
                        </div>
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        parentId={id}
                        format={{ orderBy: 'id' }}
                        source="commercial/segments"
                        entity="Segment"
                        headers={subSegmentHeaders}
                        actions={[
                          {
                            name: 'Editar',
                            title: 'Editar',
                            element: () => {
                              return (
                                <FontAwesomeIcon
                                  style={{ marginTop: '12px' }}
                                  icon={faEdit}
                                />
                              )
                            },
                            onClick: item => {
                              handlerOnClickButtonEditInCurrentRow(item)
                            }
                          },
                          {
                            name: 'Remover',
                            title: 'Remover',
                            element: () => {
                              return (
                                <FontAwesomeIcon
                                  style={{ marginTop: '12px' }}
                                  icon={faTimes}
                                />
                              )
                            },
                            onClick: item => {
                              setSubSegment(item)
                              setAlertRemoveSubSegment(true)
                            }
                          }
                        ]}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab key={1} title="Histórico">
                  <div className="portlet light">
                    <div className="portlet-title">
                      <div className="caption">Listagem</div>
                      <div className="tools"></div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalCreate}
        pageTitle={'Adicionar'}
        Children={
          <FormSegment
            typeForm="create"
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(idParentCreate)
            }}
          />
        }
      />
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEdit}
        pageTitle={'Editar'}
        Children={
          <FormSegment
            typeForm="update"
            segment={{
              ...currentItemUpdate
            }}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(idParentCreate)
            }}
          />
        }
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${segment?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemove}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemove(Number(segment?.id))
        }
        isActive={alertRemoveParent}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${subSegment?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemove}
        onClickConfirmButton={() =>
          handleRemoveSubSegment(Number(subSegment?.id))
        }
        isActive={alertRemoveSubSegment}
      />
    </>
  )
}

export default SegmentsView
