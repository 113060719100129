import React, { useEffect, useState } from 'react'
import {
  Input,
  Select,
  Textarea
} from '../../../../../../../components/FormProvider'

import { Container } from './styles'
import { useClient } from '../../../providers/ClientProvider'

export const AdicionalInformationsTab = (): JSX.Element => {
  const { initialValues } = useClient()
  const [purchaseType, setPurchaseType] = useState<string>()

  useEffect(() => {
    if (initialValues?.client.purchase_type) {
      setPurchaseType(initialValues.client.purchase_type)
    }
  }, [initialValues])

  return (
    <Container>
      <div className="row">
        <div className="col-md-2">
          <Select
            label="Modalidade da compra"
            className="form-control"
            name="client.purchase_type"
            options={[
              { name: 'COMPRA DIRETA', value: 'COMPRA DIRETA' },
              { name: 'PORTAL', value: 'PORTAL' },
              { name: 'LICITACAO', value: 'RECORRENTE' }
            ]}
            value={purchaseType}
            onChange={e => setPurchaseType(e.target.value)}
            blank
            defaultValue={''}
          />
        </div>
        {purchaseType === 'PORTAL' && (
          <>
            <div className="form-content col-md-2">
              <Input
                label="Nome do portal"
                name="client.adicional_informations_portals.portal_name"
                className="form-control"
              />
            </div>
            <div className="form-content col-md-2">
              <Input
                label="Link de acesso do Portal"
                name="client.adicional_informations_portals.portal_link"
                className="form-control"
              />
            </div>
          </>
        )}
        <div className="form-content col-md-2">
          <Input
            style={{ textTransform: 'none' }}
            label="Login"
            name="client.adicional_informations_portals.login"
            className="form-control"
          />
        </div>
        <div className="form-content col-md-2">
          <Input
            style={{ textTransform: 'none' }}
            label="Senha"
            name="client.adicional_informations_portals.password"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-content col-md-12">
          <Textarea
            label="Informações de portais"
            name="client.adicional_informations_portals.note"
            className="form-control"
            rows={7}
          />
        </div>
      </div>
    </Container>
  )
}
