import React from 'react'
import { Table } from './Table'
import { Container } from './styles'

export const PriceCompositionTab = (): JSX.Element => {
  const table = Table()
  return <Container>{table}</Container>
}

export const labelPriceComposition = 'Formação de preço'
export const namePriceComposition = '@@tabs-price-training'
