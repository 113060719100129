type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'Order'
const nameSource = 'pedidos'
const namePageTitle = 'Separar Pedido'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Separar Requisição',
  to: '/warehouse/pickOrder',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/warehouse/pickOrder/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/warehouse/pickOrder/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Atualizar',
    to: '/warehouse/pickOrder/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export const stages = [
  { name: 'SEPARAÇÃO' },
  { name: 'CONFERÊNCIA' },
  { name: 'EXPEDIÇÃO' },
  { name: 'COTAÇÃO DE FRETE' },
  { name: 'AGUARDANDO COLETA' },
  { name: 'GERANDO NF' },
  { name: 'coleta' }
]

export const types = [
  { name: 'VENDA' },
  { name: 'MANUTENÇÃO' },
  { name: 'TROCA' }
]

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
