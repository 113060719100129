type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'Payroll'
const nameSource = 'financial/payrolls'
const namePageTitle = 'Folha de pagamentos'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Folha de pagamentos',
  to: '/financial/payrolls',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/financial/payrolls/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/financial/payrolls/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/financial/payrolls/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

const departments = [
  { name: 'ADMINISTRATIVO', value: 'AD' },
  { name: 'ALMOXARIFADO', value: 'AL' },
  { name: 'COMERCIAL', value: 'CO' },
  { name: 'COMPRAS', value: 'CP' },
  { name: 'FINANCEIRA', value: 'FI' },
  { name: 'LICITACAO', value: 'LI' },
  { name: 'LOGISTICA', value: 'LO' },
  { name: 'PRODUCAO', value: 'PR' },
  { name: 'RECURSOS HUMANOS', value: 'RH' }
]

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions,
  departments
}
