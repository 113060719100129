import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 20px !important;

  textarea {
    width: 100%;
    border: 1px solid #c2cad8;
    height: 90px;
    padding: 5px 10px;
  }

  input {
    text-align: right;

    &[name*='name'] {
      text-align: left;
    }
  }
`
