import React from 'react'
import { Tab } from './tabs/index'
import { ClientProvider } from './providers/ClientProvider'
import Container from '../../../../components/Container'
import { breadcrumbView } from '../domain/breadcrumb/view'
import { namePageTitle, nameActions } from '../domain/info'
import { TabsProvider } from '../../../../hooks/tabs'
import { tabsList } from './tabs/tabsList'
import { useParams } from 'react-router-dom'
import {
  toolsViewCreate,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'

const Viewclient = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()

  return (
    <TabsProvider>
      <ClientProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[toolsViewUpdate(id), toolsViewCreate(), toolsViewList()]}
        >
          <Tab tabList={tabsList} id={id} />
        </Container>
      </ClientProvider>
    </TabsProvider>
  )
}

export default Viewclient
