/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useCallback, useEffect, useRef } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_brazilLow from '@amcharts/amcharts4-geodata/brazilLow'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR'
import api from '../../services/api'

interface AmChartMapProps {
  data?: any[]
  initialData: any[]
  revertInitial?: boolean
  maintitle?: string
  height?: string
  tooltip?: any
  exportOptions?: {
    url: string
    fieldData: string
    nameFile: string
    searchParams: SearchParams
  }
}

const AmChartMap: React.FC<AmChartMapProps> = ({
  data,
  initialData,
  revertInitial,
  maintitle,
  height,
  exportOptions,
  tooltip
}) => {
  const chartRef = useRef(null)
  const polygonSeriesRef = useRef(null)
  const regionalSeries = useRef({})

  const createSeries = useCallback(heatfield => {
    const series = chartRef.current.series.push(new am4maps.MapImageSeries())
    series.dataFields.value = heatfield

    const template = series.mapImages.template
    template.verticalCenter = 'middle'
    template.horizontalCenter = 'middle'
    template.propertyFields.latitude = 'lat'
    template.propertyFields.longitude = 'long'
    template.tooltipText = tooltip?.text || '{name}\n[bold]{stores} Pedidos[/]'
    const circle = template.createChild(am4core.Circle)
    circle.radius = 20
    circle.fillOpacity = 0.7
    circle.verticalCenter = 'middle'
    circle.horizontalCenter = 'middle'
    circle.nonScaling = true
    template.events.on('hit', function (event) {
      const field = exportOptions?.fieldData || 'financial_transactions'
      if (event.target.dataItem.dataContext?.[field]) {
        const transactions = event.target.dataItem.dataContext?.[field]

        if (!transactions) return
        const [year, month, day] = new Date()
          .toISOString()
          .slice(0, 10)
          .split('-')
        const date = `${day}-${month}-${year}`
        const hashedOrders = btoa(transactions)
        api
          .post(
            exportOptions?.url ||
              'financial/financialTransactions/generateexport',
            exportOptions?.searchParams
              ? {
                  ...exportOptions?.searchParams,
                  data: hashedOrders,

                  whereParams: [
                    {
                      entity: 'Order',
                      source: 'Order',
                      field: 'delivery_destination_state',
                      valueCondition: event.target.dataItem.dataContext?.state
                    }
                  ]
                }
              : {
                  data: hashedOrders,
                  entity: 'FinancialTransactions',
                  fieldSearch: 'id',
                  source: 'FinancialTransactions',
                  header: [
                    {
                      id: 'id',
                      tableField: 'id',
                      title: 'Movimentação'
                    },
                    {
                      id: 'due_date',
                      tableField: 'due_date',
                      title: 'Vencimento',
                      type: 'date',
                      formatStyle: '%Y-%m-%d'
                    },
                    {
                      id: 'supply_name',
                      tableField: 'name',
                      title: 'Fornecedor',
                      source: 'Suppliers',
                      emptyValue: 'SEM FORNECEDOR',
                      removable: true
                    },
                    {
                      id: 'client_name',
                      tableField: 'name',
                      title: 'Cliente',
                      emptyValue: 'SEM CLIENTE',
                      source: 'ClientNew',
                      removable: true
                    },
                    {
                      id: 'value',
                      tableField: 'value',
                      title: 'Valor',
                      type: 'money',
                      symbol: 'R$',
                      precision: 2,
                      locale: 'pt_BR'
                    }
                  ],
                  innerJoin: [
                    {
                      entity: 'ClientNew',
                      source: 'ClientNew',
                      field: 'id',
                      fieldCondition: 'client_id',
                      sourceCondition: 'FinancialTransactions',
                      isNull: true
                    },
                    {
                      entity: 'Suppliers',
                      source: 'Suppliers',
                      field: 'id',
                      fieldCondition: 'supplier_id',
                      sourceCondition: 'FinancialTransactions',
                      isNull: true
                    }
                  ]
                }
          )
          .then(response => {
            const blob = new Blob(['\ufeff' + response.data], {
              type: 'text/csv;charset=utf-8"'
            })
            const url = window.URL.createObjectURL(blob)

            const a = document.createElement('a')
            a.download = `${
              exportOptions?.nameFile || 'relatorio-movimentações'
            }-${date}.csv`
            a.href = url
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
      }
    })

    const label = template.createChild(am4core.Label)
    label.text = '{stores}'
    label.fill = am4core.color('#fff')
    label.verticalCenter = 'middle'
    label.horizontalCenter = 'middle'
    label.nonScaling = true
    series.heatRules.push({
      target: circle,
      property: 'radius',
      min: 20,
      max: 30
    })
    const home = chartRef.current.chartContainer.createChild(
      am4core.ZoomOutButton
    )

    home.align = 'right'
    home.background.fill = am4core.color('#000')
    home.events.on('hit', function () {
      chartRef.current.goHome()
    })
    home.background.states.getKey('hover').properties.fill =
      am4core.color('#333333')
    return series
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setupStores = useCallback(data => {
    regionalSeries.current = {
      US: {
        markerData: [],
        series: regionalSeries.current.US.series
      }
    }

    am4core.array.each(data, function (stores: any) {
      const store = {
        state: stores.MAIL_ST_PROV_C,
        orders: stores.orders,
        long: am4core.type.toNumber(stores.LNGTD_I),
        lat: am4core.type.toNumber(stores.LATTD_I),
        location: stores.co_loc_n,
        city: stores.mail_city_n,
        valueMonetary: am4core.type.toNumber(stores.value),
        count: am4core.type.toNumber(stores.count)
      }

      if (regionalSeries.current[store.state] === undefined) {
        const statePolygon = polygonSeriesRef.current.getPolygonById(
          'BR-' + store.state
        )
        if (statePolygon) {
          regionalSeries.current[store.state] = {
            target: store.state,
            type: 'state',
            name: statePolygon.dataItem.dataContext.name,
            count: store.count,
            stores: 1,
            lat: statePolygon.visualLatitude,
            long: statePolygon.visualLongitude,
            state: store.state,
            valueMonetary: store.valueMonetary,
            orders: stores.orders,
            markerData: []
          }

          regionalSeries.current.US.markerData.push(
            regionalSeries.current[store.state]
          )
        } else {
          return
        }
      } else {
        regionalSeries.current[store.state].stores++
        regionalSeries.current[store.state].count += store.count
        regionalSeries.current[store.state].valueMonetary += store.valueMonetary
      }

      if (regionalSeries.current[store.city] === undefined) {
        regionalSeries.current[store.city] = {
          target: store.city,
          type: 'city',
          name: store.city,
          count: store.count,
          stores: 1,
          lat: store.lat,
          long: store.long,
          state: store.state,
          orders: store.orders,
          valueMonetary: store.valueMonetary,
          markerData: []
        }
        regionalSeries.current[store.state].markerData.push(
          regionalSeries.current[store.city]
        )
      } else {
        regionalSeries.current[store.city].stores++
        regionalSeries.current[store.city].count += store.count
        regionalSeries.current[store.city].valueMonetary += store.valueMonetary
      }

      regionalSeries.current[store.city].markerData.push({
        name: store.location,
        count: store.count,
        stores: 1,
        lat: store.lat,
        long: store.long,
        state: store.state,
        orders: store.orders,
        valueMonetary: store.valueMonetary
      })
    })

    regionalSeries.current.US.series.data = regionalSeries.current.US.markerData
  }, [])

  useEffect(() => {
    am4core.useTheme(am4themes_animated)
    let currentSeries
    chartRef.current = am4core.create('amchart', am4maps.MapChart)
    chartRef.current.maxZoomLevel = 64

    chartRef.current.geodata = am4geodata_brazilLow
    chartRef.current.chartContainer.wheelable = false
    chartRef.current.language.locale = am4lang_pt_BR
    chartRef.current.language.locale._decimalSeparator = ','
    chartRef.current.language.locale._thousandSeparator = '.'
    chartRef.current.numberFormatter.numberFormat = '#,###.##'

    chartRef.current.projection = new am4maps.projections.Miller()

    const zoomOut = chartRef.current.tooltipContainer.createChild(
      am4core.ZoomOutButton
    )
    zoomOut.align = 'right'
    zoomOut.valign = 'top'
    zoomOut.margin(20, 20, 20, 20)
    zoomOut.events.on('hit', function () {
      if (currentSeries) {
        currentSeries.hide()
      }
      chart.goHome()
      zoomOut.hide()
      currentSeries = regionalSeries.current.US.series
      currentSeries.show()
    })
    zoomOut.hide()
    polygonSeriesRef.current = chartRef.current.series.push(
      new am4maps.MapPolygonSeries()
    )
    polygonSeriesRef.current.useGeodata = true
    polygonSeriesRef.current.calculateVisualCenter = true

    const polygonTemplate = polygonSeriesRef.current.mapPolygons.template
    polygonTemplate.tooltipText = '{name}'
    polygonTemplate.fill = am4core.color('grey').lighten(0.55)
    regionalSeries.current.US = {
      markerData: [],
      series: createSeries('stores')
    }

    currentSeries = regionalSeries.current.US.series

    function loadStores() {
      setupStores(initialData.length ? initialData : data)
    }

    chartRef.current.events.on('ready', loadStores)
    chartRef.current.responsive.enable = true

    return () => {
      chartRef.current && chartRef.current.dispose()
    }
  }, [createSeries, data, initialData, setupStores])

  useEffect(() => {
    if (chartRef.current) {
      if (regionalSeries.current.US.markerData) {
        if (!data || data.length) {
          setupStores(initialData)
        } else {
          setupStores(data)
        }
        chartRef.current.invalidateRawData()
      }
    }
  }, [data, initialData, revertInitial, setupStores])
  return (
    <div style={{ width: '100%', height: '100%' }} className={'portlet light'}>
      {maintitle?.length > 0 && (
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">{maintitle}</span>
          </div>
        </div>
      )}
      <div
        className="amchart"
        style={{ width: '100%', height: height || '80%' }}
      ></div>
    </div>
  )
}

export { AmChartMap }
