import React, { useEffect, useState } from 'react'
import Container from '../../../../../components/Container'
import { namePageTitle, OrdersExpeditionLogs } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewList } from '../domain/tools'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import { FormSituation } from '../components/FormSituation'
import Modal from '../../../../../components/Modal'
import Tabs from '../../../../../components/Tabs'
import Tab from '../../../../../components/Tabs/Tab'
import CustomDataTable from '../../../../../components/CustomDataTable'
import {
  logsHeaders,
  productsHeaders,
  productsHeadersNoStock
} from '../domain/headers'
import moment from 'moment'
import { FaCheck, FaFileInvoice, FaPrint } from 'react-icons/fa'
import { Alert } from '../../../../../components/Alert'
import { Input, Textarea } from '../../../../../components/Form'
import { Date } from '../../../../../components/Form/date'
import { FormC } from '../components/FormC'
import { FormObs } from '../components/FormObs'
import { TooltipComponent } from '../../../../../components/TooltipComponent'
import { DATE_MASK_LOCALE_WITH_TIME } from '../../../../../common/constants'

type Status = {
  id: number
  position: number
}
export type Volume = {
  weight: string
  height: string
  length: string
  width: string
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [logs, setLogs] = useState<OrdersExpeditionLogs[]>([])
  const [statusList, setStatusList] = useState<Status[]>([])
  const [order, setOrder] = useState<any>()
  const [products, setProducts] = useState<any[]>([])
  const [modal, setModal] = useState(false)
  const [modalConference, setModalConference] = useState(false)
  const [modalNoInvoice, setModalNoInvoice] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [modalRollback, setModalRollback] = useState(false)
  const [modalObs, setModalObs] = useState(false)
  const { addToast } = useToast()

  const [status, setStatus] = useState<number>()
  const [currentStatus, setCurrentStatus] = useState<Status>()
  const [expiration, setExpiration] = useState<Date>()
  const [currentExpiration, setCurrentExpiration] = useState('')
  const [deliveryDate, setDeliveryDate] = useState<Date>()
  const [description, setDescription] = useState('')
  const [lastCollectStatus, setLastCollectStatus] = useState(0)
  const [lastSeparateStatus, setLastSeparateStatus] = useState(0)
  const [collectClicked, setCollectClicked] = useState(false)
  const [separateClicked, setSeparateClicked] = useState(false)
  const [invoiceClicked, setInvoiceClicked] = useState(false)
  const [itemClicked, setItemClicked] = useState<OrdersExpeditionLogs>()

  const [volumes, setVolumes] = useState<Volume[]>([
    {
      weight: '',
      height: '',
      length: '',
      width: ''
    }
  ])
  const [volumesErrors, setVolumesErrors] = useState<any[]>([
    {
      weight: {
        message: 'O campo é obrigatório!',
        error: false
      },
      height: {
        message: 'O campo é obrigatório!',
        error: false
      },
      length: {
        message: 'O campo é obrigatório!',
        error: false
      },
      width: {
        message: 'O campo é obrigatório!',
        error: false
      }
    }
  ])

  const { disableLoading, activeLoading } = useLoading()
  const [formError, setFormError] = useState({
    expiration: {
      message: 'O campo é obrigatório!',
      error: false
    },
    deliveryDate: {
      message: 'O campo é obrigatório!',
      error: false
    }
  })

  const loadOrderExpedition = async () => {
    activeLoading()
    try {
      const response = await api.get(apiList(id))
      const { data } = response
      setLogs(data.logs)
      setStatusList(data.statusList)
      setOrder(data.order)
      setProducts(data.products)
      const collectStatus = data?.logs?.find((l: any) => l.new_status === 4)
      const separateStatus = data?.logs?.find((l: any) => l.new_status === 1)
      setLastCollectStatus(collectStatus?.id)
      setLastSeparateStatus(separateStatus?.id)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Pedido não encontrado.'
      })
      disableLoading()
      history.goBack()
    }
  }

  useEffect(() => {
    loadOrderExpedition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmSatus = async (hasExpiration = false) => {
    let hasErro = false
    if (hasExpiration && !expiration) {
      setFormError(prev => ({
        ...prev,
        expiration: {
          ...prev.expiration,
          error: true
        }
      }))
      hasErro = true
    }
    if (currentStatus.position === 4 && !deliveryDate) {
      setFormError(prev => ({
        ...prev,
        deliveryDate: {
          ...prev.deliveryDate,
          error: true
        }
      }))
      hasErro = true
    }
    if (currentStatus.position === 2) {
      hasErro = false
      volumesErrors.forEach((volume, index) => {
        if (!volumes[index].weight) {
          setVolumesErrors(prev => {
            const copy = JSON.parse(JSON.stringify(prev))
            copy[index].weight.error = true
            return copy
          })
          hasErro = true
        }
        if (!volumes[index].width) {
          setVolumesErrors(prev => {
            const copy = JSON.parse(JSON.stringify(prev))
            copy[index].width.error = true
            return copy
          })
          hasErro = true
        }
        if (!volumes[index].length) {
          setVolumesErrors(prev => {
            const copy = JSON.parse(JSON.stringify(prev))
            copy[index].length.error = true
            return copy
          })
          hasErro = true
        }
        if (!volumes[index].height) {
          setVolumesErrors(prev => {
            const copy = JSON.parse(JSON.stringify(prev))
            copy[index].height.error = true
            return copy
          })
          hasErro = true
        }
      })
    }
    if (hasErro) {
      return
    }
    setModal(false)
    setModalConference(false)
    activeLoading()
    const url = 'warehouse/expedition/orders/situation'
    try {
      const filteredVolumes = volumes.filter(
        v => v.height && v.length && v.weight && v.width
      )
      await api.post(url, {
        order_id: Number(id),
        status,
        expiration,
        deliveryDate,
        description,
        volumes: filteredVolumes.length ? filteredVolumes : undefined
      })
      setModalConfirm(false)
      setModalSuccess(true)
    } catch (error: any) {
      const message = error?.response?.data?.message
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description:
          message ||
          'Ocorreu um erro ao atualizar o cadastro, por favor, tente novamente.'
      })
      setModal(false)
      setModalConference(false)
      setModalConfirm(false)
    }
    setExpiration(undefined)
    setDescription('')
    setVolumes([
      {
        weight: '',
        height: '',
        length: '',
        width: ''
      }
    ])
    setVolumesErrors([
      {
        weight: {
          message: 'O campo é obrigatório!',
          error: false
        },
        height: {
          message: 'O campo é obrigatório!',
          error: false
        },
        length: {
          message: 'O campo é obrigatório!',
          error: false
        },
        width: {
          message: 'O campo é obrigatório!',
          error: false
        }
      }
    ])
    disableLoading()
  }

  useEffect(() => {
    if (!order) return
    const currentStatus = statusList.find(
      sl => Number(sl.id) === Number(order.expedicao_status_id)
    )
    setCurrentStatus(currentStatus)
    const nextStatus = statusList.find(
      sl => Number(sl.position) === Number(currentStatus?.position + 1)
    )
    if (order?.delivery_date && currentStatus?.position === 5) {
      setExpiration(
        moment(order.delivery_date, 'DD/MM/YYYY HH:mm:ss')
          .add({ hours: 3 })
          .toDate()
      )
    }

    if (nextStatus?.id) {
      setStatus(nextStatus.id)
    } else if (currentStatus?.id) {
      setStatus(currentStatus.id)
    }
  }, [order, statusList])

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[toolsViewList()]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nº Pedido
                </label>
                <p>{id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Data do pedido
                </label>
                <p>
                  {order?.date
                    ? moment(order.date, 'DD/MM/YYYY HH:mm:ss')
                        .add({ hour: 3 })
                        .format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Status do pedido
                </label>
                <p>{order?.situation}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Cliente
                </label>
                <p>{order?.client?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Contato nome
                </label>
                <p>{order?.contact_name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Contato cargo
                </label>
                <p>{order?.contact_position}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Contato Telefone/Celular
                </label>
                <p>{`${order?.contact_phone_number} / ${order?.contact_cellphone_number}`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Contato email
                </label>
                <p>{order?.contact_email}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Transportadora
                </label>
                <p>{order?.carrier?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Representante
                </label>
                <p>{order?.agent?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Etapa expedição
                </label>
                <p>{order?.expedition?.name}</p>
              </div>
            </div>
            {order?.os_notes && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Observações almoxarifado
                  </label>
                  <p>{order?.os_notes}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="portlet light" style={{ paddingTop: '0px' }}>
            <div className="portlet-title">
              <div className="caption">Itens</div>
            </div>
            <CustomDataTable
              customItems={products}
              headers={
                currentStatus?.id === 8
                  ? productsHeadersNoStock
                  : productsHeaders
              }
              customHeaders={[
                {
                  name: 'Estoque',
                  field: 'hasStock',
                  sortable: false,
                  element: item => {
                    return (
                      <p>
                        {item.hasStock?.toUpperCase() === 'OK'
                          ? 'COM ESTOQUE'
                          : item.hasStock}
                      </p>
                    )
                  }
                },
                {
                  name: 'Cód.',
                  field: 'product_id',
                  sortable: false,
                  element: item => {
                    if (!item.stock_id) {
                      return <p>{item.product?.codigo}</p>
                    }
                    return <p>{item.product_id}</p>
                  }
                },
                {
                  name: 'Produto',
                  field: 'product_name',
                  sortable: false,
                  element: item => {
                    if (!item.stock_id) {
                      return (
                        <p>
                          PRODUTO NÃO LOCALIZADO -
                          <a
                            target="_blank"
                            href={`https://multfluxosistema.com.br/produtos/view/${item.product?.id}`}
                            rel="noreferrer"
                            style={{ marginLeft: '5px' }}
                          >
                            VISUALIZAR PRODUTO ANTIGO
                          </a>
                        </p>
                      )
                    }
                    return <p>{item.product_name}</p>
                  }
                }
              ]}
              noBorder
            />
          </div>
        </div>
        <Tabs>
          {[
            <Tab key={0} title="Histórico">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">Listagem</div>
                  <div className="tools">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        color: '#333'
                      }}
                    >
                      <span
                        onClick={() => {
                          setModalRollback(true)
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <span
                          className="fa fa-edit"
                          style={{ marginRight: '3px' }}
                        ></span>
                        Retroceder Etapa
                      </span>
                    </div>
                  </div>
                </div>
                <div className="portlet-body form">
                  <CustomDataTable
                    customItems={logs}
                    headers={logsHeaders}
                    customHeaders={[
                      {
                        field: 'date',
                        name: 'Data',
                        sortable: false,
                        element: item => (
                          <span>
                            {moment(item.date, DATE_MASK_LOCALE_WITH_TIME)
                              .add(3, 'h')
                              .format(DATE_MASK_LOCALE_WITH_TIME)}
                          </span>
                        )
                      },
                      {
                        field: 'expiration',
                        name: 'Status expiração',
                        sortable: false,
                        element: item => (
                          <span>{item.expiration?.substring(0, 10)}</span>
                        )
                      },
                      {
                        name: 'Etapa',
                        field: 'new.name',
                        sortable: false,
                        element: item => {
                          let style
                          const expiration = moment(
                            item.expiration,
                            'DD/MM/YYYY HH:mm:ss'
                          ).startOf('day')
                          const realizedAt = moment(
                            item.realized_at,
                            'DD/MM/YYYY HH:mm:ss'
                          ).add({ hours: 3 })
                          if (item.expiration && item.realized_at) {
                            if (realizedAt.diff(expiration, 'days') > 0) {
                              style = { color: 'red' }
                            }
                          }
                          return <span style={style}>{item?.new?.name}</span>
                        }
                      },
                      {
                        field: 'realized_at',
                        name: 'Data realizada',
                        sortable: false,
                        element: item => {
                          let style
                          const expiration = moment(
                            item.expiration,
                            'DD/MM/YYYY HH:mm:ss'
                          ).startOf('day')
                          const realizedAt = moment(
                            item.realized_at,
                            'DD/MM/YYYY HH:mm:ss'
                          ).add({ hours: 3 })
                          if (item.expiration && item.realized_at) {
                            if (realizedAt.diff(expiration, 'days') > 0) {
                              style = { color: 'red' }
                            }
                          }
                          return (
                            <span style={style}>
                              {item.realized_at
                                ? realizedAt.format('DD/MM/YYYY')
                                : ''}
                            </span>
                          )
                        }
                      },
                      {
                        field: 'actions',
                        name: 'Ações',
                        sortable: false,
                        element: item =>
                          (item.realized_at === null &&
                            currentStatus?.id !== 7) ||
                          (currentStatus?.id === 3 &&
                            item.realized_at === null) ||
                          item?.id === lastCollectStatus ||
                          item?.id === lastSeparateStatus ? (
                            <div>
                              {item.realized_at === null && (
                                <span
                                  onClick={() => {
                                    setItemClicked(item)
                                    setModalObs(true)
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '10px'
                                  }}
                                >
                                  <span className="fa fa-edit"></span>
                                </span>
                              )}
                              {currentStatus?.id === 3 &&
                                item.realized_at === null && (
                                  <>
                                    <a
                                      href={`https://multfluxosistema.com.br/orcamentos/aprovados?codigo=${order?.id}`}
                                      style={{
                                        cursor: 'pointer',
                                        marginRight: '10px'
                                      }}
                                      onClick={() => setInvoiceClicked(true)}
                                    >
                                      <FaFileInvoice
                                        color={invoiceClicked ? '#333' : 'red'}
                                      />
                                    </a>
                                  </>
                                )}
                              {item?.id === lastCollectStatus && (
                                <span>
                                  <a
                                    href={`https://multfluxosistema.com.br/notas_fiscais/imprimir_danfe/${order?.invoice_id}/empresa:1`}
                                    target="__blank"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: '10px'
                                    }}
                                    onClick={() => setCollectClicked(true)}
                                  >
                                    <FaPrint
                                      title="Imprimir nota fiscal"
                                      color={
                                        order?.nfe_printed || collectClicked
                                          ? '#333'
                                          : 'red'
                                      }
                                    />
                                  </a>
                                  {item.realized_at === null && (
                                    <a
                                      href={`https://multfluxosistema.com.br/compras_requisicoes/add?tipo=F&pedido=${order?.id}`}
                                      target="__blank"
                                      style={{
                                        cursor: 'pointer',
                                        marginRight: '10px'
                                      }}
                                      onClick={() => setInvoiceClicked(true)}
                                    >
                                      <FaFileInvoice
                                        color={invoiceClicked ? '#333' : 'red'}
                                      />
                                    </a>
                                  )}
                                </span>
                              )}
                              {item?.id === lastSeparateStatus && (
                                <span>
                                  <a
                                    href={`https://multfluxosistema.com.br/orcamentos/imprimir_ordens_servicos/${btoa(
                                      `a:1:{s:2:"id";i:${order?.id};}`
                                    )}/E`}
                                    target="__blank"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: '10px'
                                    }}
                                    onClick={() => setSeparateClicked(true)}
                                  >
                                    <FaPrint
                                      title="Imprimir OS"
                                      color={
                                        order?.os_delivey_printed ||
                                        separateClicked
                                          ? '#333'
                                          : 'red'
                                      }
                                    />
                                  </a>
                                </span>
                              )}
                              {item.realized_at === null &&
                                currentStatus?.id !== 7 &&
                                item.new.id !== 11 && (
                                  <span
                                    onClick={() => {
                                      if (
                                        currentStatus?.position + 1 === 5 ||
                                        currentStatus?.position + 1 === 6 ||
                                        currentStatus?.position + 1 === 7
                                      ) {
                                        setModal(true)
                                        setCurrentExpiration(
                                          item.expiration?.substring(0, 10)
                                        )
                                      } else if (
                                        currentStatus?.position === 2
                                      ) {
                                        setModalConference(true)
                                      } else {
                                        if (currentStatus.position === 3) {
                                          if (!order?.invoice_id) {
                                            setModalNoInvoice(true)
                                            return
                                          }
                                        }
                                        setModalConfirm(true)
                                      }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <FaCheck color="red" />
                                  </span>
                                )}
                            </div>
                          ) : (
                            <span></span>
                          )
                      }
                    ]}
                    search
                    pagination
                  />
                </div>
              </div>
            </Tab>
          ]}
        </Tabs>
      </Container>
      {modalRollback && (
        <Modal
          onClickButtonCancel={() => setModalRollback(false)}
          isOpenModal={modalRollback}
          pageTitle={'Atualização de etapa'}
          styles={{
            padding: 0,
            marginTop: 0
          }}
          sectionStyle={{
            minWidth: 'auto'
          }}
          Children={
            <FormSituation
              rollback={true}
              setModal={setModalRollback}
              loadOrderExpedition={loadOrderExpedition}
              statusList={statusList}
              currentStatus={order?.expedicao_status_id}
              id={Number(id)}
            />
          }
        />
      )}
      {modalObs && (
        <Modal
          onClickButtonCancel={() => setModalObs(false)}
          isOpenModal={modalObs}
          pageTitle={'Atualização de etapa'}
          styles={{
            padding: 0,
            marginTop: 0
          }}
          sectionStyle={{
            minWidth: 'auto'
          }}
          Children={
            <FormObs
              setModal={setModalObs}
              loadOrderExpedition={loadOrderExpedition}
              id={Number(id)}
              value={itemClicked?.description}
            />
          }
        />
      )}
      {modal && (
        <Alert
          onClickCancellButton={() => setModal(false)}
          onClickConfirmButton={() => confirmSatus(true)}
          isActive={modal}
          title={'Alterar etapa'}
          RenderComponent={() => (
            <div style={{ width: '800px' }}>
              <div className="row" style={{ marginTop: '0px' }}>
                {currentStatus?.position === 5 && (
                  <div className="col-md-5">
                    <Input
                      value={currentExpiration}
                      label="Data limite para coleta do pedido"
                      className="form-control"
                      name="current_expiration"
                      readOnly
                      controlled
                    />
                  </div>
                )}
                <div className="col-md-5">
                  {currentStatus.position + 1 === 6 && (
                    <TooltipComponent
                      label={'Data limite para entrega no cliente'}
                      message={
                        'Data que foi informado ao cliente o dia limite que ele receberá o seu pedido. Quem inclui a data limite para entrega é o setor Compras, ele se baseia na informação que está no pedido feito pelo setor Comercial.'
                      }
                    />
                  )}
                  <Date
                    label={
                      currentStatus?.position + 1 === 5
                        ? 'Data limite para coleta do pedido'
                        : currentStatus.position + 1 === 7
                        ? 'Data que foi entregue o pedido no cliente'
                        : undefined
                    }
                    className="form-control"
                    hasError={formError.expiration}
                    name="expiration"
                    onChange={date => setExpiration(date)}
                    selected={expiration}
                    minDate={
                      currentStatus?.position + 1 === 7
                        ? undefined
                        : moment().toDate()
                    }
                    controlled
                  />
                </div>
                {currentStatus?.position === 4 && (
                  <div className="col-md-5">
                    <TooltipComponent
                      label={'Data limite para entrega no cliente'}
                      message={
                        'Data que foi informado ao cliente o dia limite que ele receberá o seu pedido. Quem inclui a data limite para entrega é o setor Compras, ele se baseia na informação que está no pedido feito pelo setor Comercial.'
                      }
                    />
                    <Date
                      className="form-control"
                      hasError={formError.deliveryDate}
                      name="delivery_date"
                      onChange={date => setDeliveryDate(date)}
                      selected={deliveryDate}
                      controlled
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="form-content col-md-12">
                  <div className="form-group">
                    <Textarea
                      autoFocus
                      onFocus={e =>
                        e.target.setSelectionRange(
                          e.target.value.length,
                          e.target.value.length
                        )
                      }
                      label="Observações"
                      className="form-control"
                      name="description"
                      onChange={e => setDescription(e.target.value)}
                      value={description}
                      rows={6}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      )}
      {modalConference && (
        <Alert
          onClickCancellButton={() => setModalConference(false)}
          onClickConfirmButton={() => confirmSatus(false)}
          isActive={modalConference}
          title={'Alterar etapa'}
          RenderComponent={() => (
            <FormC
              setVolumesErrors={setVolumesErrors}
              volumesErrors={volumesErrors}
              setVolumes={setVolumes}
              volumes={volumes}
            />
          )}
        />
      )}
      {modalNoInvoice && (
        <Alert
          onClickCancellButton={() => setModalNoInvoice(false)}
          onClickConfirmButton={() => setModalNoInvoice(false)}
          isActive={modalNoInvoice}
          title={'Etapa não concluída!'}
          message="Não foi possível concluir essa etapa. Nota fiscal não encontrada!"
        />
      )}
      {modalConfirm && (
        <Alert
          onClickCancellButton={() => setModalConfirm(false)}
          onClickConfirmButton={() => confirmSatus()}
          isActive={modalConfirm}
          title={'Avançar etapa'}
          message="Tem certeza que deseja concluir essa etapa?"
        />
      )}
      {modalSuccess && (
        <Alert
          onClickCancellButton={() => {
            setModalSuccess(false)
            loadOrderExpedition()
          }}
          onlyConfirm
          onClickConfirmButton={() =>
            history.push(
              `/warehouse/expedition/orders?statusExpedition=${currentStatus.id}`
            )
          }
          isActive={modalSuccess}
          title={'Etapa concluída'}
          message="A etapa foi concluída com sucesso."
        />
      )}
    </>
  )
}
export default View
