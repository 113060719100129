import React from 'react'
import CommissionRelease from '../../pages/Commercial/CommissionRelease'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const CommissionReleaseRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/commercial/commissionRelease"
      exact
      component={CommissionRelease}
    />
  </CustomSwitch>
)
