import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle, nameActions } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FormCategory } from '../components/Form'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { ProductProvider } from '../providers/product/ProductProvider'
import { useToast } from '../../../../hooks/toast'

interface CompetitorData {
  competitor: Competitor
  competitorProducts: CompetitorProductsData[]
}

interface CompetitorDataRequest {
  competitor: CompetitorRequest
  competitorProducts: CompetitorProductsData[]
}

interface Competitor {
  id: number
  name: string
  email: string
  telephone: string
  comments: string
  active: number
}
interface CompetitorRequest {
  id: number
  name: string
  email: string
  telephone: string
  comments: string
  active: boolean
}
interface CompetitorProductsData {
  id: number
  name: string
  prices: string
}

const Update: React.FC = () => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [competitor, setCompetitor] = useState<CompetitorData>({
    competitor: {
      name: '',
      id: 0,
      active: 1,
      comments: '',
      email: '',
      telephone: ''
    },
    competitorProducts: []
  })
  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get<CompetitorDataRequest>(apiList(id))
        const { data } = response
        setCompetitor({
          competitor: {
            name: data.competitor.name,
            id: data.competitor.id,
            active: data.competitor.active ? 1 : 0,
            comments: data.competitor.comments,
            email: data.competitor.email,
            telephone: data.competitor.telephone
          },
          competitorProducts: data.competitorProducts
        })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o concorrente',
          description:
            'Houve um error ao carregar o concorrente, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }

    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <>
      <ProductProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.update.name}
          breadcrumb={breadcrumbUpdate}
          tools={[toolsUpdate]}
        >
          <div className="form body">
            <FormCategory
              valueInput={competitor.competitor}
              competitorsproducts={competitor.competitorProducts}
              typeForm={{
                idUpdate: competitor.competitor.id,
                inputValue: competitor.competitor.name
              }}
            />
          </div>
        </Container>
      </ProductProvider>
    </>
  )
}
export default Update
