import React, { HTMLAttributes } from 'react'
interface ColumnCustoSummaryProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  value: string
}

export function ColumnCustomSummary({
  label,
  value,
  ...rest
}: ColumnCustoSummaryProps) {
  return (
    <div {...rest}>
      <div className="font-sm">{label}</div>
      <div className="uppercase font-hg">{value}</div>
    </div>
  )
}
