import React from 'react'
import List from '../../pages/Financial/BankReconciliation/List'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const BankReconciliationsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/bankReconciliations" exact component={List} />
  </CustomSwitch>
)
