import styled from 'styled-components'

export const HistoryItem = styled.div`
  margin-bottom: 20px;
  > div:first-child {
    display: flex;
    justify-content: space-between;

    > div span:first-child {
      margin-right: 10px;
    }
  }
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`
