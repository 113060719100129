import React from 'react'
import TaxCfopsList from '../../pages/Taxes/Cfop/List'
import TaxCfopsCreate from '../../pages/Taxes/Cfop/Create'
import TaxCfopsUpdate from '../../pages/Taxes/Cfop/Update'
import TaxCfopsView from '../../pages/Taxes/Cfop/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const TaxCfopRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/taxes/taxCfop" exact component={TaxCfopsList} />
    <Route path="/taxes/taxCfop/create" component={TaxCfopsCreate} exact />
    <Route path="/taxes/taxCfop/view/:id" component={TaxCfopsView} exact />
    <Route path="/taxes/taxCfop/update/:id" component={TaxCfopsUpdate} exact />
  </CustomSwitch>
)
