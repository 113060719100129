import React from 'react'
import List from '../../pages/Commercial/FreightLocalizationPercentages/List'
import Update from '../../pages/Commercial/FreightLocalizationPercentages/Update'
import Create from '../../pages/Commercial/FreightLocalizationPercentages/Create'
import View from '../../pages/Commercial/FreightLocalizationPercentages/View'
import PrivateRoutes from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FreightLocalizationPercentagesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/commercial/freightLocalizationPercentages"
      exact
      component={List}
    />
    <PrivateRoutes
      path="/commercial/freightLocalizationPercentages/update/:id"
      component={Update}
      exact
    />
    <PrivateRoutes
      path="/commercial/freightLocalizationPercentages/create"
      component={Create}
      exact
    />
    <PrivateRoutes
      path="/commercial/freightLocalizationPercentages/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
