export const headers = [
  { name: 'Data', field: 'date', sortable: true, custom: true, type: 'date' },
  { name: 'Nº Pedido', field: 'id', sortable: true },
  { name: 'Cliente', field: 'clientNew.name', sortable: true },
  { name: 'Etapa', field: 'expedition.name', sortable: true },
  {
    name: 'Observação',
    field: 'expeditionLogs.0.description',
    sortable: true,
    tdStyle: { maxWidth: '250px' }
  },
  {
    name: 'Data limite',
    field: 'expedicao_status_expiracao',
    sortable: true,
    custom: true,
    type: 'date'
  },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]

export const logsHeaders = [
  {
    name: 'Data',
    field: 'date',
    sortable: true,
    custom: true
  },
  {
    name: 'Etapa',
    field: 'new.name',
    sortable: true,
    custom: true
  },
  {
    name: 'Data limite',
    field: 'expiration',
    sortable: true,
    custom: true,
    type: 'date'
  },
  {
    name: 'Data realizada',
    field: 'realized_at',
    sortable: true,
    custom: true,
    type: 'date'
  },
  {
    name: 'Observação',
    field: 'description',
    sortable: true,
    tdStyle: { maxWidth: '250px' }
  },
  { name: 'Realizado por', field: 'userRealized.name', sortable: true },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false,
    custom: true
  }
]

export const productsHeaders = [
  { name: 'Cód.', field: 'product_id', sortable: false, custom: true },
  { name: 'Produto', field: 'product_name', sortable: false, custom: true },
  { name: 'Quantidade', field: 'quantity', sortable: false }
]

export const productsHeadersNoStock = [
  { name: 'Cód.', field: 'product_id', sortable: false },
  { name: 'Produto', field: 'product_name', sortable: false },
  { name: 'Quantidade', field: 'quantity', sortable: false },
  { name: 'Estoque', field: 'hasStock', sortable: false, custom: true }
]
