import React, { createContext, useCallback, useContext, useState } from 'react'

export type TypeTabs = {
  default?: boolean
  name: string
  label: string
  Component?: any
  isEnable: boolean
}

type TypeCurrentTab = {
  key: string
}

type TypeTabsContext = {
  addTab: (newTab: TypeTabs) => void
  removeTab: (keyTab: string) => void
  tabs: TypeTabs[]
  activeTab: (keyTab: string) => void
  disableTab: (keyTab: string) => void
  changeCurrentTab: (keyTab: string) => void
  changeTabLabel: (keyTab: string, newName: string) => void
  changeCurrentTabForNext: () => void
  currentTab: TypeCurrentTab
  changeCurrentTabForPrevious: () => void
}

type TypeTabsProvider = {
  children: JSX.Element
}

const TabsContext = createContext<TypeTabsContext>({} as TypeTabsContext)

const TabsProvider = ({ children }: TypeTabsProvider): JSX.Element => {
  const [tabs, setTabs] = useState<TypeTabs[]>([])
  const [currentTab, setCurrentTab] = useState<TypeCurrentTab>({ key: '' })

  const addTab = useCallback((newTab: TypeTabs) => {
    if (newTab.default) setCurrentTab({ key: newTab.name })
    setTabs(prevState => {
      const tabCopy = [...prevState]
      tabCopy.push(newTab)
      return tabCopy
    })
  }, [])

  const removeTab = useCallback(
    (keyTab: string): void => {
      try {
        const result = tabs.filter(({ name }) => name === keyTab)
        if (!result) {
          const resultTabsWithouRemoveTab = tabs.filter(
            ({ name }) => keyTab !== name
          )
          setTabs(resultTabsWithouRemoveTab)
          return
        }
        throw new Error('No find tab with id')
      } catch (error: any) {
        console.error(error.message)
      }
    },
    [tabs]
  )

  const activeTab = useCallback(
    (keyTab: string) => {
      try {
        const result = tabs.filter(({ name }) => name === keyTab)
        if (result.length > 0) {
          setTabs(prevState => {
            const tabsCopy = [...prevState]
            const indexTab = tabsCopy.indexOf(result[0])
            tabsCopy[indexTab].isEnable = true
            return tabsCopy
          })
          return
        }
        throw new Error('No find tab with id')
      } catch (error: any) {
        console.error(error.message)
      }
    },
    [tabs]
  )

  const disableTab = useCallback(
    (keyTab: string) => {
      try {
        const result = tabs.filter(({ name }) => name === keyTab)
        if (result.length > 0) {
          setTabs(prevState => {
            const tabsCopy = [...prevState]
            const indexTab = tabsCopy.indexOf(result[0])
            tabsCopy[indexTab].isEnable = false
            return tabsCopy
          })
          return
        }
        throw new Error('No find tab with id')
      } catch (error: any) {
        console.error(error.message)
      }
    },
    [tabs]
  )

  const changeTabLabel = useCallback(
    (keyTab: string, newLabel: string) => {
      try {
        const result = tabs.filter(({ name }) => name === keyTab)
        if (result.length > 0) {
          setTabs(prevState => {
            const tabsCopy = [...prevState]
            const indexTab = tabsCopy.indexOf(result[0])
            tabsCopy[indexTab].label = newLabel
            return tabsCopy
          })
          return
        }
        throw new Error('No find tab with id')
      } catch (error: any) {
        console.error(error.message)
      }
    },
    [tabs]
  )

  const changeCurrentTab = useCallback((keyTab: string) => {
    setCurrentTab({ key: keyTab })
  }, [])

  const changeCurrentTabForNext = () => {
    if (currentTab.key) {
      const index = tabs
        .filter(({ isEnable }) => isEnable)
        .findIndex(({ name }) => name === currentTab.key)
      const nextTab = tabs
        .filter(({ isEnable }) => isEnable)
        .find((tab, nextIndex) => {
          return nextIndex === index + 1 ? tab : null
        })
      if (nextTab) {
        setCurrentTab({ key: nextTab.name })
      }
    }
  }

  const changeCurrentTabForPrevious = () => {
    if (currentTab.key) {
      const index = tabs
        .filter(({ isEnable }) => isEnable)
        .findIndex(({ name }) => name === currentTab.key)
      const nextTab = tabs
        .filter(({ isEnable }) => isEnable)
        .find((tab, nextIndex) => {
          return nextIndex === index - 1 ? tab : null
        })
      if (nextTab) {
        setCurrentTab({ key: nextTab.name })
      }
    }
  }

  return (
    <TabsContext.Provider
      value={{
        addTab,
        removeTab,
        tabs,
        activeTab,
        disableTab,
        changeCurrentTab,
        changeTabLabel,
        currentTab,
        changeCurrentTabForPrevious,
        changeCurrentTabForNext
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}

const useTabs = (): TypeTabsContext => {
  const context = useContext(TabsContext)

  if (!context) {
    throw new Error('useTabs must be used witin a TabsProvider')
  }

  return context
}

export { TabsProvider, useTabs }
