import React from 'react'
import List from '../../pages/PersonelDepartment/Tasks/List'
import PrivateRoutes from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const TasksRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/personelDepartment/tasks" exact component={List} />
  </CustomSwitch>
)
