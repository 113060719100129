import React from 'react'
import List from '../../pages/Administrative/FinancialCategory/List'
import Update from '../../pages/Administrative/FinancialCategory/Update'
import Create from '../../pages/Administrative/FinancialCategory/Create'
import View from '../../pages/Administrative/FinancialCategory/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FinancialCategoriesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/categories" exact component={List} />
    <Route path="/financial/categories/update/:id" component={Update} exact />
    <Route path="/financial/categories/create" component={Create} exact />
    <Route path="/financial/categories/view/:id" component={View} exact />
  </CustomSwitch>
)
