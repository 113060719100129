type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'FreightLocalizationPercentage'
const nameSource = 'commercial/freightLocalizationPercentages'
const namePageTitle = 'Percentual de frete por localidade'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Percentual de frete por localidade',
  to: '/commercial/freightLocalizationPercentages',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/freightLocalizationPercentages/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/freightLocalizationPercentages/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/freightLocalizationPercentages/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export type FreightLocalizationPercentageType = 'state' | 'city'
export type FreightLocalizationPercentage = {
  id: number
  parent_id?: number
  cep?: string
  type: FreightLocalizationPercentageType
  localization: string
  code: string
  percentage: string
}
const states = [
  { name: 'ACRE', uf: 'AC' },
  { name: 'ALAGOAS', uf: 'AL' },
  { name: 'AMAPÁ', uf: 'AP' },
  { name: 'AMAZONAS', uf: 'AM' },
  { name: 'BAHIA', uf: 'BA' },
  { name: 'CEARÁ', uf: 'CE' },
  { name: 'DISTRITO FEDERAL', uf: 'DF' },
  { name: 'ESPÍRITO SANTO', uf: 'ES' },
  { name: 'GOIÁS', uf: 'GO' },
  { name: 'MARANHÃO', uf: 'MA' },
  { name: 'MATO GROSSO', uf: 'MT' },
  { name: 'MATO GROSSO DO SUL', uf: 'MS' },
  { name: 'MINAS GERAIS', uf: 'MG' },
  { name: 'PARÁ', uf: 'PA' },
  { name: 'PARAÍBA', uf: 'PB' },
  { name: 'PARANÁ', uf: 'PR' },
  { name: 'PERNAMBUCO', uf: 'PE' },
  { name: 'PIAUÍ', uf: 'PI' },
  { name: 'RIO DE JANEIRO', uf: 'RJ' },
  { name: 'RIO GRANDE DO NORTE', uf: 'RN' },
  { name: 'RIO GRANDE DO SUL', uf: 'RS' },
  { name: 'RONDÔNIA', uf: 'RO' },
  { name: 'RORAIMA', uf: 'RR' },
  { name: 'SANTA CATARINA', uf: 'SC' },
  { name: 'SÃO PAULO', uf: 'SP' },
  { name: 'SERGIPE', uf: 'SE' },
  { name: 'TOCANTINS', uf: 'TO' }
]

export {
  states,
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
