import React from 'react'
import { Container } from './styles'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

type CardFooterProps = {
  subtitle?: string
  text: string
  link?: string
  linkParams?: string
  isProgress?: number
  target?: string
  color?: string
  history?: boolean
  handleExport?(): void
}

type PropsCard = {
  number: string | number
  text: string
  textFooter: string
  valueSubtitleFooter?: number
  isProgress?: number
  progressBarColor?: string
  linkParams?: any
  link?: string
  htmlLink?: string
  footerLink?: string
  footerLinkParams?: any
  icon?: IconDefinition
  handleExport?(): void
  footerItems?: CardFooterProps[]
  width: string
  height?: string
}

export const Card = ({
  number,
  text,
  textFooter,
  valueSubtitleFooter,
  isProgress,
  linkParams,
  link,
  footerLink,
  footerLinkParams,
  icon,
  footerItems,
  width,
  height,
  progressBarColor,
  handleExport,
  htmlLink
}: PropsCard): JSX.Element => {
  const { push } = useHistory()

  return (
    <Container link={!!link} className={`${width}`}>
      <div
        className="dashboard-stat2 dark wrapper"
        style={{ padding: 15, height }}
      >
        <section
          onClick={() => {
            if (link) push(link, linkParams)
            if (handleExport) handleExport()
          }}
        >
          {htmlLink ? (
            <a
              href={htmlLink}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <div className="left number">
                <h3>
                  <span data-counter="counterup">{number}</span>
                </h3>
                <p>{text}</p>
              </div>
            </a>
          ) : (
            <div className="left number" style={{ cursor: 'pointer' }}>
              <h3>
                <span data-counter="counterup">{number}</span>
              </h3>
              <p>{text}</p>
            </div>
          )}

          <div className="right">
            {icon && (
              <div className="icon">
                <FontAwesomeIcon icon={icon} />
              </div>
            )}
          </div>
        </section>
        <div
          onClick={() => {
            if (footerLink) push(footerLink, footerLinkParams)
          }}
          className="progress-info"
        >
          {isProgress !== undefined && (
            <div className="progress">
              <span
                style={{
                  width: !isProgress ? '0%' : `${isProgress}%`,
                  background: progressBarColor
                }}
                className={`progress-bar progress-bar ${
                  progressBarColor ? '' : 'black-haze'
                }`}
              ></span>
            </div>
          )}
          {!footerItems?.length && (
            <div
              className="status"
              style={{
                color: valueSubtitleFooter ? '#f36a5a' : '#AAB5BC',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div className="status-title">{textFooter}</div>
              {!isProgress ? (
                <div className="status-number">{valueSubtitleFooter}</div>
              ) : (
                <div className="status-number">{`${isProgress} %`}</div>
              )}
            </div>
          )}
          {footerItems?.length &&
            footerItems.map(footer =>
              footer?.link !== '#' ? (
                <a
                  onClick={event => {
                    if (footer?.link && footer?.history) {
                      event.preventDefault()
                      push(footer?.link)
                    }
                    if (footer.handleExport) footer.handleExport()
                  }}
                  target={footer?.target || '_blank'}
                  href={footer?.link || '#'}
                  key={Math.random()}
                  className="status"
                  style={{
                    color: footer?.color || '#AAB5BC',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  rel="noreferrer"
                >
                  <div className="status-title">{footer?.text}</div>
                  {!footer?.isProgress ? (
                    <div className="status-number">{footer?.subtitle}</div>
                  ) : (
                    <div className="status-number">{`${footer?.isProgress} %`}</div>
                  )}
                </a>
              ) : (
                <div
                  onClick={() => {
                    if (footer.handleExport) footer.handleExport()
                  }}
                  key={Math.random()}
                  className="status"
                  style={{
                    color: footer?.color || '#AAB5BC',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: footer?.handleExport ? 'pointer' : 'default'
                  }}
                >
                  <div className="status-title">{footer?.text}</div>
                  {!footer?.isProgress ? (
                    <div className="status-number">{footer?.subtitle}</div>
                  ) : (
                    <div className="status-number">{`${footer?.isProgress} %`}</div>
                  )}
                </div>
              )
            )}
        </div>
      </div>
    </Container>
  )
}
