import React from 'react'
import ConfigurationList from '../../pages/Config/Configurations/List'
import ConfigurationUpdate from '../../pages/Config/Configurations/Update'
import ConfigurationCreate from '../../pages/Config/Configurations/Create'
import ConfigurationView from '../../pages/Config/Configurations/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ConfigurationsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/configurations"
      exact
      component={ConfigurationList}
    />
    <Route
      path="/financial/configurations/create"
      exact
      component={ConfigurationCreate}
    />
    <Route
      path="/financial/configurations/view/:id"
      exact
      component={ConfigurationView}
    />
    <Route
      path="/financial/configurations/update/:id"
      exact
      component={ConfigurationUpdate}
    />
  </CustomSwitch>
)
