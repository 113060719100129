import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Financial/DefaultedOrders/List'
import View from '../../pages/Financial/DefaultedOrders/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const DefaultedOrdersRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/financial/defaultedOrders" exact component={List} />
    <PrivateRoutes
      path="/financial/defaultedOrders/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
