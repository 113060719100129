import React, { useEffect, useState } from 'react'
import { Container, FooterStyled, IconRemove } from './style'

import { useProduct } from '../../../providers/product/ProductProvider'
import { SearchComponentProduct } from '../SearchComponentProduct'
import api from '../../../../../../services/api'
import { Input, Select } from '../../../../../../components/Form'
import { genericMaskWithTwoZero } from '../../../../../../utlis/mask'
import { useLoading } from '../../../../../../hooks/loading'
import { Loading } from '../../../../../../components/Loading'

type ProductListFetch = {
  id: number
  name: string
  prices: string
  product?: {
    id: number
    name: string
  }
}
type StockListType = {
  id: number
  name: string
  price_sale: string
  min?: string
  max?: string
  promotion?: string
  product?: {
    id: number
    name: string
  }
}

type ProductListType = {
  id: number
  name: string
}
export const Table = (type: string): JSX.Element => {
  const { composition, setComposition } = useProduct()
  const [productList, setProductList] = useState<ProductListType[]>([])
  const [selected, setSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [stocksList, setStocksList] = useState<StockListType[]>([])
  useEffect(() => {
    if (stocksList.length) {
      return
    }
    setIsLoading(true)
    let url = 'warehouse/stocks?sale=true'
    if (type === 'location') {
      url = 'warehouse/stocks?location=true'
    }
    ;(async () => {
      const { data } = await api.get<ProductListFetch[]>(url)
      const stocks = data.map(a => ({
        ...a,
        price_sale: genericMaskWithTwoZero(JSON.parse(a.prices)?.price_sale)
      }))
      setStocksList(stocks)

      const products = stocks.map(s => ({
        id: s.product.id,
        name: s.product.name
      }))
      const filtered = products?.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      )

      setProductList(filtered)
      setIsLoading(false)
    })()
  }, [type])
  if (isLoading) {
    return <Loading isActive />
  }

  return (
    <Container className="table-responsive">
      <div className="table-content-relative">
        <table className="table table-bordered margin-bottom-0">
          <tbody>
            <tr>
              <th style={{ width: '600px' }}>Produto</th>
              <th style={{ width: '500px' }}>Variação</th>
              <th>
                {type === 'location' ? 'Valor de Locação' : 'Valor de venda'}
              </th>
              <th>Quantidade mínima</th>
              <th>Quantidade máxima</th>
              <th>Valor promocional</th>
              <th>Ações</th>
            </tr>
            {composition &&
              composition.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    height: '10px'
                  }}
                >
                  <td>
                    {SearchComponentProduct({
                      index,
                      composition,
                      setComposition,
                      productList,
                      selected,
                      setSelected
                    })}
                  </td>
                  <td>
                    <Select
                      name={`composition.${index}.stock_id`}
                      className="form-control"
                      options={stocksList
                        .filter(
                          a => a.product.id === composition[index].product_id
                        )
                        .map(a => ({
                          name: a.name,
                          value: a.id
                        }))
                        .sort((a, b) => {
                          if (a.name > b.name) {
                            return 1
                          }
                          if (a.name < b.name) {
                            return -1
                          }
                          return 0
                        })}
                      rules={{ required: true }}
                      onChange={({ target }) =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].stock_id = Number(target.value)
                          return copy
                        })
                      }
                      value={
                        composition[index].stock_id === 0
                          ? null
                          : composition[index].stock_id
                      }
                      controlled
                      blank
                      defaultValue={''}
                    />
                  </td>
                  <td>
                    <Input
                      name={`composition.${index}.price_sale`}
                      className="form-control"
                      type="text"
                      value={composition[index].price_sale}
                      readOnly={true}
                    />
                  </td>
                  <td>
                    <Input
                      name={`composition.${index}.min`}
                      className="form-control"
                      type="text"
                      onChange={({ target }) =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].min = Number(target.value)
                          return copy
                        })
                      }
                      value={composition[index].min}
                    />
                  </td>
                  <td>
                    <Input
                      name={`composition.${index}.max`}
                      className="form-control"
                      type="text"
                      onChange={({ target }) =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].max = Number(target.value)
                          return copy
                        })
                      }
                      value={composition[index].max}
                    />
                  </td>
                  <td>
                    <Input
                      labelError="Valor promocional"
                      name={`composition.${index}.promotion`}
                      className="form-control"
                      type="text"
                      onChange={({ target }) =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].promotion = genericMaskWithTwoZero(
                            target.value
                          )
                          return copy
                        })
                      }
                      value={composition[index].promotion}
                      rules={{ required: true }}
                    />
                  </td>
                  <td className="actions">
                    <IconRemove
                      onClick={() =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy.splice(index, 1)
                          return copy
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <hr />
      <FooterStyled>
        <a
          onClick={() =>
            setComposition(prev => {
              const copy = [...prev]
              const final = [
                ...copy,
                { id: 0, stock_id: 0, name: '', price_sale: '0', product_id: 0 }
              ]
              return final
            })
          }
          className="btn dark btn-sm sbold uppercase"
        >
          <span
            className="fa fa-plus"
            aria-hidden="true"
            style={{ marginRight: '5px' }}
          />
          produto
        </a>
      </FooterStyled>
      <div style={{ height: '200px' }}></div>
    </Container>
  )
}
