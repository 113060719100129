export const apiCreate = (): string =>
  '/warehouse/expedition/ordersStatus/create'
export const apiList = (id?: string): string =>
  !id
    ? '/warehouse/expedition/ordersStatus/'
    : `/warehouse/expedition/ordersStatus/view/${id}`
export const apiUpdate = (id: string): string =>
  `/warehouse/expedition/ordersStatus/update/${id}`
export const apiDelete = (id: string): string =>
  `/warehouse/expedition/ordersStatus/delete/${id}`
