import React from 'react'
import List from '../../pages/GoalsEmployees/List'
import GoalsEmployees from '../../pages/GoalsEmployees'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const GoalsEmployeesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/goalsEmployees" exact component={List} />
    <Route
      path="/commercial/goalsEmployees/update"
      component={GoalsEmployees}
      exact
    />
    <Route
      path="/commercial/goalsEmployees/create"
      component={GoalsEmployees}
      exact
    />
  </CustomSwitch>
)
