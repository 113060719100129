import React, { useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource, stages } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import {
  faPrint,
  faTruck,
  faSearch,
  faClipboardCheck,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons'
import { DatatableWrapper, FilterContaier, ListTitle } from './styles'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'

const PickOrderList = (): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const [productType, setProductType] = useState('')
  const [parameters, setParameters] = useState<any[]>()

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Filtro'}
      breadcrumb={breadcrumbList}
    >
      <FilterContaier>
        <div>
          <div className="col-md-3">
            <label htmlFor="product-type">Data inicial</label>
            <input type="date" className="form-control" id="init-date"></input>
          </div>
          <div className="col-md-3">
            <label htmlFor="product-type">Data final </label>
            <input type="date" className="form-control" id="final-date"></input>
          </div>
          <div className="col-md-3">
            <label htmlFor="product-group">Etapa</label>
            <select className="form-control" id="product-group">
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              {stages.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="product-type">Tipo</label>
            <select
              className="form-control"
              id="product-type"
              value={productType}
              onChange={({ target }) => setProductType(target.value)}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              <option value="L">LOCAÇÃO</option>
              <option value="V">VENDA</option>
            </select>
          </div>
        </div>
        <footer>
          <button
            onClick={() => {
              setProductType('')
            }}
          >
            LIMPAR
          </button>
          <button
            onClick={() => {
              if (productType) {
                setParameters([
                  {
                    type: productType || undefined
                  }
                ])
              } else {
                setParameters(undefined)
              }
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr />
      </FilterContaier>
      <ListTitle>
        <p>Listagem</p>
        <hr style={{ paddingBottom: 10 }} />
      </ListTitle>
      <DatatableWrapper>
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          notHasChildren
          headers={headers}
          searchParameters={parameters}
          actions={[
            {
              name: 'print',
              title: 'Imprimir',
              icon: faPrint,
              onClick: param => {
                activeLoading()
                api
                  .get(
                    `${process.env.REACT_APP_API_URL}/warehouse/pickOrder/pdf/${param.id}`,
                    { responseType: 'arraybuffer' }
                  )
                  .then(response => {
                    const blob = new Blob([response.data], {
                      type: 'application/pdf'
                    })
                    const url = window.URL.createObjectURL(blob)

                    window.open(url)
                    disableLoading()
                  })
              }
            },
            {
              name: 'list',
              title: 'Listar',
              icon: faClipboardList
            },
            {
              name: 'check',
              title: 'Verificar',
              icon: faClipboardCheck
            },
            {
              name: 'send',
              title: 'Enviar',
              icon: faTruck
            },
            {
              name: 'search',
              title: 'Visualizar',
              icon: faSearch
            }
          ]}
        />
      </DatatableWrapper>
    </Container>
  )
}
export default PickOrderList
