import React, { createContext, useCallback, useContext, useState } from 'react'
import { v4 as uuid_v4 } from 'uuid'

import ToastContainer from '../components/ToastContainer'

export interface ToastMessage {
  id: string
  type?: 'success' | 'error' | 'info'
  title: string
  description?: string
  style?: React.CSSProperties
  styleContainer?: React.CSSProperties
  time?: number
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void
  removeToast(id: string): void
  messages: ToastMessage[]
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData)

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([])
  const [style, setStyle] = useState<React.CSSProperties>()
  const [styleContainer, setStyleContainer] = useState<React.CSSProperties>()
  const [time, setTime] = useState<number>()

  const addToast = useCallback(
    ({
      title,
      type,
      description,
      style,
      styleContainer,
      time
    }: Omit<ToastMessage, 'id'>) => {
      const id = uuid_v4()

      const toast = {
        id,
        title,
        type,
        description
      }

      setMessages(state => [...state, toast])
      setStyle(style)
      setStyleContainer(styleContainer)
      setTime(time)
    },
    []
  )

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast, messages }}>
      {children}
      <ToastContainer
        messages={messages}
        style={style}
        styleContainer={styleContainer}
        time={time}
      />
    </ToastContext.Provider>
  )
}

function useToast(): ToastContextData {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used witin a ToastProvider')
  }

  return context
}

export { ToastProvider, useToast }
