import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import {
  nameEntity,
  namePageTitle,
  nameSource,
  priorities,
  situations
} from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { FilterContaier, ListTitle } from './styles'
import Form, { Input, Select } from '../../../../components/Form'
import { Date } from '../../../../components/Form/date'
import api from '../../../../services/api'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

type User = {
  id: number
  name: string
}

const List: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()
  const [searchType, setSearchType] = useState('')
  const [situation, setSituation] = useState('')
  const [priority, setPriority] = useState('')
  const [userId, setUserId] = useState(0)
  const [id, setId] = useState(0)
  const [parameters, setParameters] = useState<any[]>()
  const [users, setUsers] = useState<User[]>([])
  const history = useHistory()

  const onSearch = async () => {
    const query = []
    if (id) {
      query.push(`id=${id}`)
    }
    if (startDate) {
      query.push(`startDate=${moment(startDate).format().substring(0, 10)}`)
    }
    if (finalDate) {
      query.push(`finalDate=${moment(finalDate).format().substring(0, 10)}`)
    }
    if (situation) {
      query.push(`situation=${situation}`)
    }
    if (priority) {
      query.push(`priority=${priority}`)
    }
    if (userId) {
      query.push(`userId=${userId}`)
    }
    if (searchType) {
      query.push(`searchType=${searchType}`)
    }

    history.replace(`/warehouse/warehouseRequests?${query.join('&')}`)
    if (
      startDate ||
      finalDate ||
      situation ||
      priority ||
      userId ||
      id ||
      searchType
    ) {
      setParameters([
        {
          id: id || undefined,
          startDate: startDate || undefined,
          finalDate: finalDate || undefined,
          situation: situation ? [situation] : undefined,
          priority: priority || undefined,
          userId: userId || undefined,
          searchType: searchType || undefined
        }
      ])
    } else {
      setParameters([])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('/users')
      setUsers(data)
    })()

    const id = location.get('id')
    const startDate = location.get('startDate')
    const finalDate = location.get('finalDate')
    const situation = location.getAll('situation')
    const priority = location.get('priority')
    const userId = location.get('userId')
    const previousSituation = location.getAll('previousSituation')
    const late = location.get('late')
    const searchType = location.get('searchType')

    if (
      startDate ||
      finalDate ||
      situation ||
      priority ||
      userId ||
      id ||
      previousSituation ||
      late ||
      searchType
    ) {
      setId(Number(id))
      if (startDate) {
        setStartDate(moment(`${startDate} 00:00:00`).toDate())
      }
      if (finalDate) {
        setFinalDate(moment(`${finalDate} 00:00:00`).toDate())
      }
      if (situation?.length) {
        setSituation(situation[0])
      }
      setPriority(priority)
      setUserId(Number(userId))
      setSearchType(searchType)
      setParameters([
        {
          id: id || undefined,
          startDate: startDate ? moment(startDate).toDate() : undefined,
          finalDate: finalDate ? moment(finalDate).toDate() : undefined,
          situation: situation || undefined,
          priority: priority || undefined,
          userId: userId || undefined,
          previousSituation: previousSituation || undefined,
          late: late || undefined,
          searchType: searchType || undefined
        }
      ])
    } else {
      setParameters([])
    }
  }, [])

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Filtro'}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <FilterContaier>
        <Form onSubmit={onSearch} defaultValues={{}}>
          <div className="col-md-1">
            <Input
              label="Nº Requisição"
              className="form-control"
              name="id"
              onChange={e => setId(Number(e.target.value))}
              value={id}
              controlled
            />
          </div>
          <div className="col-md-1">
            <Select
              label="Prioridade"
              className="form-control"
              name="priority"
              options={priorities}
              onChange={e => setPriority(e.target.value)}
              value={priority}
              controlled
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-2">
            <Date
              label="Data inicial"
              className="form-control"
              name="start_date"
              onChange={date => setStartDate(date)}
              selected={startDate}
              controlled
            />
          </div>
          <div className="col-md-2">
            <Date
              label="Data final"
              className="form-control"
              name="final_date"
              onChange={date => setFinalDate(date)}
              selected={finalDate}
              controlled
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Tipo de busca"
              className="form-control"
              name="searchType"
              options={[
                { name: 'DATA ENTREGA', value: 'delivery_date' },
                { name: 'DATA SOLICITAÇÃO', value: 'created_at' }
              ]}
              onChange={e => setSearchType(e.target.value)}
              value={searchType}
              controlled
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Solicitante"
              className="form-control"
              name="user_id"
              options={users.map(u => ({
                value: u.id,
                name: u.name
              }))}
              onChange={e => setUserId(Number(e.target.value))}
              value={userId}
              controlled
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Etapa"
              className="form-control"
              name="situation"
              options={situations}
              onChange={e => setSituation(e.target.value)}
              value={situation}
              controlled
              blank
              defaultValue={''}
            />
          </div>
          <footer className="col-md-12">
            <button
              type="reset"
              onClick={() => {
                setStartDate(undefined)
                setFinalDate(undefined)
                setSituation('')
                setPriority('')
                setUserId(0)
                setId(0)
              }}
            >
              LIMPAR
            </button>
            <button type="submit">BUSCAR</button>
          </footer>
        </Form>
        <hr />
      </FilterContaier>
      <ListTitle>
        <p>Listagem</p>
        <hr style={{ paddingBottom: 10 }} />
      </ListTitle>
      {parameters && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          orderBySort={'DESC'}
          notHasChildren
          searchParameters={parameters}
          headers={headers}
          rowStyled
          customHeaders={[
            {
              field: 'delivery_date',
              name: 'Entrega',
              sortable: false,
              element: item => (
                <span>
                  {item.delivery_date
                    ? item.delivery_date.substring(0, 10)
                    : ''}
                </span>
              )
            },
            {
              field: 'situation_expiration',
              name: 'Data Limite',
              sortable: false,
              element: item => (
                <span>
                  {item.situation_expiration
                    ? moment(item.situation_expiration, 'DD/MM/YYYY HH:mm:ss')
                        .add(3, 'h')
                        .format('DD/MM/YYYY')
                    : ''}
                </span>
                // <span>
                //   {item.situation_expiration
                //     ? item.situation_expiration.substring(0, 10)
                //     : ''}
                // </span>
              )
            },
            {
              field: 'user_id',
              name: 'Solicitante',
              sortable: true,
              element: item => (
                <span>
                  {item.user_id
                    ? users?.find(u => u.id === item.user_id)?.name
                    : ''}
                </span>
              )
            },
            {
              field: 'priority',
              name: 'Prioridade',
              sortable: true,
              element: item => (
                <span>
                  {priorities.find(s => s.value === item?.priority)?.name}
                </span>
              )
            },
            {
              field: 'situation',
              name: 'Situação',
              sortable: true,
              element: item => (
                <span>
                  {situations.find(s => s.value === item?.situation)?.name}
                </span>
              )
            }
          ]}
        />
      )}
    </Container>
  )
}
export default List
