import styled from 'styled-components'

export const Container = styled.div`
  table {
    border-collapse: collapse;
    table-layout: auto;
    th {
      width: 176px;
    }
  }
  .table thead tr th.actions,
  .table thead tr td.actions,
  .table tbody tr td:last-child {
    text-align: left;
  }
  .table.table-light > thead > tr > th {
    color: #a0a0a0;
  }
  .table.table-light > tbody > tr > td {
    color: #a0a0a0;
  }
  a {
    text-decoration: none;
    color: #a0a0a0;
  }
  tr {
    cursor: pointer;
  }
  .table.table-light > tbody > tr.trChild {
    background: #f5f5f5;
    td {
      border-bottom: 0px;
    }
    td.firstChild {
      padding: 15px 5px 15px 25px;
      input,
      label {
        cursor: pointer;
      }
    }
  }
  .table.table-light > tbody > tr.trChild td.TableData {
    border-bottom: 1px solid #f2f5f8;
  }
  tr.activeCustom {
    td {
      background: #ebebeb;
      color: #333;
      height: 84px;
    }
    /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  }
  tr.activeCustomChild {
    border: 2px solid #a0a0a0;
  }
  span.maintitle {
    color: #697882;
    font-family: 'Font', sans-serif;
    /* font-size: 22px; */
    font-weight: 400;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td {
    padding: 15px;
  }

  th {
    .ThBalance,
    .ThPercentage {
      color: #a0a0a0;
    }
  }
  .TableRowHead {
    th {
      padding: 10px 15px 15px 15px;
    }
  }
  .container,
  .container-fluid {
    margin: 0;
  }
`
