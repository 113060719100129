import React, { useCallback, useState, useEffect } from 'react'
import { Container } from './styles'
import { Table } from './component/Table'
import { useProduct } from '../../../providers/product/ProductProvider'

export const StockTab = (): JSX.Element => {
  const { attributes, hasVariation } = useProduct()
  const [attributesList, setAttributesList] = useState<any[]>([])

  useEffect(() => {
    setAttributesList(attributes)
  }, [attributes])

  const handlerClickCheckBox = useCallback(
    (index: number) => {
      attributesList[index].isChecked = !attributesList[index].isChecked
      setAttributesList([...attributesList])
    },
    [attributesList]
  )

  useEffect(() => {
    if (hasVariation === 'NO') {
      attributesList.forEach((atribute, index) => {
        attributesList[index].isChecked = false
      })
    }
  }, [attributesList, hasVariation])

  const table = Table({ attributesList })

  return (
    <>
      {hasVariation === 'YES' && (
        <Container className="row">
          <div className="check-container form-content col-md-12">
            {attributesList.map(
              ({ id, name, isChecked, parent_id }, index) =>
                parent_id === null && (
                  <div key={id}>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {
                        handlerClickCheckBox(index)
                      }}
                      value={id}
                    />
                    <label>{name}</label>
                  </div>
                )
            )}
          </div>
        </Container>
      )}
      <div className="row">{table}</div>
    </>
  )
}

export const labelHasVariation = 'Variação/Estoque'
export const nameHasVariation = '@@tabs-has-variation'
