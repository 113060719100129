import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle, nameActions } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FormCategory } from '../components/Form'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'

interface WarehouseRequestData {
  id: number
  details: string
  name: string
  active: boolean
}
interface WarehouseDetailsData {
  name: string
  price: string
  value: string
}
interface WarehouseData {
  id: number
  details: WarehouseDetailsData
  name: string
  active: number
}

const Update: React.FC = () => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [warehouse, setWarehouse] = useState<WarehouseData>({
    details: {
      name: '',
      price: '',
      value: ''
    },
    id: 0,
    name: '',
    active: 1
  })
  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get<WarehouseRequestData>(apiList(id))
        const { data } = response
        setWarehouse({
          details: JSON.parse(data.details),
          id: data.id,
          name: data.name,
          active: data.active ? 1 : 0
        })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o armazém',
          description:
            'Houve um error ao carregar o armazém, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }

    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.update.name}
        breadcrumb={breadcrumbUpdate}
        tools={[toolsUpdate]}
      >
        <div className="form body">
          <FormCategory
            valueInput={{
              ...warehouse,
              detailsName: warehouse.details.name,
              detailsPrice: warehouse.details.price,
              detailsValue: warehouse.details.value
            }}
            typeForm={{
              idUpdate: warehouse.id,
              inputValue: warehouse.name
            }}
          />
        </div>
      </Container>
    </>
  )
}
export default Update
