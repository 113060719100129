import React from 'react'
import List from '../../pages/Commercial/Segments/List'
import Update from '../../pages/Commercial/Segments/Update'
import Create from '../../pages/Commercial/Segments/Create'
import View from '../../pages/Commercial/Segments/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const SegmentsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/segments" exact component={List} />
    <Route path="/commercial/segments/update/:id" component={Update} exact />
    <Route path="/commercial/segments/create" component={Create} exact />
    <Route path="/commercial/segments/view/:id" component={View} exact />
  </CustomSwitch>
)
