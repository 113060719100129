type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const optionsPaymentForm = [
  {
    name: 'SELECIONE',
    value: ''
  },
  {
    name: 'BOLETO',
    value: 'BOLETO'
  },
  {
    name: 'CARTEIRA(CHEQUE/DINHEIRO)',
    value: 'CARTEIRA'
  },
  {
    name: 'DEPOSITO EM C/C',
    value: 'DEPOSITO EM C/C'
  },
  {
    name: 'CARTAO DE CREDITO',
    value: 'CARTAO DE CREDITO'
  },
  {
    name: 'CARTAO DE DEBITO',
    value: 'CARTAO DE DEBITO'
  },
  {
    name: 'TRANSFERENCIA',
    value: 'TRANSFERENCIA'
  },
  {
    name: 'DEBITO AUTOMATICO',
    value: 'DEBITO AUTOMATICO'
  },
  {
    name: 'FINACIAMENTO',
    value: 'FINACIAMENTO'
  },
  {
    name: 'EMPENHO',
    value: 'EMPENHO'
  }
]

const nameEntity = 'PanelLocation'
const nameSource = 'commercial/panelLocation'
const namePageTitle = 'Painel de Locação'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Cadastro',
  to: '/commercial/panelLocation',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/panelLocation/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/panelLocation/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Atualizar',
    to: '/commercial/panelLocation/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions,
  optionsPaymentForm
}
