import axios, { AxiosError } from 'axios'
import { validateToken } from '../utlis/validateToken'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})
api.interceptors.request.use(config => {
  if (!config.url.includes('checkPermission')) {
    const pathVerify = [
      { name: 'create', method: 'POST' },
      { name: 'view', method: 'GET' },
      { name: 'update', method: 'PUT' },
      { name: 'delete', method: 'DELETE' }
    ]
    const url = window.location.pathname
    const path = pathVerify.find(pathItem =>
      url.split('/').find(item => pathItem.name === item)
    )
    config.headers.frontorigin = JSON.stringify({
      url,
      method: path?.method || 'GET'
    })
  }
  return config
})
api.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error?.config?.headers?.authorization) {
    const token = error.config.headers.authorization as string
    const isValid = validateToken(token.split(' ')[1])
    if (!isValid) {
      window.location.href = '/'
      error.message = 'Sessão invalida, faça login novamente'
    }
    return Promise.reject(error)
  }
  if (error.response.status === 401) {
    window.location.href = '/'
    error.message = 'Sessão invalida, faça login novamente'
    return Promise.reject(error)
  }

  return Promise.reject(error)
})
export default api
