import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { Alert } from '../../../../../components/Alert'

type CostCentersData = {
  name: string
}

type TypesFormProps = {
  stock: {
    product: {
      name: string
      type: string
      product_category: {
        name: string
      }
    }
    id: number
    name: string
    product_units_measured: {
      name: string
    }
    current_stock: string
    replacement_point: string
  }
  setModalStockMoviments: any
  loadStock?: any
}

export const FormStockMoviments = ({
  stock,
  setModalStockMoviments,
  loadStock
}: TypesFormProps) => {
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const { activeLoading, disableLoading } = useLoading()
  const [alert, setAlert] = useState(false)
  const [data, setData] = useState<CostCentersData>()

  const send = async () => {
    try {
      const dataCreate = {
        ...data,
        stock_id: stock.id,
        status: 'realized',
        model: '',
        model_id: 0,
        warehouse_id: 0
      }
      activeLoading()
      await api.post(apiCreate(), dataCreate)
      updateDataTable()
      if (loadStock) {
        loadStock()
      }
      disableLoading()
      setAlert(false)
      setModalStockMoviments(false)
      addToast({
        type: 'success',
        title: 'Movimentação adicionada',
        description: 'A movimentação de estoque foi adicionada.'
      })
    } catch (error: any) {
      const message = error?.response?.data?.message
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o registro',
        description:
          message ||
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
      setAlert(false)
      setModalStockMoviments(false)
      disableLoading()
    }
  }
  const onSubmit = async (data: CostCentersData) => {
    setAlert(true)
    setData(data)
  }

  return (
    <div style={{ padding: '0px 10px' }}>
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="col-md-3">
          <b>Tipo</b>
          <p>{stock?.product?.type}</p>
        </div>
        <div className="col-md-3">
          <b>Grupo</b>
          <p>{stock?.product?.product_category.name}</p>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="col-md-6">
          <b>Produto</b>
          <p>{stock?.product?.name}</p>
        </div>
        <div className="col-md-4">
          <b>Variação</b>
          <p>{stock?.name}</p>
        </div>
        <div className="col-md-2">
          <b>Medida</b>
          <p>{stock?.product_units_measured?.name}</p>
        </div>
      </div>
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="col-md-2">
          <b>Ponto de reposição</b>
          <p>{stock?.replacement_point}</p>
        </div>
        <div className="col-md-2">
          <b>Estoque atual</b>
          <p>{stock?.current_stock}</p>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <div className="row" style={{ marginTop: '30px' }}>
          <div className="col-md-2">
            <Select
              label="Tipo"
              name="direction"
              className="form-control"
              options={[
                { name: 'Entrada', value: 'in' },
                { name: 'Saída', value: 'out' }
              ]}
              rules={{ required: true }}
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-2">
            <Input
              label="Quantidade"
              name="quantity"
              className="form-control"
              rules={{ required: true }}
              onKeyPress={event => {
                const regex = /^[0-9.]+$/
                if (!regex.test(event.key)) {
                  event.preventDefault()
                }
              }}
            />
          </div>
          <div className="col-md-8">
            <Input
              label="Descrição"
              name="description"
              className="form-control"
              rules={{ required: true }}
            />
          </div>
        </div>
        <div className="form-actions right">
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        </div>
      </Form>
      <Alert
        message={
          'Tem certeza que deseja adicionar a movimentação? Essa ação não pode ser desfeita.'
        }
        onClickCancellButton={() => setAlert(false)}
        onClickConfirmButton={() => send()}
        isActive={alert}
      />
    </div>
  )
}
