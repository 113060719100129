import React, { ChangeEvent, useEffect, useState } from 'react'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { Date } from '../../../../../components/Form/date'
import { FaClipboard, FaUser } from 'react-icons/fa'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiCreate, apiUpdate } from '../../domain/api'
import { ModalType, Task, User } from '../../List'
import moment from 'moment'
import ButtonLight from '../../../../../components/ButtonLight'
import ButtonLabelLight from '../../../../../components/ButtonLabelLight'
import { TASK_PRIORITY_OPTIONS } from '../../../../../common/constants/task-priority'

type TaskData = {
  id: number
  title: string
  author: string
  description: string
  start: string
  end: Date
  status: string
}

type FormTaskProps = {
  initialValues?: Task
  typeForm: 'create' | 'update'
  setModal: React.Dispatch<React.SetStateAction<ModalType>>
  modal: ModalType
  getTasks: (status: string) => void
  users: User[]
}

export const FormTask = ({
  typeForm,
  initialValues,
  setModal,
  modal,
  getTasks,
  users
}: FormTaskProps): JSX.Element => {
  const { addToast } = useToast()
  const [defaultValues, setDefaultValues] = useState<Task>()
  const [end, setEnd] = useState<Date>()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User>()
  const [selectedResponsibleUser, setSelectedResponsibleUser] = useState<
    User | undefined
  >()
  const { activeLoading, disableLoading } = useLoading()
  const [oldAttachments, setOldAttachments] = useState<Task['attachments']>([])

  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    if (!modal.open) {
      setFiles([])
      setDefaultValues(prev => ({
        ...prev,
        title: '',
        description: '',
        end: '',
        priority: ''
      }))
      setEnd(moment().toDate())
      setSelectedUsers([])
      setOldAttachments([])
    }
  }, [modal])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      setSelectedResponsibleUser(
        initialValues.taskUsers.find(taskUser => taskUser.responsible)?.user
      )

      setEnd(moment(initialValues.end, 'DD/MM/YYYY').toDate())
      setSelectedUsers(
        initialValues.taskUsers
          .filter(taskUser => !taskUser.responsible)
          .map(taskUser => taskUser.user)
      )
      setOldAttachments(initialValues.attachments)
    }
  }, [initialValues])

  const onSubmit = async (data: TaskData) => {
    if (!selectedResponsibleUser && !selectedUsers.length) {
      addToast({
        type: 'error',
        title: 'É necessário adicionar pelo menos um usuário à tarefa.'
      })
      return
    }
    const formData = new FormData()
    formData.append(
      'users',
      JSON.stringify(selectedUsers.map(selectedUser => selectedUser.id))
    )
    formData.append('end', end.toISOString())
    formData.append('status', initialValues?.status || 'to-do')
    files.forEach(file => {
      formData.append('files[]', file)
    })
    Object.entries(data).forEach(([key, value]) => {
      if (value && key !== 'end') {
        formData.append(key, String(value))
      }
    })

    if (typeForm === 'create') {
      try {
        activeLoading()
        await api.post(apiCreate(), formData)
        disableLoading()
        getTasks('to-do')
        setModal({
          open: false,
          type: 'create'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
      }
    } else {
      const id = initialValues.id
      const arr1 = initialValues.attachments.map(a => a.id)
      const arr2 = oldAttachments.map(o => o.id)
      const difference = arr1.filter(x => !arr2.includes(x))
      if (difference) {
        formData.append('attachments_to_remove', JSON.stringify(difference))
      }
      try {
        activeLoading()
        await api.put(apiUpdate(String(id)), formData)
        disableLoading()
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        getTasks(initialValues?.status || 'to-do')
        setModal({
          open: false,
          type: 'update'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    setSelectedResponsibleUser(undefined)
    disableLoading()
  }

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFiles(prev => [...prev, file])
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <Input
        label="Título"
        name="title"
        className="form-control"
        rules={{ required: true }}
      />
      <Textarea
        label="Descrição"
        name="description"
        className="form-control"
        rows={7}
        rules={{ required: true }}
      />
      <div className="row">
        <div className="col-md-4">
          <Date
            label="Prazo"
            name="end"
            className="form-control"
            onChange={date => setEnd(date)}
            selected={end}
            controlled
            rules={{ required: true }}
            minDate={moment().subtract({ hour: 3 }).toDate()}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Prioridade"
            name="priority"
            className="form-control"
            options={TASK_PRIORITY_OPTIONS}
            blank
            defaultValue={''}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Select
            label="Participante responsável"
            name="responsible"
            className="form-control"
            options={users.map(u => ({
              name: u.name,
              value: u.id
            }))}
            blank
            value={selectedResponsibleUser?.id || ''}
            defaultValue={''}
            onChange={({ target }) =>
              setSelectedResponsibleUser(
                users.find(u => u.id === Number(target.value))
              )
            }
            fullControlled
            rules={{ required: true }}
            disabled={typeForm === 'update'}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Select
            label="Adicionar outro participante"
            name="uuser"
            className="form-control"
            options={users
              .filter(user => {
                const selecteds = selectedUsers.map(
                  selectedUser => selectedUser.id
                )
                if (user?.id === selectedResponsibleUser?.id) {
                  return false
                }
                return !selecteds.includes(user.id)
              })
              .map(u => ({
                name: u.name,
                value: u.id
              }))}
            blank
            value={selectedUser?.id || ''}
            defaultValue={''}
            onChange={({ target }) =>
              setSelectedUser(users.find(u => u.id === Number(target.value)))
            }
            fullControlled
          />
        </div>
        <div className="col-md-2" style={{ padding: '0px', marginTop: '35px' }}>
          <a
            type="button"
            style={{ height: '34px' }}
            onClick={() => {
              if (selectedUser) {
                setSelectedUsers(prev => [...prev, selectedUser])
                setSelectedUser(undefined)
              }
            }}
          >
            + Adicionar participante
          </a>
        </div>
        <div className="col-md-6" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            className="row"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label
              style={{
                marginLeft: '15px'
              }}
            >
              Usuários selecionados
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: '15px'
              }}
            >
              {selectedUsers.map((selectedUser, index) => (
                <div
                  key={selectedUser.id}
                  style={{
                    border: '1px solid #ccc',
                    background: '#f2f2f2',
                    padding: '5px 8px',
                    marginBottom: '1em',
                    marginRight: '8px'
                  }}
                >
                  <FaUser style={{ marginRight: '4px' }} />
                  {selectedUser.name}
                  {
                    <a
                      style={{
                        padding: '3px 6px',
                        marginLeft: '3px'
                      }}
                      onClick={() => {
                        setSelectedUsers(prev => {
                          const copy = [...prev]
                          copy.splice(index, 1)
                          return copy
                        })
                      }}
                    >
                      X
                    </a>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-md-12"
          style={{ alignItems: 'flex-end', display: 'flex' }}
        >
          <ButtonLabelLight htmlFor="atch">Inserir Anexo</ButtonLabelLight>
          <input
            type="file"
            name="atch"
            id="atch"
            onChange={onChangeFileHandler}
            style={{ display: 'none' }}
          />
          {oldAttachments?.map((file, index) => (
            <span style={{ marginLeft: '10px' }} key={file.filename}>
              <FaClipboard style={{ marginRight: '4px' }} />
              {file.filename.substring(file.filename.indexOf('-') + 1)}
              {
                <a
                  style={{
                    padding: '3px 6px',
                    marginLeft: '3px'
                  }}
                  onClick={() => {
                    setOldAttachments(prev => {
                      const copy = [...prev]
                      copy.splice(index, 1)
                      return copy
                    })
                  }}
                >
                  X
                </a>
              }
            </span>
          ))}
          {files?.map((file, index) => (
            <span style={{ marginLeft: '10px' }} key={file.name}>
              <FaClipboard style={{ marginRight: '4px' }} />
              {file.name}
              {
                <a
                  style={{
                    padding: '3px 6px',
                    marginLeft: '3px'
                  }}
                  onClick={() => {
                    setFiles(prev => {
                      const copy = [...prev]
                      copy.splice(index, 1)
                      return copy
                    })
                  }}
                >
                  X
                </a>
              }
            </span>
          ))}
        </div>
      </div>
      <hr
        className="divider"
        style={{
          position: 'relative',
          width: 'calc(100% + 28px)',
          transform: 'translateX(-14px)',
          borderTop: '1px solid #e5e5e5'
        }}
      />
      <div className="right" style={{ border: 'none' }}>
        <ButtonLight type="submit">
          {typeForm === 'create' ? '+ Criar Tarefa' : 'Editar Tarefa'}
        </ButtonLight>
      </div>
    </Form>
  )
}
