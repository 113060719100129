import { FaTrashAlt } from 'react-icons/fa'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 5px 20px 10px 20px;
  margin: 0;
  .actions {
    width: 60px;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    border: 1px solid #e7ecf1;
  }
  .table tr td > div {
    margin-bottom: 0px !important;
  }

  .panel-body {
    font-size: 14px !important;
  }
  .panel-heading {
    background: #fafafa;
    a {
      font-size: 14px !important;
      display: block !important;
      span {
        color: #aaa;
        margin-right: 10px;
      }
    }
  }
`

export const IconRemove = styled(FaTrashAlt).attrs(() => ({
  size: '16px'
}))`
  margin: 10px auto;
  cursor: pointer;
`

export const Panel = styled.div<{ open: boolean; id: string }>`
  height: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  ${({ open, id }) =>
    open &&
    css`
      height: ${document.getElementById(id)?.scrollHeight}px;
    `}
`

export const StockItem = styled.div`
  &:first-child {
    border-top: 1px solid #f2f5f8;
  }
  border-bottom: 1px solid #f2f5f8;

  margin-bottom: 0px;
  .panel-default {
    margin-bottom: 0px;
    border: none !important;
  }
  .panel-body {
    font-size: 14px;
    border: none !important;
  }
  .panel-heading {
    background: #fff;
    height: 84px;
    display: flex;
    align-items: center;
    div {
      color: #a0a0a0;
      text-transform: capitalize;
      svg {
        margin-left: 22px;
      }
      font-size: 14px;
      font-weight: 400;
      display: block !important;
      cursor: pointer;
      span {
        color: #aaa;
        margin-right: 10px;
      }
    }
  }
`

export const Th = styled.th.attrs(
  ({ isTypeSaleOrResale }: { isTypeSaleOrResale?: boolean }) => {
    return {
      rowSpan: isTypeSaleOrResale ? 2 : 1
    }
  }
)<{
  active?: boolean
  isTypeSaleOrResale?: boolean
}>`
  display: none;
  ${({ active }) =>
    active &&
    css`
      display: table-cell;
    `}
  ${({ isTypeSaleOrResale }) =>
    isTypeSaleOrResale &&
    css`
      position: relative;
      line-height: 50px;
    `}
`

export const Td = styled.td<{
  active?: boolean
  isTypeSaleOrResale?: boolean
}>`
  display: none;
  ${({ active }) =>
    active &&
    css`
      display: table-cell;
    `}
`
