import React from 'react'
import api from '../../../../../../services/api'
import Form, { Textarea } from '../../../../../../components/Form'
import Button from '../../../../../../components/Button'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'

interface TypesFormProps {
  setModal: any
  loadOrderExpedition: any
  id: number
  value?: string
}

export const FormObs = ({
  setModal,
  loadOrderExpedition,
  id,
  value
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: any) => {
    activeLoading()
    const url = 'warehouse/expedition/orders/situation'
    try {
      await api.post(url, { order_id: id, onlyUpdate: true, ...data })
      addToast({
        type: 'success',
        title: 'Registro atualizado',
        description: 'Registro alterado com sucesso'
      })
      loadOrderExpedition()
      setModal(false)
    } catch (error: any) {
      const message = error?.response?.data?.message
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description:
          message ||
          'Ocorreu um erro ao atualizar o cadastro, por favor, tente novamente.'
      })
    }
    disableLoading()
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div style={{ width: '800px' }}>
          <div className="row">
            <div className="form-content col-md-12">
              <div className="form-group">
                <Textarea
                  label="Observações"
                  className="form-control"
                  name="description"
                  rows={6}
                  defaultValue={value}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
