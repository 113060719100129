import styled from 'styled-components'

export const Avatar = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50% !important;
  margin-right: 8px;
`

export const MessageContainer = styled.div`
  margin-top: 20px;
`
