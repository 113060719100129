import React, { HTMLAttributes } from 'react'
interface HeaderCustoSummaryProps extends HTMLAttributes<HTMLDivElement> {
  title: string
}

export function HeaderCustomSummary({ title }: HeaderCustoSummaryProps) {
  return (
    <div className="portlet-title" style={{ marginBottom: 0 }}>
      <div className="caption caption-md">
        <i className="icon-bar-chart font-dark hide"></i>
        <span className="maintitle">{title}</span>
        <span className="caption-helper"></span>
      </div>
    </div>
  )
}
