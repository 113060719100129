import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  PageContainer,
  Avatar,
  LinkContainer,
  ReplyInput,
  ContainerInput
} from './style'
import Modal from '../../../../components/Modal'
import {
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaPaperclip,
  FaRegThumbsUp,
  FaUndo
} from 'react-icons/fa'
import avatar from '../../../../assets/layouts/layout3/img/avatar.png'
import { FormTask } from '../components/Form'
import api from '../../../../services/api'
import Container from '../../../../components/Container'
import { Card } from '../../../../components/Card'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { breadcrumbList } from '../domain/breadcrumb/index'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import Form, { Input, Textarea } from '../../../../components/Form'
import { useAuth } from '../../../../hooks/auth'
import { TaskMessage } from '../components/MessageContainer'
import ButtonLight from '../../../../components/ButtonLight'
import ButtonLabelLight from '../../../../components/ButtonLabelLight'
import { CURRENT_TAB_TASK } from '../../../../common/constants'
import { Alert } from '../../../../components/Alert'
import { apiDelete } from '../domain/api'
import { FILE_PATH_TASK_ATTACHMENTS } from '../constants/pathToFile'
import { TASK_PRIORITY_ENUM } from '../../../../common/constants/task-priority'
import { StringParam, useQueryParam } from 'use-query-params'
import { useDebounce } from '../../../../utlis/useDebounce'

export type User = {
  id: number
  name: string
  avatar_url: string
}
type Attachment = {
  id: number
  task_id: number
  filename: string
  type: string
}
type Comment = {
  id: number
  message: string
  read_at: string
  user: User
  attachments: Attachment[]
  task_id: number
  created_at: string
}

export type Task = {
  id: number
  active: boolean
  title: string
  read_at: string
  author: string
  description: string
  start: string
  end: string
  status: string
  priority: string
  re_open?: string
  user_finished_at: string
  userDemandant: {
    id: number
    name: string
    user: { name: string }
    avatar_url?: string
  }
  comments: Comment[]
  attachments: Attachment[]
  taskUsers: {
    id: number
    name: string
    user: User
    user_id: number
    accepted: boolean
    responsible: boolean
  }[]
  created_at: string
  updated_at: string
  open: boolean
  dueDays: number
  finishedDueDays: number
  user_finished: boolean
}

export type ModalType = {
  open: boolean
  type: 'create' | 'update'
  task?: Task
}

enum TaskTabs {
  MY_TASK,
  TASK_REQUESTED_BY_ME
}

enum TypeTaskFinishedDate {
  OVER_TIME = 'over_time',
  FINISHED_AT = 'finished_at'
}

const HANDLE_QUERY_VALUE_BY_TAB: Record<string, string> = {
  [TaskTabs.MY_TASK]: 'other',
  [TaskTabs.TASK_REQUESTED_BY_ME]: 'me'
}

function updateQueryParams({
  url,
  queryKey,
  value
}: {
  url: string
  queryKey: string
  value: string
}) {
  const urlObj = new URL(url)
  urlObj.searchParams.set(queryKey, value)
  return `${urlObj.pathname}${urlObj.search}`
}

function handleGetStatus({ status }: { status: string }) {
  if (status === 'to-do') {
    return 'A FAZER'
  }
  if (status === 'in-progress') {
    return 'EM ANDAMENTO'
  }
  if (status === 'finished') {
    return 'FINALIZADO'
  }
  if (!status) {
    return 'TODAS AS TAREFAS'
  }
}

const List: React.FC = () => {
  const reactRouterDomlocation = useLocation()
  const [typeFinishedDate] = useQueryParam('type_finished_date')
  const [modal, setModal] = useState<ModalType>({
    open: false,
    type: 'create'
  })
  const [users, setUsers] = useState<User[]>([])

  const [tasks, setTasks] = useState<Task[]>([])
  const [searchName] = useQueryParam('name', StringParam)
  const debouncedInputSearchProducts = useDebounce(searchName || '', 500)
  const [status, setStatus] = useState<Task['status']>('to-do')
  const [myTasks, setMyTasks] = useState({
    toDo: 0,
    inProgress: 0,
    finished: 0
  })
  const [tasksRequestedByMe, setTasksRequestedByMe] = useState({
    toDo: 0,
    inProgress: 0,
    finished: 0
  })
  const [tasksFinishedOverdue, setTasksFinishedOverdue] = useState(0)
  const [tasksReOpen, setTasksReOpen] = useState(0)
  const [, setTasksFinishedAtTime] = useState(0)
  const [currentTabTask, setCurrentTabTask] = useState(() => {
    const storageCurrentTab = localStorage.getItem(CURRENT_TAB_TASK)
    return Number(storageCurrentTab) || TaskTabs.MY_TASK
  })
  const [hasOverDueTasks, setHasOverDueTasks] = useState({
    myTask: false,
    taskRequestedByMe: false,
    myTaskToDo: false,
    taskRequestedByMeToDo: false
  })
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([])
  const { disableLoading, activeLoading } = useLoading()
  const { addToast } = useToast()
  const { user } = useAuth()
  const history = useHistory()
  const location = new URLSearchParams(useLocation().search)

  const [defaultValues, setDefaultValues] = useState({ message: '' })
  const [files, setFiles] = useState<File[]>([])

  const [alertDelete, setAlertDelete] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const [alertReopen, setAlertReopen] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const handleGetUrlBox = useCallback(
    ({ link }: { link: string }) => {
      return `${link}&name=${searchName || ''}`
    },
    [searchName]
  )

  const finishedFooterItems = useMemo(() => {
    const initialList: {
      text: string
      subtitle: string
      color?: string
      target?: string
      link: string
      history?: boolean
    }[] = [
      {
        text: 'MINHA TAREFA',
        color: undefined,
        subtitle: String(myTasks.finished),
        link: handleGetUrlBox({
          link: '/personelDepartment/tasks/?status=finished&owner=other'
        }),
        target: '_self'
      },
      {
        text: 'TAREFA QUE SOLICITEI',
        color: hasOverDueTasks.taskRequestedByMeToDo ? '#f36a5a' : undefined,
        subtitle: String(tasksRequestedByMe.finished),
        link: handleGetUrlBox({
          link: '/personelDepartment/tasks/?status=finished&owner=me'
        }),
        target: '_self'
      }
    ]
    if (tasksFinishedOverdue > 0) {
      initialList.push({
        text: 'FINALIZADO COM ATRASO',
        color: '#f36a5a',
        subtitle: String(tasksFinishedOverdue),
        link: handleGetUrlBox({
          link: `/personelDepartment/tasks/?status=finished&type_finished_date=${TypeTaskFinishedDate.OVER_TIME}`
        }),
        history: true
      })
    }
    return initialList
  }, [
    handleGetUrlBox,
    hasOverDueTasks.taskRequestedByMeToDo,
    myTasks.finished,
    tasksFinishedOverdue,
    tasksRequestedByMe.finished
  ])

  const getTasks = useCallback(
    async (status: string, taskID?: string, typeFinishedDate?: string) => {
      setHasOverDueTasks({
        myTask: false,
        taskRequestedByMe: false,
        myTaskToDo: false,
        taskRequestedByMeToDo: false
      })
      activeLoading()
      const { data } = await api.get<Task[]>(
        `personelDepartment/tasks?name=${debouncedInputSearchProducts}`
      )
      setTasks(data)

      const filteredTasks: Task[] = []
      const myTasks = {
        toDo: 0,
        inProgress: 0,
        finished: 0
      }
      const tasksRequestedByMe = {
        toDo: 0,
        inProgress: 0,
        finished: 0
      }
      const taskReOpen: any[] = []
      let tasksFinishedOverdue = 0
      let tasksFinishedAtTime = 0
      data.forEach(task => {
        const dueDays = moment(task.end, 'DD/MM/YYYY')
          .startOf('days')
          .diff(moment().startOf('days'), 'days')
        const finishedDueDays = moment(task.end, 'DD/MM/YYYY').diff(
          moment(task.user_finished_at, 'DD/MM/YYYY').startOf('days'),
          'days'
        )

        task.dueDays = dueDays
        if (task.status === 'to-do') {
          if (task.userDemandant.id === Number(user.id)) {
            tasksRequestedByMe.toDo++
            if (dueDays < 0) {
              setHasOverDueTasks(prev => ({
                ...prev,
                taskRequestedByMeToDo: true
              }))
            }
          }
          if (
            task.taskUsers?.find(
              taskUser => taskUser.user.id === Number(user.id)
            )
          ) {
            if (dueDays < 0) {
              setHasOverDueTasks(prev => ({
                ...prev,
                myTaskToDo: true
              }))
            }
            myTasks.toDo++
          }
        }
        if (task.status === 'in-progress') {
          if (task.re_open) {
            taskReOpen.push(task.id)
            setTasksReOpen(
              taskReOpen.filter(
                (item, index) => taskReOpen.indexOf(item) === index
              ).length
            )
          }
          if (!task.re_open && task.userDemandant.id === Number(user.id)) {
            tasksRequestedByMe.inProgress++
            if (dueDays < 0) {
              setHasOverDueTasks(prev => ({
                ...prev,
                taskRequestedByMe: true
              }))
            }
          }

          if (
            !task.re_open &&
            task.taskUsers?.find(
              taskUser => taskUser.user.id === Number(user.id)
            )
          ) {
            if (dueDays < 0) {
              setHasOverDueTasks(prev => ({
                ...prev,
                myTask: true
              }))
            }
            myTasks.inProgress++
          }
        }
        if (task.status === 'finished') {
          task.finishedDueDays = finishedDueDays
          if (finishedDueDays < 0) {
            tasksFinishedOverdue++
          } else {
            tasksFinishedAtTime++
          }
          if (task.userDemandant.id === Number(user.id)) {
            tasksRequestedByMe.finished++
            // if (dueDays < 0) {
            //   setHasOverDueTasks(prev => ({
            //     ...prev,
            //     taskRequestedByMe: true
            //   }))
            // }
          }
          if (
            task.taskUsers?.find(
              taskUser => taskUser.user.id === Number(user.id)
            )
          ) {
            // if (dueDays < 0) {
            //   setHasOverDueTasks(prev => ({
            //     ...prev,
            //     myTask: true
            //   }))
            // }
            myTasks.finished++
          }
        }
        if (task.status === status) {
          if (status === 'finished') {
            if (currentTabTask === TaskTabs.TASK_REQUESTED_BY_ME) {
              if (task.userDemandant.id === Number(user.id)) {
                filteredTasks.push(task)
              }
            }
            if (currentTabTask === TaskTabs.MY_TASK) {
              if (
                task.taskUsers?.find(
                  taskUser => taskUser.user.id === Number(user.id)
                )
              ) {
                filteredTasks.push(task)
              }
            }
          } else {
            if (currentTabTask === TaskTabs.TASK_REQUESTED_BY_ME) {
              if (task.userDemandant.id === Number(user.id) && !task.re_open) {
                filteredTasks.push(task)
              }
            }
            if (currentTabTask === TaskTabs.MY_TASK) {
              if (
                task.taskUsers?.find(
                  taskUser => taskUser.user.id === Number(user.id)
                ) &&
                !task.re_open
              ) {
                filteredTasks.push(task)
              }
            }
          }
        }
        if (status === 're-open') {
          if (task.re_open && task.status === 'in-progress') {
            if (currentTabTask === TaskTabs.TASK_REQUESTED_BY_ME) {
              if (task.userDemandant.id === Number(user.id)) {
                filteredTasks.push(task)
              }
            }
            if (currentTabTask === TaskTabs.MY_TASK) {
              if (
                task.taskUsers?.find(
                  taskUser => taskUser.user.id === Number(user.id)
                )
              ) {
                filteredTasks.push(task)
              }
            }
          }
        }
        if (
          !status &&
          (task.status === 'in-progress' || task.status === 'to-do')
        ) {
          filteredTasks.push(task)
        }
      })
      setFilteredTasks(
        filteredTasks
          .filter(item => {
            const finishedDueDays = moment(item.end, 'DD/MM/YYYY').diff(
              moment(item.user_finished_at, 'DD/MM/YYYY').startOf('days'),
              'days'
            )
            if (typeFinishedDate === TypeTaskFinishedDate.FINISHED_AT) {
              if (finishedDueDays < 0) {
                return false
              }
            }
            if (typeFinishedDate === TypeTaskFinishedDate.OVER_TIME) {
              if (finishedDueDays >= 0) {
                return false
              }
            }

            return true
          })
          .map(oldTask => {
            return {
              ...oldTask,
              open: oldTask.open
            }
          })
      )
      setMyTasks(myTasks)
      setTasksFinishedAtTime(tasksFinishedAtTime)
      setTasksFinishedOverdue(tasksFinishedOverdue)
      setTasksRequestedByMe(tasksRequestedByMe)
      setStatus(status)
      disableLoading()
    },
    [
      activeLoading,
      debouncedInputSearchProducts,
      disableLoading,
      user.id,
      currentTabTask
    ]
  )

  const handlerClickButtonCancellAlertDelete = async () => {
    setAlertDelete({
      id: 0,
      name: '',
      isActive: false
    })
  }

  const handlerClickButtonCancellAlertReopen = async () => {
    setAlertReopen({
      id: 0,
      name: '',
      isActive: false
    })
  }

  const handlerClickButtonConfirmAlertDelete = async (id: string) => {
    activeLoading()
    try {
      await api.delete(apiDelete(id))
      addToast({
        type: 'success',
        title: 'Tarefa removida'
      })
      await getTasks(status)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover tarefa'
      })
    }
    setAlertDelete({
      id: 0,
      name: '',
      isActive: false
    })
    disableLoading()
  }

  const handlerClickButtonConfirmAlertReopen = useCallback(
    async (id: string) => {
      activeLoading()
      try {
        await api.patch(`personelDepartment/tasks/reopen/${id}`, {
          status: 'in-progress'
        })
        addToast({
          type: 'success',
          title: 'Tarefa reaberta'
        })
        await getTasks('finished')
      } catch {
        addToast({
          type: 'error',
          title: 'Erro ao reabrir tarefa.',
          description: 'Por favor, tente novamente!'
        })
      }
      setAlertReopen({
        id: 0,
        name: '',
        isActive: false
      })
      disableLoading()
    },
    [activeLoading, addToast, disableLoading, getTasks]
  )

  const handleToggleTab = useCallback((tab: TaskTabs) => {
    const newUrl = updateQueryParams({
      queryKey: 'owner',
      value: HANDLE_QUERY_VALUE_BY_TAB[tab],
      url: window.location.href
    })
    history.push(newUrl)
    setCurrentTabTask(tab)
    localStorage.setItem(CURRENT_TAB_TASK, `${tab}`)
    // Remove location of this dependency because it causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return history.listen(location => {
      if (!location.pathname.includes('/personelDepartment/tasks/')) {
        localStorage.removeItem(CURRENT_TAB_TASK)
      }
      const query = location.search.replace('?', '').split('&')
      const status = query
        .find(q => q.includes('status='))
        ?.replace('status=', '')
        ?.replace('_', '-')
      const taskID = query
        .find(q => q.includes('id='))
        ?.replace('id=', '')
        ?.replace('_', '-')
      const typeFinishedDate = query
        .find(q => q.includes('type_finished_date='))
        ?.replace('type_finished_date=', '')

      if (status) {
        getTasks(status, taskID, typeFinishedDate)
      }
    })
  }, [getTasks, history, tasks])

  const addQuery = useCallback(
    (key: string, value: string) => {
      const searchParams = new URLSearchParams(reactRouterDomlocation.search)

      searchParams.set(key, value)

      history.push({
        pathname: reactRouterDomlocation.pathname,
        search: searchParams.toString()
      })
    },
    [history, reactRouterDomlocation?.pathname, reactRouterDomlocation?.search]
  )

  const acceptTask = useCallback(
    async (id: number) => {
      activeLoading()
      try {
        await api.patch(`personelDepartment/tasks/accept/${id}`)
        addToast({
          type: 'success',
          title: 'Tarefa aceita'
        })
        await getTasks('to-do')
        addQuery('force_reload', 'true')
      } catch {
        addToast({
          type: 'error',
          title: 'Erro ao aceitar tarefa.',
          description: 'Por favor, tente novamente!'
        })
      }
      disableLoading()
    },
    [activeLoading, addQuery, addToast, disableLoading, getTasks]
  )

  const finishTask = useCallback(
    async (id: number) => {
      activeLoading()
      try {
        await api.patch(`personelDepartment/tasks/status/${id}`, {
          status: 'finished'
        })
        addToast({
          type: 'success',
          title: 'Tarefa finalizada'
        })
        await getTasks('in-progress')
        activeLoading()
        window.location.reload()
      } catch {
        addToast({
          type: 'error',
          title: 'Erro ao finalizar tarefa.',
          description: 'Por favor, tente novamente!'
        })
      }
      disableLoading()
    },
    [activeLoading, addToast, disableLoading, getTasks]
  )

  const finishTaskUser = useCallback(
    async (id: number, task: any) => {
      let type = 'finished'
      if (task.user_finished) {
        type = 're-open'
      }
      activeLoading()
      try {
        await api.patch(`personelDepartment/tasks/finishTaskUser/${id}`, {
          status: 'finished'
        })
        addToast({
          type: 'success',
          title: type === 'finished' ? 'Tarefa concluída' : 'Tarefa reaberta'
        })
        await getTasks('in-progress')
      } catch {
        addToast({
          type: 'error',
          title:
            type === 'finished'
              ? 'Erro ao concluir tarefa.'
              : 'Erro ao reabrir tarefa.',
          description: 'Por favor, tente novamente!'
        })
      }
      disableLoading()
    },
    [activeLoading, addToast, disableLoading, getTasks]
  )

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('users')
      setUsers(data)
    })()
    const status = location.get('status')
    const taskID = location.get('id')
    const owner = location.get('owner')

    getTasks(status?.replace('_', '-'), taskID)
    if (owner === 'me') {
      handleToggleTab(TaskTabs.TASK_REQUESTED_BY_ME)
    } else if (owner === 'other') {
      handleToggleTab(TaskTabs.MY_TASK)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTasks])

  const onSubmitComment = async (data: Comment) => {
    const formData = new FormData()
    formData.append('task_id', String(data.task_id))
    formData.append('message', data.message)
    files.forEach(file => {
      formData.append('files[]', file)
    })
    await api.post('/personelDepartment/comments/create', formData)
    setFiles([])
    const status = location.get('status')
    await getTasks(status?.replace('_', '-'))
    setDefaultValues({ message: '' })
  }
  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFiles(prev => [...prev, file])
  }
  /*   const onSubmitSearch = async (data: any) => {
    const searchResult = await api.post('personelDepartment/tasks/search', data)
    setTasks({ ...tasks, 'Pesquisa Personalizada': searchResult.data })
    setFilteredTasks(searchResult.data)
  }
 */
  return (
    <Container
      breadcrumb={breadcrumbList}
      dashboard={
        <div className="row col-md-12" style={{ left: '-8px' }}>
          <Card
            key={Math.random()}
            number={tasks?.filter(t => t.status === 'to-do').length}
            text={'A FAZER'}
            link={handleGetUrlBox({
              link: '/personelDepartment/tasks/?status=to_do'
            })}
            textFooter=""
            isProgress={0}
            width="col-lg-2 col-md-3 col-sm-6 col-xs-12"
            height="160px"
            footerItems={[
              {
                text: 'MINHA TAREFA',
                color: hasOverDueTasks.myTaskToDo ? '#f36a5a' : undefined,
                subtitle: String(myTasks.toDo),
                link: handleGetUrlBox({
                  link: '/personelDepartment/tasks/?status=to_do&owner=other'
                }),
                target: '_self'
              },
              {
                text: 'TAREFA QUE SOLICITEI',
                color: hasOverDueTasks.taskRequestedByMeToDo
                  ? '#f36a5a'
                  : undefined,
                subtitle: String(tasksRequestedByMe.toDo),
                link: handleGetUrlBox({
                  link: '/personelDepartment/tasks/?status=to_do&owner=me'
                }),
                target: '_self'
              }
            ]}
          />
          <Card
            key={Math.random()}
            number={tasks?.filter(t => t.status === 'in-progress').length}
            text={'EM ANDAMENTO'}
            link={handleGetUrlBox({
              link: '/personelDepartment/tasks/?status=in_progress'
            })}
            textFooter="33"
            isProgress={0}
            width="col-lg-2 col-md-3 col-sm-6 col-xs-12"
            height="160px"
            footerItems={[
              {
                text: 'MINHA TAREFA',
                color: hasOverDueTasks.myTask ? '#f36a5a' : undefined,
                subtitle: String(myTasks.inProgress),
                link: handleGetUrlBox({
                  link: '/personelDepartment/tasks/?status=in_progress&owner=other'
                }),
                target: '_self'
              },
              {
                text: 'TAREFA QUE SOLICITEI',
                color: hasOverDueTasks.taskRequestedByMe
                  ? '#f36a5a'
                  : undefined,
                subtitle: String(tasksRequestedByMe.inProgress),
                link: handleGetUrlBox({
                  link: '/personelDepartment/tasks/?status=in_progress&owner=me'
                }),
                target: '_self'
              },
              tasksReOpen
                ? {
                    text: 'TAREFA REABERTA',
                    subtitle: String(tasksReOpen),
                    link: handleGetUrlBox({
                      link: '/personelDepartment/tasks/?status=re_open&owner=me'
                    }),
                    target: '_self'
                  }
                : undefined
            ]}
          />
          <Card
            key={Math.random()}
            number={tasks?.filter(t => t.status === 'finished').length}
            text={'FINALIZADO'}
            link={handleGetUrlBox({
              link: '/personelDepartment/tasks/?status=finished'
            })}
            textFooter=""
            isProgress={0}
            width="col-lg-2 col-md-3 col-sm-6 col-xs-12"
            height="160px"
            icon={faClipboardList}
            footerItems={finishedFooterItems}
          />
        </div>
      }
    >
      <PageContainer>
        <div
          className="row"
          style={{
            marginBottom: '24px',
            display: 'flex'
          }}
        >
          <div>
            <h3
              className="caption"
              style={{
                color: '#aab5bc',
                fontWeight: 600,
                marginLeft: '15px'
              }}
            >
              {handleGetStatus({
                status
              })}
            </h3>
          </div>

          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginLeft: 'auto',
              width: 'auto'
            }}
          >
            <ContainerInput>
              <label htmlFor="name" className="control-label">
                Pesquisar
              </label>
              <input
                type="text"
                className="form-control"
                value={searchName || ''}
                onChange={event => {
                  const value = event.target.value

                  addQuery('name', value)
                }}
              />
            </ContainerInput>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '24px' }}>
          {status &&
            (currentTabTask === TaskTabs.MY_TASK ||
              status === 're-open' ||
              (status === 'finished' && typeFinishedDate === 'over_time')) && (
              <div
                className="col-md-3"
                style={{
                  maxWidth: 'fit-content',
                  cursor: 'pointer',
                  borderBottom: `${
                    currentTabTask === TaskTabs.MY_TASK
                      ? '1px solid #aab5bc'
                      : 'none'
                  }`
                }}
                onClick={() => handleToggleTab(TaskTabs.MY_TASK)}
              >
                <h4
                  className="caption"
                  style={{
                    color: '#aab5bc',
                    fontWeight: 600,
                    fontSize: '16px'
                  }}
                >
                  MINHA TAREFA
                </h4>
              </div>
            )}
          {status &&
            (currentTabTask === TaskTabs.TASK_REQUESTED_BY_ME ||
              status === 're-open' ||
              (status === 'finished' && typeFinishedDate === 'over_time')) && (
              <div
                className="col-md-3"
                onClick={() => handleToggleTab(TaskTabs.TASK_REQUESTED_BY_ME)}
                style={{
                  maxWidth: 'fit-content',
                  cursor: 'pointer',
                  borderBottom: `${
                    currentTabTask === TaskTabs.TASK_REQUESTED_BY_ME
                      ? '1px solid #aab5bc'
                      : 'none'
                  }`
                }}
              >
                <h4
                  className="caption"
                  style={{
                    fontSize: '16px',
                    color: '#aab5bc',
                    fontWeight: 600
                  }}
                >
                  TAREFA QUE SOLICITEI
                </h4>
              </div>
            )}

          <LinkContainer className="tools">
            <a
              onClick={() => {
                setModal({
                  open: true,
                  type: 'create'
                })
              }}
            >
              <i className="fa fa-plus" style={{ marginRight: '5px' }} />
              <p> Nova tarefa</p>
            </a>
          </LinkContainer>
        </div>
        <div className="row">
          <div className="table-scrollable" style={{ border: 'none' }}>
            <table className="table table-striped table-hover dt-responsive dtr-inline">
              <thead>
                <tr>
                  <th style={{ width: '60px' }}>Nº</th>
                  <th>Data registro</th>
                  <th>
                    {currentTabTask === TaskTabs.MY_TASK
                      ? 'Solicitante'
                      : 'Solicitado'}
                  </th>
                  <th>Setor</th>
                  <th style={{ width: '45%' }}>Tarefa</th>
                  <th style={{ textAlign: 'center' }}>Anexo</th>
                  <th style={{ textAlign: 'center' }}>Prioridade</th>
                  <th style={{ width: '150px' }}>Data finalização</th>
                  <th style={{ textAlign: 'center' }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredTasks.length ? (
                  filteredTasks?.map((task, index) => (
                    <tr
                      key={task.id}
                      style={{
                        color:
                          task.finishedDueDays < 0 && task.status === 'finished'
                            ? '#f36a5a'
                            : task.status === 'finished'
                            ? '#666666'
                            : task.user_finished
                            ? '#009900'
                            : '#666666'
                      }}
                    >
                      <td>{task.id}</td>
                      <td>{task.start}</td>
                      <td>
                        {task.userDemandant.id !== Number(user.id) && (
                          <Avatar
                            key={task.userDemandant.name}
                            src={task.userDemandant.avatar_url || avatar}
                            accepted={'#ffffff'}
                            title={task.userDemandant.name}
                          />
                        )}
                        {task.userDemandant.id === Number(user.id) && (
                          <>
                            {task.taskUsers
                              ?.sort((a, b) => {
                                if (a.responsible === b.responsible) return 0
                                return a.responsible ? -1 : 1
                              })
                              ?.map(ud => (
                                <Avatar
                                  key={ud.id}
                                  src={ud.user.avatar_url || avatar}
                                  accepted={ud.accepted ? '#009900' : '#DB3C30'}
                                  title={ud.user.name}
                                />
                              ))}
                          </>
                        )}
                      </td>
                      <td>{task.userDemandant?.user?.name}</td>
                      <td>
                        <div key={task.id}>
                          <p
                            style={{ marginBottom: '1.5rem', fontSize: '16px' }}
                          >
                            {task.title}
                          </p>
                          {task.open && task.status === 'in-progress' && (
                            <Form
                              onSubmit={onSubmitComment}
                              defaultValues={defaultValues}
                            >
                              <Input
                                name="task_id"
                                value={task.id}
                                hidden
                                controlled
                              />
                              <ReplyInput>
                                <Textarea
                                  placeholder="Digite sua mensagem..."
                                  className="form-control"
                                  name="message"
                                  id="reply"
                                  rows={5}
                                  rules={{ required: true }}
                                ></Textarea>
                                <div className="row">
                                  {files?.map(
                                    file =>
                                      !!file && (
                                        <a
                                          style={{ marginLeft: '20px' }}
                                          key={file.name}
                                        >
                                          <FaPaperclip
                                            style={{ marginRight: '4px' }}
                                          />
                                          {file.name}
                                          {
                                            <a
                                              style={{
                                                padding: '3px 6px',
                                                marginLeft: '3px'
                                              }}
                                              onClick={() => {
                                                setFiles(prev => {
                                                  const copy = [...prev]
                                                  copy.splice(index, 1)
                                                  return copy
                                                })
                                              }}
                                            >
                                              X
                                            </a>
                                          }
                                        </a>
                                      )
                                  )}
                                </div>
                                <div>
                                  <ButtonLabelLight htmlFor="commentAttachment">
                                    Inserir Anexo
                                  </ButtonLabelLight>
                                  <input
                                    type="file"
                                    name="commentAttachment"
                                    id="commentAttachment"
                                    onChange={onChangeFileHandler}
                                    style={{ display: 'none' }}
                                  />
                                  <ButtonLight
                                    type="submit"
                                    className="btn btn-sm sbold uppercase"
                                  >
                                    Enviar
                                  </ButtonLight>
                                </div>
                              </ReplyInput>
                            </Form>
                          )}
                          <div>
                            {task.comments.length ? (
                              <TaskMessage
                                key={Math.random() * 9999} // this line force re-render and show latest comment
                                avatarUrl={task.comments[0]?.user?.avatar_url}
                                date={task.comments[0]?.created_at}
                                userName={task.comments[0]?.user?.name}
                                message={task.comments[0]?.message}
                                newMessage
                                attachments={task.comments[0]?.attachments}
                                taskId={task.id}
                              />
                            ) : (
                              <TaskMessage message={task.description} />
                            )}
                          </div>
                          {task.open && task.status !== 'to-do' && (
                            <>
                              {task.comments
                                ?.filter((c, index) => index)
                                ?.map(comment => (
                                  <TaskMessage
                                    key={comment?.id}
                                    avatarUrl={comment?.user?.avatar_url}
                                    date={comment?.created_at}
                                    userName={comment?.user?.name}
                                    message={comment?.message}
                                    attachments={comment?.attachments}
                                    taskId={task.id}
                                  />
                                ))}
                              <p>
                                {task.comments.length !== 0 && (
                                  <TaskMessage
                                    avatarUrl={task.userDemandant.avatar_url}
                                    date={task.start}
                                    userName={task.userDemandant.name}
                                    message={task.description}
                                    taskId={task.id}
                                  />
                                )}
                              </p>
                            </>
                          )}
                        </div>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {task.attachments?.map(att => (
                          <a
                            key={att.id}
                            onClick={() => {
                              activeLoading()
                              const a = document.createElement('a')
                              api
                                .post(
                                  'personelDepartment/tasks/file',
                                  {
                                    name: FILE_PATH_TASK_ATTACHMENTS({
                                      filename: att.filename,
                                      taskId: att.task_id
                                    })
                                  },
                                  {
                                    responseType: 'arraybuffer'
                                  }
                                )
                                .then(response => {
                                  const blob = new Blob([response.data], {
                                    type: a.type.toLowerCase()
                                  })
                                  const url = window.URL.createObjectURL(blob)
                                  a.href = url
                                  a.download = att.filename.substring(
                                    att.filename.indexOf('-') + 1
                                  )
                                  a.click()
                                  disableLoading()
                                })
                                .catch(() => {
                                  disableLoading()
                                  addToast({
                                    type: 'error',
                                    title: 'Arquivo não encontrado'
                                  })
                                })
                            }}
                          >
                            <FaPaperclip
                              title={att.filename.substring(
                                att.filename.indexOf('-') + 1
                              )}
                            />
                          </a>
                        ))}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {TASK_PRIORITY_ENUM[task.priority]}
                      </td>
                      <td>
                        {task.status === 'finished'
                          ? task.user_finished_at?.substring(0, 10)
                          : task.end}
                        {task.status !== 'finished' &&
                          (task.dueDays < 0 ? (
                            <p>{task.dueDays * -1} dias em atraso</p>
                          ) : (
                            <p>{`resta${task.dueDays !== 1 ? 'm' : ''} ${
                              task.dueDays
                            } dias`}</p>
                          ))}
                      </td>
                      <td>
                        {task.status === 'to-do' ? (
                          <>
                            {task.userDemandant.id !== Number(user.id) &&
                              task.taskUsers.find(
                                taskUser => taskUser.responsible
                              )?.user_id === Number(user.id) && (
                                <span
                                  style={{
                                    marginRight: '10px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    const taskUserId = task.taskUsers.find(
                                      t => t.user.id === Number(user.id)
                                    ).id
                                    acceptTask(taskUserId)
                                  }}
                                >
                                  <FaCheck title="Aceitar tarefa" />
                                </span>
                              )}
                            {task.userDemandant.id === Number(user.id) && (
                              <span
                                style={{
                                  marginRight: '10px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  setModal({
                                    open: true,
                                    type: 'update',
                                    task
                                  })
                                }}
                              >
                                <span className="fa fa-edit" title="Editar" />
                              </span>
                            )}
                            {task.userDemandant.id === Number(user.id) && (
                              <span
                                className="fa fa-remove"
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '10px'
                                }}
                                onClick={() => {
                                  setAlertDelete({
                                    id: task.id,
                                    name: task.title,
                                    isActive: true
                                  })
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {task.status === 'in-progress' ? (
                              <>
                                {task.userDemandant.id === Number(user.id) && (
                                  <span
                                    style={{
                                      marginRight: '10px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={async () => {
                                      await finishTask(task.id)
                                    }}
                                  >
                                    <FaRegThumbsUp title="Finalizar tarefa" />
                                  </span>
                                )}
                                {task.userDemandant.id !== Number(user.id) &&
                                  task.taskUsers.find(
                                    taskUser => taskUser.responsible
                                  )?.user_id === Number(user.id) && (
                                    <span
                                      style={{
                                        marginRight: '10px',
                                        cursor: 'pointer'
                                      }}
                                      onClick={async () => {
                                        await finishTaskUser(task.id, task)
                                      }}
                                    >
                                      {task.user_finished ? (
                                        <FaUndo title="Reabrir tarefa" />
                                      ) : (
                                        <FaRegThumbsUp title="Finalizar tarefa" />
                                      )}
                                    </span>
                                  )}
                                {/* {task.userDemandant.id === Number(user.id) && (
                                  <span
                                    style={{
                                      marginRight: '10px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      setModal({
                                        open: true,
                                        type: 'update',
                                        task
                                      })
                                    }}
                                  >
                                    <span
                                      className="fa fa-edit"
                                      title="Editar"
                                    />
                                  </span>
                                )} */}
                                {task.userDemandant.id === Number(user.id) && (
                                  <span
                                    className="fa fa-remove"
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: '10px'
                                    }}
                                    onClick={() => {
                                      setAlertDelete({
                                        id: task.id,
                                        name: task.title,
                                        isActive: true
                                      })
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {task.userDemandant.id === Number(user.id) && (
                                  <span
                                    style={{
                                      marginRight: '10px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      setAlertReopen({
                                        id: task.id,
                                        name: task.title,
                                        isActive: true
                                      })
                                    }}
                                  >
                                    <FaUndo title="Reabrir tarefa" />
                                  </span>
                                )}
                              </>
                            )}
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setFilteredTasks(prevState => {
                                  const copy = JSON.parse(
                                    JSON.stringify(prevState)
                                  )
                                  copy[index].open = !copy[index].open
                                  return copy
                                })
                              }}
                            >
                              {!task.open ? (
                                <FaChevronDown size={13} />
                              ) : (
                                <FaChevronUp size={13} />
                              )}
                            </span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9}>Sem tarefas no momento</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {modal && (
          <Modal
            Children={
              <FormTask
                typeForm={modal.type}
                setModal={setModal}
                modal={modal}
                getTasks={getTasks}
                users={users?.filter(
                  userData => Number(user?.id) !== Number(userData?.id)
                )}
                initialValues={modal.task}
              />
            }
            isOpenModal={modal.open}
            pageTitle={
              modal.type === 'create' ? 'Nova Tarefa' : 'Editar tarefa'
            }
            onClickButtonCancel={() =>
              setModal({
                open: false,
                type: 'create'
              })
            }
            styles={{ padding: '0px' }}
          />
        )}
        {alertDelete && (
          <Alert
            message={`Tem certeza que deseja excluir a tarefa ${alertDelete.name}?`}
            onClickCancellButton={handlerClickButtonCancellAlertDelete}
            onClickConfirmButton={() => {
              handlerClickButtonConfirmAlertDelete(String(alertDelete.id))
            }}
            isActive={alertDelete.isActive}
          />
        )}
        {alertReopen && (
          <Alert
            message={`Tem certeza que deseja reabrir a tarefa ${alertReopen.name}?`}
            onClickCancellButton={handlerClickButtonCancellAlertReopen}
            onClickConfirmButton={() => {
              handlerClickButtonConfirmAlertReopen(String(alertReopen.id))
            }}
            isActive={alertReopen.isActive}
          />
        )}
      </PageContainer>
    </Container>
  )
}
export default List
