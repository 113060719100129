import React, { useCallback, useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import {
  Input,
  InputMask,
  Select
} from '../../../../../../../components/FormProvider'

import { Container, IconRemove, Panel, ContactItem } from './styles'
import { useClient } from '../../../providers/ClientProvider'
import { phoneMask } from '../../../../../../../utlis/mask'
import { useToast } from '../../../../../../../hooks/toast'
import { Alert } from '../../../../../../../components/Alert'
import { useLoading } from '../../../../../../../hooks/loading'
import api from '../../../../../../../services/api'
import Multiselect from 'react-widgets/Multiselect'
import 'react-widgets/styles.css'
type Sectors = {
  id: number
  name: string
  active: boolean
}

type SectorsOptions = {
  value: number
  name: string
}
const OPTIONS_TYPES_EMAIL = [
  'Nota fiscal, XML ou Fatura',
  'Pix, Boleto, Transferência ou Cartão de crédito',
  // 'Financeiro',
  'E-mail marketing'
]
export const ContactTab = (): JSX.Element => {
  const [sectorsOptions, setSectorsOptions] = useState<SectorsOptions[]>([])

  const { contacts, setContacts, selectedTypesEmail, setSelectedTypes } =
    useClient()
  const { addToast } = useToast()

  const { activeLoading, disableLoading } = useLoading()

  const [alert, setAlert] = useState({
    open: false,
    id: 0,
    index: 0
  })

  const handleLoadSectors = useCallback(async () => {
    try {
      const responseSectors = await api.get<Sectors[]>(
        '/commercial/clientsSectors'
      )
      setSectorsOptions(
        responseSectors.data.map(item => ({ name: item.name, value: item.id }))
      )
    } catch (error: any) {
      addToast({
        title: 'Ocorreu um erro',
        description:
          error.message || 'Ocorreu um erro ao buscar os dados tente novamente'
      })
    }
  }, [addToast])

  useEffect(() => {
    handleLoadSectors()
  }, [handleLoadSectors])

  const handleAlertConfirm = useCallback(async () => {
    if (!alert.id) {
      return
    }
    activeLoading()
    try {
      await api.delete(`commercial/personsContacts/delete/${alert.id}`)
      setContacts(prev => {
        const copy = [...prev]
        copy[alert.index].show = false
        return copy
      })
      disableLoading()
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'erro ao excluir contato'
      })
    }
    setAlert({
      open: false,
      id: 0,
      index: 0
    })
  }, [
    activeLoading,
    addToast,
    alert.id,
    alert.index,
    disableLoading,
    setContacts
  ])
  return (
    <Container className="table-responsive">
      {contacts.map((contactItem, index) => {
        return (
          contactItem &&
          contactItem.show && (
            <ContactItem
              key={contactItem.id}
              className="panel-group"
              id="accordion"
            >
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <div
                      onClick={() =>
                        setContacts((prev: any) => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].open = !copy[index].open
                          return copy
                        })
                      }
                    >
                      {contactItem?.name?.toUpperCase() || 'NOVO CONTATO'}
                      {!contactItem?.open ? (
                        <FaChevronDown size={13} />
                      ) : (
                        <FaChevronUp size={13} />
                      )}
                    </div>
                  </h4>
                </div>
                <Panel
                  id={`${index}`}
                  className={'panel-collapse'}
                  open={contactItem.open}
                  style={{
                    height: contactItem.open ? '350px' : '0px'
                  }}
                >
                  <div className="panel-body" style={{ paddingLeft: '0px' }}>
                    <div className="form-content col-md-11">
                      <div className="row no-margin-top">
                        <div className="form-content col-md-4">
                          <Input
                            label="Nome"
                            name={`contact.${index}.name`}
                            className="form-control"
                            rules={{ required: true }}
                            onChange={e => {
                              setContacts((prev: any) => {
                                const copy = JSON.parse(JSON.stringify(prev))
                                copy[index].name = e.target.value
                                return copy
                              })
                            }}
                            value={contactItem.name}
                          />
                          <Input
                            name={`contact.${index}.id`}
                            style={{ display: 'none' }}
                          />
                        </div>
                        <div className="form-content col-md-4">
                          <Input
                            label="Cargo"
                            name={`contact.${index}.position`}
                            className="form-control"
                          />
                        </div>
                        <div className="form-content col-md-2">
                          <Select
                            label="Setor"
                            name={`contact.${index}.sector_id`}
                            className="form-control"
                            options={sectorsOptions}
                            blank
                            defaultValue=""
                            // rules={{ required: true }}
                          />
                        </div>
                        <div className="form-content col-md-2">
                          <Input
                            label="Telefone fixo"
                            name={`contact.${index}.phone_number`}
                            className="form-control"
                            maxLength={15}
                            onChange={e => {
                              setContacts((prev: any) => {
                                const copy = JSON.parse(JSON.stringify(prev))
                                copy[index].phone_number = phoneMask(
                                  e.target.value
                                )
                                return copy
                              })
                            }}
                            value={contactItem.phone_number}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-content col-md-2">
                          <Input
                            label="Celular principal"
                            name={`contact.${index}.cellphone_number1`}
                            className="form-control"
                            maxLength={15}
                            // rules={{ required: true }}
                            onChange={e => {
                              setContacts((prev: any) => {
                                const copy = JSON.parse(JSON.stringify(prev))
                                copy[index].cellphone_number1 = phoneMask(
                                  e.target.value
                                )
                                return copy
                              })
                            }}
                            value={contactItem.cellphone_number1}
                          />
                        </div>
                        <div className="form-content col-md-2">
                          <InputMask
                            label="celular"
                            name={`contact.${index}.cellphone_number2`}
                            className="form-control"
                            mask={phoneMask}
                            maxLength={15}
                          />
                        </div>
                        <div className="form-content col-md-4 mt-0">
                          <Input
                            style={{ textTransform: 'none' }}
                            label="Email"
                            name={`contact.${index}.email`}
                            className="form-control"
                            onChange={e => {
                              setContacts((prev: any) => {
                                const copy = JSON.parse(JSON.stringify(prev))
                                copy[index].email = e.target.value
                                return copy
                              })
                              const repeated = contacts.find(
                                cont =>
                                  cont.email &&
                                  cont.email === e.target.value &&
                                  cont.id !== contactItem.id
                              )
                              if (repeated) {
                                setContacts((prev: any) => {
                                  const copy = JSON.parse(JSON.stringify(prev))
                                  copy[index].email = ''
                                  return copy
                                })
                                addToast({
                                  type: 'error',
                                  title: `Email ${e.target.value} já em uso.`
                                })
                              }
                            }}
                            value={contactItem.email}
                            // rules={{ required: true }}
                          />
                        </div>

                        <div className="col-md-4 mt-0">
                          <label
                            className="control-label"
                            style={{ fontWeight: 'normal' }}
                          >
                            Tipo de envio de e-mail para o cliente
                          </label>
                          <Multiselect
                            className={`container-multi-select ${
                              selectedTypesEmail[index]?.length
                                ? ''
                                : 'max-height-34px'
                            }`}
                            messages={{
                              emptyList: 'Nenhum resultado encontrado',
                              emptyFilter: 'Nenhum resultado encontrado'
                            }}
                            data={OPTIONS_TYPES_EMAIL}
                            value={selectedTypesEmail[index] || []}
                            onChange={(data: any) => {
                              setSelectedTypes(oldData => {
                                const copy = { ...oldData }
                                copy[index] = data
                                return copy
                              })
                            }}
                          />
                          <Input
                            name={`contact.${index}.type_send_email`}
                            style={{ display: 'none', marginTop: '0' }}
                            value={selectedTypesEmail[index]}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-content col-md-4">
                          <Input
                            label="LinkedIn"
                            name={`contact.${index}.linkedin`}
                            className="form-control"
                          />
                        </div>
                        <div className="form-content col-md-4">
                          <Input
                            label="Instagram"
                            name={`contact.${index}.instagram`}
                            className="form-control"
                          />
                        </div>
                        <div className="form-content col-md-4">
                          <Input
                            label="Facebook"
                            name={`contact.${index}.facebook`}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ textAlign: 'center' }}>
                      <b>Ações</b>
                      <p>
                        <IconRemove
                          onClick={() => {
                            if (contacts.length > 1) {
                              if (contactItem.id) {
                                setAlert({
                                  open: true,
                                  id: contactItem.id,
                                  index
                                })
                              } else {
                                setContacts(prev => {
                                  const copy = [...prev]
                                  copy[index].show = false
                                  return copy
                                })
                              }
                            }
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </Panel>
              </div>
            </ContactItem>
          )
        )
      })}
      <button
        style={{ marginBottom: '10px', marginTop: '10px' }}
        type="button"
        onClick={() =>
          setContacts(prev => {
            const copy = [...prev]
            return [
              ...copy,
              {
                open: true,
                show: true,
                name: ''
              }
            ]
          })
        }
        className="btn dark btn-sm sbold uppercase"
      >
        <span
          className="fa fa-plus"
          aria-hidden="true"
          style={{ marginRight: '5px' }}
        />
        Adicionar
      </button>
      <Alert
        message={'Tem certeza que deseja excluir o registro?'}
        onClickCancellButton={() => {
          setAlert({
            open: false,
            id: 0,
            index: 0
          })
        }}
        onClickConfirmButton={() => {
          handleAlertConfirm()
        }}
        isActive={alert.open}
      />
    </Container>
  )
}
