import React, { createContext, useContext, useState } from 'react'
import { WarehouseRequest, WarehouseRequestProduct } from '../../domain/info'

type Stock = {
  id: number
  name: string
  current_stock?: number
  product?: {
    id: number
    name: string
  }
}

type ProductContextType = {
  warehouseRequests: WarehouseRequest
  setWarehouseRequests: React.Dispatch<React.SetStateAction<WarehouseRequest>>
  warehouseRequestsProducts: WarehouseRequestProduct[]
  setWarehouseRequestsProducts: React.Dispatch<
    React.SetStateAction<WarehouseRequestProduct[]>
  >
  oldWarehouseRequestsProducts: WarehouseRequestProduct[]
  setOldWarehouseRequestsProducts: React.Dispatch<
    React.SetStateAction<WarehouseRequestProduct[]>
  >
  stocks: Stock[]
  setStocks: React.Dispatch<React.SetStateAction<Stock[]>>
}

const ProductContext = createContext<ProductContextType>(
  {} as ProductContextType
)

type ProductProviderParams = {
  children: JSX.Element
}

export const ProductProvider = ({ children }: ProductProviderParams) => {
  const [warehouseRequests, setWarehouseRequests] = useState<WarehouseRequest>()
  const [warehouseRequestsProducts, setWarehouseRequestsProducts] = useState<
    WarehouseRequestProduct[]
  >([{ id: '0', stock_id: '' }])
  const [oldWarehouseRequestsProducts, setOldWarehouseRequestsProducts] =
    useState<WarehouseRequestProduct[]>([])
  const [stocks, setStocks] = useState<Stock[]>([])

  return (
    <ProductContext.Provider
      value={{
        warehouseRequests,
        setWarehouseRequests,
        warehouseRequestsProducts,
        setWarehouseRequestsProducts,
        oldWarehouseRequestsProducts,
        setOldWarehouseRequestsProducts,
        stocks,
        setStocks
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const useProduct = (): ProductContextType => {
  const context = useContext(ProductContext)
  if (!context) throw new Error('Context Product context not found')
  return context
}
