import React, { createContext, useCallback, useContext, useState } from 'react'

interface ExportOptions {
  url: string
  totalItems: number
}

interface DataTableContext {
  isUpdated: boolean
  exportOptions: ExportOptions
  updateDataTable: () => void
  handleSetExportOptions: (data: ExportOptions) => void
}

const DataTableContext = createContext<DataTableContext>({} as DataTableContext)

const DataTableProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [isUpdated, setIsUpdate] = useState<boolean>(false)
  const [exportOptions, setExportOptions] = useState({
    url: '',
    totalItems: 0
  })

  const updateDataTable = useCallback(() => {
    setIsUpdate(!isUpdated)
  }, [isUpdated])

  const handleSetExportOptions = useCallback((data: any) => {
    setExportOptions(data)
  }, [])

  return (
    <DataTableContext.Provider
      value={{
        updateDataTable,
        isUpdated,
        handleSetExportOptions,
        exportOptions
      }}
    >
      {children}
    </DataTableContext.Provider>
  )
}

function useUpdateDataTable(): DataTableContext {
  const context = useContext(DataTableContext)
  if (!context) {
    throw new Error('Updated Provider must be used witin a DataTableProvider')
  }

  return context
}

export { DataTableProvider, useUpdateDataTable }
