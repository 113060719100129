import styled from 'styled-components'

export const ControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  /* background: #fff;
  padding: 15px; */

  .amcharts-labelValue {
    transform: translate(714px, 0) !important;
  }
`
