import React, { useEffect, useState } from 'react'
import { ControlPanel } from './style'
import { TablePortletWithInputs } from '../../../components/TablePortletWithInputs'
import Container from '../../../components/Container'

import { useLocation } from 'react-router'
import { toolsCreate } from './domain/tools'

const ExpenseBudget: React.FC = () => {
  const location = new URLSearchParams(useLocation().search)
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (location.get('edit')) {
      setUpdate(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      pageTitle={'Planejamento de Despesa'}
      portletTitle={update ? 'Editar' : 'Adicionar'}
      breadcrumb={[
        {
          name: 'Início',
          to: '/'
        },
        {
          name: 'Planejamento de Despesa'
        },
        {
          name: update ? 'Editar' : 'Adicionar'
        }
      ]}
      tools={[toolsCreate]}
    >
      <ControlPanel>
        <TablePortletWithInputs
          className=""
          maintitle="Selecione o Centro de Custo"
          subtitle="Quadro de lançamento de valores por Categoria e Subcategoria"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          getUserData={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setInitialData={() => {}}
          realData={[]}
          isHighlight={true}
          isHighlightOver={true}
          lastChildStyle="boldGrey"
          stylesChild={{
            th: {
              classname: 'hasBottomBorder'
            },
            column4: {
              classname: ''
            }
          }}
          hasDropdown={true}
          initialData={[]}
          tableHead={[
            '',
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
            'Total'
          ]}
          setUpdate={setUpdate}
          update={update}
        />
      </ControlPanel>
    </Container>
  )
}

export default ExpenseBudget
