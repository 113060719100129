import React, { useCallback, useEffect, useRef, useState } from 'react'

import logoImg from '../../../assets/logo-multfluxo.png'
import { Contanier, Content } from './styles'
import Form, {
  Input,
  InputMask,
  Select
} from '../../../components/FormProvider'
import { useToast } from '../../../hooks/toast'
import axios from 'axios'
import {
  cnpjMask,
  cpfMask,
  creditCardMask,
  phoneMask,
  zipCodeMask
} from '../../../utlis/mask'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useLoading } from '../../../hooks/loading'
import { useLocation } from 'react-router-dom'
import { Loading } from '../../../components/Loading'

type CardData = {
  number: string
  holder_name: string
  exp_month: number
  exp_year: number
  cvv: string
}
type ClientData = {
  document: string
  name: string
  email: string
  phone: string
}
type PaymentData = {
  installments: number
}
type BillingAddressData = {
  zip_code: number
}

type FormData = {
  card: CardData
  client: ClientData
  payment: PaymentData
  billing_address: BillingAddressData
}

const CreditCardPaymentPage = (): JSX.Element => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const { addToast } = useToast()
  const { activeLoading, disableLoading, loading } = useLoading()

  const [order, setOrder] = useState<any>()
  const [cnpj, setCnpj] = useState('')

  const [zipCode, setZipCode] = useState({
    search: false,
    code: undefined
  })
  const [street, setStreet] = useState<string>()
  const [complement, setComplement] = useState<string>()
  const [number, setNumber] = useState<string>()
  const [district, setDistrict] = useState<string>()
  const [city, setCity] = useState<string>()
  const [uf, setUf] = useState<string>()

  const cardToken = useRef('')
  const token = useRef(searchParams.get('token'))

  const searchZipCode = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${zipCode.code}/json/`
      )
      if (data.erro) {
        addToast({
          type: 'error',
          title: 'CEP inválido',
          description: 'CEP inválido, por favor, tente novamente.'
        })
      }
      if (!data.erro) {
        setStreet(data.logradouro)
        setDistrict(data.bairro)
        setCity(data.localidade)
        setUf(data.uf)
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao consultar CEP',
        description:
          'Erro ao consultar o CEP na api ViaCEP, por favor, tente novamente.'
      })
    }
  }, [addToast, zipCode])

  useEffect(() => {
    if (zipCode?.search && zipCode?.code?.length === 9) {
      activeLoading()
      searchZipCode()
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading, searchZipCode, zipCode])

  const getOrder = useCallback(
    async (orderId: string) => {
      activeLoading()
      try {
        const orderResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/commercial/orders/viewOrderWithInstallments/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${token.current}`
            }
          }
        )
        setOrder(orderResponse.data)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar pedido'
        })
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
      disableLoading()
    },
    [activeLoading, addToast, disableLoading]
  )

  useEffect(() => {
    if (token.current) {
      const { sub } = jwtDecode<JwtPayload>(token.current)

      const orderId = sub.split('#')[3]
      getOrder(orderId)
    }
  }, [getOrder])

  const getCardToken = useCallback(
    async (card: CardData) => {
      try {
        const getTokenResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/financial/pagarMePayments/getCardToken`,
          {
            card
          },
          {
            headers: {
              Authorization: `Bearer ${token.current}`
            }
          }
        )
        cardToken.current = getTokenResponse.data
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Dados do cartão incorretos'
        })
      }
    },
    [addToast]
  )

  const onSubmit = useCallback(
    async (data: FormData) => {
      const cardData = {
        number: data.card.number.replace(/\D/g, ''),
        holder_name: data.card.holder_name,
        exp_month: Number(data.card.exp_month),
        exp_year: Number(data.card.exp_year),
        cvv: data.card.cvv
      }

      if (
        data.client.document.length !== 14 &&
        data.client.document.length !== 18
      ) {
        return
      }
      const documentType = data.client.document.length === 14 ? 'CPF' : 'CNPJ'
      const phoneAreaCode = data.client.phone.substring(1, 3)
      const phoneNumber = data.client.phone.substring(4).replace(/\D/g, '')
      const clientData = {
        name: data.client.name,
        document: data.client.document,
        document_type: documentType,
        email: data.client.email,
        phone_area_code: phoneAreaCode,
        phone_number: phoneNumber
      }

      activeLoading()
      try {
        await getCardToken(cardData)
        console.log('submit data: ', data)
        await axios.post(
          `${process.env.REACT_APP_API_URL}/financial/pagarMePayments/createCreditCard`,
          {
            order_id: order?.id,
            card_token: cardToken.current,
            client: clientData,
            payment: data.payment,
            billing_address: data.billing_address
          },
          {
            headers: {
              Authorization: `Bearer ${token.current}`
            }
          }
        )
        addToast({
          type: 'success',
          title: 'Pagamento processado',
          description: 'Aguarde a confirmação do seu pagamento.'
        })
      } catch (err: any) {
        const message = err?.response?.data?.message
        addToast({
          type: 'error',
          title: 'Erro no pagamento',
          description: message || 'Confira seus dados.'
        })
      }
      disableLoading()
    },
    [activeLoading, addToast, disableLoading, getCardToken, order?.id]
  )

  return (
    <Contanier>
      <Loading isActive={loading} />
      <div className="logo">
        <a href="#ss">
          <img className="logo-light" src={logoImg} alt="" width="200" />
        </a>
      </div>
      <Content>
        <div className="content">
          <div>
            <p>Nº pedido: {order?.id}</p>
            <p>Cliente: {order?.clientNew?.name}</p>
            <p>Valor: R$ {order?.total_value}</p>
            <p>
              Produtos:{' '}
              {order?.products?.map((product: any) => (
                <p key={product.id}>{product.product_name}</p>
              ))}
            </p>
          </div>
          <Form onSubmit={onSubmit}>
            <h3 className="form-title font-dark">Pagamento</h3>
            <div className="row">
              <div className="col-sm-4">
                <InputMask
                  label="Cartão"
                  className="form-control"
                  autoComplete="off"
                  placeholder="1111 1111 1111 1111"
                  name="card.number"
                  mask={creditCardMask}
                  rules={{ required: true }}
                  maxLength={19}
                />
              </div>
              <div className="col-sm-8">
                <Input
                  label="Nome impresso no cartão"
                  className="form-control"
                  autoComplete="off"
                  placeholder="John Doe"
                  name="card.holder_name"
                  rules={{ required: true }}
                  onKeyPress={event => {
                    const regex = /^[0-9]+$/
                    if (regex.test(event.key)) event.preventDefault()
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <Select
                  label="Parcelas"
                  className="form-control"
                  name="payment.installments"
                  options={order?.installmentsOptions || []}
                  blank
                  defaultValue={''}
                  rules={{ required: true }}
                />
              </div>
              <div className="col-sm-2 col-xs-4">
                <Input
                  label="Mês"
                  className="form-control"
                  autoComplete="off"
                  placeholder="01"
                  name="card.exp_month"
                  maxLength={2}
                  rules={{ required: true }}
                  onKeyPress={event => {
                    const regex = /^[0-9]+$/
                    if (!regex.test(event.key)) event.preventDefault()
                  }}
                />
              </div>
              <div className="col-sm-3 col-xs-8">
                <Input
                  label="Ano"
                  className="form-control"
                  autoComplete="off"
                  placeholder="9999"
                  name="card.exp_year"
                  maxLength={4}
                  rules={{ required: true, minLength: 4 }}
                  onKeyPress={event => {
                    const regex = /^[0-9]+$/
                    if (!regex.test(event.key)) event.preventDefault()
                  }}
                />
              </div>
              <div className="col-sm-3 col-xs-4">
                <Input
                  label="CVV"
                  className="form-control"
                  autoComplete="off"
                  placeholder="123"
                  name="card.cvv"
                  rules={{ required: true }}
                  onKeyPress={event => {
                    const regex = /^[0-9]+$/
                    if (!regex.test(event.key)) event.preventDefault()
                  }}
                />
              </div>
            </div>
            <h5>Dados do pagador</h5>
            <div className="row">
              <div className="col-sm-8">
                <Input
                  label="Nome"
                  className="form-control"
                  autoComplete="off"
                  placeholder="John Doe"
                  name="client.name"
                  rules={{ required: true }}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  label="CPF/CNPJ"
                  className="form-control"
                  autoComplete="off"
                  placeholder="999 999 999 99"
                  name="client.document"
                  onChange={e => {
                    if (e.target.value.length <= 14) {
                      setCnpj(cpfMask(e.target.value))
                    } else {
                      setCnpj(cnpjMask(e.target.value))
                    }
                  }}
                  value={cnpj}
                  maxLength={18}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <Input
                  label="Email"
                  type="email"
                  className="form-control"
                  autoComplete="off"
                  placeholder="john@doe.com"
                  name="client.email"
                  rules={{ required: true }}
                />
              </div>
              <div className="col-sm-4">
                <InputMask
                  label="Celular"
                  className="form-control"
                  autoComplete="off"
                  placeholder="(99) 99999 9999"
                  name="client.phone"
                  mask={phoneMask}
                  maxLength={15}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <h5>Endereço de cobrança</h5>
            <div className="row">
              <div className="col-md-4">
                <Input
                  label="CEP"
                  name="billing_address.zip_code"
                  className="form-control"
                  value={zipCode.code}
                  onChange={e => {
                    setZipCode({
                      search: true,
                      code: zipCodeMask(e.target.value)
                    })
                  }}
                  maxLength={9}
                  onKeyPress={event => {
                    const regex = /^[0-9.]+$/
                    if (!regex.test(event.key)) event.preventDefault()
                  }}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Logradouro"
                  name="billing_address.street"
                  className="form-control"
                  value={street}
                  onChange={e => setStreet(e.target.value)}
                  rules={{ required: true }}
                />
              </div>
              <div className="col-md-2">
                <Input
                  label="Número"
                  name="billing_address.number"
                  className="form-control"
                  value={number}
                  onChange={e => setNumber(e.target.value)}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Input
                  label="Complemento"
                  name="billing_address.complement"
                  className="form-control"
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="Bairro"
                  name="billing_address.district"
                  className="form-control"
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="Cidade"
                  name="billing_address.city"
                  className="form-control"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </div>
              <div className="col-md-2">
                <Input
                  label="UF"
                  name="billing_address.state"
                  className="form-control"
                  value={uf}
                  onChange={e => setUf(e.target.value)}
                />
              </div>
            </div>

            <div className="form-actions">
              <button type="submit" className="btn-submit">
                CONCLUIR PAGAMENTO
              </button>
            </div>
          </Form>
        </div>
      </Content>
    </Contanier>
  )
}
export default CreditCardPaymentPage
