import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Form, { Input, Textarea } from '../../../../components/Form'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { ListTitle, Table } from './styles'
import { addHours } from 'date-fns'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utlis/mask'
type Product = {
  id: number
  product_name: string
  quantity: number
  quantity_delivered: number
  damage_quantity: number
  damage_description: string
  value: number
}
type Order = {
  id: number
  products: Product[]
  delivery_date: string
  pick_up_date: string
  total_value: string
  data: string
  products_value: string
  freight_value: string
  picked_up: boolean
  delivered: boolean
  dispatch: boolean
  type: string
  client: {
    name: string
  }
}
const ExpenseBudgetList = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const [order, setOrder] = useState<Order>()
  const [products, setProducts] = useState<Product[]>([])
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const [fileOrder, setFileOrder] = useState<File>()
  function onChangeFileHandlerOrder(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFileOrder(file)
  }

  const [fileChecklist, setFileChecklist] = useState<File>()
  function onChangeFileHandlerChecklist(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]

    setFileChecklist(file)
  }

  const loadOrder = useCallback(async () => {
    try {
      const { data } = await api.get<Order>(`commercial/orders/view/${id}`)
      if (data.type === 'V') {
        addToast({
          type: 'error',
          title: 'Esse pedido não é Locação.',
          description: 'Por favor, tente com um pedido do tipo Locação.'
        })
        setTimeout(() => {
          window.location.href =
            'https://multfluxosistema.com.br/orcamentos/visualizar_agenda'
        }, 3000)
      } else if (data.picked_up) {
        addToast({
          type: 'error',
          title: 'Esse pedido já foi retirado.',
          description:
            'Por favor, tente com um pedido que ainda não foi retirado.'
        })
        setTimeout(() => {
          window.location.href =
            'https://multfluxosistema.com.br/orcamentos/visualizar_agenda'
        }, 3000)
      } else if (!data.dispatch || !data.delivered) {
        addToast({
          type: 'error',
          title: 'Esse não está pronto ou não foi entregue!',
          description: 'Por favor, tente com um pedido que já esteja pronto.'
        })
        setTimeout(() => {
          window.location.href =
            'https://multfluxosistema.com.br/orcamentos/visualizar_agenda'
        }, 3000)
      } else {
        setOrder(data)
        setProducts(data.products)
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar o produto.',
        description:
          'Houve um error ao carregar o produto, tente novamente mais tarde.'
      })
    }
  }, [addToast, id])

  useEffect(() => {
    loadOrder()
  }, [loadOrder])

  const sendOrder = async () => {
    console.log(products)
    activeLoading()
    try {
      const formData = new FormData()
      formData.append('id', id)
      formData.append('products', JSON.stringify(products))
      formData.append('order_signed', fileOrder)
      formData.append('checklist', fileChecklist)
      await api.post('commercial/orders/deliver', formData)
      addToast({
        type: 'success',
        title: 'Pedido atualizado!',
        description: 'O pedido foi atualizado enviados com sucessoo!'
      })
      disableLoading()
      window.location.href =
        'https://multfluxosistema.com.br/orcamentos/visualizar_agenda'
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao dar baixa no pedido.',
        description: 'Houve um erro, tente novamente mais tarde.'
      })
      disableLoading()
    }
  }

  return (
    <Container
      pageTitle={'Pedido'}
      portletTitle={'Baixa de Pedidos'}
      breadcrumb={breadcrumbView}
    >
      <Form onSubmit={sendOrder}>
        <div className="row ml-8">
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Nº Pedido</label>
              <p>{order?.id}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Cliente</label>
              <p>{order?.client?.name}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Data de entrega</label>
              <p>
                {addHours(
                  new Date(
                    `${order?.delivery_date
                      .substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 23:00:00`
                  ),
                  3
                ).getDate()}
                /
                {String(
                  addHours(
                    new Date(
                      `${order?.delivery_date
                        .substring(0, 10)
                        .split('/')
                        .reverse()
                        .join('-')} 23:00:00`
                    ),
                    3
                  ).getMonth() + 1
                ).padStart(2, '0')}
                /
                {addHours(
                  new Date(
                    `${order?.delivery_date
                      .substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 23:00:00`
                  ),
                  3
                ).getFullYear()}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Data de retirada</label>
              <p>
                {addHours(
                  new Date(
                    `${order?.pick_up_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 23:00:00`
                  ),
                  3
                ).getDate()}
                /
                {String(
                  addHours(
                    new Date(
                      `${order?.pick_up_date
                        ?.substring(0, 10)
                        .split('/')
                        .reverse()
                        .join('-')} 23:00:00`
                    ),
                    3
                  ).getMonth() + 1
                ).padStart(2, '0')}
                /
                {addHours(
                  new Date(
                    `${order?.pick_up_date
                      ?.substring(0, 10)
                      .split('/')
                      .reverse()
                      .join('-')} 23:00:00`
                  ),
                  3
                ).getFullYear()}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Tipo</label>
              <p>{order?.type === 'L' ? 'LOCAÇÃO' : 'VENDA'}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Valor produtos</label>
              <p>{genericMaskWithTwoZeroWithPoint(order?.products_value)}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Valor frete</label>
              <p>{order?.freight_value}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="control-label">Valor total</label>
              <p>{order?.total_value}</p>
            </div>
          </div>
        </div>
        <ListTitle>
          <p>Produtos</p>
          <hr style={{ paddingBottom: 10 }} />
        </ListTitle>
        <Table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
          <thead>
            <tr>
              <th>Cod. produto</th>
              <th>Nome</th>
              <th className="col-md-1">Qtd. Locada</th>
              <th className="col-md-1">Qtd. entregue</th>
              <th className="col-md-1">Qtd. avarias</th>
              <th className="col-md-5">Descrição avarias</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((t, index) => (
              <tr key={t.id}>
                <td>{t.id}</td>
                <td>{t.product_name}</td>
                <td>{t.quantity}</td>
                <td>
                  <Input
                    type="text"
                    className="form-control"
                    name="quantity_delivered"
                    id="quantity_delivered"
                    value={t.quantity_delivered}
                    onKeyPress={event => {
                      const regex = /^[0-9.]+$/
                      if (!regex.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={({ target }) => {
                      setProducts(prev => {
                        const copy = JSON.parse(JSON.stringify(prev))
                        copy[index].quantity_delivered = target.value
                        if (
                          Number(copy[index].quantity) < Number(target.value)
                        ) {
                          copy[index].quantity_delivered = copy[index].quantity
                          addToast({
                            type: 'error',
                            title:
                              'Quantidade entregue maior que a quantidade do produto.'
                          })
                        }
                        return copy
                      })
                    }}
                    rules={{ required: true }}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control"
                    name="damage_quantity"
                    id="damage_quantity"
                    value={t.damage_quantity}
                    onKeyPress={event => {
                      const regex = /^[0-9.]+$/
                      if (!regex.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={({ target }) => {
                      setProducts(prev => {
                        const copy = JSON.parse(JSON.stringify(prev))
                        copy[index].damage_quantity = target.value
                        return copy
                      })
                    }}
                  />
                </td>
                <td>
                  <Textarea
                    rows={3}
                    className="form-control"
                    name="damage_description"
                    id="damage_description"
                    value={t.damage_description}
                    onChange={({ target }) => {
                      setProducts(prev => {
                        const copy = JSON.parse(JSON.stringify(prev))
                        copy[index].damage_description = target.value
                        return copy
                      })
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="row">
          <div className="col-md-2" style={{ marginTop: '20px' }}>
            <label htmlFor="order_signed">
              <b>Pedido assinado</b>
            </label>
            <input
              type="file"
              name="order_signed"
              id="order_signed"
              className="form-control"
              onChange={onChangeFileHandlerOrder}
              required
              style={{ marginBottom: '1rem' }}
            />
          </div>
          <div className="col-md-2" style={{ marginTop: '20px' }}>
            <label htmlFor="checklist">
              <b>Checklist</b>
            </label>
            <input
              type="file"
              name="checklist"
              id="checklist"
              className="form-control"
              onChange={onChangeFileHandlerChecklist}
              required
              style={{ marginBottom: '1rem' }}
            />
          </div>
        </div>
        <div className="form-actions right">
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Enviar Pedido
          </Button>
        </div>
      </Form>
    </Container>
  )
}
export default ExpenseBudgetList
