import React, { useCallback, useState } from 'react'
import { DatePicker } from '../DatePicker'
import { Container } from './style'

interface HeaderPortLetProps {
  mainTitle: string
  subTitle?: string
  actions: {
    title: string
    value: string
  }[]
  startIndexAction?: number
  setInitialData?: (date?: string) => Promise<void>
  handleSetDate(date: string): Promise<void>
}

const HeaderPortlet: React.FC<HeaderPortLetProps> = ({
  subTitle,
  mainTitle,
  actions,
  setInitialData,
  handleSetDate,
  startIndexAction
}) => {
  const [selectedItem, setSelectedItem] = useState(
    startIndexAction >= 0 ? startIndexAction : 2
  )
  const [isReset, setIsReset] = useState(false)
  const handleSwitchPeriod = useCallback(
    (index: number) => {
      if (actions[index].title !== 'Período') {
        setIsReset(true)
        if (index === selectedItem) {
          setSelectedItem(startIndexAction >= 0 ? startIndexAction : 2)
          setInitialData(
            actions[startIndexAction >= 0 ? startIndexAction : 2].title
          )
          return
        }
      } else {
        setIsReset(false)
        setSelectedItem(index)

        return
      }
      handleSetDate(actions[index].value)
      setSelectedItem(index)
    },
    [actions, handleSetDate, selectedItem, startIndexAction, setInitialData]
  )

  return (
    <Container className="portlet-title">
      <div className="caption caption-md">
        <i className="icon-bar-chart font-dark hide"></i>
        <span className="caption-subject font-green-steel bold uppercase">
          {mainTitle}
        </span>
        <span className="caption-helper">{subTitle}</span>
      </div>
      <div className="actions">
        <div className="btn-group btn-group-devided" data-toggle="buttons">
          {actions.map((item, index) => {
            if (item.title !== 'Período') {
              return (
                <label
                  className={`btn btn-transparent blue-oleo btn-no-border btn-outline  btn-sm ${
                    index === selectedItem ? 'active' : ''
                  }`}
                  key={item.title}
                >
                  <input
                    type="radio"
                    name="options"
                    className="toggle"
                    id={`option${index + 1}`}
                    onClick={() => handleSwitchPeriod(index)}
                  />
                  {item.title}
                </label>
              )
            } else {
              return (
                <DatePicker
                  setInitialData={setInitialData}
                  initialAction={
                    actions[startIndexAction >= 0 ? startIndexAction : 2].title
                  }
                  handleSwitchPeriod={() => handleSwitchPeriod(index)}
                  handleResetData={() =>
                    setSelectedItem(
                      startIndexAction >= 0 ? startIndexAction : 2
                    )
                  }
                  handleSetDate={handleSetDate}
                  isReset={isReset}
                  key={item.title}
                  className={`btn btn-transparent blue-oleo btn-no-border btn-outline  btn-sm ${
                    index === selectedItem ? 'active' : ''
                  }`}
                />
              )
            }
          })}
        </div>
      </div>
    </Container>
  )
}

export { HeaderPortlet }
