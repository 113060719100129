import React from 'react'
import TaxNcmList from '../../pages/Taxes/Ncm/List'
import TaxNcmCreate from '../../pages/Taxes/Ncm/Create'
import TaxNcmUpdate from '../../pages/Taxes/Ncm/Update'
import TaxNcmView from '../../pages/Taxes/Ncm/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const TaxNcmRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/taxes/taxNcm" exact component={TaxNcmList} />
    <Route path="/taxes/taxNcm/create" component={TaxNcmCreate} exact />
    <Route path="/taxes/taxNcm/view/:id" component={TaxNcmView} exact />
    <Route path="/taxes/taxNcm/update/:id" component={TaxNcmUpdate} exact />
  </CustomSwitch>
)
