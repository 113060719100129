import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'
import api from '../../../../../../services/api'

export const toolsList: ToolsContainerProps[] = [
  {
    name: nameActions.create.name,
    to: nameActions.create.to,
    icon: nameActions.create.icon,
    hasParams: false
  },
  {
    name: 'Agenda',
    to: '/commercial/clientsAppointments/listByUser',
    icon: 'fa fa-calendar',
    hasParams: false
  },
  {
    name: 'Importar',
    to: 'commercial/clients/copy_from_old_table',
    icon: 'fa fa-download',
    hasParams: false,
    handleOnClick: async () => {
      try {
        const data = await api.get('commercial/clients/copy_from_old_table')
        alert(data.data.message)
      } catch (error) {
        alert(error)
      }
    },
    users: ['3']
  }
]
