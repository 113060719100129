import React, { useEffect } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { FormDataProtocol } from '../../domain/protocols'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { useProduct } from '../../providers/product/ProductProvider'
import { TypeComposition } from '../../domain/models/typeProduct'
import { HasCompositionTab } from '../../../../Commercial/Competitors/components/HasComposition'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  valueInput?: object
  competitorsproducts?: TypeComposition[]
  isOpenInModal?: false | IsOpenInModalProps
  typeForm:
    | 'create'
    | {
        idUpdate: number
        inputValue: string
        idParentUpdate?: string
      }
}

interface CompetitorData {
  name: string
  email: string
  telephone: string
  comments: string
  active: boolean
  competitorProducts: TypeComposition[]
}

export const FormCategory = ({
  isOpenInModal,
  typeForm,
  valueInput,
  competitorsproducts
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { composition, setComposition } = useProduct()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (competitorsproducts && competitorsproducts.length > 0) {
      setComposition(competitorsproducts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitorsproducts])

  const onSubmitForm = async (data: FormDataProtocol) => {
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate: CompetitorData = {
            name: data.name,
            comments: data.comments,
            email: data.email,
            telephone: data.telephone,
            active: true,
            competitorProducts: composition
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o concorrente',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate: CompetitorData = {
              name: data.name,
              comments: data.comments,
              email: data.email,
              telephone: data.telephone,
              active: true,
              competitorProducts: composition
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o concorrente',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const id = typeForm.idUpdate
          const dataUpdate: CompetitorData = {
            name: data.name,
            comments: data.comments,
            email: data.email,
            telephone: data.telephone,
            active: !!Number(data.active),
            competitorProducts: composition
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Concorrente atualizado',
              description: 'Concorrente alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o concorrente',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate: CompetitorData = {
            name: data.name,
            comments: data.comments,
            email: data.email,
            telephone: data.telephone,
            active: !!Number(data.active),
            competitorProducts: composition
          }
          const id = typeForm.idUpdate

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Concorrente atualizado',
              description: 'Concorrente alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o concorrente',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={valueInput}>
      <>
        <div className="form-body">
          <div className="row">
            <div className="col-md-4">
              <Input
                name="name"
                className="form-control"
                label="Nome"
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-4">
              <Input
                name="email"
                className="form-control"
                label="Email"
                rules={{ required: true }}
              />
            </div>
            <div className={typeForm === 'create' ? 'col-md-4' : 'col-md-2'}>
              <Input
                name="telephone"
                className="form-control"
                label="Telefone"
                rules={{ required: true }}
              />
            </div>
            {typeForm !== 'create' && (
              <div className="col-md-2">
                <Select
                  name="active"
                  className="form-control"
                  label="Ativo"
                  rules={{ required: true }}
                  defaultValue={1}
                  options={[
                    {
                      name: 'Sim',
                      value: 1
                    },
                    {
                      name: 'Não',
                      value: 0
                    }
                  ]}
                />
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-12">
              <Textarea
                name="comments"
                className="form-control"
                label="Comentários"
                rules={{ required: true }}
                rows={6}
              />
            </div>
          </div>
        </div>
        {isOpenInModal && typeForm === 'create' ? (
          <hr className="divider" />
        ) : (
          <div style={{ margin: '10px 0' }} />
        )}

        <HasCompositionTab />
        <div className="form-actions right">
          {/* {isOpenInModal && (
            <button
              onClick={isOpenInModal.handleOnClose}
              type="reset"
              className="btn btn-default btn-sm sbold uppercase"
            >
              Fechar
            </button>
          )} */}
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        </div>
      </>
    </Form>
  )
}
