import { ToolsContainerProps } from '../../../../../../components/Container'

export const toolsList: ToolsContainerProps[] = [
  {
    name: 'Adicionar',
    to: '/warehouse/products/create',
    icon: 'fa fa-plus',
    hasParams: false
  }
]
