import styled from 'styled-components'

export const LinkContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #666;

  div {
    display: flex !important;
    align-items: center;
  }

  .link {
    margin-left: 5px;
    cursor: pointer;
  }
`
export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  div {
    border-radius: 14px !important;
  }
`
export const Container = styled.div`
  table {
    width: 100%;
  }

  table td {
    max-width: 900px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`
