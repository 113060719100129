import React from 'react'
import PrivateRoutes from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'
import CashFlowAnalysis from '../../pages/Financial/CashFlowAnalysis'

export const CashFlowAnalysisRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/financial/cashflowanalysis"
      component={CashFlowAnalysis}
      exact
    />
  </CustomSwitch>
)
