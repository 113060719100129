import React, { useCallback, useState } from 'react'
import { DatePicker } from '../DatePicker'
import { Container } from './style'
interface UsersProps {
  name: string
  avatar_url: string
  goalAccomplished: number
  cases: number
  closed: number
  rate: number
}
interface HeaderPortLetProps {
  mainTitle: string
  subTitle?: string
  actions: any[]
  activeMembersData: UsersProps[]
  handleSetDate(date: string, update?: boolean): void
}

const HeaderPortlet: React.FC<HeaderPortLetProps> = ({
  subTitle,
  mainTitle,
  actions,
  handleSetDate
  // activeMembersData
}) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const [isReset, setIsReset] = useState(false)
  // const [selectedUser, setSelectedUser] = useState('')
  // const handleGetUser = useCallback(
  //   (username: string) => {
  //     if (username === selectedUser) {
  //       setSelectedUser('')
  //       setInitialData()
  //     } else {
  //       setSelectedUser(username)
  //       getUserData()
  //     }
  //   },
  //   [getUserData, selectedUser, setInitialData]
  // )
  const handleSwitchPeriod = useCallback(
    (index: number) => {
      if (actions[index] !== 'Selecione o Mês') {
        setIsReset(true)
        if (index === selectedItem) {
          setSelectedItem(0)
          return
        }
      } else {
        setIsReset(false)
        setSelectedItem(index)

        return
      }
      handleSetDate(actions[index] === 'Mês Atual' ? 'Mes' : actions[index])
      setSelectedItem(index)
    },
    [actions, handleSetDate, selectedItem]
  )

  return (
    <Container className="portlet-title">
      <div className="caption caption-md">
        <i className="icon-bar-chart font-dark hide"></i>
        <span className="caption-subject font-green-steel bold uppercase">
          {mainTitle}
        </span>
        <span className="caption-helper">{subTitle}</span>
      </div>
      <div className="actions">
        <div className="btn-group btn-group-devided" data-toggle="buttons">
          {actions.map((item, index) => {
            if (item !== 'Selecione o Mês') {
              return (
                <label
                  className={`btn btn-transparent blue-oleo btn-no-border btn-outline  btn-sm ${
                    index === selectedItem ? 'active' : ''
                  }`}
                  key={item}
                >
                  <input
                    type="radio"
                    name="options"
                    className="toggle"
                    id="option1"
                    onClick={() => handleSwitchPeriod(index)}
                  />
                  {item}
                </label>
              )
            } else {
              return (
                <DatePicker
                  // handleSwitchPeriod={() => setSelectedItem(index)}
                  handleSwitchPeriod={() => handleSwitchPeriod(index)}
                  handleResetData={() => setSelectedItem(0)}
                  handleSetDate={handleSetDate}
                  isReset={isReset}
                  key={item}
                  className={`btn btn-transparent blue-oleo btn-no-border btn-outline  btn-sm ${
                    index === selectedItem ? 'active' : ''
                  }`}
                />
              )
            }
          })}
        </div>
      </div>
    </Container>
  )
}

export { HeaderPortlet }
