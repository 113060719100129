import React, { useCallback, useEffect, useState } from 'react'
import { ControlPanel } from './style'
import { BoardOfMembers } from './components/BoardOfMembers'
import { HeaderPortlet } from './components/HeaderPortlet'
import Container from '../../../components/Container'
import api from '../../../services/api'
import { useLoading } from '../../../hooks/loading'
import currency from 'currency.js'
import { FaUserCircle } from 'react-icons/fa'
import { useAuth } from '../../../hooks/auth'
import { genericMaskWithTwoZeroWithPoint } from '../../../utlis/mask'
import { useToast } from '../../../hooks/toast'
import { Alert } from '../../../components/Alert'

const CommissionRelease: React.FC = () => {
  const { user } = useAuth()
  const [activeMembers, setActiveMembers] = useState([])
  const [yearMonth, setYearMonth] = useState('')
  const [selectedDate, setSelectedDate] = useState('Mes')
  const [financialModalIsOpen, setFinancialModalIsOpen] = useState(false)

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const BRL_FOUR_DIGITS = (value: any) =>
    currency(value, {
      precision: 4,
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const saveData = useCallback(
    async (activeMembers?: any[]) => {
      const goalsEmployees = activeMembers.map(goals => {
        return {
          id: goals.id,
          name: goals.fullName,
          employee_id: goals.employee_id,
          goal_id: goals.goal_id,
          month: goals.month,
          goal_achieved: BRL(goals?.goalAchieved).value,
          commission_percentage: BRL_FOUR_DIGITS(goals?.commissionPercentage)
            .value,
          commissionField: goals?.commissionField,
          generated_commission: BRL(goals.commission).value,
          adicional_award: BRL(goals.adicionalAward).value,
          total_commission: BRL(goals.total).value,
          financial: goals.financial,
          orders: goals?.orders?.split?.(',').filter((o: string) => o),
          due_date: activeMembers[0].due_date,
          order_type: goals.order_type
        }
      })
      try {
        await api.put('commercial/goalsEmployees/updateCommission', {
          goalsEmployees
        })
        addToast({
          type: 'success',
          title: 'Comissões atualizadas',
          description: 'Comissões alteradas com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar as comissões',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    },
    [addToast]
  )

  const getPercentageByRange = useCallback(
    (
      goalAchieved: string,
      goal: currency,
      goalDivisor: string,
      ranges: any[],
      manager: boolean,
      type: string
    ) => {
      const percentageAchieved = BRL(goalAchieved)
        .divide(goal.divide(genericMaskWithTwoZeroWithPoint(goalDivisor)))
        .format()

      const mod = percentageAchieved.substring(0, percentageAchieved.length - 1)
      const counts = ranges.map(
        (r: { range_percentage: any }) => Number(r.range_percentage) / 100
      )
      const closest = counts.reduce(function (prev: number, curr: number) {
        return Math.abs(curr - BRL(mod).value) < Math.abs(prev - BRL(mod).value)
          ? curr
          : prev
      })

      const key = manager
        ? 'commission_percentage_coordinator'
        : 'commission_percentage_seller'
      const percentage = ranges.find(
        r => Number(r.range_percentage) / 100 === closest && r.type === type
      )?.[key]

      return BRL_FOUR_DIGITS(percentage.replace('.', ',')).format()
    },
    []
  )

  const loadData = useCallback(
    async (date?: string) => {
      activeLoading()
      const selectDate = date || 'Mes'
      let selectPeriod: any
      if (selectDate === 'Mes') {
        selectPeriod = 'month'
      }
      const order = await api.get(
        `/commercial/goalsEmployees/Mes/${
          user.role_id === 1 ? undefined : user.employee.agent_id
        }`
      )
      order.data.agentGraphicData.forEach((manager: { team: any[] }) => {
        manager.team.sort((a, b) => {
          if (a.employee_name < b.employee_name) {
            return -1
          }
          if (a.last_nom > b.last_nom) {
            return 1
          }
          return 0
        })
      })
      const rangesResponse = await api.get('/commercial/goalsRangesCommissions')
      const goalDivisor = rangesResponse.data.goal_divisor
      const ranges = rangesResponse.data.goalsRangesCommissions

      const data = order.data.agentGraphicData
        ?.flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)
      const dia1 = new Date(`${order.data.year}-${order.data.month}-1`).getDay()
      const monthIndex = new Date().getMonth() + 1
      const year = new Date().getFullYear()
      const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`
      const dueDate = `${year}-${month}-05`
      // if (dia1 === 7) {
      //   dueDate = `${year}-${month}-06`
      // } else {
      //   dueDate = `${year}-${month}-07`
      // }
      setYearMonth(dueDate)

      const members = data.map((item: any) => {
        const splitedName = item.employee_name.split(' ')
        const name = `${splitedName[0]} ${splitedName[splitedName.length - 1]}`
        const exist = order.data.goalsEmployees.find(
          (goal: { employee_id: any; month: any; goal_id: any }) =>
            goal.employee_id === item.employee_id &&
            goal.month === order.data.month &&
            goal.goal_id === order.data.goal_id
        )
        const type =
          item.employee_id === 116 || item.manager_id === 116
            ? 'sale'
            : 'location'

        if (item.total_team) {
          const goalAchieved = exist?.financial_moviment_id
            ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
            : BRL(item.total_team[selectPeriod].total_profit || 0).format()

          const commissionPercentage = getPercentageByRange(
            goalAchieved,
            BRL(item.total_team[selectPeriod]?.goal || 0),
            goalDivisor,
            ranges,
            true,
            type
          )
          let commission = BRL_FOUR_DIGITS(goalAchieved)
            .multiply(BRL_FOUR_DIGITS(commissionPercentage))
            .divide(100)
            .format()
          commission = BRL(commission).format()

          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item.total_team[selectPeriod]?.goal || 0).format(),
            goalAchieved,
            commissionPercentage: exist?.financial_moviment_id
              ? BRL_FOUR_DIGITS(
                  exist?.commission_percentage?.replace('.', ',')
                ).format()
              : BRL_FOUR_DIGITS(commissionPercentage).format(),
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : commission,
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) || '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(commission)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item.total_team[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: type === 'sale' ? 'V' : 'L',
            manager_id: item.manager_id
          }
        } else {
          const goalAchieved = exist?.financial_moviment_id
            ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
            : BRL(item[selectPeriod]?.total_profit || 0).format()

          const commissionPercentage = getPercentageByRange(
            goalAchieved,
            BRL(item[selectPeriod]?.goal || 0),
            goalDivisor,
            ranges,
            false,
            type
          )
          let commission = BRL_FOUR_DIGITS(goalAchieved)
            .multiply(BRL_FOUR_DIGITS(commissionPercentage))
            .divide(100)
            .format()
          commission = BRL(commission).format()

          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item[selectPeriod]?.goal || 0).format(),
            goalAchieved,
            commissionPercentage: exist?.financial_moviment_id
              ? BRL_FOUR_DIGITS(
                  exist?.commission_percentage?.replace('.', ',')
                ).format()
              : BRL_FOUR_DIGITS(commissionPercentage).format(),
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : commission,
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) || '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(commission)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: type === 'sale' ? 'V' : 'L',
            manager_id: item.manager_id
          }
        }
      })
      setActiveMembers(members)
      await saveData(members)
      disableLoading()
    },
    [
      activeLoading,
      disableLoading,
      saveData,
      user?.role_id,
      user?.employee?.agent_id,
      getPercentageByRange
    ]
  )

  const handleSetDate = useCallback(
    async (date?: string, update = true) => {
      setSelectedDate(date)
      const selectDate = date
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      activeLoading()
      const order = await api.get(
        `/commercial/goalsEmployees/${date}/${
          user.role_id === 1 ? undefined : user.employee.agent_id
        }`
      )
      order.data.agentGraphicData.forEach((manager: { team: any[] }) => {
        manager.team.sort((a, b) => {
          if (a.employee_name < b.employee_name) {
            return -1
          }
          if (a.last_nom > b.last_nom) {
            return 1
          }
          return 0
        })
      })
      const rangesResponse = await api.get('/commercial/goalsRangesCommissions')
      const goalDivisor = rangesResponse.data.goal_divisor
      const ranges = rangesResponse.data.goalsRangesCommissions

      const data = order.data.agentGraphicData
        ?.flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)
      const dia1 = new Date(`${order.data.year}-${order.data.month}-1`).getDay()
      const monthIndex = new Date().getMonth() + 1
      const year = new Date().getFullYear()
      const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`
      const dueDate = `${year}-${month}-05`
      // if (dia1 === 7) {
      //   dueDate = `${year}-${month}-06`
      // } else {
      //   dueDate = `${year}-${month}-07`
      // }
      setYearMonth(dueDate)
      const members = data.map((item: any) => {
        const splitedName = item.employee_name.split(' ')
        const name = `${splitedName[0]} ${splitedName[splitedName.length - 1]}`
        const exist = order.data.goalsEmployees.find(
          (goal: { employee_id: any; month: any; goal_id: any }) =>
            goal.employee_id === item.employee_id &&
            goal.month === order.data.month &&
            goal.goal_id === order.data.goal_id
        )
        const type =
          item.employee_id === 116 || item.manager_id === 116
            ? 'sale'
            : 'location'

        if (item.total_team) {
          const goalAchieved =
            Number(exist?.financial_moviment_id) > 0
              ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
              : BRL(item.total_team[selectPeriod]?.total_profit || 0).format()

          const commissionPercentage = getPercentageByRange(
            goalAchieved,
            BRL(item.total_team[selectPeriod]?.goal || 0),
            goalDivisor,
            ranges,
            true,
            type
          )
          let commission = BRL_FOUR_DIGITS(goalAchieved)
            .multiply(BRL_FOUR_DIGITS(commissionPercentage))
            .divide(100)
            .format()
          commission = BRL(commission).format()

          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item.total_team[selectPeriod]?.goal || 0).format(),
            goalAchieved,
            commissionPercentage: exist?.financial_moviment_id
              ? BRL_FOUR_DIGITS(
                  exist?.commission_percentage?.replace('.', ',')
                ).format()
              : BRL_FOUR_DIGITS(commissionPercentage).format(),
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : commission,
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) || '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(commission)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item.total_team[selectPeriod]?.orders || '',
            due_date: dueDate,
            order_type: type === 'sale' ? 'V' : 'L',
            manager_id: item.manager_id
          }
        } else {
          const goalAchieved = exist?.financial_moviment_id
            ? genericMaskWithTwoZeroWithPoint(exist?.goal_achieved)
            : BRL(item[selectPeriod]?.total_profit || 0).format()

          const commissionPercentage = getPercentageByRange(
            goalAchieved,
            BRL(item[selectPeriod]?.goal || 0),
            goalDivisor,
            ranges,
            false,
            type
          )
          let commission = BRL_FOUR_DIGITS(goalAchieved)
            .multiply(BRL_FOUR_DIGITS(commissionPercentage))
            .divide(100)
            .format()
          commission = BRL(commission).format()

          return {
            ...item,
            id: exist?.id,
            name,
            fullName: item.employee_name,
            avatar_url: FaUserCircle,
            goal_id: order.data.goal_id,
            month: order.data.month,
            goal: BRL(item[selectPeriod]?.goal || 0).format(),
            goalAchieved,
            commissionPercentage: exist?.financial_moviment_id
              ? BRL_FOUR_DIGITS(
                  exist?.commission_percentage?.replace('.', ',')
                ).format()
              : BRL_FOUR_DIGITS(commissionPercentage).format(),
            commission: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.generated_commission)
              : commission,
            adicionalAward:
              genericMaskWithTwoZeroWithPoint(exist?.adicional_award) || '0,00',
            total: exist?.financial_moviment_id
              ? genericMaskWithTwoZeroWithPoint(exist?.total_commission)
              : BRL(commission)
                  .add(
                    genericMaskWithTwoZeroWithPoint(exist?.adicional_award) ||
                      '0,00'
                  )
                  .format(),
            financial: false,
            financial_moviment_id: exist?.financial_moviment_id,
            orders: item[selectPeriod]?.orders,
            due_date: dueDate,
            order_type: type === 'sale' ? 'V' : 'L',
            manager_id: item.manager_id
          }
        }
      })
      setActiveMembers(members)
      if (update) {
        await saveData(members)
      }

      disableLoading()
    },
    [
      activeLoading,
      user?.role_id,
      user?.employee?.agent_id,
      disableLoading,
      getPercentageByRange,
      saveData
    ]
  )

  useEffect(() => {
    activeLoading()
    loadData()
  }, [activeLoading, loadData])

  async function handleFinancialConfirmButton() {
    activeLoading()
    try {
      await saveData(activeMembers)
      await handleSetDate(selectedDate, false)
      setFinancialModalIsOpen(false)
    } catch (error) {
      setFinancialModalIsOpen(false)
    }
    disableLoading()
  }

  async function handleSaveButton() {
    let hasFinancial = false
    activeMembers.forEach(goals => {
      if (goals.financial) {
        hasFinancial = true
      }
    })
    if (hasFinancial) {
      setFinancialModalIsOpen(true)
      return
    }
    activeLoading()
    await saveData(activeMembers)
    disableLoading()
    handleSetDate(selectedDate, false)
  }

  return (
    <>
      <Container
        pageTitle={'Lançamento de comissão por range'}
        portletTitle={''}
        breadcrumb={[
          {
            name: 'Início',
            to: '/'
          },
          {
            name: 'comercial'
          },
          {
            name: 'Lançamento de comissão por range'
          }
        ]}
        tools={[]}
      >
        <ControlPanel>
          <div
            className="row"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <HeaderPortlet
              activeMembersData={activeMembers}
              handleSetDate={handleSetDate}
              actions={['Mês Atual', 'Selecione o Mês']}
              mainTitle=""
            />
          </div>
          <BoardOfMembers
            activeMembersData={activeMembers}
            setActiveMembersData={setActiveMembers}
            handleSetDate={handleSetDate}
            selectedDate={selectedDate}
            tableHead={[
              {
                colSpan: 2,
                name: 'Membro'
              },
              {
                name: 'Meta'
              },
              {
                name: 'Meta Realizada'
              },
              {
                name: '% Comissão Meta Realizada ',
                colSpan: 2
              },
              {
                name: 'Comissão'
              },
              {
                name: 'Premiação Adicional'
              },
              {
                name: 'Total'
              },
              {
                name: 'Financeiro'
              }
            ]}
          />
          {activeMembers.length > 0 && (
            <div
              className="col-md-12"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <button
                className="btn dark btn-sm bold uppercase"
                onClick={handleSaveButton}
              >
                Salvar
              </button>
            </div>
          )}
        </ControlPanel>
      </Container>
      <Alert
        RenderComponent={() => (
          <>
            <h4>Deseja realmente lançar esses valores no financeiro? </h4>
            <br />
            {activeMembers?.map(
              ({ name, financial, total }) =>
                financial && (
                  <div key={name}>
                    <p key={name}>
                      {name} - R$ {total}
                    </p>
                  </div>
                )
            )}
            <div
              style={{
                display: 'flex',
                marginBottom: '1em',
                alignItems: 'baseline',
                marginTop: '1em'
              }}
            >
              <label htmlFor="date">Data de pagamento</label>
              <input
                style={{ marginLeft: '8px', width: '200px' }}
                className="form-control"
                type="date"
                name="date"
                id="date"
                defaultValue={yearMonth}
                onChange={e => {
                  const members = [...activeMembers]
                  members[0].due_date = e.target.value
                }}
              />
            </div>
          </>
        )}
        onClickCancellButton={() => setFinancialModalIsOpen(false)}
        onClickConfirmButton={handleFinancialConfirmButton}
        isActive={financialModalIsOpen}
      />
    </>
  )
}

export default CommissionRelease
