import React from 'react'
import List from '../../pages/Financial/Investments/List'
import Update from '../../pages/Financial/Investments/Update'
import Create from '../../pages/Financial/Investments/Create'
import View from '../../pages/Financial/Investments/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const InvestmentsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/investments" exact component={List} />
    <Route path="/financial/investments/update/:id" component={Update} exact />
    <Route path="/financial/investments/create" component={Create} exact />
    <Route path="/financial/investments/view/:id" component={View} exact />
  </CustomSwitch>
)
