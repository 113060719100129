import React from 'react'
import GoalsRangesCommissions from '../../pages/Commercial/GoalsRangesCommissions'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const GoalsRangesCommissionsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/commercial/goalsRangesCommissions/update"
      component={GoalsRangesCommissions}
      exact
    />
  </CustomSwitch>
)
