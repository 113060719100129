type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'WarehouseRequest'
const nameSource = 'warehouse/warehouseRequests'
const namePageTitle = 'Almoxarifado requisições'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Cadastro',
  to: '/warehouse/warehouseRequests',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/warehouse/warehouseRequests/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/warehouse/warehouseRequests/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Atualizar',
    to: '/warehouse/warehouseRequests/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

type Situation = 'new' | 'separate' | 'delivered' | 'canceled'
type Priority = 'normal' | 'high' | 'urgent'

export type WarehouseRequest = {
  id: number
  user_id: number
  description?: string
  delivery_date?: string
  situation: Situation
  token: string
  priority: Priority
  quantity: number
  user: {
    id: number
    name: string
  }
  warehouseRequestsProducts?: WarehouseRequestProduct[]
  warehouseRequestsLogs?: WarehouseRequestLog[]
  created_at: string
  updated_at: string
}

export type WarehouseRequestLog = {
  id: number
  date: Date
  previous_situation: Situation
  new_situation: Situation
  user: { name: string }
  description?: string
}

export type WarehouseRequestProduct = {
  id: string
  product_id?: number
  product_name?: string
  stock_id: string
  current_stock?: number
  stock?: {
    current_stock?: number
    name: string
    product: {
      id: number
      name: string
    }
  }
  quantity?: string
  delivered_quantity?: number
  stock_moviment_id?: number
}
const priorities = [
  { name: 'NORMAL', value: 'normal' },
  { name: 'ALTA', value: 'high' },
  { name: 'URGENTE', value: 'urgent' }
]

const situations = [
  { name: 'REQUISIÇÃO DE MATERIAL', value: 'new' },
  { name: 'SEPARADO', value: 'separate' },
  { name: 'ENTREGUE', value: 'delivered' },
  { name: 'CANCELADO', value: 'canceled' }
]

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions,
  priorities,
  situations
}
