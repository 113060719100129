import styled from 'styled-components'

export const FilterContaier = styled.div`
  width: 100%;
  padding-left: 0;
  float: none;

  label {
    font-weight: 600;
  }
  select,
  input {
    font-size: 13;
    padding: 5;
  }

  button {
    margin-bottom: 14px;
    padding: 0.5rem 1rem;

    background: #fff;
    border: solid 1px #ccc;
    font-size: 12px;
    transition: all 0.2s;

    &:hover {
      background: #f2f2f2;
    }
  }

  hr {
    margin-top: 10px !important;
  }
`
export const ListTitle = styled.div`
  p {
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 10px;
    color: #666;
  }

  hr {
    margin-bottom: 10px;
  }
`
export const DatatableWrapper = styled.div`
  a:not(:last-child) {
    color: red;
    opacity: 0.8;
  }
`

export const Table = styled.table`
  td,
  th {
    text-align: left !important;
  }
  td:first-child,
  th:first-child {
    text-align: center !important;
    padding: 10px 25px !important;
    width: 10px !important;
  }
  td:last-child {
    text-align: center !important;
  }
  input[type='checkbox'] {
    display: block;
    margin: 0px 20px;
  }
  td > div {
    margin: 0px;
  }
`
