import React, { useCallback, useEffect, useState } from 'react'

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import FullCalendar from '@fullcalendar/react' // must go before plugins
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction' // needed for dayClick
import { Container } from './styles'
import moment from 'moment'
import Modal from '../../../../../../components/Modal'
import { FormAppointment } from '../../../../Agent/components/AppointmentForm'
import api from '../../../../../../services/api'
import { useClient } from '../../providers/ClientProvider'
import {
  APPOINTMENT_ACTION_COLOR_MAP,
  APPOINTMENT_ACTION_MAP,
  Appointment,
  ModalProps
} from '../../../../Agent/Appointments'
import { useLoading } from '../../../../../../hooks/loading'
import { useAuth } from '../../../../../../hooks/auth'
import { TooltipText } from '../../../../../../components/TooltipText'

type Event = {
  id: string
  date: string
  title: string
  extendedProps: Appointment
}

export default function ScheduleTab() {
  const { initialValues } = useClient()

  const { user } = useAuth()
  const [clientAppointments, setClientAppointments] = useState<Appointment[]>(
    []
  )
  const [events, setEvents] = useState<Event[]>([])

  const [modal, setModal] = useState<ModalProps>({
    open: false
  })

  const { activeLoading, disableLoading } = useLoading()

  const getClientAppointments = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get<Appointment[]>(
        `commercial/clientsAppointments/listByClient/${initialValues.client.id}`
      )
      setClientAppointments(data)
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading, initialValues.client.id])

  useEffect(() => {
    getClientAppointments()
  }, [getClientAppointments])

  useEffect(() => {
    const events = clientAppointments.map(appointment => {
      const dateFormated = moment(appointment.date, 'DD/MM/YYYY HH:mm:ss')
        .add(3, 'h')
        .format('YYYY-MM-DD')
      let backgroundColor = APPOINTMENT_ACTION_COLOR_MAP[appointment.action]

      if (
        moment().startOf('D').isAfter(moment(dateFormated).startOf('D')) &&
        !appointment.appointment_result
      ) {
        backgroundColor = 'red'
      }

      return {
        id: String(appointment.id),
        title: `[${APPOINTMENT_ACTION_MAP[appointment.action]}] ${
          appointment.user?.name
        } - ${appointment.description}`,
        date: dateFormated,
        backgroundColor: backgroundColor,
        extendedProps: { ...appointment, date: dateFormated },
        borderColor: '#fff'
      }
    })
    setEvents(events)
  }, [clientAppointments])

  const week = document.getElementsByClassName('fc-dayGridWeek-button')[0]
  const month = document.getElementsByClassName('fc-dayGridMonth-button')[0]
  const controls = document.getElementsByClassName('fc-button')

  if (controls) {
    Array.from(controls).forEach(control => {
      control.setAttribute('style', 'background: #444444')
    })
  }

  if (week) {
    week.setAttribute(
      'style',
      'width: 85px;overflow: hidden;white-space: nowrap; word-spacing: 20px; background: #444444'
    )
    week.textContent = 'Semana '
  }
  if (month) {
    month.setAttribute(
      'style',
      'width: 55px;overflow: hidden;white-space: nowrap; word-spacing: 20px; background: #444444'
    )
    month.innerHTML = 'Mês '
  }

  const handleDateClick = (click: DateClickArg) => {
    if (moment().startOf('D').isAfter(moment(click.dateStr).startOf('D'))) {
      return
    }
    setModal({
      open: true,
      type: 'create',
      initialValues: {
        client: { id: initialValues.client.id },
        date: click.dateStr,
        description: '',
        appointment_result: '',
        time: ''
      }
    })
  }
  return (
    <>
      <Container>
        <div
          className="row"
          style={{ padding: '0px 4rem', textTransform: 'uppercase' }}
        >
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            locale="pt"
            initialView="dayGridMonth"
            timeZone="-03:00"
            events={events}
            eventContent={info => (
              <div
                className="fc-event-main-frame"
                onClick={() => {
                  const extendedProps = info.event.extendedProps as Appointment
                  if (extendedProps.user.id !== Number(user.id)) {
                    return
                  }
                  setModal({
                    open: true,
                    type: 'update',
                    initialValues: extendedProps
                  })
                }}
              >
                <div className="fc-event-title-container">
                  <span
                    style={{
                      textDecoration: 'none',
                      whiteSpace: 'normal',
                      margin: '5px 0px',
                      color: '#fff'
                    }}
                    className="fc-event-title fc-sticky"
                  >
                    {info.event.extendedProps.appointment_result ? (
                      <TooltipText
                        label={info.event.title}
                        message={info.event.extendedProps.appointment_result}
                      />
                    ) : (
                      info.event.title
                    )}
                  </span>
                </div>
              </div>
            )}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridWeek,dayGridMonth'
            }}
            dateClick={handleDateClick}
            viewHeight="100px"
            contentHeight={'70vh'}
          />
        </div>
      </Container>
      {modal.open && (
        <Modal
          isOpenModal={modal.open}
          pageTitle="Agendamento"
          onClickButtonCancel={() => {
            setModal({
              open: false
            })
          }}
          Children={
            <FormAppointment
              typeForm={modal.type}
              initialValues={modal.initialValues}
              setModal={setModal}
              getAppointments={getClientAppointments}
            />
          }
        />
      )}
    </>
  )
}
