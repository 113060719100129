import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import { useClient } from '../../providers/ClientProvider'
import DataTable from '../../../../../../components/DataTable'
import { nameEntity, nameSource } from './domain/info'
import { headers } from './domain/headers'
import moment from 'moment'
import { FaPrint } from 'react-icons/fa'
import api from '../../../../../../services/api'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'

export const FinancialTransactionsTab = (): JSX.Element => {
  const { initialValues } = useClient()
  const [id, setId] = useState()
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  useEffect(() => {
    if (initialValues?.client.id) {
      setId(initialValues.client.id)
    }
  }, [initialValues])

  return (
    <Container>
      {id && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          orderByField="due_date"
          orderBySort="DESC"
          notHasChildren
          headers={headers}
          searchParameters={[{ client_id: id }]}
          customHeaders={[
            {
              field: 'due_date',
              name: 'Data de vencimento',
              sortable: false,
              element: item =>
                item.due_date && (
                  <span>
                    {moment(item.due_date, 'DD/MM/YYYY HH:mm:ss')
                      .add(3, 'h')
                      .format('DD/MM/YYYY')}
                  </span>
                )
            },
            {
              field: 'situation',
              name: 'Situação',
              sortable: false,
              element: item => (
                <span>{item.situation ? 'Paga' : 'Pendente'}</span>
              )
            },
            {
              name: 'Ações',
              field: 'actions',
              sortable: false,
              element: item => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end'
                  }}
                >
                  <a
                    href={`https://multfluxosistema.com.br/movimentacoes_financeiras/view_contas_a_receber/${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-search"></span>
                  </a>
                  {item.payment_form === 'BOLETO' && (
                    <a
                      style={{ opacity: item.payment_gateway ? 1 : 0.3 }}
                      onClick={() => {
                        if (!item.payment_gateway) {
                          return
                        }
                        activeLoading()
                        api
                          .get(
                            `/financial/financialMovimentPayments/${item.id}`,
                            {
                              responseType: 'arraybuffer'
                            }
                          )
                          .then(response => {
                            const a = document.createElement('a')
                            const blob = new Blob([response.data], {
                              type: 'application/pdf'
                            })
                            const url = window.URL.createObjectURL(blob)
                            a.href = url
                            a.download = `Boleto-${initialValues?.person?.name}.pdf`
                            a.click()
                            disableLoading()
                          })
                          .catch(error => {
                            const errorResponse = String.fromCharCode.apply(
                              null,
                              new Uint8Array(error?.response?.data) as any
                            )
                            const message = errorResponse
                              ? JSON.parse(errorResponse)
                              : { message: 'Erro ao baixar boleto' }

                            addToast({
                              title: message?.message,
                              type: 'error'
                            })
                            disableLoading()
                          })
                      }}
                    >
                      <FaPrint />
                    </a>
                  )}
                </div>
              )
            }
          ]}
        />
      )}
    </Container>
  )
}
