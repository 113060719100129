import React, { useEffect, useState } from 'react'
import Container from '../../../components/Container'
import { namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useToast } from '../../../hooks/toast'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import { Alert } from '../../../components/Alert'

interface PermissionData {
  id: number
  name: string
  description: string
  method: string
  base_url: string
  path?: string
  grouped: string
  function: string
  created_at: string
  updated_at: string
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [permission, setPermission] = useState<PermissionData | null>(null)
  const { addToast } = useToast()

  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadPermission(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<PermissionData>(apiList(id))
        const { data } = response
        setPermission({
          ...data
        })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a permissão',
          description:
            'Houve um error ao carregar a permissão, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadPermission()
  }, [id, addToast, activeLoading, disableLoading, location.pathname, history])
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Permissão removida com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Permissão não removida, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Permissão removida com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Permissão não removida, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(id),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{permission?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="description" className="control-label">
                  Descrição
                </label>
                <p>{permission?.description}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="method" className="control-label">
                  Método
                </label>
                <p>{permission?.method}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="base_url" className="control-label">
                  URL de Base
                </label>
                <p>{permission?.base_url}</p>
              </div>
            </div>
            {permission?.path ? (
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="path" className="control-label">
                    Caminho
                  </label>
                  <p>{permission?.path}</p>
                </div>
              </div>
            ) : (
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="path" className="control-label">
                    Caminho
                  </label>
                  <p>SEM CAMINHO</p>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="path" className="control-label">
                  Grupo
                </label>
                <p>{permission?.grouped}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="path" className="control-label">
                  Função
                </label>
                <p>{permission?.function}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{permission?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{permission?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir a permissão ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir a permissão ${permission?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(permission?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}
export default View
