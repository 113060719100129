export const apiCreateAppointment = (): string =>
  '/commercial/clientsAppointments/create/'
export const apiListAppointment = (id?: string): string =>
  !id
    ? '/commercial/clientsAppointments/'
    : `/commercial/clientsAppointments/view/${id}`
export const apiUpdateAppointment = (id: string): string =>
  `/commercial/clientsAppointments/update/${id}`
export const apiDeleteAppointment = (id: string): string =>
  `/commercial/clientsAppointments/delete/${id}`
export const apiHistoryAppointment = (id: string): string =>
  `/commercial/clientsAppointments/history/${id}`
