import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { FormContent, SearchContainer } from './styles'

type NatureOperationData = {
  name: string
  tax_cfop_id: number
  tax_cfop_name: string
}

type TypesFormProps = {
  initialValues?: NatureOperationData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

type CfopListType = {
  id: number
  code: string
  descriptions: string
}

export const FormNatureOperation = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<NatureOperationData>()
  const { activeLoading, disableLoading } = useLoading()

  const [value, setValue] = useState('')
  const [cfopId, setCfopId] = useState(0)

  const [ncmList, setNcmList] = useState<CfopListType[]>([])
  const [selected, setSelected] = useState(false)

  const getCfopList = useCallback(async () => {
    const code = isNaN(parseInt(value)) ? '' : value
    const descriptions = isNaN(parseInt(value)) ? value : ''

    const { data } = await api.get<CfopListType[]>('taxes/taxCfop', {
      params: {
        searchParameters: { code, descriptions }
      }
    })
    setNcmList(data)
  }, [value])

  useEffect(() => {
    if (!value) return
    const timeOutId = setTimeout(() => getCfopList(), 500)
    return () => clearTimeout(timeOutId)
  }, [getCfopList, value])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        name: initialValues.name,
        tax_cfop_id: initialValues.tax_cfop_id,
        tax_cfop_name: initialValues.tax_cfop_name
      })
    }
  }, [initialValues])

  const onSubmit = async (data: NatureOperationData) => {
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            tax_cfop_id: Number(data.tax_cfop_id),
            tax_cfop_name: 'undefined'
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          ...data,
          tax_cfop_id: Number(data.tax_cfop_id),
          tax_cfop_name: 'undefined'
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <div className="row">
        <FormContent>
          <Input
            label="Nome"
            name="name"
            className="form-control"
            rules={{ required: true }}
          />
          <SearchContainer>
            <div>
              <Input
                label="Cfop"
                className="form-control"
                name="tax_cfop_name"
                id="option"
                onChange={({ target }) => {
                  setValue(target.value)
                  setSelected(false)
                }}
                value={value}
                controlled
                rules={{ required: true }}
              />
              <ul>
                {value !== '' &&
                  !selected &&
                  ncmList.map(item => (
                    <li
                      key={item.id}
                      onClick={() => {
                        setValue(`${item.code} - ${item.descriptions}`)
                        setCfopId(item.id)
                        setSelected(true)
                      }}
                    >
                      {`${item.code} - ${item.descriptions}`}
                    </li>
                  ))}
              </ul>
            </div>
          </SearchContainer>
          <Input type="hidden" name="tax_cfop_id" value={cfopId} controlled />
        </FormContent>
      </div>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
