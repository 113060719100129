/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { ControlPanel } from './style'
import { BoardOfMembers } from '../../../components/BoardOfMembers'
import { BoardOfSummary } from '../../../components/BoardOfSummary'
import { AmChartMap } from '../../../components/AmChartMap'
import { AmChartMixedColumn } from '../../../components/AmChartMixedColumn'
import { AmChartSortedBar } from '../../../components/AmChartSortedBar'
import { HeaderPortlet } from '../../../components/HeaderPortlet'
import avatar1 from '../../assets/global/img/users/avatar_profile.jpg'
import Container from '../../../components/Container'
import api from '../../../services/api'
import { useLoading } from '../../../hooks/loading'
import currency from 'currency.js'
import { FaUserCircle } from 'react-icons/fa'
import convertDateToISO from '../../../utlis/convertDateToISO'
import { useAuth } from '../../../hooks/auth'
import { AmChartPizza } from '../../../components/AmChartPizza'
import {
  SUM_TOTAL_FIELD_NAME,
  SUM_TOTAL_FIELD_TYPE
} from '../../Financial/CashFlowAnalysis'
import * as am4core from '@amcharts/amcharts4/core'
interface OrderDataProps {
  graphicData: any[]
  summaryData: any[any]
  summaryMonthData: any[]
  mapData: any[]
  agentGraphicData: any[]
}

const searchParamsChart = {
  entity: 'Order',
  fieldSearch: 'id',
  header: [
    {
      id: 'order_id',
      tableField: 'id',
      title: 'Pedido'
    },
    {
      id: 'date',
      tableField: 'date',
      title: 'Data',
      type: 'date',
      formatStyle: '%Y-%m-%d'
    },
    {
      id: 'name_department',
      tableField: 'name',
      title: 'Segmento',
      source: 'Departments',
      emptyValue: 'SEM SEGMENTO'
    },
    {
      id: 'client_name',
      tableField: 'name',
      title: 'Cliente',
      emptyValue: 'SEM CLIENTE',
      source: 'Client'
    },
    {
      id: 'products_value',
      tableField: 'products_value',
      title: 'Valor Produtos',
      type: 'money',
      symbol: 'R$',
      precision: 2,
      locale: 'pt_BR'
    },
    {
      id: 'freight_value',
      tableField: 'freight_value',
      title: 'Valor Frete',
      type: 'money',
      symbol: 'R$',
      precision: 2,
      locale: 'pt_BR'
    },
    {
      id: 'show_included_freight',
      tableField: 'show_included_freight',
      title: 'Frete Embutido',
      type: 'boolean',
      condition: {
        true: 'Sim',
        false: 'Não'
      }
    },
    {
      id: 'ipi_value',
      tableField: 'ipi_value',
      title: 'Valor IPI',
      type: 'money',
      symbol: 'R$',
      precision: 2,
      locale: 'pt_BR'
    },
    {
      id: 'total_value',
      tableField: 'total_value',
      title: 'Valor Total',
      type: 'money',
      symbol: 'R$',
      precision: 2,
      locale: 'pt_BR'
    },
    {
      id: 'invoice_date',
      tableField: 'invoice_date',
      title: 'Data Faturamento',
      type: 'date',
      formatStyle: '%Y-%m-%d'
    },
    {
      id: 'released',
      tableField: 'released',
      title: 'Faturado',
      type: 'boolean',
      condition: {
        true: 'Sim',
        false: 'Não'
      }
    },
    {
      id: 'agent_id',
      tableField: 'name',
      title: 'Representante',
      source: 'Employee',
      emptyValue: 'SEM REPRESENTANTE'
    }
  ],
  source: 'Order',
  innerJoin: [
    {
      entity: 'ClientNew',
      source: 'ClientNew',
      field: 'id',
      fieldCondition: 'client_id',
      sourceCondition: 'Order'
    },
    {
      entity: 'Departments',
      source: 'Departments',
      field: 'id',
      fieldCondition: 'segment_id',
      sourceCondition: 'ClientNew'
    },
    {
      entity: 'Employee',
      source: 'Employee',
      field: 'agent_id',
      fieldCondition: 'agent_id',
      sourceCondition: 'Order'
    }
  ]
}

const GoalAnalysis: React.FC = () => {
  const { user } = useAuth()
  const [activeMembers, setActiveMembers] = useState([])
  const [salesSummary, setBoardSummary] = useState([])
  const [dataUpdateProducts, setDataUpdateProducts] = useState([])
  const [dataUpdateArea, setDataUpdateArea] = useState([])
  const [dataUpdateMixed, setDataUpdateMixed] = useState([])
  const [dataUpdateMap, setDataUpdateMap] = useState([])
  const [selectedDate, setSelectedDate] = useState('Mes')
  const [ordersData, setOrdersData] = useState<OrderDataProps>(
    {} as OrderDataProps
  )
  const [selectedAgentId, setSelectedAgentId] = useState(0)
  const { activeLoading, disableLoading } = useLoading()
  const [datesRange, setDatesRange] = useState({
    minDate: undefined,
    maxDate: undefined
  })
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const createMembersBoard = useCallback(
    (data: any[], selectPeriod: any, hasDivide?: boolean) => {
      let goalDivide: any
      let goalDivideTeammate: any
      if (data.length) {
        if (!hasDivide) {
          return data
            .map((item: any) => {
              if (item?.total_team) {
                let teammates
                if (item?.team?.length) {
                  teammates = item.team
                    .sort(
                      (
                        a: { employee_name: string },
                        b: { employee_name: string }
                      ) => {
                        const nameA = a.employee_name.toUpperCase()
                        const nameB = b.employee_name.toUpperCase()
                        if (nameA < nameB) {
                          return -1
                        }
                        if (nameA > nameB) {
                          return 1
                        }
                        return 0
                      }
                    )
                    .map(
                      (team: any) =>
                        team && {
                          ...team,
                          name: `${team.employee_name.split(' ')[0]} ${
                            team.employee_name.split(' ').reverse()[0]
                          } `,
                          avatar_url: FaUserCircle,
                          goalAccomplished: BRL(
                            team[selectPeriod]?.goal || 0
                          ).format(),
                          cases: BRL(
                            team[selectPeriod]?.total_profit || 0
                          ).format(),
                          closed: BRL(
                            (team[selectPeriod]?.total_profit || 0) -
                              (team[selectPeriod]?.goal || 0)
                          ).format(),
                          discount: BRL(
                            team[selectPeriod]?.total_discount
                          ).format(),
                          rate: currency(
                            (team[selectPeriod]?.goal || 0) === 0
                              ? 0
                              : ((team[selectPeriod].total_profit || 0) * 100) /
                                  (team[selectPeriod]?.goal || 0),
                            {
                              symbol: '%',
                              pattern: '#!'
                            }
                          ).format()
                        }
                    )
                }
                return {
                  ...item,
                  name: `${item.employee_name.split(' ')[0]} ${
                    item.employee_name.split(' ').reverse()[0]
                  } `,
                  avatar_url: FaUserCircle,
                  goalAccomplished: BRL(
                    item.total_team[selectPeriod]?.goal || 0
                  ).format(),
                  cases: BRL(
                    item.total_team[selectPeriod].total_profit || 0
                  ).format(),
                  closed: BRL(
                    (item.total_team[selectPeriod].total_profit || 0) -
                      (item.total_team[selectPeriod]?.goal || 0)
                  ).format(),
                  discount: BRL(
                    item.total_team[selectPeriod]?.total_discount
                  ).format(),
                  rate: currency(
                    (item.total_team[selectPeriod]?.goal || 0) === 0
                      ? 0
                      : ((item.total_team[selectPeriod].total_profit || 0) *
                          100) /
                          (item.total_team[selectPeriod]?.goal || 0),
                    {
                      symbol: '%',
                      pattern: '#!'
                    }
                  ).format(),
                  teammates
                }
              }
            })
            .filter(item => item)
        } else {
          return data
            .map((item: any) => {
              if (item?.total_team) {
                let teammates
                if (item?.team?.length) {
                  teammates = item.team
                    .sort(
                      (
                        a: { employee_name: string },
                        b: { employee_name: string }
                      ) => {
                        const nameA = a.employee_name.toUpperCase()
                        const nameB = b.employee_name.toUpperCase()
                        if (nameA < nameB) {
                          return -1
                        }
                        if (nameA > nameB) {
                          return 1
                        }
                        return 0
                      }
                    )
                    .map((team: any) => {
                      if (selectPeriod === 'day') {
                        goalDivideTeammate = team[selectPeriod]?.goal / 22
                      }
                      if (selectPeriod === 'week') {
                        goalDivideTeammate = (team[selectPeriod]?.goal / 22) * 5
                      }
                      return {
                        ...team,
                        name: `${team.employee_name.split(' ')[0]} ${
                          team.employee_name.split(' ').reverse()[0]
                        } `,
                        avatar_url: FaUserCircle,
                        goalAccomplished: BRL(
                          goalDivideTeammate || team[selectPeriod]?.goal || 0
                        ).format(),
                        cases: BRL(
                          team[selectPeriod]?.total_profit || 0
                        ).format(),
                        closed: BRL(
                          (team[selectPeriod]?.total_profit || 0) -
                            (goalDivideTeammate ||
                              team[selectPeriod]?.goal ||
                              0)
                        ).format(),
                        discount: BRL(
                          team[selectPeriod]?.total_discount
                        ).format(),
                        rate: currency(
                          (goalDivideTeammate ||
                            team[selectPeriod]?.goal ||
                            0) === 0
                            ? 0
                            : ((team[selectPeriod].total_profit || 0) * 100) /
                                (goalDivideTeammate ||
                                  team[selectPeriod]?.goal ||
                                  0),
                          {
                            symbol: '%',
                            pattern: '#!'
                          }
                        ).format()
                      }
                    })
                }
                if (selectPeriod === 'day') {
                  goalDivide = item.total_team[selectPeriod]?.goal / 22
                }
                if (selectPeriod === 'week') {
                  goalDivide = (item.total_team[selectPeriod]?.goal / 22) * 5
                }

                return {
                  ...item,
                  name: `${item.employee_name.split(' ')[0]} ${
                    item.employee_name.split(' ').reverse()[0]
                  } `,
                  avatar_url: FaUserCircle,
                  goalAccomplished: BRL(
                    goalDivide || item.total_team[selectPeriod]?.goal || 0
                  ).format(),
                  cases: BRL(
                    item.total_team[selectPeriod].total_profit || 0
                  ).format(),
                  closed: BRL(
                    (item.total_team[selectPeriod].total_profit || 0) -
                      (goalDivide || item.total_team[selectPeriod]?.goal || 0)
                  ).format(),
                  discount: BRL(
                    item.total_team[selectPeriod]?.total_discount
                  ).format(),
                  rate: currency(
                    (goalDivide || item.total_team[selectPeriod]?.goal || 0) ===
                      0
                      ? 0
                      : ((item.total_team[selectPeriod].total_profit || 0) *
                          100) /
                          (goalDivide ||
                            item.total_team[selectPeriod]?.goal ||
                            0),
                    {
                      symbol: '%',
                      pattern: '#!'
                    }
                  ).format(),
                  teammates
                }
              }
            })
            .filter(item => item)
        }
      }
      return []
    },
    []
  )
  const createSummaryData = useCallback((data: any, isAgent?: boolean) => {
    if (data) {
      if (!isAgent) {
        return {
          ...data,
          order: {
            orderNumber:
              data.approved.total_approved === 0
                ? 0
                : BRL(data.approved.total_approved).format(),
            orderQuantity: data.approved.approved_quantity,
            converteToOrder: currency(data.quoteConvertedToOrder, {
              symbol: '%',
              pattern: '#!'
            }).format()
          },
          orderDiscount: {
            orderDiscountNumber:
              data.total_discount === 0 ? 0 : BRL(data.total_discount).format(),
            orderDiscountQuantity: data.discount_quantity,
            discountToClient: currency(data.discounGivenOnOrder, {
              symbol: '%',
              pattern: '#!'
            }).format()
          },
          budget: {
            budgetNumber:
              data.pending.total_pending === 0
                ? 0
                : BRL(data.pending.total_pending).format(),
            budgetQuantity: data.pending.pending_quantity,
            contactPending: data.pending.monitoring_situation || 0
          },
          calledOff: {
            calledOffNumber:
              data.canceled.total_canceled === 0
                ? 0
                : BRL(data.canceled.total_canceled).format(),
            calledOffQuantity: data.canceled.canceled_quantity,
            calledOffToClient: data.canceledToOrders
          },
          revenue: {
            billerdNumber: BRL(data.total_profit).format(),
            billerdQuantity: data.delivery_state
              .split(',')
              .filter((item: any) => item).length,
            toProfitNumber: BRL(data.total_toProfit).format(),
            prospection: data.prospection
          }
        }
      }
      return {
        ...data,
        order: {
          orderNumber:
            data.approved.total_approved === 0
              ? 0
              : BRL(data.approved.total_approved).format(),
          orderQuantity: data.approved.approved_quantity,
          converteToOrder: currency(data.quoteConvertedToOrder, {
            symbol: '%',
            pattern: '#!'
          }).format()
        },
        orderDiscount: {
          orderDiscountNumber:
            data.approved.total_discount === 0
              ? 0
              : BRL(data.approved.total_discount).format(),
          orderDiscountQuantity: data.approved.discount_quantity,
          discountToClient: currency(data.discounGivenOnOrder, {
            symbol: '%',
            pattern: '#!'
          }).format()
        },
        budget: {
          budgetNumber:
            data.pending.total_pending === 0
              ? 0
              : BRL(data.pending.total_pending).format(),
          budgetQuantity: data.pending.pending_quantity,
          contactPending: data.pending.monitoring_situation
        },
        calledOff: {
          calledOffNumber:
            data.canceled.total_canceled === 0
              ? 0
              : BRL(data.canceled.total_canceled).format(),
          calledOffQuantity: data.canceled.canceled_quantity,
          calledOffToClient: data.canceledToOrders
        },
        revenue: {
          billerdNumber: BRL(data.total_profit).format(),
          billerdQuantity: data.delivery_state
            .split(',')
            .filter((item: any) => item).length,
          toProfitNumber: BRL(data.total_toProfit).format(),
          prospection: data.prospection
        }
      }
    }
    return {
      order: {
        orderNumber: 0,
        orderQuantity: 0,
        converteToOrder: 0
      },
      orderDiscount: {
        orderDiscountNumber: 0,
        orderDiscountQuantity: 0,
        discountToClient: 0
      },
      budget: {
        budgetNumber: 0,
        budgetQuantity: 0,
        contactPending: 0
      },
      calledOff: {
        calledOffNumber: 0,
        calledOffQuantity: 0,
        calledOffToClient: 0
      },
      revenue: {
        billerdNumber: 0,
        billerdQuantity: 0,
        toProfitNumber: 0,
        prospection: 0
      }
    }
  }, [])
  const createProductsData = useCallback((data: any[]) => {
    const maxLighten = 0.65
    let percentage = 0
    let subPercentage = 0
    if (data.length) {
      const groupByCategory = data.reduce((group, product) => {
        const { group_name } = product
        group[group_name] = group[group_name] ?? []
        group[group_name].push(product)
        return group
      }, {})
      const groupByCategoryArray = Object.entries(groupByCategory)
      const totalGroupBy: any = {}
      const totalValue = data.reduce((previousItem, currentItem) => {
        if (!totalGroupBy[currentItem.group_name]) {
          totalGroupBy[currentItem.group_name] = {
            total_value: currentItem.total_value,
            quantity: Number(currentItem.quantity)
          }
        } else {
          totalGroupBy[currentItem.group_name].total_value =
            totalGroupBy[currentItem.group_name].total_value +
            currentItem.total_value
          totalGroupBy[currentItem.group_name].quantity =
            totalGroupBy[currentItem.group_name].quantity +
            Number(currentItem.quantity)
        }
        return previousItem + currentItem.total_value
      }, 0)
      data.forEach(item => {
        const percent = (item.total_value / totalValue) * 100
        subPercentage = subPercentage || percent
      }, 0)
      groupByCategoryArray.forEach(([key]: any) => {
        const groupTotalValue = totalGroupBy[key].total_value
        const percent = (groupTotalValue / totalValue) * 100
        percentage = percentage || percent
      }, 0)
      return groupByCategoryArray.map(([key, value]: any) => {
        const percent = (totalGroupBy[key].total_value / totalValue) * 100
        const percentLight = (percentage - percent) / 100
        return {
          type: key,
          percent,
          value: totalGroupBy[key].total_value,
          quantity: `${totalGroupBy[key].quantity}`,
          orders: value.map((subProduct: any) => subProduct.orders).join(''),
          subs: value.map((subProduct: any) => {
            const subPercent = (subProduct.total_value / totalValue) * 100
            const subPercentLight = (subPercentage - subPercent) / 100
            return {
              type: subProduct.name,
              percent: subPercent,
              value: subProduct.total_value,
              quantity: subProduct.quantity,
              orders: subProduct.orders,
              color: am4core
                .color('#E08E20')
                .lighten(
                  subPercentLight > maxLighten ? maxLighten : subPercentLight
                )
            }
          }),
          color: am4core
            .color('#E08E20')
            .lighten(percentLight > maxLighten ? maxLighten : percentLight)
        }
      })
    }
    return []
  }, [])
  const createDepartmentClientsData = useCallback((data: any[]) => {
    const maxLighten = 0.65
    let percentage = 0
    if (data.length) {
      const totalValue = data.reduce((previousItem, currentItem) => {
        return previousItem + currentItem.total_approved
      }, 0)
      data.forEach(item => {
        const percent = (item.total_approved / totalValue) * 100
        percentage = percentage > percent ? percentage : percent
      }, 0)
      const sortedData = data.sort(
        (a, b) => b.total_approved - a.total_approved
      )
      return sortedData.map((summary: any) => {
        const percent = (summary.total_approved / totalValue) * 100
        const percentLight = (percentage - percent) / 100
        return {
          type: summary.client_name,
          percent: percent,
          value: summary.total_approved,
          quantity: summary.count_id,
          orders: summary.orders,
          subs: [],
          color: am4core
            .color('#BEBFC5')
            .lighten(percentLight > maxLighten ? maxLighten : percentLight)
        }
      })
    }
    return []
  }, [])
  const createDepartmentData = useCallback(
    (data: any[]) => {
      const maxLighten = 0.65
      let percentage = 0
      if (data.length) {
        const totalValue = data.reduce((previousItem, currentItem) => {
          return previousItem + currentItem.total_approved
        }, 0)
        data.forEach(item => {
          const percent = (item.total_approved / totalValue) * 100
          percentage = percentage || percent
        }, 0)
        const sortedData = data.sort(
          (a, b) => b.total_approved - a.total_approved
        )
        return sortedData.map((summary: any) => {
          const clients: any[] = createDepartmentClientsData(
            summary.clients || []
          )
          const percent = (summary.total_approved / totalValue) * 100
          const percentLight = (percentage - percent) / 100

          return {
            type: summary.name_department,
            percent: percent,
            value: summary.total_approved,
            quantity: summary.count_id,
            orders: summary.orders,
            subs: clients,
            color: am4core
              .color('#BEBFC5')
              .lighten(percentLight > maxLighten ? maxLighten : percentLight)
          }
        })
      }
      return []
    },
    [createDepartmentClientsData]
  )
  function filterDuplicatesObject(data: any[], findField: string) {
    const unique: any[] = []
    const filterData = data.filter(item => {
      if (item) {
        const isDuplicate = unique.includes(item[findField])
        if (!isDuplicate) {
          unique.push(item[findField])
          return true
        }
        return false
      }
      return true
    })
    return filterData
  }
  const createMapData = useCallback((data: any, orders?: string) => {
    if (data.length) {
      return data
        .split(',')
        .map((state: any) => state && { MAIL_ST_PROV_C: state, orders })
    }
    return []
  }, [])
  const setDataWithoutFilter = useCallback(
    (
      dataSummary: any,
      dataProducts: any[],
      dataDepartment: any[],
      dataMap: any[]
    ) => {
      setBoardSummary([createSummaryData(dataSummary)])
      setDataUpdateProducts(
        createProductsData(filterDuplicatesObject(dataProducts, 'name'))
      )
      setDataUpdateArea(createDepartmentData(dataDepartment))
      setDataUpdateMap(createMapData(dataMap, dataSummary.orders))
    },
    [createDepartmentData, createMapData, createProductsData, createSummaryData]
  )

  const loadData = useCallback(
    async (date?: string) => {
      const selectDate = date || 'Mes'
      let selectPeriod: any
      if (selectDate === 'Mes') {
        selectPeriod = 'month'
      }
      if (user.role_id !== 1 && !user.employee?.agent_id) {
        createSummaryData({
          order: {
            orderNumber: 0,
            orderQuantity: 0,
            converteToOrder: 0
          },
          orderDiscount: {
            orderDiscountNumber: 0,
            orderDiscountQuantity: 0,
            discountToClient: 0
          },
          budget: {
            budgetNumber: 0,
            budgetQuantity: 0,
            contactPending: 0
          },
          calledOff: {
            calledOffNumber: 0,
            calledOffQuantity: 0,
            calledOffToClient: 0
          },
          revenue: {
            billerdNumber: 0,
            billerdQuantity: 0,
            toProfitNumber: 0,
            prospection: 0
          }
        })
        disableLoading()
        return
      }
      const order = await api.get(
        `/commercial/goalAnalysis/Hoje/${
          user.role_id === 1 ? undefined : user.employee.agent_id
        }`
      )
      setOrdersData(order.data)
      const data = order.data.agentGraphicData
        .flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)
      setDataUpdateMixed(
        order.data.graphicData.map(
          (item: any) =>
            item && {
              date: item.invoice_date,
              goal: item.goal,
              goalAccomplished: item.total_profit,
              discount: item.total_discount,
              lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
            }
        )
      )

      setActiveMembers(createMembersBoard(data, selectPeriod))

      if (order.data.summaryData[selectPeriod]) {
        setDataWithoutFilter(
          order.data.summaryData[selectPeriod],
          order.data.summaryData[selectPeriod].ordersProducts,
          order.data.summaryData[selectPeriod].ordersDepartment,
          order.data.summaryData[selectPeriod].delivery_state
        )
      } else {
        setBoardSummary([createSummaryData(undefined)])
        setDataUpdateProducts(createProductsData([]))
        setDataUpdateArea(createDepartmentData([]))
        setDataUpdateMap(createMapData([]))
      }

      setTimeout(() => {
        disableLoading()
      }, 300)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableLoading]
  )

  async function setInitialData(date?: string, agent_id?: number) {
    if (user.role_id !== 1 && !user.employee?.agent_id) {
      setBoardSummary([
        createSummaryData({
          order: {
            orderNumber: 0,
            orderQuantity: 0,
            converteToOrder: 0
          },
          orderDiscount: {
            orderDiscountNumber: 0,
            orderDiscountQuantity: 0,
            discountToClient: 0
          },
          budget: {
            budgetNumber: 0,
            budgetQuantity: 0,
            contactPending: 0
          },
          calledOff: {
            calledOffNumber: 0,
            calledOffQuantity: 0,
            calledOffToClient: 0
          },
          revenue: {
            billerdNumber: 0,
            billerdQuantity: 0,
            toProfitNumber: 0,
            prospection: 0
          }
        })
      ])

      disableLoading()
      return
    }
    activeLoading()
    const selectDate = date.length > 0 ? date : selectedDate
    const selectAgent = agent_id === 0 ? agent_id : selectedAgentId
    let selectPeriod: any
    if (
      selectDate === 'Mes' ||
      selectDate === 'Mês' ||
      selectDate.includes('period')
    ) {
      selectPeriod = 'month'
    }
    if (selectDate === 'Ano') {
      selectPeriod = 'year'
    }
    if (selectDate === 'Hoje') {
      selectPeriod = 'day'
    }
    if (selectDate === 'Semana') {
      selectPeriod = 'week'
    }
    setSelectedDate(date.length > 0 ? date : selectedDate)
    setSelectedAgentId(agent_id === 0 ? agent_id : selectedAgentId)

    const data = ordersData.agentGraphicData
      .flatMap((item: any) =>
        item.agent_id > 0 ? [item, item.team].flat() : item.team
      )
      .filter((item: any) => item)
    if (selectDate.includes('period')) {
      const { minDate, maxDate } = JSON.parse(date || selectDate)
      // const { minDate, maxDate } = dateRange(selectDate)
      const order = await api.get(
        `/commercial/goalAnalysis/${date || selectDate}/${
          user.role_id === 1 ? undefined : user.employee.agent_id
        }`
      )
      const data = order.data.agentGraphicData
        .flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)

      setActiveMembers(createMembersBoard(data, selectPeriod))
      if (selectAgent) {
        const agentGraphic = data.find(
          (agent: any) => agent && agent.agent_id === selectAgent
        )
        if (agentGraphic) {
          if (agentGraphic.total_team) {
            setDatesRange({
              maxDate,
              minDate
            })
            setDataUpdateMixed(
              agentGraphic.total_team.monthsData.map(
                (item: any) =>
                  item && {
                    date: item.invoice_date,
                    goal: item.goal,
                    goalAccomplished: item.total_profit,
                    discount: item.total_discount,
                    lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                  }
              )
            )
            setBoardSummary([
              createSummaryData(agentGraphic.total_team[selectPeriod], true)
            ])
            setDataUpdateProducts(
              createProductsData(
                agentGraphic.total_team[selectPeriod].ordersProducts
              )
            )
            setDataUpdateArea(
              createDepartmentData(
                agentGraphic.total_team[selectPeriod].ordersDepartment
              )
            )
            setDataUpdateMap(
              createMapData(
                agentGraphic.total_team[selectPeriod].delivery_state,
                agentGraphic.total_team[selectPeriod].orders
              )
            )
          } else {
            setDatesRange({
              maxDate,
              minDate
            })
            setDataUpdateMixed(
              agentGraphic.monthsData.map(
                (item: any) =>
                  item && {
                    date: item.invoice_date,
                    goal: item.goal,
                    goalAccomplished: item.total_profit,
                    discount: item.total_discount,
                    lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                  }
              )
            )
            setBoardSummary([
              createSummaryData(agentGraphic[selectPeriod], true)
            ])
            setDataUpdateProducts(
              createProductsData(agentGraphic[selectPeriod].ordersProducts)
            )
            setDataUpdateArea(
              createDepartmentData(agentGraphic[selectPeriod].ordersDepartment)
            )
            setDataUpdateMap(
              createMapData(
                agentGraphic[selectPeriod].delivery_state,
                agentGraphic[selectPeriod].orders
              )
            )
          }
          disableLoading()
        }
      } else {
        setDatesRange({
          maxDate,
          minDate
        })
        setDataUpdateMixed(
          order.data.graphicData.map(
            (item: any) =>
              item && {
                date: item.invoice_date,
                goal: item.goal,
                goalAccomplished: item.total_profit,
                discount: item.total_discount,
                lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
              }
          )
        )
        if (order.data.summaryData[selectPeriod]) {
          setDataWithoutFilter(
            order.data.summaryData[selectPeriod],
            order.data.summaryData[selectPeriod].ordersProducts,
            order.data.summaryData[selectPeriod].ordersDepartment,
            order.data.summaryData[selectPeriod].delivery_state
          )
        } else {
          setBoardSummary([createSummaryData(undefined)])
          setDataUpdateProducts(createProductsData([]))
          setDataUpdateArea(createDepartmentData([]))
          setDataUpdateMap(createMapData([]))
        }
        disableLoading()
      }
      setTimeout(() => {
        disableLoading()
      }, 500)
      return
    }
    const year = new Date().getFullYear()
    setDatesRange({
      minDate: `${year}-01`,
      maxDate: `${year}-12`
    })
    setDataUpdateMixed(
      ordersData.graphicData.map(
        (item: any) =>
          item && {
            date: item.invoice_date,
            goal: item.goal,
            goalAccomplished: item.total_profit,
            discount: item.total_discount,
            lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
          }
      )
    )
    setActiveMembers(createMembersBoard(data, selectPeriod, true))
    if (selectAgent) {
      const agentGraphic = data.find(
        (agent: any) => agent && agent.agent_id === selectAgent
      )
      if (agentGraphic) {
        if (agentGraphic.total_team) {
          setDataUpdateMixed(
            agentGraphic.total_team.monthsData.map(
              (item: any) =>
                item && {
                  date: item.invoice_date,
                  goal: item.goal,
                  goalAccomplished: item.total_profit,
                  discount: item.total_discount,
                  lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                }
            )
          )
          setBoardSummary([
            createSummaryData(agentGraphic.total_team[selectPeriod], true)
          ])
          setDataUpdateProducts(
            createProductsData(
              agentGraphic.total_team[selectPeriod].ordersProducts
            )
          )
          setDataUpdateArea(
            createDepartmentData(
              agentGraphic.total_team[selectPeriod].ordersDepartment
            )
          )
          setDataUpdateMap(
            createMapData(
              agentGraphic.total_team[selectPeriod].delivery_state,
              agentGraphic.total_team[selectPeriod].orders
            )
          )
        } else {
          setDataUpdateMixed(
            agentGraphic.monthsData.map(
              (item: any) =>
                item && {
                  date: item.invoice_date,
                  goal: item.goal,
                  goalAccomplished: item.total_profit,
                  discount: item.total_discount,
                  lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                }
            )
          )
          setBoardSummary([createSummaryData(agentGraphic[selectPeriod], true)])
          setDataUpdateProducts(
            createProductsData(agentGraphic[selectPeriod].ordersProducts)
          )
          setDataUpdateArea(
            createDepartmentData(agentGraphic[selectPeriod].ordersDepartment)
          )
          setDataUpdateMap(
            createMapData(
              agentGraphic[selectPeriod].delivery_state,
              agentGraphic[selectPeriod].orders
            )
          )
        }
        disableLoading()
      }
    } else {
      if (ordersData.summaryData[selectPeriod]) {
        setDataWithoutFilter(
          ordersData.summaryData[selectPeriod],
          ordersData.summaryData[selectPeriod].ordersProducts,
          ordersData.summaryData[selectPeriod].ordersDepartment,
          ordersData.summaryData[selectPeriod].delivery_state
        )
      } else {
        setBoardSummary([createSummaryData(undefined)])
        setDataUpdateProducts(createProductsData([]))
        setDataUpdateArea(createDepartmentData([]))
        setDataUpdateMap(createMapData([]))
      }
    }
    disableLoading()
  }

  const handleSetDate = useCallback(
    async (date?: string, agent_id?: number) => {
      activeLoading()
      if (user.role_id !== 1 && !user.employee?.agent_id) {
        setBoardSummary([
          createSummaryData({
            order: {
              orderNumber: 0,
              orderQuantity: 0,
              converteToOrder: 0
            },
            orderDiscount: {
              orderDiscountNumber: 0,
              orderDiscountQuantity: 0,
              discountToClient: 0
            },
            budget: {
              budgetNumber: 0,
              budgetQuantity: 0,
              contactPending: 0
            },
            calledOff: {
              calledOffNumber: 0,
              calledOffQuantity: 0,
              calledOffToClient: 0
            },
            revenue: {
              billerdNumber: 0,
              billerdQuantity: 0,
              toProfitNumber: 0,
              prospection: 0
            }
          })
        ])
        disableLoading()
        return
      }
      const selectDate = date || selectedDate
      const selectAgent = agent_id || selectedAgentId
      setSelectedDate(date || selectedDate)
      setSelectedAgentId(agent_id || selectedAgentId)
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }

      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        const order = await api.get(
          `/commercial/goalAnalysis/${date || selectedDate}/${
            user.role_id === 1 ? undefined : user.employee.agent_id
          }`
        )
        const data = order.data.agentGraphicData
          .flatMap((item: any) =>
            item.agent_id > 0 ? [item, item.team].flat() : item.team
          )
          .filter((item: any) => item)

        setActiveMembers(createMembersBoard(data, selectPeriod))
        if (selectAgent) {
          const agentGraphic = data.find(
            (agent: any) => agent && agent.agent_id === selectAgent
          )
          if (agentGraphic) {
            if (agentGraphic.total_team) {
              setDatesRange({
                maxDate,
                minDate
              })
              setDataUpdateMixed(
                agentGraphic.total_team.monthsData.map(
                  (item: any) =>
                    item && {
                      date: item.invoice_date,
                      goal: item.goal,
                      goalAccomplished: item.total_profit,
                      discount: item.total_discount,
                      lastYearTarget:
                        (item.total_profit || 0) - (item.goal || 0)
                    }
                )
              )

              setBoardSummary([
                createSummaryData(agentGraphic.total_team[selectPeriod], true)
              ])

              setDataUpdateProducts(
                createProductsData(
                  agentGraphic.total_team[selectPeriod].ordersProducts
                )
              )
              setDataUpdateArea(
                createDepartmentData(
                  agentGraphic.total_team[selectPeriod].ordersDepartment
                )
              )
              setDataUpdateMap(
                createMapData(
                  agentGraphic.total_team[selectPeriod].delivery_state,
                  agentGraphic.total_team[selectPeriod].orders
                )
              )
            } else {
              setDatesRange({
                maxDate,
                minDate
              })
              setDataUpdateMixed(
                agentGraphic.monthsData.map(
                  (item: any) =>
                    item && {
                      date: item.invoice_date,
                      goal: item.goal,
                      goalAccomplished: item.total_profit,
                      discount: item.total_discount,
                      lastYearTarget:
                        (item.total_profit || 0) - (item.goal || 0)
                    }
                )
              )
              setBoardSummary([
                createSummaryData(agentGraphic[selectPeriod], true)
              ])
              setDataUpdateProducts(
                createProductsData(agentGraphic[selectPeriod].ordersProducts)
              )
              setDataUpdateArea(
                createDepartmentData(
                  agentGraphic[selectPeriod].ordersDepartment
                )
              )
              setDataUpdateMap(
                createMapData(
                  agentGraphic[selectPeriod].delivery_state,
                  agentGraphic[selectPeriod].orders
                )
              )
            }
          }
        } else {
          if (order.data.summaryData[selectPeriod]) {
            setDataWithoutFilter(
              order.data.summaryData[selectPeriod],
              order.data.summaryData[selectPeriod].ordersProducts,
              order.data.summaryData[selectPeriod].ordersDepartment,
              order.data.summaryData[selectPeriod].delivery_state
            )
          } else {
            setBoardSummary([createSummaryData(undefined)])
            setDataUpdateProducts(createProductsData([]))
            setDataUpdateArea(createDepartmentData([]))
            setDataUpdateMap(createMapData([]))
          }
          setDatesRange({
            maxDate,
            minDate
          })
          setDataUpdateMixed(
            order.data.graphicData.map(
              (item: any) =>
                item && {
                  date: item.invoice_date,
                  goal: item.goal,
                  goalAccomplished: item.total_profit,
                  discount: item.total_discount,
                  lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                }
            )
          )
        }
        setTimeout(() => {
          disableLoading()
        }, 500)
        return
      }
      const year = new Date().getFullYear()
      setDatesRange({
        minDate: `${year}-01`,
        maxDate: `${year}-12`
      })
      const data = ordersData.agentGraphicData
        .flatMap((item: any) =>
          item.agent_id > 0 ? [item, item.team].flat() : item.team
        )
        .filter((item: any) => item)

      setActiveMembers(createMembersBoard(data, selectPeriod, true))
      if (selectAgent) {
        const agentGraphic = data.find(
          (agent: any) => agent && agent.agent_id === selectAgent
        )
        if (agentGraphic) {
          if (agentGraphic.total_team) {
            setDataUpdateMixed(
              agentGraphic.total_team.monthsData.map(
                (item: any) =>
                  item && {
                    date: item.invoice_date,
                    goal: item.goal,
                    goalAccomplished: item.total_profit,
                    discount: item.total_discount,
                    lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                  }
              )
            )
            setBoardSummary([
              createSummaryData(agentGraphic.total_team[selectPeriod], true)
            ])

            setDataUpdateProducts(
              createProductsData(
                agentGraphic.total_team[selectPeriod].ordersProducts
              )
            )
            setDataUpdateArea(
              createDepartmentData(
                agentGraphic.total_team[selectPeriod].ordersDepartment
              )
            )
            setDataUpdateMap(
              createMapData(
                agentGraphic.total_team[selectPeriod].delivery_state,
                agentGraphic.total_team[selectPeriod].orders
              )
            )
          } else {
            setDataUpdateMixed(
              agentGraphic.monthsData.map(
                (item: any) =>
                  item && {
                    date: item.invoice_date,
                    goal: item.goal,
                    goalAccomplished: item.total_profit,
                    discount: item.total_discount,
                    lastYearTarget: (item.total_profit || 0) - (item.goal || 0)
                  }
              )
            )

            setBoardSummary([
              createSummaryData(agentGraphic[selectPeriod], true)
            ])

            setDataUpdateArea(
              createDepartmentData(agentGraphic[selectPeriod].ordersDepartment)
            )
            setDataUpdateProducts(
              createProductsData(agentGraphic[selectPeriod].ordersProducts)
            )
            setDataUpdateMap(
              createMapData(
                agentGraphic[selectPeriod].delivery_state,
                agentGraphic[selectPeriod].orders
              )
            )
          }
          disableLoading()
        }
      } else {
        if (ordersData.summaryData[selectPeriod]) {
          setDataWithoutFilter(
            ordersData.summaryData[selectPeriod],
            ordersData.summaryData[selectPeriod].ordersProducts,
            ordersData.summaryData[selectPeriod].ordersDepartment,
            ordersData.summaryData[selectPeriod].delivery_state
          )
        } else {
          setBoardSummary([createSummaryData(undefined)])
          setDataUpdateProducts(createProductsData([]))
          setDataUpdateArea(createDepartmentData([]))
          setDataUpdateMap(createMapData([]))
        }

        setTimeout(() => {
          disableLoading()
        }, 300)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeLoading,
      createDepartmentData,
      createMapData,
      createMembersBoard,
      createProductsData,
      createSummaryData,
      disableLoading,
      ordersData.agentGraphicData,
      ordersData.summaryData,
      selectedAgentId,
      selectedDate,
      setDataWithoutFilter
    ]
  )

  useEffect(() => {
    activeLoading()
    loadData()
  }, [activeLoading, loadData])
  return (
    <Container
      pageTitle={'Controle de Metas'}
      portletTitle={''}
      breadcrumb={[
        {
          name: 'Início',
          to: '/'
        },
        {
          name: 'Controle de Metas'
        }
      ]}
      tools={[]}
    >
      <ControlPanel>
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <HeaderPortlet
            getUserData={() => {}}
            activeMembersData={activeMembers}
            setInitialData={setInitialData}
            handleSetDate={handleSetDate}
            actions={['Hoje', 'Semana', 'Mês', 'Ano', 'Período']}
            mainTitle=""
          />
        </div>
        <div className="row">
          <BoardOfMembers
            handleSetDate={handleSetDate}
            getUserData={() => {}}
            activeMembersData={activeMembers}
            setInitialData={setInitialData}
            tableHead={[
              {
                colSpan: 2,
                name: 'Membro'
              },
              {
                name: 'Meta'
              },
              {
                name: 'Meta Realizada'
              },
              {
                name: 'Balanço',
                classname: 'ThBalance'
              },
              {
                name: 'Desconto'
              },
              {
                name: 'Percentual',
                classname: 'ThPercente'
              }
            ]}
          />

          <div className="col-md-6">
            <AmChartMixedColumn
              maintitle="Grafico de Meta"
              settings={{
                valueAxis2Title: 'Dias de Mercado',
                valueAxis1Title: '\n',
                series4Title: 'Balanço'
              }}
              data={dataUpdateMixed}
              initialData={[]}
              minDate={datesRange.minDate}
              maxDate={datesRange.maxDate}
            />
          </div>
        </div>

        <div className="row">
          <BoardOfSummary
            boardOfSummaryData={salesSummary}
            generatedExport={true}
          />
          <div className="col-md-6">
            <AmChartMap
              data={dataUpdateMap || []}
              maintitle="Pedidos Faturados por estados"
              initialData={[]}
              height="50vh"
              exportOptions={{
                fieldData: 'orders',
                nameFile: 'relatório-pedidos-por-estado',
                searchParams: searchParamsChart,
                url: '/commercial/orders/generateexport'
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="d-flex">
            <div className="col-md-12">
              <AmChartPizza
                data={dataUpdateProducts}
                divId="amChartPizza"
                maintitle="Gráfico dos Produtos Faturados"
                style={{ width: '100%', height: '400px' }}
                height="750"
                resizeChart={true}
                styleSettings={{
                  legend: {
                    maxWidth: 800,
                    minWidth: 680
                  }
                }}
                options={{
                  tooltips: {
                    text: "QTD: {quantity} {category}: {value.value.formatNumber('#.#')}% ({openValue})"
                  }
                }}
                exportOptions={{
                  fieldData: 'orders',
                  nameFile: 'relatório-pedidos-por-produtos',
                  searchParams: searchParamsChart,
                  url: '/commercial/orders/generateexport'
                }}
                hasExport
                ignoreFields={[SUM_TOTAL_FIELD_NAME]}
                fields={[
                  {
                    type: SUM_TOTAL_FIELD_TYPE,
                    name: SUM_TOTAL_FIELD_NAME,
                    percent: 0,
                    id: Math.random() * 9999
                  }
                ]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex">
            <div className="col-md-12">
              <AmChartPizza
                data={dataUpdateArea}
                divId="amChartPizza1"
                maintitle="Gráfico dos Segmentos Faturados"
                style={{ width: '100%', height: '400px' }}
                height="750"
                resizeChart={true}
                styleSettings={{
                  legend: {
                    maxWidth: 800,
                    minWidth: 680
                  }
                }}
                options={{
                  tooltips: {
                    text: "QTD: {quantity} {category}: {value.value.formatNumber('#.#')}% ({openValue})"
                  }
                }}
                ignoreFields={[SUM_TOTAL_FIELD_NAME]}
                exportOptions={{
                  fieldData: 'orders',
                  nameFile: 'relatório-pedidos-por-segmento',
                  searchParams: searchParamsChart,
                  url: '/commercial/orders/generateexport'
                }}
                hasExport
                fields={[
                  {
                    type: SUM_TOTAL_FIELD_TYPE,
                    name: SUM_TOTAL_FIELD_NAME,
                    percent: 0,
                    id: Math.random()
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </ControlPanel>
    </Container>
  )
}

export default GoalAnalysis
