import React, { useEffect, useState, useCallback } from 'react'
import Form from '../../../../../../components/FormProvider'
import { useTabs } from '../../../../../../hooks/tabs'

import {
  Container,
  ContentItem,
  RenderComponent,
  TabHeaderContainer,
  TabName,
  TabPanelContainer
} from './styles'
import { useClient } from '../../providers/ClientProvider'
import { Footer } from '../footer'
import { useToast } from '../../../../../../hooks/toast'
import { useHistory } from 'react-router'
import Modal from '../../../../../../components/Modal'

type TypeContentProps = {
  tabList: any[]
  id?: string
  type?: 'create' | 'update'
}

export const Tab = ({ tabList, id, type }: TypeContentProps): JSX.Element => {
  const {
    addTab,
    changeCurrentTab,
    currentTab,
    tabs,
    changeCurrentTabForNext,
    changeCurrentTabForPrevious
  } = useTabs()

  const { getClient, saveClient, getOptions, clientQueryData } = useClient()
  const { addToast } = useToast()
  const history = useHistory()

  const [defaultValues, setDefaultValues] = useState<any>()
  const [errors, setErrors] = useState('')
  const [errorsModal, setErrorsModal] = useState(false)
  const [submited, setSubmited] = useState(0)
  const [saving, setSaving] = useState(false)

  const handleClickErrorsModal = () => {
    setErrorsModal(false)
  }

  useEffect(() => {
    tabList.forEach(({ name, isDefault, label, isEnable, Component }) => {
      addTab({ default: isDefault, name, label, isEnable, Component })
    })
  }, [tabList, addTab])

  useEffect(() => {
    if (id) {
      ;(async () => {
        const product = await getClient(id)
        console.log('product', product)
        setDefaultValues(product)
      })()
    } else {
      console.log('options')
      getOptions()
    }
  }, [id, getClient, getOptions])

  const onClickNameTab = (name: string) => changeCurrentTab(name)

  async function handleSubmit(data: any) {
    console.log(data)
    setSaving(true)
    data.person.query_data = clientQueryData?.client
      ? JSON.stringify(clientQueryData.client)
      : undefined
    data.person.query_data_partners = clientQueryData?.partners
      ? JSON.stringify(clientQueryData.partners)
      : undefined

    if (data.person.type === 'PF') {
      data.person.fantasy_name = data.person.name
      data.client.name = data.person.name
    }
    data.address.active = true
    const clientId = await saveClient(data, id)
    if (clientId) {
      addToast({
        type: 'success',
        title: id ? 'Cliente atualizado' : 'Cliente adicionado',
        description: id
          ? 'Cliente atualizado com sucesso!'
          : 'Cliente adicionado com sucesso!'
      })
      setSaving(false)
      history.push(`/commercial/clients/view/${clientId}`)
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o cliente',
        description:
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
      setSaving(false)
    }
  }

  const handleErrors = useCallback(
    (data: any) => {
      console.log(data)
      const errorsList = Object.keys(data)
      if (errorsList.length !== 0) {
        let errorTabList = ''
        errorsList.forEach(e => {
          const tabs = [
            { key: 'client', tab: 'Dados' },
            { key: 'person', tab: 'Dados' },
            { key: 'address', tab: 'Dados' },
            { key: 'contact', tab: 'Contato' },
            { key: 'payment', tab: 'Forma de pagamento' }
          ]
          const tab = tabs.find(t => t.key === e)
          if (!errorTabList.includes(tab?.tab)) {
            errorTabList += `${tab?.tab}+`
          }
        })
        setErrors(errorTabList)
        if (submited === 1) {
          setErrorsModal(true)
          setSubmited(submited + 1)
        }
      } else {
        setErrors('')
      }
    },
    [submited]
  )

  const handleSaveButton = () => {
    setSubmited(submited + 1)
    errors.length && setErrorsModal(true)
  }

  return (
    <Container>
      <ContentItem>
        <TabHeaderContainer>
          {tabs.map(
            ({ label, isEnable, name }) =>
              isEnable && (
                <TabName
                  onClick={() => onClickNameTab(name)}
                  key={name}
                  isActive={currentTab.key === name}
                >
                  {label}
                </TabName>
              )
          )}
        </TabHeaderContainer>
        <TabPanelContainer>
          <Form
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            hasErrors={handleErrors}
          >
            {tabs.map(({ Component, name }) => (
              <RenderComponent key={name} isActive={name === currentTab.key}>
                {Component?.({ type }) || name}
              </RenderComponent>
            ))}
            <div className="form-actions right">
              <Footer
                saving={saving}
                onClickButtonNext={changeCurrentTabForNext}
                onClickButtonBack={changeCurrentTabForPrevious}
                onClickButtonSave={handleSaveButton}
              />
            </div>
          </Form>
        </TabPanelContainer>
      </ContentItem>
      <Modal
        isOpenModal={errorsModal}
        Children={
          <div>
            <h4>Os campos destacados na(s) aba(s):</h4>
            {errors.split('+').map(e => (
              <h4 key={e} style={{ fontWeight: 'bold' }}>
                {e && `- ${e}`}
              </h4>
            ))}
            <h4>são de preenchimento obrigatório</h4>
          </div>
        }
        pageTitle={'Atenção'}
        onClickButtonCancel={handleClickErrorsModal}
      />
    </Container>
  )
}
