import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../../components/Container'
import Tabs from '../../../../../components/Tabs'
import Tab from '../../../../../components/Tabs/Tab'
import DataTable from '../../../../../components/DataTable'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { nameEntity, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'

interface CostCentersData {
  id: number
  name: string
  position: number
  deadline_days: number
  dashboard: boolean
  dashboard_icon: string
  created_at: string
  updated_at: string
}

const CostCentersView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [costCenter, setCostCenter] = useState<CostCentersData | null>(null)
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadCostCenter(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<CostCentersData>(apiList(id))
        const { data } = response
        setCostCenter(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')

        console.log(baseUrl, path, location.pathname.split('/'))
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o Centro de custo',
          description: 'Houve um error ao carregar, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadCostCenter()
  }, [addToast, activeLoading, disableLoading, id, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Centro de custo removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Centro de custo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveItem, setAlertRemoveItem] = useState(false)

  const handleOnClickRemoveItem = () => {
    setAlertRemoveItem(true)
  }

  const handlerOnClickButtonConfirmRemoveItem = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveItem(false)
      addToast({
        type: 'success',
        title: 'Centro de custo removido com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveItem(false)
      addToast({
        type: 'error',
        title: 'Centro de custo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveItem = () => {
    setAlertRemoveItem(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveItem()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{costCenter?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{costCenter?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Ordem
                </label>
                <p>{costCenter?.position}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Prazo
                </label>
                <p>{costCenter?.deadline_days}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Dashboard
                </label>
                <p>{costCenter?.dashboard ? 'SIM' : 'NÃO'}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Dashboard icone
                </label>
                <p>{costCenter?.dashboard_icon}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{costCenter?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{costCenter?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                {[
                  <Tab key={0} title="Histórico">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>
                ]}
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${costCenter?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveItem}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveItem(Number(costCenter?.id))
        }
        isActive={alertRemoveItem}
      />
    </>
  )
}

export default CostCentersView
