import React, { createContext, useContext, useState } from 'react'
import { TypeComposition } from '../../domain/models/typeProduct'

type ProductContextType = {
  composition: TypeComposition[]
  setComposition: React.Dispatch<React.SetStateAction<TypeComposition[]>>
}

const ProductContext = createContext<ProductContextType>(
  {} as ProductContextType
)

type ProductProviderParams = {
  children: JSX.Element
}

export const ProductProvider = ({ children }: ProductProviderParams) => {
  const [composition, setComposition] = useState<TypeComposition[]>([
    { id: 0, stock_id: 0, name: '', price_sale: '', product_id: 0 }
  ])

  return (
    <ProductContext.Provider
      value={{
        composition,
        setComposition
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const useProduct = (): ProductContextType => {
  const context = useContext(ProductContext)
  if (!context) throw new Error('Context Product context not found')
  return context
}
