import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DropdownList,
  Input,
  Select
} from '../../../../../../../components/FormProvider'
import { Date } from '../../../../../../../components/FormProvider/date'
import { useLoading } from '../../../../../../../hooks/loading'
import { useToast } from '../../../../../../../hooks/toast'
import {
  zipCodeMask,
  phoneMask,
  cnpjMask,
  cpfMask
} from '../../../../../../../utlis/mask'

import { Container } from './styles'
import { useClient } from '../../../providers/ClientProvider'
import api from '../../../../../../../services/api'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'

export const OverviewTab = ({
  type
}: {
  type: 'create' | 'update'
}): JSX.Element => {
  const { setValue } = useFormContext()

  const {
    initialValues,
    originsOptions,
    segmentsOptions,
    subSegmentsOptions: subSegmentsOptionsData,
    allSegmentsOptions,
    clientsTypesOptions,
    setClientQueryData,
    setContacts
  } = useClient()

  const [personType, setPersonType] = useState('PJ')

  const [cnpjCpf, setCnpjCpf] = useState<string>()
  const [subSegmentsOptions, setSubSegmentsOptions] = useState([])
  const [subSegmentsSelected, setSubSegmentsSelected] = useState<
    number | string
  >()

  const [name, setName] = useState<string>()
  const [fantasyName, setFantasyName] = useState<string>()

  const [zipCode, setZipCode] = useState({
    search: false,
    code: undefined
  })
  const [street, setStreet] = useState<string>()
  const [complement, setComplement] = useState<string>()
  const [number, setNumber] = useState<string>()
  const [district, setDistrict] = useState<string>()
  const [city, setCity] = useState<string>()
  const [uf, setUf] = useState<string>()

  const [openDate, setOpenDate] = useState<Date>()

  const [phone1, setPhone1] = useState<string>()
  const [phone2, setPhone2] = useState<string>()
  const [cellphone, setCellphone] = useState<string>()

  const [segmentSelected, setSegmentSelected] = useState<number | string>()
  const [contributorType, setContributorType] = useState<number>()

  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const segmentsWithSubSegmentIds = useMemo(() => {
    return subSegmentsOptionsData.map(item => item.parent_id) || []
  }, [subSegmentsOptionsData])

  useEffect(() => {
    if (initialValues?.person.type) {
      setPersonType(initialValues.person.type)
    }
  }, [initialValues])

  useEffect(() => {
    if (initialValues?.client) {
      const filterSubSegments = allSegmentsOptions.filter(
        (segment: any) =>
          segment.parent_id === Number(initialValues?.client?.segment_id)
      )
      setSubSegmentsOptions(filterSubSegments)
      setSegmentSelected(Number(initialValues?.client?.segment_id) || ' ')
      setSubSegmentsSelected(
        Number(initialValues?.client?.sub_segment_id) || ' '
      )
    }
  }, [initialValues, allSegmentsOptions])

  const searchZipCode = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${zipCode.code}/json/`
      )
      if (data.erro) {
        addToast({
          type: 'error',
          title: 'CEP inválido',
          description: 'CEP inválido, por favor, tente novamente.'
        })
      }
      if (!data.erro) {
        setStreet(data.logradouro)
        setDistrict(data.bairro)
        setCity(data.localidade)
        setUf(data.uf)
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao consultar CEP',
        description:
          'Erro ao consultar o CEP na api ViaCEP, por favor, tente novamente.'
      })
    }
  }, [addToast, zipCode])

  useEffect(() => {
    if (zipCode?.search && zipCode?.code?.length === 9) {
      activeLoading()
      searchZipCode()
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading, searchZipCode, zipCode])

  const searchCnpjCpf = useCallback(
    async (document: string, type: string) => {
      try {
        activeLoading()
        const lemitResponse = await api.post('apis/lemit/query', {
          client_id: initialValues?.client.id,
          document,
          type
        })
        const data = lemitResponse.data
        if (data.client) {
          let contactsLemit: any[] = []
          const phones = data.client[type].fixos || []
          const cellphones = data.client[type].celulares || []
          const emails = data.client[type].emails || []
          const maxContacts = [phones.length, cellphones.length, emails.length]
            .sort((a, b) => a - b)
            .reverse()[0]

          for (let i = 0; i < maxContacts; i += 1) {
            let contactPhone = phones[i]
            if (contactPhone) {
              contactPhone = phoneMask(
                `${contactPhone.ddd}${contactPhone.numero}`
              )
            }
            let contactCellphone = cellphones[i]
            if (contactCellphone) {
              contactCellphone = phoneMask(
                `${contactCellphone.ddd}${contactCellphone.numero}`
              )
            }
            let email = emails[i]
            if (email) {
              email = email.email
            }
            const contact = {
              name: `Contato ${i + 1}`,
              email,
              phone_number: contactPhone,
              cellphone_number1: contactCellphone,
              show: true
            }
            contactsLemit.push(contact)
          }

          if (initialValues?.person?.contacts) {
            contactsLemit = contactsLemit.filter(contact => {
              let hasContact = false
              initialValues?.person?.contacts?.forEach((pContact: any) => {
                if (
                  pContact.email === contact.email ||
                  phoneMask(pContact.phone_number || '') ===
                    contact.phone_number ||
                  phoneMask(pContact.cellphone_number1 || '') ===
                    contact.cellphone_number1
                ) {
                  hasContact = true
                }
              })
              return !hasContact
            })
          }
          if (contactsLemit.length) {
            if (!initialValues) {
              setContacts(() => {
                return [...contactsLemit]
              })
            } else {
              setContacts(prev => {
                const copy = JSON.parse(JSON.stringify(prev))
                return [...copy, ...contactsLemit]
              })
            }
          }

          setClientQueryData(data)
          addToast({
            type: 'success',
            title: 'Dados encontrados'
          })
          const address =
            type === 'pessoa'
              ? data.client.pessoa?.enderecos?.[0]
              : data.client.empresa?.endereco
          if (address) {
            setZipCode({ search: false, code: zipCodeMask(address?.cep) })
            setStreet(address.logradouro)
            setNumber(address.numero)
            setComplement(address.complemento)
            setDistrict(address.bairro)
            setCity(address.cidade)
            setUf(address.uf)
          }

          if (type === 'pessoa') {
            setName(data.client.pessoa.nome)
            setValue(
              'person.date_opening_birth',
              moment(data.client.pessoa.data_nascimento, 'YYYY-MM-DD').toDate()
            )
          }
          if (type === 'empresa') {
            setName(data.client.empresa.razao_social)
            setFantasyName(
              data.client.empresa.nome_fantasia ||
                data.client.empresa.razao_social
            )
            setValue(
              'person.date_opening_birth',
              moment(data.client.empresa.data_fundacao, 'YYYY-MM-DD').toDate()
            )

            try {
              const publicaCnpjResponse = await api.post(
                'apis/publicaCnpj/query',
                {
                  client_id: initialValues?.client.id,
                  document,
                  type
                }
              )
              const dataPublica = publicaCnpjResponse.data

              const stateRegistration =
                dataPublica.client.empresa.inscricao_estadual
                  ?.inscricao_estadual
              if (stateRegistration) {
                setValue('person.state_registration', stateRegistration)
              }
            } catch (error) {
              console.log('Erro ao consultar Publica api')
            }
          }

          const phone1 = data.client[type].fixos?.[0]
          const phone2 = data.client[type].fixos?.[1]
          const cellphone = data.client[type].celulares?.[0]
          if (phone1) {
            setPhone1(phoneMask(`${phone1.ddd}${phone1.numero}`))
          }
          if (phone2) {
            setPhone2(phoneMask(`${phone2.ddd}${phone2.numero}`))
          }
          if (cellphone) {
            setCellphone(phoneMask(`${cellphone.ddd}${cellphone.numero}`))
          }

          disableLoading()
        }
      } catch (error: any) {
        console.log(error)
        if (error.response?.data?.message === 'Cnpj/Cpf já cadastrado') {
          setCnpjCpf('')
        }
        addToast({
          type: 'error',
          title: error.response?.data?.message || 'Erro ao consultar CPF/CNPJ'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      setClientQueryData,
      setValue,
      initialValues,
      setContacts
    ]
  )

  useEffect(() => {
    if (!personType) {
      return
    }
    if (personType === 'PF' && cnpjCpf?.length === 14) {
      activeLoading()
      searchCnpjCpf(cnpjCpf, 'pessoa')
      disableLoading()
    } else if (personType === 'PJ' && cnpjCpf?.length === 18) {
      activeLoading()
      searchCnpjCpf(cnpjCpf, 'empresa')
      disableLoading()
    }
  }, [
    activeLoading,
    addToast,
    disableLoading,
    searchCnpjCpf,
    cnpjCpf,
    personType
  ])

  console.log(type)

  return (
    <Container>
      <div className="row">
        <div className="col-md-4">
          <Input name="client.id" style={{ display: 'none' }} />
          <Input name="person.id" style={{ display: 'none' }} />
          <Input name="address.id" style={{ display: 'none' }} />

          <div style={{ display: 'flex' }}>
            <Input
              type="radio"
              id="pf"
              style={{ marginLeft: '10px', marginRight: '5px' }}
              name="person.type"
              value="PF"
              checked={personType === 'PF'}
              onChange={e => {
                setCnpjCpf('')
                setPersonType(e.target.value)
              }}
            />
            <label htmlFor="pf">Pessoa Física</label>
            <Input
              type="radio"
              id="pj"
              style={{ marginLeft: '10px', marginRight: '5px' }}
              name="person.type"
              value="PJ"
              checked={personType === 'PJ'}
              onChange={e => {
                setCnpjCpf('')
                setPersonType(e.target.value)
              }}
            />
            <label htmlFor="pj">Pessoa Jurídica</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          {personType === 'PJ' ? (
            <Input
              label="CNPJ"
              name="person.cnpj_cpf"
              className="form-control"
              onChange={e => setCnpjCpf(cnpjMask(e.target.value))}
              value={cnpjCpf}
              maxLength={18}
              rules={{ required: true }}
            />
          ) : (
            <Input
              label="CPF"
              name="person.cnpj_cpf"
              className="form-control"
              onChange={e => setCnpjCpf(cpfMask(e.target.value))}
              value={cnpjCpf}
              maxLength={14}
              rules={{ required: true }}
            />
          )}
        </div>
        <div className="col-md-2">
          <Select
            label="Tipo de cliente"
            className="form-control"
            name="client.client_type_id"
            options={clientsTypesOptions}
            rules={{ required: true }}
            blank
            defaultValue={''}
          />
        </div>
        <div className="col-md-6 mt-0">
          <DropdownList
            label="Segmento"
            name="client.segment_id"
            options={segmentsOptions.filter(segment => {
              if (type === 'create') {
                return segmentsWithSubSegmentIds.includes(Number(segment.value))
              }
              return true
            })}
            rules={{ required: true }}
            className="container-multi-select max-height-34px"
            filter="contains"
            currentValue={segmentSelected}
            setCurrentValue={data => {
              setSegmentSelected(data)
              const filterSubSegments = subSegmentsOptionsData.filter(
                (segment: any) => segment.parent_id === Number(data)
              )
              setSubSegmentsOptions(filterSubSegments)
            }}
          />
        </div>
        <div className="col-md-2 mt-0">
          <DropdownList
            label="Subsegmento"
            name="client.sub_segment_id"
            options={subSegmentsOptions}
            rules={{ required: true }}
            className="container-multi-select max-height-34px"
            filter="contains"
            disabled={!subSegmentsOptions?.length}
            currentValue={subSegmentsSelected}
            setCurrentValue={setSubSegmentsSelected}
          />
        </div>
      </div>
      <div className="row">
        {personType === 'PJ' ? (
          <>
            <div className="col-md-4">
              <Input
                label="Razão social"
                name="person.name"
                className="form-control"
                value={name}
                onChange={e => setName(e.target.value)}
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Nome fantasia"
                name="person.fantasy_name"
                className="form-control"
                value={fantasyName}
                onChange={e => setFantasyName(e.target.value)}
                rules={{ required: true }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-md-4">
              <Input
                label="Nome"
                name="person.name"
                className="form-control"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="RG"
                name="person.document"
                className="form-control"
              />
            </div>
            <div className="col-md-2">
              <Date
                label="Data de nascimento"
                name="person.date_opening_birth"
                className="form-control"
                selected={openDate}
                onChange={date => setOpenDate(date)}
                rules={{ required: true }}
              />
            </div>
          </>
        )}
        <div className="col-md-2">
          <Select
            label="Tipo captação"
            className="form-control"
            name="client.capture_type"
            options={[
              { name: 'ATIVA', value: 'A' },
              { name: 'PASSIVA', value: 'P' }
            ]}
            rules={{ required: true }}
            blank
            defaultValue={''}
          />
        </div>
        <div className="col-md-2">
          <Select
            label="Origem"
            className="form-control"
            name="client.client_origin_id"
            options={originsOptions}
            rules={{ required: true }}
            blank
            defaultValue={''}
          />
        </div>
        <div className="col-md-2">
          <Select
            label="Consumidor final"
            className="form-control"
            name="client.final_consumer"
            options={[
              { name: 'SIM', value: 1 },
              { name: 'NAO', value: 0 }
            ]}
            blank
            defaultValue={''}
          />
        </div>
      </div>
      {personType === 'PJ' && (
        <div className="row">
          <>
            <div className="col-md-2">
              <Date
                label="Data de abertura empresa"
                name="person.date_opening_birth"
                className="form-control"
                selected={openDate}
                onChange={date => setOpenDate(date)}
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Tipo contribuinte"
                options={[
                  { name: 'CONTRIBUINTE ICMS', value: 1 },
                  { name: 'CONTRIBUINTE ISENTO', value: 2 },
                  { name: 'NÃO CONTRIBUINTE', value: 9 }
                ]}
                name="person.contributor_type"
                className="form-control"
                rules={{ required: true }}
                onChange={e => setContributorType(Number(e.target.value))}
                blank
                defaultValue={''}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Inscrição Estadual"
                name="person.state_registration"
                className="form-control"
                rules={{ required: [1, 2].includes(contributorType) }}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Inscrição Municipal"
                name="person.city_registration"
                className="form-control"
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Inscrição SUFRAMA"
                name="person.suframa_registration"
                className="form-control"
              />
            </div>
          </>
        </div>
      )}
      <div className="row">
        <div className="col-md-2">
          <Input
            label="CEP"
            name="address.zip_code"
            className="form-control"
            value={zipCode.code}
            onChange={e => {
              setZipCode({ search: true, code: zipCodeMask(e.target.value) })
            }}
            maxLength={9}
            onKeyPress={event => {
              const regex = /^[0-9.]+$/
              if (!regex.test(event.key)) event.preventDefault()
            }}
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-8">
          <Input
            label="Logradouro"
            name="address.street"
            className="form-control"
            value={street}
            onChange={e => setStreet(e.target.value)}
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-2">
          <Input
            label="Número"
            name="address.number"
            className="form-control"
            value={number}
            onChange={e => setNumber(e.target.value)}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <Input
            label="Complemento"
            name="address.complement"
            className="form-control"
            value={complement}
            onChange={e => setComplement(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Bairro"
            name="address.district"
            className="form-control"
            value={district}
            onChange={e => setDistrict(e.target.value)}
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Cidade"
            name="address.city"
            className="form-control"
            value={city}
            onChange={e => setCity(e.target.value)}
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-2">
          <Input
            label="UF"
            name="address.state"
            className="form-control"
            value={uf}
            onChange={e => setUf(e.target.value)}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <Input
            label="Telefone fixo 1"
            name="client.phone_number1"
            className="form-control"
            value={phone1}
            onChange={e => setPhone1(phoneMask(e.target.value))}
            maxLength={15}
          />
        </div>
        <div className="col-md-2">
          <Input
            label="Telefone fixo 2"
            name="client.phone_number2"
            className="form-control"
            value={phone2}
            onChange={e => setPhone2(phoneMask(e.target.value))}
            maxLength={15}
          />
        </div>
        <div className="col-md-2">
          <Input
            label="Celular principal"
            name="client.cellphone_number"
            className="form-control"
            value={cellphone}
            onChange={e => setCellphone(phoneMask(e.target.value))}
            maxLength={15}
          />
        </div>
      </div>
    </Container>
  )
}
