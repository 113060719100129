import React from 'react'
import List from '../../pages/Financial/PaymentRemittanceFiles/List'
import Create from '../../pages/Financial/PaymentRemittanceFiles/Create'
import View from '../../pages/Financial/PaymentRemittanceFiles/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PaymentRemittanceFilesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/paymentRemittanceFiles" exact component={List} />
    <Route
      path="/financial/paymentRemittanceFiles/create"
      component={Create}
      exact
    />
    <Route
      path="/financial/paymentRemittanceFiles/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
