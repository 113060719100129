import React from 'react'
import CommissionReleaseByRange from '../../pages/Commercial/CommissionReleaseByRange'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const CommissionReleaseByRangeRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/commercial/commissionReleaseByRange"
      exact
      component={CommissionReleaseByRange}
    />
  </CustomSwitch>
)
