import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import '../../assets/global/plugins/datatables/datatables.min.css'
import { LinkContainer } from './style'
import { useUpdateDataTable } from '../../hooks/dataTable'
import { useLoading } from '../../hooks/loading'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading } from '../Loading'
import moment from 'moment'

interface Action {
  name: string
  icon?: IconDefinition
  spanIcon?: string
  htmlIcon?: JSX.Element
  title: string
  link?: string
  onClick?: (params: any) => void
  linkTo?: (params: any) => string
}

interface Header {
  name: string
  field: string
  sortable: boolean
  custom?: boolean
  element?: (item: any) => JSX.Element
  tdStyle?: React.CSSProperties
}

interface DataTableProps {
  headers?: Header[]
  customHeaders?: Header[]
  actions?: Action[]
  search?: boolean
  pagination?: boolean
  viewField?: { source: string; field: string }
  customItems: any[]
  noBorder?: boolean
}

const CustomDataTable = ({
  headers,
  search,
  pagination,
  customHeaders,
  actions,
  customItems,
  noBorder
}: DataTableProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState<any[]>([])
  const [filterItems, setFilterItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(200)
  const { isUpdated } = useUpdateDataTable()

  const { activeLoading, disableLoading } = useLoading()

  const loadParams = useCallback(() => {
    const params = {
      keyword: '',
      page: currentPage,
      perPage: ItemsPerPage
    }

    return params
  }, [ItemsPerPage, currentPage])

  useEffect(() => {
    ;(async () => {
      try {
        setItems(customItems)
        setFilterItems(customItems)
        setTotalItems(customItems.length)
        setCurrentPage(1)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error(error)
      }
    })()
  }, [isUpdated, activeLoading, disableLoading, customItems])

  const getSearched = async () => {
    const params = loadParams()
    const response = await api.get('dataTable', {
      params: {
        ...params
      }
    })
    setItems(response.data.items)
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    if (value.length === 0) {
      setItems(filterItems)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => getSearched(), 500)
  }

  return (
    <div className="dataTables_wrapper no-footer">
      <Loading isActive={isLoading} />
      {(pagination || search) && (
        <div className="row">
          {pagination && (
            <div className="col-md-6 col-sm-6">
              <div className="dataTables_length">
                <label>
                  <select
                    onChange={e => setItemsPerPage(Number(e.target.value))}
                    className="form-control input-sm input-xsmall input-inline"
                    defaultValue="200"
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  resultados por página
                </label>
              </div>
            </div>
          )}
          {search && (
            <div className="col-md-6 col-sm-6">
              <div className="dataTables_filter">
                <label>
                  Pesquisar
                  <Search onSearch={value => onSearchItem(value)} />
                </label>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className="table-scrollable"
        style={{ borderWidth: noBorder ? '0px' : '1px' }}
      >
        <table
          className={
            noBorder
              ? 'table table-striped table-hover dt-responsive dtr-inline'
              : 'dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline'
          }
        >
          <TableHeader
            headers={headers}
            onSorting={(field, order, type) => {
              const itemSorted = items.sort((a, b) => {
                const fields = field.split('.')
                let currentFieldA
                let currentFieldB
                if (fields.length === 1) {
                  currentFieldA = a[fields[0]]
                  currentFieldB = b[fields[0]]
                }
                if (fields.length === 2) {
                  currentFieldA = a[fields[0]]?.[fields[1]]
                  currentFieldB = b[fields[0]]?.[fields[1]]
                }
                if (fields.length === 3) {
                  currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                  currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                }
                if (type === 'date') {
                  if (
                    moment(currentFieldA, 'DD/MM/YYYY').isValid() &&
                    moment(currentFieldB, 'DD/MM/YYYY').isValid()
                  ) {
                    const dateIsAfter =
                      order === 'ASC'
                        ? moment(currentFieldA, 'DD/MM/YYYY').isAfter(
                            moment(currentFieldB, 'DD/MM/YYYY')
                          )
                        : moment(currentFieldA, 'DD/MM/YYYY').isBefore(
                            moment(currentFieldB, 'DD/MM/YYYY')
                          )

                    return dateIsAfter ? 1 : -1
                  }
                }
                if (type === 'monetary') {
                  return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                    Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                    order === 'ASC'
                    ? 1
                    : -1
                }
                return currentFieldA > currentFieldB && order === 'ASC' ? 1 : -1
              })
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                const types = [
                  { key: 'locacao', label: 'LOCAÇÃO' },
                  { key: 'L', label: 'LOCAÇÃO' },
                  { key: 'materia-prima', label: 'MATERIA PRIMA' },
                  { key: 'revenda', label: 'REVENDA' },
                  { key: 'semi-acabado', label: 'SEMI ACABADO' },
                  { key: 'consumo', label: 'USO E CONSUMO' },
                  { key: 'CONSUMO', label: 'USO E CONSUMO' },
                  { key: 'venda', label: 'VENDA' },
                  { key: 'V', label: 'VENDA' },
                  { key: 'M', label: 'MANUTENÇÃO' }
                ]
                const typeProduct = types.find(
                  t => t.key === item.product?.type
                )
                if (typeProduct) {
                  item.product.type = typeProduct.label
                }
                const type = types.find(t => t.key === item.type)
                if (type) {
                  item.type = type.label
                }
                return (
                  <tr key={item.id}>
                    {headers.map(header =>
                      header?.field !== 'actions' && !header.custom ? (
                        <td
                          key={`${header?.field}-${item.id}`}
                          style={header.tdStyle}
                        >
                          <p
                            style={{
                              textAlign: 'left'
                            }}
                          >
                            {typeof item[header?.field] === 'boolean' &&
                              (item[header?.field] ? 'Sim' : 'Nâo')}
                            {header?.field.split('.').length === 3 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]?.[header?.field.split('.')[2]]}
                            {header?.field.split('.').length === 2 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]}
                            {header?.field.split('.').length === 1 &&
                              item[header?.field]}
                          </p>
                        </td>
                      ) : header.custom ? (
                        <td style={header.tdStyle}>
                          {customHeaders
                            .find(h => h.field === header.field)
                            .element(item)}
                        </td>
                      ) : (
                        <td key={`actions-${item.id}`} className="actions">
                          {actions && (
                            <LinkContainer>
                              {actions.map(action => (
                                <Link
                                  to={
                                    action.link ||
                                    (action.linkTo && action.linkTo(item)) ||
                                    '#'
                                  }
                                  key={action.name}
                                  title={action.title}
                                  onClick={e => {
                                    if (action.onClick) {
                                      e.preventDefault()
                                      action.onClick(item)
                                    }
                                  }}
                                >
                                  {action.spanIcon && (
                                    <span className={action.spanIcon} />
                                  )}
                                  {action.icon && (
                                    <FontAwesomeIcon icon={action.icon} />
                                  )}
                                  {action.htmlIcon && action.htmlIcon}
                                </Link>
                              ))}
                            </LinkContainer>
                          )}
                        </td>
                      )
                    )}
                  </tr>
                )
              })) || (
              <tr>
                <td colSpan={headers.length}>Nenhum registro encontrado</td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      {pagination && (
        <div className="row">
          <div className="col-md-5 col-sm-5">
            <div className="dataTables_info">
              Mostrando {totalItems} registros
            </div>
          </div>
          <div className="col-md-7 col-sm-7">
            <div className="dataTables_paginate paging_bootstrap_number">
              <Pagination
                total={totalItems}
                itemsPerPage={ItemsPerPage}
                currentPage={currentPage}
                onPageChange={page => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomDataTable
