export const headers = [
  {
    name: 'Data da solicitação',
    field: 'created_at',
    sortable: true
  },
  { name: 'Nº Requisição', field: 'id', sortable: true },
  { name: 'Etapa', field: 'situation', sortable: true, custom: true },
  {
    name: 'Data Limite',
    field: 'situation_expiration',
    sortable: true,
    custom: true
  },
  {
    name: 'Observação',
    field: 'description',
    custom: false,
    sortable: true
  },

  {
    name: 'Solicitante',
    field: 'user_id',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const logsHeaders = [
  {
    name: 'Data',
    field: 'date',
    sortable: true
  },
  {
    name: 'Etapa',
    field: 'new_situation',
    sortable: true,
    custom: true
  },
  {
    name: 'Data limite',
    field: 'situation_expiration',
    sortable: true,
    custom: true
  },
  {
    name: 'Data Realizada',
    field: 'realized_at',
    sortable: true,
    custom: true
  },
  {
    name: 'Observação',
    field: 'description',
    sortable: true
  },
  { name: 'Realizado por', field: 'user.name', sortable: true }
]

export const productsHeaders = [
  { name: 'Produto', field: 'stock.product.name', sortable: true },
  { name: 'Variação', field: 'stock.name', sortable: true },
  { name: 'Quantidade', field: 'quantity', sortable: true }
]
