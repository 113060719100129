import React, { useState } from 'react'
import api from '../../../../../../services/api'
import Form, { Select, Textarea } from '../../../../../../components/Form'
import Button from '../../../../../../components/Button'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { Date } from '../../../../../../components/Form/date'
import moment from 'moment'
import { TooltipComponent } from '../../../../../../components/TooltipComponent'

interface TypesFormProps {
  setModal: any
  loadOrderExpedition: any
  statusList: any[]
  currentStatus: number
  rollback: boolean
  id: number
}

export const FormSituation = ({
  setModal,
  loadOrderExpedition,
  statusList,
  currentStatus,
  rollback,
  id
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [newStatus, setNewStatus] = useState('')
  const [startDate, setStartDate] = useState<Date>()

  const onSubmitForm = async (data: any) => {
    activeLoading()
    const url = 'warehouse/expedition/orders/situation'
    try {
      await api.post(url, { order_id: id, ...data })
      addToast({
        type: 'success',
        title: 'Registro atualizado',
        description: 'Registro alterado com sucesso'
      })
      loadOrderExpedition()
      setModal(false)
    } catch (error: any) {
      const message = error?.response?.data?.message
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description:
          message ||
          'Ocorreu um erro ao atualizar o cadastro, por favor, tente novamente.'
      })
    }
    disableLoading()
  }
  let statusFiltered = []
  const statusPosition = statusList.find(
    sl => Number(sl.id) === currentStatus
  )?.position
  const selectedStatus = statusList.find(
    sl => Number(sl.id) === Number(newStatus)
  )

  statusFiltered = statusList.filter(
    f =>
      (Number(f.position) - 1 === Number(statusPosition) ||
        Number(f.position) + 1 === Number(statusPosition)) &&
      Number(f.position) !== 0
  )
  if (rollback) {
    statusFiltered = statusList
      .filter(
        f =>
          Number(f.position) < Number(statusPosition) &&
          Number(f.position) !== 0
      )
      .sort((a, b) => a.position - b.position)
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div style={{ width: '800px' }}>
          <div className="row" style={{ marginTop: '0px' }}>
            <div className="col-md-6">
              <Select
                name="status"
                className="form-control"
                label="Etapa"
                options={statusFiltered.map(s => ({
                  name: s.name,
                  value: s.id
                }))}
                rules={{ required: true }}
                onChange={e => setNewStatus(e.target.value)}
                blank
                defaultValue={''}
              />
            </div>
            {(selectedStatus?.position === 4 ||
              selectedStatus?.position === 5 ||
              selectedStatus?.position === 6) && (
              <div className="col-md-5">
                {selectedStatus.position === 5 && (
                  <TooltipComponent
                    label={'Data limite para entrega no cliente'}
                    message={
                      'Data que foi informado ao cliente o dia limite que ele receberá o seu pedido. Quem inclui a data limite para entrega é o setor Compras, ele se baseia na informação que está no pedido feito pelo setor Comercial.'
                    }
                  />
                )}
                <Date
                  label={
                    selectedStatus?.position === 4
                      ? 'Data limite para coleta do pedido'
                      : selectedStatus.position === 6
                      ? 'Data que foi entregue o pedido no cliente'
                      : undefined
                  }
                  className="form-control"
                  rules={{ required: true }}
                  name="expiration"
                  onChange={date => setStartDate(date)}
                  selected={startDate}
                  minDate={moment().toDate()}
                  controlled
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="form-content col-md-12">
              <div className="form-group">
                <Textarea
                  label="Observações"
                  className="form-control"
                  name="description"
                  rows={6}
                  rules={{ required: true }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
