import React from 'react'
import List from '../../pages/Financial/Payrolls/List'
import Create from '../../pages/Financial/Payrolls/Create'
import View from '../../pages/Financial/Payrolls/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PayrollsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/payrolls" exact component={List} />
    <Route path="/financial/payrolls/create" component={Create} exact />
    <Route path="/financial/payrolls/view/:id" component={View} exact />
  </CustomSwitch>
)
