export const headers = [
  { name: 'Pedido', field: 'id', sortable: false },
  { name: 'Categoria', field: 'type', sortable: false },
  { name: 'Data', field: 'date', sortable: false, custom: true },
  { name: 'Frete', field: 'freight_value', sortable: false },
  { name: 'Ipi', field: 'ipi_value', sortable: false },
  { name: 'Valor total', field: 'total_value', sortable: false },
  {
    name: 'Data de entrega',
    field: 'delivery_date',
    sortable: false,
    custom: true
  },
  { name: 'Expedição', field: 'expedition.name', sortable: false },
  { name: 'Faturado', field: 'total_value', sortable: false },
  { name: 'Fatura/NF', field: 'invoice_id', sortable: false, custom: true },
  { name: 'Pagamento', field: 'pay_with', sortable: false },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]
