import React from 'react'
import List from '../../../pages/Warehouse/product/ProductUnitMeasured/List'
import Update from '../../../pages/Warehouse/product/ProductUnitMeasured/Update'
import Create from '../../../pages/Warehouse/product/ProductUnitMeasured/Create'
import View from '../../../pages/Warehouse/product/ProductUnitMeasured/View'
import Route from '../../Route'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const ProductUnitMeasuredRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/productUnitMeasured" exact component={List} />
    <Route
      path="/warehouse/productUnitMeasured/update/:id"
      component={Update}
      exact
    />
    <Route
      path="/warehouse/productUnitMeasured/create"
      component={Create}
      exact
    />
    <Route
      path="/warehouse/productUnitMeasured/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
