import React, { createContext, useContext, useCallback, useState } from 'react'

import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import moment from 'moment'

type ClientContextType = {
  getClient: (id: string) => any
  saveClient: (data: any, id?: string) => Promise<string>
  getOptions: () => Promise<void>
  initialValues: any
  originsOptions: { name: string; value: string }[]
  carriersOptions: { name: string; value: string }[]
  segmentsOptions: { name: string; value: string }[]
  economicGroupsOptions: { name: string; value: string }[]
  clientsTypesOptions: { name: string; value: string }[]
  financialTransactions: any[]
}

const ClientContext = createContext<ClientContextType>({} as ClientContextType)

type ClientProviderParams = {
  children: JSX.Element
}

export const ClientProvider = ({ children }: ClientProviderParams) => {
  const { activeLoading, disableLoading } = useLoading()
  const [initialValues, setInitialValues] = useState<any>()

  const [originsOptions, setOriginsOptions] = useState([])
  const [carriersOptions, setCarriersOptions] = useState([])
  const [segmentsOptions, setSegmentsOptions] = useState([])
  const [economicGroupsOptions, setEconomicGroupsOptions] = useState([])
  const [clientsTypesOptions, setClientsTypesOptions] = useState([])

  const [financialTransactions, setFinancialTransactions] = useState([])

  const getOptions = useCallback(async () => {
    const { data } = await api.get('commercial/clients/selectOptions')
    setOriginsOptions(
      data.origins?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setCarriersOptions(
      data.carriers?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setSegmentsOptions(
      data.segments?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setEconomicGroupsOptions(
      data.clientEconomicGroups?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setClientsTypesOptions(
      data.clientsTypes?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
  }, [])

  const getClient = useCallback(
    async (id: string) => {
      activeLoading()
      await getOptions()
      const { data } = await api.get(`commercial/clients/view/${id}`)
      if (data.financialTransactions) {
        setFinancialTransactions(data.financialTransactions)
      }
      const initialValues = {
        client: {
          ...data,
          adicional_informations_portals: JSON.parse(
            data.adicional_informations_portals
          )
        },
        person: {
          ...data.person,
          state_registration_free: data?.person?.state_registration_free
            ? 'true'
            : 'false',
          city_registration_free: data?.person?.city_registration_free
            ? 'true'
            : 'false',
          suframa_registration_free: data?.person?.suframa_registration_free
            ? 'true'
            : 'false',
          date_opening_birth: data.person?.date_opening_birth
            ? moment(data.person?.date_opening_birth, 'DD/MM/YYYY HH:mm:ss')
                .add(3, 'h')
                .toDate()
            : undefined
        },
        segment: data.segment,
        subSegment: data.subSegment,
        payment: [
          data.paymentMethod.find(
            (payment: { type: string }) => payment.type === 'sale'
          ),
          data.paymentMethod.find(
            (payment: { type: string }) => payment.type === 'location'
          )
        ],
        address: data.person?.addresses[0],
        contact: data.person?.contacts.filter((item: any) => !item.deleted_at),
        email: data.emails,
        seller: data.seller,
        locationSeller: data.locationSeller,
        saleSeller: data.saleSeller,
        freight: data.freightOptions
      }
      setInitialValues(initialValues)
      disableLoading()
      return initialValues
    },
    [activeLoading, disableLoading, getOptions]
  )

  const saveClient = useCallback(async (client: any, id?: string) => {
    try {
      if (id) {
        const { data } = await api.put(
          `commercial/clients/update/${id}`,
          client
        )
        if (data.error) {
          return false
        }
        return data.id
      }
      const { data } = await api.post('commercial/clients/create', client)
      if (data.error) {
        return false
      }
      return data.id
    } catch (error) {
      return false
    }
  }, [])

  return (
    <ClientContext.Provider
      value={{
        getClient,
        saveClient,
        getOptions,
        initialValues,
        originsOptions,
        carriersOptions,
        segmentsOptions,
        economicGroupsOptions,
        clientsTypesOptions,
        financialTransactions
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClient = (): ClientContextType => {
  const context = useContext(ClientContext)
  if (!context) throw new Error('Context Client context not found')
  return context
}
