import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select } from '../../../../../components/Form'
import Button from '../../../../../components/Button'

type DigitalCertificateData = {
  company_id: number
  file: string
  password: string
  user_id: number
  user_name?: string
}

type TypesFormProps = {
  initialValues?: DigitalCertificateData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormDigitalCertificates = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<DigitalCertificateData>()
  const [companies, setCompanies] = useState([])
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        company_id: initialValues.company_id,
        file: initialValues.file,
        password: initialValues.password,
        user_id: initialValues.user_id,
        user_name: initialValues.user_name
      })
    }
  }, [initialValues])

  useEffect(() => {
    const load = async () => {
      const companies = await api.get('financial/configurations')
      setCompanies(companies.data)
    }
    load()
  }, [])

  const onSubmit = async (data: DigitalCertificateData) => {
    if (typeForm === 'create') {
      try {
        const dataCreate = {
          ...data
        }
        activeLoading()
        await api.post(apiCreate(), dataCreate)
        disableLoading()
        history.push(nameActions.read.to)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
        updateDataTable()
      }
    } else {
      const dataUpdate = {
        ...data
      }
      const id = initialValues?.idUpdate

      try {
        activeLoading()
        await api.put(apiUpdate(String(id)), dataUpdate)
        updateDataTable()
        disableLoading()
        history.push(nameActions.read.to)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        history.push(nameActions.read.to)
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <div className="row">
        <div className="col-md-3">
          <Select
            options={companies.map(({ id, name }) => ({
              name,
              value: id
            }))}
            label="Empresa"
            name="company_id"
            className="form-control"
            defaultValue={''}
            blank
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Arquivo"
            name="file"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Senha"
            name="password"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
