import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Container, ControlPanel } from './styles'
import { BRL } from '../../../../../../utlis/mask'
import currency from 'currency.js'
import * as am4core from '@amcharts/amcharts4/core'
import api from '../../../../../../services/api'
import { HeaderPortlet } from '../../../components/HeaderPortlet'
import { TablePortlet } from '../../../../../../components/TablePortlet'
import { AmChartMixedColumn } from '../../../../../../components/AmChartMixedColumn'
import { AmChartPizza } from '../../../../../../components/AmChartPizza'
import { CustomSummary } from '../../../../../../components/CustomSummary'
import moment from 'moment'
import {
  MONTHS,
  MONTHS_ORDER,
  TRANSLATE_MONTHS
} from '../../../../../../common/constants'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../../components/Loading'
import {
  SUM_TOTAL_FIELD_NAME,
  SUM_TOTAL_FIELD_TYPE,
  WITHOUT_CATEGORY_FIELD_NAME,
  WITHOUT_CATEGORY_FIELD_TYPE
} from '../../../../../Financial/CashFlowAnalysis'

const CURRENT_YEAR_DATE = moment().format('YYYY')
const CURRENT_MONTH_DATE = moment().format('MM')
const PREVIOUS_YEAR_DATE = moment().subtract(1, 'year').format('YYYY')
const LAST_YEAR_DATE = moment().subtract(2, 'year').format('YYYY')

export const ResumeTab = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const [financeIncomeData, setFinanceIncomeData] = useState<any>()
  const [financeIncomePeriodData, setFinanceIncomePeriodData] = useState<any>(
    []
  )
  const [salesSummary, setSalesSummary] = useState([])
  const [incomeData, setIncomeData] = useState([])
  const [groupProduct, setGroupProduct] = useState([])
  const [dataUpdateGroupProduct, setDataUpdateGroupProduct] = useState([])
  const [dataUpdateMixed, setDataUpdateMixed] = useState([])
  const [selectedTableRow, setSelectedTableRow] = useState({
    name: '',
    child: {
      name: ''
    }
  })
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'))
  const currentMonthName = useMemo(
    () => TRANSLATE_MONTHS[moment().locale('en-US').format('MMMM')],
    []
  )
  const [previousYear, SetPreviousYear] = useState(
    moment().subtract(1, 'year').format('YYYY')
  )
  const [lastYear, setLastYear] = useState(
    moment().subtract(2, 'year').format('YYYY')
  )
  const [selectedDate, setSelectedDate] = useState('Ano')
  const [selectedIncomeId, setSelectedIncomeId] = useState(0)
  const [datesRange, setDatesRange] = useState({
    minDate: undefined,
    maxDate: undefined
  })
  const createSummaryData = useCallback((data: any, isAgent?: boolean) => {
    if (data) {
      if (!isAgent) {
        return {
          ...data,
          order: {
            orderNumber:
              data.approved.total_approved === 0
                ? 0
                : BRL(data.approved.total_approved).format(),
            orderQuantity: data.approved.approved_quantity,
            converteToOrder: currency(data.quoteConvertedToOrder, {
              symbol: '%',
              pattern: '#!'
            }).format()
          },
          orderDiscount: {
            orderDiscountNumber:
              data.total_discount === 0 ? 0 : BRL(data.total_discount).format(),
            orderDiscountQuantity: data.discount_quantity,
            discountToClient: currency(data.discounGivenOnOrder, {
              symbol: '%',
              pattern: '#!'
            }).format()
          },
          budget: {
            budgetNumber:
              data.pending.total_pending === 0
                ? 0
                : BRL(data.pending.total_pending).format(),
            budgetQuantity: data.pending.pending_quantity,
            contactPending: data.pending.monitoring_situation || 0
          },
          calledOff: {
            calledOffNumber:
              data.canceled.total_canceled === 0
                ? 0
                : BRL(data.canceled.total_canceled).format(),
            calledOffQuantity: data.canceled.canceled_quantity,
            calledOffToClient: data.canceledToOrders
          },
          revenue: {
            billerdNumber: BRL(data.total_profit).format(),
            billerdQuantity: data.delivery_state
              .split(',')
              .filter((item: any) => item).length,
            toProfitNumber: BRL(data.total_toProfit).format(),
            prospection: data.prospection
          }
        }
      }
      return {
        ...data,
        order: {
          orderNumber:
            data.approved.total_approved === 0
              ? 0
              : BRL(data.approved.total_approved).format(),
          orderQuantity: data.approved.approved_quantity,
          converteToOrder: currency(data.quoteConvertedToOrder, {
            symbol: '%',
            pattern: '#!'
          }).format()
        },
        orderDiscount: {
          orderDiscountNumber:
            data.approved.total_discount === 0
              ? 0
              : BRL(data.approved.total_discount).format(),
          orderDiscountQuantity: data.approved.discount_quantity,
          discountToClient: currency(data.discounGivenOnOrder, {
            symbol: '%',
            pattern: '#!'
          }).format()
        },
        budget: {
          budgetNumber:
            data.pending.total_pending === 0
              ? 0
              : BRL(data.pending.total_pending).format(),
          budgetQuantity: data.pending.pending_quantity,
          contactPending: data.pending.monitoring_situation
        },
        calledOff: {
          calledOffNumber:
            data.canceled.total_canceled === 0
              ? 0
              : BRL(data.canceled.total_canceled).format(),
          calledOffQuantity: data.canceled.canceled_quantity || 0,
          calledOffToClient: data.canceledToOrders || 0
        },
        revenue: {
          billerdNumber: BRL(data.total_profit).format(),
          billerdQuantity: data.delivery_state
            .split(',')
            .filter((item: any) => item).length,
          toProfitNumber: BRL(data.total_toProfit).format(),
          prospection: data.prospection
        }
      }
    }
    return {
      order: {
        orderNumber: 0,
        orderQuantity: 0,
        converteToOrder: 0
      },
      orderDiscount: {
        orderDiscountNumber: 0,
        orderDiscountQuantity: 0,
        discountToClient: 0
      },
      budget: {
        budgetNumber: 0,
        budgetQuantity: 0,
        contactPending: 0
      },
      calledOff: {
        calledOffNumber: 0,
        calledOffQuantity: 0,
        calledOffToClient: 0
      },
      revenue: {
        billerdNumber: 0,
        billerdQuantity: 0,
        toProfitNumber: 0,
        prospection: 0
      }
    }
  }, [])

  const generatePropertiesByYear = useCallback(
    ({
      year,
      month,
      oldData,
      previousYear
    }: {
      year: string
      month: any
      oldData: any
      previousYear: string
    }) => {
      let quantity = month.quantity || 0
      let realized_value = month.realized_value
      console.log(year)
      if (year === previousYear) {
        if (oldData[month.name]?.column2) {
          quantity += oldData[month.name].column2
        }
        if (oldData[month.name]?.column3) {
          realized_value = BRL(oldData[month.name].column3, { symbol: '' })
            .add(realized_value)
            .format()
        }
        return {
          column2: quantity,
          column3: realized_value
        }
      }
      if (oldData[month.name]?.column4) {
        quantity += oldData[month.name].column4
      }
      if (oldData[month.name]?.column5) {
        realized_value = BRL(oldData[month.name].column5, { symbol: '' })
          .add(realized_value)
          .format()
      }
      return {
        column4: quantity,
        column5: realized_value
      }
    },
    []
  )
  const createBoardIncome = useCallback(
    (data: any[], options?: any, selectDate?: any, hasDivide?: boolean) => {
      const tempData: Record<string, any> = {}
      let previewDivide: any
      if (data.length) {
        if (!hasDivide) {
          data
            .map(([key, valeu]) => {
              if (valeu?.length) {
                return valeu.map((month: MonthApiResponse) => {
                  if (tempData[month.name]) {
                    const data = {
                      ...tempData[month.name],
                      ...generatePropertiesByYear({
                        month,
                        year: key,
                        oldData: tempData,
                        ...options
                      }),
                      column1: month.name
                    }
                    const column6 = BRL(data.column5)
                      .subtract(data.column3)
                      .format()
                    const column7 = currency(
                      data.column3 === '0,00'
                        ? 0
                        : currency(BRL(data.column5).value)
                            .multiply(100)
                            .divide(BRL(data.column3).value)
                            .subtract(100)
                            .format(),
                      {
                        symbol: '%',
                        pattern: '#!',
                        negativePattern: '-#!'
                      }
                    ).format()

                    tempData[month.name] = {
                      ...data,
                      column6,
                      column7,
                      column5: BRL(data.column5, {
                        symbol: ''
                      }).format(),
                      column3: BRL(data.column3, {
                        symbol: ''
                      }).format()
                    }
                    return data
                  }
                  const data = {
                    id: month.category_id,
                    ...generatePropertiesByYear({
                      month,
                      year: key,
                      oldData: tempData,
                      ...options
                    }),
                    column1: month.name
                  }
                  const column6 = BRL(data.column5)
                    .subtract(data.column3)
                    .format()
                  const column7 = currency(
                    data.column3 === '0,00'
                      ? 0
                      : currency(BRL(data.column5).value)
                          .multiply(100)
                          .divide(BRL(data.column3).value)
                          .subtract(100)
                          .format(),
                    {
                      symbol: '%',
                      pattern: '#!',
                      negativePattern: '-#!'
                    }
                  ).format()
                  tempData[month.name] = {
                    ...data,
                    column6,
                    column7,
                    column5: BRL(data.column5, {
                      symbol: ''
                    }).format(),
                    column3: BRL(data.column3, {
                      symbol: ''
                    }).format(),
                    disable: data.column1 === 'TOTAL'
                  }
                  return data
                })
              }
              return null
            })
            .filter((item: any) => item)

          return Object.entries(tempData)
            .map(([, value]) => value)
            .sort((currentMonthSort: any, nextMonthSort: any) => {
              if (currentMonthSort.column1 === 'TOTAL') {
                return 1
              }
              if (nextMonthSort.column1 === 'TOTAL') {
                return -1
              }
              return (
                MONTHS_ORDER.indexOf(currentMonthSort.column1) -
                MONTHS_ORDER.indexOf(nextMonthSort.column1)
              )
            })
        } else {
          return data
            .map((finance: any) => {
              if (finance.category_name) {
                if (selectDate === 'day') {
                  previewDivide = finance.expected_value / 22
                }
                if (selectDate === 'week') {
                  previewDivide = (finance.expected_value / 22) * 5
                }
                return {
                  id: finance.category_id,
                  column1: finance.category_name,
                  column2: BRL(
                    previewDivide || finance.expected_value
                  ).format(),
                  column3: BRL(finance.realized_value).format(),
                  column4: BRL(finance.realized_value)
                    .subtract(previewDivide || finance.expected_value)
                    .format(),
                  column5: currency(
                    finance.realized_value === 0
                      ? 0
                      : currency(finance.realized_value)
                          .multiply(100)
                          .divide(previewDivide || finance.expected_value)
                          .format(),
                    {
                      symbol: '%',
                      pattern: '#!'
                    }
                  ).format()
                }
              }
              return null
            })
            .filter((item: any) => item)
        }
      }
      return []
    },
    [generatePropertiesByYear]
  )
  const createBoardGroupProductsIncome = useCallback(
    ({
      data,
      hasDivide,
      item,
      selectDate
    }: {
      data: any[]
      lastItem: any[]
      item: any
      selectDate?: any
      hasDivide?: boolean
    }) => {
      let previewDivide: any
      if (data.length) {
        if (!hasDivide) {
          return data
            .filter(item => item)
            .map(
              (finance: any) =>
                finance && {
                  disable: finance.group_name === 'TOTAL',
                  id: finance.group_id,
                  column1: finance.group_name,
                  column2: finance.products_quantity,
                  column3: BRL(finance.product_total_value)
                    .divide(finance.products_quantity)
                    .format(),
                  column4: BRL(finance.lastItem.products_total_value).format(),
                  column5: currency(
                    finance.product_total_value === 0
                      ? 0
                      : currency(finance.product_total_value)
                          .multiply(100)
                          .divide(item.realized_value)
                          .format(),
                    {
                      symbol: '%',
                      pattern: '#!'
                    }
                  ).format(),
                  column6: BRL(finance.product_total_value).format(),
                  subItems: finance.products
                    .filter((item: any) => item)
                    .map((product: any) => {
                      return {
                        disable: true,
                        id: product.id,
                        column1: product.name,
                        column2: product.products_quantity,
                        column3: BRL(Number(product.product_total_value))
                          .divide(product.products_quantity)
                          .format(),
                        column4: BRL(
                          Number(product.lastItem.product_total_value)
                        ).format(),
                        column5: currency(
                          product.product_total_value === 0
                            ? 0
                            : currency(Number(product.product_total_value))
                                .multiply(100)
                                .divide(Number(finance.product_total_value))
                                .format(),
                          {
                            symbol: '%',
                            pattern: '#!'
                          }
                        ).format(),
                        column6: BRL(
                          Number(product.product_total_value)
                        ).format()
                      }
                    })
                }
            )
        } else {
          return data
            .filter(item => item)
            .map((finance: any) => {
              if (selectDate === 'day') {
                previewDivide = finance.expected_value / 22
              }
              if (selectDate === 'week') {
                previewDivide = (finance.expected_value / 22) * 5
              }
              return {
                id: finance.group_id,
                column1: finance.group_name,
                column2: BRL(previewDivide || finance.expected_value).format(),
                column3: BRL(finance.product_total_value).format(),
                column4: BRL(finance.product_total_value)
                  .subtract(previewDivide || finance.expected_value)
                  .format(),
                column5: currency(
                  previewDivide ||
                    finance.expected_value === 0 ||
                    !(previewDivide || finance.expected_value)
                    ? 0
                    : currency(finance.product_total_value)
                        .multiply(100)
                        .divide(previewDivide || finance.expected_value)
                        .format(),
                  {
                    symbol: '%',
                    pattern: '#!'
                  }
                ).format(),
                column6: finance.products_quantity,
                subItems: finance.products
                  .filter((item: any) => item)
                  .map((product: any) => {
                    return {
                      id: product.id,
                      column1: product.name,

                      column3: BRL(product.product_total_value).format(),
                      column4: BRL(product.product_total_value)
                        .subtract(previewDivide || product.expected_value)
                        .format(),

                      column6: product.products_quantity
                    }
                  })
              }
            })
        }
      }
      return []
    },
    []
  )

  const findBiggerPercetage = useCallback((data: any[], maxValue: number) => {
    let percentageIncomeData = 0
    data
      .filter((item: any) => item)
      .forEach((product: any) => {
        const percentage = (product.product_total_value / maxValue) * 100
        if (percentageIncomeData < percentage) {
          percentageIncomeData = percentage
        }
      })
    return { percentageIncomeData }
  }, [])

  const setDataWithoutFilter = useCallback(
    ({ dataSummary }: { dataSummary: any }) => {
      setSalesSummary([createSummaryData(dataSummary)])
    },
    [createSummaryData]
  )

  const createDataForGraphicMixed = useCallback(
    ({
      data,
      currentYear,
      previousYear,
      lastYear
    }: {
      data: any[]
      currentYear: string
      previousYear: string
      lastYear: string
    }) => {
      if (!data?.length) return []

      return data.map((month: any) => {
        return {
          date: month.date,
          goalAccomplished: month[`${currentYear}realized_value`], // Receita ano atual
          discount: month[`${previousYear}realized_value`], // Receita ano passado
          // Receita ano retrasado
          lastYearTarget: month[`${lastYear}realized_value`] || 0
        }
      })
    },
    []
  )

  function createChartPizzaData(
    data: any[],
    color: string,
    percentage: any,
    maxValue: number,
    type?: string
  ) {
    const maxLighten = 0.65
    if (type) {
      return data
        .filter((item: any) => item)
        .sort(
          (currentValue: any, nextValue: any) =>
            Number(nextValue.product_total_value) -
            Number(currentValue.product_total_value)
        )
        .filter((product: any) => Number(product.product_total_value) > 0)
        .map((product: any) => {
          const subProducts = product.products.filter((item: any) => item)
          let subProductsBiggerValue = 0
          let subProductsBiggerPercentage = 0
          subProducts.forEach((subProduct: { product_total_value: number }) => {
            const productPercentage =
              Number(subProduct.product_total_value) /
              Number(product.product_total_value)
            if (
              Number(subProduct.product_total_value) > subProductsBiggerValue
            ) {
              subProductsBiggerValue = subProduct.product_total_value
            }
            if (productPercentage > subProductsBiggerPercentage) {
              subProductsBiggerPercentage = productPercentage
            }
          })
          const percent = (Number(product.product_total_value) / maxValue) * 100
          const percentLight = (percentage - percent) / 45
          return {
            type: product.group_name,
            percent,
            value: Number(product.product_total_value),
            color: am4core
              .color(color)
              .lighten(percentLight > maxLighten ? maxLighten : percentLight),
            subs: subProducts.map((subProduct: any) => {
              const subPercent =
                (Number(subProduct.product_total_value) /
                  subProductsBiggerValue) *
                100
              const subPercentLight =
                subProductsBiggerPercentage -
                Number(subProduct.product_total_value) /
                  Number(product.product_total_value)
              console.log(subPercentLight)
              return {
                type: subProduct.name,
                percent: subPercent,
                value: Number(subProduct.product_total_value),
                color: am4core
                  .color(color)
                  .lighten(
                    subPercentLight > maxLighten ? maxLighten : subPercentLight
                  )
              }
            })
          }
        })
        .flatMap((item: any) => item)
        .filter((item: any) => item)
    }
    return data
      .filter((item: any) => item)
      .sort(
        (currentValue: any, nextValue: any) =>
          Number(nextValue.product_total_value) -
          Number(currentValue.product_total_value)
      )
      .filter((product: any) => Number(product.product_total_value) > 0)
      .map((product: any) => {
        const percent = (Number(product.product_total_value) / maxValue) * 100
        const percentLight = (percentage - percent) / 45
        return {
          type: product.products_name,
          percent,
          value: Number(product.product_total_value),
          color: am4core
            .color(color)
            .lighten(percentLight > maxLighten ? maxLighten : percentLight),
          subs: product.products
            .filter((item: any) => item)
            .map((subProduct: any) => {
              return {
                type: subProduct.name,
                percent,
                value: Number(subProduct.product_total_value),
                color: am4core
                  .color(color)
                  .lighten(
                    percentLight > maxLighten ? maxLighten : percentLight
                  )
              }
            })
        }
      })
      .flatMap((item: any) => item)
      .filter((item: any) => item)
  }

  const handleMountInitialData = useCallback(
    ({
      transactionData,
      selectPeriod,
      currentYear,
      lastYear,
      previousYear
    }: {
      transactionData?: any
      selectPeriod: string
      currentYear: string
      previousYear: string
      lastYear: string
    }) => {
      const transactionFinanceIncome = financeIncomeData || transactionData
      const boardDataArray =
        Object.entries(
          transactionFinanceIncome.financeIncome?.[selectPeriod]
        ) || []
      setFinanceIncomeData(transactionFinanceIncome)
      setIncomeData(
        createBoardIncome(boardDataArray, {
          currentYear,
          previousYear,
          lastYear
        })
      )

      if (
        transactionFinanceIncome?.financeIncome?.[selectPeriod]?.[currentYear]
      ) {
        const dataLength =
          transactionFinanceIncome?.financeIncome?.[selectPeriod]?.[currentYear]
            ?.length
        const totalData =
          transactionFinanceIncome?.financeIncome?.[selectPeriod]?.[
            currentYear
          ][dataLength - 1]

        setGroupProduct(
          createBoardGroupProductsIncome({
            data: totalData.productsTotal,
            lastItem: totalData.lastItem,
            item: totalData,
            hasDivide: false,
            selectDate: 'year'
          })
        )

        const pizzaGraphicProducts = totalData.products.filter(
          (item: { group_name: string }) => item && item.group_name !== 'TOTAL'
        )
        const percentGroupProducts = findBiggerPercetage(
          pizzaGraphicProducts,
          totalData.realized_value
        )
        setDataUpdateGroupProduct(
          createChartPizzaData(
            pizzaGraphicProducts,
            '#62b033',
            percentGroupProducts.percentageIncomeData,
            totalData.realized_value,
            'groupProduct'
          )
        )
      } else {
        setGroupProduct([])
        setDataUpdateGroupProduct([])
      }
      setDataUpdateMixed(
        createDataForGraphicMixed({
          data: transactionFinanceIncome.graphicData,
          currentYear,
          lastYear,
          previousYear
        })
      )
      if (transactionFinanceIncome.summaryData[selectPeriod]) {
        setDataWithoutFilter({
          dataSummary: transactionFinanceIncome.summaryData[selectPeriod]
        })
      } else {
        setSalesSummary([createSummaryData(undefined)])
      }
    },
    [
      createBoardGroupProductsIncome,
      createBoardIncome,
      createDataForGraphicMixed,
      createSummaryData,
      financeIncomeData,
      findBiggerPercetage,
      setDataWithoutFilter
    ]
  )

  const loadData = useCallback(async () => {
    if (!id) return
    const selectPeriod = 'year'
    setIsLoading(true)
    const transactionFinanceIncome = await api.post(
      '/financial/income/listPerPeriodAndClient',
      {
        date: 'date',
        client_id: id
      }
    )
    handleMountInitialData({
      selectPeriod,
      transactionData: transactionFinanceIncome.data,
      currentYear,
      lastYear,
      previousYear
    })
    setIsLoading(false)
  }, [id, currentYear, handleMountInitialData, lastYear, previousYear])

  async function setInitialData(date?: string, income_id?: number) {
    setSelectedTableRow(undefined)

    const selectDate = date.length > 0 ? date : selectedDate

    setSelectedDate(date.length > 0 ? date : selectedDate)
    setSelectedIncomeId(
      income_id === 0 || !income_id ? income_id : selectedIncomeId
    )
    let selectPeriod: any
    setIsLoading(true)
    if (
      selectDate === 'Mes' ||
      selectDate === 'Mês' ||
      selectDate.includes('period')
    ) {
      selectPeriod = 'month'
    }
    if (selectDate === 'Ano') {
      selectPeriod = 'year'
    }
    if (selectDate === 'Hoje') {
      selectPeriod = 'day'
    }
    if (selectDate === 'Semana') {
      selectPeriod = 'week'
    }
    if (selectDate.includes('period')) {
      const { maxDate } = JSON.parse(date || selectDate)
      const transactionFinanceIncome = {
        ...financeIncomePeriodData
      }
      setFinanceIncomePeriodData(transactionFinanceIncome)
      setDatesRange({
        minDate: `${moment(maxDate).format('YYYY')}-01`,
        maxDate: `${moment(maxDate).format('YYYY')}-12`
      })
      setSalesSummary([
        createSummaryData(
          transactionFinanceIncome.data?.summaryData?.[selectPeriod]
        )
      ])
      if (
        transactionFinanceIncome?.data?.financeIncome?.[selectPeriod]?.[
          currentYear
        ]
      ) {
        const dataLength =
          transactionFinanceIncome?.data?.financeIncome?.[selectPeriod]?.[
            currentYear
          ]?.length
        const totalData =
          transactionFinanceIncome?.data?.financeIncome?.[selectPeriod]?.[
            currentYear
          ][dataLength - 1]

        setGroupProduct(
          createBoardGroupProductsIncome({
            data: totalData.productsTotal,
            lastItem: totalData.lastItem,
            item: totalData,
            hasDivide: false,
            selectDate: 'year'
          })
        )

        const pizzaGraphicProducts = totalData.products.filter(
          (item: { group_name: string }) => item && item.group_name !== 'TOTAL'
        )
        const percentGroupProducts = findBiggerPercetage(
          pizzaGraphicProducts,
          totalData.realized_value
        )
        setDataUpdateGroupProduct(
          createChartPizzaData(
            pizzaGraphicProducts,
            '#62b033',
            percentGroupProducts.percentageIncomeData,
            totalData.realized_value,
            'groupProduct'
          )
        )
      } else {
        setGroupProduct([])
        setDataUpdateGroupProduct([])
      }
    } else {
      setCurrentYear(CURRENT_YEAR_DATE)
      SetPreviousYear(PREVIOUS_YEAR_DATE)
      setLastYear(LAST_YEAR_DATE)
      const year = moment().format('YYYY')
      setDatesRange({
        minDate: `${year}-01`,
        maxDate: `${year}-12`
      })

      handleMountInitialData({
        selectPeriod,
        currentYear: CURRENT_YEAR_DATE,
        lastYear: LAST_YEAR_DATE,
        previousYear: PREVIOUS_YEAR_DATE
      })
    }
    setIsLoading(false)
  }
  const handleSetContentMonthPeriod = useCallback(() => {
    setDatesRange({
      minDate: `${CURRENT_YEAR_DATE}-${CURRENT_MONTH_DATE}`,
      maxDate: `${CURRENT_YEAR_DATE}-${CURRENT_MONTH_DATE}`
    })
    setSelectedTableRow(oldSelectedTableRow => ({
      ...oldSelectedTableRow,
      name: currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1)
    }))
    handleMountInitialData({
      selectPeriod: 'month',
      currentYear: CURRENT_YEAR_DATE,
      lastYear: LAST_YEAR_DATE,
      previousYear: PREVIOUS_YEAR_DATE
    })
  }, [currentMonthName, handleMountInitialData])
  const handleSetDataSelectPeriod: any = useMemo(
    () => ({
      month: () => {
        handleSetContentMonthPeriod()
      },
      week: () => {
        setDatesRange({
          minDate: `${CURRENT_YEAR_DATE}-${CURRENT_MONTH_DATE}`,
          maxDate: `${CURRENT_YEAR_DATE}-${CURRENT_MONTH_DATE}`
        })
        setSelectedTableRow(oldSelectedTableRow => ({
          ...oldSelectedTableRow,
          name:
            currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1)
        }))

        if (financeIncomeData.summaryData.week) {
          setDataWithoutFilter({
            dataSummary: financeIncomeData.summaryData.week
          })
        } else {
          setSalesSummary([createSummaryData(undefined)])
        }
      },
      day: () => {
        setDatesRange({
          minDate: `${CURRENT_YEAR_DATE}-${CURRENT_MONTH_DATE}`,
          maxDate: `${CURRENT_YEAR_DATE}-${CURRENT_MONTH_DATE}`
        })

        setSelectedTableRow(oldSelectedTableRow => ({
          ...oldSelectedTableRow,
          name:
            currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1)
        }))

        if (financeIncomeData.summaryData.day) {
          setDataWithoutFilter({
            dataSummary: financeIncomeData.summaryData.day
          })
        } else {
          setSalesSummary([createSummaryData(undefined)])
        }
      },
      year: () => {
        setDatesRange({
          minDate: `${CURRENT_YEAR_DATE}-01`,
          maxDate: `${CURRENT_YEAR_DATE}-12`
        })

        handleMountInitialData({
          selectPeriod: 'year',
          currentYear: CURRENT_YEAR_DATE,
          lastYear: LAST_YEAR_DATE,
          previousYear: PREVIOUS_YEAR_DATE
        })
      }
    }),
    [
      createSummaryData,
      currentMonthName,
      financeIncomeData?.summaryData?.day,
      financeIncomeData?.summaryData?.week,
      handleMountInitialData,
      handleSetContentMonthPeriod,
      setDataWithoutFilter
    ]
  )

  const handleSetDate = useCallback(
    async (date: string, income_id?: number, fullData?: any) => {
      const selectDate = date || selectedDate

      setSelectedDate(date || selectedDate)
      setSelectedIncomeId(income_id || selectedIncomeId)
      setIsLoading(true)
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { maxDate } = JSON.parse(date || selectDate)
        const selectedCurrentYear = moment(maxDate).format('YYYY')
        const selectedPreviousYear = moment(maxDate)
          .subtract(1, 'year')
          .format('YYYY')
        const selectedLastYear = moment(maxDate)
          .subtract(2, 'year')
          .format('YYYY')
        setCurrentYear(selectedCurrentYear)
        SetPreviousYear(selectedPreviousYear)
        setLastYear(selectedLastYear)
        let transactionFinanceIncome: any = {}
        if (selectedDate && selectDate.includes(selectedDate)) {
          transactionFinanceIncome = {
            ...financeIncomePeriodData
          }
        } else {
          const { data } = await api.post(
            '/financial/income/listPerPeriodAndClient/',
            {
              date: JSON.parse(date || selectDate),
              client_id: id
            }
          )
          transactionFinanceIncome.data = data
        }

        if (fullData) {
          setDatesRange({
            minDate: `${moment(maxDate).format('YYYY')}-${
              MONTHS[fullData.column1]
            }`,
            maxDate: `${moment(maxDate).format('YYYY')}-${
              MONTHS[fullData.column1]
            }`
          })
          setSalesSummary([
            createSummaryData(
              transactionFinanceIncome.data?.summaryDataGroupedPerMonth?.[
                MONTHS[fullData.column1]
              ]
            )
          ])
          const monthData =
            transactionFinanceIncome?.data?.financeIncome[selectPeriod][
              selectedCurrentYear
            ][MONTHS[fullData.column1] - 1]

          setGroupProduct(
            createBoardGroupProductsIncome({
              data: monthData.productsTotal,
              lastItem: monthData.lastItem,
              item: monthData,
              hasDivide: false,
              selectDate: 'year'
            })
          )

          const pizzaGraphicProducts = monthData.products.filter(
            (item: { group_name: string }) => item.group_name !== 'TOTAL'
          )
          const percentGroupProducts = findBiggerPercetage(
            pizzaGraphicProducts,
            monthData.realized_value
          )
          setDataUpdateGroupProduct(
            createChartPizzaData(
              pizzaGraphicProducts,
              '#62b033',
              percentGroupProducts.percentageIncomeData,
              monthData.realized_value,
              'groupProduct'
            )
          )
          setIsLoading(false)
          return
        }
        setDatesRange({
          minDate: `${moment(maxDate).format('YYYY')}-01`,
          maxDate: `${moment(maxDate).format('YYYY')}-12`
        })
        setFinanceIncomePeriodData(transactionFinanceIncome)
        const boardDataArray =
          Object.entries(
            transactionFinanceIncome.data.financeIncome?.[selectPeriod]
          ) || []
        setIncomeData(
          createBoardIncome(boardDataArray, {
            currentYear: moment(maxDate).format('YYYY'),
            previousYear: moment(maxDate).subtract(1, 'year').format('YYYY'),
            lastYear: moment(maxDate).subtract(2, 'year').format('YYYY')
          })
        )
        setDataUpdateMixed(
          createDataForGraphicMixed({
            data: transactionFinanceIncome.data.graphicData,
            currentYear: moment(maxDate).format('YYYY'),
            previousYear: moment(maxDate).subtract(1, 'year').format('YYYY'),
            lastYear: moment(maxDate).subtract(2, 'year').format('YYYY')
          })
        )
        if (
          transactionFinanceIncome?.data?.financeIncome?.[selectPeriod]?.[
            selectedCurrentYear
          ]
        ) {
          const dataLength =
            transactionFinanceIncome?.data?.financeIncome?.[selectPeriod]?.[
              selectedCurrentYear
            ]?.length
          const totalData =
            transactionFinanceIncome?.data?.financeIncome?.[selectPeriod]?.[
              selectedCurrentYear
            ][dataLength - 1]

          setGroupProduct(
            createBoardGroupProductsIncome({
              data: totalData.productsTotal,
              lastItem: totalData.lastItem,
              item: totalData,
              hasDivide: false,
              selectDate: 'year'
            })
          )

          const pizzaGraphicProducts = totalData.products.filter(
            (item: { group_name: string }) =>
              item && item.group_name !== 'TOTAL'
          )
          const percentGroupProducts = findBiggerPercetage(
            pizzaGraphicProducts,
            totalData.realized_value
          )
          setDataUpdateGroupProduct(
            createChartPizzaData(
              pizzaGraphicProducts,
              '#62b033',
              percentGroupProducts.percentageIncomeData,
              totalData.realized_value,
              'groupProduct'
            )
          )
        } else {
          setGroupProduct([])
          setDataUpdateGroupProduct([])
        }
        if (transactionFinanceIncome.data.summaryData[selectPeriod]) {
          setDataWithoutFilter({
            dataSummary: transactionFinanceIncome.data.summaryData[selectPeriod]
          })
        } else {
          setSalesSummary([createSummaryData(undefined)])
        }
      } else {
        if (fullData) {
          const year = moment().format('YYYY')
          setDatesRange({
            minDate: `${year}-${MONTHS[fullData.column1]}`,
            maxDate: `${year}-${MONTHS[fullData.column1]}`
          })

          setSalesSummary([
            createSummaryData(
              financeIncomeData?.summaryDataGroupedPerMonth?.[
                MONTHS[fullData.column1]
              ]
            )
          ])
          if (
            financeIncomeData?.financeIncome?.[selectPeriod]?.[
              CURRENT_YEAR_DATE
            ] &&
            financeIncomeData?.financeIncome?.[selectPeriod]?.[
              CURRENT_YEAR_DATE
            ]?.[MONTHS[fullData.column1] - 1]
          ) {
            const monthData =
              financeIncomeData?.financeIncome[selectPeriod][CURRENT_YEAR_DATE][
                MONTHS[fullData.column1] - 1
              ]

            setGroupProduct(
              createBoardGroupProductsIncome({
                data: monthData.productsTotal,
                lastItem: monthData.lastItem,
                item: monthData,
                hasDivide: false,
                selectDate: 'year'
              })
            )

            const pizzaGraphicProducts = monthData.products.filter(
              (item: { group_name: string }) => item.group_name !== 'TOTAL'
            )
            const percentGroupProducts = findBiggerPercetage(
              pizzaGraphicProducts,
              monthData.realized_value
            )
            setDataUpdateGroupProduct(
              createChartPizzaData(
                pizzaGraphicProducts,
                '#62b033',
                percentGroupProducts.percentageIncomeData,
                monthData.realized_value,
                'groupProduct'
              )
            )
          } else {
            setGroupProduct([])
            setDataUpdateGroupProduct([])
          }
          setIsLoading(false)
          return
        }
        setSelectedTableRow(undefined)
        handleSetDataSelectPeriod[selectPeriod]?.()
      }
      setIsLoading(false)
    },
    [
      selectedDate,
      selectedIncomeId,
      createBoardIncome,
      createDataForGraphicMixed,
      financeIncomePeriodData,
      id,
      createSummaryData,
      createBoardGroupProductsIncome,
      findBiggerPercetage,
      setDataWithoutFilter,
      handleSetDataSelectPeriod,
      financeIncomeData?.summaryDataGroupedPerMonth,
      financeIncomeData?.financeIncome
    ]
  )

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Loading isActive={isLoading} />
      <Container>
        <ControlPanel>
          <div
            className="row"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <HeaderPortlet
              handleSetDate={handleSetDate}
              setInitialData={setInitialData}
              actions={['Hoje', 'Semana', 'Mês', 'Ano', 'Período']}
              mainTitle=""
              startIndexAction={3}
            />
          </div>
          <div className="row mb-5">
            <CustomSummary.Root className="col-md-12">
              <CustomSummary.Header title="Resumo do Cliente" />
              <CustomSummary.Content>
                <CustomSummary.Row
                  className="row"
                  style={{
                    padding: '30px 0px',
                    borderBottom: '1px solid #F2F5F8'
                  }}
                >
                  <CustomSummary.Column
                    label="Pedido"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={salesSummary?.[0]?.order?.orderNumber}
                  />
                  <CustomSummary.Column
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    label="Qtd Pedido"
                    value={salesSummary?.[0]?.order?.orderQuantity}
                  />
                  <CustomSummary.Column
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    label="Orçamento convertido em Pedido"
                    value={salesSummary?.[0]?.order?.converteToOrder}
                  />
                  <CustomSummary.Column
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    label="Orçamento"
                    value={salesSummary?.[0]?.budget?.budgetNumber}
                  />
                  <CustomSummary.Column
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    label="Qtd Orçamento"
                    value={salesSummary?.[0]?.budget?.budgetQuantity}
                  />
                  <CustomSummary.Column
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    label="Orçamento Pendente de Oportunidade"
                    value={salesSummary?.[0]?.budget?.contactPending}
                  />
                </CustomSummary.Row>
                <CustomSummary.Row
                  className="row"
                  style={{
                    padding: '30px 0px',
                    borderBottom: '1px solid #F2F5F8'
                  }}
                >
                  <CustomSummary.Column
                    label="Desconto"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={
                      salesSummary?.[0]?.orderDiscount?.orderDiscountNumber
                    }
                  />
                  <CustomSummary.Column
                    label="Qtd Desconto"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={
                      salesSummary?.[0]?.orderDiscount?.orderDiscountQuantity
                    }
                  />
                  <CustomSummary.Column
                    label="Desconto concedido em Pedido"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={salesSummary?.[0]?.orderDiscount?.discountToClient}
                  />
                  <CustomSummary.Column
                    label="Cancelado"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={salesSummary?.[0]?.calledOff?.calledOffNumber}
                  />
                  <CustomSummary.Column
                    label="Qtd Cancelado"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={salesSummary?.[0]?.calledOff?.calledOffQuantity}
                  />
                  <CustomSummary.Column
                    label="Cancelado convertido em Pedido"
                    className="col-md-2"
                    style={{
                      cursor: 'pointer'
                    }}
                    value={salesSummary?.[0]?.calledOff?.calledOffToClient}
                  />
                </CustomSummary.Row>
              </CustomSummary.Content>
            </CustomSummary.Root>
          </div>
          <div className="row">
            {!isLoading && (
              <TablePortlet
                hasTotal={true}
                className="col-md-6 ps-2"
                maintitle="Quadro de Pedidos"
                handleSetDate={handleSetDate}
                setInitialData={setInitialData}
                resetSelected={selectedTableRow}
                realData={incomeData}
                hasDropdown={false}
                initialData={[]}
                actions={[]}
                indexOnlySettings={[incomeData.length - 1]}
                stylesChild={{
                  column1: {
                    onlyClassname: 'boldGrey',
                    isOnly: true
                  },
                  column2: {
                    onlyClassname: 'boldGrey',
                    isOnly: true
                  },
                  column3: {
                    onlyClassname: 'boldGrey',
                    isOnly: true
                  },
                  column5: {
                    onlyClassname: 'boldGrey',
                    isOnly: true
                  },
                  column6: {
                    onlyClassname: 'boldGrey',
                    isOnly: true
                  },
                  column4: {
                    onlyClassname: 'boldGrey',
                    isOnly: true
                  },
                  th: {
                    classname: 'hasBottomBorder'
                  },
                  column7: {
                    classname: 'boldGrey',
                    isHighlight: true,
                    overAndUnder: true,
                    notValidateSymbol: true,
                    highlightOverNumber: 0
                  },
                  highlightOverColor: '#62b033',
                  highlightUnderColor: '#a0a0a0'
                }}
                tableHead={[
                  'Meses',
                  'Quantidade',
                  `Pedido ${previousYear}`,
                  'Quantidade',
                  `Pedido ${currentYear}`,
                  'Balanço',
                  'Percentual'
                ]}
              />
            )}

            {!isLoading && (
              <div className="col-md-6">
                <AmChartMixedColumn
                  settings={{
                    valueAxis1Title: 'Receita',
                    series2Exclude: true,
                    series1Title: `Receita ${currentYear}`,
                    series3Title: `Receita ${previousYear}`,
                    series4Title: `Receita ${lastYear}`,
                    series3fillColor: '#D9D9D9',
                    series3StrokeColor: '#D9D9D9',
                    series4fillColor: '#E6E6E6',
                    series4StrokeColor: '#E6E6E6',
                    series4StrokeDasharray: '0'
                  }}
                  data={dataUpdateMixed}
                  fillColor="#62b033"
                  initialData={[]}
                  minDate={datesRange.minDate}
                  maxDate={datesRange.maxDate}
                />
              </div>
            )}
          </div>

          <div className="row">
            <TablePortlet
              className="col-md-6"
              maintitle="Quadro de Receita por Produto"
              realData={groupProduct}
              lastChildStyle="boldGrey"
              initialData={[]}
              actions={[]}
              stylesChild={{
                column1: {
                  classname: 'boldGrey'
                },
                column4: {
                  classname: ' dropDown'
                },
                column5: {
                  classname: 'boldGrey',
                  isHighlight: true,
                  overAndUnder: true,
                  notValidateSymbol: true,
                  highlightOverNumber: 0
                },
                th: {
                  classname: 'hasBottomBorder',
                  0: {
                    width: 550
                  }
                },
                highlightOverColor: '#62b033',
                highlightUnderColor: '#a0a0a0'
              }}
              tableHead={[
                'Produto',
                'Quantidade',
                'Média de valor de venda',
                'Valor da ultima venda',
                'Percentual',
                'Receita'
              ]}
            />

            <div className="col-md-6">
              <AmChartPizza
                data={dataUpdateGroupProduct}
                divId="amChartPizzaGroupProduct"
                maintitle="Grafíco por Grupo do Produto"
                style={{ width: '100%', height: '400px' }}
                height="750"
                ignoreFields={[SUM_TOTAL_FIELD_NAME]}
                fields={[
                  {
                    type: WITHOUT_CATEGORY_FIELD_TYPE,
                    name: WITHOUT_CATEGORY_FIELD_NAME,
                    percent: 0,
                    id: Math.random() * 999
                  },
                  {
                    type: SUM_TOTAL_FIELD_TYPE,
                    name: SUM_TOTAL_FIELD_NAME,
                    percent: 0,
                    id: Math.random()
                  }
                ]}
                styleSettings={{
                  legend: {
                    maxWidth: 322
                  }
                }}
              />
            </div>
          </div>
        </ControlPanel>
      </Container>
    </>
  )
}
