import React from 'react'
import List from '../../pages/Commercial/Competitors/List'
import Update from '../../pages/Commercial/Competitors/Update'
import Create from '../../pages/Commercial/Competitors/Create'
import View from '../../pages/Commercial/Competitors/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const CompetitorsRegisterRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/competitors" exact component={List} />
    <Route path="/commercial/competitors/update/:id" component={Update} exact />
    <Route path="/commercial/competitors/create" component={Create} exact />
    <Route path="/commercial/competitors/view/:id" component={View} exact />
  </CustomSwitch>
)
