import { TypeBreadcrumb } from '../index'

export const breadcrumbList: TypeBreadcrumb[] = [
  {
    name: 'Início',
    to: '/'
  },
  {
    name: 'Planejamento de Receita'
  }
]
