import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import { useClient } from '../../providers/ClientProvider'
import LabelText from '../../../../../../components/LabelText'

export const AdicionalInformationsTab = (): JSX.Element => {
  const { initialValues } = useClient()
  const [purchaseType, setPurchaseType] = useState<string>()

  useEffect(() => {
    if (initialValues?.client.purchase_type) {
      setPurchaseType(initialValues.client.purchase_type)
    }
  }, [initialValues])

  return (
    <Container>
      <div className="row">
        <LabelText
          label="Modalidade da compra"
          text={initialValues?.client.purchase_type}
        />
        {purchaseType === 'PORTAL' && (
          <>
            <LabelText
              label="Nome do portal"
              text={
                initialValues?.client.adicional_informations_portals
                  ?.portal_name
              }
            />
            <LabelText
              label="Link de acesso do Portal"
              text={
                initialValues.client.adicional_informations_portals?.portal_link
              }
            />
          </>
        )}
        <LabelText
          label="Login"
          text={initialValues?.client.adicional_informations_portals?.login}
        />
        <LabelText
          label="Senha"
          text={initialValues?.client.adicional_informations_portals?.password}
        />
      </div>
      <div className="row">
        <LabelText
          label="Informações de portais"
          text={initialValues?.client.adicional_informations_portals?.note}
        />
      </div>
    </Container>
  )
}
