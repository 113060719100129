import React from 'react'
import { useTransition } from 'react-spring'

import Toast from './Toast'
import { ToastMessage } from '../../hooks/toast'
import { Contanier } from './styles'

interface TostContanierProps {
  messages: ToastMessage[]
  style?: React.CSSProperties
  styleContainer?: React.CSSProperties
  time?: number
}

const ToastContainer: React.FC<TostContanierProps> = ({
  messages,
  style,
  styleContainer,
  time
}) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { top: '120%' },
      enter: { top: '0%' },
      leave: { top: '120%' }
    }
  )
  return (
    <Contanier style={styleContainer}>
      {messagesWithTransitions.map(({ item, key }) => (
        <Toast key={key} style={style} message={item} time={time} />
      ))}
    </Contanier>
  )
}

export default ToastContainer
