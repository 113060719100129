import React from 'react'
import List from '../../pages/Commercial/PanelLocation/List'
import PrivateRoutes from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PanelLocationRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/panelLocation" exact component={List} />
  </CustomSwitch>
)
