import styled from 'styled-components'

export const Label = styled.label`
  all: unset;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;

  background: #fff;
  border: solid 1px #ccc;
  font-size: 12px;
  font-weight: 600 !important;
  text-transform: uppercase;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }
`
