import styled from 'styled-components'

export const Container = styled.div`
  .btn.btn-outline.blue-oleo.active,
  .btn.btn-outline.blue-oleo:active,
  .btn.btn-outline.blue-oleo:active:focus,
  .btn.btn-outline.blue-oleo:active:hover,
  .btn.btn-outline.blue-oleo:focus,
  .btn.btn-outline.blue-oleo:hover {
    background: #a0a0a0;
    color: #fff;
  }
  .btn.btn-outline.blue-oleo {
    color: #a0a0a0;
  }
`
