import styled from 'styled-components'

export const Contanier = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  input,
  option {
    text-transform: none !important;
  }
`

export const Content = styled.div`
  body {
    background-color: #eff3f8 !important;
  }
  .logo {
    margin: 0 auto;
    margin-top: 60px;
    padding: 15px;
    text-align: center;
  }

  .content {
    background-color: white;
    border-radius: 7px;
    width: 100%;
    max-width: 800px;
    margin: 40px auto 10px auto;
    padding: 30px;
    padding-top: 10px;
    position: relative;

    .form-actions {
      display: flex;
      width: 100%;
    }
    .btn-submit {
      background: #50c878;
      border: 3px solid #48b46c;
      color: #f1f1f1;

      font-weight: 800;
      padding: 10px 15px;
      margin-left: auto;
    }
    input::placeholder {
      opacity: 0.5;
    }
  }

  .content h3 {
    color: #4db3a5;
    text-align: center;
    font-size: 28px;
    font-weight: 400 !important;
    margin-bottom: 30px;
  }
`
