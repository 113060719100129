import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import { useClient } from '../../providers/ClientProvider'
import DataTable from '../../../../../../components/DataTable'
import { nameEntity, nameSource } from './domain/info'
import { headers } from './domain/headers'
import { FaPrint } from 'react-icons/fa'
import xmlIconDark from '../../../../../../assets/image/xml-dark.png'
import moment from 'moment'
import api from '../../../../../../services/api'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'

export const OrderTab = (): JSX.Element => {
  const { initialValues } = useClient()
  const [id, setId] = useState()
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  useEffect(() => {
    if (initialValues?.client.id) {
      setId(initialValues.client.id)
    }
  }, [initialValues])

  return (
    <Container>
      {id && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          notHasChildren
          headers={headers}
          searchParameters={[{ client_id: id, situation: 'APROVADO' }]}
          customHeaders={[
            {
              field: 'date',
              name: 'Data',
              sortable: false,
              element: item =>
                item.date && (
                  <span>
                    {moment(item.date, 'DD/MM/YYYY HH:mm:ss')
                      .add(3, 'h')
                      .format('DD/MM/YYYY')}
                  </span>
                )
            },
            {
              field: 'delivery_date',
              name: 'Data',
              sortable: false,
              element: item =>
                item.delivery_date && (
                  <span>
                    {moment(item.delivery_date, 'DD/MM/YYYY HH:mm:ss')
                      .add(3, 'h')
                      .format('DD/MM/YYYY')}
                  </span>
                )
            },
            {
              field: 'invoice_id',
              name: 'Fatura',
              sortable: false,
              element: item =>
                item.invoice_id && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'end'
                    }}
                  >
                    <a
                      href={`https://multfluxosistema.com.br/notas_fiscais?id=${item.invoice_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaPrint size={16} />
                    </a>
                    <a
                      href={`https://multfluxosistema.com.br/notas_fiscais?id=${item.invoice_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{
                          marginBottom: '5px',
                          marginLeft: '1rem',
                          opacity: '0.7'
                        }}
                        src={xmlIconDark}
                        width={15}
                      ></img>
                    </a>
                  </div>
                )
            },
            {
              field: 'actions',
              name: 'Ações',
              sortable: false,
              element: item => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end'
                  }}
                >
                  <a
                    href={`https://multfluxosistema.com.br/orcamentos/view/${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-search"></span>
                  </a>
                  <a
                    href={`https://multfluxosistema.com.br/orcamentos/edit/${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-edit"></span>
                  </a>
                  <a
                    onClick={() => {
                      activeLoading()
                      api
                        .get(`/commercial/commercialBudget/${item.id}`, {
                          responseType: 'arraybuffer'
                        })
                        .then(response => {
                          const a = document.createElement('a')
                          const blob = new Blob([response.data], {
                            type: 'application/pdf'
                          })
                          const url = window.URL.createObjectURL(blob)
                          a.href = url
                          a.download = `Pedido-${initialValues?.person?.name}.pdf`
                          a.click()
                          disableLoading()
                        })
                        .catch(error => {
                          const errorResponse = String.fromCharCode.apply(
                            null,
                            new Uint8Array(error?.response?.data) as any
                          )
                          const message = errorResponse
                            ? JSON.parse(errorResponse)
                            : { message: 'Erro ao baixar pedido' }

                          addToast({
                            title: message?.message,
                            type: 'error'
                          })
                          disableLoading()
                        })
                    }}
                  >
                    <FaPrint />
                  </a>
                </div>
              )
            }
          ]}
        />
      )}
    </Container>
  )
}
