import React, { useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import { Container, Panel, ContactItem } from './styles'
import { useClient } from '../../providers/ClientProvider'
import LabelText from '../../../../../../components/LabelText'

export const ContactTab = (): JSX.Element => {
  const { initialValues } = useClient()
  const [contacts, setContacts] = useState<any[]>([])
  const panelRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (initialValues?.contact) {
      setContacts(initialValues.contact)
    }
  }, [initialValues])

  return (
    <Container className="table-responsive">
      {contacts.map((contactItem, index) => {
        return (
          contactItem && (
            <ContactItem
              key={contactItem.id}
              className="panel-group"
              id="accordion"
            >
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <div
                      onClick={() =>
                        setContacts((prev: any) => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].open = !copy[index].open
                          return copy
                        })
                      }
                    >
                      {contactItem?.name?.toUpperCase() || 'NOVO CONTATO'}
                      {!contactItem?.open ? (
                        <FaChevronDown size={13} />
                      ) : (
                        <FaChevronUp size={13} />
                      )}
                    </div>
                  </h4>
                </div>
                <Panel
                  id={`${index}`}
                  className={'panel-collapse'}
                  ref={panelRef}
                  open={contactItem.open}
                  style={{
                    height: contactItem.open
                      ? `${panelRef.current?.scrollHeight}px`
                      : '0px'
                  }}
                >
                  <div className="panel-body">
                    <div className="form-content col-md-11">
                      <div className="row no-margin-top">
                        <LabelText
                          label="Nome"
                          text={contactItem.name}
                          col={4}
                        />
                        <LabelText
                          label="Cargo"
                          text={contactItem.position}
                          col={4}
                        />
                        <LabelText
                          label="Setor"
                          text={contactItem.sector?.name}
                          col={2}
                        />
                        <LabelText
                          label="Telefone fixo"
                          text={contactItem.phone_number}
                          col={2}
                        />
                      </div>
                      <div className="row">
                        <LabelText
                          label="Celular principal"
                          text={contactItem.cellphone_number1}
                          col={2}
                        />
                        <LabelText
                          label="celular"
                          text={contactItem.cellphone_number2}
                          col={2}
                        />
                        <LabelText
                          label="Email"
                          text={contactItem.email}
                          col={4}
                        />
                        <LabelText
                          label="Tipo de envio de e-mail para o cliente"
                          htmlText={
                            <div>
                              {contactItem?.type_send_email && (
                                <p>{contactItem?.type_send_email?.join(',')}</p>
                              )}
                            </div>
                          }
                          col={4}
                        />
                      </div>
                      <div className="row">
                        <LabelText
                          label="LinkedIn"
                          text={contactItem.linkedin}
                          col={4}
                        />
                        <LabelText
                          label="Instagram"
                          text={contactItem.instagram}
                          col={4}
                        />
                        <LabelText
                          label="Facebook"
                          text={contactItem.facebook}
                          col={4}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            </ContactItem>
          )
        )
      })}
    </Container>
  )
}
