import React, { useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import moment from 'moment'
import { FaDownload } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import currency from 'currency.js'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utlis/mask'
import { useToast } from '../../../../hooks/toast'

const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })

const PaymentRemittanceFileList = (): JSX.Element => {
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
  }>({
    id: 0,
    isActive: false
  })
  const { updateDataTable } = useUpdateDataTable()
  const { addToast } = useToast()

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        notHasChildren
        headers={headers}
        orderBySort="DESC"
        customHeaders={[
          {
            name: 'Período',
            field: 'year',
            sortable: false,
            element: item => <span>{`${item.month}/${item.year}`}</span>
          },
          {
            name: 'Data',
            field: 'created_at',
            sortable: false,
            element: item => (
              <span>
                {moment(item.created_at, 'DD/MM/YYYY HH:mm:ss').format(
                  'DD/MM/YYYY'
                )}
              </span>
            )
          },
          {
            name: 'Salários dos Funcionários',
            field: 'total_salary',
            sortable: false,
            element: item => (
              <span>
                {BRL(
                  genericMaskWithTwoZeroWithPoint(item.total_salary)
                ).format()}
              </span>
            )
          },
          {
            name: 'Proventos Adicionais',
            field: 'total_adicional_earnings',
            sortable: false,
            element: item => (
              <span>
                {BRL(
                  genericMaskWithTwoZeroWithPoint(item.total_adicional_earnings)
                ).format()}
              </span>
            )
          },
          {
            name: 'Total Descontos',
            field: 'total_discounts',
            sortable: false,
            element: item => (
              <span>
                {BRL(
                  genericMaskWithTwoZeroWithPoint(item.total_discounts)
                ).format()}
              </span>
            )
          },
          {
            name: 'Total',
            field: 'total',
            sortable: false,
            element: item => (
              <span>
                {BRL(genericMaskWithTwoZeroWithPoint(item.total)).format()}
              </span>
            )
          },
          {
            name: 'Convênios/Tributos',
            field: 'value_taxes',
            sortable: false,
            element: item => (
              <span>{`${item.value_taxes} (${
                item.quantity_taxes || '0'
              })`}</span>
            )
          }
        ]}
        actions={[
          {
            name: 'Baixar',
            title: 'Baixar',
            htmlIcon: <FaDownload />,
            onClick: async item => {
              const response = await api.post('financial/payrolls/generate', {
                id: item.payment_remittance_file_id
              })
              const blob = new Blob([response.data], {
                type: 'text/plain'
              })
              console.log(response.headers)

              const name = response.headers['content-disposition']
                .split('filename=')[1]
                .split('.')[0]
              const extension = response.headers['content-disposition']
                .split('.')[1]
                .split(';')[0]

              const filename = `${name.substring(
                1,
                name.length
              )}.${extension.substring(0, extension.length - 1)}`
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = filename
              a.click()
            }
          },
          /* {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: item => `/financial/paymentRemittanceFiles/view/${item.id}`
          },
           */
          {
            name: 'Deletar',
            title: 'Deletar',
            htmlIcon: <MdClose />,
            onClick: async item => {
              setIsActiveAlert({
                id: item.id,
                isActive: true
              })
            }
          }
        ]}
      />
      <Alert
        message={'Tem certeza que deseja excluir esse arquivo?'}
        onClickCancellButton={() =>
          setIsActiveAlert({
            id: 0,
            isActive: false
          })
        }
        onClickConfirmButton={async () => {
          try {
            await api.delete(`financial/payrolls/${alert.id}`)
            addToast({
              type: 'success',
              title: 'Folha de pagamento removida!',
              description: 'A folha de pagamento foi removida com sucessoo!'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Error ao remover Folha de pagamento.',
              description:
                'Houve um error ao tentar remover a folha de pagamento, tente novamente mais tarde'
            })
          }
          updateDataTable()
          setIsActiveAlert({
            id: 0,
            isActive: false
          })
        }}
        isActive={alert.isActive}
      />
    </Container>
  )
}

export default PaymentRemittanceFileList
