import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form from '../../../../../components/FormProvider'
import moment from 'moment'
import {
  ModalProps,
  Appointment,
  APPOINTMENT_ACTION_MAP
} from '../../Appointments'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import {
  apiCreateAppointment,
  apiHistoryAppointment,
  apiUpdateAppointment
} from '../../domain/api'
import {
  DATE_MASK_ISO_WITHOUT_TIME,
  DATE_MASK_LOCALE_WITHOUT_TIME,
  DATE_MASK_LOCALE_WITH_TIME
} from '../../../../../common/constants'
import { FormFields } from './fields'
import Tab from '../../../../../components/Tabs/Tab'
import Tabs from '../../../../../components/Tabs'
import { HistoryItem } from './styles'

type TypesFormProps = {
  typeForm: 'create' | 'update'
  initialValues?: Appointment
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
  getAppointments: () => void
}

export const FormAppointment = ({
  typeForm,
  initialValues,
  setModal,
  getAppointments
}: TypesFormProps): JSX.Element => {
  const [defaultValues, setDefaultValues] = useState<any>()

  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const [history, setHistory] = useState<Appointment[]>([])

  const getHistory = useCallback(async (id: number) => {
    const historyRequest = await api.get(apiHistoryAppointment(String(id)))
    setHistory(historyRequest.data)
  }, [])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        date: moment(initialValues.date).toDate()
      })
    }
    if (initialValues?.id) {
      getHistory(initialValues.id)
    }
  }, [getHistory, initialValues])

  const onSubmitForm = async (data: any) => {
    const appointmentData = {
      id: initialValues.id,
      client_id: initialValues.client.id,
      parent_id: initialValues.parent_id,
      ...data,
      date: moment(data.date).format(DATE_MASK_ISO_WITHOUT_TIME)
    }

    activeLoading()
    if (typeForm === 'create') {
      try {
        await api.post(apiCreateAppointment(), {
          ...appointmentData
        })
        disableLoading()
        addToast({
          type: 'success',
          title: 'Agedamento criado com sucesso'
        })
        getAppointments()
        setModal({
          open: false
        })
      } catch (error) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Erro ao criar agendamento'
        })
      }
    } else {
      try {
        await api.put(apiUpdateAppointment(appointmentData.id), {
          ...appointmentData,
          id: undefined
        })
        disableLoading()
        addToast({
          type: 'success',
          title: 'Agedamento atualizado com sucesso'
        })
        getAppointments()
        setModal({
          open: false
        })
      } catch (error) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Erro ao atualizar agendamento'
        })
      }
    }
  }
  return (
    <div style={{ marginTop: '20px' }}>
      <Tabs>
        <Tab key={0} title="Dados">
          <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
            <FormFields
              typeForm={typeForm}
              initialValues={initialValues}
              setModal={setModal}
              getAppointments={getAppointments}
            />
          </Form>
        </Tab>
        {typeForm === 'update' ? (
          <Tab key={1} title="Histórico">
            <div style={{ minHeight: '350px' }}>
              {history.length === 0 && <p>Sem agendamentos relacionados</p>}
              {history.map(h => (
                <HistoryItem key={h.id}>
                  <div>
                    <p>[{APPOINTMENT_ACTION_MAP[h.action]}]</p>
                    <div>
                      <span>
                        {moment(h.date, DATE_MASK_LOCALE_WITH_TIME)
                          .add(3, 'h')
                          .format(DATE_MASK_LOCALE_WITHOUT_TIME)}
                      </span>
                      <span>{h.time}</span>
                    </div>
                  </div>
                  <p>
                    <b>Descrição: </b>
                    {h.description}
                  </p>
                  <p>
                    <b>Resultado: </b>
                    {h.appointment_result}
                  </p>
                  <p></p>
                </HistoryItem>
              ))}
            </div>
          </Tab>
        ) : (
          <></>
        )}
      </Tabs>
    </div>
  )
}
