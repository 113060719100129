export const headers = [
  { name: 'Descrição Sistema', field: 'name', custom: true, sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Descrição Bancária', field: 'description', sortable: true },
  { name: 'Vencimento', field: 'due_date', sortable: true },
  { name: 'Liquidação', field: 'settlement_date', sortable: true },
  {
    name: 'Valor',
    field: 'value',
    sortable: false
  }
]
export const notFoundheaders = [
  { name: 'Descrição Bancária', field: 'description', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Forma de pagamento', field: 'payment_form', sortable: true },
  { name: 'Liquidação', field: 'settlement_date', sortable: true },
  {
    name: 'Valor',
    field: 'value',
    sortable: false
  }
]
