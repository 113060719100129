import styled from 'styled-components'

interface DateButtonProps {
  isSelected: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  .btn.btn-outline.blue-oleo {
    color: #a0a0a0;
  }
  .btn.btn-outline.blue-oleo.active,
  .btn.btn-outline.blue-oleo:active,
  .btn.btn-outline.blue-oleo:active:focus,
  .btn.btn-outline.blue-oleo:active:hover,
  .btn.btn-outline.blue-oleo:focus,
  .btn.btn-outline.blue-oleo:hover {
    color: #fff;
  }
`
export const DropdownDate = styled.div`
  position: absolute;
  width: 240px;
  padding: 5px;
  bottom: -242px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 1;
  min-height: 243px;
`
export const DateButton = styled.button<DateButtonProps>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
  border: 0;
  outline: 0;
  background: transparent;
  &:hover {
    background: #a0a0a0;
    color: #fff;
  }
`
export const WrapperDates = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  div {
    padding: 10px;
  }
  .activeDate {
    background: #aeaeae;
    color: #fff;
  }
  .selectedDate {
    background: #a0a0a0;
    color: #fff;
  }
  .disable {
    pointer-events: none;
    color: #c2c2c2;
  }
`
export const ContainerInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  min-width: 120px;
  input {
    text-align: left;
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    text-transform: none;
    font-size: 13px;
    &:hover {
      color: #fff;
      ::placeholder {
        color: #fff;
      }
    }
  }
  .InputGrowth {
    width: 180px;
  }
`
export const ContainerDateYear = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  padding: 5px 0;
  p {
    font-size: 16px;
    cursor: default;
  }
  svg {
    cursor: pointer;
  }
`
