export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const viewHaders = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
