import React, { useEffect } from 'react'
import { useTabs } from '../../../../../hooks/tabs'

import {
  Container,
  RenderComponent,
  TabHeaderContainer,
  TabName,
  TabPanelContainer
} from './styles'
import { useClient } from '../providers/ClientProvider'

type TypeContentProps = {
  tabList: any[]
  id?: string
}

export const Tab = ({ tabList, id }: TypeContentProps): JSX.Element => {
  const { addTab, changeCurrentTab, currentTab, tabs } = useTabs()

  const { getClient, getOptions, financialTransactions, initialValues } =
    useClient()

  useEffect(() => {
    tabList.forEach(({ name, isDefault, label, isEnable, Component }) => {
      addTab({ default: isDefault, name, label, isEnable, Component })
    })
  }, [tabList, addTab])

  useEffect(() => {
    if (id) {
      ;(async () => {
        await getClient(id)
      })()
    } else {
      getOptions()
    }
  }, [id, getClient, getOptions])

  const onClickNameTab = (name: string) => changeCurrentTab(name)
  return (
    <Container>
      <div className="form-body mb-5">
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="name" className="control-label">
                Nº cliente
              </label>
              <p>{initialValues?.client?.id}</p>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="email" className="control-label">
                Tipo pessoa
              </label>
              <p>
                {initialValues?.person?.type === 'PJ'
                  ? 'Pessoa jurídica'
                  : 'Pessoa física'}
              </p>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="email" className="control-label">
                {initialValues?.person?.type === 'PJ' ? 'CNPJ' : 'CPF'}
              </label>
              <p>{initialValues?.person?.cnpj_cpf}</p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="telephone" className="control-label">
                Razão social
              </label>
              <p>{initialValues?.person?.name}</p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="comments" className="control-label">
                Nome Fantasia
              </label>
              <p>{initialValues?.person?.fantasy_name}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="active" className="control-label">
                Segmento
              </label>
              <p>{initialValues?.segment?.name}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="active" className="control-label">
                Subsegmento
              </label>
              <p>{initialValues?.subSegment?.name}</p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="created" className="control-label">
                Responsável Venda
              </label>
              <p>{initialValues?.saleSeller?.name}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="created" className="control-label">
                Responsável Locação
              </label>
              <p>{initialValues?.locationSeller?.name}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="created" className="control-label">
                Criado em
              </label>
              <p>{initialValues?.client?.created_at}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="created" className="control-label">
                Atualizado em
              </label>
              <p>{initialValues?.client?.updated_at}</p>
            </div>
          </div>
        </div>
      </div>
      <TabHeaderContainer>
        {tabs.map(
          ({ label, isEnable, name }) =>
            isEnable &&
            (name !== 'financial_pendencies' ||
              financialTransactions.length > 0) && (
              <TabName
                onClick={() => onClickNameTab(name)}
                key={name}
                isActive={currentTab.key === name}
              >
                <span
                  style={{
                    color:
                      name === 'financial_pendencies' &&
                      financialTransactions.length
                        ? 'red'
                        : 'inherit'
                  }}
                >
                  {label}
                </span>
              </TabName>
            )
        )}
      </TabHeaderContainer>
      <TabPanelContainer>
        {tabs.map(
          ({ Component, name }) =>
            (name !== 'schedule' || currentTab.key === name) && (
              <RenderComponent key={name} isActive={name === currentTab.key}>
                {Component || name}
              </RenderComponent>
            )
        )}
      </TabPanelContainer>
    </Container>
  )
}
