import React from 'react'
import { TypeContentTabs } from './index'
import { Fiscal, labelFiscal, nameFiscal } from './tabs/Fiscal'
import {
  PriceComposition,
  labelPriceComposition,
  namePriceComposition
} from './tabs/PriceComposition'
import { Stock, nameStock, labelStock } from './tabs/Stock'

export const makeTabs = async (): Promise<TypeContentTabs[]> => {
  return [
    {
      label: labelStock,
      name: nameStock,
      isEnable: true,
      Component: <Stock />
    },
    {
      label: labelPriceComposition,
      name: namePriceComposition,
      isEnable: false,
      Component: <PriceComposition />
    },
    {
      label: labelFiscal,
      name: nameFiscal,
      isEnable: false,
      Component: <Fiscal />
    }
  ]
}
