import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import {
  namePageTitle,
  priorities,
  situations,
  WarehouseRequest
} from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'
import { FormSituation } from '../components/FormSituation'
import Modal from '../../../../components/Modal'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import CustomDataTable from '../../../../components/CustomDataTable'
import { logsHeaders, productsHeaders } from '../domain/headers'
import { useAuth } from '../../../../hooks/auth'
import moment from 'moment'

type User = {
  id: number
  role_id: number
}
const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { user } = useAuth()
  const [warehouseRequest, setWarehouseRequest] = useState<WarehouseRequest>()
  const [users, setUsers] = useState<User[]>()
  const [modal, setModal] = useState(false)
  const [modalReceived, setModalReceived] = useState(false)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  const loadWarehouseRequest = async () => {
    activeLoading()
    try {
      const response = await api.get<{
        warehouseRequest: WarehouseRequest
        users: User[]
      }>(apiList(id))
      const { data } = response
      setWarehouseRequest(data.warehouseRequest)
      setUsers(data.users)
      disableLoading()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Requisição não encontrada.'
      })
      disableLoading()
      history.goBack()
    }
  }

  useEffect(() => {
    loadWarehouseRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Requisição removida com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Requisição não removida, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const userWarehouseRequest = users?.find(
    u => u.id === warehouseRequest?.user_id
  )
  let a = true
  const userWarehouseRequestIds = users
    ?.filter(u => u.role_id === userWarehouseRequest?.role_id)
    .map(u => u.id)
  if (!userWarehouseRequestIds?.includes(Number(user.id))) {
    a = false
  }
  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(id),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nº Requisição
                </label>
                <p>{warehouseRequest?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="start_date" className="control-label">
                  Prioridade
                </label>
                <p>
                  {
                    priorities.find(s => s.value === warehouseRequest?.priority)
                      ?.name
                  }
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label
                  style={{ marginRight: '10px' }}
                  htmlFor="start_date"
                  className="control-label"
                >
                  Etapa
                </label>
                <p>
                  {
                    situations.find(
                      s => s.value === warehouseRequest?.situation
                    )?.name
                  }
                </p>
              </div>
            </div>
            {warehouseRequest?.token && (
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="start_date" className="control-label">
                    Token
                  </label>
                  <p>{warehouseRequest?.token}</p>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="start_date" className="control-label">
                  Solicitante
                </label>
                <p>{warehouseRequest?.user?.name}</p>
              </div>
            </div>
            {warehouseRequest?.delivery_date && (
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="start_date" className="control-label">
                    Data Entregue
                  </label>
                  <p>{warehouseRequest?.delivery_date?.substring(0, 10)}</p>
                </div>
              </div>
            )}
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created_at" className="control-label">
                  Cadastrado em
                </label>
                <p>{warehouseRequest?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated_at" className="control-label">
                  Atualizado em
                </label>
                <p>{warehouseRequest?.updated_at}</p>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: '50px' }}>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated_at" className="control-label">
                  Descrição
                </label>
                <p style={{ whiteSpace: 'pre' }}>
                  {warehouseRequest?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Tabs>
          {[
            <Tab key={0} title="Produtos">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">Listagem</div>
                  <div className="tools"></div>
                </div>
                <div className="portlet-body form">
                  {warehouseRequest?.warehouseRequestsProducts &&
                    warehouseRequest.warehouseRequestsProducts.length > 0 && (
                      <CustomDataTable
                        customItems={warehouseRequest?.warehouseRequestsProducts.sort(
                          (a, b) =>
                            a?.stock?.product?.name > b?.stock?.product?.name
                              ? 1
                              : -1
                        )}
                        headers={productsHeaders}
                        pagination
                        search
                      />
                    )}
                </div>
                {['separate'].includes(warehouseRequest?.situation) && a && (
                  <button
                    className="btn dark btn-sm sbold uppercase"
                    style={{ marginTop: '5px' }}
                    onClick={() => setModalReceived(true)}
                  >
                    Registrar recebimento
                  </button>
                )}
              </div>
            </Tab>,
            <Tab key={1} title="Histórico">
              <div className="portlet light">
                <div className="portlet-title">
                  <div className="caption">Listagem</div>
                  <div className="tools">
                    {!['delivered', 'canceled'].includes(
                      warehouseRequest?.situation
                    ) &&
                      user?.role_id === 2 && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#333'
                          }}
                        >
                          <span
                            onClick={() => {
                              setModal(true)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <span
                              className="fa fa-edit"
                              style={{ marginRight: '3px' }}
                            ></span>
                            Alterar Situação
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="portlet-body form">
                  {warehouseRequest?.warehouseRequestsLogs &&
                    warehouseRequest.warehouseRequestsLogs.length > 0 && (
                      <>
                        <CustomDataTable
                          customItems={warehouseRequest.warehouseRequestsLogs.sort(
                            (a, b) => b.id - a.id
                          )}
                          headers={logsHeaders}
                          customHeaders={[
                            {
                              field: 'previous_situation',
                              name: 'Situação anterior',
                              sortable: true,
                              element: item => (
                                <span>
                                  {
                                    situations.find(
                                      s => s.value === item?.previous_situation
                                    )?.name
                                  }
                                </span>
                              )
                            },
                            {
                              field: 'new_situation',
                              name: 'Situação nova',
                              sortable: true,
                              element: item => (
                                <span>
                                  {
                                    situations.find(
                                      s => s.value === item?.new_situation
                                    )?.name
                                  }
                                </span>
                              )
                            },
                            {
                              field: 'realized_at',
                              name: 'Realizado em',
                              sortable: true,
                              element: item => (
                                <span>
                                  {item.realized_at
                                    ? moment(
                                        item.realized_at,
                                        'DD/MM/YYYY HH:mm:ss'
                                      ).format('DD/MM/YYYY HH:mm:ss')
                                    : ''}
                                </span>
                              )
                            },
                            {
                              name: 'Data limite',
                              field: 'situation_expiration',
                              sortable: true,
                              element: item => (
                                <span>
                                  {item.situation_expiration
                                    ? moment(
                                        item.situation_expiration,
                                        'DD/MM/YYYY HH:mm:ss'
                                      )
                                        .add(3, 'h')
                                        .format('DD/MM/YYYY')
                                    : ''}
                                </span>
                              )
                            }
                          ]}
                          search
                          pagination
                        />
                      </>
                    )}
                </div>
              </div>
            </Tab>
          ]}
        </Tabs>
      </Container>
      {modal && (
        <Modal
          onClickButtonCancel={() => setModal(false)}
          isOpenModal={modal}
          pageTitle={'Alterar situação'}
          styles={{
            padding: 0,
            marginTop: 0
          }}
          sectionStyle={{
            minWidth: 'auto'
          }}
          Children={
            <FormSituation
              received={false}
              setModal={setModal}
              loadWarehouseRequest={loadWarehouseRequest}
              situation={warehouseRequest?.situation}
              id={warehouseRequest?.id}
            />
          }
        />
      )}
      {modalReceived && (
        <Modal
          onClickButtonCancel={() => setModalReceived(false)}
          isOpenModal={modalReceived}
          pageTitle={'Alterar situação: Entregue'}
          styles={{
            padding: 0,
            marginTop: 0
          }}
          sectionStyle={{
            minWidth: 'auto'
          }}
          Children={
            <FormSituation
              received
              setModal={setModalReceived}
              loadWarehouseRequest={loadWarehouseRequest}
              situation={warehouseRequest?.situation}
              id={warehouseRequest?.id}
            />
          }
        />
      )}
      <Alert
        message={`Tem certeza que deseja excluir a requisição ${warehouseRequest?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(warehouseRequest?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}
export default View
