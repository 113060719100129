import React from 'react'
import PrivateRoutes from '../../Route'
import List from '../../../pages/Warehouse/Expedition/Orders/List'
import View from '../../../pages/Warehouse/Expedition/Orders/View'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const ExpeditionOrdersRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/warehouse/expedition/orders" exact component={List} />
    <PrivateRoutes
      path="/warehouse/expedition/orders/view/:id"
      exact
      component={View}
    />
  </CustomSwitch>
)
