import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Container,
  DropdownDate,
  DateButton,
  WrapperDates,
  ContainerInput,
  ContainerDateYear
} from './styles'
import {
  FaTimes,
  FaCalendarAlt,
  FaArrowLeft,
  FaArrowRight
} from 'react-icons/fa'
import moment from 'moment'

interface DateRangeProps {
  minDate: Date
  maxDate: Date
  minRange: number | undefined
  maxRange: number | undefined
  firstDate: string
  secondDate: string
  firstDateFull: string
  secondDateFull: string
}

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  getUserData?: () => void
  setInitialData(date?: string): void
  handleSwitchPeriod(index: number): void
  handleResetData(): void
  handleSetDate(date: string, agent_id?: number): void
  isReset: boolean
  initialAction?: string
  hasSelectFutureDate?: boolean
}
const DatePicker: React.FC<DatePickerProps> = ({
  className,
  handleSwitchPeriod,
  setInitialData,
  handleResetData,
  isReset,
  handleSetDate,
  initialAction,
  ...rest
}) => {
  const [years, setYears] = useState([])
  const [currentMonth, setCurrentMonth] = useState('Período')
  const [minYearDate, setMinYearDate] = useState<number>(0)
  const [maxYearDate, setMaxYearDate] = useState<number>(
    new Date().getFullYear()
  )
  const [toggleModal, setToggleModal] = useState(false)
  const [isSelectRange, setIsSelectRange] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeProps>({
    minDate: undefined,
    maxDate: undefined,
    maxRange: undefined,
    minRange: undefined,
    firstDate: '',
    secondDate: '',
    firstDateFull: '',
    secondDateFull: ''
  })

  const generateArrayOfYears = useCallback(
    ({ backYear, maxYear }: { backYear?: number; maxYear?: number }) => {
      const max = maxYear ?? new Date().getFullYear()
      let min = max - (backYear || 11)
      if (min < 2020) {
        min = 2020
      }
      if (maxYear > new Date().getFullYear()) {
        maxYear = new Date().getFullYear()
      }
      const newYears = []

      for (let i = max; i >= min; i--) {
        if (i > new Date().getFullYear()) {
          continue
        }
        newYears.push(i)
      }
      setMinYearDate(min)
      setMaxYearDate(
        max > new Date().getFullYear() ? new Date().getFullYear() : max
      )
      return newYears.reverse()
    },
    []
  )

  useEffect(() => {
    setYears(generateArrayOfYears({}))
  }, [generateArrayOfYears])

  const verifyRangeDate = useCallback(
    (year: number) => {
      const minYear = selectedDateRange.minDate?.getFullYear()
      const maxYear = selectedDateRange.maxDate?.getFullYear()
      console.log(year, 'YEAR')
      console.log(minYear, 'MIN')
      console.log(maxYear, 'MAX')
      if (minYear === maxYear && year === minYear) {
        return 'activeDate'
      }
      if (minYear === maxYear && year !== minYear) {
        return ''
      }
      if (minYear && maxYear && year >= minYear && year <= maxYear) {
        if (year >= minYear) {
          return 'activeDate'
        }
        if (year <= maxYear) {
          return 'activeDate'
        }
      }

      if (minYear && !maxYear) {
        if (minYear === year) {
          return 'selectedDate activeDate'
        }
      }
      if (maxYear && !minYear) {
        if (maxYear === year) {
          return 'selectedDate activeDate'
        }
      }

      return ''
    },
    [selectedDateRange.maxDate, selectedDateRange.minDate]
  )

  const handleToggleModal = useCallback(() => {
    handleSwitchPeriod(4)
    setToggleModal(!toggleModal)
  }, [handleSwitchPeriod, toggleModal])

  const handleSetCurrentMonth = useCallback(
    (year: number, index: number) => {
      if (isSelectRange) {
        setIsSelectRange(false)
        if (selectedDateRange.minDate <= new Date(`january 01, ${year}`)) {
          setSelectedDateRange({
            minRange: selectedDateRange.minRange,
            maxRange: index,
            minDate: selectedDateRange.minDate,
            maxDate: moment().set('year', year).endOf('year').toDate(),
            firstDate: selectedDateRange.firstDate,
            secondDate: 'year',
            firstDateFull: '',
            secondDateFull: ''
          })
          const dates = {
            period: true,
            minDate: selectedDateRange.minDate.toISOString().slice(0, 7),
            maxDate: moment()
              .set('year', year)
              .endOf('year')
              .format('YYYY-MM-DD')
          }
          if (selectedDateRange.minDate.getFullYear() === year) {
            setCurrentMonth(`${currentMonth} até ${year}`)
          } else {
            inputRef.current.className = 'InputGrowthMedium'

            setCurrentMonth(
              `Período de ${selectedDateRange.minDate.getFullYear()} até ${year}`
            )
          }
          handleSetDate(JSON.stringify(dates))
        } else {
          setSelectedDateRange({
            minRange: index,
            maxRange: selectedDateRange.minRange,
            minDate: new Date(`january 01, ${year}`),
            maxDate: moment(selectedDateRange.minDate).endOf('year').toDate(),
            firstDate: selectedDateRange.firstDate,
            secondDate: 'year',
            firstDateFull: selectedDateRange.firstDateFull,
            secondDateFull: 'january'
          })
          const dates = {
            period: true,
            minDate: new Date(`january 01, ${year}`).toISOString().slice(0, 7),
            maxDate: selectedDateRange.minDate.toISOString().slice(0, 7)
          }
          if (selectedDateRange.minDate.getFullYear() === year) {
            setCurrentMonth(`Período de ${year} até ${year}`)
          } else {
            inputRef.current.className = 'InputGrowthMedium'

            setCurrentMonth(
              `Período de ${year} até ${selectedDateRange.minDate.getFullYear()}`
            )
          }

          handleSetDate(JSON.stringify(dates))
        }
        handleToggleModal()
      } else {
        setSelectedDateRange({
          minRange: index,
          maxRange: undefined,
          minDate: new Date(`january 01, ${year}`),
          maxDate: moment().set('year', year).endOf('year').toDate(),
          firstDate: 'year',
          secondDate: 'year',
          firstDateFull: 'monthFullName',
          secondDateFull: 'monthFullName'
        })
        setIsSelectRange(true)
        setCurrentMonth(`Período de ${year}`)
      }
    },
    [
      currentMonth,
      handleSetDate,
      handleToggleModal,
      isSelectRange,
      selectedDateRange.firstDate,
      selectedDateRange.firstDateFull,
      selectedDateRange.minDate,
      selectedDateRange.minRange
    ]
  )
  const handleClearCurrentMonth = useCallback(() => {
    setToggleModal(false)
    handleResetData()
    setCurrentMonth('Período')
    setSelectedDateRange({
      minDate: undefined,
      maxDate: undefined,
      maxRange: undefined,
      minRange: undefined,
      firstDate: '',
      secondDate: '',
      firstDateFull: '',
      secondDateFull: ''
    })
    setInitialData(initialAction || 'Mes')
  }, [handleResetData, initialAction, setInitialData])

  const handleClearInput = useCallback(() => {
    if (currentMonth === 'Período') {
      setToggleModal(false)
      setCurrentMonth('Período')
    }
  }, [currentMonth])

  const handleNextYear = useCallback(() => {
    if (maxYearDate + 11 > new Date().getFullYear()) {
      return
    }
    setYears(generateArrayOfYears({ backYear: 0, maxYear: maxYearDate + 11 }))
  }, [generateArrayOfYears, maxYearDate])

  const handlePreviousYear = useCallback(() => {
    if (minYearDate <= 2020) {
      return
    }
    setYears(generateArrayOfYears({ backYear: 11, maxYear: minYearDate - 1 }))
  }, [generateArrayOfYears, minYearDate])

  useEffect(() => {
    if (isReset) {
      if (currentMonth !== 'Período') {
        setToggleModal(false)
        setCurrentMonth('Período')
        setSelectedDateRange({
          minDate: undefined,
          maxDate: undefined,
          maxRange: undefined,
          minRange: undefined,
          firstDate: '',
          secondDate: '',
          firstDateFull: '',
          secondDateFull: ''
        })
      }
    }
  }, [currentMonth, isReset])

  return (
    <Container onMouseLeave={handleClearInput}>
      <ContainerInput className={className}>
        <FaCalendarAlt
          onClick={handleToggleModal}
          style={{ marginRight: '5px', marginBottom: '2px' }}
          size={13}
          className="FaCalendar"
        />
        <input
          readOnly
          ref={inputRef}
          className={currentMonth.length > 10 ? 'InputGrowth' : ''}
          type="text"
          value={currentMonth}
          onClick={handleToggleModal}
          style={{
            color: toggleModal && '#fff'
          }}
          {...rest}
        />
        {currentMonth !== 'Período' && (
          <FaTimes onClick={handleClearCurrentMonth} />
        )}
      </ContainerInput>
      {toggleModal && (
        <DropdownDate>
          <ContainerDateYear>
            <FaArrowLeft
              onClick={handlePreviousYear}
              color={minYearDate <= 2020 ? '#ccc' : '#a0a0a0'}
            />
            <p>Ano</p>
            <FaArrowRight
              onClick={handleNextYear}
              color={
                maxYearDate === new Date().getFullYear() ? '#ccc' : '#a0a0a0'
              }
            />
          </ContainerDateYear>
          <WrapperDates>
            {years.map((year, index) => (
              <div key={year} className={verifyRangeDate(year)}>
                <DateButton
                  type="button"
                  className={verifyRangeDate(year)}
                  isSelected={
                    selectedDateRange.minRange === index ||
                    index === selectedDateRange.maxRange
                  }
                  onClick={() => handleSetCurrentMonth(year, index)}
                >
                  {year}
                </DateButton>
              </div>
            ))}
          </WrapperDates>
        </DropdownDate>
      )}
    </Container>
  )
}

export { DatePicker }
