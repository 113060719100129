import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

type ClientType = {
  id: number
  name: string
}
type TypesFormProps = {
  clientType?: ClientType
  typeForm: 'create' | 'update'
}

export const FormClientType = ({
  clientType,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<ClientType>()

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    setDefaultValues({
      ...clientType
    })
  }, [clientType])

  const onSubmitForm = async (data: ClientType) => {
    try {
      if (typeForm === 'create') {
        try {
          activeLoading()
          await api.post(apiCreate(), data)
          disableLoading()
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const id = clientType.id

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), data)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="row">
          <div className="form-content col-md-3">
            <Input
              name="name"
              className="form-control"
              label="Nome"
              rules={{ required: true }}
            />
          </div>
        </div>
        <div className="form-actions right">
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        </div>
      </>
    </Form>
  )
}
