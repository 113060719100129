import React from 'react'
import ProductCategoriesList from '../../../pages/Warehouse/product/ProductCategories/List'
import ProductCategoriesUpdate from '../../../pages/Warehouse/product/ProductCategories/Update'
import ProductCategoriesCreate from '../../../pages/Warehouse/product/ProductCategories/Create'
import ProductCategoriesView from '../../../pages/Warehouse/product/ProductCategories/View'
import Route from '../../Route'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const ProductCategoriesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/warehouse/productCategories"
      exact
      component={ProductCategoriesList}
    />
    <Route
      path="/warehouse/productCategories/create"
      component={ProductCategoriesCreate}
      exact
    />
    <Route
      path="/warehouse/productCategories/view/:id"
      component={ProductCategoriesView}
      exact
    />
    <Route
      path="/warehouse/productCategories/update/:id"
      component={ProductCategoriesUpdate}
      exact
    />
  </CustomSwitch>
)
