import React, { useCallback, useEffect, useState } from 'react'
import { Input, Select } from '../../../../../../../components/FormProvider'

import { Container, FreightItem, IconRemove, Panel } from './styles'
import { useClient } from '../../../providers/ClientProvider'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { Alert } from '../../../../../../../components/Alert'
import api from '../../../../../../../services/api'
import { useLoading } from '../../../../../../../hooks/loading'
import { useToast } from '../../../../../../../hooks/toast'

export const FreightFields = ({ type }: { type: string }): JSX.Element => {
  const { initialValues, carriersOptions } = useClient()
  const [freightOptions, setFreightOptions] = useState<any[]>([
    {
      id: 1,
      open: true,
      show: true
    }
  ])

  useEffect(() => {
    if (initialValues?.freight) {
      setFreightOptions(
        initialValues.freight.map((freight: any) => ({
          ...freight,
          show: true,
          open: true,
          pickup_requested: freight.pickup_requested ? '1' : '0'
        }))
      )
    }
  }, [initialValues, type])

  const { activeLoading, disableLoading } = useLoading()

  const { addToast } = useToast()

  const [alert, setAlert] = useState({
    open: false,
    id: 0,
    index: 0
  })

  const handleAlertConfirm = useCallback(async () => {
    if (!alert.id) activeLoading()
    try {
      await api.delete(`commercial/clientsFreightsOptions/delete/${alert.id}`)
      setFreightOptions(prev => {
        const copy = [...prev]
        copy[alert.index].show = false
        return copy
      })
      disableLoading()
    } catch (error) {
      disableLoading()
      addToast({
        title: 'erro ao excluir frete'
      })
    }
    setAlert({
      open: false,
      id: 0,
      index: 0
    })
  }, [activeLoading, addToast, alert.id, alert.index, disableLoading])

  return (
    <Container>
      {freightOptions.map((freightItem, index) => {
        return (
          freightItem &&
          freightItem.show &&
          freightItem.type === type && (
            <FreightItem
              key={freightItem.id}
              className="panel-group"
              id="accordion"
            >
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <div
                      onClick={() =>
                        setFreightOptions((prev: any) => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].open = !copy[index].open
                          return copy
                        })
                      }
                    >
                      {freightItem?.freight_type?.toUpperCase() ||
                        'NOVA OPÇÃO DE FRETE'}
                      {!freightItem?.open ? (
                        <FaChevronDown size={13} />
                      ) : (
                        <FaChevronUp size={13} />
                      )}
                    </div>
                  </h4>
                </div>
                <Panel
                  id={`${index}`}
                  className={'panel-collapse'}
                  open={freightItem.open}
                  style={{
                    height: freightItem.open ? '90px' : '0px'
                  }}
                >
                  <div className="panel-body" style={{ paddingLeft: '0px' }}>
                    <div className="form-content col-md-11">
                      <div className="row no-margin-top">
                        <div className="col-md-3">
                          <Select
                            label="Tipo de frete"
                            className="form-control"
                            name={`freight.${index}.freight_type`}
                            options={[
                              { name: 'CIF', value: 'cif' },
                              { name: 'FOB', value: 'fob' }
                            ]}
                            blank
                            defaultValue={''}
                          />
                          <Input
                            name={`freight.${index}.type`}
                            value={type}
                            style={{ display: 'none' }}
                          />
                          <Input
                            name={`freight.${index}.id`}
                            style={{ display: 'none' }}
                          />
                        </div>
                        <div className="col-md-3">
                          <Select
                            label="Tipo de transportadora"
                            className="form-control"
                            name={`freight.${index}.transport_mode`}
                            options={[
                              { name: 'DO CLIENTE', value: 'client' },
                              { name: 'MULTFLUXO', value: 'multfluxo' },
                              { name: 'TERCEIRIZADA', value: 'outsource' }
                            ]}
                            blank
                            defaultValue={''}
                          />
                        </div>
                        <div className="col-md-3">
                          <Select
                            label="Transportadora"
                            className="form-control"
                            name={`freight.${index}.transport_id`}
                            options={carriersOptions}
                            blank
                            defaultValue={''}
                          />
                        </div>
                        <div className="col-md-3">
                          <Select
                            label="Multfluxo solicita a coleta para o cliente"
                            className="form-control"
                            name={`freight.${index}.pickup_requested`}
                            value={freightItem.pickup_requested}
                            onChange={e => {
                              setFreightOptions(prev => {
                                const copy = [...prev]
                                copy[index].pickup_requested = e.target.value
                                return copy
                              })
                            }}
                            options={[
                              { name: 'SIM', value: '1' },
                              { name: 'NÃO', value: '0' }
                            ]}
                            blank
                            defaultValue={''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ textAlign: 'center' }}>
                      <b>Ações</b>
                      <p>
                        <IconRemove
                          onClick={() => {
                            if (freightOptions.length > 1) {
                              if (freightItem.id) {
                                setAlert({
                                  open: true,
                                  id: freightItem.id,
                                  index
                                })
                              } else {
                                setFreightOptions(prev => {
                                  const copy = [...prev]
                                  copy[alert.index].show = false
                                  return copy
                                })
                              }
                            }
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </Panel>
              </div>
            </FreightItem>
          )
        )
      })}
      <button
        style={{ marginBottom: '10px', marginTop: '10px' }}
        type="button"
        onClick={() =>
          setFreightOptions(prev => {
            const copy = [...prev]
            return [
              ...copy,
              {
                open: true,
                show: true,
                type,
                name: ''
              }
            ]
          })
        }
        className="btn dark btn-sm sbold uppercase"
      >
        <span
          className="fa fa-plus"
          aria-hidden="true"
          style={{ marginRight: '5px' }}
        />
        Adicionar
      </button>
      <Alert
        message={'Tem certeza que deseja excluir o registro?'}
        onClickCancellButton={() => {
          setAlert({
            open: false,
            id: 0,
            index: 0
          })
        }}
        onClickConfirmButton={() => {
          handleAlertConfirm()
        }}
        isActive={alert.open}
      />
    </Container>
  )
}
