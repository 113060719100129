import React from 'react'
import List from '../../../pages/Administrative/CostCenters/List'
import Update from '../../../pages/Administrative/CostCenters/Update'
import Create from '../../../pages/Administrative/CostCenters/Create'
import View from '../../../pages/Administrative/CostCenters/View'
import Route from '../../Route'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const CostCentersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/costCenters" exact component={List} />
    <Route path="/financial/costCenters/update/:id" component={Update} exact />
    <Route path="/financial/costCenters/create" component={Create} exact />
    <Route path="/financial/costCenters/view/:id" component={View} exact />
  </CustomSwitch>
)
