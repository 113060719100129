import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container, ContainerActionsData, WrapperActionsData } from './styles'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utlis/mask'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import api from '../../../../services/api'
import Form, { Input } from '../../../../components/Form'
import currency from 'currency.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from '../../../../components/Alert'
import { useHistory, useLocation } from 'react-router'
import Button from '../../../../components/Button'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

interface StylesChildProps {
  th?: {
    classname: string
  }
  column4?: {
    classname: string
  }
}

type ProductCategory = {
  id: number
  name: string
  active: boolean
  employees: any
  manager_id: number
  [month: string]: any
}

interface TablePortletWithInputsProps {
  maintitle?: string
  subtitle?: string
  tableHead?: string[]
  isHighlight?: boolean
  stylesChild?: StylesChildProps
  setUpdate: (value: boolean) => void
  update: boolean
}

const TablePortletWithInputs: React.FC<TablePortletWithInputsProps> = ({
  maintitle,
  subtitle,
  tableHead,
  stylesChild,
  setUpdate,
  update
}) => {
  const history = useHistory()
  const location = new URLSearchParams(useLocation().search)

  const [year, setYear] = useState<string>('')

  const [toggleSubItems, setToggleSubItems] = useState<any>({})
  const [toggleCheckbox, setToggleCheckbox] = useState<any>({})
  const [categories, setCategories] = useState<ProductCategory[]>([])
  const [alertCopyAll, setAlertCopyAll] = useState(false)
  const [currentCopy, setCurrentCopy] = useState({
    event: '',
    month: '',
    tIndex: 0,
    eIndex: 0,
    tName: '',
    parent: false
  })

  const [percentage, setPercentage] = useState('')

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const [totalParent, setTotalParent] = useState<any>({})
  const [totalSum, setTotal] = useState<any>({})
  const months = useMemo(() => {
    return [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez'
    ]
  }, [])
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const [formError, setFormError] = useState({
    company: false,
    year: {
      message: 'O campo Ano é obrigatório!',
      error: false
    }
  })

  const handleItemSubtract = useCallback(
    (team, employee, tName) => {
      let total = totalSum
      let totalParent1 = totalParent

      months.forEach(month => {
        if (categories[team].employees[employee][month]) {
          const oldValue = categories[team].employees[employee][month].value
          categories[team].employees[employee][month] = {
            ...categories[team].employees[employee][month],
            value: undefined
          }

          if (!categories[team].employees[employee].manager_id) {
            totalParent1 = {
              ...totalParent1,
              [tName]: {
                ...totalParent1[tName],
                total: BRL(totalParent1[tName]?.total)
                  .subtract(oldValue)
                  .format(),
                [month]: BRL(totalParent1[tName]?.[month])
                  .subtract(oldValue)
                  .format()
              }
            }
            total = {
              ...total,
              [month]: BRL(total[month]).subtract(oldValue).format(),
              total: BRL(total.total).subtract(oldValue).format()
            }
          }
        }
      })
      categories[team].employees[employee].total = ''
      setTotal(total)
      setTotalParent(totalParent1)
    },
    [totalSum, months, categories, totalParent]
  )

  const handleToggleModal = useCallback(
    (event, item) => {
      event.stopPropagation()
      if (!toggleSubItems[item]) {
        setToggleSubItems({
          ...toggleSubItems,
          [item]: {
            name: item,
            isShow: true,
            isSelected: true
          }
        })
      }
      if (toggleSubItems[item]?.isShow) {
        setToggleSubItems({
          ...toggleSubItems,
          [item]: {
            name: item,
            isShow: false,
            isSelected: true
          }
        })
        return
      }
      if (!toggleSubItems[item]?.isShow) {
        setToggleSubItems({
          ...toggleSubItems,
          [item]: {
            name: item,
            isShow: true,
            isSelected: true
          }
        })
      }
    },
    [toggleSubItems]
  )

  const handleToggleCheckbox = useCallback(
    (team, employee, tIndex, eIndex) => {
      if (!toggleCheckbox[team]?.[employee]?.isShow) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [team]: {
            ...toggleCheckbox[team],
            [employee]: {
              isShow: true
            }
          }
        })
        return
      }
      if (toggleCheckbox[team]?.[employee]?.isShow) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [team]: {
            ...toggleCheckbox[team],
            [employee]: {
              isShow: false
            }
          }
        })
        handleItemSubtract(tIndex, eIndex, team)
      }
    },
    [handleItemSubtract, toggleCheckbox]
  )

  const handleGetAllTotal = useCallback(
    (month, tIndex, eIndex, tName, parent) => {
      let total = '0'
      let totalAll = '0'
      let totalParentItem = '0'
      let totalParentAll = '0'

      categories.forEach(item => {
        Object.values(item.employees).forEach((child: any) => {
          if (!child.manager_id) {
            if (!isNaN(BRL(child?.total).intValue)) {
              totalAll = BRL(child?.total).add(totalAll).format()
            }
            if (!isNaN(BRL(child?.[month]?.value).intValue)) {
              total = BRL(child?.[month]?.value).add(total).format()
            }
          }
        })
      })
      categories.forEach(item => {
        if (item.name === tName) {
          Object.values(item.employees).forEach((child: any) => {
            if (
              !isNaN(BRL(child[month]?.value).intValue) &&
              !child.manager_id
            ) {
              totalParentItem = BRL(child[month]?.value)
                .add(totalParentItem)
                .format()
            }
            months.forEach(month1 => {
              if (
                !isNaN(BRL(child[month1]?.value).intValue) &&
                !child.manager_id
              ) {
                totalParentAll = BRL(child[month1]?.value)
                  .add(totalParentAll)
                  .format()
              }
            })
          })
        }
      })
      setTotal((prev: any) => ({
        ...prev,
        [month]: total !== '0' ? total : '',
        total: totalAll !== '0' ? totalAll : ''
      }))
      if (!parent) {
        setTotalParent((prev: any) => ({
          ...prev,
          [tName]: {
            ...prev[tName],
            total: totalParentAll,
            [month]: totalParentItem !== '0' ? totalParentItem : ''
          }
        }))
      }
    },
    [categories, months]
  )

  const handleGetTotal = useCallback(
    (event, month, tIndex, eIndex, tName) => {
      if (!event) return
      if (categories[tIndex].employees[eIndex][month]?.value) {
        let total = '0'
        categories[tIndex].employees[eIndex][month] = {
          ...categories[tIndex].employees[eIndex][month],
          value: genericMaskWithTwoZeroWithPoint(event)
        }
        months.forEach(item => {
          if (categories[tIndex].employees[eIndex][item]?.value !== undefined) {
            total = BRL(categories[tIndex].employees[eIndex][item].value)
              .add(total)
              .format()
          }
        })
        categories[tIndex].employees[eIndex].total = total

        handleGetAllTotal(
          month,
          tIndex,
          eIndex,
          tName,
          categories[tIndex].employees[eIndex].manager_id
        )

        total = '0'
        return
      }
      categories[tIndex].employees[eIndex][month] = {
        ...categories[tIndex].employees[eIndex][month],
        value: genericMaskWithTwoZeroWithPoint(event)
      }
      categories[tIndex].employees[eIndex].total = categories[tIndex].employees[
        eIndex
      ].total
        ? BRL(genericMaskWithTwoZeroWithPoint(event))
            .add(
              genericMaskWithTwoZeroWithPoint(
                categories[tIndex].employees[eIndex].total
              )
            )
            .format()
        : genericMaskWithTwoZeroWithPoint(event)
      handleGetAllTotal(
        month,
        tIndex,
        eIndex,
        tName,
        categories[tIndex].employees[eIndex].manager_id
      )
    },
    [categories, handleGetAllTotal, months]
  )
  const copyAll = useCallback(
    (event, month, tIndex, eIndex, tName, parent) => {
      if (!event) return
      let current = false
      months.forEach(item => {
        if (item === month) {
          current = true
        }
        if (current) {
          let total = '0'
          categories[tIndex].employees[eIndex][item] = {
            ...categories[tIndex].employees[eIndex][item],
            value: genericMaskWithTwoZeroWithPoint(event)
          }
          months.forEach(item => {
            if (
              categories[tIndex].employees[eIndex][item]?.value !== undefined
            ) {
              total = BRL(categories[tIndex].employees[eIndex][item].value)
                .add(total)
                .format()
            }
          })
          categories[tIndex].employees[eIndex].total = total

          handleGetAllTotal(item, tIndex, eIndex, tName, parent)

          total = '0'
        }
      })
    },
    [months, categories, handleGetAllTotal]
  )

  const handleOnClickCopyAll = () => {
    setAlertCopyAll(false)
    const { event, month, tIndex, eIndex, tName } = currentCopy
    handleGetTotal(event, month, tIndex, eIndex, tName)
  }

  const handlerOnClickButtonConfirmCopyAll = async () => {
    setAlertCopyAll(false)
    const { event, month, tIndex, eIndex, tName, parent } = currentCopy
    copyAll(event, month, tIndex, eIndex, tName, parent)
  }

  const loadOne = useCallback(
    async (categories: any) => {
      const monthsCodes = [
        ['jan', '01'],
        ['fev', '02'],
        ['mar', '03'],
        ['abr', '04'],
        ['mai', '05'],
        ['jun', '06'],
        ['jul', '07'],
        ['ago', '08'],
        ['set', '09'],
        ['out', '10'],
        ['nov', '11'],
        ['dez', '12']
      ]
      const { data } = await api.get('/commercial/goals/view/', {
        params: {
          year
        }
      })
      if (data) {
        setUpdate(true)
        history.replace(`/commercial/goalsEmployees/update?year=${year}&edit=1`)
        let total: any = {}
        let totalParent: any = {}
        let toggleCheckbox: any = {}
        for (const e of data) {
          const value = genericMaskWithTwoZeroWithPoint(e.total_value)
          console.log(e.manager_id)
          const managerId = e.manager_id || e.employee_id
          const team = categories.find(
            (s: { manager_id: any }) => s.manager_id === managerId
          )
          if (!team) continue
          const sub: any = Object.values(team.employees).find(
            (s: any) => s.employee_id === e.employee_id
          )
          if (!sub) continue
          const code = monthsCodes.find(mC => mC[1] === e.month)
          sub[code[0]] = {
            id: e.id,
            value: value
          }
          sub.total = BRL(sub[code[0]].value).add(sub.total).format()
          if (!e.manager_id) {
            total = {
              ...total,
              [code[0]]: BRL(total[code[0]]).add(value).format(),
              total: BRL(total.total).add(value).format()
            }
          }
          if (!e.manager_id) {
            totalParent = {
              ...totalParent,
              [team.name]: {
                ...totalParent[team.name],
                total: BRL(totalParent[team.name]?.total).add(value).format(),
                [code[0]]: BRL(totalParent[team.name]?.[code[0]])
                  .add(value)
                  .format()
              }
            }
          }
          toggleCheckbox = {
            ...toggleCheckbox,
            [team.name]: {
              ...toggleCheckbox[team.name],
              [sub.name]: {
                ...toggleCheckbox[sub.name],
                isShow: true
              }
            }
          }
        }

        setTotal(total)
        setTotalParent(totalParent)
        setToggleCheckbox(toggleCheckbox)
      } else {
        setUpdate(false)
        history.push('/commercial/goalsEmployees/create')
      }
      disableLoading()
    },
    [disableLoading, history, setUpdate, year]
  )

  const loadCategories = useCallback(async () => {
    const { data } = await api.get<ProductCategory[]>(
      'commercial/goalsEmployees/teams'
    )
    const list = data.map(category => ({
      id: category.id,
      name: category.name,
      employees: category.employees,
      manager_id: category.manager_id,
      active: false
    }))
    setCategories(list)
    if (year?.length === 4) {
      setTotal({})
      setToggleCheckbox({})

      await loadOne(list)
    }
    if (!year) {
      disableLoading()
    }
  }, [disableLoading, loadOne, year])

  useEffect(() => {
    if (year.length === 4) {
      setFormError(prev => ({
        ...prev,
        year: {
          ...prev.year,
          error: false
        }
      }))
    }
    if (year?.length === 4) {
      ;(async () => {
        const { data } = await api.get('/commercial/goals/view/', {
          params: {
            year
          }
        })
        if (data) loadCategories()
      })()
    } else {
      disableLoading()
    }
  }, [disableLoading, loadCategories, year])

  useEffect(() => {
    activeLoading()
    loadCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading])

  useEffect(() => {
    if (location.get('year')) {
      setYear(location.get('year'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function validateCompanyYear() {
    if (year.length !== 4) {
      addToast({
        type: 'error',
        title: 'Campos incorretos',
        description: 'É necessário informar a empresa e o ano.'
      })
      if (year.length < 4) {
        setFormError(prev => ({
          ...prev,
          year: {
            ...prev.year,
            error: true
          }
        }))
      }
      return false
    }
    return true
  }

  async function handleSaveButton() {
    const validate = validateCompanyYear()
    if (!validate) return

    activeLoading()
    const monthsCodes = [
      ['jan', '01'],
      ['fev', '02'],
      ['mar', '03'],
      ['abr', '04'],
      ['mai', '05'],
      ['jun', '06'],
      ['jul', '07'],
      ['ago', '08'],
      ['set', '09'],
      ['out', '10'],
      ['nov', '11'],
      ['dez', '12']
    ]
    const final: any = {
      year,
      categories: [],
      deletes: []
    }
    categories.forEach(category => {
      Object.values(category.employees).forEach((employee: any) => {
        Object.keys(employee).forEach(a => {
          if (months.includes(a)) {
            const code = monthsCodes.find(mC => mC[0] === a)
            if (employee[a].value) {
              const value = BRL(employee[a].value).value
              final.categories.push({
                id: employee[a].id,
                employee_id: employee.employee_id,
                manager_id: employee.manager_id,
                month: code[1],
                total_value: Number(value),
                agent_id: employee.agent_id
              })
            } else {
              if (employee[a].id) {
                final.deletes.push(employee[a].id)
              }
            }
          }
        })
      })
    })
    console.log(final)
    if (update) {
      try {
        await api.put('/commercial/goals/update', { ...final })
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        await loadCategories()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    } else {
      final.deletes = undefined
      try {
        await api.post('/commercial/goals/create', { ...final })
        history.push('/commercial/goalsEmployees')
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  function getLastYearValues(hasPercentage: boolean) {
    activeLoading()
    const validate = validateCompanyYear()
    if (!validate) {
      disableLoading()
      return
    }
    ;(async () => {
      const { data } = await api.get('/commercial/goals/view/', {
        params: {
          year: String(Number(year) - 1)
        }
      })
      if (data) {
        const monthsCodes = [
          ['jan', '01'],
          ['fev', '02'],
          ['mar', '03'],
          ['abr', '04'],
          ['mai', '05'],
          ['jun', '06'],
          ['jul', '07'],
          ['ago', '08'],
          ['set', '09'],
          ['out', '10'],
          ['nov', '11'],
          ['dez', '12']
        ]
        let total: any = {}
        let toggleCheckbox: any = {}
        for (const e of data) {
          let value = genericMaskWithTwoZeroWithPoint(e.total_value)
          if (hasPercentage) {
            const percentageValue = percentage.replace('%', '')
            if (!percentageValue) {
              disableLoading()
              return
            }
            const percentageFinal = BRL(
              genericMaskWithTwoZeroWithPoint(e.total_value)
            ).multiply(BRL(percentageValue).divide(100))
            value = BRL(genericMaskWithTwoZeroWithPoint(e.total_value))
              .add(percentageFinal)
              .format()
          }
          const managerId = e.manager_id || e.employee_id
          const team = categories.find(
            (s: { manager_id: any }) => s.manager_id === managerId
          )
          if (!team) continue
          const sub: any = Object.values(team.employees).find(
            (s: any) => s.employee_id === e.employee_id
          )
          if (!sub) continue
          const code = monthsCodes.find(mC => mC[1] === e.month)
          sub[code[0]] = {
            value: value
          }
          sub.total = BRL(sub[code[0]].value).add(sub.total).format()
          if (!e.manager_id) {
            total = {
              ...total,
              [code[0]]: BRL(total[code[0]]).add(value).format(),
              total: BRL(total.total).add(value).format()
            }
          }
          toggleCheckbox = {
            ...toggleCheckbox,
            [team.name]: {
              ...toggleCheckbox[team.name],
              [sub.name]: {
                ...toggleCheckbox[sub.name],
                isShow: true
              }
            }
          }
        }

        setTotal(total)
        setToggleCheckbox(toggleCheckbox)
      } else {
        addToast({
          type: 'error',
          title: 'Nenhum registro encontrado para o ano anterior'
        })
      }
    })()
    disableLoading()
  }

  return (
    <>
      <Container>
        <div className="row">
          <Form>
            <div className="col-md-3">
              <Input
                hasError={formError.year}
                type="text"
                onKeyPress={event => {
                  const regex = /^[0-9.]+$/
                  if (!regex.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                maxLength={4}
                className="form-control"
                label="Ano"
                name="year"
                value={year}
                onChange={e => setYear(e.target.value)}
                readOnly={update}
              />
            </div>
          </Form>
        </div>
        <div className="portlet light ">
          {maintitle && (
            <div className="portlet-title">
              <div className="caption caption-md">
                <i className="icon-bar-chart font-dark hide"></i>
                <span className="maintitle">{maintitle}</span>
              </div>
            </div>
          )}
          <div className="portlet-body">
            <div className="portlet light custom">
              <div className="portlet-title">
                <div className="caption caption-md">
                  <i className="icon-bar-chart font-dark hide"></i>
                  <span className="subtitle">{subtitle}</span>
                </div>
              </div>
            </div>
            {!update && (
              <ContainerActionsData>
                <WrapperActionsData>
                  <button onClick={() => getLastYearValues(false)}>
                    Dados Ano Anterior
                  </button>
                  <div className="WrapperPercentage">
                    <Input
                      name="fev"
                      placeholder="%  0,00"
                      value={percentage}
                      onChange={event =>
                        setPercentage(
                          '%  ' +
                            genericMaskWithTwoZeroWithPoint(event.target.value)
                        )
                      }
                    />
                  </div>
                  <button onClick={() => getLastYearValues(true)}>
                    Calcular
                  </button>
                </WrapperActionsData>
              </ContainerActionsData>
            )}
            <div>
              <table className="table table-light">
                <thead className="tableHead">
                  <tr>
                    {tableHead.map((item, index) =>
                      index === 0 ? (
                        <th
                          key={`${item}`}
                          className={
                            index !== 0
                              ? stylesChild.th?.classname
                              : `${stylesChild.th?.classname} firstTableHead`
                          }
                        ></th>
                      ) : (
                        <th
                          key={`${item}`}
                          className={
                            index !== 0
                              ? stylesChild.th?.classname
                              : `${stylesChild.th?.classname} firstTableHead`
                          }
                        >
                          {item}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {categories?.map((team, tIndex) => (
                    <React.Fragment key={team.name}>
                      <tr
                        key={team.name}
                        className={
                          toggleSubItems[team.name]?.isShow
                            ? 'activeCustom'
                            : 'title'
                        }
                      >
                        <td>
                          <div
                            className="LastChildSpace"
                            onClick={event =>
                              handleToggleModal(event, team.name)
                            }
                          >
                            {team.name}
                            {team.name === toggleSubItems[team.name]?.name ? (
                              !toggleSubItems[team.name]?.isShow ? (
                                <FaChevronDown size={13} />
                              ) : (
                                <FaChevronUp size={13} />
                              )
                            ) : (
                              <FaChevronDown size={13} />
                            )}
                          </div>
                        </td>
                        {months.map(month => (
                          <td
                            className="TableData total-category"
                            key={`${team.name}${month}`}
                          >
                            {totalParent[team.name] &&
                              totalParent[team.name][month] !== '0,00' &&
                              totalParent[team.name][month]}
                          </td>
                        ))}
                        <td className="TableData total-category">
                          {totalParent[team.name]?.total}
                        </td>
                      </tr>
                      {toggleSubItems[team.name]?.isShow &&
                        team.employees &&
                        Object.entries(team.employees).map(
                          ([key, employee]: any) => (
                            <tr key={employee.name} className="trChild">
                              <td className="firstChild">
                                <input
                                  type="checkbox"
                                  name={`check${tIndex + key + employee.name}`}
                                  id={`check${tIndex + key + employee.name}`}
                                  onClick={() =>
                                    handleToggleCheckbox(
                                      team.name,
                                      employee.name,
                                      tIndex,
                                      key
                                    )
                                  }
                                  defaultChecked={
                                    toggleCheckbox[team.name]?.[employee.name]
                                      ?.isShow
                                  }
                                />
                                <label
                                  htmlFor={`check${
                                    tIndex + key + employee.name
                                  }`}
                                >
                                  {employee.name}
                                </label>
                              </td>
                              {toggleCheckbox[team.name]?.[employee.name]
                                ?.isShow ? (
                                <>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.jan?.value,
                                              month: 'jan',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }

                                      <Input
                                        name="jan"
                                        value={employee.jan?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'jan',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.fev?.value,
                                              month: 'fev',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="fev"
                                        value={employee.fev?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'fev',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.mar?.value,
                                              month: 'mar',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="mar"
                                        value={employee.mar?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'mar',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.abr?.value,
                                              month: 'abr',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="abr"
                                        value={employee.abr?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'abr',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.mai?.value,
                                              month: 'mai',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="mai"
                                        value={employee.mai?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'mai',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.jun?.value,
                                              month: 'jun',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="jun"
                                        value={employee.jun?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'jun',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.jul?.value,
                                              month: 'jul',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="jul"
                                        value={employee.jul?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'jul',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.ago?.value,
                                              month: 'ago',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="ago"
                                        value={employee.ago?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'ago',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.set?.value,
                                              month: 'set',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="set"
                                        value={employee.set?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'set',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.out?.value,
                                              month: 'out',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="out"
                                        value={employee.out?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'out',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      {
                                        <FontAwesomeIcon
                                          icon={faCopy}
                                          onClick={() => {
                                            setAlertCopyAll(true)
                                            setCurrentCopy({
                                              event: employee.nov?.value,
                                              month: 'nov',
                                              tIndex,
                                              eIndex: key,
                                              tName: team.name,
                                              parent: employee.manager_id
                                            })
                                          }}
                                        />
                                      }
                                      <Input
                                        name="nov"
                                        value={employee.nov?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'nov',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData">
                                    <div className="Border">
                                      <Input
                                        name="dez"
                                        value={employee.dez?.value || ''}
                                        onChange={event =>
                                          handleGetTotal(
                                            event.target.value,
                                            'dez',
                                            tIndex,
                                            key,
                                            team.name
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="TableData total-category">
                                    {employee.total !== '0,00' &&
                                      employee.total}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                </>
                              )}
                            </tr>
                          )
                        )}
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="TableRowTotal">
                    <td>SOMA TOTAL</td>
                    <td className="BoldBlack">
                      {totalSum.jan !== '0,00' && totalSum.jan}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.fev !== '0,00' && totalSum.fev}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.mar !== '0,00' && totalSum.mar}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.abr !== '0,00' && totalSum.abr}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.mai !== '0,00' && totalSum.mai}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.jun !== '0,00' && totalSum.jun}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.jul !== '0,00' && totalSum.jul}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.ago !== '0,00' && totalSum.ago}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.set !== '0,00' && totalSum.set}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.out !== '0,00' && totalSum.out}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.nov !== '0,00' && totalSum.nov}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.dez !== '0,00' && totalSum.dez}
                    </td>
                    <td className="BoldBlack">
                      {totalSum.total !== '0,00' && totalSum.total}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div className="ContainerButton">
          <Button
            onClick={handleSaveButton}
            type="submit"
            className="btn dark btn-sm sbold uppercase"
          >
            Salvar
          </Button>
        </div>
      </Container>
      <Alert
        message={'Deseja copiar para todos os meses?'}
        onClickCancellButton={handleOnClickCopyAll}
        onClickConfirmButton={handlerOnClickButtonConfirmCopyAll}
        isActive={alertCopyAll}
        title="Copiar"
        textConfim="SIM"
        textCancel="NÃO"
      />
    </>
  )
}

export { TablePortletWithInputs }
