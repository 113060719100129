import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../../components/Container'
import DataTable from '../../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import moment from 'moment'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { FilterContaier, ListTitle } from './styles'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date } from '../../../../../components/Form/date'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { Loading } from '../../../../../components/Loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'

const ReconciliationOrderButton = ({
  statusExpedition
}: {
  statusExpedition: string
}) => {
  if (statusExpedition !== '11') {
    return null
  }
  return (
    <div>
      <button type="button" className="btn dark btn-sm bold uppercase">
        Conciliar pagamentos
      </button>
    </div>
  )
}

const List: React.FC = () => {
  const { updateDataTable } = useUpdateDataTable()
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()
  const [statusExpedition, setSituation] = useState('')
  const [searchType, setSearchType] = useState('')
  const [statusExpeditionList, setSituationList] = useState([])
  const [id, setId] = useState(0)

  const [parameters, setParameters] = useState<any[]>()
  const history = useHistory()

  const onSearch = async () => {
    const query = []
    if (id) {
      query.push(`id=${id}`)
    }
    if (startDate) {
      query.push(`startDate=${moment(startDate).format().substring(0, 10)}`)
    }
    if (finalDate) {
      query.push(`finalDate=${moment(finalDate).format().substring(0, 10)}`)
    }
    if (statusExpedition) {
      query.push(`statusExpedition=${statusExpedition}`)
    }
    if (searchType) {
      query.push(`searchType=${searchType}`)
    }

    history.replace(`/warehouse/expedition/orders?${query.join('&')}`)
    if (startDate || finalDate || statusExpedition || id || searchType) {
      setParameters([
        {
          type: 'V',
          status: 'A',
          id: id || undefined,
          startDate: startDate || undefined,
          finalDate: finalDate || undefined,
          statusExpedition: statusExpedition ? [statusExpedition] : undefined,
          searchType: searchType || undefined
        }
      ])
    } else {
      setParameters([
        {
          type: 'V',
          status: 'A'
        }
      ])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('/warehouse/expedition/ordersStatus')
      setSituationList(data)
    })()
    const id = location.get('id')
    const startDate = location.get('startDate')
    const finalDate = location.get('finalDate')
    const statusExpedition = location.getAll('statusExpedition')
    const searchType = location.get('searchType')
    const prevStatus = location.get('prevStatus')
    const late = location.get('late')

    if (
      startDate ||
      finalDate ||
      statusExpedition ||
      id ||
      searchType ||
      prevStatus ||
      late
    ) {
      setId(Number(id))
      if (startDate) {
        setStartDate(moment(`${startDate} 00:00:00`).toDate())
      }
      if (finalDate) {
        setFinalDate(moment(`${finalDate} 00:00:00`).toDate())
      }
      if (statusExpedition.length) {
        setSituation(statusExpedition[0])
      }
      setSearchType(searchType)
      setParameters([
        {
          type: 'V',
          status: 'A',
          id: id || undefined,
          startDate: startDate ? moment(startDate).toDate() : undefined,
          finalDate: finalDate ? moment(finalDate).toDate() : undefined,
          statusExpedition: statusExpedition || undefined,
          searchType: searchType || undefined,
          prevStatus: prevStatus || undefined,
          late: late || undefined
        }
      ])
    } else {
      setParameters([
        {
          type: 'V',
          status: 'A'
        }
      ])
    }
  }, [])

  const handleReconciliationOrders = useCallback(async () => {
    setIsLoading(true)
    try {
      await api.get('/commercial/orders/reconciliation')
      updateDataTable()
      addToast({
        title: 'Pedidos conciliados',
        description: 'Pedidos conciliados',
        type: 'success'
      })
    } catch (error) {
      addToast({
        title: 'Ocorreu um erro',
        description:
          'Ocorreu um erro ao conciliar os pedidos, tente novamente mais tarde.',
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }, [addToast, updateDataTable])

  return (
    <>
      {isLoading && <Loading isActive />}
      <Container
        pageTitle={`${namePageTitle}${
          statusExpedition
            ? ' - ' +
              statusExpeditionList.find(
                list => list.id === Number(statusExpedition)
              )?.name
            : ''
        }`}
        tools={[
          {
            name: '',
            to: '#',
            iconElement: (
              <ReconciliationOrderButton statusExpedition={statusExpedition} />
            ),
            hasParams: false,
            handleOnClick: handleReconciliationOrders
          }
        ]}
        portletTitle={'Filtro'}
        breadcrumb={breadcrumbList}
      >
        <FilterContaier>
          <Form onSubmit={onSearch} defaultValues={{}}>
            <div className="col-md-2">
              <Input
                label="Nº Pedido"
                className="form-control"
                name="id"
                onChange={e => setId(Number(e.target.value))}
                value={id}
                controlled
              />
            </div>
            <div className="col-md-2">
              <Date
                label="Data inicial"
                className="form-control"
                name="start_date"
                onChange={date => setStartDate(date)}
                selected={startDate}
                controlled
              />
            </div>
            <div className="col-md-2">
              <Date
                label="Data final"
                className="form-control"
                name="final_date"
                onChange={date => setFinalDate(date)}
                selected={finalDate}
                controlled
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Tipo de busca"
                className="form-control"
                name="searchType"
                options={[
                  {
                    name: 'DATA EXPIRAÇÃO',
                    value: 'expedicao_status_expiracao'
                  },
                  { name: 'DATA ATUALIZAÇÃO', value: 'updated_at' },
                  { name: 'DATA PEDIDO', value: 'date' },
                  { name: 'ENTREGA PREVISTA', value: 'delivery_date' }
                ]}
                onChange={e => setSearchType(e.target.value)}
                value={searchType}
                controlled
                blank
                defaultValue={''}
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Etapa"
                className="form-control"
                name="statusExpedition"
                options={statusExpeditionList?.map(s => ({
                  name: s.name,
                  value: s.id
                }))}
                onChange={e => setSituation(e.target.value)}
                value={statusExpedition}
                controlled
                blank
                defaultValue={''}
              />
            </div>
            <footer className="col-md-12">
              <button
                type="reset"
                onClick={() => {
                  setStartDate(undefined)
                  setFinalDate(undefined)
                  setSituation('')
                  setSearchType('')
                  setId(0)
                }}
              >
                LIMPAR
              </button>
              <button type="submit">BUSCAR</button>
            </footer>
          </Form>
          <hr />
        </FilterContaier>
        <ListTitle>
          <p>Listagem</p>
          <hr style={{ paddingBottom: 10 }} />
        </ListTitle>
        {parameters && (
          <DataTable
            source={nameSource}
            entity={nameEntity}
            format={{ orderBy: 'id' }}
            orderBySort={'DESC'}
            notHasChildren
            searchParameters={parameters}
            headers={headers}
            rowStyled
            customHeaders={[
              {
                field: 'delivery_date',
                name: 'Entrega',
                sortable: false,
                element: item => (
                  <span>
                    {item.delivery_date
                      ? moment(item.delivery_date, 'DD/MM/YYYY HH:m:ss')
                          .add({ hour: 3 })
                          .format('DD/MM/YYYY')
                      : ''}
                  </span>
                )
              },
              {
                field: 'date',
                name: 'Data',
                sortable: false,
                element: item => (
                  <span>
                    {item.date
                      ? moment(item.date, 'DD/MM/YYYY HH:m:ss')
                          .add({ hour: 3 })
                          .format('DD/MM/YYYY')
                      : ''}
                  </span>
                )
              },
              {
                field: 'expedicao_status_expiracao',
                name: 'Etapa expiração',
                sortable: false,
                element: item => (
                  <span>
                    {item.expedicao_status_expiracao
                      ? moment(
                          item.expedicao_status_expiracao,
                          'DD/MM/YYYY HH:mm:ss'
                        )
                          .add({ hours: 3 })
                          .format('DD/MM/YYYY')
                      : ''}
                  </span>
                )
              },
              {
                field: 'expedicao_status_data',
                name: 'Etapa atualização',
                sortable: false,
                element: item => (
                  <span>
                    {item.expedicao_status_data
                      ? moment(
                          item.expedicao_status_data,
                          'DD/MM/YYYY HH:mm:ss'
                        )
                          .add({ hours: 3 })
                          .format('DD/MM/YYYY')
                      : ''}
                  </span>
                )
              },
              {
                field: 'actions',
                name: 'Ações',
                sortable: false,
                element: item => (
                  <div>
                    <a
                      onClick={() =>
                        history.push(
                          `/warehouse/expedition/orders/view/${item.id}`
                        )
                      }
                    >
                      <span className="fa fa-search"></span>
                    </a>
                  </div>
                )
              }
            ]}
          />
        )}
      </Container>
    </>
  )
}
export default List
