import styled from 'styled-components'

export const Container = styled.div`
  table {
    border-collapse: collapse;
    table-layout: auto;
    th {
      width: 90px;
    }
  }
  .table thead tr th.actions,
  .table thead tr td.actions,
  .table tbody tr td:last-child {
    text-align: left;
  }
  .table > thead > tr > th {
    border: 0;
  }
  .table > thead > tr > th.hasBottomBorder {
    border-bottom: 1px solid #f2f5f8;
  }
  .table > tbody > tr > td {
    color: #a0a0a0;
  }
  .bold {
    color: rgb(224, 142, 32);
  }
  .boldGrey {
    color: #a0a0a0;
    font-weight: 700;
  }
  .LastChildSpace {
    /* display: flex;
    justify-content: space-between; */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    svg {
      margin-left: 22px;
    }
  }
  tr {
    cursor: pointer;
  }
  tr.activeCustom {
    > td {
      background: #ebebeb;
      color: #333;
      height: 84px;
    }
  }
  tr.title {
    > td {
      height: 84px;
    }
  }
  tr.table-head-tr {
    height: 84px;
  }
  tr.TableRowTotal {
    height: 84px;
  }
  span.maintitle {
    color: #697882;
    font-family: 'Font', sans-serif;
    font-weight: 400;
  }
  span.subtitle {
    color: #697882;
    font-family: 'Font', sans-serif;
    font-weight: 400;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    vertical-align: middle;
  }

  .table > thead.TableHead > tr > th {
    height: 60px;
    padding: 5px;
    vertical-align: middle;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #a0a0a0;
    width: 135px;
    height: 30;
    font-size: 14px;
  }
  .btn.btn-outline.active,
  .btn.btn-outline:active,
  .btn.btn-outline:active:focus,
  .btn.btn-outline:active:hover,
  .btn.btn-outline:focus,
  .btn.btn-outline:hover {
    background: #a0a0a0;
    color: #fff;
  }
  .cost-center-btn {
    width: 200px;
  }

  .table.table-light > thead > tr > th {
    color: #a0a0a0;
    font-weight: 400;
    font-size: 14px;
  }
  .table.table-light > tbody > tr.trChild {
    background: #f5f5f5;
    td {
      border-bottom: 0px;
    }
    td.firstChild {
      padding: 15px 5px 15px 25px;
      input,
      label {
        cursor: pointer;
      }
    }
  }
  .table.table-light > tbody > tr.activeCustom {
    td {
      border-bottom: 0px;
    }
  }
  .table.table-light > tbody > tr.disableCustom {
    border-bottom: 1px solid #f2f5f8;
  }
  .table.table-light thead tr th {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
  }
  .table.table-light thead tr th.firstTableHead {
    width: 270px;
    font-size: 16px;
  }
  .table.table-light {
    .TableData {
      background: #fff;
      border-bottom: 1px solid #f2f5f8;
      /* height: 84px; */
      .Border {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 0px 8px 5px;
        border: 1px solid rgb(226, 226, 226);
        width: 100%;
        > div {
          margin-bottom: 0;
        }
        svg {
          color: rgb(226, 226, 226);
        }
      }
      div {
        width: 90%;
        div {
          input {
            outline: none;
            border: 0;
            width: 100%;
            padding: 0;
            padding-left: 5px;
          }
        }
      }
    }
    .total-category {
      font-weight: bold;
    }
  }
  .table.table-light > tbody > tr.activeCustom td.TableData {
    border-bottom: 1px solid #f2f5f8;
  }
  .table.table-light > tbody > tr.trChild td.TableData {
    border-bottom: 1px solid #f2f5f8;
  }
  .table.table-light td.firstChild {
    text-transform: capitalize;
    input {
      margin-right: 10px;
    }
  }
  .table.table-light tr.TableRowTotal {
    border-bottom: 1px solid #f2f5f8;
  }

  .table.table-light.custom {
    width: 50%;
    margin-bottom: 40px;
  }
  .portlet.light.custom {
    padding: 0;
  }
  .table.table-light td.SemiBold {
    font-weight: 500;
    color: #a0a0a0;
  }
  .TableRowTotal {
    color: #a0a0a0;
  }
  .TableRowTotal .BoldBlack {
    font-weight: bold;
    color: #000;
  }
  .table > tbody > tr > td.BoldBlack {
    font-weight: bold;
    color: #000;
  }
  .ContainerButton {
    width: 100%;
    display: flex;
    justify-content: right;
    button {
      outline: none;
      border: 1px solid #e7e7e7;
      padding: 5px;
      font-size: 13px;
      background: transparent;
      margin-bottom: 40px;
      margin-right: 25px;
    }
  }
`

export const ContainerActionsData = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 4rem;
`
export const WrapperActionsData = styled.div`
  display: flex;
  button {
    margin-left: 5px;
    height: 32px;
    min-width: 132px;
    padding: 0 10px;
    background: #a0a0a0;
    color: #fff;
    border: 0;
    outline: 0;
  }
  .WrapperPercentage {
    margin-left: 45px;
    input {
      outline: 0;
      border: 1px solid rgb(226, 226, 226);
      height: 30px;
      width: 100px;
      padding-left: 5px;
    }
  }
`

export const CostCenterTotal = styled.div`
  position: absolute;
  height: 20px;
  width: 100%;
  top: 50px;
  right: 50%;
  transform: translateX(calc(50% - 5px));
  text-align: center;
`
