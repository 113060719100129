import React from 'react'
import List from '../../pages/Financial/ExpenseBudget/List'
import ExpenseBudget from '../../pages/Financial/ExpenseBudget'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ExpenseBudgetRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/expenseBudget" exact component={List} />
    <Route
      path="/financial/expenseBudget/update"
      component={ExpenseBudget}
      exact
    />
    <Route
      path="/financial/expenseBudget/create"
      component={ExpenseBudget}
      exact
    />
  </CustomSwitch>
)
