import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Container,
  ContainerActionsData,
  WrapperActionsData,
  CostCenterTotal
} from './styles'
import { genericMaskWithTwoZeroWithPoint } from '../../utlis/mask'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import api from '../../services/api'
import Form, { Input, Select } from '../Form'
import currency from 'currency.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from '../Alert'
import { useHistory, useLocation } from 'react-router'
import Button from '../Button'
import { useLoading } from '../../hooks/loading'
import { useToast } from '../../hooks/toast'

type CostCenter = {
  id: number
  name: string
}

interface StylesChildProps {
  th?: {
    classname: string
  }
  column4?: {
    classname: string
  }
}

interface ActionTab {
  id: number
  name: string
  active: boolean
  initialData: any[]
}

interface Company {
  id: number
  name: string
}

interface TablePortletWithInputsProps {
  getUserData(): void
  setInitialData(): void
  realData?: any[]
  initialData?: any[]
  maintitle?: string
  subtitle?: string
  actions?: string[]
  tableHead?: string[]
  isHighlight?: boolean
  isHighlightOver?: boolean
  hasDropdown?: boolean
  className?: string
  lastChildStyle?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  stylesChild?: StylesChildProps
  setUpdate: (value: boolean) => void
  update: boolean
}

const TablePortletWithInputs: React.FC<TablePortletWithInputsProps> = ({
  maintitle,
  subtitle,
  tableHead,
  isHighlight = true,
  className,
  stylesChild,
  setUpdate,
  update
}) => {
  const [hiddenCost, setHiddenCost] = useState<number[]>(() => {
    const storageHiddenCost = localStorage.getItem('@Multfluxo:hidden-cost')
    if (storageHiddenCost?.length) {
      return JSON.parse(storageHiddenCost)
    }
    return []
  })
  const [selectedItem, setSelectedItem] = useState({
    index: 0,
    name: 'ADMINISTRATIVO'
  })
  const history = useHistory()
  const location = new URLSearchParams(useLocation().search)

  const [companies, setCompanies] = useState<Company[]>([])
  const [company, setCompany] = useState<string>('')
  const [year, setYear] = useState<string>('')

  const [toggleSubItems, setToggleSubItems] = useState<any>({})
  const [toggleCheckbox, setToggleCheckbox] = useState<any>({})
  const [actionTabs, setActionTabs] = useState<ActionTab[]>([])
  const [alertCopyAll, setAlertCopyAll] = useState(false)
  const [currentCopy, setCurrentCopy] = useState<any>()

  const [percentage, setPercentage] = useState('')

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const handleSwitchPeriod = useCallback(
    (index: number, name: string) => {
      if (index === selectedItem.index) {
        setSelectedItem({
          index: 0,
          name: 'ADMINISTRATIVO'
        })
        return
      }
      setSelectedItem({
        index,
        name
      })
    },
    [selectedItem.index]
  )
  const [totalSum, setTotal] = useState<any>({})
  const [totalParent, setTotalParent] = useState<any>({})
  const months = useMemo(() => {
    return [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez'
    ]
  }, [])
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const [formError, setFormError] = useState({
    company: false,
    year: {
      message: 'O campo Ano é obrigatório!',
      error: false
    }
  })

  const handleItemSubtract = useCallback(
    (
      {
        categoryName,
        categoryIndex,
        subIndex,
        costCenterIndex
      }: {
        categoryName: string
        categoryIndex: number
        subIndex: number
        costCenterIndex: number
      },
      costCenterName: string
    ) => {
      let total = totalSum
      let totalParent1 = totalParent

      months.forEach(month => {
        if (
          actionTabs[costCenterIndex].initialData[categoryIndex]
            .childCategories[subIndex][month]
        ) {
          const oldValue =
            actionTabs[costCenterIndex].initialData[categoryIndex]
              .childCategories[subIndex][month].value
          actionTabs[costCenterIndex].initialData[
            categoryIndex
          ].childCategories[subIndex][month] = {
            ...actionTabs[costCenterIndex].initialData[categoryIndex]
              .childCategories[subIndex][month],
            value: undefined
          }
          actionTabs[actionTabs.length - 1].initialData[
            categoryIndex
          ].childCategories[subIndex][month] = {
            ...actionTabs[actionTabs.length - 1].initialData[categoryIndex]
              .childCategories[subIndex][month],
            value: BRL(
              actionTabs[actionTabs.length - 1].initialData[categoryIndex]
                .childCategories[subIndex][month]?.value
            ).subtract(oldValue)
          }
          actionTabs[actionTabs.length - 1].initialData[
            categoryIndex
          ].childCategories[subIndex].total = BRL(
            actionTabs[actionTabs.length - 1].initialData[categoryIndex]
              .childCategories[subIndex]?.total
          )
            .subtract(oldValue)
            .format()

          total = {
            ...total,
            [costCenterName]: {
              ...total[costCenterName],
              [month]: BRL(total[costCenterName]?.[month])
                .subtract(oldValue)
                .format(),
              total: BRL(total[costCenterName]?.total)
                .subtract(oldValue)
                .format()
            },
            TOTAL: {
              ...total.TOTAL,
              [month]: BRL(total.TOTAL?.[month]).subtract(oldValue).format(),
              total: BRL(total.TOTAL?.total).subtract(oldValue).format()
            }
          }

          totalParent1 = {
            ...totalParent1,
            [costCenterName]: {
              ...totalParent1[costCenterName],
              [categoryName]: {
                ...totalParent1[costCenterName]?.[categoryName],
                total: BRL(totalParent1[costCenterName]?.[categoryName]?.total)
                  .subtract(oldValue)
                  .format(),
                [month]: BRL(
                  totalParent1[costCenterName]?.[categoryName]?.[month]
                )
                  .subtract(oldValue)
                  .format()
              }
            },
            TOTAL: {
              ...totalParent1.TOTAL,
              [categoryName]: {
                ...totalParent1.TOTAL?.[categoryName],
                total: BRL(totalParent1.TOTAL?.[categoryName]?.total)
                  .subtract(oldValue)
                  .format(),
                [month]: BRL(totalParent1.TOTAL?.[categoryName]?.[month])
                  .subtract(oldValue)
                  .format()
              }
            }
          }
        }
      })
      actionTabs[costCenterIndex].initialData[categoryIndex].childCategories[
        subIndex
      ].total = ''
      setTotal(total)
      setTotalParent(totalParent1)
    },
    [actionTabs, months, totalSum, totalParent]
  )

  const handleToggleModal = useCallback(
    (event, item, categoryIndex) => {
      event.stopPropagation()
      if (!toggleSubItems[item]) {
        setToggleSubItems({
          ...toggleSubItems,
          [categoryIndex]: {
            ...toggleSubItems[categoryIndex],
            [item]: {
              name: item,
              isShow: true,
              isSelected: true
            }
          }
        })
        // setSelectedUser(item)
        // getUserData()
      }
      if (toggleSubItems[categoryIndex]?.[item]?.isShow) {
        setToggleSubItems({
          ...toggleSubItems,
          [categoryIndex]: {
            ...toggleSubItems[categoryIndex],
            [item]: {
              name: item,
              isShow: false,
              isSelected: true
            }
          }
        })
        // delete toggleCheckbox[item]
        return
      }
      if (!toggleSubItems[categoryIndex]?.[item]?.isShow) {
        setToggleSubItems({
          ...toggleSubItems,
          [categoryIndex]: {
            ...toggleSubItems[categoryIndex],
            [item]: {
              name: item,
              isShow: true,
              isSelected: true
            }
          }
        })
      }
      // if (toggleSubItems[categoryIndex]?.[item]?.isShow && item !== toggleSubItems.name) {
      //   setActionTabs({
      //     isShow: true,
      //     name: item
      //   })
      //   setSelectedUser(item)
      //   // getUserData()
      // }
    },
    [toggleSubItems]
  )
  const handleToggleCheckbox = useCallback(
    (
      event,
      { categoryName, subName, categoryIndex, subIndex, costCenterIndex },
      costCenterName
    ) => {
      // event.stopPropagation()
      if (!toggleCheckbox[costCenterName]?.[categoryName]) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [costCenterName]: {
            ...toggleCheckbox[costCenterName],
            [categoryName]: {
              [subName]: {
                name: subName,
                isShow: true
              }
            }
          },
          TOTAL: {
            ...toggleCheckbox.TOTAL,
            [categoryName]: {
              ...toggleCheckbox.TOTAL?.[categoryName],
              [subName]: {
                ...toggleCheckbox.TOTAL?.[categoryName]?.[subName],
                name: subName,
                isShow: true
              }
            }
          }
        })
        return
      }
      if (toggleCheckbox[costCenterName]?.[categoryName]?.[subName]?.isShow) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [costCenterName]: {
            ...toggleCheckbox[costCenterName],
            [categoryName]: {
              ...toggleCheckbox[costCenterName]?.[categoryName],
              [subName]: {
                ...toggleCheckbox[costCenterName]?.[categoryName]?.[subName],
                isShow: false
              }
            }
          }
        })
        handleItemSubtract(
          { categoryName, categoryIndex, subIndex, costCenterIndex },
          costCenterName
        )
      }
      if (!toggleCheckbox[costCenterName]?.[categoryName]?.[subName]?.isShow) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [costCenterName]: {
            ...toggleCheckbox[costCenterName],
            [categoryName]: {
              ...toggleCheckbox[costCenterName]?.[categoryName],
              [subName]: {
                ...toggleCheckbox[costCenterName]?.[categoryName]?.[subName],
                isShow: true
              }
            }
          },
          TOTAL: {
            ...toggleCheckbox.TOTAL,
            [categoryName]: {
              ...toggleCheckbox.TOTAL?.[categoryName],
              [subName]: {
                ...toggleCheckbox.TOTAL?.[categoryName]?.[subName],
                isShow: true
              }
            }
          }
        })
      }
    },
    [handleItemSubtract, toggleCheckbox]
  )

  const handleGetUser = useCallback(
    (event, item: any, categoryIndex) => {
      if (toggleSubItems[item] && !toggleSubItems[item].isShow) {
        const toggle = {
          ...toggleSubItems,
          [categoryIndex]: {
            ...toggleSubItems[categoryIndex],
            [item]: {
              ...toggleSubItems[categoryIndex][item],
              isSelected: false
            }
          }
        }
        setToggleSubItems(toggle)
      }
      if (!toggleSubItems[item] || !toggleSubItems[item].isSelected) {
        handleToggleModal(event, item, categoryIndex)
      }
    },
    [handleToggleModal, toggleSubItems]
  )
  const handleGetAllTotal = useCallback(
    (month: any, itemParentName: string, categoryIndex) => {
      let total = '0'
      let totalAll = '0'
      let totalParentItem = '0'
      let totalParentAll = '0'
      let totalMonth = '0'
      let totalTotal = '0'
      let totalMonthCategory = '0'
      let totalTotalCategory = '0'
      const category = actionTabs.find(c => c.name === categoryIndex)
      category.initialData.forEach(item => {
        if (item.childCategories.length > 0) {
          item.childCategories.forEach((child: any) => {
            if (!isNaN(BRL(child.total).intValue)) {
              totalAll = BRL(child.total).add(totalAll).format()
            }
            if (!isNaN(BRL(child[month]?.value).intValue)) {
              total = BRL(child[month]?.value).add(total).format()
            }
          })
        }
      })
      category.initialData.forEach(item => {
        if (item.name === itemParentName) {
          if (item.childCategories.length > 0) {
            item.childCategories.forEach((child: any) => {
              if (!isNaN(BRL(child[month]?.value).intValue)) {
                totalParentItem = BRL(child[month]?.value)
                  .add(totalParentItem)
                  .format()
              }
              months.forEach(month1 => {
                if (!isNaN(BRL(child[month1]?.value).intValue)) {
                  totalParentAll = BRL(child[month1]?.value)
                    .add(totalParentAll)
                    .format()
                }
              })
            })
          }
        }
      })
      actionTabs.forEach(item => {
        if (item.id !== 0) {
          if (item.name === categoryIndex) {
            totalMonth = BRL(totalParentItem).add(totalMonth).format()
            totalTotal = BRL(totalParentAll).add(totalTotal).format()
            totalMonthCategory = BRL(total).add(totalMonthCategory).format()
            totalTotalCategory = BRL(totalAll).add(totalTotalCategory).format()
          } else {
            totalMonth = BRL(totalParent[item.name]?.[itemParentName]?.[month])
              .add(totalMonth)
              .format()
            totalTotal = BRL(totalParent[item.name]?.[itemParentName]?.total)
              .add(totalTotal)
              .format()
            totalMonthCategory = BRL(totalSum[item.name]?.[month])
              .add(totalMonthCategory)
              .format()
            totalTotalCategory = BRL(totalSum[item.name]?.total)
              .add(totalTotalCategory)
              .format()
          }
        }
      })
      setTotal((prev: any) => ({
        ...prev,
        [categoryIndex]: {
          ...prev[categoryIndex],
          [month]:
            total !== '0'
              ? total
              : BRL(totalSum[categoryIndex]?.[month]).format(),
          total:
            totalAll !== '0'
              ? totalAll
              : BRL(totalSum[categoryIndex].total).format()
        },
        TOTAL: {
          ...prev.TOTAL,
          [month]: totalMonthCategory,
          total: totalTotalCategory
        }
      }))
      setTotalParent((prev: any) => ({
        ...prev,
        [categoryIndex]: {
          ...prev[categoryIndex],
          [itemParentName]: {
            ...prev[categoryIndex]?.[itemParentName],
            total: totalParentAll,
            [month]:
              totalParentItem !== '0'
                ? totalParentItem
                : BRL(totalParent[month]).format()
          }
        },
        TOTAL: {
          ...prev.TOTAL,
          [itemParentName]: {
            ...prev.TOTAL?.[itemParentName],
            total: totalTotal,
            [month]: totalMonth
          }
        }
      }))
    },
    [actionTabs, months, totalParent, totalSum]
  )
  const handleGetTotal = useCallback(
    (
      event,
      { categoryIndex, itemName, itemIndex, itemChildIndex, input_id }
    ) => {
      if (!event) return
      const category = actionTabs.find(c => c.name === categoryIndex)
      const categoryTotal = actionTabs.find(c => c.id === 0)
      if (
        category.initialData[itemIndex].childCategories[itemChildIndex][
          input_id
        ]?.value
      ) {
        let total = '0'
        category.initialData[itemIndex].childCategories[itemChildIndex][
          input_id
        ] = {
          ...category.initialData[itemIndex].childCategories[itemChildIndex][
            input_id
          ],
          value: genericMaskWithTwoZeroWithPoint(event)
        }
        months.forEach(item => {
          if (
            category.initialData[itemIndex].childCategories[itemChildIndex][
              item
            ]?.value !== undefined
          ) {
            total = BRL(
              category.initialData[itemIndex].childCategories[itemChildIndex][
                item
              ].value
            )
              .add(total)
              .format()
          }
        })
        category.initialData[itemIndex].childCategories[itemChildIndex].total =
          total

        let totalCategory = '0'
        let totalMonth = '0'
        actionTabs.forEach(tab => {
          if (tab.id !== 0) {
            totalCategory = BRL(
              tab.initialData[itemIndex].childCategories[itemChildIndex][
                input_id
              ]?.value
            )
              .add(totalCategory)
              .format()
            totalMonth = BRL(
              tab.initialData[itemIndex].childCategories[itemChildIndex]?.total
            )
              .add(totalMonth)
              .format()
          }
        })
        categoryTotal.initialData[itemIndex].childCategories[itemChildIndex][
          input_id
        ] = {
          ...categoryTotal.initialData[itemIndex].childCategories[
            itemChildIndex
          ][input_id],
          value: totalCategory
        }
        categoryTotal.initialData[itemIndex].childCategories[itemChildIndex] = {
          ...categoryTotal.initialData[itemIndex].childCategories[
            itemChildIndex
          ],
          total: totalMonth
        }

        handleGetAllTotal(input_id, itemName, categoryIndex)

        total = '0'
        return
      }
      category.initialData[itemIndex].childCategories[itemChildIndex][
        input_id
      ] = {
        ...category.initialData[itemIndex].childCategories[itemChildIndex][
          input_id
        ],
        value: genericMaskWithTwoZeroWithPoint(event)
      }
      category.initialData[itemIndex].childCategories[itemChildIndex].total =
        category.initialData[itemIndex].childCategories[itemChildIndex].total
          ? BRL(genericMaskWithTwoZeroWithPoint(event))
              .add(
                genericMaskWithTwoZeroWithPoint(
                  category.initialData[itemIndex].childCategories[
                    itemChildIndex
                  ].total
                )
              )
              .format()
          : genericMaskWithTwoZeroWithPoint(event)
      handleGetAllTotal(input_id, itemName, categoryIndex)
    },
    [handleGetAllTotal, months, actionTabs]
  )
  const copyAll = useCallback(
    (
      event,
      { categoryIndex, itemName, itemIndex, itemChildIndex, input_id }
    ) => {
      if (!event) return
      const category = actionTabs.find(c => c.name === categoryIndex)
      const categoryTotal = actionTabs.find(c => c.id === 0)
      let current = false
      months.forEach(item => {
        if (item === input_id) {
          current = true
        }
        if (current) {
          let total = '0'
          category.initialData[itemIndex].childCategories[itemChildIndex][
            item
          ] = {
            ...category.initialData[itemIndex].childCategories[itemChildIndex][
              item
            ],
            value: genericMaskWithTwoZeroWithPoint(event)
          }
          months.forEach(item => {
            if (
              category.initialData[itemIndex].childCategories[itemChildIndex][
                item
              ]?.value !== undefined
            ) {
              total = BRL(
                category.initialData[itemIndex].childCategories[itemChildIndex][
                  item
                ].value
              )
                .add(total)
                .format()
            }
          })
          category.initialData[itemIndex].childCategories[
            itemChildIndex
          ].total = total

          let totalCategory = '0'
          let totalMonth = '0'
          actionTabs.forEach(tab => {
            if (tab.id !== 0) {
              totalCategory = BRL(
                tab.initialData[itemIndex].childCategories[itemChildIndex][item]
                  ?.value
              )
                .add(totalCategory)
                .format()
              totalMonth = BRL(
                tab.initialData[itemIndex].childCategories[itemChildIndex]
                  ?.total
              )
                .add(totalMonth)
                .format()
            }
          })
          categoryTotal.initialData[itemIndex].childCategories[itemChildIndex][
            item
          ] = {
            ...categoryTotal.initialData[itemIndex].childCategories[
              itemChildIndex
            ][item],
            value: totalCategory
          }
          categoryTotal.initialData[itemIndex].childCategories[itemChildIndex] =
            {
              ...categoryTotal.initialData[itemIndex].childCategories[
                itemChildIndex
              ],
              total: totalMonth
            }

          handleGetAllTotal(item, itemName, categoryIndex)

          total = '0'
        }
      })
    },
    [handleGetAllTotal, months, actionTabs]
  )

  const handleOnClickCopyAll = () => {
    setAlertCopyAll(false)
    handleGetTotal(currentCopy.item, currentCopy.item2)
  }

  const handlerOnClickButtonConfirmCopyAll = async () => {
    setAlertCopyAll(false)
    copyAll(currentCopy.item, currentCopy.item2)
  }

  const loadOne = useCallback(
    async (actionsTabs: any) => {
      const monthsCodes = [
        ['jan', '01'],
        ['fev', '02'],
        ['mar', '03'],
        ['abr', '04'],
        ['mai', '05'],
        ['jun', '06'],
        ['jul', '07'],
        ['ago', '08'],
        ['set', '09'],
        ['out', '10'],
        ['nov', '11'],
        ['dez', '12']
      ]
      const responseViewExpenseBudget = await api.get(
        'financial/expenseBudget/viewData/',
        {
          params: {
            company_id: company,
            year
          }
        }
      )
      if (responseViewExpenseBudget.data.hidden_costs) {
        setHiddenCost(responseViewExpenseBudget.data.hidden_costs || [])
        localStorage.setItem(
          '@Multfluxo:hidden-cost',
          JSON.stringify(responseViewExpenseBudget.data.hidden_costs)
        )
      }
      const { data } = await api.get('financial/expenseBudget/view/', {
        params: {
          company_id: company,
          year
        }
      })

      if (data) {
        setUpdate(true)
        history.replace(
          `/financial/expenseBudget/update?company_id=${company}&year=${year}&edit=1`
        )
        let total: any = {}
        let totalParent: any = {}
        let toggleCheckbox: any = {}
        for (const e of data) {
          const value = genericMaskWithTwoZeroWithPoint(e.expected_value)
          const costCenter = actionsTabs.find(
            (a: { id: any }) => a.id === e.cost_center_id
          )
          const costCenterTotal = actionsTabs.find(
            (a: { id: any }) => a.id === 0
          )
          if (!costCenter) continue
          if (!costCenterTotal) continue
          const category = costCenter.initialData.find(
            (c: { id: number }) => c.id === e.category?.parent_id
          )
          const categoryTotal = costCenterTotal.initialData.find(
            (c: { id: number }) => c.id === e.category?.parent_id
          )
          if (!category) continue
          if (!categoryTotal) continue
          const sub = category.childCategories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          if (!sub) continue
          const subTotal = categoryTotal.childCategories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          if (!subTotal) continue
          const code = monthsCodes.find(mC => mC[1] === e.month)
          sub[code[0]] = {
            id: e.id,
            value: value
          }
          sub.total = BRL(value).add(sub.total).format()
          subTotal[code[0]] = {
            value: BRL(subTotal[code[0]]?.value).add(value).format()
          }
          subTotal.total = BRL(value).add(subTotal.total).format()
          total = {
            ...total,
            [costCenter.name]: {
              ...total[costCenter.name],
              [code[0]]: BRL(total[costCenter.name]?.[code[0]])
                .add(value)
                .format(),
              total: BRL(total[costCenter.name]?.total).add(value).format()
            },
            TOTAL: {
              ...total.TOTAL,
              [code[0]]: BRL(total.TOTAL?.[code[0]]).add(value).format(),
              total: BRL(total.TOTAL?.total).add(value).format()
            }
          }
          totalParent = {
            ...totalParent,
            [costCenter.name]: {
              ...totalParent[costCenter.name],
              [category.name]: {
                ...totalParent[costCenter.name]?.[category.name],
                total: BRL(totalParent[costCenter.name]?.[category.name]?.total)
                  .add(value)
                  .format(),
                [code[0]]: BRL(
                  totalParent[costCenter.name]?.[category.name]?.[code[0]]
                )
                  .add(value)
                  .format()
              }
            },
            TOTAL: {
              ...totalParent.TOTAL,
              [category.name]: {
                ...totalParent.TOTAL?.[category.name],
                total: BRL(totalParent.TOTAL?.[category.name]?.total)
                  .add(value)
                  .format(),
                [code[0]]: BRL(totalParent.TOTAL?.[category.name]?.[code[0]])
                  .add(value)
                  .format()
              }
            }
          }
          toggleCheckbox = {
            ...toggleCheckbox,
            [costCenter.name]: {
              ...toggleCheckbox[costCenter.name],
              [category.name]: {
                ...toggleCheckbox[costCenter.name]?.[category.name],
                [sub.name]: {
                  ...toggleCheckbox[costCenter.name]?.[category.name]?.[
                    sub.name
                  ],
                  name: sub.name,
                  isShow: true
                },
                parent: category.name
              }
            },
            TOTAL: {
              ...toggleCheckbox.TOTAL,
              [category.name]: {
                ...toggleCheckbox.TOTAL?.[category.name],
                [sub.name]: {
                  ...toggleCheckbox.TOTAL?.[category.name]?.[sub.name],
                  name: sub.name,
                  isShow: true
                },
                parent: category.name
              }
            }
          }
          /* setToggleSubItems((prev: { [x: string]: any }) => ({
            ...prev,
            [costCenter.name]: {
              ...prev[costCenter.name],
              [category.name]: {
                name: category.name,
                isShow: true,
                isSelected: true
              }
            }
          }))
          */
        }
        setTotal(total)
        setTotalParent(totalParent)
        setToggleCheckbox(toggleCheckbox)
      } else {
        setUpdate(false)
        history.push('/financial/expenseBudget/create')
      }
      disableLoading()
    },
    [company, disableLoading, history, setUpdate, year]
  )

  const loadCategories = useCallback(async () => {
    const response = await api.get('financial/categories/list')
    const costCenters = await api.get<CostCenter[]>('financial/costCenters')
    const actionsTab = costCenters.data.map(action => {
      const copy = JSON.stringify(response.data)
      const copy2 = JSON.parse(copy)
      return {
        id: action.id,
        name: action.name,
        active: false,
        initialData: copy2
      }
    })
    actionsTab[actionsTab.length] = {
      id: 0,
      name: 'TOTAL',
      active: false,
      initialData: JSON.parse(JSON.stringify(response.data))
    }
    if (actionsTab.length > 0) actionsTab[0].active = true
    setActionTabs(actionsTab)
    if (company && year?.length === 4) {
      setTotal({})
      setTotalParent({})
      setToggleCheckbox({})
      setToggleSubItems({})

      await loadOne(actionsTab)
    }
    if (!company && !year) {
      disableLoading()
    }
  }, [company, disableLoading, loadOne, year])

  const loadCompanies = useCallback(async () => {
    const response = await api.get<Company[]>('financial/configurations')
    setCompanies(response.data)
  }, [])

  useEffect(() => {
    loadCompanies()
  }, [loadCompanies])

  useEffect(() => {
    if (company) {
      setFormError(prev => ({
        ...prev,
        company: false
      }))
    }
    if (year.length === 4) {
      setFormError(prev => ({
        ...prev,
        year: {
          ...prev.year,
          error: false
        }
      }))
    }
    if (company && year?.length === 4) {
      ;(async () => {
        const { data } = await api.get('/financial/expenseBudget/view/', {
          params: {
            company_id: company,
            year
          }
        })
        if (data) loadCategories()
      })()
    } else {
      disableLoading()
    }
  }, [company, disableLoading, loadCategories, year])

  useEffect(() => {
    activeLoading()
    loadCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading])

  useEffect(() => {
    if (location.get('company_id') && location.get('year')) {
      setCompany(location.get('company_id'))
      setYear(location.get('year'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const activeActionTab = (name: string, index: number) => {
    handleSwitchPeriod(index, name)
    setActionTabs(prev => {
      const newTabs = prev.map(newTab => ({
        ...newTab,
        active: newTab.name === name
      }))
      return newTabs
    })
  }

  function validateCompanyYear() {
    if (!company || year.length !== 4) {
      addToast({
        type: 'error',
        title: 'Campos incorretos',
        description: 'É necessário informar a empresa e o ano.'
      })
      if (!company) {
        setFormError(prev => ({
          ...prev,
          company: true
        }))
      }
      if (year.length < 4) {
        setFormError(prev => ({
          ...prev,
          year: {
            ...prev.year,
            error: true
          }
        }))
      }
      return false
    }
    return true
  }

  async function handleSaveButton() {
    const validate = validateCompanyYear()
    if (!validate) return

    activeLoading()
    const monthsCodes = [
      ['jan', '01'],
      ['fev', '02'],
      ['mar', '03'],
      ['abr', '04'],
      ['mai', '05'],
      ['jun', '06'],
      ['jul', '07'],
      ['ago', '08'],
      ['set', '09'],
      ['out', '10'],
      ['nov', '11'],
      ['dez', '12']
    ]
    const final: any = {
      company_id: company,
      year,
      categories: [],
      deletes: []
    }
    actionTabs.forEach(costCenter => {
      if (costCenter.id) {
        costCenter.initialData.forEach(category => {
          category.childCategories.forEach((child: any) => {
            Object.keys(child).forEach(a => {
              if (months.includes(a)) {
                const code = monthsCodes.find(mC => mC[0] === a)
                if (child[a].value) {
                  const value = BRL(child[a].value).value
                  final.categories.push({
                    id: child[a].id,
                    cost_center_id: costCenter.id,
                    category_id: child.id,
                    month: code[1],
                    expected_value: Number(value)
                  })
                } else {
                  if (child[a].id) {
                    final.deletes.push(child[a].id)
                  }
                }
              }
            })
          })
        })
      }
    })
    if (update) {
      try {
        await api.put('/financial/expenseBudget/update', { ...final })
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        await loadCategories()
        setActionTabs(prev => {
          const newTabs = prev.map(newTab => ({
            ...newTab,
            active: newTab.name === selectedItem.name
          }))
          return newTabs
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    } else {
      final.deletes = undefined
      try {
        await api.post('/financial/expenseBudget/create', { ...final })
        history.push('/financial/expenseBudget')
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  function getLastYearValues(hasPercentage: boolean) {
    activeLoading()
    const validate = validateCompanyYear()
    if (!validate) {
      disableLoading()
      return
    }
    ;(async () => {
      const { data } = await api.get('/financial/expenseBudget/view/', {
        params: {
          company_id: company,
          year: String(Number(year) - 1)
        }
      })
      if (data) {
        const monthsCodes = [
          ['jan', '01'],
          ['fev', '02'],
          ['mar', '03'],
          ['abr', '04'],
          ['mai', '05'],
          ['jun', '06'],
          ['jul', '07'],
          ['ago', '08'],
          ['set', '09'],
          ['out', '10'],
          ['nov', '11'],
          ['dez', '12']
        ]
        let total: any = {}
        let totalParent: any = {}
        let toggleCheckbox: any = {}
        for (const e of data) {
          let value = genericMaskWithTwoZeroWithPoint(e.expected_value)
          if (hasPercentage) {
            const percentageValue = percentage.replace('%', '')
            if (!percentageValue) {
              disableLoading()
              return
            }
            const percentageFinal = BRL(
              genericMaskWithTwoZeroWithPoint(e.expected_value)
            ).multiply(BRL(percentageValue).divide(100))
            value = BRL(genericMaskWithTwoZeroWithPoint(e.expected_value))
              .add(percentageFinal)
              .format()
          }
          const costCenter = actionTabs.find(
            (a: { id: any }) => a.id === e.cost_center_id
          )
          if (!costCenter) continue
          const category = costCenter.initialData.find(
            (c: { id: number }) => c.id === e.category?.parent_id
          )
          if (!category) continue
          const sub = category.childCategories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          if (!sub) continue
          const code = monthsCodes.find(mC => mC[1] === e.month)
          sub[code[0]] = {
            value: value
          }
          sub.total = BRL(sub[code[0]].value).add(sub.total).format()
          total = {
            ...total,
            [costCenter.name]: {
              ...total[costCenter.name],
              [code[0]]: BRL(total[costCenter.name]?.[code[0]])
                .add(value)
                .format(),
              total: BRL(total[costCenter.name]?.total).add(value).format()
            },
            TOTAL: {
              ...total.TOTAL,
              [code[0]]: BRL(total.TOTAL?.[code[0]]).add(value).format(),
              total: BRL(total.TOTAL?.total).add(value).format()
            }
          }
          totalParent = {
            ...totalParent,
            [costCenter.name]: {
              ...totalParent[costCenter.name],
              [category.name]: {
                ...totalParent[costCenter.name]?.[category.name],
                total: BRL(totalParent[costCenter.name]?.[category.name]?.total)
                  .add(value)
                  .format(),
                [code[0]]: BRL(
                  totalParent[costCenter.name]?.[category.name]?.[code[0]]
                )
                  .add(value)
                  .format()
              }
            }
          }
          toggleCheckbox = {
            ...toggleCheckbox,
            [costCenter.name]: {
              ...toggleCheckbox[costCenter.name],
              [category.name]: {
                ...toggleCheckbox[costCenter.name]?.[category.name],
                [sub.name]: {
                  ...toggleCheckbox[costCenter.name]?.[category.name]?.[
                    sub.name
                  ],
                  name: sub.name,
                  isShow: true
                },
                parent: category.name
              }
            },
            TOTAL: {
              ...toggleCheckbox.TOTAL,
              [category.name]: {
                ...toggleCheckbox.TOTAL?.[category.name],
                [sub.name]: {
                  ...toggleCheckbox.TOTAL?.[category.name]?.[sub.name],
                  name: sub.name,
                  isShow: true
                },
                parent: category.name
              }
            }
          }
        }
        setTotal(total)
        setTotalParent(totalParent)
        setToggleCheckbox(toggleCheckbox)
      } else {
        addToast({
          type: 'error',
          title: 'Nenhum registro encontrado para o ano anterior'
        })
      }
    })()
    disableLoading()
  }

  return (
    <>
      <Container className={className}>
        <div className="row">
          <Form>
            <div className="col-md-3">
              <Select
                style={{
                  borderColor: formError.company ? 'red' : '',
                  color: formError.company ? 'red' : ''
                }}
                className="form-control"
                label="Empresa"
                name="company"
                options={companies.map(({ id, name }) => ({
                  name,
                  value: id
                }))}
                blank
                value={company}
                onChange={e => setCompany(e.target.value)}
                disabled={update}
              />
            </div>
            <div className="col-md-3">
              <Input
                hasError={formError.year}
                type="text"
                onKeyPress={event => {
                  const regex = /^[0-9.]+$/
                  if (!regex.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                maxLength={4}
                className="form-control"
                label="Ano"
                name="year"
                value={year}
                onChange={e => setYear(e.target.value)}
                readOnly={update}
              />
            </div>
          </Form>
        </div>
        <div className="portlet light ">
          <div className="portlet-title">
            <div className="caption caption-md">
              <i className="icon-bar-chart font-dark hide"></i>
              <span className="maintitle">{maintitle}</span>
            </div>
          </div>

          <div className="portlet-body">
            {actionTabs.length > 0 && (
              <div className="row">
                <table className="table table-light custom">
                  <thead>
                    <tr className="TableHeaderActions">
                      {actionTabs
                        .filter(
                          item =>
                            !hiddenCost?.find(hiddenCostItem => {
                              return hiddenCostItem === item.id
                            })
                        )
                        .map(({ name, active }, index) => (
                          <th
                            key={name}
                            onClick={() => activeActionTab(name, index)}
                            className="TableHeadActions"
                          >
                            <div
                              className={`btn btn-transparent btn-no-border btn-outline cost-center-btn btn-sm ${
                                active && 'active'
                              }`}
                            >
                              {name}
                            </div>
                            <CostCenterTotal>
                              {!totalSum[name]?.total ||
                              totalSum[name]?.total === '0,00'
                                ? ''
                                : 'R$ ' + totalSum[name]?.total}
                            </CostCenterTotal>
                          </th>
                        ))}
                    </tr>
                  </thead>
                </table>
              </div>
            )}
            <div
              className="portlet light custom"
              style={{ marginTop: '50px', marginBottom: '0px' }}
            >
              <div className="portlet-title" style={{ marginBottom: '0px' }}>
                <div className="caption caption-md">
                  <i className="icon-bar-chart font-dark hide"></i>
                  <span className="subtitle">{subtitle}</span>
                </div>
              </div>
            </div>
            {!update && (
              <ContainerActionsData>
                <WrapperActionsData>
                  <button onClick={() => getLastYearValues(false)}>
                    Dados Ano Anterior
                  </button>
                  <div className="WrapperPercentage">
                    <Input
                      name="fev"
                      placeholder="%  0,00"
                      value={percentage}
                      onChange={event =>
                        setPercentage(
                          '%  ' +
                            genericMaskWithTwoZeroWithPoint(event.target.value)
                        )
                      }
                    />
                  </div>
                  <button onClick={() => getLastYearValues(true)}>
                    Calcular
                  </button>
                </WrapperActionsData>
              </ContainerActionsData>
            )}
            {actionTabs.map(
              ({ name, active, initialData }, costCenterIndex) =>
                active && (
                  <div key={name}>
                    <table className="table table-light">
                      <thead className="tableHead">
                        <tr className="table-head-tr">
                          {tableHead.map((item, index) =>
                            index === 0 ? (
                              <th
                                key={`${item}${className}`}
                                className={
                                  index !== 0
                                    ? stylesChild.th?.classname
                                    : `${stylesChild.th?.classname} firstTableHead`
                                }
                              >
                                <b>{selectedItem.name}</b>
                              </th>
                            ) : (
                              <th
                                key={`${item}${className}`}
                                className={
                                  index !== 0
                                    ? stylesChild.th?.classname
                                    : `${stylesChild.th?.classname} firstTableHead`
                                }
                              >
                                <b>{item}</b>
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {initialData.length > 0 &&
                          initialData.map((item, indexParent) => (
                            <React.Fragment key={item.name}>
                              <tr
                                key={item.name}
                                className={
                                  isHighlight &&
                                  toggleSubItems[name]?.[item.name]?.isShow
                                    ? 'activeCustom'
                                    : 'title'
                                }
                                onClick={event =>
                                  handleGetUser(event, item.name, name)
                                }
                              >
                                <td>
                                  <div className="LastChildSpace">
                                    {item.name}
                                    {item.name ===
                                    toggleSubItems[name]?.[item.name]?.name ? (
                                      !toggleSubItems[name]?.[item.name]
                                        ?.isShow ? (
                                        <FaChevronDown
                                          size={13}
                                          onClick={event =>
                                            handleToggleModal(
                                              event,
                                              item.name,
                                              name
                                            )
                                          }
                                        />
                                      ) : (
                                        <FaChevronUp
                                          size={13}
                                          onClick={event =>
                                            handleToggleModal(
                                              event,
                                              item.name,
                                              name
                                            )
                                          }
                                        />
                                      )
                                    ) : (
                                      <FaChevronDown
                                        size={13}
                                        onClick={event =>
                                          handleToggleModal(
                                            event,
                                            item.name,
                                            name
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                                {months.map(month => (
                                  <td
                                    className="TableData total-category"
                                    key={`${item.name}${month}`}
                                  >
                                    {totalParent[name]?.[item.name] &&
                                      totalParent[name]?.[item.name][month] !==
                                        '0,00' &&
                                      totalParent[name]?.[item.name][month]}
                                  </td>
                                ))}
                                <td className="TableData total-category">
                                  {totalParent[name]?.[item.name]?.total}
                                </td>
                              </tr>
                              {toggleSubItems[name]?.[item.name]?.isShow &&
                                item.name ===
                                  toggleSubItems[name]?.[item.name].name &&
                                item.childCategories.map(
                                  (itemChild: any, index: number) => (
                                    <tr key={index} className="trChild">
                                      <td className="firstChild">
                                        {name !== 'TOTAL' && (
                                          <input
                                            type="checkbox"
                                            name={`check${
                                              name + item.name + itemChild.name
                                            }`}
                                            id={`check${
                                              name + item.name + itemChild.name
                                            }`}
                                            defaultChecked={
                                              toggleCheckbox[name]?.[
                                                item.name
                                              ] &&
                                              toggleCheckbox[name]?.[item.name][
                                                itemChild.name
                                              ] &&
                                              toggleCheckbox[name]?.[item.name][
                                                itemChild.name
                                              ].isShow
                                            }
                                            onClick={event =>
                                              handleToggleCheckbox(
                                                event,
                                                {
                                                  categoryName: item.name,
                                                  subName: itemChild.name,
                                                  categoryIndex: indexParent,
                                                  subIndex: index,
                                                  costCenterIndex
                                                },
                                                name
                                              )
                                            }
                                          />
                                        )}
                                        <label
                                          htmlFor={`check${
                                            name + item.name + itemChild.name
                                          }`}
                                        >
                                          {' '}
                                          {itemChild.name}
                                        </label>
                                      </td>
                                      {toggleCheckbox[name]?.[item.name]?.[
                                        itemChild.name
                                      ]?.isShow ? (
                                        <>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.jan?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.jan
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'jan'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="jan"
                                                  value={
                                                    itemChild.jan?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'jan'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.fev?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.fev
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'fev'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="fev"
                                                  value={
                                                    itemChild.fev?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'fev'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.mar?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.mar
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'mar'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="mar"
                                                  value={
                                                    itemChild.mar?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'mar'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.abr?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.abr
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'abr'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="abr"
                                                  value={
                                                    itemChild.abr?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'abr'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.mai?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.mai
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'mai'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="mai"
                                                  value={
                                                    itemChild.mai?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'mai'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.jun?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.jun
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'jun'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="jun"
                                                  value={
                                                    itemChild.jun?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'jun'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.jul?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.jul
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'jul'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="jul"
                                                  value={
                                                    itemChild.jul?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'jul'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.ago?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.ago
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'ago'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="ago"
                                                  value={
                                                    itemChild.ago?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'ago'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.set?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.set
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'set'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="set"
                                                  value={
                                                    itemChild.set?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'set'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.out?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.out
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'out'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="out"
                                                  value={
                                                    itemChild.out?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'out'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.nov?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <FontAwesomeIcon
                                                  icon={faCopy}
                                                  onClick={() => {
                                                    setAlertCopyAll(true)
                                                    setCurrentCopy({
                                                      item: itemChild.nov
                                                        ?.value,
                                                      item2: {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'nov'
                                                      }
                                                    })
                                                  }}
                                                />
                                                <Input
                                                  name="nov"
                                                  value={
                                                    itemChild.nov?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'nov'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData">
                                            {name === 'TOTAL' ? (
                                              <div>
                                                {itemChild.dez?.value || ''}
                                              </div>
                                            ) : (
                                              <div className="Border">
                                                <Input
                                                  name="dez"
                                                  value={
                                                    itemChild.dez?.value || ''
                                                  }
                                                  onChange={event =>
                                                    handleGetTotal(
                                                      event.target.value,
                                                      {
                                                        categoryIndex: name,
                                                        itemName: item.name,
                                                        itemIndex: indexParent,
                                                        itemChildIndex: index,
                                                        input_id: 'dez'
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td className="TableData total-category">
                                            {itemChild.total === '0,00'
                                              ? 0
                                              : itemChild.total}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                          <td className="TableData"></td>
                                        </>
                                      )}
                                    </tr>
                                  )
                                )}
                            </React.Fragment>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="TableRowTotal">
                          <td>SOMA TOTAL</td>
                          <td className="BoldBlack">
                            {totalSum[name]?.jan === '0,00'
                              ? 0
                              : totalSum[name]?.jan}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.fev === '0,00'
                              ? 0
                              : totalSum[name]?.fev}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.mar === '0,00'
                              ? 0
                              : totalSum[name]?.mar}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.abr === '0,00'
                              ? 0
                              : totalSum[name]?.abr}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.mai === '0,00'
                              ? 0
                              : totalSum[name]?.mai}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.jun === '0,00'
                              ? 0
                              : totalSum[name]?.jun}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.jul === '0,00'
                              ? 0
                              : totalSum[name]?.jul}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.ago === '0,00'
                              ? 0
                              : totalSum[name]?.ago}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.set === '0,00'
                              ? 0
                              : totalSum[name]?.set}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.out === '0,00'
                              ? 0
                              : totalSum[name]?.out}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.nov === '0,00'
                              ? 0
                              : totalSum[name]?.nov}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.dez === '0,00'
                              ? 0
                              : totalSum[name]?.dez}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[name]?.total === '0,00'
                              ? 0
                              : totalSum[name]?.total}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="ContainerButton">
          <Button
            onClick={handleSaveButton}
            type="submit"
            className="btn dark btn-sm sbold uppercase"
          >
            Salvar
          </Button>
        </div>
      </Container>
      <Alert
        message={'Deseja copiar para todos os meses?'}
        onClickCancellButton={handleOnClickCopyAll}
        onClickConfirmButton={handlerOnClickButtonConfirmCopyAll}
        isActive={alertCopyAll}
        title="Copiar"
        textConfim="SIM"
        textCancel="NÃO"
      />
    </>
  )
}

export { TablePortletWithInputs }
