import React from 'react'
import ReactTooltip from 'react-tooltip'

import { Container } from './style'
type TypeTooltipComponent = {
  message: string
  label: string
  bold?: boolean
}

export const TooltipText = ({
  message,
  label,
  bold
}: TypeTooltipComponent): JSX.Element => {
  return (
    <Container bold={bold} data-tip={message} data-class="my-tooltip">
      <label htmlFor="form">{label}</label>
      <div>
        <ReactTooltip multiline={true} place="top" type="dark" effect="float" />
      </div>
    </Container>
  )
}
