import React, { useState } from 'react'
import api from '../../../../../services/api'
import {
  InputMask,
  Select,
  Textarea
} from '../../../../../components/FormProvider'
import { Date } from '../../../../../components/FormProvider/date'
import Button from '../../../../../components/Button'
import moment from 'moment'
import { ModalProps, Appointment } from '../../Appointments'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiUpdateAppointment } from '../../domain/api'
import { DATE_MASK_ISO_WITHOUT_TIME } from '../../../../../common/constants'
import { timeMask } from '../../../../../utlis/mask'
import { Alert } from '../../../../../components/Alert'
import { useFormContext } from 'react-hook-form'

type TypesFormProps = {
  typeForm: 'create' | 'update'
  initialValues?: Appointment
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>
  getAppointments: () => void
}

export const FormFields = ({
  typeForm,
  initialValues,
  setModal,
  getAppointments
}: TypesFormProps): JSX.Element => {
  const { getValues } = useFormContext()

  const [date, setDate] = useState<Date>()

  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const [alertChildAppointment, setAlertChildAppointment] = useState<{
    open: boolean
    id: number
  }>({
    id: 0,
    open: false
  })

  const handlerCancellAlertChildAppointment = async () => {
    const values = getValues()
    const appointmentData = {
      id: initialValues.id,
      client_id: initialValues.client.id,
      time: values.time,
      action: values.action,
      description: values.description,
      appointment_result: values.appointment_result,
      date: moment(values.date).format(DATE_MASK_ISO_WITHOUT_TIME)
    }
    activeLoading()
    try {
      await api.put(apiUpdateAppointment(String(appointmentData.id)), {
        ...appointmentData,
        id: undefined
      })
      disableLoading()
      addToast({
        type: 'success',
        title: 'Agedamento atualizado com sucesso'
      })
      getAppointments()
      setModal({
        open: false
      })
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Erro ao atualizar agendamento'
      })
    }
  }

  const handlerConfirmAlertChildAppointment = async (id: number) => {
    await handlerCancellAlertChildAppointment()
    setAlertChildAppointment({
      open: false,
      id: 0
    })
    setModal({
      open: true,
      type: 'create',
      initialValues: {
        date: moment().format('YYYY-MM-DD'),
        parent_id: initialValues.parent_id || id,
        client: {
          ...initialValues.client
        }
      }
    })
  }

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <Date
            label="Data"
            name="date"
            className="form-control"
            onChange={date => setDate(date)}
            selected={date}
            disabled={
              initialValues?.date &&
              moment(initialValues.date)
                .endOf('D')
                .isBefore(moment().startOf('D'))
            }
            minDate={moment().toDate()}
          />
        </div>
        <div className="col-md-3">
          <InputMask
            label="Hora"
            name="time"
            mask={timeMask}
            className="form-control"
            maxLength={5}
            disabled={
              initialValues?.date &&
              moment(initialValues.date)
                .endOf('D')
                .isBefore(moment().startOf('D'))
            }
          />
        </div>
      </div>
      <Select
        label="Ação"
        name="action"
        className="form-control"
        options={[
          { name: 'Email', value: 'email' },
          { name: 'Ligação', value: 'call' },
          { name: 'Mensagem', value: 'message' },
          { name: 'Visita', value: 'visit' }
        ]}
        blank
        defaultValue={''}
        rules={{ required: true }}
      />
      <Textarea
        label="Descrição"
        name="description"
        className="form-control"
        rows={3}
        rules={{ required: true }}
      />
      {typeForm === 'update' &&
        initialValues &&
        moment(initialValues.date).isSameOrBefore(moment()) && (
          <Textarea
            label="Resultado do agendamento"
            name="appointment_result"
            className="form-control"
            rows={3}
          />
        )}
      <div className="form-actions right">
        {typeForm === 'update' &&
        initialValues &&
        moment(initialValues.date).isSameOrBefore(moment()) ? (
          <Button
            type="button"
            className="btn dark btn-sm sbold uppercase"
            onClick={() => {
              if (typeForm === 'update') {
                setAlertChildAppointment({
                  open: true,
                  id: initialValues.id
                })
              }
            }}
          >
            Salvar
          </Button>
        ) : (
          <Button type="submit" className="btn dark btn-sm sbold uppercase">
            Salvar
          </Button>
        )}
      </div>
      <Alert
        message={'Deseja criar um novo agendamento?'}
        onClickCancellButton={handlerCancellAlertChildAppointment}
        onClickConfirmButton={() => {
          handlerConfirmAlertChildAppointment(alertChildAppointment.id)
        }}
        isActive={alertChildAppointment.open}
        textCancel="NÃO (APENAS SALVAR)"
        textConfim="SIM"
      />
    </>
  )
}
