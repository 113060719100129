import { ColumnCustomSummary } from './ColumnCustomSummary'
import { ContentCustomSummary } from './ContentCustomSummary'
import { HeaderCustomSummary } from './HeaderCustomSummary'
import { RootCustomSummary } from './RootCustomSummary'
import { RowCustomSummary } from './RowCustomSummary'

export const CustomSummary = {
  Root: RootCustomSummary,
  Content: ContentCustomSummary,
  Header: HeaderCustomSummary,
  Row: RowCustomSummary,
  Column: ColumnCustomSummary
}
