import React from 'react'
import View from '../../pages/Commercial/Orders/Deliver'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const OrdersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/orders/deliver/:id" exact component={View} />
  </CustomSwitch>
)
