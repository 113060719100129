import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
  float: right;

  input {
    cursor: pointer;
  }
`
