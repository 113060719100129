export const apiCreate = (): string =>
  '/commercial/clientsEconomicGroups/create/'
export const apiList = (id?: string): string =>
  !id
    ? '/commercial/clientsEconomicGroups/'
    : `/commercial/clientsEconomicGroups/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/clientsEconomicGroups/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/clientsEconomicGroups/delete/${id}`
