import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import '../../../../assets/global/plugins/datatables/datatables.min.css'
import { LinkContainer, ContainerSwitch, Container } from './style'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { useLoading } from '../../../../hooks/loading'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactSwitch from 'react-switch'
import { apiUpdate } from '../../domain/api'
import { useAuth } from '../../../../hooks/auth'
import Tooltip from '../../../../components/Tooltip'
import { TooltipComponent } from '../../../../components/TooltipComponent'

interface Action {
  name: string
  icon?: IconDefinition
  spanIcon?: string
  title: string
  link?: string
  onClick?: (params: any) => void
  linkTo?: (params: any) => string
}

interface Header {
  name: string
  field: string
  sortable: boolean
  hasChildren?: boolean
}

interface SearchParameters {
  [key: string]: string
}

interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  entity: string
  source: string
  headers?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  onlyParent?: boolean
  onlyView?: boolean
  parentId?: string
  viewField?: { source: string; field: string }
  entityId?: string
  searchParameters?: SearchParameters[]
  childrens?: {
    headerField: string
    component: JSX.Element
  }
  format: {
    orderBy: string
  }
}

const DataTable = ({
  onActions,
  entity,
  source,
  notHasChildren,
  viewField,
  onlyView,
  onlyParent,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  actions,
  format,
  parentId,
  entityId,
  searchParameters
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>([])
  const [filterItems, setFilterItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(200)
  const { isUpdated, updateDataTable } = useUpdateDataTable()
  const { user } = useAuth()

  const { activeLoading, disableLoading } = useLoading()

  const handlerOnClickButtonList = (currentValue: any) => {
    if (viewField) {
      return `/${viewField.source}/view/${currentValue[viewField.field]}?tab=${
        currentValue.id
      }`
    }

    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      return `/${source}/view/${currentValue.id}`
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    return `/${source}/update/${currentValue.id}`
  }

  const handlerOnClickButtonRemove = (currentValue: any) => {
    if (onActions?.onClickButtonRemove) {
      onActions.onClickButtonRemove(currentValue)
    }
  }

  const loadParams = useCallback(() => {
    const params = {
      entity,
      source,
      keyword: '',
      page: currentPage,
      perPage: ItemsPerPage,
      orderByField: '',
      searchParameters,
      onlyParent,
      orderBy: format.orderBy,
      parentId,
      entityId
    }

    if (!parentId) Object.assign(params, { parentId: '' })
    if (!entityId) Object.assign(params, { entityId: '' })

    return params
  }, [
    ItemsPerPage,
    currentPage,
    entity,
    entityId,
    format.orderBy,
    onlyParent,
    parentId,
    searchParameters,
    source
  ])

  useEffect(() => {
    ;(async () => {
      try {
        activeLoading()
        const params = loadParams()
        const response = await api.get('dataTable', { params })

        setItems(response.data.items)
        setFilterItems(response.data.items)
        setTotalItems(response.data.totalItens)
        setCurrentPage(response.data.page)
        disableLoading()
      } catch (error) {
        disableLoading()
        console.error(error)
      }
    })()
  }, [loadParams, isUpdated, activeLoading, disableLoading])

  const firstItem =
    totalItems === 0 ? totalItems : ItemsPerPage * (currentPage - 1) + 1

  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      if (notHasChildren) {
        sum = items.reduce((sum, value) => {
          if (!value.parent_id) {
            return sum + 1
          }
          return sum
        }, 0)
      } else {
        sum = items.length
      }
    }
    return sum
  }

  const getSearched = async (value: string) => {
    const params = loadParams()
    const response = await api.get('dataTable', {
      params: {
        ...params,
        keyword: { [params.orderBy]: value }
      }
    })
    setItems(response.data.items)
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    if (value.length === 0) {
      setItems(filterItems)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => getSearched(value), 500)
  }

  const handleUpdate = useCallback(
    async (item: any) => {
      await api.put(apiUpdate(item.id), {
        entity: item.entity,
        entity_id: item.entity_id,
        description: item.description,
        verified: !item.verified,
        verified_by: user.name,
        verified_by_id: user.id
      })
      updateDataTable()
    },
    [updateDataTable, user.id, user.name]
  )

  return (
    <Container className="dataTables_wrapper no-footer">
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_length">
            <label>
              <select
                onChange={e => setItemsPerPage(Number(e.target.value))}
                className="form-control input-sm input-xsmall input-inline"
                defaultValue="200"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              resultados por página
            </label>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_filter">
            <label>
              Pesquisar
              <Search onSearch={value => onSearchItem(value)} />
            </label>
          </div>
        </div>
      </div>
      <div className="table-scrollable">
        <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
          <TableHeader
            headers={headers}
            onSorting={(field, order) => {
              const itemSorted = items.sort((a, b) => {
                const fields = field.split('.')
                let currentFieldA
                let currentFieldB
                if (fields.length === 1) {
                  currentFieldA = a[fields[0]]
                  currentFieldB = b[fields[0]]
                }
                if (fields.length === 2) {
                  currentFieldA = a[fields[0]]?.[fields[1]]
                  currentFieldB = b[fields[0]]?.[fields[1]]
                }
                if (fields.length === 3) {
                  currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                  currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                }
                return currentFieldA > currentFieldB && order === 'ASC' ? 1 : -1
              })
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                const types = [
                  { key: 'locacao', label: 'LOCAÇÃO' },
                  { key: 'L', label: 'LOCAÇÃO' },
                  { key: 'materia-prima', label: 'MATERIA PRIMA' },
                  { key: 'revenda', label: 'REVENDA' },
                  { key: 'semi-acabado', label: 'SEMI ACABADO' },
                  { key: 'consumo', label: 'USO E CONSUMO' },
                  { key: 'CONSUMO', label: 'USO E CONSUMO' },
                  { key: 'venda', label: 'VENDA' },
                  { key: 'V', label: 'VENDA' }
                ]
                const typeProduct = types.find(
                  t => t.key === item.product?.type
                )
                if (typeProduct) {
                  item.product.type = typeProduct.label
                }
                const type = types.find(t => t.key === item.type)
                if (type) {
                  item.type = type.label
                }
                return (
                  <tr key={item.id}>
                    {headers.map(
                      header =>
                        (header?.field !== 'actions' && (
                          <td key={`${header?.field}-${item.id}`}>
                            {header.hasChildren ? (
                              <ContainerSwitch>
                                <ReactSwitch
                                  checked={item.verified}
                                  onChange={() => handleUpdate(item)}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  disabled={
                                    item.verified
                                      ? item.verified_by_id !== user.id
                                      : false
                                  }
                                />
                                {item.verified &&
                                  item.verified_by_id !== user.id && (
                                    <TooltipComponent
                                      label=""
                                      message="Este registro foi verificado por outro usuário"
                                      bold
                                    />
                                  )}
                              </ContainerSwitch>
                            ) : (
                              <p
                                style={{
                                  textAlign: 'left'
                                }}
                              >
                                {typeof item[header?.field] === 'boolean' &&
                                  (item[header?.field] ? 'Sim' : 'Nâo')}
                                {header?.field.split('.').length === 3 &&
                                  item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ]?.[header?.field.split('.')[2]]}
                                {header?.field.split('.').length === 2 &&
                                  item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ]}
                                {header?.field.split('.').length === 1 &&
                                  item[header?.field]}
                              </p>
                            )}
                          </td>
                        )) || (
                          <td key={`actions-${item.id}`} className="actions">
                            {(actions && (
                              <LinkContainer>
                                {actions.map(action => (
                                  <Link
                                    to={
                                      action.link ||
                                      (action.linkTo && action.linkTo(item)) ||
                                      '#'
                                    }
                                    key={action.name}
                                    title={action.title}
                                    onClick={e => {
                                      if (action.onClick) {
                                        e.preventDefault()
                                        action.onClick(item)
                                      }
                                    }}
                                  >
                                    {action.spanIcon ? (
                                      <span className={action.spanIcon} />
                                    ) : (
                                      <FontAwesomeIcon icon={action.icon} />
                                    )}
                                  </Link>
                                ))}
                              </LinkContainer>
                            )) || (
                              <LinkContainer>
                                {!item.parent_id && (
                                  <Link
                                    className="link"
                                    key={Math.random()}
                                    title="Visualizar"
                                    to={() => handlerOnClickButtonList(item)}
                                  >
                                    <span className="fa fa-search" />
                                  </Link>
                                )}
                                <div>
                                  {!onlyView && (
                                    <Link
                                      className="link"
                                      key={Math.random()}
                                      title="Editar"
                                      onClick={e => {
                                        if (onActions?.onClickButtonEdit) {
                                          e.preventDefault()
                                          onActions.onClickButtonEdit(item)
                                        }
                                      }}
                                      to={() => handlerOnClickButtonEdit(item)}
                                    >
                                      <span className="fa fa-edit" />
                                    </Link>
                                  )}
                                  {!notHasChildren && (
                                    <div
                                      key={Math.random()}
                                      title="Remover"
                                      className="link"
                                      onClick={() => {
                                        handlerOnClickButtonRemove(item)
                                      }}
                                    >
                                      <span className="fa fa-remove" />
                                    </div>
                                  )}
                                </div>
                              </LinkContainer>
                            )}
                          </td>
                        )
                    )}
                  </tr>
                )
              })) || (
              <tr>
                <td colSpan={headers.length}>Nenhum registro encontrado</td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-5">
          <div className="dataTables_info">
            Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
            {totalItems} registros
          </div>
        </div>
        <div className="col-md-7 col-sm-7">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DataTable
