import React, { HTMLAttributes, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useLoading } from '../../hooks/loading'
import { Loading } from '../Loading'
import { LinkContainer, ContainerWrapper } from './style'
import { useAuth } from '../../hooks/auth'

interface Breadcrumb {
  name: string
  to?: string
}

type ParamsPush = {
  id: string
  value: string
}
export interface ToolsContainerProps {
  name: string
  to: string
  icon?: string
  iconElement?: ReactNode
  hasParams?: false | ParamsPush
  handleOnClick?: <T>(currentValue: T | any) => void
  users?: string[]
}

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  pageTitle?: string
  portletTitle?: string
  breadcrumb?: Breadcrumb[]
  dashboard?: JSX.Element
  tools?: ToolsContainerProps[]
  styleContent?: HTMLAttributes<HTMLDivElement>
  Content?: () => JSX.Element
}

const Container: React.FC<ContainerProps> = ({
  pageTitle,
  portletTitle,
  breadcrumb,
  dashboard,
  tools,
  children,
  Content,
  ...props
}) => {
  const { loading } = useLoading()
  const { user } = useAuth()

  return (
    <ContainerWrapper {...props} className="page-content-wrapper">
      <Loading isActive={loading} />
      {pageTitle && (
        <div className="page-head">
          <div className="container-fluid">
            <div className="page-title">
              <h1>{pageTitle}</h1>
            </div>
          </div>
        </div>
      )}
      <div className="page-content">
        <div className="container-fluid">
          {breadcrumb && (
            <ul className="page-breadcrumb breadcrumb">
              {breadcrumb.map((bread, i) => (
                <li key={bread.name}>
                  {(bread.to && <Link to={bread.to}>{bread.name}</Link>) ||
                    bread.name}
                  {breadcrumb.length !== i + 1 && (
                    <i className="fa fa-circle" />
                  )}
                </li>
              ))}
            </ul>
          )}
          {dashboard && dashboard}
          <div className="page-content-inner">
            <div className="mt-content-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="portlet light bordered">
                    {Content ? (
                      <Content />
                    ) : (
                      <>
                        {portletTitle && (
                          <div className="portlet-title">
                            <div className="caption">{portletTitle}</div>
                            {tools && (
                              <LinkContainer className="tools">
                                {tools.map(
                                  tool =>
                                    (!tool.users ||
                                      tool.users.includes(
                                        String(user?.id)
                                      )) && (
                                      <Link
                                        style={{
                                          cursor: 'pointer'
                                        }}
                                        key={Math.random()}
                                        to={tool.to}
                                        onClick={e => {
                                          if (tool.handleOnClick) {
                                            e.preventDefault()
                                            tool.handleOnClick(tool)
                                          }
                                        }}
                                      >
                                        {tool.iconElement ? (
                                          tool.iconElement
                                        ) : (
                                          <i className={tool.icon} />
                                        )}

                                        <p>{tool.name}</p>
                                      </Link>
                                    )
                                )}
                              </LinkContainer>
                            )}
                          </div>
                        )}
                        <div className="portlet-body form">{children} </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerWrapper>
  )
}

export default Container
