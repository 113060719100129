import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { useHistory, useParams } from 'react-router-dom'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'
import { TypeComposition } from '../domain/models/typeProduct'
import { BRL, genericMaskWithTwoZero } from '../../../../utlis/mask'

interface CampaignData {
  campaign: Campaign
  camapaign_items: TypeComposition[]
}

interface CampaignDataFetch {
  campaing: Campaign
  camapaign_items: CampaignItems[]
}

export interface Campaign {
  id: number
  name: string
  type?: string
  pricing_type?: string
  department: string
  client: string
  start_date: string
  end_date: string
  start_date_delivery: string
  end_date_delivery: string
  description: string
  created_at: string
  updated_at: string
}

interface CampaignItems {
  id: number
  campaign_id: number
  stock_id: number
  stock: {
    name: string
    prices: string
    product: {
      id: number
      name: string
    }
  }
  min?: number
  max?: number
  promotion: string
}

type Client = {
  id: number
  name: string
  departmentName: string
}

type Department = {
  id: number
  name: string
}

const PRICING_TYPE_TRANSLATE: Record<string, string> = {
  unique: 'Único',
  proportional: 'Proporcional'
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [campaigns, setCampaigns] = useState<CampaignData>()
  const [clients, setClients] = useState<Client[]>([])
  const [departments, setDepartments] = useState<Department[]>([])

  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  const loadDepartments = async (campaigns: CampaignData) => {
    if (!campaigns) return
    const departmentsIds: number[] = JSON.parse(campaigns?.campaign?.department)
    const clientsIds = JSON.parse(campaigns?.campaign?.client)

    const departmentsFetch = await (await api.get('commercial/segments')).data
    const departments: Department[] = departmentsFetch
      .filter((a: { id: any }) => departmentsIds.includes(a.id))
      .map((department: { id: any; name: any }) => ({
        id: department.id,
        name: department.name
      }))
    const clients = (
      await api.get(`commercial/clients?departments_ids="${departmentsIds}"`)
    ).data
      .filter((a: { id: any }) => clientsIds.includes(a.id))
      .map((client: any) => ({
        id: client.id,
        name: client.person.name
      }))
    setClients(clients)
    setDepartments(departments)
  }

  useEffect(() => {
    async function loadcampaigns(): Promise<void> {
      activeLoading()
      const response = await api.get<CampaignDataFetch>(apiList(id))
      const { data } = response
      const campaign = {
        ...data.campaing,
        start_date: data.campaing.start_date,
        end_date: data.campaing.end_date
      }
      const campaignItems = data.camapaign_items.map(item => ({
        id: item.id,
        stock_id: item.stock_id,
        name: item.stock.name,
        product_id: item.stock.product.id,
        product_name: item.stock.product.name,
        price_sale: BRL(
          genericMaskWithTwoZero(JSON.parse(item.stock.prices)?.price_sale)
        ).format(),
        min: item.min,
        max: item.max,
        promotion: genericMaskWithTwoZero(item.promotion)
      }))

      setCampaigns({
        campaign,
        camapaign_items: campaignItems
      })
      await loadDepartments({
        campaign,
        camapaign_items: campaignItems
      })
      disableLoading()
    }
    loadcampaigns()
  }, [id, addToast, activeLoading, disableLoading])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Campanha removida com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Campanha não removida, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(id),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{campaigns?.campaign.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Tipo
                </label>
                <p>
                  {campaigns?.campaign.type === 'location'
                    ? 'Locação'
                    : campaigns?.campaign.type === 'sale'
                    ? 'Venda'
                    : ''}
                </p>
              </div>
            </div>
            {campaigns?.campaign?.type === 'location' && (
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    Tipo de precificação
                  </label>
                  <p>
                    {PRICING_TYPE_TRANSLATE[campaigns?.campaign.pricing_type]}
                  </p>
                </div>
              </div>
            )}
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="start_date" className="control-label">
                  Data inicial
                </label>
                <p>{campaigns?.campaign.start_date?.substring(0, 10)}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="end_date" className="control-label">
                  Data final
                </label>
                <p>{campaigns?.campaign.end_date?.substring(0, 10)}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="start_date" className="control-label">
                  Data entrega inicial
                </label>
                <p>
                  {campaigns?.campaign.start_date_delivery?.substring(0, 10)}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="end_date" className="control-label">
                  Data entrega final
                </label>
                <p>{campaigns?.campaign.end_date_delivery?.substring(0, 10)}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created_at" className="control-label">
                  Cadastrado em
                </label>
                <p>{campaigns?.campaign.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated_at" className="control-label">
                  Atualizado em
                </label>
                <p>{campaigns?.campaign.updated_at}</p>
              </div>
            </div>
          </div>
        </div>
        <h4>Descrição</h4>
        <div style={{ marginBottom: '20px' }}>
          <p style={{ whiteSpace: 'pre' }}>
            {campaigns?.campaign?.description}
          </p>
        </div>
        {departments.length > 0 && (
          <>
            <h4 style={{ marginTop: '20px' }}>Seguimentos</h4>
            <div className="dataTables_wrapper no-footer">
              <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                <tbody>
                  {departments.map(({ id, name }) => (
                    <tr key={id}>
                      <td style={{ textAlign: 'left' }}>{name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {clients.length > 0 && (
          <>
            <h4 style={{ marginTop: '20px' }}>Clientes</h4>
            <div className="dataTables_wrapper no-footer">
              <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                <tbody>
                  {clients.map(({ id, name }) => (
                    <tr key={id}>
                      <td style={{ textAlign: 'left' }}>{name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <h4 style={{ marginTop: '20px' }}>Produtos</h4>
        {campaigns?.camapaign_items && campaigns.camapaign_items.length > 0 && (
          <div className="dataTables_wrapper no-footer">
            <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
              <tbody>
                <tr>
                  <th>Produto</th>
                  <th>Variação</th>
                  <th>
                    {campaigns?.campaign?.type === 'location'
                      ? 'Valor de Locação'
                      : 'Valor de venda'}
                  </th>
                  <th>Quantidade Minima</th>
                  <th>Quantidade Máxima</th>
                  <th>Valor promocional</th>
                </tr>
                {campaigns?.camapaign_items &&
                  campaigns.camapaign_items.map(
                    ({
                      name,
                      price_sale,
                      max,
                      min,
                      promotion,
                      product_name
                    }) => (
                      <tr key={name}>
                        <td>{product_name}</td>
                        <td>{name}</td>
                        <td>{price_sale}</td>
                        <td>{min}</td>
                        <td>{max}</td>
                        <td style={{ textAlign: 'left' }}>{promotion}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        )}
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir a campanha ${campaigns?.campaign.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(
            Number(campaigns?.campaign.id)
          )
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}
export default View
