import React from 'react'
import List from '../../pages/Financial/FinancialTransactions/NotEmailed/list'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FinancialTransactionsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/financialTransactions/notEmailed"
      exact
      component={List}
    />
  </CustomSwitch>
)
