import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import { useClient } from '../../providers/ClientProvider'
import LabelText from '../../../../../../components/LabelText'
import moment from 'moment'

const CONTRIBUTOR_TYPE = {
  1: 'CONTRIBUINTE ICMS',
  2: 'CONTRIBUINTE ISENTO',
  9: 'NÃO CONTRIBUINTE'
}

export const OverviewTab = (): JSX.Element => {
  const {
    initialValues,
    originsOptions,
    segmentsOptions,
    economicGroupsOptions,
    clientsTypesOptions
  } = useClient()
  const [personType, setPersonType] = useState('PJ')

  useEffect(() => {
    if (initialValues?.person.type) {
      setPersonType(initialValues.person.type)
    }
  }, [initialValues])

  return (
    <Container>
      <div className="row">
        <LabelText
          label="Tipo captação"
          text={
            initialValues?.client?.capture_type === 'P' ? 'Passiva' : 'Ativa'
          }
          col={2}
        />
        <LabelText
          label="Origem"
          text={
            originsOptions.find(
              option => option.value === initialValues?.client.client_origin_id
            )?.name
          }
          col={2}
        />
        <LabelText
          label="Consumidor final"
          text={initialValues?.client.final_consumer ? 'SIM' : 'NÃO'}
          col={2}
        />
        <LabelText
          label="Segmento"
          text={
            segmentsOptions.find(
              option => option.value === initialValues?.client.segment_id
            )?.name
          }
          col={4}
        />
        <LabelText
          label="Tipo de cliente"
          text={
            clientsTypesOptions.find(
              option => option.value === initialValues?.client.client_type_id
            )?.name
          }
          col={2}
        />
      </div>
      <div className="row">
        {personType === 'PJ' ? (
          <>
            <LabelText
              label="Razão social"
              text={initialValues?.person.name}
              col={6}
            />
            <LabelText
              label="Nome fantasia"
              text={initialValues?.person.fantasy_name}
              col={4}
            />
          </>
        ) : (
          <LabelText label="Nome" text={initialValues?.person.name} col={6} />
        )}
      </div>
      <div className="row">
        {personType === 'PJ' ? (
          <>
            <LabelText
              label="Tipo contribuinte"
              text={
                CONTRIBUTOR_TYPE[initialValues?.person.contributor_type as 1]
              }
              col={2}
            />
            <LabelText
              label="Inscrição Estadual"
              text={initialValues?.person.state_registration}
              col={2}
            />
            <LabelText
              label="Inscrição Municipal"
              text={initialValues?.person.city_registration}
              col={2}
            />
            <LabelText
              label="Inscrição SUFRAMA"
              text={initialValues?.person.suframa_registration}
              col={2}
            />
            <LabelText
              label="Data de abertura"
              text={
                initialValues?.person.date_opening_birth
                  ? moment(initialValues?.person.date_opening_birth).format(
                      'DD/MM/YYYY'
                    )
                  : ''
              }
              col={2}
            />
          </>
        ) : (
          <>
            <LabelText
              label="RG"
              text={initialValues?.person.document}
              col={2}
            />
            <LabelText
              label="Data de nascimento"
              text={
                initialValues?.person.date_opening_birth
                  ? moment(initialValues?.person.date_opening_birth).format(
                      'DD/MM/YYYY'
                    )
                  : ''
              }
              col={2}
            />
          </>
        )}
      </div>
      <div className="row">
        <LabelText
          label="CEP"
          text={initialValues?.address?.zip_code}
          col={2}
        />
        <LabelText
          label="Logradouro"
          text={initialValues?.address?.street}
          col={4}
        />
        <LabelText
          label="Número"
          text={initialValues?.address?.number}
          col={2}
        />
        <LabelText
          label="Complemento"
          text={initialValues?.address?.complement}
          col={4}
        />
      </div>
      <div className="row">
        <LabelText
          label="Bairro"
          text={initialValues?.address?.district}
          col={2}
        />
        <LabelText label="Cidade" text={initialValues?.address?.city} col={2} />
        <LabelText label="UF" text={initialValues?.address?.state} col={2} />
        <LabelText
          label="Telefone fixo 1"
          text={initialValues?.client.phone_number1}
          col={2}
        />
        <LabelText
          label="Telefone fixo 2"
          text={initialValues?.client.phone_number2}
          col={2}
        />
        <LabelText
          label="Celular principal"
          text={initialValues?.client.cellphone_number}
          col={2}
        />
      </div>
      <div className="row">
        <LabelText
          label="Responsável Venda"
          text={initialValues?.client.saleSeller?.name}
          col={2}
        />
        <LabelText
          label="Responsável Locação"
          text={initialValues?.client.locationSeller?.name}
          col={2}
        />
      </div>
    </Container>
  )
}
