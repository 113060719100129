import React from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import ReactTooltip from 'react-tooltip'

import { Container } from './style'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type TypeTooltipComponent = {
  message: string
  label: string
  bold?: boolean
  icon?: IconDefinition
}

export const TooltipComponent = ({
  message,
  label,
  bold,
  icon
}: TypeTooltipComponent): JSX.Element => {
  return (
    <Container bold={bold}>
      <label htmlFor="form">{label}</label>
      <div>
        <span data-tip={message} data-class="my-tooltip">
          <FontAwesomeIcon icon={icon || faQuestionCircle} />
        </span>
        <ReactTooltip multiline={true} place="top" type="dark" effect="float" />
      </div>
    </Container>
  )
}
