export const headers = [
  {
    name: 'Tipo',
    field: 'product.type',
    sortable: true
  },
  {
    name: 'Grupo',
    field: 'product.product_category.name',
    sortable: true
  },
  { name: 'Produto', field: 'product.name', sortable: true },
  { name: 'Variação', field: 'name', sortable: true },
  { name: 'Estoque Atual', field: 'current_stock', sortable: true },
  { name: 'Ponto de Reposição', field: 'replacement_point', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
