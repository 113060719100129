import styled from 'styled-components'

export const ListTitle = styled.div`
  p {
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 10px;
    color: #666;
  }

  hr {
    margin-bottom: 10px;
  }
`
export const DatatableWrapper = styled.div`
  a:not(:last-child) {
    color: red;
    opacity: 0.8;
  }
`

export const Table = styled.table`
  td,
  th {
    text-align: left !important;
  }
`
