import { FaTrashAlt } from 'react-icons/fa'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  .title {
    font-weight: bold;
  }
  .items {
    > td {
      text-align: start !important;
    }
  }
`

export const IconRemove = styled(FaTrashAlt).attrs(() => ({
  size: '16px'
}))`
  margin: 10px auto;
  cursor: pointer;
`

export const Panel = styled.div<{ open: boolean; id: string }>`
  height: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  ${({ open, id }) =>
    open &&
    css`
      height: ${document.getElementById(id)?.scrollHeight}px;
    `}
`

export const StockItem = styled.div`
  &:first-child {
    border-top: 1px solid #f2f5f8;
  }
  border-bottom: 1px solid #f2f5f8;

  margin-bottom: 0px;
  .panel-default {
    margin-bottom: 0px;
    border: none !important;
  }
  .panel-body {
    font-size: 14px;
    border: none !important;
  }
  .panel-heading {
    background: #fff;
    height: 84px;
    display: flex;
    align-items: center;
    div {
      color: #a0a0a0;
      text-transform: capitalize;
      svg {
        margin-left: 22px;
      }
      font-size: 14px;
      font-weight: 400;
      display: block !important;
      cursor: pointer;
      span {
        color: #aaa;
        margin-right: 10px;
      }
    }
  }

  label {
    font-weight: bold;
    color: #a0a0a0;
  }

  button {
    margin-top: 20px;
  }

  .row .form-content {
    margin-bottom: 15px;
  }
`
