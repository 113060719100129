import React, { useCallback, useEffect, useRef, useState } from 'react'
import { breadcrumbList } from '../domain/breadcrumb'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faTruck,
  faClipboardList,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { Card } from '../../../../components/Card'
import {
  Container,
  ContainerButton,
  ContainerWrapper,
  ControlPanel,
  LinkContainer,
  ListTitle,
  SelectContainer
} from './style'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import Button from '../../../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HeaderPortlet } from '../components/HeaderPortlet'
import Search from '../components/Search'
import { Loading } from '../../../../components/Loading'
import TableHeader from '../components/Header'
import convertDateToISO from '../../../../utlis/convertDateToISO'
import { Alert } from '../../../../components/Alert'
import Modal from '../../../../components/Modal'
import { FormOrder } from '../components/Form'
import DataTable from '../../../../components/DataTable'
import { headers, historyHeaders } from '../domain/headers'
import { BRL } from '../../../../utlis/mask'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { optionsPaymentForm } from '../domain/info'

interface ControlData {
  name: string
  items: {
    number: string
    text: string
    link?: string
    htmlLink?: string
    icon: IconDefinition
    isProgress: number
    handleExport?(): Promise<void>
    footerItems: {
      subtitle: string
      text: string
      link: string
      linkParams: string
      isProgress: number
      handleExport?(): Promise<void>
    }[]
  }[]
}
interface OrdersData {
  status: string
  id: number
  order_origin: number
  order_previous: number
  clientName: string
  freight_value: string
  total_value: string
  due_date: string
  payment_form: string
  important: string
  delivery_date: string
  pick_up_date: string
  history: string
  created_at: string
  updated_at: string
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TasksData {
  id: number
  client: {
    name: string
  }
  date: string
  expiration: string
  requester: string
  requested: string
}

interface ISearchParams {
  data: string
  entity: string
  source: string
  fieldSearch: string
  header: {
    id: string
    title: string
    tableField: string
    type?: string
    formatStyle?: string
    precision?: number
    locale?: string
    symbol?: string
    source?: string
    emptyValue?: string
    multiItem?: string[]
    condition?: { true: string; false: string }
  }[]
  innerJoin?: {
    entity: string
    source: string
    field: string
    fieldCondition: string
    sourceCondition: string
  }[]
}

const List: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { disableLoading, activeLoading, loading } = useLoading()
  const { addToast } = useToast()
  const [controlsData, setControlsData] = useState<ControlData[]>([
    {
      name: '',
      items: [
        {
          number: '0',
          text: 'LOCAÇÃO MÊS',
          link: '#',
          icon: faTruck,
          isProgress: 0,

          footerItems: [
            {
              subtitle: '0',
              text: 'LOCAÇÃO',
              link: '#',
              linkParams: '#',
              isProgress: 0
            },
            {
              subtitle: '0',
              text: 'LOCAÇÃO ESTENDIDA',
              link: '#',
              linkParams: '#',
              isProgress: 0
            }
          ]
        },
        {
          number: '0',
          text: 'LOCAÇÃO',
          link: '#',
          icon: faClock,
          isProgress: 0,
          footerItems: [
            {
              subtitle: '0',
              text: 'ORÇAMENTO',
              link: '#',
              linkParams: '#',
              isProgress: 0
            },
            {
              subtitle: '0',
              text: 'CANCELADO',
              link: '#',
              linkParams: '#',
              isProgress: 0
            }
          ]
        },
        {
          number: '0',
          text: 'LOCAÇÃO ESTENDIDA',
          link: '#',
          icon: faClipboardList,
          isProgress: 0,
          footerItems: [
            {
              subtitle: '0',
              text: 'RENOVAÇÕES DIA',
              link: '#',
              linkParams: 'string',
              isProgress: 0
            },
            {
              subtitle: '0',
              text: 'RENOVAÇÕES MÊS',
              link: '#',
              linkParams: '#',
              isProgress: 0
            }
          ]
        }
      ]
    }
  ])
  const [selectedOrders, setSelectedOrders] = useState<any>({
    'Renovações Pendentes': [],
    'Renovações Hoje': []
  })
  const [markAll, setMarkAll] = useState<any>({
    'Renovações Pendentes': true,
    'Renovações Hoje': true
  })
  const markAllPeriodRef = useRef(null)
  const markAllPendingRef = useRef(null)
  const [ordersPeriod, setOrdersPeriod] = useState<OrdersData[]>([])
  const [ordersPending, setOrdersPending] = useState<OrdersData[]>([])
  const [searchOrdersPeriod, setSearchOrdersPeriod] = useState<OrdersData[]>([])
  const [searchOrdersPending, setSearchOrdersPending] = useState<OrdersData[]>(
    []
  )
  const { handleSubmit, control } = useForm()
  const [modalOrderPeriod, setModalOrderPeriod] = useState(false)
  const [modalOrderPending, setModalOrderPending] = useState(false)
  const [modalOrderHistory, setModalOrderHistory] = useState(false)
  const [parameters, setParameters] = useState<any[]>([])
  const [currentOrderCancellPeriod, setCurrentOrderCancellPeriod] =
    useState<OrdersData>({} as OrdersData)
  const [currentOrderCancellPending, setCurrentOrderCancellPending] =
    useState<OrdersData>({} as OrdersData)
  const [currentOrderHistory, setCurrentOrderHistory] = useState<OrdersData>(
    {} as OrdersData
  )
  const [selectedDate, setSelectedDate] = useState('D')
  const [currentPeriod, setCurrentPeriod] = useState('Hoje')

  const refModalPeriod = useRef(null)
  const refModalPending = useRef(null)

  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
    title: string
    target?: any
  }>({
    id: 0,
    isActive: false,
    name: '',
    title: ''
  })
  const handleGenerateCsv = useCallback(
    async ({
      data,
      entity,
      fieldSearch,
      header,
      source,
      innerJoin
    }: ISearchParams) => {
      if (!data) return
      const [year, month, day] = new Date()
        .toISOString()
        .slice(0, 10)
        .split('-')
      const date = `${day}-${month}-${year}`
      const hashedOrders = btoa(data)
      api
        .post('commercial/orders/generateexport', {
          data: hashedOrders,
          entity,
          fieldSearch,
          source,
          header,
          innerJoin
        })
        .then(response => {
          const blob = new Blob(['\ufeff' + response.data], {
            type: 'text/csv;charset=utf-8"'
          })
          const url = window.URL.createObjectURL(blob)

          const a = document.createElement('a')
          a.download = `relatorio-pedidos-${date}.csv`
          a.href = url
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        })
    },
    []
  )
  const generateExport = useCallback(
    async (data: any) => {
      await handleGenerateCsv({
        data,
        entity: 'Order',
        fieldSearch: 'id',
        header: [
          {
            id: 'order_id',
            tableField: 'id',
            title: 'Pedido'
          },
          {
            id: 'date',
            tableField: 'date',
            title: 'Data',
            type: 'date',
            formatStyle: '%Y-%m-%d'
          },
          {
            id: 'name_department',
            tableField: 'name',
            title: 'Segmento',
            source: 'Departments',
            emptyValue: 'SEM SEGMENTO'
          },
          {
            id: 'client_name',
            tableField: 'name',
            title: 'Cliente',
            emptyValue: 'SEM CLIENTE',
            source: 'Client'
          },
          {
            id: 'products_value',
            tableField: 'products_value',
            title: 'Valor Produtos',
            type: 'money',
            symbol: 'R$',
            precision: 2,
            locale: 'pt_BR'
          },
          {
            id: 'freight_value',
            tableField: 'freight_value',
            title: 'Valor Frete',
            type: 'money',
            symbol: 'R$',
            precision: 2,
            locale: 'pt_BR'
          },
          {
            id: 'show_included_freight',
            tableField: 'show_included_freight',
            title: 'Frete Embutido',
            type: 'boolean',
            condition: {
              true: 'Sim',
              false: 'Não'
            }
          },
          {
            id: 'ipi_value',
            tableField: 'ipi_value',
            title: 'Valor IPI',
            type: 'money',
            symbol: 'R$',
            precision: 2,
            locale: 'pt_BR'
          },
          {
            id: 'total_value',
            tableField: 'total_value',
            title: 'Valor Total',
            type: 'money',
            symbol: 'R$',
            precision: 2,
            locale: 'pt_BR'
          },
          {
            id: 'invoice_date',
            tableField: 'invoice_date',
            title: 'Data Faturamento',
            type: 'date',
            formatStyle: '%Y-%m-%d'
          },
          {
            id: 'released',
            tableField: 'released',
            title: 'Faturado',
            type: 'boolean',
            condition: {
              true: 'Sim',
              false: 'Não'
            }
          },
          {
            id: 'agent_id',
            tableField: 'name',
            title: 'Representante',
            source: 'Employee'
          }
        ],
        source: 'Order',
        innerJoin: [
          {
            entity: 'Client',
            source: 'Client',
            field: 'id',
            fieldCondition: 'client_id',
            sourceCondition: 'Order'
          },
          {
            entity: 'Departments',
            source: 'Departments',
            field: 'id',
            fieldCondition: 'department_id',
            sourceCondition: 'Client'
          },
          {
            entity: 'Employee',
            source: 'Employee',
            field: 'agent_id',
            fieldCondition: 'agent_id',
            sourceCondition: 'Order'
          }
        ]
      })
    },
    [handleGenerateCsv]
  )
  const loadOrdersPending = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get(
        '/commercial/orders/listExtendedLocations/pending'
      )
      setOrdersPending(
        data.map(
          (item: any) =>
            item && {
              ...item,
              delivery_date: item.delivery_date?.split(' ')[0]
            }
        )
      )
      setSearchOrdersPending(
        data.map(
          (item: any) =>
            item && {
              ...item,
              delivery_date: item.delivery_date?.split(' ')[0]
            }
        )
      )
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar os pedidos',
        description:
          'Houve um error ao carregar os pedidos, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, location.pathname])

  const loadOrdersPeriod = useCallback(
    async (date?: string, customPeriod?: string) => {
      const title = 'Renovações Hoje'
      const titles: any = {
        'Renovações Hoje': markAllPeriodRef
      }
      const transformPeriods: any = {
        D: 'Hoje',
        M: 'Mês',
        W: 'Semana'
      }
      activeLoading()
      const selectDate = date || selectedDate
      const selectCurrentPeriod =
        transformPeriods[date] || customPeriod || currentPeriod
      setSelectedDate(date || selectedDate)
      setCurrentPeriod(selectCurrentPeriod)
      try {
        const { data } = await api.get(
          `/commercial/orders/listExtendedLocations/${selectDate}`
        )
        setOrdersPeriod(
          data.map(
            (item: any) =>
              item && {
                ...item,
                delivery_date: item.delivery_date?.split(' ')[0]
              }
          )
        )
        setSearchOrdersPeriod(
          data.map(
            (item: any) =>
              item && {
                ...item,
                delivery_date: item.delivery_date?.split(' ')[0]
              }
          )
        )
        setSelectedOrders({
          ...selectedOrders,
          [title]: []
        })
        setMarkAll({
          ...selectedOrders,
          [title]: true
        })
        titles[title].current.checked = false
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar os pedidos',
          description:
            'Houve um error ao carregar os pedidos, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    },
    [
      activeLoading,
      addToast,
      currentPeriod,
      disableLoading,
      history,
      location.pathname,
      selectedDate,
      selectedOrders
    ]
  )

  const loadTotalLocations = useCallback(async () => {
    const startMonth = moment().startOf('M').format('DD/MM/YYYY')
    const endMonth = moment().endOf('M').format('DD/MM/YYYY')
    activeLoading()
    try {
      const { data } = await api.get('/commercial/orders/totalLocations')
      setControlsData([
        {
          name: '',
          items: [
            {
              number: data.locationMonth.total,
              text: 'LOCAÇÃO MÊS',
              icon: faTruck,
              isProgress: 0,
              handleExport: () =>
                generateExport(data.locationMonth.ordersTotal),
              footerItems: [
                {
                  subtitle: data.locationMonth.totalLocations,
                  text: 'LOCAÇÃO',
                  link: '#',
                  linkParams: '#',
                  isProgress: 0,
                  handleExport: () =>
                    generateExport(data.locationMonth.ordersLocation)
                },
                {
                  subtitle: data.locationMonth.totalLocationsExtended,
                  text: 'LOCAÇÃO ESTENDIDA',
                  link: '#',
                  linkParams: '#',
                  isProgress: 0,
                  handleExport: () =>
                    generateExport(data.locationMonth.ordersExtented)
                }
              ]
            },
            {
              number: data.locationMonth.totalLocations,
              text: 'LOCAÇÃO',
              icon: faClock,
              isProgress: 0,
              handleExport: () => generateExport(data.location.ordersLocation),
              footerItems: [
                {
                  subtitle: data.location.totalPending,
                  text: 'ORÇAMENTO',
                  link: '#',
                  linkParams: '#',
                  isProgress: 0,
                  handleExport: () =>
                    generateExport(data.location.ordersPending)
                },
                {
                  subtitle: data.location.totalCanceled,
                  text: 'CANCELADO',
                  link: '#',
                  linkParams: '#',
                  isProgress: 0,
                  handleExport: () =>
                    generateExport(data.location.ordersCanceled)
                }
              ]
            },
            {
              number: data.locationMonth.totalLocationsExtended,
              text: 'LOCAÇÃO ESTENDIDA',
              icon: faClipboardList,
              isProgress: 0,
              handleExport: () =>
                generateExport(data.locationMonth.ordersExtented),
              footerItems: [
                {
                  subtitle: data.locationsExtended.totalDay,
                  text: 'RENOVAÇÕES DIA',
                  link: '#',
                  linkParams: 'string',
                  isProgress: 0,
                  handleExport: () =>
                    generateExport(data.locationsExtended.ordersDay)
                },
                {
                  subtitle: data.locationsExtended.totalMonth,
                  text: 'RENOVAÇÕES MÊS',
                  link: '#',
                  linkParams: '#',
                  isProgress: 0,
                  handleExport: () =>
                    generateExport(data.locationsExtended.ordersMonth)
                }
              ]
            }
          ]
        }
      ])
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar os pedidos',
        description:
          'Houve um error ao carregar os pedidos, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading, generateExport])

  const handleSelect = useCallback(
    (item?: OrdersData, title?: string, toggleAll?: string) => {
      activeLoading()
      const titles: any = {
        'Renovações Pendentes': searchOrdersPending,
        'Renovações Hoje': searchOrdersPeriod
      }
      if (toggleAll === 'mark') {
        setSelectedOrders({
          ...selectedOrders,
          [title]: [...titles[title]]
        })
        setMarkAll({
          ...markAll,
          [title]: false
        })
        disableLoading()
        return
      }
      if (toggleAll === 'unmark') {
        setSelectedOrders({
          ...selectedOrders,
          [title]: []
        })
        setMarkAll({
          ...markAll,
          [title]: true
        })
        disableLoading()
        return
      }
      const findOrder = selectedOrders[title].find(
        (order: OrdersData) => order.id === item.id
      )
      if (findOrder) {
        const filterOrder = selectedOrders[title].filter(
          (order: OrdersData) => order.id !== item.id
        )
        setSelectedOrders({
          ...selectedOrders,
          [title]: [...filterOrder]
        })
      } else {
        setSelectedOrders({
          ...selectedOrders,
          [title]: [...selectedOrders[title], item]
        })
      }
      disableLoading()
    },
    [
      activeLoading,
      disableLoading,
      markAll,
      searchOrdersPending,
      searchOrdersPeriod,
      selectedOrders
    ]
  )
  const handleProcessingOrders = useCallback(
    async (title: string, alert: any) => {
      activeLoading()
      const titles: any = {
        'Renovações Pendentes': markAllPendingRef,
        'Renovações Hoje': markAllPeriodRef
      }
      let filterOrder = selectedOrders[title].filter(
        (order: OrdersData) => order.id
      )
      const arrayAlert = Object.entries(alert?.target)
      const errosOrder = arrayAlert
        .map(([key, formValue]: any) => {
          const clearKey = key.replaceAll(title.replace(/\s/g, ''), '')
          const value = formValue.due_date
          const payment = formValue.payment_form
          const findOrder = filterOrder.find(
            (item: any) => item.id === Number(clearKey)
          )
          if (findOrder) {
            if (!payment || payment === '') {
              return findOrder.id
            }
            if (
              value === '' ||
              !value ||
              !moment(value, 'DD/MM/YYYY').isValid()
            ) {
              return findOrder.id
            }
            if (
              moment(value, 'DD/MM/YYYY').isValid() &&
              moment(value, 'DD/MM/YYYY').isSameOrBefore(moment())
            ) {
              return findOrder.id
            }
          }
          return false
        })
        .filter(item => item)

      if (errosOrder.length) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Campos Obrigatórios Com Erro',
          description: `Pedidos Selecionandos devem conter uma data de vencimento maior que hoje, Pedidos: ${errosOrder.join(
            ','
          )}`
        })
        return
      }
      filterOrder = arrayAlert
        .map(([key, value]: any) => {
          const clearKey = key.replaceAll(title.replace(/\s/g, ''), '')
          const findOrder = filterOrder.find(
            (item: any) => item.id === Number(clearKey)
          )
          if (findOrder) {
            return {
              ...findOrder,
              ...value
            }
          }
          return undefined
        })
        .filter(item => item)

      try {
        await api.post('/commercial/orders/locations/extendedLocations/renew', {
          orders: filterOrder.map((order: OrdersData) => order)
        })
        setSelectedOrders({
          ...selectedOrders,
          [title]: []
        })
        setMarkAll({
          ...selectedOrders,
          [title]: true
        })
        titles[title].current.checked = false
        if (title === 'Renovações Hoje') {
          await loadOrdersPeriod()
        }
        if (title === 'Renovações Pendentes') {
          await loadOrdersPending()
        }
        await loadTotalLocations()
        disableLoading()
        addToast({
          type: 'success',
          title: 'Pedidos processados',
          description: 'Pedidos processados com sucesso'
        })
      } catch (error: any) {
        const responseMessage: string = error?.response?.data?.message
        const userMessage = responseMessage?.includes('USER MESSAGE: ')
          ? responseMessage.replace('USER MESSAGE: ', '')
          : ''
        const renewed = responseMessage?.includes('Pedidos renovados: 0')
        if (!renewed) {
          setSelectedOrders({
            ...selectedOrders,
            [title]: []
          })
          setMarkAll({
            ...selectedOrders,
            [title]: true
          })
          titles[title].current.checked = false
          if (title === 'Renovações Hoje') {
            await loadOrdersPeriod()
          }
          if (title === 'Renovações Pendentes') {
            await loadOrdersPending()
          }
        }
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao processar os pedidos',
          description:
            userMessage ||
            'Houve um error ao processar os pedidos, tente novamente mais tarde!'
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeLoading, addToast, disableLoading, loadOrdersPending, selectedOrders]
  )
  useEffect(() => {
    ;(async () => {
      await loadOrdersPeriod('D')
      await loadOrdersPending()
      await loadTotalLocations()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getSearched = (value: string, searchData: OrdersData[]) => {
    const convertValue = value.toLowerCase()
    let newTotal = '0'
    const filterOrders = searchData.filter(order => {
      if (order.clientName?.includes('TOTAL')) return true
      const convertClientName = order.clientName?.toLowerCase()
      const convertOrderId = order.id.toString()
      if (
        convertClientName?.includes(convertValue) ||
        convertOrderId.includes(convertValue)
      ) {
        newTotal = BRL(newTotal).add(order.total_value).format()
        return true
      }
      return false
    })
    const findIndexTotal = filterOrders.findIndex(item =>
      item?.clientName?.includes('TOTAL')
    )

    filterOrders[findIndexTotal].total_value = newTotal
    return filterOrders
  }

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      title: '',
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = () => {
    try {
      const copyAlert = { ...alert }
      handleProcessingOrders(alert.title, copyAlert)
      setIsActiveAlert({
        title: '',
        id: 0,
        isActive: false,
        name: ''
      })
    } catch (err) {
      setIsActiveAlert({
        title: '',
        id: 0,
        isActive: false,
        name: ''
      })
    }
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (
    value: string,
    searchData: any[],
    type: string
  ) => {
    const tranformTypes: any = {
      period: setSearchOrdersPeriod,
      pending: setSearchOrdersPending
    }
    const setHandleData = tranformTypes[type]
    if (value.length === 0) {
      let newTotal = '0'
      const findIndexTotal = searchData.findIndex(item =>
        item?.clientName?.includes('TOTAL')
      )
      for (const order of searchData) {
        if (order && !order.clientName?.includes('TOTAL')) {
          newTotal = BRL(newTotal).add(order.total_value).format()
        }
      }
      searchData[findIndexTotal].total_value = newTotal
      setHandleData(searchData)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(
      () => setHandleData(getSearched(value, searchData)),
      500
    )
  }

  const handleClickOnClose = async () => {
    setModalOrderPeriod(false)
    setModalOrderPending(false)
    await loadOrdersPeriod()
    await loadOrdersPending()
    await loadTotalLocations()
  }
  const handleOpenModal = (order: OrdersData, type: string) => {
    if (type === 'period') {
      setModalOrderPeriod(true)
      setCurrentOrderCancellPeriod(order)
    } else {
      setModalOrderPending(true)
      setCurrentOrderCancellPending(order)
    }
  }
  const handleOpenModalHistory = (order: OrdersData) => {
    setParameters([
      { orders_id: `${order.id},${order.order_origin},${order.order_previous}` }
    ])
    setCurrentOrderHistory(order)
    setModalOrderHistory(true)
  }

  const verifyInputs = useCallback(() => {
    const allowValues = ['mes', 'mês', 'semana', 'hoje']
    return !allowValues.includes(currentPeriod.toLowerCase())
  }, [currentPeriod])

  return (
    <ContainerWrapper className="page-content-wrapper">
      <Loading isActive={loading} />
      <div className="page-head">
        <div className="container-fluid">
          <div className="page-title">
            <h1>Painel de Locação</h1>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container-fluid">
          {breadcrumbList && (
            <ul className="page-breadcrumb breadcrumb">
              {breadcrumbList.map((bread, i) => (
                <li key={bread.name}>
                  {(bread.to && <Link to={bread.to}>{bread.name}</Link>) ||
                    bread.name}
                  {breadcrumbList.length !== i + 1 && (
                    <i className="fa fa-circle" />
                  )}
                </li>
              ))}
            </ul>
          )}

          <ControlPanel className="page-content-inner">
            {controlsData.map(({ name, items }) => (
              <div key={name}>
                <h4>{name}</h4>
                <div className="row">
                  <div className="col-md-12">
                    {items.map(
                      ({
                        icon,
                        link,
                        number,
                        text,
                        footerItems,
                        isProgress,
                        handleExport,
                        htmlLink
                      }) => (
                        <Card
                          key={Math.random()}
                          number={number}
                          text={text}
                          textFooter=""
                          isProgress={isProgress}
                          width="col-lg-2 col-md-3 col-sm-6 col-xs-12"
                          height="150px"
                          icon={icon}
                          link={link}
                          handleExport={handleExport}
                          footerLink="#"
                          footerItems={footerItems}
                          progressBarColor={'0'}
                          htmlLink={htmlLink}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </ControlPanel>

          <form
            onSubmit={handleSubmit(data => {
              setIsActiveAlert({
                ...alert,
                target: { ...data },
                name: `${
                  selectedOrders['Renovações Hoje'].filter(
                    (item: any) => item.id
                  ).length
                }`,
                isActive: true,
                title: 'Renovações Hoje'
              })
            })}
          >
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12" style={{ padding: '0 23px' }}>
                    <div className="portlet light bordered">
                      <div className="">
                        <div className="row" style={{ marginBottom: '50px' }}>
                          <div className="col-md-12">
                            <div
                              className="row"
                              style={{
                                display: 'flex',
                                alignItems: 'end',
                                paddingBottom: 10
                              }}
                            >
                              <div className="col-md-3">
                                <ListTitle>
                                  <p>
                                    Locação Estendida - Renovações{' '}
                                    {currentPeriod}
                                  </p>
                                </ListTitle>
                              </div>
                              <div
                                className="col-md-3"
                                style={{ margin: '0 auto' }}
                              >
                                <HeaderPortlet
                                  handleSetDate={loadOrdersPeriod}
                                  setInitialData={() => loadOrdersPeriod('D')}
                                  actions={[
                                    {
                                      title: 'Hoje',
                                      value: 'D'
                                    },
                                    {
                                      title: 'Semana',
                                      value: 'W'
                                    },
                                    {
                                      title: 'Mês',
                                      value: 'M'
                                    },
                                    {
                                      title: 'Período',
                                      value: 'Período'
                                    }
                                  ]}
                                  mainTitle=""
                                  startIndexAction={0}
                                />
                              </div>
                              <div
                                className="col-md-3"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end'
                                }}
                              >
                                <div className="">
                                  <div className="dataTables_filter">
                                    <label
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <p style={{ marginRight: '15px' }}>
                                        Pesquisar
                                      </p>
                                      <Search
                                        onSearch={value =>
                                          onSearchItem(
                                            value,
                                            ordersPeriod,
                                            'period'
                                          )
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr style={{ marginBottom: 60 }} />
                            <div className="table-scrollable">
                              <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                                <thead>
                                  <tr>
                                    <th
                                      style={{ padding: '10px' }}
                                      className={
                                        verifyInputs() ? 'disabled' : ''
                                      }
                                      onClick={() =>
                                        handleSelect(
                                          undefined,
                                          'Renovações Hoje',
                                          markAll['Renovações Hoje']
                                            ? 'mark'
                                            : 'unmark'
                                        )
                                      }
                                    >
                                      <div
                                        className="check-container form-content col-md-12"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                          height: '100%',
                                          verticalAlign: 'middle'
                                        }}
                                      >
                                        <input
                                          readOnly
                                          type="checkbox"
                                          ref={markAllPeriodRef}
                                          disabled={verifyInputs()}
                                        />
                                      </div>
                                    </th>
                                    <TableHeader
                                      headers={headers}
                                      onSorting={(field, order, type) => {
                                        const itemSorted =
                                          searchOrdersPeriod.sort(
                                            (a: any, b: any) => {
                                              if (
                                                (a.clientName || b.clientName)
                                                  .toLowerCase()
                                                  .includes('total')
                                              ) {
                                                return 0
                                              }
                                              const fields = field.split('.')
                                              let currentFieldA
                                              let currentFieldB
                                              if (fields.length === 1) {
                                                currentFieldA = a[fields[0]]
                                                currentFieldB = b[fields[0]]
                                              }
                                              if (fields.length === 2) {
                                                currentFieldA =
                                                  a[fields[0]]?.[fields[1]]
                                                currentFieldB =
                                                  b[fields[0]]?.[fields[1]]
                                              }
                                              if (fields.length === 3) {
                                                currentFieldA =
                                                  a[fields[0]]?.[fields[1]]?.[
                                                    fields[2]
                                                  ]
                                                currentFieldB =
                                                  b[fields[0]]?.[fields[1]]?.[
                                                    fields[2]
                                                  ]
                                              }
                                              if (type === 'date') {
                                                return new Date(
                                                  convertDateToISO(
                                                    currentFieldA
                                                  )
                                                ) >
                                                  new Date(
                                                    convertDateToISO(
                                                      currentFieldB
                                                    )
                                                  ) && order === 'ASC'
                                                  ? 1
                                                  : -1
                                              }
                                              if (type === 'monetary') {
                                                return Number(
                                                  currentFieldA.replaceAll(
                                                    /[',','.']/gi,
                                                    ''
                                                  )
                                                ) >
                                                  Number(
                                                    currentFieldB.replaceAll(
                                                      /[',','.']/gi,
                                                      ''
                                                    )
                                                  ) && order === 'ASC'
                                                  ? 1
                                                  : -1
                                              }
                                              return currentFieldA >
                                                currentFieldB && order === 'ASC'
                                                ? 1
                                                : -1
                                            }
                                          )
                                        setSearchOrdersPeriod([...itemSorted])
                                      }}
                                    />
                                    <th style={{ textAlign: 'center' }}>
                                      Histórico
                                    </th>
                                    <th style={{ textAlign: 'center' }}>
                                      Ações
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchOrdersPeriod.length > 1 ? (
                                    searchOrdersPeriod?.map(order => (
                                      <tr
                                        key={Math.random()}
                                        className={
                                          verifyInputs() ? 'disabled' : ''
                                        }
                                      >
                                        {!order.clientName?.includes(
                                          'TOTAL'
                                        ) ? (
                                          <td
                                            style={{ padding: '10px' }}
                                            onClick={() =>
                                              handleSelect(
                                                order,
                                                'Renovações Hoje'
                                              )
                                            }
                                          >
                                            <div
                                              className="check-container form-content col-md-12"
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%'
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                readOnly
                                                disabled={verifyInputs()}
                                                checked={
                                                  !!selectedOrders[
                                                    'Renovações Hoje'
                                                  ]?.find(
                                                    (
                                                      orderSelected: OrdersData
                                                    ) =>
                                                      orderSelected?.id ===
                                                      order?.id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            style={
                                              order.clientName?.includes(
                                                'TOTAL'
                                              )
                                                ? {
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                  }
                                                : {}
                                            }
                                          >
                                            {order?.clientName}
                                          </td>
                                        )}
                                        <td
                                          style={
                                            order.clientName?.includes('TOTAL')
                                              ? {
                                                  fontWeight: 'bold'
                                                }
                                              : {}
                                          }
                                        >
                                          {order.clientName?.includes('TOTAL')
                                            ? searchOrdersPeriod.length - 1
                                            : order?.id}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes('TOTAL')
                                            ? order?.clientName
                                            : ''}
                                        </td>
                                        <td
                                          style={
                                            order.clientName?.includes('TOTAL')
                                              ? { fontWeight: 'bold' }
                                              : {}
                                          }
                                        >
                                          {order?.total_value}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <Controller
                                              name={`${order.id}RenovaçõesHoje.due_date`}
                                              control={control}
                                              defaultValue={
                                                order?.due_date
                                                  ? moment(order?.due_date)
                                                      .add(6, 'h')
                                                      .add(1, 'M')
                                                      .format('DD/MM/YYYY')
                                                  : ''
                                              }
                                              key={`${order.id}RenovaçõesHoje`}
                                              render={({ field }) => (
                                                <Container>
                                                  <InputMask
                                                    style={{
                                                      width: '120px'
                                                    }}
                                                    {...field}
                                                    maskPlaceholder={null}
                                                    mask="99/99/9999"
                                                    disabled={verifyInputs()}
                                                  />
                                                </Container>
                                              )}
                                            />
                                          ) : (
                                            <div></div>
                                          )}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <Controller
                                              name={`${order.id}RenovaçõesHoje.payment_form`}
                                              control={control}
                                              defaultValue={order?.payment_form}
                                              key={`${order.id}RenovaçõesHojePayment`}
                                              render={({ field }) => (
                                                <SelectContainer>
                                                  <select
                                                    {...field}
                                                    disabled={verifyInputs()}
                                                  >
                                                    {optionsPaymentForm.map(
                                                      option => (
                                                        <option
                                                          value={option.value}
                                                          key={option.name}
                                                        >
                                                          {option.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                </SelectContainer>
                                              )}
                                            />
                                          ) : (
                                            <div></div>
                                          )}
                                        </td>
                                        <td>{order?.delivery_date}</td>
                                        <td>{order?.pick_up_date}</td>
                                        <td>{order?.created_at}</td>
                                        <td style={{ textAlign: 'center' }}>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <FontAwesomeIcon
                                              icon={faClipboardList}
                                              style={{ cursor: 'pointer' }}
                                              color="#666"
                                              onClick={() =>
                                                handleOpenModalHistory(order)
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                              }}
                                            >
                                              <LinkContainer>
                                                <a
                                                  href={`https://multfluxosistema.com.br/orcamentos/view/${order.id}`}
                                                  target="_blank"
                                                  className="link"
                                                  rel="noreferrer"
                                                >
                                                  <i
                                                    className="fa fa-search"
                                                    style={{
                                                      cursor: 'pointer'
                                                    }}
                                                  />
                                                </a>
                                              </LinkContainer>
                                              <LinkContainer
                                                className="link"
                                                onClick={() =>
                                                  handleOpenModal(
                                                    order,
                                                    'period'
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-times-circle-o"
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '15.5px'
                                                  }}
                                                />
                                              </LinkContainer>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={9}>
                                        Sem Registros Renovações
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <ContainerButton>
                              <Button
                                type="submit"
                                className="btn dark btn-sm sbold uppercase"
                                disabled={
                                  !selectedOrders['Renovações Hoje'].filter(
                                    (item: any) => item.id
                                  ).length
                                }
                              >
                                PROCESSAR
                              </Button>
                            </ContainerButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form
            onSubmit={handleSubmit(data => {
              setIsActiveAlert({
                ...alert,
                target: { ...data },
                name: `${
                  selectedOrders['Renovações Pendentes'].filter(
                    (item: any) => item.id
                  ).length
                }`,
                isActive: true,
                title: 'Renovações Pendentes'
              })
            })}
          >
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12" style={{ padding: '0 23px' }}>
                    <div className="portlet light bordered">
                      <div className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="row"
                              style={{
                                display: 'flex',
                                alignItems: 'end',
                                paddingBottom: 10
                              }}
                            >
                              <div className="col-md-3">
                                <ListTitle>
                                  <p>
                                    Locação Estendida - Renovações Pendentes
                                  </p>
                                </ListTitle>
                              </div>
                              <div
                                className="col-md-9"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end'
                                }}
                              >
                                <div className="">
                                  <div className="dataTables_filter">
                                    <label
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <p style={{ marginRight: '15px' }}>
                                        Pesquisar
                                      </p>
                                      <Search
                                        onSearch={value =>
                                          onSearchItem(
                                            value,
                                            ordersPending,
                                            'pending'
                                          )
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr style={{ marginBottom: 60 }} />
                            <div className="table-scrollable">
                              <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                                <thead>
                                  <tr>
                                    <th
                                      style={{ padding: '10px' }}
                                      onClick={() =>
                                        handleSelect(
                                          undefined,
                                          'Renovações Pendentes',
                                          markAll['Renovações Pendentes']
                                            ? 'mark'
                                            : 'unmark'
                                        )
                                      }
                                    >
                                      <div
                                        className="check-container form-content col-md-12"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: '100%',
                                          height: '100%',
                                          verticalAlign: 'middle'
                                        }}
                                      >
                                        <input
                                          readOnly
                                          type="checkbox"
                                          ref={markAllPendingRef}
                                        />
                                      </div>
                                    </th>
                                    <TableHeader
                                      headers={headers}
                                      onSorting={(field, order, type) => {
                                        const itemSorted =
                                          searchOrdersPending.sort(
                                            (a: any, b: any) => {
                                              if (
                                                (a.clientName || b.clientName)
                                                  .toLowerCase()
                                                  .includes('total')
                                              ) {
                                                return 0
                                              }
                                              const fields = field.split('.')
                                              let currentFieldA
                                              let currentFieldB
                                              if (fields.length === 1) {
                                                currentFieldA = a[fields[0]]
                                                currentFieldB = b[fields[0]]
                                              }
                                              if (fields.length === 2) {
                                                currentFieldA =
                                                  a[fields[0]]?.[fields[1]]
                                                currentFieldB =
                                                  b[fields[0]]?.[fields[1]]
                                              }
                                              if (fields.length === 3) {
                                                currentFieldA =
                                                  a[fields[0]]?.[fields[1]]?.[
                                                    fields[2]
                                                  ]
                                                currentFieldB =
                                                  b[fields[0]]?.[fields[1]]?.[
                                                    fields[2]
                                                  ]
                                              }
                                              if (type === 'date') {
                                                return new Date(
                                                  convertDateToISO(
                                                    currentFieldA
                                                  )
                                                ) >
                                                  new Date(
                                                    convertDateToISO(
                                                      currentFieldB
                                                    )
                                                  ) && order === 'ASC'
                                                  ? 1
                                                  : -1
                                              }
                                              if (type === 'monetary') {
                                                return Number(
                                                  currentFieldA.replaceAll(
                                                    /[',','.']/gi,
                                                    ''
                                                  )
                                                ) >
                                                  Number(
                                                    currentFieldB.replaceAll(
                                                      /[',','.']/gi,
                                                      ''
                                                    )
                                                  ) && order === 'ASC'
                                                  ? 1
                                                  : -1
                                              }
                                              return currentFieldA >
                                                currentFieldB && order === 'ASC'
                                                ? 1
                                                : -1
                                            }
                                          )
                                        setSearchOrdersPending([...itemSorted])
                                      }}
                                    />
                                    <th style={{ textAlign: 'center' }}>
                                      Histórico
                                    </th>
                                    <th style={{ textAlign: 'center' }}>
                                      Ações
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {searchOrdersPending.length > 1 ? (
                                    searchOrdersPending?.map(order => (
                                      <tr key={Math.random()}>
                                        {!order.clientName?.includes(
                                          'TOTAL'
                                        ) ? (
                                          <td
                                            style={{ padding: '10px' }}
                                            onClick={() =>
                                              handleSelect(
                                                order,
                                                'Renovações Pendentes'
                                              )
                                            }
                                          >
                                            <div
                                              className="check-container form-content col-md-12"
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%'
                                              }}
                                            >
                                              <input
                                                readOnly
                                                type="checkbox"
                                                checked={
                                                  !!selectedOrders[
                                                    'Renovações Pendentes'
                                                  ]?.find(
                                                    (
                                                      orderSelected: OrdersData
                                                    ) =>
                                                      orderSelected?.id ===
                                                      order?.id
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            style={
                                              order.clientName?.includes(
                                                'TOTAL'
                                              )
                                                ? {
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                  }
                                                : {}
                                            }
                                          >
                                            {order?.clientName}
                                          </td>
                                        )}
                                        <td
                                          style={
                                            order.clientName?.includes('TOTAL')
                                              ? {
                                                  fontWeight: 'bold'
                                                }
                                              : {}
                                          }
                                        >
                                          {order.clientName?.includes('TOTAL')
                                            ? searchOrdersPending.length - 1
                                            : order.id}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes('TOTAL')
                                            ? order?.clientName
                                            : ''}
                                        </td>
                                        <td
                                          style={
                                            order.clientName?.includes('TOTAL')
                                              ? { fontWeight: 'bold' }
                                              : {}
                                          }
                                        >
                                          {order?.total_value}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <Controller
                                              render={({ field }) => (
                                                <Container>
                                                  <InputMask
                                                    style={{
                                                      width: '120px'
                                                    }}
                                                    {...field}
                                                    mask="99/99/9999"
                                                  />
                                                </Container>
                                              )}
                                              name={`${order.id}RenovaçõesPendentes.due_date`}
                                              control={control}
                                              defaultValue={
                                                order?.due_date
                                                  ? moment(order?.due_date)
                                                      .add(6, 'hour')
                                                      .add(1, 'M')
                                                      .format('DD/MM/YYYY')
                                                  : ''
                                              }
                                            />
                                          ) : (
                                            <div></div>
                                          )}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <Controller
                                              name={`${order.id}RenovaçõesPendentes.payment_form`}
                                              control={control}
                                              defaultValue={order?.payment_form}
                                              key={`${order.id}RenovaçõesPendentesPayment`}
                                              render={({ field }) => (
                                                <SelectContainer>
                                                  <select {...field}>
                                                    {optionsPaymentForm.map(
                                                      option => (
                                                        <option
                                                          value={option.value}
                                                          key={option.name}
                                                        >
                                                          {option.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                </SelectContainer>
                                              )}
                                            />
                                          ) : (
                                            <div></div>
                                          )}
                                        </td>
                                        <td>{order?.delivery_date}</td>
                                        <td>{order?.pick_up_date}</td>
                                        <td>{order?.created_at}</td>
                                        <td style={{ textAlign: 'center' }}>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <FontAwesomeIcon
                                              icon={faClipboardList}
                                              style={{ cursor: 'pointer' }}
                                              color="#666"
                                              onClick={() =>
                                                handleOpenModalHistory(order)
                                              }
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td>
                                          {!order.clientName?.includes(
                                            'TOTAL'
                                          ) ? (
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                              }}
                                            >
                                              <LinkContainer>
                                                <a
                                                  href={`https://multfluxosistema.com.br/orcamentos/view/${order.id}`}
                                                  target="_blank"
                                                  className="link"
                                                  rel="noreferrer"
                                                >
                                                  <i
                                                    className="fa fa-search"
                                                    style={{
                                                      cursor: 'pointer'
                                                    }}
                                                  />
                                                </a>
                                              </LinkContainer>
                                              <LinkContainer
                                                className="link"
                                                onClick={() =>
                                                  handleOpenModal(
                                                    order,
                                                    'pending'
                                                  )
                                                }
                                              >
                                                <i
                                                  className="fa fa-times-circle-o"
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontSize: '15.5px'
                                                  }}
                                                />
                                              </LinkContainer>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={9}>
                                        Sem Renovações Pendentes
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <ContainerButton>
                              <Button
                                type="submit"
                                className="btn dark btn-sm sbold uppercase"
                                disabled={
                                  !selectedOrders[
                                    'Renovações Pendentes'
                                  ].filter((item: any) => item.id).length
                                }
                              >
                                PROCESSAR
                              </Button>
                            </ContainerButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* <div className="page-content-inner">
            <div className="mt-content-body">
              <div className="row">
                <div className="col-md-12" style={{ padding: '0 23px' }}>
                  <div className="portlet light bordered">
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <ListTitle>
                            <p>Tarefas</p>
                            <hr style={{ paddingBottom: 10 }} />
                          </ListTitle>
                          <div className="table-scrollable">
                            <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                              <thead>
                                <tr>
                                  <th>Pedido</th>
                                  <th>Cliente</th>
                                  <th>Data</th>
                                  <th>Expiração</th>
                                  <th>Solicitante</th>
                                  <th>Solicitado</th>
                                  <th style={{ textAlign: 'center' }}>Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {task?.map(task => (
                                  <tr key={Math.random()}>
                                    <td>{task?.id}</td>
                                    <td>{task?.client.name}</td>
                                    <td>{task?.date}</td>
                                    <td>{task?.expiration}</td>
                                    <td>{task?.requester}</td>
                                    <td>{task?.requested}</td>
                                    <td>
                                      <LinkContainer>
                                        <i
                                          className="fa fa-search"
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </LinkContainer>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {modalOrderPeriod && (
        <Modal
          refModal={refModalPeriod}
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalOrderPeriod}
          pageTitle={'Cancelamento de Locação'}
          Children={
            <FormOrder
              typeForm="update"
              initialValues={{
                idUpdate: Number(currentOrderCancellPeriod.id),
                ...currentOrderCancellPeriod
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(currentOrderCancellPeriod.id)
              }}
            />
          }
        />
      )}
      {modalOrderPending && (
        <Modal
          refModal={refModalPending}
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalOrderPending}
          pageTitle={'Cancelamento de Locação'}
          Children={
            <FormOrder
              typeForm="update"
              initialValues={{
                idUpdate: Number(currentOrderCancellPending.id),
                ...currentOrderCancellPending
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(currentOrderCancellPending.id)
              }}
            />
          }
        />
      )}
      {modalOrderHistory && (
        <Modal
          refModal={refModalPending}
          onClickButtonCancel={() => setModalOrderHistory(false)}
          isOpenModal={modalOrderHistory}
          pageTitle={`Historico do Nº Pedido ${currentOrderHistory.id}`}
          styles={{ padding: '30px' }}
          Children={
            <DataTable
              source={'orderHistory'}
              entity="OrderHistory"
              format={{ orderBy: 'created_at' }}
              orderBySort="DESC"
              notHasChildren
              headers={historyHeaders}
              searchParameters={parameters}
            />
          }
        />
      )}
      <Alert
        message={`Confirma a renovação de ${alert.name} pedidos ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        isActive={alert.isActive}
      />
    </ContainerWrapper>
  )
}
export default List
