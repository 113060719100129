import React from 'react'
import TaxSituationList from '../../pages/Taxes/Situation/List'
import TaxSituationCreate from '../../pages/Taxes/Situation/Create'
import TaxSituationUpdate from '../../pages/Taxes/Situation/Update'
import TaxSituationView from '../../pages/Taxes/Situation/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const TaxSituationRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/taxes/taxSituation" exact component={TaxSituationList} />
    <Route
      path="/taxes/taxSituation/create"
      component={TaxSituationCreate}
      exact
    />
    <Route
      path="/taxes/taxSituation/view/:id"
      component={TaxSituationView}
      exact
    />
    <Route
      path="/taxes/taxSituation/update/:id"
      component={TaxSituationUpdate}
      exact
    />
  </CustomSwitch>
)
