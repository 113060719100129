import React, { useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import api from '../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import '../../../../../assets/global/plugins/datatables/datatables.min.css'
import { LinkContainer } from './style'
import { genericMaskWithTwoZero } from '../../../../../utlis/mask'
import Modal from '../../../../../components/Modal'
interface Action {
  name: string
  icon: string
  title: string
}

interface Header {
  name: string
  field: string
  sortable: boolean
}

interface SearchParameters {
  [key: string]: string
}

interface HistoryProduct {
  old_price: string
  updated_at: string
  name: string
}

interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  products: any[]
  source: string
  headers?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  onlyParent?: boolean
  parentId?: string
  entityId?: string
  searchParameters?: SearchParameters[]
  format: {
    orderBy: string
  }
}

const DataTable = ({
  onActions,
  products,
  source,
  notHasChildren,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  actions
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>(products)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterItems, setFilterItems] = useState(products)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalItems, setTotalItems] = useState(items.length)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(200)
  const [currentProductName, setCurrentProductName] = useState('')
  const [historyProducts, setHistoryProducts] = useState<HistoryProduct[]>([])
  const [toggleModal, setToggleModal] = useState(false)
  const history = useHistory()

  const handlerOnClickButtonList = (currentValue: any) => {
    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      history.push(`/${source}/view/${currentValue.id}`, {
        id: currentValue.id,
        value: currentValue.name
      })
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    if (onActions?.onClickButtonEdit) {
      onActions.onClickButtonEdit(currentValue)
    } else {
      history.push(`/${source}/update/${currentValue.id}`, {
        id: currentValue.id,
        value: currentValue.name
      })
    }
  }

  const handlerOnClickButtonRemove = (currentValue: any) => {
    if (onActions?.onClickButtonRemove) {
      onActions.onClickButtonRemove(currentValue)
    }
  }

  const handleOpenModal = useCallback(
    (name: string, productsHistory: HistoryProduct[]) => {
      setHistoryProducts(productsHistory)
      setCurrentProductName(name)
      setToggleModal(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyProducts, toggleModal]
  )

  const firstItem =
    totalItems === 0 ? totalItems : ItemsPerPage * (currentPage - 1) + 1

  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      if (notHasChildren) {
        sum = items.reduce((sum, value) => {
          if (!value.parent_id) {
            return sum + 1
          }
          return sum
        }, 0)
      } else {
        sum = items.length
      }
    }
    return sum
  }

  const onSearchItem = (value: string) => {
    if (value.length === 0) {
      setItems(filterItems)
      return
    }
    if (items && items.length > 0) {
      const itemsFilter = items.filter(({ name }) =>
        name.toLowerCase().includes(value.toLowerCase())
      )
      setItems(itemsFilter)
      return
    }
    setItems(filterItems)
  }

  return (
    <div className="dataTables_wrapper no-footer">
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_length">
            <label>
              <select
                onChange={e => setItemsPerPage(Number(e.target.value))}
                className="form-control input-sm input-xsmall input-inline"
                defaultValue="200"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              resultados por página
            </label>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_filter">
            <label>
              Pesquisar
              <Search onSearch={value => onSearchItem(value)} />
            </label>
          </div>
        </div>
      </div>
      <div className="table-scrollable">
        <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
          <TableHeader
            headers={headers}
            onSorting={() => {
              const itemSorted = items.sort(() => -1)
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                return !notHasChildren ? (
                  <tr key={item.id}>
                    {headers.map(
                      header =>
                        (header.field !== 'actions' && (
                          <td key={`${header.field}-${item.id}`}>
                            <p
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              {typeof item[header.field] === 'boolean'
                                ? item[header.field]
                                  ? 'Sim'
                                  : 'Não'
                                : item[header.field]}
                            </p>
                          </td>
                        )) || (
                          <td key={`actions-${item.id}`} className="actions">
                            {(actions &&
                              actions.map(action => (
                                <Link
                                  key={Math.random()}
                                  title="Visualizar"
                                  to={`/${source}/${action.name}/${item.id}`}
                                >
                                  <span className={action.icon} />
                                </Link>
                              ))) || (
                              <LinkContainer>
                                {!2 && (
                                  <div
                                    className="link"
                                    key={Math.random()}
                                    title="Visualizar"
                                    onClick={() =>
                                      handlerOnClickButtonList(item)
                                    }
                                  >
                                    <span className="fa fa-search" />
                                  </div>
                                )}
                                <div>
                                  <div
                                    className="link"
                                    key={Math.random()}
                                    title="Editar"
                                    onClick={() => {
                                      handlerOnClickButtonEdit(item)
                                    }}
                                  >
                                    <span className="fa fa-edit" />
                                  </div>
                                  <div
                                    key={Math.random()}
                                    title="Remover"
                                    className="link"
                                    onClick={() => {
                                      handlerOnClickButtonRemove(item)
                                    }}
                                  >
                                    <span className="fa fa-remove" />
                                  </div>
                                </div>
                              </LinkContainer>
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ) : (
                  true && (
                    <tr key={item.id}>
                      {headers.map(
                        header =>
                          (header.field !== 'actions' && (
                            <td key={`${header.field}-${item.id}`}>
                              <p
                                style={{
                                  textAlign: 'left'
                                }}
                              >
                                {typeof item[header.field] === 'boolean'
                                  ? item[header.field]
                                    ? 'Sim'
                                    : 'Nâo'
                                  : item[header.field]}
                              </p>
                            </td>
                          )) || (
                            <td key={`actions-${item.id}`} className="actions">
                              {(actions &&
                                actions.map(action => (
                                  <Link
                                    key={Math.random()}
                                    title="Visualizar"
                                    to={`/${source}/${action.name}/${1}`}
                                  >
                                    <span className={action.icon} />
                                  </Link>
                                ))) || (
                                <LinkContainer>
                                  {true && (
                                    <div
                                      className="link"
                                      key={Math.random()}
                                      title="Visualizar"
                                      onClick={() => {
                                        handleOpenModal(item.name, item.history)
                                      }}
                                    >
                                      <span className="fa fa-search" />
                                    </div>
                                  )}
                                  {/* <div
                                    className="link"
                                    key={Math.random()}
                                    title="Editar"
                                    onClick={() => {
                                      handlerOnClickButtonEdit(item)
                                    }}
                                  >
                                    <span className="fa fa-edit" />
                                  </div> */}
                                </LinkContainer>
                              )}
                            </td>
                          )
                      )}
                    </tr>
                  )
                )
              })) || (
              <tr>
                <td colSpan={headers.length}>Nenhum registro encontrado</td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-5">
          <div className="dataTables_info">
            Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
            {totalItems} registros
          </div>
        </div>
        <div className="col-md-7 col-sm-7">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpenModal={toggleModal}
        onClickButtonCancel={() => setToggleModal(false)}
        pageTitle="Histórico"
        Children={
          historyProducts ? (
            <div className="dataTables_wrapper no-footer">
              <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                <tbody>
                  <tr>
                    <th>Produto</th>
                    <th>Preço</th>
                    <th>Atualizado em</th>
                  </tr>
                  {historyProducts.map(item => (
                    <tr key={Math.random() * 999}>
                      <td>{currentProductName}</td>
                      <td
                        style={{
                          textAlign: 'left'
                        }}
                      >
                        {/* {genericMaskWithTwoZero('1.00')} */}
                        {genericMaskWithTwoZero(item.old_price)}
                      </td>
                      <td
                        style={{
                          textAlign: 'left'
                        }}
                      >
                        {item.updated_at}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <h3>Seu produto ainda não sofreu alteração</h3>
            </div>
          )
        }
      />
    </div>
  )
}

export default DataTable
