import styled from 'styled-components'

export const FilterContaier = styled.div`
  width: 100%;
  padding-left: 0;
  float: none;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
  }
  select {
    font-size: 13;
    padding: 5;
  }

  footer {
    margin-bottom: 20px;
    align-self: flex-end;

    button {
      margin: 0 0.5rem;
      padding: 0.5rem 1rem;

      background: #fff;
      border: solid 1px #ccc;
      font-size: 12px;
      transition: all 0.2s;

      &:hover {
        background: #f2f2f2;
      }
    }

    hr {
      margin-top: 10px !important;
    }
  }
`
export const ListTitle = styled.div`
  p {
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 10px;
    color: #666;
  }

  hr {
    margin-bottom: 10px;
  }
`
