import React from 'react'

import { FreightFields } from './freightFields'

export const FreightTab = (): JSX.Element => {
  return (
    <>
      <h4>Venda</h4>
      <FreightFields type={'sale'} />
      <hr />
      <h4>Locação</h4>
      <FreightFields type={'location'} />
    </>
  )
}
