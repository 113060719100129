import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/processingErrors/List'
import Create from '../../pages/processingErrors/Create'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ProcessingErrorsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/processingErrors" exact component={List} />
    <PrivateRoutes path="/processingErrors/create" component={Create} exact />
  </CustomSwitch>
)
