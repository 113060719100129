import React, { useEffect } from 'react'
import {
  Container,
  ContentItem,
  RenderComponent,
  TabHeaderContainer,
  TabName,
  TabPanelContainer
} from './styles'
import { useTabs } from '../../../../../../../hooks/tabs'
import { makeTabs } from './tabs'

export type TypeContentTabs = {
  name: string
  label: string
  isEnable: boolean
  Component?: JSX.Element
}

export const Content = (): JSX.Element => {
  const { tabs, addTab, currentTab, changeCurrentTab } = useTabs()

  useEffect(() => {
    async function load() {
      const tabs = await makeTabs()
      tabs.map(tab => addTab(tab))
      changeCurrentTab(tabs[0].name)
    }
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container>
        <ContentItem>
          <TabHeaderContainer>
            {tabs.map(
              ({ label, name, isEnable }, index) =>
                isEnable && (
                  <TabName
                    key={index}
                    onClick={() => changeCurrentTab(name)}
                    isActive={name === currentTab.key}
                  >
                    {label}
                  </TabName>
                )
            )}
          </TabHeaderContainer>
          <TabPanelContainer>
            <>
              {tabs.map(({ Component, name }) => (
                <RenderComponent key={name} isActive={name === currentTab.key}>
                  {Component}
                </RenderComponent>
              ))}
            </>
          </TabPanelContainer>
        </ContentItem>
      </Container>
    </>
  )
}
