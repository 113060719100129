import React from 'react'

import { PaymentMethodFields } from './paymentFields'

export const PaymentMethodTab = (): JSX.Element => {
  return (
    <>
      <h4>Venda</h4>
      <PaymentMethodFields index={0} />
      <hr />
      <h4>Locação</h4>
      <PaymentMethodFields index={1} />
    </>
  )
}
