import React, { useState } from 'react'
import Container from '../../../components/Container'
import DataTable from '../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useHistory } from 'react-router'
import api from '../../../services/api'
import { Alert } from '../../../components/Alert'
import { useUpdateDataTable } from '../../../hooks/dataTable'

const ExpenseBudgetList = (): JSX.Element => {
  const history = useHistory()

  const [alert, setAlert] = useState(false)
  const [currentItem, setCurrentItem] = useState(0)

  const { updateDataTable } = useUpdateDataTable()

  const handleEdit = (item: any) => {
    history.push(`/commercial/goalsEmployees/update?year=${item.year}`)
  }
  const handleDelete = async (item: any) => {
    setAlert(true)
    setCurrentItem(item.id)
  }
  const handleDeleteButtonAlert = async () => {
    await api.delete(`commercial/goals/delete/${currentItem}`)
    setAlert(false)
    updateDataTable()
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Listagem'}
        breadcrumb={breadcrumbList}
        tools={[toolsList]}
      >
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'id' }}
          orderByField=""
          notHasChildren
          headers={headers}
          actions={[
            {
              name: 'edit',
              title: 'Editar',
              spanIcon: 'fa fa-edit',
              onClick: handleEdit
            },
            {
              name: 'delete',
              title: 'Excluir',
              spanIcon: 'fa fa-remove',
              onClick: handleDelete
            }
          ]}
        />
      </Container>
      <Alert
        message={'Deseja excluir esse registro?'}
        onClickCancellButton={() => setAlert(false)}
        onClickConfirmButton={handleDeleteButtonAlert}
        isActive={alert}
      />
    </>
  )
}
export default ExpenseBudgetList
