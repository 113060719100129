import React, { useCallback, useEffect, useState } from 'react'

import { Container } from './styles'
import api from '../../../../../../services/api'
import { useClient } from '../../providers/ClientProvider'
import { useLoading } from '../../../../../../hooks/loading'
import CustomDataTable from '../../../../../../components/CustomDataTable'
import { Alert } from '../../../../../../components/Alert'
import { useToast } from '../../../../../../hooks/toast'

const HEADERS_PRODUCTS = [
  { name: 'Cod.', field: 'code', sortable: false },
  { name: 'Data', field: 'created_at', sortable: false },
  { name: 'Logomarca', field: 'image_logo_url', sortable: false, custom: true },
  { name: 'Simulação', field: 'image_url', sortable: false, custom: true },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]

export default function ImageTab() {
  const { initialValues } = useClient()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [alertDelete, setAlertDelete] = useState({
    id: '',
    active: false
  })
  const [apiBudgets, setApiBudgets] = useState<{
    id: number
    products: {
      name: string
      code: string
      quantity: number
      image_url: string
      image_logo_url: string
      image_filename: string
    }[]
  }>(null)

  const getClientApiBudget = useCallback(async () => {
    if (!initialValues?.person?.cnpj_cpf) {
      return
    }
    activeLoading()
    try {
      const responseApiBudget = await api.post(
        '/commercial/apiBudget/getByCnpj',
        {
          cnpj: initialValues?.person?.cnpj_cpf
        }
      )
      const products: any[] = []
      if (responseApiBudget.data?.products?.length) {
        for (const product of responseApiBudget.data?.products) {
          products.push({
            ...product,
            created_at: responseApiBudget.data.created_at
          })
        }
      }
      setApiBudgets({ ...responseApiBudget.data, products })
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading, initialValues?.person])

  useEffect(() => {
    getClientApiBudget()
  }, [getClientApiBudget])

  const handleCloseDelete = useCallback(async () => {
    setAlertDelete({
      id: '',
      active: false
    })
    await getClientApiBudget()
  }, [getClientApiBudget])

  const handleConfirmDelete = useCallback(async () => {
    if (!apiBudgets) {
      return
    }
    const products = apiBudgets.products.filter(
      item => item.code !== alertDelete.id
    )
    try {
      await api.put(`/commercial/apiBudget/update/${apiBudgets.id}`, {
        ...apiBudgets,
        products
      })
      addToast({
        title: 'Removido com sucesso',
        description: 'Removido com sucesso',
        type: 'success'
      })
    } catch (error) {
      addToast({
        title: 'Erro ao remover',
        description: 'Erro ao remover',
        type: 'error'
      })
    }
    handleCloseDelete()
  }, [addToast, alertDelete.id, apiBudgets, handleCloseDelete])

  return (
    <Container>
      <CustomDataTable
        customItems={apiBudgets?.products || []}
        headers={HEADERS_PRODUCTS}
        customHeaders={[
          {
            name: 'Logomarca',
            field: 'image_logo_url',
            sortable: false,
            element: item => {
              if (!item.image_logo_url) {
                return <p>Sem imagem de logo</p>
              }
              return (
                <a target="_blank" href={item.image_logo_url} rel="noreferrer">
                  Visualizar
                </a>
              )
            }
          },
          {
            name: 'Simulação',
            field: 'image_url',
            sortable: false,
            element: item => {
              if (!item.image_url) {
                return <p>Sem imagem de simulação</p>
              }
              return (
                <a target="_blank" href={item.image_url} rel="noreferrer">
                  Visualizar
                </a>
              )
            }
          },
          {
            name: 'Ações',
            field: 'actions',
            sortable: false,
            element: item => {
              return (
                <span
                  style={{ cursor: 'pointer' }}
                  className="fa fa-times"
                  onClick={() => {
                    setAlertDelete({
                      id: item.code,
                      active: true
                    })
                  }}
                ></span>
              )
            }
          }
        ]}
      />

      <Alert
        message={`Deseja realmente remover o item ${alertDelete?.id}`}
        onClickCancellButton={handleCloseDelete}
        onClickConfirmButton={handleConfirmDelete}
        isActive={alertDelete.active}
      />
    </Container>
  )
}
