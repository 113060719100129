import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../../services/api'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select } from '../../../../../../components/Form'
import Button from '../../../../../../components/Button'

type CostCentersData = {
  name: string
  dashboard: number
}

type TypesFormProps = {
  initialValues?: CostCentersData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormStatus = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<CostCentersData>()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        dashboard: initialValues.dashboard ? 1 : 0
      })
    }
  }, [initialValues])

  const onSubmit = async (data: CostCentersData) => {
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data
          }
          activeLoading()
          await api.post(apiCreate(), dataCreate)
          disableLoading()
          history.push(nameActions.read.to)
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
          updateDataTable()
        }
      } else {
        const dataUpdate = {
          ...data
        }
        const id = initialValues?.idUpdate

        try {
          activeLoading()
          await api.put(apiUpdate(String(id)), dataUpdate)
          updateDataTable()
          disableLoading()
          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (error) {
          history.push(nameActions.read.to)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <div className="row">
        <div className="col-md-4">
          <Input
            label="Nome"
            name="name"
            className="form-control"
            rules={{ required: true }}
          />
        </div>
        <div className="col-md-1">
          <Input label="Prazo" name="deadline_days" className="form-control" />
        </div>
        <div className="col-md-1">
          <Input label="Posição" name="position" className="form-control" />
        </div>
        <div className="col-md-2">
          <Select
            options={[
              { name: 'Sim', value: 1 },
              { name: 'Não', value: 0 }
            ]}
            label="Dashboard"
            name="dashboard"
            className="form-control"
            blank
            defaultValue={''}
          />
        </div>
        <div className="col-md-2">
          <Input label="Icone" name="dashboard_icon" className="form-control" />
        </div>
        <div className="col-md-2">
          <Select
            options={[
              { name: 'Selecione', value: '' },
              { name: 'Reservado', value: 'reserved' },
              { name: 'Realizado', value: 'realized' },
              { name: 'Cancelado', value: 'canceled' }
            ]}
            label="Status movimentação de stock"
            name="stock_moviment_status"
            className="form-control"
            blank
            defaultValue={''}
          />
        </div>
      </div>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
