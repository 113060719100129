import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  table {
  }
  .disable {
    display: none;
  }

  margin-top: 20px !important;
`
