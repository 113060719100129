import styled from 'styled-components'
import { Input } from '../../../../../../components/FormProvider'

export const Container = styled.div`
  .disable {
    display: none;
  }
  margin-top: 20px !important;
`

export const RadioInput = styled(Input)`
  display: inline-block;
  margin-right: 5px !important;
  margin-bottom: 20px !important;
  &:nth-of-type(2) {
    margin-left: 15px !important;
  }
`
