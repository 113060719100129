import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, nameEntity, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'

interface ClientEconomicGroupData {
  id: number
  name: string
  created_at: string
  updated_at: string
}

const SegmentsView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [clientEconomicGroup, setClientEconomicGroup] =
    useState<ClientEconomicGroupData | null>(null)
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadClientEconomicGroup(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ClientEconomicGroupData>(apiList(id))
        const { data } = response
        setClientEconomicGroup(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a origem',
          description:
            'Houve um error ao carregar a origem, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadClientEconomicGroup()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemove = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Origem removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Origem não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemove = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{clientEconomicGroup?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{clientEconomicGroup?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{clientEconomicGroup?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{clientEconomicGroup?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                {[
                  <Tab key={0} title="Histórico">
                    <div className="portlet light">
                      <div className="portlet-title">
                        <div className="caption">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>
                ]}
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${clientEconomicGroup?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemove}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemove(Number(clientEconomicGroup?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default SegmentsView
