import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle, nameActions, WarehouseRequest } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useParams } from 'react-router-dom'
import { FormWarehouseRequest } from '../components/Form'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { ProductProvider } from '../providers/product/ProductProvider'

const Update: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [warehouseRequest, setWarehouseRequests] = useState<WarehouseRequest>()
  useEffect(() => {
    async function loadData() {
      activeLoading()
      const response = await api.get<{
        warehouseRequest: WarehouseRequest
      }>(apiList(id))
      const { data } = response
      const products = data.warehouseRequest.warehouseRequestsProducts.map(
        a => ({
          ...a,
          product_id: a.stock.product.id,
          product_name: a.stock.product.name
        })
      )

      setWarehouseRequests({
        ...data.warehouseRequest,
        warehouseRequestsProducts: products
      })
      disableLoading()
    }

    loadData()
  }, [activeLoading, disableLoading, id])

  return (
    <>
      <ProductProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.update.name}
          breadcrumb={breadcrumbUpdate}
          tools={[toolsUpdate]}
        >
          <div className="form body">
            <FormWarehouseRequest
              typeForm="update"
              warehouseRequest={warehouseRequest}
            />
          </div>
        </Container>
      </ProductProvider>
    </>
  )
}
export default Update
