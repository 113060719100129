import React, { useEffect, useState } from 'react'
import { Container, FooterStyled, IconRemove } from './style'

import { useProduct } from '../../../providers/product/ProductProvider'
import { Input } from '../../../../../../components/Form'
import { SearchComponent } from '../SearchComponent'
import api from '../../../../../../services/api'
import { genericMaskWithTwoZero } from '../../../../../../utlis/mask'

type ProductListType = {
  id: number
  name: string
}
export const Table = (): JSX.Element => {
  const { composition, setComposition } = useProduct()
  const [productList, setProductList] = useState<ProductListType[]>([])
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<ProductListType[]>('warehouse/stocks')
      setProductList(data)
    })()
  }, [])

  // const [total, setTotal] = useState(0)

  // useEffect(() => {
  //   let soma = 0
  //   for (let i = 0; i < composition.length; i++) {
  //     const subtotal =
  //       Number(composition[i].amount) * Number(composition[i].cost)
  //     soma += subtotal
  //   }
  //   setTotal(soma)
  // }, [composition])

  return (
    <Container className="table-responsive">
      <div className="table-content-relative">
        <table className="table table-bordered margin-bottom-0">
          <tbody>
            <tr>
              <th style={{ width: '50%' }}>Produto</th>
              <th>Custo</th>
              <th>Ações</th>
            </tr>
            {composition &&
              composition.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    height: '10px'
                  }}
                >
                  <td>
                    {SearchComponent({
                      index,
                      composition,
                      setComposition,
                      productList,
                      selected,
                      setSelected
                    })}
                  </td>
                  {/* <td>
                    <Input
                      name={`composition.${index}.amount`}
                      placeholder="0"
                      className="form-control"
                      type="text"
                      onChange={({ target }) =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].amount = Number(target.value)
                          return copy
                        })
                      }
                    />
                  </td> */}
                  <td>
                    <Input
                      name={`composition.${index}.cost`}
                      placeholder="0.00"
                      className="form-control"
                      type="text"
                      value={item.prices}
                      onChange={({ target }) =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy[index].prices = genericMaskWithTwoZero(
                            target.value
                          )
                          // copy[index].prices = (convertValueMaskInNumber(target.value)).toString()
                          // copy[index].prices = `${convertValueMaskInNumberWithTwoZero(target.value)}`
                          // copy[index].prices = Number(target.value)
                          // copy[index].prices = Number(target.value)
                          return copy
                        })
                      }
                    />
                  </td>
                  {/* <td>
                    <Input
                      name={`composition.${index}.subtotal`}
                      placeholder="0.00"
                      className="form-control"
                      type="text"
                      value={amount * cost}
                      controlled
                      disabled
                    />
                  </td> */}
                  <input type="hidden" name="product_id" />
                  <input type="hidden" name="stock_id" />
                  <td className="actions">
                    <IconRemove
                      onClick={() =>
                        setComposition(prev => {
                          const copy = [...prev]
                          copy.splice(index, 1)
                          return copy
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <hr />
      <FooterStyled>
        <a
          onClick={() =>
            setComposition(prev => {
              const copy = [...prev]
              const final = [...copy, { id: 0, name: '', prices: '0.00' }]
              return final
            })
          }
          className="btn dark btn-sm sbold uppercase"
        >
          <span
            className="fa fa-plus"
            aria-hidden="true"
            style={{ marginRight: '5px' }}
          />
          produto
        </a>
        {/* <div>
          <h4>Total</h4>
          <h6>{total.toFixed(2)}</h6>
        </div> */}
      </FooterStyled>
    </Container>
  )
}
