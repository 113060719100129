import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Warehouse/WarehouseRegister/List'
import Update from '../../pages/Warehouse/WarehouseRegister/Update'
import Create from '../../pages/Warehouse/WarehouseRegister/Create'
import View from '../../pages/Warehouse/WarehouseRegister/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const WarehouseRegisterRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/warehouse" exact component={List} />
    <PrivateRoutes path="/warehouse/update/:id" exact component={Update} />
    <PrivateRoutes path="/warehouse/create" exact component={Create} />
    <PrivateRoutes path="/warehouse/view/:id" exact component={View} />
  </CustomSwitch>
)
