import React from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'

const List: React.FC = () => {
  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'name' }}
        notHasChildren
        headers={headers}
        customHeaders={[
          {
            field: 'start_date',
            name: 'Vigência',
            sortable: false,
            element: item => (
              <span>{`${item.start_date.substring(
                0,
                10
              )} à ${item.end_date.substring(0, 10)}`}</span>
            )
          },
          {
            field: 'type',
            name: 'Tipo',
            sortable: false,
            element: item => (
              <span>
                {item.type === 'location'
                  ? 'Locação'
                  : item.type === 'sale'
                  ? 'Venda'
                  : ''}
              </span>
            )
          }
        ]}
      />
    </Container>
  )
}
export default List
