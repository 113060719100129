import React, { useCallback, useEffect, useState } from 'react'

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import FullCalendar from '@fullcalendar/react' // must go before plugins
import moment from 'moment'
import Modal from '../../../../components/Modal'
import { FormAppointment } from '../components/AppointmentForm'
import api from '../../../../services/api'
import { useAuth } from '../../../../hooks/auth'
import { nameActions, namePageTitle } from '../domain/info'
import { breadcrumbAppointments } from '../domain/breadcrumb/appointments'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import { TooltipText } from '../../../../components/TooltipText'
import { toolsViewList } from '../../Clients/domain/tools'

type Event = {
  id: string
  date: string
  title: string
  extendedProps: Appointment
}

export type Appointment = {
  id?: number
  parent_id?: number
  client?: { id: number; person?: { name?: string } }
  date: string
  time?: string
  action?: 'call' | 'email' | 'message' | 'visit'
  description?: string
  user?: { id: number; name: string }
  appointment_result?: string
}

export type ModalProps =
  | {
      open: true
      type: 'create' | 'update'
      initialValues: Appointment
    }
  | {
      open: false
      type?: null
      initialValues?: null
    }

export const APPOINTMENT_ACTION_MAP = {
  call: 'Ligação',
  email: 'Email',
  message: 'Mensagem',
  visit: 'Visita'
}

export const APPOINTMENT_ACTION_COLOR_MAP = {
  call: '#0077b6',
  email: '#fb8500',
  message: '#588157',
  visit: '#9d4edd'
}

export default function AppointmentsPage() {
  const { user } = useAuth()

  const [agentAppointments, setAgentAppointments] = useState<Appointment[]>([])
  const [events, setEvents] = useState<Event[]>([])

  const [modal, setModal] = useState<ModalProps>({
    open: false
  })

  const { activeLoading, disableLoading } = useLoading()

  const getAgentAppointments = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get<Appointment[]>(
        `commercial/clientsAppointments/listByUser/${user.id}`
      )
      setAgentAppointments(data)
      disableLoading()
    } catch (error) {
      disableLoading()
    }
  }, [activeLoading, disableLoading, user])

  useEffect(() => {
    getAgentAppointments()
  }, [getAgentAppointments])

  useEffect(() => {
    const events = agentAppointments.map(appointment => {
      const dateFormated = moment(appointment.date, 'DD/MM/YYYY HH:mm:ss')
        .add(3, 'h')
        .format('YYYY-MM-DD')
      let backgroundColor = APPOINTMENT_ACTION_COLOR_MAP[appointment.action]

      if (
        moment().startOf('D').isAfter(moment(dateFormated).startOf('D')) &&
        !appointment.appointment_result
      ) {
        backgroundColor = 'red'
      }

      return {
        id: String(appointment.id),
        title: `[${APPOINTMENT_ACTION_MAP[appointment.action]}] ${
          appointment.client?.person?.name
        } - ${appointment.description}`,
        date: dateFormated,
        backgroundColor: backgroundColor,
        extendedProps: { ...appointment, date: dateFormated },
        borderColor: '#fff'
      }
    })
    setEvents(events)
  }, [agentAppointments])

  const week = document.getElementsByClassName('fc-dayGridWeek-button')[0]
  const month = document.getElementsByClassName('fc-dayGridMonth-button')[0]
  const controls = document.getElementsByClassName('fc-button')

  if (controls) {
    Array.from(controls).forEach(control => {
      control.setAttribute('style', 'background: #444444')
    })
  }

  if (week) {
    week.setAttribute(
      'style',
      'width: 85px;overflow: hidden;white-space: nowrap; word-spacing: 20px; background: #444444'
    )
    week.textContent = 'Semana '
  }
  if (month) {
    month.setAttribute(
      'style',
      'width: 55px;overflow: hidden;white-space: nowrap; word-spacing: 20px; background: #444444'
    )
    month.innerHTML = 'Mês '
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.appointments.name}
        breadcrumb={breadcrumbAppointments}
        tools={[toolsViewList()]}
      >
        <div
          className="row"
          style={{ padding: '2rem 4rem', textTransform: 'uppercase' }}
        >
          <FullCalendar
            plugins={[dayGridPlugin]}
            locale="pt"
            initialView="dayGridMonth"
            timeZone="-03:00"
            events={events}
            eventContent={info => (
              <div
                className="fc-event-main-frame"
                onClick={() => {
                  setModal({
                    open: true,
                    type: 'update',
                    initialValues: info.event.extendedProps as Appointment
                  })
                }}
              >
                <div className="fc-event-title-container">
                  <span
                    style={{
                      textDecoration: 'none',
                      whiteSpace: 'normal',
                      margin: '5px 0px',
                      color: '#fff'
                    }}
                    className="fc-event-title fc-sticky"
                  >
                    {info.event.extendedProps.appointment_result ? (
                      <TooltipText
                        label={info.event.title}
                        message={info.event.extendedProps.appointment_result}
                      />
                    ) : (
                      info.event.title
                    )}
                  </span>
                </div>
              </div>
            )}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridWeek,dayGridMonth'
            }}
            viewHeight="100px"
            contentHeight={'70vh'}
          />
        </div>
      </Container>
      {modal.open && (
        <Modal
          isOpenModal={modal.open}
          pageTitle={`Agendamento - ${modal.initialValues?.client?.person?.name}`}
          onClickButtonCancel={() => {
            setModal({
              open: false
            })
          }}
          Children={
            <FormAppointment
              typeForm={modal.type}
              initialValues={modal.initialValues}
              setModal={setModal}
              getAppointments={getAgentAppointments}
            />
          }
        />
      )}
    </>
  )
}
