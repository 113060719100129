export const headers = [
  { name: 'Nome', field: 'localization', sortable: true },
  { name: 'UF', field: 'code', sortable: true },
  { name: 'Percentual', field: 'percentage', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const viewHaders = [
  { name: 'Nome', field: 'localization', sortable: true },
  { name: 'Código IBGE', field: 'code', sortable: true },
  { name: 'Percentual', field: 'percentage', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
