import React, { useEffect, useState } from 'react'
import { useTabs } from '../../../../../../../../../hooks/tabs'
import {
  formatProductTypeToLowerCase,
  SALE,
  SEMI_FINISHED
} from '../../../../../domain/data/products'
import { CompositonView } from '../../../domain/response/productResponse'
import { useProduct } from '../../../provider/productProvider'
import { Container } from './style'
import currency from 'currency.js'

export const labelHasComposition = 'Composição'
export const nameHasComposition = '@@tabs-view-has-composition'

const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })
type StockListType = {
  id: number
  name: string
  product?: {
    id: number
    name: string
  }
}
export const HasComposition = ({
  composition,
  stocksList
}: {
  composition: string
  stocksList: StockListType[]
}): JSX.Element => {
  const { getProduct } = useProduct()
  let compositionList: CompositonView[] = []
  const { activeTab } = useTabs()

  useEffect(() => {
    if (compositionList[0]?.name !== '') {
      if (
        getProduct().type?.replace(' ', '-') ===
          formatProductTypeToLowerCase(SEMI_FINISHED) ||
        getProduct().type?.replace(' ', '-') ===
          formatProductTypeToLowerCase(SALE)
      ) {
        activeTab(nameHasComposition)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProduct()])

  if (composition) {
    compositionList = JSON.parse(composition.toLowerCase())
  }

  const productWithAtributes = () =>
    compositionList?.map(composition => {
      return composition
    })

  productWithAtributes()

  let soma = '0'
  for (let i = 0; i < compositionList.length; i++) {
    if (!compositionList[i]) continue
    const subtotal = BRL(compositionList[i].amount).multiply(
      BRL(compositionList[i].cost)
    )
    soma = BRL(subtotal).add(BRL(soma)).format()
  }
  const total = soma
  if (compositionList.length === 0) {
    return <></>
  }
  return (
    <Container>
      <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
        Composição do Produto
      </label>
      <table className="table table-bordered margin-bottom-0">
        <tbody>
          <tr>
            <th style={{ width: '30%' }}>Produto</th>
            <th style={{ width: '30%' }}>Variação</th>
            <th>
              {getProduct()?.type === 'semi acabado'
                ? 'Fração %'
                : 'Quantidade %'}
            </th>
            <th>Custo</th>
            <th>Subtotal</th>
          </tr>
          {compositionList &&
            compositionList.map(
              comp =>
                comp && (
                  <tr key={comp.stock_id} className="items">
                    <td>
                      {comp.stock_id
                        ? stocksList.find(s => s.id === Number(comp.stock_id))
                            ?.product?.name
                        : comp.product_name}
                    </td>
                    <td>{comp.name}</td>
                    <td>{comp.amount}</td>
                    <td>{comp.cost}</td>
                    <td>
                      {BRL(comp.amount).multiply(BRL(comp.cost)).format()}
                    </td>
                  </tr>
                )
            )}
          <tr>
            <td className="title" colSpan={3}>
              Total
            </td>
            <td className="value">{total}</td>
          </tr>
        </tbody>
      </table>
    </Container>
  )
}
