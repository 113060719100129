import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { nameActions, namePageTitle } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { toolsViewList } from '../domain/tools'
import { LinkContainer } from './styles'
import Modal from '../../../../components/Modal'
import { FormDefaultedOrders } from '../components/Form'
import convertDateToISO from '../../../../utlis/convertDateToISO'

interface DefaultedOrderData {
  client_id: number
  created_at: string
  days_delay: number
  deleted_at: null
  due_date: string
  financial_transaction_id: number
  id: number
  notes: string
  order_id: number
  payment_date: string
  reconsidered: false
  reconsidered_date: string
  reconsidered_user: string
  value: string
  paid: boolean
  type: string
  updated_at: string
  client: {
    id: number
    name: string
    pj_cnpj: string
  }
  clientNew: {
    id: number
    person: {
      name: string
      cnpj_cpf: string
    }
  }
  user: {
    name: string
  }
  defaultedOrders: DefaultedOrderData[]
}

const DefaultedOrdersView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const refModal = useRef(null)
  const [modalEdit, setModalEdit] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState<DefaultedOrderData>()
  const translateType: any = {
    L: 'LOCAÇÃO',
    V: 'VENDA',
    M: 'MANUTENÇÃO'
  }
  const [defaultedOrder, setDefaultedOrder] =
    useState<DefaultedOrderData | null>(null)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadDefaultedOrders(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<DefaultedOrderData>(apiList(id))
        const { data } = response
        data.defaultedOrders.sort((currentDate: any, nextDate: any) => {
          return new Date(convertDateToISO(currentDate.due_date)) <
            new Date(convertDateToISO(nextDate.due_date))
            ? 1
            : -1
        })
        setDefaultedOrder(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar os pedidos inadimplentes',
          description:
            'Houve um error ao carregar os pedidos inadimplentes, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadDefaultedOrders()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])
  const handleEdit = useCallback((item: DefaultedOrderData) => {
    setCurrentEditItem(item)
    setModalEdit(true)
  }, [])

  const handleClickOnClose = () => {
    setModalEdit(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[toolsViewList()]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{defaultedOrder?.clientNew?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{defaultedOrder?.clientNew?.person?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="cnpj" className="control-label">
                  Cnpj
                </label>
                <p>{defaultedOrder?.clientNew?.person?.cnpj_cpf}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <div className="table-scrollable">
                <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Pedido</th>
                      <th>Vencimento</th>
                      <th>Pagamento</th>
                      <th>Valor</th>
                      <th>Pago</th>
                      <th>Dias Atrasado</th>
                      <th>Cliente liberado</th>
                      <th>Liberado por</th>
                      <th>Liberado em</th>
                      <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {defaultedOrder?.defaultedOrders?.map(defaultedOrder => (
                      <tr key={Math.random()}>
                        <td>{translateType[defaultedOrder.type]}</td>
                        <td>{defaultedOrder.order_id}</td>
                        <td>{defaultedOrder.due_date}</td>
                        <td>{defaultedOrder.payment_date}</td>
                        <td>{defaultedOrder.value}</td>
                        <td>{defaultedOrder.paid ? 'Sim' : 'Não'}</td>
                        <td>{defaultedOrder.days_delay}</td>
                        <td>{defaultedOrder.reconsidered ? 'Sim' : 'Não'}</td>
                        <td>{defaultedOrder?.user?.name}</td>
                        <td>{defaultedOrder.reconsidered_date}</td>
                        <td>
                          <LinkContainer>
                            <div
                              onClick={() => handleEdit(defaultedOrder)}
                              className="link"
                            >
                              <i
                                className="fa fa-edit"
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </LinkContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEdit}
        pageTitle={'Editar'}
        styles={{
          padding: 0
        }}
        Children={
          <FormDefaultedOrders
            typeForm="update"
            initialValues={{
              idUpdate: Number(currentEditItem?.financial_transaction_id),
              ...currentEditItem
            }}
          />
        }
      />
    </>
  )
}

export default DefaultedOrdersView
