import React from 'react'

import { Container } from './styles'
import LabelText from '../../../../../../components/LabelText'
import { useClient } from '../../providers/ClientProvider'

const PAYMENT_CONDITIONS = {
  credit: 'A PRAZO',
  cash: 'A VISTA'
}

export const PaymentMethodFields = ({
  index
}: {
  index: number
}): JSX.Element => {
  const { initialValues } = useClient()

  return (
    <Container>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <LabelText
              label="Forma de pagamento"
              text={initialValues?.payment?.[index]?.default_payment_method}
              col={6}
            />
          </div>
          <div className="row">
            <LabelText
              label="Condição de pagamento"
              text={
                PAYMENT_CONDITIONS[
                  initialValues?.payment?.[index]?.payment_condition as 'credit'
                ]
              }
              col={6}
            />
          </div>
        </div>
        <div className="col-md-7">
          <h4>Observação de faturamento</h4>
          <div className="row">
            <LabelText
              label="Prazo estipulado de pagamento"
              text={initialValues?.payment?.[index]?.deadline_days_payment}
              col={3}
            />
          </div>
          <div className="row">
            <LabelText
              label="Dia inicial do mês para faturamento"
              text={initialValues?.payment?.[index]?.billing_days_start}
              col={3}
            />
            <LabelText
              label="Dia final do mês para faturamento"
              text={initialValues?.payment?.[index]?.billing_days_end}
              col={3}
            />
          </div>
          <div className="row">
            <LabelText
              label="Dia inicial do mês para vencimento"
              text={initialValues?.payment?.[index]?.due_days_start}
              col={3}
            />
            <LabelText
              label="Dia final do mês para vencimento"
              text={initialValues?.payment?.[index]?.due_days_end}
              col={3}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
