import jwtDecode, { JwtPayload } from 'jwt-decode'

export function validateToken(token: string): boolean {
  const decodedToken = jwtDecode<JwtPayload>(token)
  const currentDate = new Date().getTime()
  if (!decodedToken.exp) return false
  if (currentDate > decodedToken.exp * 1000) {
    return false
  }
  return true
}
