import { nameActions } from '../../info'
import { TypeBreadcrumb } from '../index'

export const breadcrumbView: TypeBreadcrumb[] = [
  {
    name: 'Início',
    to: '/'
  },
  {
    name: 'Comercial'
  },
  {
    name: 'Pedido'
  },
  {
    name: 'Baixa'
  }
]
