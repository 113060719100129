export const headers = [
  { name: 'Data', field: 'created_at', sortable: true, custom: true },
  { name: 'Empresa', field: 'company_id', sortable: true, custom: true },
  { name: 'Conta', field: 'bank_account_id', sortable: true, custom: true },
  { name: 'Tipo', field: 'type', sortable: true, custom: true },
  {
    name: 'Transferências',
    field: 'value_transfers',
    sortable: true,
    custom: true
  },
  { name: 'Títulos', field: 'value_titles', sortable: true, custom: true },
  {
    name: 'Convênios/Tributos',
    field: 'value_taxes',
    sortable: true,
    custom: true
  },
  { name: 'Valor Total', field: 'total_value', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersCreate = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Fornecedor', field: 'supplier_name', sortable: true },
  { name: 'Valor', field: 'value', sortable: true, type: 'monetary' },
  { name: 'Vencimento', field: 'due_date', sortable: true, type: 'date' },
  { name: 'Forma', field: 'payment_form', sortable: true },
  { name: 'Obs', field: 'notes', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersView = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Fornecedor', field: 'supplier_name', sortable: true },
  { name: 'Valor', field: 'value', sortable: true },
  { name: 'Vencimento', field: 'due_date', sortable: true, custom: true },
  { name: 'Forma', field: 'payment_form', sortable: true },
  {
    name: 'Status',
    field: 'transmission_status',
    sortable: true,
    custom: true
  },
  { name: 'Erro', field: 'transmission_erro', sortable: true, custom: true }
]
