import React, { useEffect } from 'react'
import {
  formatProductTypeToLowerCase,
  RE_SALE,
  SALE
} from '../../../../../domain/data/products'
import { PriceCompositionView } from '../../../domain/response/productResponse'
import { useProduct } from '../../../provider/productProvider'
import { Container } from './style'
import { useTabs } from '../../../../../../../../../hooks/tabs'
import { genericMaskWithTwoZero } from '../../../../../../../../../utlis/mask'
import { TooltipComponent } from '../../../../../../../../../components/TooltipComponent'
export const labelPriceComposition = 'Formação de preço do produto'
export const namePriceComposition = '@@tabs-view-PriceComposition'

export const PriceComposition = (): JSX.Element => {
  const { getProduct } = useProduct()
  const { price_composition } = getProduct()
  let priceCompositionList: PriceCompositionView = {} as PriceCompositionView
  const { activeTab } = useTabs()

  if (price_composition) {
    priceCompositionList = JSON.parse(price_composition.toLowerCase())
  }

  useEffect(() => {
    if (
      getProduct().type?.replace(' ', '-') ===
        formatProductTypeToLowerCase(RE_SALE) ||
      getProduct().type?.replace(' ', '-') ===
        formatProductTypeToLowerCase(SALE)
    ) {
      activeTab(namePriceComposition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProduct()])

  return (
    <Container>
      <p style={{ marginBottom: '20px', marginTop: '25px' }}>LUCRATIVIDADE</p>
      <div className="row">
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="Margem de lucro %"
              message="A margem de lucro é uma medida de lucratividade. É calculado encontrando o lucro como uma porcentagem da receita. Existem 3 tipos de margens de lucro: margem de lucro bruto, margem de lucro operacional e margem de lucro líquido. A Margem de Lucro Bruto é calculada como o lucro bruto dividido pelas vendas líquidas."
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.margin_profit)}</p>
        </div>
      </div>
      <p style={{ marginBottom: '20px', marginTop: '60px' }}>CUSTO FIXO</p>
      <div className="row">
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="Custo operacional %"
              message="Custo operacional de um produto é toda a despesa gerada para se produzir o produto."
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.fixed_cost)}</p>
        </div>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="Diferencial de aliquota na compra %"
              message="É um instrumento utilizado para equilibrar a arrecadação desse imposto entre os estados."
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.aliq_dif)}</p>
        </div>
      </div>
      <p style={{ marginBottom: '20px', marginTop: '60px' }}>CUSTOS FISCAIS</p>
      <div className="row">
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="Contribuição social %"
              message="É a atribuição da capacidade ou titularidade de certos tributos a certas pessoas, que não são o próprio Estado, em benefício das próprias finalidades."
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.social_contrib)}</p>
        </div>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="ICMS %"
              message="Imposto sobre Operações relativas à Circulação de Mercadorias e sobre Prestações de Serviços de Transporte Interestadual e Intermunicipal e de Comunicação é um imposto cobrado pela movimentação de mercadorias já embutido nos produtos."
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.icms)}</p>
        </div>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="IPI %"
              message="Imposto sobre Produtos Industrializados. É um tributo federal, aplicado sempre que uma mercadoria sai da fábrica. No entanto, nem todos os produtos sofrem incidência do IPI"
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.ipi)}</p>
        </div>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="PIS %"
              message="É a abreviação de Programa de Integração Social e é cobrado sobre os seguintes itens: faturamento, importação e folha de pagamento"
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.pis)}</p>
        </div>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="COFINS %"
              message="Significa Contribuição para Financiamento da Seguridade Social, São contribuintes deste imposto empresas em geral. O fato gerador da COFINS é o lucro bruto de uma empresa"
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.cofins)}</p>
        </div>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="I. Renda %"
              message="Imposto de Renda Pessoa Jurídica é um tributo federal que deve ser pago por todas as empresas com CNPJ ativo, sua base de cálculo considera o regime tributário da pessoa jurídica."
            />
          </label>
          <p>{genericMaskWithTwoZero(priceCompositionList.income_tax)}</p>
        </div>
      </div>
      <div className="row" style={{ marginTop: '25px' }}>
        <div className="form-content col-md-2">
          <label htmlFor="tipo do produto">
            <TooltipComponent
              label="Adicional de I. de Renda %"
              message="As empresas do Luco Presumido e Lucro Real farão o cálculo do adicional de IR de 10% sobre o valor excedente, caso a base de IRPJ ultrapasse R$ 240.000,00 anual, o limite trimestral é de R$ 60.000,00, onde calculamos R$ 20.000,00 mensal, quando o usuário opta por antecipar o imposto."
            />
          </label>
          <p>
            {genericMaskWithTwoZero(priceCompositionList.income_tax_aditional)}
          </p>
        </div>
      </div>
    </Container>
  )
}
