type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'OrderExpeditionStatus'
const nameSource = 'warehouse/expedition/ordersStatus'
const namePageTitle = 'Pedidos expedição status'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Pedidos expedição status',
  to: '/warehouse/expedition/ordersStatus',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/warehouse/expedition/ordersStatus/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/warehouse/expedition/ordersStatus/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Atualizar',
    to: '/warehouse/expedition/ordersStatus/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
