export const headers = [
  { name: 'Cliente', field: 'person.name', sortable: true },
  { name: 'Nome fantasia', field: 'person.fantasy_name', sortable: true },
  {
    name: 'CNPJ/CPF',
    field: 'person.cnpj_cpf',
    sortable: true,
    tdStyle: { width: '180px' }
  },
  {
    name: 'Cidade/UF',
    field: 'personAddress.uf',
    sortable: true,
    custom: true
  },
  { name: 'Segmento', field: 'segment.name', sortable: true },
  { name: 'Subsegmento', field: 'subSegment.name', sortable: true },
  { name: 'Responsável Venda', field: 'saleSeller.name', sortable: true },
  { name: 'Responsável Locação', field: 'locationSeller.name', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
