import React from 'react'
import PaymentPage from '../../pages/payment/CreditCardPayment'
import { CustomSwitch } from '../../components/CustomSwitch'
import { Route } from 'react-router-dom'

export const CreditCardPaymentRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/creditCardPayment" exact component={PaymentPage} />
  </CustomSwitch>
)
