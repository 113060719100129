import styled from 'styled-components'

export const Container = styled.div`
  .amcharts-labelText {
    transform: translate(0, 0);
  }
  .amcharts-labelValue {
    transform: translate(232px, 0);
  }
`
