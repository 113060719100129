import React from 'react'
import {
  Input,
  InputMask,
  Select
} from '../../../../../../../components/FormProvider'

import { Container } from './styles'
import { twoDigitsByComma } from '../../../../../../../utlis/mask'

export const PaymentMethodFields = ({
  type,
  index
}: {
  type: string
  index: number
}): JSX.Element => {
  return (
    <Container>
      <div className="row">
        <div className="col-md-4">
          <div className="row no-margin-top">
            <div className="col-md-6">
              <Input
                name={`payment.${index}.type`}
                value={type}
                style={{ display: 'none' }}
              />
              <Input name={`payment.${index}.id`} style={{ display: 'none' }} />
              <Select
                label="Forma de pagamento"
                className="form-control"
                name={`payment.${index}.default_payment_method`}
                options={[
                  { name: 'BOLETO', value: 'BOLETO' },
                  { name: 'PIX', value: 'PIX' },
                  { name: 'CARTAO DE CREDITO', value: 'CARTAO DE CREDITO' },
                  { name: 'CARTAO DE DEBITO', value: 'CARTAO DE DEBITO' },
                  { name: 'TRANSFERENCIA', value: 'TRANSFERENCIA' }
                ]}
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Select
                label="Condição de pagamento"
                className="form-control"
                name={`payment.${index}.payment_condition`}
                options={[
                  { name: 'A VISTA', value: 'cash' },
                  { name: 'A PRAZO', value: 'credit' }
                ]}
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Select
                label="Calculo de vencimento"
                className="form-control"
                name={`payment.${index}.calculation_due_date`}
                options={[
                  { name: 'APOS ENTREGA', value: 'after_delivery' },
                  { name: 'FATURAMENTO', value: 'invoicing' }
                ]}
                blank
                defaultValue={''}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <h4>Observação de faturamento</h4>
          <div className="row">
            <div className="form-content col-md-3">
              <Input
                label="Prazo estipulado de pagamento"
                name={`payment.${index}.deadline_days_payment`}
                className="form-control"
                maxLength={2}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-content col-md-3">
              <Input
                label="Dia inicial do mês para faturamento"
                name={`payment.${index}.billing_days_start`}
                className="form-control"
                maxLength={2}
              />
              <span
                style={{
                  position: 'absolute',
                  right: '-5px',
                  bottom: '18px',
                  fontSize: '16px'
                }}
              >
                à
              </span>
            </div>
            <div className="form-content col-md-3">
              <Input
                label="Dia final do mês para faturamento"
                name={`payment.${index}.billing_days_end`}
                className="form-control"
                maxLength={2}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-content col-md-3">
              <InputMask
                mask={twoDigitsByComma}
                label="Dia inicial do mês para vencimento"
                name={`payment.${index}.due_days_start`}
                className="form-control"
                maxLength={20}
              />
              <span
                style={{
                  position: 'absolute',
                  right: '-5px',
                  bottom: '18px',
                  fontSize: '16px'
                }}
              >
                à
              </span>
            </div>
            <div className="form-content col-md-3">
              <Input
                label="Dia final do mês para vencimento"
                name={`payment.${index}.due_days_end`}
                className="form-control"
                maxLength={2}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
