import React from 'react'
import List from '../../pages/Financial/InvoicesReceived/List'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const InvoicesReceivedRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/invoicesReceived" exact component={List} />
  </CustomSwitch>
)
