import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle, nameActions } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useParams } from 'react-router-dom'
import { FormCategory } from '../components/Form'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { ProductProvider } from '../providers/product/ProductProvider'
import { TypeComposition } from '../domain/models/typeProduct'
import { BRL, genericMaskWithTwoZero } from '../../../../utlis/mask'

interface CampaignData {
  campaign: Campaign
  camapaign_items: TypeComposition[]
}

interface CampaignDataFetch {
  campaing: Campaign
  camapaign_items: CampaignItems[]
}

export interface Campaign {
  id: number
  name: string
  type: string
  pricing_type: string
  department: string
  client: string
  start_date: string
  end_date: string
  start_date_delivery: string
  end_date_delivery: string
  description: string
}

interface CampaignItems {
  id: number
  campaign_id: number
  stock_id: number
  stock: {
    name: string
    prices: string
    product: {
      id: number
      name: string
    }
  }
  min?: number
  max?: number
  promotion: string
}

const Update: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [campaigns, setCampaigns] = useState<CampaignData>()
  useEffect(() => {
    async function loadData() {
      activeLoading()
      const response = await api.get<CampaignDataFetch>(apiList(id))
      const { data } = response
      const campaign = {
        ...data.campaing,
        start_date: data.campaing.start_date?.slice(0, 10),
        end_date: data.campaing.end_date?.slice(0, 10),
        start_date_delivery: data.campaing.start_date_delivery?.slice(0, 10),
        end_date_delivery: data.campaing.end_date_delivery?.slice(0, 10)
      }
      const campaignItems = data.camapaign_items.map(item => ({
        id: item.id,
        stock_id: item.stock_id,
        name: item.stock?.name,
        product_id: item.stock?.product?.id,
        product_name: item.stock?.product?.name,
        price_sale: item.stock
          ? BRL(
              genericMaskWithTwoZero(JSON.parse(item.stock?.prices)?.price_sale)
            ).format()
          : '0',
        min: item.min,
        max: item.max,
        promotion: genericMaskWithTwoZero(item.promotion)
      }))

      setCampaigns({
        campaign,
        camapaign_items: campaignItems
      })
      disableLoading()
    }

    loadData()
  }, [activeLoading, disableLoading, id])

  return (
    <>
      <ProductProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.update.name}
          breadcrumb={breadcrumbUpdate}
          tools={[toolsUpdate]}
        >
          <div className="form body">
            <FormCategory
              typeForm="update"
              campaignId={String(campaigns?.campaign.id)}
              campaignItems={campaigns?.camapaign_items}
              campaign={campaigns?.campaign}
            />
          </div>
        </Container>
      </ProductProvider>
    </>
  )
}
export default Update
