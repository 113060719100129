import React, { useEffect, useState } from 'react'

import { Container, EmailItem, Panel } from './styles'
import LabelText from '../../../../../../components/LabelText'
import { useClient } from '../../providers/ClientProvider'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

export const FreightFields = ({ type }: { type: string }): JSX.Element => {
  const { initialValues, carriersOptions } = useClient()
  const [emails, setEmails] = useState<any[]>([])

  useEffect(() => {
    if (initialValues?.freight?.length) {
      setEmails(initialValues.freight)
    }
  }, [initialValues])

  const TRANSPORT_MODE_MAP = {
    client: 'DO CLIENTE',
    multfluxo: 'MULTFLUXO',
    outsource: 'TERCEIRIZADA'
  }

  return (
    <Container className="table-responsive">
      {emails.map((emailItem, index) => {
        return (
          emailItem &&
          emailItem.type === type && (
            <EmailItem
              key={emailItem.id}
              className="panel-group"
              id="accordion"
            >
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <div
                      onClick={() =>
                        setEmails((prev: any) => {
                          const copy = JSON.parse(JSON.stringify(prev))
                          copy[index].open = !copy[index].open
                          return copy
                        })
                      }
                    >
                      {emailItem?.freight_type?.toUpperCase() || 'NOVO FRETE'}
                      {!emailItem?.open ? (
                        <FaChevronDown size={13} />
                      ) : (
                        <FaChevronUp size={13} />
                      )}
                    </div>
                  </h4>
                </div>
                <Panel
                  id={`${index}`}
                  className={'panel-collapse'}
                  open={emailItem.open}
                  style={{
                    height: emailItem.open ? '90px' : '0px'
                  }}
                >
                  <div className="panel-body">
                    <div className="form-content col-md-12">
                      <div className="row no-margin-top">
                        <LabelText
                          label="Tipo de frete"
                          text={String(emailItem.freight_type).toUpperCase()}
                          col={3}
                        />
                        <LabelText
                          label="Tipo de transportadora"
                          text={
                            TRANSPORT_MODE_MAP[
                              emailItem.transport_mode as keyof typeof TRANSPORT_MODE_MAP
                            ]
                          }
                          col={3}
                        />
                        <LabelText
                          label="Transportadora"
                          text={
                            carriersOptions.find(
                              c => c.value === emailItem.transport_id
                            )?.name
                          }
                          col={3}
                        />
                        <LabelText
                          label="Multfluxo solicita a coleta para o cliente"
                          text={emailItem.pickup_requested ? 'SIM' : 'NÃO'}
                          col={3}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            </EmailItem>
          )
        )
      })}
    </Container>
  )
}
