export const TASK_PRIORITY_ENUM: Record<string, string> = {
  normal: 'Normal',
  medium: 'Média',
  high: 'Alta'
}
export const TASK_PRIORITY_OPTIONS = Object.entries(TASK_PRIORITY_ENUM).map(
  ([key, value]) => ({
    name: value,
    value: key
  })
)
