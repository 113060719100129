type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'Order'
const nameSource = 'warehouse/expedition/orders'
const namePageTitle = 'Expedição'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Cadastro',
  to: '/warehouse/expedition/orders',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/warehouse/expedition/orders/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/warehouse/expedition/orders/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Atualizar',
    to: '/warehouse/expedition/orders/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export type OrdersExpeditionLogs = {
  id: number
  date: string
  expiration: string
  previous_status: string
  new_status: string
  user: { name: string }
  description?: string
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
