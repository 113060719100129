import styled from 'styled-components'

export const FormContent = styled.div`
  padding: 1em;

  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1em;

  input[name='name'] {
    width: 400px;
  }
  input[name='level'] {
    width: 100px;
  }
`
export const SearchContainer = styled.div<{ erro?: boolean }>`
  position: relative;
  margin-bottom: 2rem;

  ul {
    width: 500px;
    max-height: 350px;
    margin: 0;
    padding: 0;

    position: absolute;

    border: ${props => props.erro && '1px solid red'};
    color: ${props => (props.erro ? 'red' : '#000')};
    background: #fff;

    overflow: auto;
    z-index: 999;

    li {
      font-size: 14px;
      padding: 3px;
      cursor: pointer;

      &:hover {
        background: #f1f2f3;
      }
    }
  }
`
