/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ControlPanel } from './style'
import { AmChartMixedColumn } from '../../../components/AmChartMixedColumn'
import { HeaderPortlet } from '../../../components/HeaderPortlet'
import { TablePortlet } from '../../../components/TablePortlet'
import Container from '../../../components/Container'
import api from '../../../services/api'
import currency from 'currency.js'
import { useLoading } from '../../../hooks/loading'
import { AmChartPizza } from '../../../components/AmChartPizza'
import { AmChartMap } from '../../../components/AmChartMap'
import * as am4core from '@amcharts/amcharts4/core'
const FinancialExpenses: React.FC = () => {
  const [activeMembers, setActiveMembers] = useState([])
  const [salesSummary, setSaleSummary] = useState([])
  const [expenseBudgetData, setExpenseBudgetData] = useState<any>([])
  const [expenseBudgetPeriodData, setExpenseBudgetPeriodData] = useState<any>(
    []
  )
  const [dataUpdateProvider, setDataUpdateProvider] = useState([])
  const [dataUpdateMapProvider, setDataUpdateMapProvider] = useState([])
  const [dataUpdateMixed, setDataUpdateMixed] = useState([])
  const [selectedDate, setSelectedDate] = useState('Mes')
  const [selectedCenterId, setSelectedCenterId] = useState(0)
  const [resetToggle, setResetToggle] = useState(false)
  const [resetSelected, setResetSelected] = useState(false)
  const { activeLoading, disableLoading } = useLoading()
  const [datesRange, setDatesRange] = useState({
    minDate: undefined,
    maxDate: undefined
  })
  const percetageExpenseRef = useRef(0)

  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })
  const createExpenseCenterCost = useCallback(
    (data: any[], selectDate?: any, hasDivide?: boolean) => {
      let previewDivide: any
      if (data.length) {
        if (!hasDivide) {
          return data
            .filter(item => item)
            .sort((currentExpense, nextExpense) => {
              if (currentExpense.cost_center_name === 'TOTAL') {
                return 1
              }
              return currentExpense.cost_center_name.localeCompare(
                nextExpense.cost_center_name
              )
            })
            .map((expense: any) => {
              return {
                id: expense.cost_center_id,
                column1: expense.cost_center_name,
                column2: BRL(expense.expected_value).format(),
                column3: BRL(expense.realized_value).format(),
                column4: BRL(expense.expected_value)
                  .subtract(expense.realized_value)
                  .format(),
                column5: currency(
                  currency(expense.expected_value).intValue === 0
                    ? 0
                    : BRL(expense.realized_value)
                        .multiply(100)
                        .divide(expense.expected_value),
                  {
                    symbol: '%',
                    pattern: '#!',
                    decimal: ',',
                    separator: '.'
                  }
                ).format()
              }
            })
        } else {
          return data
            .filter(item => item)
            .sort((currentExpense, nextExpense) => {
              if (currentExpense.cost_center_name === 'TOTAL') {
                return 1
              }
              return currentExpense.cost_center_name.localeCompare(
                nextExpense.cost_center_name
              )
            })
            .map((expense: any) => {
              expenseBudgetData.expensesBudgets.month.forEach(
                (expenseDivide: any) => {
                  if (expense.cost_center_id === expenseDivide.cost_center_id) {
                    if (selectDate === 'day') {
                      previewDivide = expenseDivide.expected_value / 22
                    }
                    if (selectDate === 'week') {
                      previewDivide = (expenseDivide.expected_value / 22) * 5
                    }
                  }
                }
              )
              return {
                id: expense.cost_center_id,
                column1: expense.cost_center_name,
                column2: BRL(previewDivide || expense.expected_value).format(),
                column3: BRL(expense.realized_value).format(),
                column4: BRL(previewDivide || expense.expected_value)
                  .subtract(expense.realized_value)
                  .format(),
                column5: currency(
                  currency(expense.expected_value).intValue === 0
                    ? 0
                    : BRL(expense.realized_value)
                        .multiply(100)
                        .divide(previewDivide || expense.expected_value),
                  {
                    symbol: '%',
                    pattern: '#!',
                    decimal: ',',
                    separator: '.'
                  }
                ).format()
              }
            })
        }
      }
      return []
    },
    [expenseBudgetData]
  )
  const createExpenseCategerory = useCallback(
    (
      data: any[],
      selectDate?: any,
      hasDivide?: boolean,
      center_cost_id?: number
    ) => {
      let previewDivide: any
      let previewDivideSubCategory: any
      if (data.length) {
        if (!hasDivide) {
          return data
            .filter(item => item)
            .sort((expenseBudgetCategory, nextExpenseBudgetCategory) =>
              expenseBudgetCategory.name.localeCompare(
                nextExpenseBudgetCategory.name,
                'pt-BR'
              )
            )
            .map(
              (expenseBudgetCategory: any) =>
                expenseBudgetCategory && {
                  id: expenseBudgetCategory.category_id,
                  column1: expenseBudgetCategory.name,
                  column2: BRL(expenseBudgetCategory.expected_value).format(),
                  column3: BRL(expenseBudgetCategory.realized_value).format(),
                  column4: BRL(expenseBudgetCategory.expected_value)
                    .subtract(expenseBudgetCategory.realized_value)
                    .format(),
                  column5: currency(
                    currency(expenseBudgetCategory.expected_value).intValue ===
                      0
                      ? 0
                      : BRL(expenseBudgetCategory.realized_value)
                          .multiply(100)
                          .divide(expenseBudgetCategory.expected_value),
                    {
                      symbol: '%',
                      pattern: '#!',
                      decimal: ',',
                      separator: '.'
                    }
                  ).format(),
                  subItems: expenseBudgetCategory.subCategories
                    .filter((item: any) => item)
                    .sort((currentSubCategory: any, nextSubCategory: any) =>
                      currentSubCategory.name.localeCompare(
                        nextSubCategory.name,
                        'pt-BR'
                      )
                    )
                    .map(
                      (subCategory: any) =>
                        subCategory && {
                          disable: true,
                          column1: subCategory.name,
                          column2: BRL(subCategory.expected_value).format(),
                          column3: BRL(subCategory.realized_value).format(),
                          column4: BRL(subCategory.expected_value)
                            .subtract(subCategory.realized_value)
                            .format(),
                          column5: currency(
                            currency(subCategory.expected_value).intValue === 0
                              ? 0
                              : BRL(subCategory.realized_value)
                                  .multiply(100)
                                  .divide(subCategory.expected_value),
                            {
                              symbol: '%',
                              pattern: '#!',
                              decimal: ',',
                              separator: '.'
                            }
                          ).format()
                        }
                    )
                }
            )
        } else {
          return data
            .filter(item => item)
            .sort(
              (expenseBudgetCategory: any, nextExpenseBudgetCategory: any) =>
                expenseBudgetCategory.name.localeCompare(
                  nextExpenseBudgetCategory.name,
                  'pt-BR'
                )
            )
            .map((expenseBudgetCategory: any) => {
              expenseBudgetData.expensesBudgets.month.forEach(
                (expenseDivide: any) => {
                  if (center_cost_id === expenseDivide.cost_center_id) {
                    expenseDivide.categories
                      .filter((item: any) => item)
                      .forEach((category: any) => {
                        if (
                          expenseBudgetCategory.category_id ===
                          category.category_id
                        ) {
                          if (selectDate === 'day') {
                            previewDivide = category.expected_value / 22
                          }
                          if (selectDate === 'week') {
                            previewDivide = (category.expected_value / 22) * 5
                          }
                        }
                      })
                  }
                }
              )
              return {
                id: expenseBudgetCategory.category_id,
                column1: expenseBudgetCategory.name,
                column2: BRL(
                  previewDivide || expenseBudgetCategory.expected_value
                ).format(),
                column3: BRL(expenseBudgetCategory.realized_value).format(),
                column4: BRL(
                  previewDivide || expenseBudgetCategory.expected_value
                )
                  .subtract(expenseBudgetCategory.realized_value)
                  .format(),
                column5: currency(
                  currency(
                    previewDivide || expenseBudgetCategory.expected_value
                  ).intValue === 0
                    ? 0
                    : BRL(expenseBudgetCategory.realized_value)
                        .multiply(100)
                        .divide(
                          previewDivide || expenseBudgetCategory.expected_value
                        ),
                  {
                    symbol: '%',
                    pattern: '#!',
                    decimal: ',',
                    separator: '.'
                  }
                ).format(),
                subItems: expenseBudgetCategory.subCategories
                  .filter((item: any) => item)
                  .sort((currentSubCategory: any, nextSubCategory: any) =>
                    currentSubCategory.name.localeCompare(
                      nextSubCategory.name,
                      'pt-BR'
                    )
                  )
                  .map((subCategory: any) => {
                    expenseBudgetData.expensesBudgets.month.forEach(
                      (expenseDivide: any) => {
                        if (center_cost_id === expenseDivide.cost_center_id) {
                          expenseDivide.categories.forEach((category: any) => {
                            if (
                              category.category_id ===
                              expenseBudgetCategory.category_id
                            ) {
                              category.subCategories
                                .filter((item: any) => item)
                                .forEach((subCategoryDivide: any) => {
                                  if (
                                    subCategory.category_id ===
                                    subCategoryDivide.category_id
                                  ) {
                                    if (selectDate === 'day') {
                                      previewDivideSubCategory =
                                        subCategoryDivide.expected_value / 22
                                    }
                                    if (selectDate === 'week') {
                                      previewDivideSubCategory =
                                        (subCategoryDivide.expected_value /
                                          22) *
                                        5
                                    }
                                  }
                                })
                            }
                          })
                        }
                      }
                    )
                    return {
                      disable: true,
                      column1: subCategory.name,
                      column2: BRL(
                        previewDivideSubCategory || subCategory.expected_value
                      ).format(),
                      column3: BRL(subCategory.realized_value).format(),
                      column4: BRL(
                        previewDivideSubCategory || subCategory.expected_value
                      )
                        .subtract(subCategory.realized_value)
                        .format(),
                      column5: currency(
                        currency(
                          previewDivideSubCategory || subCategory.expected_value
                        ).intValue === 0
                          ? 0
                          : BRL(subCategory.realized_value)
                              .multiply(100)
                              .divide(
                                previewDivideSubCategory ||
                                  subCategory.expected_value
                              ),
                        {
                          symbol: '%',
                          pattern: '#!',
                          decimal: ',',
                          separator: '.'
                        }
                      ).format()
                    }
                  })
              }
            })
        }
      }
      return []
    },
    [expenseBudgetData]
  )
  const findBiggerPercetage = useCallback(
    (data: any[], maxValue: number, isParent?: boolean, isLocal?: boolean) => {
      let percentageExpenseData = 0
      if (isParent) {
        data
          .filter((item: any) => item)
          .forEach((month: any) => {
            if (month.name !== 'Total' && month.name) {
              month.categories
                .filter((item: any) => item)
                .forEach((category: any) => {
                  if (
                    percentageExpenseData <
                    category.realized_value_percentage_expense
                  ) {
                    percentageExpenseData =
                      category.realized_value_percentage_expense
                  }
                })
            }
          })
        if (!isLocal) {
          percetageExpenseRef.current = percentageExpenseData
        }
        return { percentageExpenseData }
      }
      data
        .filter((item: any) => item)
        .forEach((category: any) => {
          const percentage = (category.realized_total_value / maxValue) * 100
          if (percentageExpenseData < percentage) {
            percentageExpenseData = percentage
          }
        })
      return { percentageExpenseData }
    },
    []
  )

  function createCategories(
    data: any[],
    color: string,
    percentage: any,
    maxValue: number,
    isParent?: boolean
  ) {
    const maxLighten = 0.65
    if (isParent) {
      return data
        .filter((item: any) => item)
        .map((month: any) => {
          if (month.name !== 'Total') {
            return month.categories
              .filter((item: any) => item)
              .sort(
                (currentValue: any, nextValue: any) =>
                  nextValue.realized_total_value -
                  currentValue.realized_total_value
              )
              .filter((category: any) => category.realized_total_value > 0)
              .map((category: any) => {
                let biggerPercent = 0
                return {
                  type: category.name,
                  percent: category.realized_total_value,
                  value: category.realized_total_value,
                  financial_transactions: category.financial_transactions,
                  color: am4core
                    .color(color)
                    .lighten(
                      (percentage - category.realized_total_value) / 100
                    ),
                  subs: category.subCategories
                    .filter((item: any) => item)
                    .sort(
                      (currentValue: any, nextValue: any) =>
                        nextValue.realized_total_value -
                        currentValue.realized_total_value
                    )
                    .map((subCategory: any) => {
                      if (
                        subCategory.realized_total_value /
                          category.realized_total_value >
                        biggerPercent
                      ) {
                        biggerPercent =
                          subCategory.realized_total_value /
                          category.realized_total_value
                      }
                      return {
                        type: subCategory.name,
                        value: subCategory.realized_total_value,
                        financial_transactions:
                          subCategory.financial_transactions,
                        color: am4core
                          .color(color)
                          .lighten(
                            biggerPercent -
                              subCategory.realized_total_value /
                                category.realized_total_value >=
                              0.8
                              ? 0.7
                              : biggerPercent -
                                  subCategory.realized_total_value /
                                    category.realized_total_value
                          ),
                        percent: subCategory.realized_total_value
                      }
                    })
                }
              })
          }
        })
        .flatMap((item: any) => item)
        .filter((item: any) => item)
    }
    return data
      .filter((item: any) => item)
      .sort(
        (currentValue: any, nextValue: any) =>
          nextValue.realized_total_value - currentValue.realized_total_value
      )
      .filter((category: any) => category.realized_total_value > 0)
      .map((category: any) => {
        const percent = (category.realized_total_value / maxValue) * 100
        const percentLight = (percentage - percent) / 45
        return {
          type: category.supplier_name,
          percent,
          financial_transactions: category.financial_transactions,
          value: category.realized_total_value,
          color: am4core
            .color(color)
            .lighten(percentLight > maxLighten ? maxLighten : percentLight),
          subs: []
        }
      })
      .flatMap((item: any) => item)
      .filter((item: any) => item)
  }

  async function loadData() {
    activeLoading()
    const response = await api.get(
      '/financial/expensesAnalysis/listPerPeriod/date'
    )
    setExpenseBudgetData(response.data)
    setSaleSummary(createExpenseCenterCost(response.data.expensesBudgets.month))
    setDataUpdateMixed(
      response.data.graphicData.map((month: any) => {
        return {
          date: month.month,
          goal: month.expected_value,
          goalAccomplished: month.realized_value,
          discount: month.realized_value - month.expected_value,
          lastYearTarget:
            Number(month.expected_value) === 0
              ? 0
              : (month.realized_value * 100) / month.expected_value
        }
      })
    )

    setTimeout(() => {
      disableLoading()
    }, 300)
  }

  const setInitialData = useCallback(
    async (date?: string, center_cost_id?: number) => {
      activeLoading()
      const selectDate = date.length > 0 ? date : selectedDate
      const selectCenter =
        center_cost_id === 0 ? center_cost_id : selectedCenterId
      setSelectedDate(date.length > 0 ? date : selectedDate)
      setSelectedCenterId(
        center_cost_id === 0 ? center_cost_id : selectedCenterId
      )
      let selectPeriod: any

      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        const response = {
          data: expenseBudgetPeriodData
        }
        setSaleSummary(
          createExpenseCenterCost(
            response.data.expensesBudgets[selectPeriod],
            selectPeriod,
            true
          )
        )

        if (selectCenter) {
          const centerFind = response.data.expensesBudgets[selectPeriod].find(
            (center: any) => center.cost_center_id === selectCenter
          )
          const categories = centerFind.categories.filter(
            (item: any) => item.name
          )
          if (centerFind) {
            setDatesRange({
              maxDate,
              minDate
            })
            setActiveMembers(createExpenseCategerory(categories))
            setDataUpdateMixed(
              centerFind.graphicMonths.map(
                (month: any) =>
                  month && {
                    date: month.month,
                    goal: month.expected_value,
                    goalAccomplished: month.realized_value,
                    discount: month.realized_value - month.expected_value,
                    lastYearTarget:
                      Number(month.expected_value) === 0
                        ? 0
                        : (month.realized_value * 100) / month.expected_value
                  }
              )
            )
            if (centerFind.suppliers.length) {
              const { percentageExpenseData } = findBiggerPercetage(
                centerFind.suppliers,
                centerFind.realized_value
              )
              setDataUpdateProvider(
                createCategories(
                  centerFind.suppliers,
                  '#af3f2c',
                  percentageExpenseData,
                  centerFind.realized_value
                )
              )
              setDataUpdateMapProvider(
                centerFind.suppliers
                  .filter((item: any) => item)
                  .map(
                    (supply: any) =>
                      supply && {
                        MAIL_ST_PROV_C: supply.supplier_state,
                        value: supply.realized_total_value
                      }
                  )
              )
            } else {
              setDataUpdateProvider([])
              setDataUpdateMapProvider([])
            }
            setTimeout(() => {
              disableLoading()
            }, 500)
            return
          }
        } else {
          setDatesRange({
            maxDate,
            minDate
          })
          setDataUpdateMixed(
            response.data.graphicData.map(
              (month: any) =>
                month && {
                  date: month.month,
                  goal: month.expected_value,
                  goalAccomplished: month.realized_value,
                  discount: month.realized_value - month.expected_value,
                  lastYearTarget:
                    Number(month.expected_value) === 0
                      ? 0
                      : (month.realized_value * 100) / month.expected_value
                }
            )
          )
          setDataUpdateProvider([])
          setDataUpdateMapProvider([])
          setActiveMembers([])
        }
        disableLoading()
        return
      }

      const year = new Date().getFullYear()
      setDatesRange({
        minDate: `${year}-01`,
        maxDate: `${year}-12`
      })
      setSaleSummary(
        createExpenseCenterCost(
          expenseBudgetData.expensesBudgets[selectPeriod],
          selectPeriod,
          true
        )
      )
      if (selectCenter) {
        const centerFind = expenseBudgetData.expensesBudgets[selectPeriod].find(
          (center: any) => center.cost_center_id === selectCenter
        )
        if (centerFind) {
          const categories = centerFind.categories.filter(
            (item: any) => item.name
          )
          setActiveMembers(
            createExpenseCategerory(
              categories,
              selectPeriod,
              true,
              selectCenter
            )
          )
          setDataUpdateMixed(
            centerFind.graphicMonths.map(
              (month: any) =>
                month && {
                  date: month.month,
                  goal: month.expected_value,
                  goalAccomplished: month.realized_value,
                  discount: month.realized_value - month.expected_value,
                  lastYearTarget:
                    Number(month.expected_value) === 0
                      ? 0
                      : (month.realized_value * 100) / month.expected_value
                }
            )
          )
          if (centerFind.suppliers.length) {
            const { percentageExpenseData } = findBiggerPercetage(
              centerFind.suppliers,
              centerFind.realized_value
            )
            setDataUpdateMapProvider(
              centerFind.suppliers
                .filter((item: any) => item)
                .map(
                  (supply: any) =>
                    supply && {
                      MAIL_ST_PROV_C: supply.supplier_state,
                      value: supply.realized_total_value
                    }
                )
            )
            setDataUpdateProvider(
              createCategories(
                centerFind.suppliers,
                '#af3f2c',
                percentageExpenseData,
                centerFind.realized_value
              )
            )
          } else {
            setDataUpdateProvider([])
            setDataUpdateMapProvider([])
          }
          setTimeout(() => {
            disableLoading()
          }, 300)
          return
        }
      } else {
        setDataUpdateMixed(
          expenseBudgetData.graphicData.map(
            (month: any) =>
              month && {
                date: month.month,
                goal: month.expected_value,
                goalAccomplished: month.realized_value,
                discount: month.realized_value - month.expected_value,
                lastYearTarget:
                  Number(month.expected_value) === 0
                    ? 0
                    : (month.realized_value * 100) / month.expected_value
              }
          )
        )
        setDataUpdateProvider([])
        setDataUpdateMapProvider([])
        setActiveMembers([])
      }
      disableLoading()
    },
    [
      activeLoading,
      createExpenseCategerory,
      createExpenseCenterCost,
      disableLoading,
      expenseBudgetData.expensesBudgets,
      expenseBudgetData.graphicData,
      expenseBudgetPeriodData,
      findBiggerPercetage,
      selectedCenterId,
      selectedDate
    ]
  )

  const handleSetDate = useCallback(
    async (date: string, center_cost_id?: number) => {
      setResetSelected(true)
      setResetToggle(true)
      const selectDate = date || selectedDate
      const selectCenter = center_cost_id || selectedCenterId
      setSelectedDate(date || selectedDate)
      setSelectedCenterId(center_cost_id || selectedCenterId)
      activeLoading()
      let selectPeriod: any
      if (
        selectDate === 'Mes' ||
        selectDate === 'Mês' ||
        selectDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectDate === 'Semana') {
        selectPeriod = 'week'
      }
      if (selectDate.includes('period')) {
        const { minDate, maxDate } = JSON.parse(date || selectDate)
        let response
        if (selectedDate && selectDate.includes(selectedDate)) {
          response = {
            data: expenseBudgetPeriodData
          }
        } else {
          response = await api.get(
            `/financial/expensesAnalysis/listPerPeriod/${date || selectDate}`
          )
        }
        setExpenseBudgetPeriodData(response.data)
        setSaleSummary(
          createExpenseCenterCost(response.data.expensesBudgets[selectPeriod])
        )
        if (selectCenter) {
          const centerFind = response.data.expensesBudgets[selectPeriod].find(
            (center: any) => center.cost_center_id === selectCenter
          )
          if (centerFind) {
            const categories = centerFind.categories.filter(
              (item: any) => item.name
            )
            setDatesRange({
              minDate,
              maxDate
            })
            setActiveMembers(createExpenseCategerory(categories))
            setDataUpdateMixed(
              centerFind.graphicMonths.map(
                (month: any) =>
                  month && {
                    date: month.month,
                    goal: month.expected_value,
                    goalAccomplished: month.realized_value,
                    discount: month.realized_value - month.expected_value,
                    lastYearTarget:
                      Number(month.expected_value) === 0
                        ? 0
                        : (month.realized_value * 100) / month.expected_value
                  }
              )
            )

            if (centerFind.suppliers.length) {
              const { percentageExpenseData } = findBiggerPercetage(
                centerFind.suppliers,
                centerFind.realized_value
              )
              setDataUpdateProvider(
                createCategories(
                  centerFind.suppliers,
                  '#af3f2c',
                  percentageExpenseData,
                  centerFind.realized_value
                )
              )
              setDataUpdateMapProvider(
                centerFind.suppliers
                  .filter((item: any) => item)
                  .map(
                    (supply: any) =>
                      supply && {
                        MAIL_ST_PROV_C: supply.supplier_state,
                        value: supply.realized_total_value
                      }
                  )
              )
            } else {
              setDataUpdateProvider([])
              setDataUpdateMapProvider([])
            }
            setTimeout(() => {
              disableLoading()
            }, 500)
            return
          }
        } else {
          setDatesRange({
            maxDate,
            minDate
          })
          setDataUpdateMixed(
            response.data.graphicData.map(
              (month: any) =>
                month && {
                  date: month.month,
                  goal: month.expected_value,
                  goalAccomplished: month.realized_value,
                  discount: month.realized_value - month.expected_value,
                  lastYearTarget:
                    Number(month.expected_value) === 0
                      ? 0
                      : (month.realized_value * 100) / month.expected_value
                }
            )
          )
          setDataUpdateProvider([])
          setActiveMembers([])
        }
        disableLoading()
        return
      }
      const year = new Date().getFullYear()
      setDatesRange({
        minDate: `${year}-01`,
        maxDate: `${year}-12`
      })
      setSaleSummary(
        createExpenseCenterCost(
          expenseBudgetData.expensesBudgets[selectPeriod],
          selectPeriod,
          true
        )
      )
      if (selectCenter) {
        const centerFind = expenseBudgetData.expensesBudgets[selectPeriod].find(
          (center: any) => center.cost_center_id === selectCenter
        )
        if (centerFind) {
          const categories = centerFind.categories.filter(
            (item: any) => item.name
          )
          setActiveMembers(
            createExpenseCategerory(
              categories,
              selectPeriod,
              true,
              centerFind.cost_center_id
            )
          )
          setDataUpdateMixed(
            centerFind.graphicMonths.map(
              (month: any) =>
                month && {
                  date: month.month,
                  goal: month.expected_value,
                  goalAccomplished: month.realized_value,
                  discount: month.realized_value - month.expected_value,
                  lastYearTarget:
                    Number(month.expected_value) === 0
                      ? 0
                      : (month.realized_value * 100) / month.expected_value
                }
            )
          )
          if (centerFind.suppliers.length) {
            const { percentageExpenseData } = findBiggerPercetage(
              centerFind.suppliers,
              centerFind.realized_value
            )
            setDataUpdateProvider(
              createCategories(
                centerFind.suppliers,
                '#af3f2c',
                percentageExpenseData,
                centerFind.realized_value
              )
            )
            setDataUpdateMapProvider(
              centerFind.suppliers
                .filter((item: any) => item)
                .map(
                  (supply: any) =>
                    supply && {
                      MAIL_ST_PROV_C: supply.supplier_state,
                      value: supply.realized_total_value
                    }
                )
            )
          } else {
            setDataUpdateProvider([])
            setDataUpdateMapProvider([])
          }
          setTimeout(() => {
            disableLoading()
          }, 300)
          return
        }
      } else {
        setDataUpdateMixed(
          expenseBudgetData.graphicData.map(
            (month: any) =>
              month && {
                date: month.month,
                goal: month.expected_value,
                goalAccomplished: month.realized_value,
                discount: month.realized_value - month.expected_value,
                lastYearTarget:
                  Number(month.expected_value) === 0
                    ? 0
                    : (month.realized_value * 100) / month.expected_value
              }
          )
        )
        setDataUpdateProvider([])
        setDataUpdateMapProvider([])
        setActiveMembers([])
      }
      disableLoading()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeLoading,
      disableLoading,
      expenseBudgetData.expensesBudgets,
      expenseBudgetData.graphicData,
      selectedCenterId,
      selectedDate,
      expenseBudgetPeriodData
    ]
  )
  const handleleCategorySet = useCallback(
    async (date: string, category_id: number) => {
      setResetSelected(false)
      setResetToggle(false)
      let selectPeriod: any
      if (
        selectedDate === 'Mes' ||
        selectedDate === 'Mês' ||
        selectedDate.includes('period')
      ) {
        selectPeriod = 'month'
      }
      if (selectedDate === 'Ano') {
        selectPeriod = 'year'
      }
      if (selectedDate === 'Hoje') {
        selectPeriod = 'day'
      }
      if (selectedDate === 'Semana') {
        selectPeriod = 'week'
      }
      activeLoading()
      if (selectedDate.includes('period')) {
        const centerFind = expenseBudgetPeriodData.expensesBudgets[
          selectPeriod
        ].find((center: any) => center.cost_center_id === selectedCenterId)
        if (centerFind) {
          const findCategory = centerFind.categories.find(
            (category: any) => category.category_id === category_id
          )
          if (findCategory) {
            const { percentageExpenseData } = findBiggerPercetage(
              findCategory.suppliers,
              findCategory.realized_value
            )
            setDataUpdateProvider(
              createCategories(
                findCategory.suppliers,
                '#af3f2c',
                percentageExpenseData,
                findCategory.realized_value
              )
            )
            setDataUpdateMapProvider(
              findCategory.suppliers
                .filter((item: any) => item)
                .map(
                  (supply: any) =>
                    supply && {
                      MAIL_ST_PROV_C: supply.supplier_state,
                      value: supply.realized_total_value
                    }
                )
            )
          } else {
            setDataUpdateProvider([])
            setDataUpdateMapProvider([])
          }
        }
        setTimeout(() => {
          disableLoading()
        }, 300)
        return
      }
      const centerFind = expenseBudgetData.expensesBudgets[selectPeriod].find(
        (center: any) => center.cost_center_id === selectedCenterId
      )
      if (centerFind) {
        const findCategory = centerFind.categories.find(
          (category: any) => category.category_id === category_id
        )
        if (findCategory) {
          const { percentageExpenseData } = findBiggerPercetage(
            findCategory.suppliers,
            findCategory.realized_value
          )
          setDataUpdateProvider(
            createCategories(
              findCategory.suppliers,
              '#af3f2c',
              percentageExpenseData,
              findCategory.realized_value
            )
          )
          setDataUpdateMapProvider(
            findCategory.suppliers
              .filter((item: any) => item)
              .map(
                (supply: any) =>
                  supply && {
                    MAIL_ST_PROV_C: supply.supplier_state,
                    value: supply.realized_total_value
                  }
              )
          )
        } else {
          setDataUpdateProvider([])
          setDataUpdateMapProvider([])
        }
        setTimeout(() => {
          disableLoading()
        }, 300)
        return
      }
      disableLoading()
    },
    [
      activeLoading,
      disableLoading,
      expenseBudgetData.expensesBudgets,
      expenseBudgetPeriodData.expensesBudgets,
      findBiggerPercetage,
      selectedCenterId,
      selectedDate
    ]
  )

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      pageTitle={'Controle de Despesas'}
      portletTitle={''}
      breadcrumb={[
        {
          name: 'Início',
          to: '/'
        },
        {
          name: 'Controle de Despesas'
        }
      ]}
      tools={[]}
    >
      <ControlPanel>
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <HeaderPortlet
            handleSetDate={handleSetDate}
            getUserData={() => {}}
            activeMembersData={activeMembers}
            setInitialData={setInitialData}
            actions={['Hoje', 'Semana', 'Mês', 'Ano', 'Período']}
            mainTitle=""
          />
        </div>
        <div className="row">
          <TablePortlet
            className="col-md-6"
            maintitle="Quadro de Despesa por Centro de Custo"
            handleSetDate={handleSetDate}
            setInitialData={setInitialData}
            realData={salesSummary}
            isHighlight={true}
            isHighlightOver={true}
            lastChildStyle="boldGrey"
            stylesChild={{
              th: {
                classname: 'hasBottomBorder'
              },
              column4: {
                classname: 'boldGrey',
                isHighlight: true,
                overAndUnder: true,
                notValidateSymbol: true
              },
              highlightOverColor: '#a0a0a0',
              highlightUnderColor: '#c42f2f'
            }}
            hasDropdown={false}
            initialData={[]}
            actions={[]}
            tableHead={[
              'Centro de Custo',
              'Previsto',
              'Realizado',
              'Balanço',
              'Percentual'
            ]}
          />

          <div className="col-md-6">
            <AmChartMixedColumn
              initialData={[]}
              fillColor="#c42f2f"
              settings={{
                valueAxis1Title: 'Despesa',
                valueAxis2Title: '',
                series2Title: 'Planejamento Despesa',
                series1Title: 'Despesa Realizada',
                series3Title: 'Balanço',
                series4Title: 'Percentual',
                series4TooltipText: '{name}\n[bold font-size: 20]{valueY}%[/]'
              }}
              minDate={datesRange.minDate}
              maxDate={datesRange.maxDate}
              data={dataUpdateMixed}
            />
          </div>
        </div>

        <div className="row">
          <TablePortlet
            className="col-md-7"
            maintitle="Quadro de Despesa por Categoria e Subcategoria"
            handleSetDate={handleleCategorySet}
            setInitialData={() => setInitialData('', selectedCenterId)}
            realData={activeMembers}
            lastChildStyle="boldGrey dropDown"
            initialData={[]}
            actions={[]}
            resetSelected={
              resetSelected && {
                name: '',
                child: {
                  name: ''
                }
              }
            }
            resetToggle={
              resetToggle && {
                name: '',
                isShow: false,
                child: {
                  name: '',
                  isShow: false
                }
              }
            }
            stylesChild={{
              column4: {
                classname: 'dropDown boldGrey',
                isHighlight: true,
                overAndUnder: true,
                notValidateSymbol: true
              },
              th: {
                classname: 'hasBottomBorder',
                0: {
                  width: 250
                }
              },
              highlightOverColor: '#a0a0a0',
              highlightUnderColor: '#c42f2f'
            }}
            tableHead={[
              'Categoria',
              'Previsto',
              'Realizado',
              'Balanço',
              'Percentual'
            ]}
          />
          <div className="col-md-5">
            <AmChartMap
              maintitle="Mapa dos Estados por Fornecedor"
              data={dataUpdateMapProvider}
              initialData={[]}
              tooltip={{
                text: '{name}\n[bold]{stores} Fornecedores[/]\n R$ {valueMonetary}'
              }}
              height="550px"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AmChartPizza
              disableClick
              data={dataUpdateProvider}
              divId="amChartPizzaSupply"
              maintitle="Grafíco de Despesa por Fornecedor"
              style={{ width: '100%', height: '400px' }}
              height="750"
              hasExport
              resizeChart={true}
              styleSettings={{
                legend: {
                  maxWidth: 900
                }
              }}
            />
          </div>
        </div>
      </ControlPanel>
    </Container>
  )
}

export default FinancialExpenses
