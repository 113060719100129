import React from 'react'
import { Tab } from '../../presentation/components/tabs/index'
import { ProductProvider } from '../../presentation/providers/product/ProductProvider'
import Container from '../../../../../../components/Container'
import { breadcrumbUpdate } from '../../domain/data/breadcrumb/update'
import { namePageTitle, nameActions } from '../../domain/data/info'
import { TabsProvider } from '../../../../../../hooks/tabs'
import { tabsList } from '../../presentation/components/tabs/tabsList'
import { useParams } from 'react-router-dom'
import { toolsUpdate } from '../../domain/data/tools'

const UpdateProductPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()

  return (
    <TabsProvider>
      <ProductProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.update.name}
          breadcrumb={breadcrumbUpdate}
          tools={[toolsUpdate]}
        >
          <Tab tabList={tabsList} id={id} />
        </Container>
      </ProductProvider>
    </TabsProvider>
  )
}

export default UpdateProductPage
