import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Container,
  ContainerActionsData,
  CostCenterTotal,
  WrapperActionsData
} from './styles'
import { genericMaskWithTwoZeroWithPoint } from '../../../../../utlis/mask'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'
import currency from 'currency.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from '../../../../../components/Alert'
import { useHistory, useLocation } from 'react-router'
import Button from '../../../../../components/Button'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'

interface StylesChildProps {
  th?: {
    classname: string
  }
  column4?: {
    classname: string
  }
}

interface ActionTab {
  name: string
  active: boolean
  categories: ProductCategory[]
}

type ProductCategory = {
  id: number
  name: string
  active: boolean
  [month: string]: any
}

interface TablePortletWithInputsProps {
  maintitle?: string
  subtitle?: string
  tableHead?: string[]
  isHighlight?: boolean
  stylesChild?: StylesChildProps
  setUpdate: (value: boolean) => void
  update: boolean
}

const TablePortletWithInputs: React.FC<TablePortletWithInputsProps> = ({
  maintitle,
  subtitle,
  tableHead,
  stylesChild,
  setUpdate,
  update
}) => {
  const history = useHistory()
  const location = new URLSearchParams(useLocation().search)

  const [year, setYear] = useState<string>('')
  const [id, setID] = useState<string>('')

  const [toggleCheckbox, setToggleCheckbox] = useState<any>({})
  const [actionTabs, setActionTabs] = useState<ActionTab[]>([])
  const [alertCopyAll, setAlertCopyAll] = useState(false)
  const [currentCopy, setCurrentCopy] = useState({
    event: '',
    month: '',
    tabIndex: 0,
    tabName: '',
    index: 0
  })

  const [selectedItem, setSelectedItem] = useState({
    index: 0,
    name: 'VENDA'
  })

  const [percentage, setPercentage] = useState('')

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const [totalSum, setTotal] = useState<any>({})
  const months = useMemo(() => {
    return [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez'
    ]
  }, [])
  const monthsCodes = [
    ['jan', '01'],
    ['fev', '02'],
    ['mar', '03'],
    ['abr', '04'],
    ['mai', '05'],
    ['jun', '06'],
    ['jul', '07'],
    ['ago', '08'],
    ['set', '09'],
    ['out', '10'],
    ['nov', '11'],
    ['dez', '12']
  ]

  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const [formError, setFormError] = useState({
    company: false,
    year: {
      message: 'O campo Ano é obrigatório!',
      error: false
    }
  })

  const handleItemSubtract = useCallback(
    (tabIndex: number, index: number, tabName: string) => {
      let total = totalSum

      months.forEach(month => {
        if (actionTabs[tabIndex].categories[index][month]) {
          const oldValue = actionTabs[tabIndex].categories[index][month].value
          actionTabs[tabIndex].categories[index][month] = {
            ...actionTabs[tabIndex].categories[index][month],
            value: undefined
          }
          actionTabs[actionTabs.length - 1].categories[index][month] = {
            ...actionTabs[actionTabs.length - 1].categories[index][month],
            value: BRL(
              actionTabs[actionTabs.length - 1].categories[index][month]?.value
            ).subtract(oldValue)
          }
          actionTabs[actionTabs.length - 1].categories[index].total = BRL(
            actionTabs[actionTabs.length - 1].categories[index]?.total
          )
            .subtract(oldValue)
            .format()

          total = {
            ...total,
            [tabName]: {
              ...total[tabName],
              [month]: BRL(total[month]).subtract(oldValue).format(),
              total: BRL(total.total).subtract(oldValue).format()
            },
            TOTAL: {
              ...total.TOTAL,
              [month]: BRL(total.TOTAL?.[month]).subtract(oldValue).format(),
              total: BRL(total.TOTAL?.total).subtract(oldValue).format()
            }
          }
        }
      })
      actionTabs[tabIndex].categories[index].total = ''
      setTotal(total)
    },
    [totalSum, months, actionTabs]
  )

  const handleToggleCheckbox = useCallback(
    (tabName, name, tabIndex, index) => {
      if (!toggleCheckbox[name]?.isShow) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [tabName]: {
            ...toggleCheckbox[tabName],
            [name]: {
              isShow: true
            }
          },
          TOTAL: {
            ...toggleCheckbox.TOTAL,
            [name]: {
              ...toggleCheckbox.TOTAL?.[name],
              name: name,
              isShow: true
            }
          }
        })
        return
      }
      if (toggleCheckbox[name]?.isShow) {
        setToggleCheckbox({
          ...toggleCheckbox,
          [tabName]: {
            ...toggleCheckbox[tabName],
            [name]: {
              isShow: false
            }
          }
        })
        handleItemSubtract(tabIndex, index, tabName)
      }
    },
    [handleItemSubtract, toggleCheckbox]
  )

  const handleGetAllTotal = useCallback(
    (month, tabName) => {
      let total = '0'
      let totalAll = '0'
      let totalParentAll = '0'
      let totalMonthCategory = '0'
      let totalTotalCategory = '0'

      const tab = actionTabs.find(c => c.name === tabName)
      tab.categories.forEach(item => {
        if (!isNaN(BRL(item.total).intValue)) {
          totalAll = BRL(item.total).add(totalAll).format()
        }
        if (!isNaN(BRL(item[month]?.value).intValue)) {
          total = BRL(item[month]?.value).add(total).format()
        }
        months.forEach(month => {
          if (!isNaN(BRL(item[month]?.value).intValue)) {
            totalParentAll = BRL(item[month]?.value)
              .add(totalParentAll)
              .format()
          }
        })
      })
      actionTabs.forEach(item => {
        if (item.name !== 'TOTAL') {
          if (item.name === tabName) {
            totalMonthCategory = BRL(total).add(totalMonthCategory).format()
            totalTotalCategory = BRL(totalAll).add(totalTotalCategory).format()
          } else {
            totalMonthCategory = BRL(totalSum[item.name]?.[month])
              .add(totalMonthCategory)
              .format()
            totalTotalCategory = BRL(totalSum[item.name]?.total)
              .add(totalTotalCategory)
              .format()
          }
        }
      })

      setTotal((prev: any) => ({
        ...prev,
        [tabName]: {
          ...prev[tabName],
          [month]: total !== '0' ? total : '',
          total: totalAll !== '0' ? totalAll : ''
        },
        TOTAL: {
          ...prev.TOTAL,
          [month]: totalMonthCategory,
          total: totalTotalCategory
        }
      }))
    },
    [actionTabs, months, totalSum]
  )

  const handleGetTotal = useCallback(
    (event, month, tabIndex, tabName, index) => {
      if (!event) return
      const categoryTotal = actionTabs.find(c => c.name === 'TOTAL')
      if (actionTabs[tabIndex].categories[index][month]?.value) {
        let total = '0'
        actionTabs[tabIndex].categories[index][month] = {
          ...actionTabs[tabIndex].categories[index][month],
          value: genericMaskWithTwoZeroWithPoint(event)
        }
        months.forEach(item => {
          if (
            actionTabs[tabIndex].categories[index][item]?.value !== undefined
          ) {
            total = BRL(actionTabs[tabIndex].categories[index][item].value)
              .add(total)
              .format()
          }
        })
        actionTabs[tabIndex].categories[index].total = total

        let totalCategory = '0'
        let totalMonth = '0'
        actionTabs.forEach(tab => {
          if (tab.name !== 'TOTAL') {
            totalCategory = BRL(tab.categories[index][month]?.value)
              .add(totalCategory)
              .format()
            totalMonth = BRL(tab.categories[index]?.total)
              .add(totalMonth)
              .format()
          }
        })
        categoryTotal.categories[index][month] = {
          ...categoryTotal.categories[index][month],
          value: totalCategory
        }
        categoryTotal.categories[index] = {
          ...categoryTotal.categories[index],
          total: totalMonth
        }

        handleGetAllTotal(month, tabName)

        total = '0'
        return
      }
      actionTabs[tabIndex].categories[index][month] = {
        ...actionTabs[tabIndex].categories[index][month],
        value: genericMaskWithTwoZeroWithPoint(event)
      }
      actionTabs[tabIndex].categories[index].total = actionTabs[tabIndex]
        .categories[index].total
        ? BRL(genericMaskWithTwoZeroWithPoint(event))
            .add(
              genericMaskWithTwoZeroWithPoint(
                actionTabs[tabIndex].categories[index].total
              )
            )
            .format()
        : genericMaskWithTwoZeroWithPoint(event)
      handleGetAllTotal(month, tabName)
    },
    [actionTabs, handleGetAllTotal, months]
  )
  const copyAll = useCallback(
    (event, month, tabIndex, tabName, index) => {
      if (!event) return
      const categoryTotal = actionTabs.find(c => c.name === 'TOTAL')
      let current = false
      months.forEach(item => {
        if (item === month) {
          current = true
        }
        if (current) {
          let total = '0'
          actionTabs[tabIndex].categories[index][item] = {
            ...actionTabs[tabIndex].categories[index][item],
            value: genericMaskWithTwoZeroWithPoint(event)
          }
          months.forEach(item => {
            if (
              actionTabs[tabIndex].categories[index][item]?.value !== undefined
            ) {
              total = BRL(actionTabs[tabIndex].categories[index][item].value)
                .add(total)
                .format()
            }
          })
          actionTabs[tabIndex].categories[index].total = total
          let totalCategory = '0'
          let totalMonth = '0'
          actionTabs.forEach(tab => {
            if (tab.name !== 'TOTAL') {
              totalCategory = BRL(tab.categories[index][item]?.value)
                .add(totalCategory)
                .format()
              totalMonth = BRL(tab.categories[index]?.total)
                .add(totalMonth)
                .format()
            }
          })
          categoryTotal.categories[index][item] = {
            ...categoryTotal.categories[index][item],
            value: totalCategory
          }
          categoryTotal.categories[index] = {
            ...categoryTotal.categories[index],
            total: totalMonth
          }

          handleGetAllTotal(item, tabName)

          total = '0'
        }
      })
    },
    [months, actionTabs, handleGetAllTotal]
  )

  const handleOnClickCopyAll = () => {
    setAlertCopyAll(false)
    const { event, month, tabIndex, tabName, index } = currentCopy
    handleGetTotal(event, month, tabIndex, tabName, index)
  }

  const handlerOnClickButtonConfirmCopyAll = async () => {
    setAlertCopyAll(false)
    const { event, month, tabIndex, tabName, index } = currentCopy
    copyAll(event, month, tabIndex, tabName, index)
  }

  const loadOne = useCallback(
    async (tabs: any) => {
      const monthsCodes = [
        ['jan', '01'],
        ['fev', '02'],
        ['mar', '03'],
        ['abr', '04'],
        ['mai', '05'],
        ['jun', '06'],
        ['jul', '07'],
        ['ago', '08'],
        ['set', '09'],
        ['out', '10'],
        ['nov', '11'],
        ['dez', '12']
      ]

      const { data } = await api.get('/financial/productCategoryBudget/view/', {
        params: {
          year
        }
      })
      if (data) {
        setUpdate(true)
        history.replace(
          `/financial/productCategoryBudget/update?year=${year}&id=${id}&edit=1`
        )
        let total: any = {}
        let toggleCheckbox: any = {}
        for (const e of data) {
          const value = genericMaskWithTwoZeroWithPoint(e.expected_value)
          const tab = tabs.find((s: { name: any }) => s.name === e.type)
          const costCenterTotal = tabs.find(
            (a: { name: any }) => a.name === 'TOTAL'
          )
          if (!tab) continue
          if (!costCenterTotal) continue
          const sub = tab.categories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          const subTotal = costCenterTotal.categories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          if (!sub) continue
          if (!subTotal) continue
          const code = monthsCodes.find(mC => mC[1] === e.month)
          sub[code[0]] = {
            id: e.id,
            value: value
          }
          sub.total = BRL(value).add(sub.total).format()
          subTotal[code[0]] = {
            value: BRL(subTotal[code[0]]?.value).add(value).format()
          }
          subTotal.total = BRL(value).add(subTotal.total).format()
          total = {
            ...total,
            [tab.name]: {
              ...total[tab.name],
              [code[0]]: BRL(total[tab.name]?.[code[0]]).add(value).format(),
              total: BRL(total[tab.name]?.total).add(value).format()
            },
            TOTAL: {
              ...total.TOTAL,
              [code[0]]: BRL(total.TOTAL?.[code[0]]).add(value).format(),
              total: BRL(total.TOTAL?.total).add(value).format()
            }
          }
          toggleCheckbox = {
            ...toggleCheckbox,
            [tab.name]: {
              ...toggleCheckbox[tab.name],
              [sub.name]: {
                ...toggleCheckbox[sub.name],
                isShow: true
              }
            },
            TOTAL: {
              ...toggleCheckbox.TOTAL,
              [sub.name]: {
                ...toggleCheckbox.TOTAL?.[sub.name],
                name: sub.name,
                isShow: true
              }
            }
          }
        }

        setTotal(total)
        setToggleCheckbox(toggleCheckbox)
      } else {
        setUpdate(false)
        history.push('/financial/productCategoryBudget/create')
      }
      disableLoading()
    },
    [disableLoading, history, id, setUpdate, year]
  )

  const loadCategories = useCallback(async () => {
    const { data } = await api.get<ProductCategory[]>(
      '/warehouse/productCategories/listGenerateRevenue'
    )
    const list = data.map(category => ({
      id: category.id,
      name: category.name,
      active: false
    }))
    const actionsTab = ['VENDA', 'LOCACAO', 'TOTAL'].map(action => {
      const copy = JSON.stringify(list)
      const copy2 = JSON.parse(copy)
      return {
        name: action,
        active: false,
        categories: copy2
      }
    })
    if (actionsTab.length > 0) actionsTab[0].active = true

    setActionTabs(actionsTab)
    if (year?.length === 4) {
      setTotal({})
      setToggleCheckbox({})

      await loadOne(actionsTab)
    }
    if (!year) {
      disableLoading()
    }
  }, [disableLoading, loadOne, year])

  useEffect(() => {
    if (year.length === 4) {
      setFormError(prev => ({
        ...prev,
        year: {
          ...prev.year,
          error: false
        }
      }))
    }
    if (year?.length === 4) {
      ;(async () => {
        const { data } = await api.get(
          '/financial/productCategoryBudget/view/',
          {
            params: {
              year
            }
          }
        )
        if (data) loadCategories()
      })()
    } else {
      disableLoading()
    }
  }, [disableLoading, id, loadCategories, year])

  useEffect(() => {
    activeLoading()
    loadCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading])

  useEffect(() => {
    if (location.get('year')) {
      setYear(location.get('year'))
    }
    if (location.get('id')) {
      setID(location.get('id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function validateCompanyYear() {
    if (year.length !== 4) {
      addToast({
        type: 'error',
        title: 'Campos incorretos',
        description: 'É necessário informar a empresa e o ano.'
      })
      if (year.length < 4) {
        setFormError(prev => ({
          ...prev,
          year: {
            ...prev.year,
            error: true
          }
        }))
      }
      return false
    }
    return true
  }

  async function handleSaveButton() {
    const validate = validateCompanyYear()
    if (!validate) return

    activeLoading()
    const final: any = {
      year,
      categories: [],
      deletes: []
    }

    actionTabs.forEach(tab => {
      if (tab.name !== 'TOTAL') {
        tab.categories.forEach(category => {
          Object.keys(category).forEach(a => {
            if (months.includes(a)) {
              const code = monthsCodes.find(mC => mC[0] === a)
              if (category[a].value) {
                const value = BRL(category[a].value).value
                final.categories.push({
                  id: category[a].id,
                  type: tab.name,
                  category_id: category.id,
                  month: code[1],
                  expected_value: Number(value)
                })
              } else {
                if (category[a].id) {
                  final.deletes.push(category[a].id)
                }
              }
            }
          })
        })
      }
    })
    if (update) {
      try {
        await api.put('/financial/productCategoryBudget/update', { ...final })
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
        await loadCategories()
        setActionTabs(prev => {
          const newTabs = prev.map(newTab => ({
            ...newTab,
            active: newTab.name === selectedItem.name
          }))
          return newTabs
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    } else {
      final.deletes = undefined
      try {
        await api.post('/financial/productCategoryBudget/create', { ...final })
        history.push('/financial/productCategoryBudget')
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  function getLastYearValues(hasPercentage: boolean) {
    activeLoading()
    const validate = validateCompanyYear()
    if (!validate) {
      disableLoading()
      return
    }
    ;(async () => {
      const lastYear = Number(year) - 1
      const { data } = await api.get('/financial/productCategoryBudget/view/', {
        params: {
          year: lastYear
        }
      })
      if (data) {
        let total: any = {}
        let toggleCheckbox: any = {}
        for (const e of data) {
          let value = genericMaskWithTwoZeroWithPoint(e.expected_value)
          if (hasPercentage) {
            const percentageValue = percentage.replace('%', '')
            if (!percentageValue) {
              disableLoading()
              return
            }
            const percentageFinal = BRL(
              genericMaskWithTwoZeroWithPoint(e.expected_value)
            ).multiply(BRL(percentageValue).divide(100))
            value = BRL(genericMaskWithTwoZeroWithPoint(e.expected_value))
              .add(percentageFinal)
              .format()
          }
          const tab = actionTabs.find((s: { name: any }) => s.name === e.type)
          const costCenterTotal = actionTabs.find(
            (a: { name: any }) => a.name === 'TOTAL'
          )
          if (!tab) continue
          if (!costCenterTotal) continue
          const subTotal = costCenterTotal.categories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          const sub = tab.categories.find(
            (s: { id: any }) => s.id === e.category_id
          )
          if (!sub) continue
          if (!subTotal) continue

          const code = monthsCodes.find(mC => mC[1] === e.month)
          sub[code[0]] = {
            value: value
          }
          sub.total = BRL(value).add(sub.total).format()
          subTotal[code[0]] = {
            value: BRL(subTotal[code[0]]?.value).add(value).format()
          }
          subTotal.total = BRL(value).add(subTotal.total).format()

          total = {
            ...total,
            [tab.name]: {
              ...total[tab.name],
              [code[0]]: BRL(total[tab.name]?.[code[0]]).add(value).format(),
              total: BRL(total[tab.name]?.total).add(value).format()
            },
            TOTAL: {
              ...total.TOTAL,
              [code[0]]: BRL(total.TOTAL?.[code[0]]).add(value).format(),
              total: BRL(total.TOTAL?.total).add(value).format()
            }
          }
          toggleCheckbox = {
            ...toggleCheckbox,
            [tab.name]: {
              ...toggleCheckbox[tab.name],
              [sub.name]: {
                ...toggleCheckbox[sub.name],
                isShow: true
              }
            },
            TOTAL: {
              ...toggleCheckbox.TOTAL,
              [sub.name]: {
                ...toggleCheckbox.TOTAL?.[sub.name],
                name: sub.name,
                isShow: true
              }
            }
          }
        }
        setTotal(total)
        setToggleCheckbox(toggleCheckbox)
      } else {
        addToast({
          type: 'error',
          title: 'Nenhum registro encontrado para o ano anterior'
        })
      }
    })()
    disableLoading()
  }

  const handleSwitchPeriod = useCallback(
    (index: number, name: string) => {
      if (index === selectedItem.index) {
        setSelectedItem({
          index: 0,
          name: 'VENDA'
        })
        return
      }
      setSelectedItem({
        index,
        name
      })
    },
    [selectedItem.index]
  )

  const activeActionTab = (name: string, index: number) => {
    handleSwitchPeriod(index, name)
    setActionTabs(prev => {
      const newTabs = prev.map(newTab => ({
        ...newTab,
        active: newTab.name === name
      }))
      return newTabs
    })
  }

  return (
    <>
      <Container>
        <div className="row">
          <Form>
            <div className="col-md-3">
              <Input
                hasError={formError.year}
                type="text"
                onKeyPress={event => {
                  const regex = /^[0-9.]+$/
                  if (!regex.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                maxLength={4}
                className="form-control"
                label="Ano"
                name="year"
                value={year}
                onChange={e => setYear(e.target.value)}
                readOnly={update}
              />
            </div>
          </Form>
        </div>
        <div className="portlet light ">
          {maintitle && (
            <div className="portlet-title">
              <div className="caption caption-md">
                <i className="icon-bar-chart font-dark hide"></i>
                <span className="maintitle">{maintitle}</span>
              </div>
            </div>
          )}
          <div className="portlet-body">
            {actionTabs.length > 0 && (
              <div className="row">
                <table className="table table-light custom">
                  <thead>
                    <tr className="TableHeaderActions">
                      {actionTabs.map(({ name, active }, index) => (
                        <th
                          key={name}
                          onClick={() => activeActionTab(name, index)}
                          className="TableHeadActions"
                        >
                          <div
                            className={`btn btn-transparent btn-no-border btn-outline ${
                              active && 'active'
                            }`}
                          >
                            {name}
                          </div>
                          <CostCenterTotal>
                            {!totalSum[name]?.total ||
                            totalSum[name]?.total === '0,00'
                              ? ''
                              : 'R$ ' + totalSum[name]?.total}
                          </CostCenterTotal>
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>
            )}
            <div className="portlet light custom">
              <div className="portlet-title">
                <div className="caption caption-md">
                  <i className="icon-bar-chart font-dark hide"></i>
                  <span className="subtitle">{subtitle}</span>
                </div>
              </div>
            </div>
            {!update && (
              <ContainerActionsData>
                <WrapperActionsData>
                  <button onClick={() => getLastYearValues(false)}>
                    Dados Ano Anterior
                  </button>
                  <div className="WrapperPercentage">
                    <Input
                      name="fev"
                      placeholder="%  0,00"
                      value={percentage}
                      onChange={event =>
                        setPercentage(
                          '%  ' +
                            genericMaskWithTwoZeroWithPoint(event.target.value)
                        )
                      }
                    />
                  </div>
                  <button onClick={() => getLastYearValues(true)}>
                    Calcular
                  </button>
                </WrapperActionsData>
              </ContainerActionsData>
            )}
            {actionTabs.map(
              ({ name, active, categories }, tabIndex) =>
                active && (
                  <div key={name}>
                    <table className="table table-light">
                      <thead className="tableHead">
                        <tr>
                          {tableHead.map((item, index) =>
                            index === 0 ? (
                              <th
                                key={`${item}`}
                                className={
                                  index !== 0
                                    ? stylesChild.th?.classname
                                    : `${stylesChild.th?.classname} firstTableHead`
                                }
                              ></th>
                            ) : (
                              <th
                                key={`${item}`}
                                className={
                                  index !== 0
                                    ? stylesChild.th?.classname
                                    : `${stylesChild.th?.classname} firstTableHead`
                                }
                              >
                                {item}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {categories?.map((item, index) => (
                          <React.Fragment key={item.name}>
                            <tr className="trChild">
                              <td className="firstChild">
                                {name !== 'TOTAL' && (
                                  <input
                                    type="checkbox"
                                    name={`check${index + item.name}`}
                                    id={`check${index + item.name}`}
                                    onClick={() =>
                                      handleToggleCheckbox(
                                        name,
                                        item.name,
                                        tabIndex,
                                        index
                                      )
                                    }
                                    defaultChecked={
                                      toggleCheckbox[name]?.[item.name]?.isShow
                                    }
                                  />
                                )}
                                <label htmlFor={`check${index + item.name}`}>
                                  {item.name}
                                </label>
                              </td>
                              {toggleCheckbox[name]?.[item.name]?.isShow ? (
                                <>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.jan?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.jan?.value,
                                                month: 'jan',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="jan"
                                          value={item.jan?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'jan',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.fev?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.fev?.value,
                                                month: 'fev',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="fev"
                                          value={item.fev?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'fev',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.mar?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.mar?.value,
                                                month: 'mar',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="mar"
                                          value={item.mar?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'mar',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.abr?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.abr?.value,
                                                month: 'abr',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="abr"
                                          value={item.abr?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'abr',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.mai?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.mai?.value,
                                                month: 'mai',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="mai"
                                          value={item.mai?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'mai',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.jun?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.jun?.value,
                                                month: 'jun',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="jun"
                                          value={item.jun?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'jun',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.jul?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.jul?.value,
                                                month: 'jul',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="jul"
                                          value={item.jul?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'jul',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.ago?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.ago?.value,
                                                month: 'ago',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="ago"
                                          value={item.ago?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'ago',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.set?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.set?.value,
                                                month: 'set',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="set"
                                          value={item.set?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'set',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.out?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.out?.value,
                                                month: 'out',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="out"
                                          value={item.out?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'out',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.nov?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        {
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            onClick={() => {
                                              setAlertCopyAll(true)
                                              setCurrentCopy({
                                                event: item.nov?.value,
                                                month: 'nov',
                                                tabIndex,
                                                tabName: name,
                                                index
                                              })
                                            }}
                                          />
                                        }
                                        <Input
                                          name="nov"
                                          value={item.nov?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'nov',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData">
                                    {name === 'TOTAL' ? (
                                      <div>{item.dez?.value || ''}</div>
                                    ) : (
                                      <div className="Border">
                                        <Input
                                          name="dez"
                                          value={item.dez?.value || ''}
                                          onChange={event =>
                                            handleGetTotal(
                                              event.target.value,
                                              'dez',
                                              tabIndex,
                                              name,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="TableData total-category">
                                    {item.total !== '0,00' && item.total}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                  <td className="TableData"></td>
                                </>
                              )}
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="TableRowTotal">
                          <td>SOMA TOTAL</td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.jan !== '0,00' &&
                              totalSum[selectedItem?.name]?.jan}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.fev !== '0,00' &&
                              totalSum[selectedItem?.name]?.fev}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.mar !== '0,00' &&
                              totalSum[selectedItem?.name]?.mar}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.abr !== '0,00' &&
                              totalSum[selectedItem?.name]?.abr}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.mai !== '0,00' &&
                              totalSum[selectedItem?.name]?.mai}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.jun !== '0,00' &&
                              totalSum[selectedItem?.name]?.jun}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.jul !== '0,00' &&
                              totalSum[selectedItem?.name]?.jul}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.ago !== '0,00' &&
                              totalSum[selectedItem?.name]?.ago}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.set !== '0,00' &&
                              totalSum[selectedItem?.name]?.set}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.out !== '0,00' &&
                              totalSum[selectedItem?.name]?.out}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.nov !== '0,00' &&
                              totalSum[selectedItem?.name]?.nov}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.dez !== '0,00' &&
                              totalSum[selectedItem?.name]?.dez}
                          </td>
                          <td className="BoldBlack">
                            {totalSum[selectedItem?.name]?.total !== '0,00' &&
                              totalSum[selectedItem?.name]?.total}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="ContainerButton">
          <Button
            onClick={handleSaveButton}
            type="submit"
            className="btn dark btn-sm sbold uppercase"
          >
            Salvar
          </Button>
        </div>
      </Container>
      <Alert
        message={'Deseja copiar para todos os meses?'}
        onClickCancellButton={handleOnClickCopyAll}
        onClickConfirmButton={handlerOnClickButtonConfirmCopyAll}
        isActive={alertCopyAll}
        title="Copiar"
        textConfim="SIM"
        textCancel="NÃO"
      />
    </>
  )
}

export { TablePortletWithInputs }
