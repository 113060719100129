import React from 'react'
import PrivateRoutes from '../../Route'
import List from '../../../pages/Warehouse/Expedition/OrdersStatus/List'
import Create from '../../../pages/Warehouse/Expedition/OrdersStatus/Create'
import Update from '../../../pages/Warehouse/Expedition/OrdersStatus/Update'
import View from '../../../pages/Warehouse/Expedition/OrdersStatus/View'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const ExpeditionOrdersStatusRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/warehouse/expedition/ordersStatus"
      exact
      component={List}
    />
    <PrivateRoutes
      path="/warehouse/expedition/ordersStatus/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/warehouse/expedition/ordersStatus/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/warehouse/expedition/ordersStatus/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
