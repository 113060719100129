import React, { HTMLAttributes, ReactNode } from 'react'
interface ContentCustoSummaryProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}
export function ContentCustomSummary({ children }: ContentCustoSummaryProps) {
  return (
    <div className="portlet-body">
      <div className="container">{children}</div>
    </div>
  )
}
