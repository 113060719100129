import React from 'react'
import { Tab } from '../presentation/components/tabs/index'
import { ClientProvider } from '../presentation/providers/ClientProvider'
import Container from '../../../../components/Container'
import { breadcrumbUpdate } from '../domain/breadcrumb/update'
import { namePageTitle, nameActions } from '../domain/info'
import { TabsProvider } from '../../../../hooks/tabs'
import { tabsList } from '../presentation/components/tabs/tabsList'
import { useParams } from 'react-router-dom'
import { toolsUpdate } from '../domain/tools'

const UpdateProductPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()

  return (
    <TabsProvider>
      <ClientProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.update.name}
          breadcrumb={breadcrumbUpdate}
          tools={[toolsUpdate]}
        >
          <Tab tabList={tabsList} id={id} />
        </Container>
      </ClientProvider>
    </TabsProvider>
  )
}

export default UpdateProductPage
