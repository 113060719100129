import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  Container,
  DropdownDate,
  DateButton,
  WrapperDates,
  ContainerInput
} from './styles'
import { FaTimes, FaCalendarAlt } from 'react-icons/fa'

interface DateRangeProps {
  minDate: Date
  maxDate: Date
  minRange: number | undefined
  maxRange: number | undefined
  firstDate: string
  secondDate: string
  firstDateFull: string
  secondDateFull: string
}

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  getUserData?: () => void
  setInitialData(date?: string): void
  handleSwitchPeriod(index: number): void
  handleResetData(): void
  handleSetDate(date: string, customPeriod?: string): void
  isReset: boolean
  initialAction?: string
}
const DatePicker: React.FC<DatePickerProps> = ({
  className,
  handleSwitchPeriod,
  setInitialData,
  handleResetData,
  isReset,
  handleSetDate,
  initialAction,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUserData,
  ...rest
}) => {
  const [months] = useState([
    {
      name: 'Janeiro',
      shortName: 'JAN',
      numberMonth: 1
    },
    {
      name: 'Fevereiro',
      shortName: 'FEV',
      numberMonth: 2
    },
    {
      name: 'Março',
      shortName: 'MAR',
      numberMonth: 3
    },
    {
      name: 'Abril',
      shortName: 'ABR',
      numberMonth: 4
    },
    {
      name: 'Maio',
      shortName: 'MAI',
      numberMonth: 5
    },
    {
      name: 'Junho',
      shortName: 'JUN',
      numberMonth: 6
    },
    {
      name: 'Julho',
      shortName: 'JUL',
      numberMonth: 7
    },
    {
      name: 'Agosto',
      shortName: 'AGO',
      numberMonth: 8
    },
    {
      name: 'Setembro',
      shortName: 'SET',
      numberMonth: 9
    },
    {
      name: 'Outubro',
      shortName: 'OUT',
      numberMonth: 10
    },
    {
      name: 'Novembro',
      shortName: 'NOV',
      numberMonth: 11
    },
    {
      name: 'Dezembro',
      shortName: 'DEZ',
      numberMonth: 12
    }
  ])
  const [currentMonth, setCurrentMonth] = useState('Período')
  const [toggleModal, setToggleModal] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeProps>({
    minDate: new Date(),
    maxDate: new Date(),
    maxRange: undefined,
    minRange: undefined,
    firstDate: '',
    secondDate: '',
    firstDateFull: '',
    secondDateFull: ''
  })

  const verifyRangeDate = (index: number) => {
    if (index <= new Date().getMonth()) {
      return 'disable'
    }
    if (selectedDateRange.minRange === index) {
      return 'selectedDate activeDate'
    }
    return ''
  }

  const handleToggleModal = useCallback(() => {
    handleSwitchPeriod(3)
    setToggleModal(!toggleModal)
  }, [handleSwitchPeriod, toggleModal])

  const handleSetCurrentMonth = useCallback(
    (month: number, monthFullName: string, index: number) => {
      setSelectedDateRange({
        minDate: new Date(),
        maxDate: new Date(),
        minRange: index,
        maxRange: index,
        firstDate: '',
        secondDate: '',
        firstDateFull: '',
        secondDateFull: ''
      })
      setCurrentMonth(`Período de ${monthFullName}`)
      handleSetDate(
        JSON.stringify({
          period: true,
          month: month
        }),
        `de ${monthFullName}`
      )
      handleToggleModal()
    },
    [handleSetDate, handleToggleModal]
  )
  const handleClearCurrentMonth = useCallback(() => {
    setToggleModal(false)
    handleResetData()
    setCurrentMonth('Período')
    setSelectedDateRange({
      minDate: new Date(),
      maxDate: new Date(),
      maxRange: undefined,
      minRange: undefined,
      firstDate: '',
      secondDate: '',
      firstDateFull: '',
      secondDateFull: ''
    })
    setInitialData(initialAction || 'Mês')
  }, [handleResetData, initialAction, setInitialData])
  const handleClearInput = useCallback(() => {
    if (currentMonth === 'Período') {
      setToggleModal(false)
      setCurrentMonth('Período')
    }
  }, [currentMonth])
  useEffect(() => {
    if (isReset) {
      if (currentMonth !== 'Período') {
        setToggleModal(false)
        setCurrentMonth('Período')
        setSelectedDateRange({
          minDate: new Date(),
          maxDate: new Date(),
          maxRange: undefined,
          minRange: undefined,
          firstDate: '',
          secondDate: '',
          firstDateFull: '',
          secondDateFull: ''
        })
      }
    }
  }, [currentMonth, isReset])
  return (
    <Container onMouseLeave={handleClearInput}>
      <ContainerInput className={className}>
        <FaCalendarAlt
          onClick={handleToggleModal}
          style={{ marginRight: '5px', marginBottom: '2px' }}
          size={13}
          className="FaCalendar"
        />
        <input
          readOnly
          className={currentMonth.length > 10 ? 'InputGrowth' : ''}
          type="text"
          value={currentMonth}
          onClick={handleToggleModal}
          style={{
            color: toggleModal && '#fff'
          }}
          {...rest}
        />
        {currentMonth !== 'Período' && (
          <FaTimes onClick={handleClearCurrentMonth} />
        )}
      </ContainerInput>
      {toggleModal && (
        <DropdownDate>
          <WrapperDates>
            {months.map((month, index) => (
              <div key={month.name} className={verifyRangeDate(index)}>
                <DateButton
                  type="button"
                  className={verifyRangeDate(index)}
                  isSelected={
                    selectedDateRange.minRange === index ||
                    index === selectedDateRange.maxRange
                  }
                  onClick={() =>
                    handleSetCurrentMonth(month.numberMonth, month.name, index)
                  }
                >
                  {month.shortName}
                </DateButton>
              </div>
            ))}
          </WrapperDates>
        </DropdownDate>
      )}
    </Container>
  )
}

export { DatePicker }
