import React, { useCallback } from 'react'
import Container from '../../../components/Container'
import DataTable from '../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { apiDelete } from '../domain/api'
import api from '../../../services/api'
import { useLoading } from '../../../hooks/loading'
import { useUpdateDataTable } from '../../../hooks/dataTable'
import Switch from 'react-switch'

const List: React.FC = () => {
  const { activeLoading, disableLoading } = useLoading()
  const { updateDataTable } = useUpdateDataTable()
  const handleDelete = useCallback(
    async (itemId: any) => {
      activeLoading()
      await api.delete(apiDelete(itemId))
      updateDataTable()
      disableLoading()
    },
    [activeLoading, disableLoading, updateDataTable]
  )
  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'entity' }}
        actions={[
          {
            name: 'Remover',
            title: 'Remover',
            onClick: item => handleDelete(item.id),
            spanIcon: 'fa fa-remove'
          }
        ]}
        headers={headers}
        notHasChildren
      />
    </Container>
  )
}
export default List
