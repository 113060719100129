import React from 'react'
import DataTable from '../../../../../../../../../../../components/DataTable'
import moment from 'moment'
import { DATE_MASK_LOCALE_WITH_TIME } from '../../../../../../../../../../../common/constants'

const HEADERS_DATATABLE = [
  { name: 'Data', field: 'created_at', sortable: false, custom: true },
  { name: 'Quantidade', field: 'quantity', sortable: false },
  { name: 'Tipo', field: 'direction', sortable: false, custom: true },
  { name: 'Status', field: 'status', sortable: false, custom: true },
  { name: 'Descrição', field: 'description', sortable: false },
  { name: 'Feito por', field: 'user.name', sortable: false }
]

const DIRECTION_TRANSLATED: Record<string, string> = {
  out: 'Saida',
  in: 'Entrada'
}

const STATUS_TRANSLATED: Record<string, string> = {
  reserved: 'Reservado',
  realized: 'Realizado'
}

export const ListStockMoviments = ({ stock_id }: { stock_id: string }) => {
  return (
    <DataTable
      source="StockMoviments"
      entity="StockMoviments"
      format={{ orderBy: 'id' }}
      notHasChildren
      headers={HEADERS_DATATABLE}
      searchParameters={[{ stock_id }]}
      customHeaders={[
        {
          name: 'Data',
          field: 'created_at',
          sortable: false,
          element: item => {
            return (
              <p>
                {moment(item.created_at, DATE_MASK_LOCALE_WITH_TIME)
                  .add(3, 'h')
                  .format(DATE_MASK_LOCALE_WITH_TIME)}
              </p>
            )
          }
        },
        {
          name: 'Tipo',
          field: 'direction',
          sortable: false,
          element: item => {
            return <p>{DIRECTION_TRANSLATED[item.direction]}</p>
          }
        },
        {
          name: 'Status',
          field: 'status',
          sortable: false,
          element: item => {
            return <p>{STATUS_TRANSLATED[item.status]}</p>
          }
        }
      ]}
    />
  )
}
