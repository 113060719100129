export const DATE_MASK_LOCALE_WITH_TIME = 'DD/MM/YYYY HH:mm:ss'
export const DATE_MASK_LOCALE_WITHOUT_TIME = 'DD/MM/YYYY'
export const DATE_MASK_ISO_WITHOUT_TIME = 'YYYY-MM-DD'
export const CURRENT_TAB_TASK = '@Multfluxo:current-tab-task'
export const MONTHS: Record<string, number> = {
  Janeiro: 1,
  Fevereiro: 2,
  Março: 3,
  Abril: 4,
  Maio: 5,
  Junho: 6,
  Julho: 7,
  Agosto: 8,
  Setembro: 9,
  Outubro: 10,
  Novembro: 11,
  Dezembro: 12
}
export const MONTHS_ORDER = Object.keys(MONTHS)
export const TRANSLATE_MONTHS: Record<string, string> = {
  January: 'Janeiro',
  February: 'Fevereiro',
  March: 'Março',
  April: 'Abril',
  May: 'Maio',
  June: 'Junho',
  July: 'Julho',
  August: 'Agosto',
  September: 'Setembro',
  October: 'Outubro',
  November: 'Novembro',
  December: 'Dezembro'
}
