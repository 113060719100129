import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Select, Textarea } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useAuth } from '../../../../../hooks/auth'

type DefaultedOrderData = {
  client_id: number
  created_at: string
  days_delay: number
  deleted_at: null
  due_date: string
  financial_transaction_id: number
  id: number
  notes: string
  order_id: number
  payment_date: string
  reconsidered: false
  reconsidered_date: string
  reconsidered_user: string
  updated_at: string
  client: {
    id: number
    name: string
  }
  defaultedOrders: DefaultedOrderData[]
}

type TypesFormProps = {
  initialValues?: DefaultedOrderData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormDefaultedOrders = ({
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const { user } = useAuth()
  const [defaultValues, setDefaultValues] = useState<DefaultedOrderData>()
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const onSubmit = async (data: DefaultedOrderData) => {
    if (typeForm === 'create') {
      try {
        const dataCreate = {
          ...data
        }
        activeLoading()
        await api.post(apiCreate(), dataCreate)
        disableLoading()
        history.push(nameActions.read.to)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
        updateDataTable()
      }
    } else {
      const dataUpdate = {
        ...data,
        reconsidered_user: user.id
      }
      const id = initialValues?.idUpdate

      try {
        activeLoading()
        await api.put(apiUpdate(String(id)), dataUpdate)
        updateDataTable()
        disableLoading()
        history.push(nameActions.read.to)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        history.push(nameActions.read.to)
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      <div className="row">
        <div className="col-md-6">
          <Select
            options={[
              {
                name: 'Sim',
                value: 'true'
              },
              {
                name: 'Não',
                value: 'false'
              }
            ]}
            label="Liberado"
            name="reconsidered"
            className="form-control"
            defaultValue={''}
            blank
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Textarea
            label="Observação"
            name="notes"
            className="form-control"
            style={{ minHeight: 200 }}
            rules={{ required: true }}
          />
        </div>
      </div>
      <hr
        style={{
          position: 'relative',
          width: '102.9%',
          margin: '15px auto',
          transform: 'translateX(-1.4%)'
        }}
      />
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
