import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../../components/Container'
import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import { FormStatus } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions, namePageTitle } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools/update'
import { useToast } from '../../../../../hooks/toast'

const CostCentersUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [status, setStatus] = useState<{
    id: string
    name: string
    dashboard: number
  }>()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setStatus(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o Centro de custo',
          description: 'Houve um error ao carregar, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }

    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location])
  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={[toolsUpdate]}
    >
      <div className="form-body">
        <FormStatus
          initialValues={{
            idUpdate: Number(status?.id),
            ...status
          }}
          typeForm="update"
        />
      </div>
    </Container>
  )
}

export default CostCentersUpdate
