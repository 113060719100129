/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react'
import { namePageTitle } from '../domain/info'
import { headers, notFoundheaders } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { ContainerWrapper, FilterContaier, ListTitle } from './styles'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import { Date as DatePicker } from '../../../../components/Form/date'
import moment from 'moment'
import CustomDataTable from '../components/CustomDataTable'
import { useForm } from 'react-hook-form'
import { Alert } from '../../../../components/Alert'
import { Loading } from '../../../../components/Loading'
import { Link } from 'react-router-dom'

interface FilterOptions {
  operationType?: string
  startDate: Date
  endDate: Date
}

const BankReconciliationList = (): JSX.Element => {
  const { addToast } = useToast()
  const { handleSubmit, register, watch, setValue, reset } = useForm()
  const { activeLoading, disableLoading, loading } = useLoading()
  const [transactionsBudget, setTransactionsBudget] = useState([])
  const [transactionsIncome, setTransactionsIncome] = useState([])
  const [notFoundtransactions, setNotFoundTransactions] = useState([])
  const [filterNotFoundtransactions, setFilterNotFoundTransactions] = useState(
    []
  )
  const [filterTransactionsBudget, setFilterTransactionsBudget] = useState([])
  const [filterTransactionsIncome, setFilterTransactionsIncome] = useState([])
  const [disabledButtonBudget, setDisabledButtonBudget] = useState(false)
  const [disabledButtonIncome, setDisabledButtonIncome] = useState(false)
  const [operationType, setOperationType] = useState('')
  const selectedAll = watch('markAll')
  const selectedIncomeAll = watch('markIncomeAll')
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
    title: string
    data?: any
    type?: string
  }>({
    id: 0,
    isActive: false,
    name: '',
    title: ''
  })
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('W').toDate()
  )
  const [endDate, setEndDate] = useState<Date>(moment().toDate())

  const handleFilter = useCallback(
    async ({ operationType, startDate, endDate }: FilterOptions) => {
      activeLoading()
      const dateFormat = 'YYYY-MM-DD'
      try {
        const response = await api.post('/financial/bankReconciliations', {
          operationType,
          startDate: startDate
            ? moment(startDate).format(dateFormat)
            : moment().startOf('W').format(dateFormat),
          endDate: endDate
            ? moment(endDate).format(dateFormat)
            : moment().endOf('W').format(dateFormat)
        })
        setTransactionsBudget(response.data.localizedBudgetTransactions)
        setFilterTransactionsBudget(response.data.localizedBudgetTransactions)
        setTransactionsIncome(response.data.localizedIncomeTransactions)
        setFilterTransactionsIncome(response.data.localizedIncomeTransactions)
        setNotFoundTransactions(response.data.notLocalizedTransactions)
        setFilterNotFoundTransactions(response.data.notLocalizedTransactions)
        disableLoading()
      } catch (error) {
        disableLoading()
      }
      reset({ markIncomeAll: false, markAll: false })
      reset()
      const fields = watch()
      for (const [key, value] of Object.entries(fields)) {
        if (typeof value === 'object') {
          const dataArray = value.entries()
          for (const [subKey, subValue] of dataArray) {
            if (subValue) {
              setValue(`${key}.${subKey}.active`, false)
            }
          }
        }
      }
    },
    [activeLoading, disableLoading, reset, setValue, watch]
  )

  useEffect(() => {
    handleFilter({ endDate, startDate, operationType: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onSubmitBudget = useCallback(
    async data => {
      activeLoading()
      const dataEntries = data.budgets
        ? Object.entries(data.budgets).filter(([key, value]: any) => {
            if (value) {
              if (key === 'markAll') {
                return false
              }
              return value.active
            }
            return false
          })
        : []
      try {
        await api.post('financial/financialTransactions/reconciliation', {
          transactions: dataEntries.map(([key]) => {
            const findTransaction = transactionsBudget.find(
              transaction => transaction.id === Number(key)
            )
            return findTransaction
          })
        })
        await handleFilter({ endDate, startDate, operationType })
        reset({ markAll: false })
        reset()
        disableLoading()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error ao processar os pedidos',
          description:
            'Houve um error ao conciliar as movimentações financeiras, tente novamente mais tarde!'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      endDate,
      handleFilter,
      operationType,
      reset,
      startDate,
      transactionsBudget
    ]
  )

  const onSubmitIncome = useCallback(
    async data => {
      activeLoading()
      const dataEntries = data.incomes
        ? Object.entries(data.incomes).filter(([key, value]: any) => {
            if (value) {
              if (key === 'markIncomeAll') {
                return false
              }
              return value.active
            }
            return false
          })
        : []
      try {
        await api.post('financial/financialTransactions/reconciliation', {
          transactions: dataEntries.map(([key]) => {
            const findTransaction = transactionsIncome.find(
              transaction => transaction.id === Number(key)
            )
            return findTransaction
          })
        })
        await handleFilter({ endDate, startDate, operationType })
        reset({ markIncomeAll: false })
        reset()
        disableLoading()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error ao processar os pedidos',
          description:
            'Houve um error ao conciliar as movimentações financeiras, tente novamente mais tarde!'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      endDate,
      handleFilter,
      operationType,
      reset,
      startDate,
      transactionsIncome
    ]
  )
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      title: '',
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = () => {
    try {
      const copyAlertData = { ...alert.data }
      if (alert.type === 'budget') {
        onSubmitBudget(copyAlertData)
      } else {
        onSubmitIncome(copyAlertData)
      }
      setIsActiveAlert({
        title: '',
        id: 0,
        isActive: false,
        name: ''
      })
    } catch (err) {
      setIsActiveAlert({
        title: '',
        id: 0,
        isActive: false,
        name: ''
      })
    }
  }
  useEffect(() => {
    const subscription = watch(value => {
      const markAll = value.markAll
      const markIncomeAll = value.markIncomeAll
      const budgets: any[] = value?.budgets?.filter(
        (item: { active: boolean }) => item && item?.active
      )
      const incomes = value?.incomes?.filter(
        (item: { active: boolean }) => item && item?.active
      )
      if (!budgets?.length && !markAll) {
        setDisabledButtonBudget(true)
      } else {
        setDisabledButtonBudget(false)
      }
      if (!incomes?.length && !markIncomeAll) {
        setDisabledButtonIncome(true)
      } else {
        setDisabledButtonIncome(false)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <ContainerWrapper className="page-content-wrapper">
        <Loading isActive={loading} />
        <div className="page-head">
          <div className="container-fluid">
            <div className="page-title">
              <h1>{namePageTitle}</h1>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container-fluid">
            {breadcrumbList && (
              <ul className="page-breadcrumb breadcrumb">
                {breadcrumbList.map((bread, i) => (
                  <li key={bread.name}>
                    {(bread.to && <Link to={bread.to}>{bread.name}</Link>) ||
                      bread.name}
                    {breadcrumbList.length !== i + 1 && (
                      <i className="fa fa-circle" />
                    )}
                  </li>
                ))}
              </ul>
            )}
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="portlet light bordered">
                      <div className="portlet-title">
                        <div className="caption">Filtro</div>
                      </div>
                      <div className="portlet-body form">
                        <FilterContaier>
                          <div>
                            <div
                              className="col-md-2"
                              style={{ marginTop: '15px' }}
                            >
                              <DatePicker
                                className="form-control"
                                name="start_date"
                                label="Data de início"
                                selected={startDate}
                                onChange={e => {
                                  if (endDate) {
                                    if (e > endDate) {
                                      setEndDate(null)
                                    }
                                  }
                                  setStartDate(e)
                                }}
                                controlled
                              />
                            </div>
                            <div
                              className="col-md-2"
                              style={{ marginTop: '15px' }}
                            >
                              <DatePicker
                                className="form-control"
                                name="end_date"
                                label="Data de término"
                                selected={endDate}
                                minDate={startDate}
                                maxDate={
                                  startDate
                                    ? moment(startDate)
                                        .add('30', 'd')
                                        .isAfter(moment())
                                      ? moment().toDate()
                                      : moment(startDate)
                                          .add('30', 'd')
                                          .toDate()
                                    : undefined
                                }
                                onChange={e => setEndDate(e)}
                                controlled
                              />
                            </div>
                            <div
                              className="col-md-2"
                              style={{ marginTop: '15px' }}
                            >
                              <label htmlFor="operationType">
                                Tipo de Operação
                              </label>
                              <select
                                className="form-control"
                                id="operationType"
                                value={operationType}
                                onChange={({ target }) =>
                                  setOperationType(target.value)
                                }
                              >
                                <option value="">Selecione</option>
                                <option value="C">Crédito</option>
                                <option value="D">Débito</option>
                              </select>
                            </div>
                          </div>
                          <footer>
                            <button
                              onClick={() => {
                                setStartDate(moment().startOf('W').toDate())
                                setEndDate(moment().endOf('W').toDate())
                                setOperationType('')
                                handleFilter({
                                  endDate: null,
                                  startDate: null,
                                  operationType: ''
                                })
                              }}
                            >
                              LIMPAR
                            </button>
                            <button
                              onClick={() => {
                                handleFilter({
                                  operationType,
                                  startDate,
                                  endDate
                                })
                              }}
                            >
                              BUSCAR
                            </button>
                          </footer>
                          <hr />
                        </FilterContaier>
                        <ListTitle>
                          <p>Despesas Localizadas</p>
                          <hr style={{ paddingBottom: 10 }} />
                        </ListTitle>
                        <form
                          onSubmit={handleSubmit(data => {
                            const dataEntries = data.budgets
                              ? Object.entries(data.budgets).filter(
                                  ([key, value]: any) => {
                                    if (value) {
                                      if (key === 'markAll') {
                                        return false
                                      }
                                      return value.active
                                    }
                                    return false
                                  }
                                )
                              : []
                            if (!dataEntries.length) {
                              return
                            }

                            setIsActiveAlert({
                              ...alert,
                              data,
                              name: `${dataEntries.length}`,
                              isActive: true,
                              type: 'budget'
                            })
                          })}
                        >
                          <CustomDataTable
                            customItems={transactionsBudget}
                            customFilterItems={filterTransactionsBudget}
                            setCustomItems={setFilterTransactionsBudget}
                            headers={[
                              {
                                name: '',
                                field: '',
                                custom: true,
                                sortable: false,
                                element: () => (
                                  <th
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      {...register('markAll')}
                                      value="all"
                                      onChange={() => {
                                        const fields = Object.entries(watch())
                                        for (const [key, value] of fields) {
                                          if (key === 'markAll') {
                                            setValue(key, !selectedAll)
                                          }
                                          if (key === 'budgets') {
                                            const arrayData: any[] =
                                              value.entries()
                                            for (const [
                                              subKey,
                                              subValue
                                            ] of arrayData) {
                                              if (subValue) {
                                                const findTransaction =
                                                  filterTransactionsBudget.find(
                                                    item =>
                                                      item.id === Number(subKey)
                                                  )
                                                if (findTransaction) {
                                                  setValue(
                                                    `budgets.${subKey}.active`,
                                                    !selectedAll
                                                  )
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </th>
                                )
                              },
                              ...headers
                            ]}
                            customHeaders={[
                              {
                                name: '',
                                field: '',
                                sortable: false,
                                element: item => {
                                  return (
                                    <td
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        {...register(
                                          `budgets.${item.id}.active`
                                        )}
                                      />
                                    </td>
                                  )
                                }
                              },
                              {
                                name: 'Descrição Sistema',
                                field: 'name',
                                sortable: true,
                                element: item => (
                                  <span>
                                    {item.name}{' '}
                                    {item.supplier_name
                                      ? `- ${item.supplier_name}`
                                      : ''}{' '}
                                    - Cód: {item.id}
                                  </span>
                                )
                              }
                            ]}
                            search
                            pagination
                          />
                          <div style={{ marginTop: '30px' }}>
                            <button
                              type="submit"
                              className="btn dark btn-sm sbold uppercase"
                              disabled={disabledButtonBudget}
                            >
                              CONCILIAR
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
      <ContainerWrapper className="page-content-wrapper">
        <div className="page-container">
          <div className="container-fluid">
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="portlet light bordered">
                      <div className="portlet-body form">
                        <ListTitle>
                          <p>Receitas Localizadas</p>
                          <hr style={{ paddingBottom: 10 }} />
                        </ListTitle>
                        <form
                          onSubmit={handleSubmit(data => {
                            const dataEntries = data.incomes
                              ? Object.entries(data.incomes).filter(
                                  ([key, value]: any) => {
                                    if (value) {
                                      if (key === 'markIncomeAll') {
                                        return false
                                      }
                                      return value.active
                                    }
                                    return false
                                  }
                                )
                              : []
                            if (!dataEntries.length) {
                              return
                            }
                            setIsActiveAlert({
                              ...alert,
                              data,
                              name: `${dataEntries.length}`,
                              isActive: true,
                              type: 'income'
                            })
                          })}
                        >
                          <CustomDataTable
                            customItems={transactionsIncome}
                            customFilterItems={filterTransactionsIncome}
                            setCustomItems={setFilterTransactionsIncome}
                            headers={[
                              {
                                name: '',
                                field: '',
                                custom: true,
                                sortable: false,
                                element: () => (
                                  <th
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      {...register('markIncomeAll', {
                                        shouldUnregister: true
                                      })}
                                      value="incomeAll"
                                      onChange={() => {
                                        const fields = Object.entries(watch())
                                        for (const [key, value] of fields) {
                                          if (key === 'markIncomeAll') {
                                            setValue(key, !selectedIncomeAll)
                                          }
                                          if (key === 'incomes') {
                                            const arrayData: any[] =
                                              value.entries()
                                            for (const [
                                              subKey,
                                              subValue
                                            ] of arrayData) {
                                              if (subValue) {
                                                const findTransaction =
                                                  filterTransactionsIncome.find(
                                                    item =>
                                                      item.id === Number(subKey)
                                                  )
                                                if (findTransaction) {
                                                  setValue(
                                                    `incomes.${subKey}.active`,
                                                    !selectedIncomeAll
                                                  )
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </th>
                                )
                              },
                              ...headers
                            ]}
                            customHeaders={[
                              {
                                name: '',
                                field: '',
                                sortable: false,
                                element: item => {
                                  return (
                                    <td
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        {...register(
                                          `incomes.${item.id}.active`
                                        )}
                                      />
                                    </td>
                                  )
                                }
                              },
                              {
                                name: 'Descrição Sistema',
                                field: 'name',
                                sortable: true,
                                element: item => (
                                  <span>
                                    {item.name}{' '}
                                    {item.supplier_name
                                      ? `- ${item.supplier_name}`
                                      : ''}{' '}
                                    - Cód: {item.id}
                                  </span>
                                )
                              }
                            ]}
                            search
                            pagination
                          />
                          <div style={{ marginTop: '30px' }}>
                            <button
                              type="submit"
                              className="btn dark btn-sm sbold uppercase"
                              disabled={disabledButtonIncome}
                            >
                              CONCILIAR
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
      <ContainerWrapper className="page-content-wrapper">
        <div className="page-container">
          <div className="container-fluid">
            <div className="page-content-inner">
              <div className="mt-content-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="portlet light bordered">
                      <div className="portlet-body form">
                        <ListTitle>
                          <p>Transações Não Localizadas</p>
                          <hr style={{ paddingBottom: 10 }} />
                        </ListTitle>
                        <CustomDataTable
                          customItems={notFoundtransactions}
                          customFilterItems={filterNotFoundtransactions}
                          setCustomItems={setFilterNotFoundTransactions}
                          headers={notFoundheaders}
                          search
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
      <Alert
        message={`Confirma a conciliação de ${alert.name} ${
          Number(alert.name) === 1 ? 'movimentação' : 'movimentações'
        } ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        isActive={alert.isActive}
      />
    </>
  )
}

export default BankReconciliationList
