import React from 'react'
import List from '../../pages/Commercial/ClientsSectors/List'
import Update from '../../pages/Commercial/ClientsSectors/Update'
import Create from '../../pages/Commercial/ClientsSectors/Create'
import View from '../../pages/Commercial/ClientsSectors/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsSectorsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/clientsSectors" exact component={List} />
    <Route
      path="/commercial/clientsSectors/update/:id"
      component={Update}
      exact
    />
    <Route path="/commercial/clientsSectors/create" component={Create} exact />
    <Route path="/commercial/clientsSectors/view/:id" component={View} exact />
  </CustomSwitch>
)
