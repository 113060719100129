import React, { useState } from 'react'
import { Container } from './styles'
import { IconBaseProps } from 'react-icons'
import { Input } from '../../../../../components/Form'
import currency from 'currency.js'
import {
  genericMaskWithFourZeroWithPoint,
  genericMaskWithTwoZeroWithPoint
} from '../../../../../utlis/mask'
import { Alert } from '../../../../../components/Alert'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'

interface UsersProps {
  id: number
  name: string
  agent_id: number
  avatar_url: string | React.ComponentType<IconBaseProps>
  goal: number
  goalAchieved: number
  commission: number
  commissionPercentage: string
  commissionPercentageWithoutGoal: string
  adicionalAward: string
  total: string
  financial: boolean
  financial_moviment_id?: number
  manager_id?: number
}

interface BoardOfMembersProps {
  activeMembersData: UsersProps[]
  tableHead?: any[]
  setActiveMembersData: React.Dispatch<React.SetStateAction<any[]>>
  handleSetDate: (date: string) => Promise<void>
  selectedDate: string
}
const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })

const BRL_FOUR_DIGITS = (value: any) =>
  currency(value, {
    precision: 4,
    symbol: '',
    decimal: ',',
    separator: '.'
  })

const BoardOfMembers: React.FC<BoardOfMembersProps> = ({
  activeMembersData,
  setActiveMembersData,
  tableHead,
  handleSetDate,
  selectedDate
}) => {
  const [removeFinancialModalIsOpen, setRemoveFinancialModalIsOpen] =
    useState(false)

  const [commissionIndex, setCommissionIndex] = useState(0)
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  async function handleRemoveFinancialConfirmButton() {
    activeLoading()
    try {
      const members = [...activeMembersData]
      await api.put('commercial/goalsEmployees/removeFinancialCommission', {
        goalEmployeeId: members[commissionIndex].id,
        financialMovimentId: members[commissionIndex].financial_moviment_id
      })
      await handleSetDate(selectedDate)
      setRemoveFinancialModalIsOpen(false)
      addToast({
        type: 'success',
        title: 'Financeiro removido',
        description: 'Registro alterado com sucesso'
      })
    } catch (error) {
      console.log(error)
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description: 'Essa comissão já foi paga'
      })
      setRemoveFinancialModalIsOpen(false)
    }
    disableLoading()
  }

  const verifyWhatCommissionFieldChoice = (item: UsersProps) => {
    const verifyIfGoalAchievedIsLessGoal =
      BRL(item.goalAchieved).value < BRL(item.goal).value
    return verifyIfGoalAchievedIsLessGoal
      ? 'commissionPercentageWithoutGoal'
      : 'commissionPercentage'
  }
  return (
    <>
      <Container className="col-md-12">
        <div className="portlet light ">
          <div className="portlet-title">
            <div className="caption caption-md">
              <i className="icon-bar-chart font-dark hide"></i>
              <span className="maintitle">Apuração de Meta</span>
            </div>
          </div>
          <div className="portlet-body">
            <div>
              <table className="table table-light">
                <thead>
                  <tr className="TableRowHead">
                    {tableHead?.length &&
                      tableHead.map(tableHead => (
                        <th
                          colSpan={tableHead.colSpan || 0}
                          className={tableHead.classname || ''}
                          key={tableHead.name}
                        >
                          {tableHead.name}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {activeMembersData.length ? (
                    activeMembersData.map((user, index) => {
                      return (
                        <tr
                          key={user.name}
                          style={{
                            background: user.manager_id ? 'inherit' : '#f2f2f2'
                          }}
                        >
                          <td className="fit">
                            {typeof user.avatar_url === 'string' ? (
                              <img
                                className="user-pic rounded"
                                src={user.avatar_url}
                              />
                            ) : (
                              <user.avatar_url size={30} color="#d4d4d4" />
                            )}
                          </td>
                          <td>
                            <a>{user.name}</a>
                          </td>
                          <td>{user.goal}</td>
                          <td>{user.goalAchieved}</td>
                          <td style={{ width: '200px' }} colSpan={2}>
                            <Input
                              name="e"
                              value={user.commissionPercentage}
                              className="form-control"
                              readOnly={!!user.financial_moviment_id}
                              onChange={e =>
                                setActiveMembersData(prev => {
                                  const newValue =
                                    genericMaskWithFourZeroWithPoint(
                                      e.target.value
                                    )
                                  const copy = [...prev]
                                  copy[index].commissionPercentage = newValue
                                  if (
                                    verifyWhatCommissionFieldChoice(user) ===
                                    'commissionPercentage'
                                  ) {
                                    copy[index].commission = BRL(
                                      copy[index].goalAchieved
                                    )
                                      .multiply(BRL_FOUR_DIGITS(newValue) || 0)
                                      .divide(100)
                                      .format()
                                    copy[index].total = BRL(
                                      copy[index].adicionalAward
                                    )
                                      .add(copy[index].commission)
                                      .format()
                                  }
                                  return copy
                                })
                              }
                              controlled
                            />
                          </td>
                          <td style={{ width: '200px' }} colSpan={2}>
                            <Input
                              name="e"
                              value={user.commissionPercentageWithoutGoal}
                              className="form-control"
                              readOnly={!!user.financial_moviment_id}
                              onChange={e =>
                                setActiveMembersData(prev => {
                                  const newValue =
                                    genericMaskWithTwoZeroWithPoint(
                                      e.target.value
                                    )
                                  const copy = [...prev]
                                  copy[index].commissionPercentageWithoutGoal =
                                    newValue
                                  if (
                                    verifyWhatCommissionFieldChoice(user) ===
                                    'commissionPercentageWithoutGoal'
                                  ) {
                                    copy[index].commission = BRL(
                                      copy[index].goalAchieved
                                    )
                                      .multiply(BRL(newValue) || 0)
                                      .divide(100)
                                      .format()
                                    copy[index].total = BRL(
                                      copy[index].adicionalAward
                                    )
                                      .add(copy[index].commission)
                                      .format()
                                  }
                                  return copy
                                })
                              }
                              controlled
                            />
                          </td>
                          <td style={{ width: '200px' }}>{user.commission}</td>
                          <td style={{ width: '200px' }}>
                            <Input
                              name=""
                              value={user.adicionalAward}
                              className="form-control"
                              readOnly={!!user.financial_moviment_id}
                              onChange={e =>
                                setActiveMembersData(prev => {
                                  const newValue =
                                    genericMaskWithTwoZeroWithPoint(
                                      e.target.value
                                    )
                                  const copy = [...prev]
                                  copy[index].adicionalAward = newValue
                                  copy[index].total = BRL(
                                    copy[index].adicionalAward
                                  )
                                    .add(user.commission)
                                    .format()
                                  return copy
                                })
                              }
                              controlled
                            />
                          </td>
                          <td style={{ width: '200px' }}>{user.total}</td>
                          <td style={{ width: '40px' }}>
                            {!user.financial_moviment_id ? (
                              <Input
                                style={{
                                  margin: 'auto',
                                  cursor: 'pointer',
                                  marginLeft: '25px'
                                }}
                                name=""
                                type="checkbox"
                                disabled={!BRL(user.commissionPercentage).value}
                                onClick={() => {
                                  const members = [...activeMembersData]
                                  members[index].financial =
                                    !members[index].financial
                                  setActiveMembersData(members)
                                }}
                              />
                            ) : (
                              <div
                                style={{ marginLeft: '20px' }}
                                className="btn btn-default btn-sm uppercase"
                                onClick={() => {
                                  setRemoveFinancialModalIsOpen(true)
                                  setCommissionIndex(index)
                                }}
                              >
                                x
                              </div>
                            )}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} style={{ textAlign: 'center' }}>
                        Nenhum registro encontrado
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        RenderComponent={() => (
          <>
            <h4>Deseja excluir esse registro do financeiro?</h4>
          </>
        )}
        onClickCancellButton={() => setRemoveFinancialModalIsOpen(false)}
        onClickConfirmButton={handleRemoveFinancialConfirmButton}
        isActive={removeFinancialModalIsOpen}
      />
    </>
  )
}

export { BoardOfMembers }
