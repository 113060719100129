import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container } from './styles'
import { FaChevronDown } from 'react-icons/fa'
import Switch from 'react-switch'
import { TooltipComponent } from '../TooltipComponent'

interface TablePortletProps {
  handleSetDate?: (date: string, item_id?: number, item?: any) => void
  setInitialData?: (date: string, item_id?: number, item?: any) => void
  handleToggleSwitch?: (item: any) => void
  realData?: any[]
  initialData?: any[]
  maintitle?: string
  actions?: string[]
  actionsStyles?: any[]
  tableHead: string[]
  isHighlight?: boolean
  isHighlightOver?: boolean
  hasDropdown?: boolean
  className?: string
  lastChildStyle?: string
  hasTotal?: boolean
  hasToggle?: boolean
  isReset?: boolean
  indexColumnToggle?: number[]
  stylesChild?: any
  indexOnlySettings?: number[]
  switchSettings?: any
  resetToggle?: any
  resetSelected?: any
}

const TablePortlet: React.FC<TablePortletProps> = ({
  handleSetDate,
  setInitialData,

  realData,
  maintitle,
  actions = [],
  tableHead,
  isHighlight = true,
  isHighlightOver = true,

  className,
  hasDropdown = true,
  stylesChild,

  indexOnlySettings,
  hasToggle,
  indexColumnToggle,
  handleToggleSwitch,
  switchSettings,
  resetToggle,
  resetSelected
}) => {
  const [selectedItem, setSelectedItem] = useState(-1)
  const [selectedUser, setSelectedUser] = useState({
    name: '',
    child: {
      name: ''
    }
  })
  const [toggleSubItems, setToggleSubItems] = useState({
    name: '',
    isShow: false,
    child: {
      name: '',
      isShow: false
    }
  })
  const selected = useRef({
    name: '',
    child: {
      name: ''
    }
  })

  const handleGetData = useCallback(
    (event, item: any, isChild: boolean) => {
      event.stopPropagation()
      if (!isHighlight) return
      if (item.disable) return

      if (isChild) {
        if (item.column1 === selectedUser.child.name) {
          setSelectedUser({
            ...selectedUser,
            child: {
              name: ''
            }
          })
          if (hasDropdown) {
            setToggleSubItems({
              ...toggleSubItems,
              child: {
                isShow: false,
                name: item.column1
              }
            })
          }
          setInitialData?.('', 0, item)
          return
        }
        if (!hasDropdown && item.column1 !== selectedUser.child.name) {
          setSelectedUser({
            ...selectedUser,
            child: {
              name: item.column1
            }
          })
        }
      } else {
        if (item.column1 === selectedUser.name) {
          setSelectedUser({
            name: '',
            child: {
              name: ''
            }
          })
          if (hasDropdown) {
            setToggleSubItems({
              name: item.column1,
              isShow: false,
              child: {
                isShow: false,
                name: ''
              }
            })
          }
          setInitialData?.('', 0, item)
          return
        }
        if (!hasDropdown && item.column1 !== selectedUser.child.name) {
          setSelectedUser({
            name: item.column1,
            child: {
              name: ''
            }
          })
        }
      }

      if (hasDropdown && item.subItems.length > 0) {
        if (isChild) {
          setToggleSubItems({
            ...toggleSubItems,
            child: {
              isShow: true,
              name: item.column1
            }
          })
          setSelectedUser({
            ...selectedUser,
            child: {
              name: item.column1
            }
          })
        } else {
          setSelectedUser({
            name: item.column1,
            child: {
              name: ''
            }
          })
          selected.current = {
            name: item.column1,
            child: {
              name: ''
            }
          }
          setToggleSubItems({
            isShow: true,
            name: item.column1,
            child: {
              name: '',
              isShow: false
            }
          })
        }
      } else {
        setToggleSubItems({
          isShow: false,
          name: toggleSubItems.name,
          child: {
            isShow: false,
            name: toggleSubItems.child.name
          }
        })
      }

      handleSetDate?.('', item.id, item)
    },
    [
      handleSetDate,
      hasDropdown,
      isHighlight,
      selectedUser,
      setInitialData,
      toggleSubItems
    ]
  )
  const handleSwitchPeriod = useCallback(
    (index: number) => {
      if (index === selectedItem) {
        setSelectedItem(-1)
        setInitialData?.(actions[2])
        return
      }
      setSelectedItem(index)
      handleSetDate?.(actions[index])
    },
    [actions, handleSetDate, selectedItem, setInitialData]
  )
  useEffect(() => {
    if (resetSelected && resetToggle) {
      setSelectedUser(resetSelected)
      setToggleSubItems(resetToggle)
    }
  }, [resetSelected, resetToggle])
  useEffect(() => {
    if (resetSelected?.name) {
      setSelectedUser(resetSelected)
    } else {
      setSelectedUser({
        child: {
          name: ''
        },
        name: ''
      })
    }
  }, [resetSelected, resetToggle])
  return (
    <Container className={className}>
      <div className="portlet light ">
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">{maintitle}</span>
          </div>
        </div>
        <div className="portlet-body">
          {actions.length > 0 && (
            <div className="row">
              <table className="table table-light">
                <thead className="TableHead">
                  <tr>
                    {actions.map((item, index) => (
                      <th
                        key={`${Math.random()}`}
                        onClick={() => handleSwitchPeriod(index)}
                      >
                        <div
                          className={`btn btn-transparent btn-no-border btn-outline btn-sm ${
                            index === selectedItem && 'active'
                          }`}
                        >
                          {item}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
          )}
          <div className="table-scrollable table-scrollable-borderless">
            <table className="table table-light">
              <thead>
                <tr>
                  {tableHead.map((item, index) => (
                    <th
                      style={{
                        width: stylesChild.th[index]?.width
                      }}
                      key={`${Math.random()}`}
                      className={stylesChild.th?.classname}
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {realData.length > 0 ? (
                  realData.map((item, indexItem) => {
                    if (
                      hasDropdown &&
                      toggleSubItems.isShow &&
                      item.subItems?.length > 0
                    ) {
                      return (
                        <React.Fragment
                          key={`${Math.random() * Math.random() + 1}`}
                        >
                          <tr
                            key={`${Math.random()}`}
                            className={
                              isHighlight && item.column1 === selectedUser.name
                                ? 'activeCustom'
                                : ''
                            }
                            onClick={event => handleGetData(event, item, false)}
                          >
                            {tableHead.map((tableColumn, index) => {
                              if (item[`column${index + 1}`] !== undefined) {
                                return (
                                  <td key={`${Math.random()}`}>
                                    <div
                                      className={
                                        stylesChild[`column${index + 1}`]
                                          ?.classname || ''
                                      }
                                      style={{
                                        color: !stylesChild[
                                          `column${index + 1}`
                                        ]?.overAndUnder
                                          ? stylesChild[`column${index + 1}`]
                                              ?.isHighlight &&
                                            isHighlightOver &&
                                            (item[
                                              `column${index + 1}`
                                            ].includes('-') ||
                                              ((!stylesChild[
                                                `column${index + 1}`
                                              ].notValidateSymbol
                                                ? item[
                                                    `column${index + 1}`
                                                  ].includes(
                                                    stylesChild[
                                                      `column${index + 1}`
                                                    ].validateSymbol || '%'
                                                  )
                                                : true) &&
                                                Number(
                                                  item[`column${index + 1}`]
                                                    .replaceAll(',', '')
                                                    .replace('%', '')
                                                    .replaceAll('.', '')
                                                ) >
                                                  (stylesChild[
                                                    `column${index + 1}`
                                                  ].highlightOverNumber !==
                                                  undefined
                                                    ? stylesChild[
                                                        `column${index + 1}`
                                                      ].highlightOverNumber
                                                    : 10000))) &&
                                            stylesChild.highlightColor
                                          : stylesChild[`column${index + 1}`]
                                              ?.isHighlight &&
                                            isHighlightOver &&
                                            item[`column${index + 1}`].includes(
                                              '-'
                                            )
                                          ? stylesChild.highlightUnderColor
                                          : (!stylesChild[`column${index + 1}`]
                                              .notValidateSymbol
                                              ? item[
                                                  `column${index + 1}`
                                                ].includes(
                                                  stylesChild[
                                                    `column${index + 1}`
                                                  ].validateSymbol || '%'
                                                )
                                              : true) &&
                                            Number(
                                              item[`column${index + 1}`]
                                                .replaceAll(',', '')
                                                .replace('%', '')
                                                .replaceAll('.', '')
                                            ) >
                                              (stylesChild[`column${index + 1}`]
                                                .highlightOverNumber !==
                                              undefined
                                                ? stylesChild[
                                                    `column${index + 1}`
                                                  ].highlightOverNumber
                                                : 10000)
                                          ? stylesChild.highlightOverColor
                                          : '#a0a0a0'
                                      }}
                                    >
                                      {item[`column${index + 1}`]}
                                      {index + 1 === 1 &&
                                        hasDropdown &&
                                        item.subItems?.length > 0 && (
                                          <FaChevronDown
                                            style={{
                                              marginLeft: '22px'
                                            }}
                                            size={13}
                                            className={
                                              toggleSubItems.name ===
                                              item.column1
                                                ? !toggleSubItems.isShow
                                                  ? 'RotateDown'
                                                  : 'RotateUp'
                                                : ''
                                            }
                                            onClick={event =>
                                              handleGetData(event, item, false)
                                            }
                                          />
                                        )}
                                      {hasToggle &&
                                        indexColumnToggle.includes(index) && (
                                          <div
                                            onClick={event =>
                                              event.stopPropagation()
                                            }
                                          >
                                            {switchSettings.hasTooltip &&
                                              item[
                                                switchSettings.approved_role
                                              ] && (
                                                <TooltipComponent
                                                  label={
                                                    switchSettings.tooltip.label
                                                  }
                                                  message={
                                                    switchSettings.tooltip
                                                      .message
                                                  }
                                                  bold={
                                                    switchSettings.tooltip.bold
                                                  }
                                                  key={`${Math.random()}`}
                                                />
                                              )}
                                            <Switch
                                              onChange={() =>
                                                handleToggleSwitch(item)
                                              }
                                              disabled={
                                                !!item[
                                                  switchSettings.approved_role
                                                ]
                                              }
                                              checked={
                                                item[
                                                  switchSettings.ischecked
                                                ] || false
                                              }
                                              {...switchSettings}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                )
                              } else {
                                return <td key={`${Math.random()}`}></td>
                              }
                            })}
                          </tr>
                          {item.subItems?.length &&
                            item.column1 === toggleSubItems.name &&
                            item.subItems.map((item: any) => (
                              <>
                                <tr
                                  key={`${Math.random()}`}
                                  className={
                                    isHighlight &&
                                    item.column1 === selectedUser.child.name
                                      ? 'trChildSubItems'
                                      : 'trChild'
                                  }
                                  onClick={event =>
                                    handleGetData(event, item, true)
                                  }
                                >
                                  {tableHead.map((tableColumn, index) => {
                                    if (
                                      item[`column${index + 1}`] !== undefined
                                    ) {
                                      return (
                                        <td key={`${Math.random()}`}>
                                          <div
                                            className={
                                              stylesChild[`column${index + 1}`]
                                                ?.classname || ''
                                            }
                                            style={{
                                              color: !stylesChild[
                                                `column${index + 1}`
                                              ]?.overAndUnder
                                                ? stylesChild[
                                                    `column${index + 1}`
                                                  ]?.isHighlight &&
                                                  isHighlightOver &&
                                                  (item[
                                                    `column${index + 1}`
                                                  ].includes('-') ||
                                                    ((!stylesChild[
                                                      `column${index + 1}`
                                                    ].notValidateSymbol
                                                      ? item[
                                                          `column${index + 1}`
                                                        ].includes(
                                                          stylesChild[
                                                            `column${index + 1}`
                                                          ].validateSymbol ||
                                                            '%'
                                                        )
                                                      : true) &&
                                                      Number(
                                                        item[
                                                          `column${index + 1}`
                                                        ]
                                                          .replaceAll(',', '')
                                                          .replace('%', '')
                                                          .replaceAll('.', '')
                                                      ) >
                                                        (stylesChild[
                                                          `column${index + 1}`
                                                        ]
                                                          .highlightOverNumber !==
                                                        undefined
                                                          ? stylesChild[
                                                              `column${
                                                                index + 1
                                                              }`
                                                            ]
                                                              .highlightOverNumber
                                                          : 10000))) &&
                                                  stylesChild.highlightColor
                                                : stylesChild[
                                                    `column${index + 1}`
                                                  ]?.isHighlight &&
                                                  isHighlightOver &&
                                                  item[
                                                    `column${index + 1}`
                                                  ].includes('-')
                                                ? stylesChild.highlightUnderColor
                                                : (!stylesChild[
                                                    `column${index + 1}`
                                                  ].notValidateSymbol
                                                    ? item[
                                                        `column${index + 1}`
                                                      ].includes(
                                                        stylesChild[
                                                          `column${index + 1}`
                                                        ].validateSymbol || '%'
                                                      )
                                                    : true) &&
                                                  Number(
                                                    item[`column${index + 1}`]
                                                      .replaceAll(',', '')
                                                      .replace('%', '')
                                                      .replaceAll('.', '')
                                                  ) >
                                                    (stylesChild[
                                                      `column${index + 1}`
                                                    ].highlightOverNumber !==
                                                    undefined
                                                      ? stylesChild[
                                                          `column${index + 1}`
                                                        ].highlightOverNumber
                                                      : 10000)
                                                ? stylesChild.highlightOverColor
                                                : '#a0a0a0'
                                            }}
                                          >
                                            {item[`column${index + 1}`]}
                                            {index + 1 === 1 &&
                                              hasDropdown &&
                                              item.subItems?.length > 0 && (
                                                <FaChevronDown
                                                  style={{
                                                    marginLeft: '22px'
                                                  }}
                                                  size={13}
                                                  className={
                                                    toggleSubItems.child
                                                      .name === item.column1
                                                      ? !toggleSubItems.child
                                                          .isShow
                                                        ? 'RotateDown'
                                                        : 'RotateUp'
                                                      : ''
                                                  }
                                                  onClick={event =>
                                                    handleGetData(
                                                      event,
                                                      item,
                                                      true
                                                    )
                                                  }
                                                />
                                              )}
                                            {hasToggle &&
                                              indexColumnToggle.includes(
                                                index
                                              ) && (
                                                <div
                                                  onClick={event =>
                                                    event.stopPropagation()
                                                  }
                                                >
                                                  {switchSettings.hasTooltip &&
                                                    item[
                                                      switchSettings
                                                        .approved_role
                                                    ] && (
                                                      <TooltipComponent
                                                        label={
                                                          switchSettings.tooltip
                                                            .label
                                                        }
                                                        message={
                                                          switchSettings.tooltip
                                                            .message
                                                        }
                                                        bold={
                                                          switchSettings.tooltip
                                                            .bold
                                                        }
                                                        key={
                                                          switchSettings.tooltip
                                                            .key
                                                        }
                                                      />
                                                    )}
                                                  <Switch
                                                    onChange={() =>
                                                      handleToggleSwitch(item)
                                                    }
                                                    disabled={
                                                      !!item[
                                                        switchSettings
                                                          .approved_role
                                                      ]
                                                    }
                                                    checked={
                                                      item[
                                                        switchSettings.ischecked
                                                      ] || false
                                                    }
                                                    {...switchSettings}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </td>
                                      )
                                    } else {
                                      return <td key={`${Math.random()}`}></td>
                                    }
                                  })}
                                </tr>
                                {item.subItems?.length &&
                                  item.column1 === toggleSubItems.child.name &&
                                  // eslint-disable-next-line array-callback-return
                                  item.subItems.map((child: any) => {
                                    if (
                                      hasDropdown &&
                                      toggleSubItems.child.isShow &&
                                      item.subItems?.length > 0
                                    ) {
                                      return (
                                        <tr
                                          key={`${Math.random()}`}
                                          className="trChildSubItems"
                                          onClick={event =>
                                            handleGetData(event, item, true)
                                          }
                                        >
                                          {tableHead.map(
                                            (tableColumn, index) => {
                                              if (
                                                child[`column${index + 1}`] !==
                                                undefined
                                              ) {
                                                return (
                                                  <td key={`${Math.random()}`}>
                                                    <div
                                                      className={
                                                        (stylesChild.child &&
                                                          stylesChild.child[
                                                            `column${index + 1}`
                                                          ]?.classname) ||
                                                        ''
                                                      }
                                                      style={{
                                                        color: !stylesChild[
                                                          `column${index + 1}`
                                                        ]?.overAndUnder
                                                          ? stylesChild[
                                                              `column${
                                                                index + 1
                                                              }`
                                                            ]?.isHighlight &&
                                                            isHighlightOver &&
                                                            (item[
                                                              `column${
                                                                index + 1
                                                              }`
                                                            ].includes('-') ||
                                                              ((!stylesChild[
                                                                `column${
                                                                  index + 1
                                                                }`
                                                              ]
                                                                .notValidateSymbol
                                                                ? item[
                                                                    `column${
                                                                      index + 1
                                                                    }`
                                                                  ].includes(
                                                                    stylesChild[
                                                                      `column${
                                                                        index +
                                                                        1
                                                                      }`
                                                                    ]
                                                                      .validateSymbol ||
                                                                      '%'
                                                                  )
                                                                : true) &&
                                                                Number(
                                                                  item[
                                                                    `column${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                    .replaceAll(
                                                                      ',',
                                                                      ''
                                                                    )
                                                                    .replace(
                                                                      '%',
                                                                      ''
                                                                    )
                                                                    .replaceAll(
                                                                      '.',
                                                                      ''
                                                                    )
                                                                ) >
                                                                  (stylesChild[
                                                                    `column${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                    .highlightOverNumber !==
                                                                  undefined
                                                                    ? stylesChild[
                                                                        `column${
                                                                          index +
                                                                          1
                                                                        }`
                                                                      ]
                                                                        .highlightOverNumber
                                                                    : 10000))) &&
                                                            stylesChild.highlightColor
                                                          : stylesChild[
                                                              `column${
                                                                index + 1
                                                              }`
                                                            ]?.isHighlight &&
                                                            isHighlightOver &&
                                                            item[
                                                              `column${
                                                                index + 1
                                                              }`
                                                            ].includes('-')
                                                          ? stylesChild.highlightUnderColor
                                                          : (!stylesChild[
                                                              `column${
                                                                index + 1
                                                              }`
                                                            ].notValidateSymbol
                                                              ? item[
                                                                  `column${
                                                                    index + 1
                                                                  }`
                                                                ].includes(
                                                                  stylesChild[
                                                                    `column${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                    .validateSymbol ||
                                                                    '%'
                                                                )
                                                              : true) &&
                                                            Number(
                                                              item[
                                                                `column${
                                                                  index + 1
                                                                }`
                                                              ]
                                                                .replaceAll(
                                                                  ',',
                                                                  ''
                                                                )
                                                                .replace(
                                                                  '%',
                                                                  ''
                                                                )
                                                                .replaceAll(
                                                                  '.',
                                                                  ''
                                                                )
                                                            ) >
                                                              (stylesChild[
                                                                `column${
                                                                  index + 1
                                                                }`
                                                              ]
                                                                .highlightOverNumber !==
                                                              undefined
                                                                ? stylesChild[
                                                                    `column${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                    .highlightOverNumber
                                                                : 10000)
                                                          ? stylesChild.highlightOverColor
                                                          : '#a0a0a0'
                                                      }}
                                                    >
                                                      {
                                                        child[
                                                          `column${index + 1}`
                                                        ]
                                                      }
                                                      {index + 1 === 1 &&
                                                        hasDropdown &&
                                                        child.subItems?.length >
                                                          0 && (
                                                          <FaChevronDown
                                                            style={{
                                                              marginLeft: '22px'
                                                            }}
                                                            size={13}
                                                            onClick={event =>
                                                              handleGetData(
                                                                event,
                                                                item,
                                                                true
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      {hasToggle &&
                                                        indexColumnToggle.includes(
                                                          index
                                                        ) && (
                                                          <div
                                                            onClick={event =>
                                                              event.stopPropagation()
                                                            }
                                                          >
                                                            {switchSettings.hasTooltip &&
                                                              child[
                                                                switchSettings
                                                                  .approved_role
                                                              ] && (
                                                                <TooltipComponent
                                                                  label={
                                                                    switchSettings
                                                                      .tooltip
                                                                      .label
                                                                  }
                                                                  message={
                                                                    switchSettings
                                                                      .tooltip
                                                                      .message
                                                                  }
                                                                  bold={
                                                                    switchSettings
                                                                      .tooltip
                                                                      .bold
                                                                  }
                                                                  key={
                                                                    switchSettings
                                                                      .tooltip
                                                                      .key
                                                                  }
                                                                />
                                                              )}
                                                            <Switch
                                                              onChange={() =>
                                                                handleToggleSwitch(
                                                                  child
                                                                )
                                                              }
                                                              disabled={
                                                                !!child[
                                                                  switchSettings
                                                                    .approved_role
                                                                ]
                                                              }
                                                              checked={
                                                                child[
                                                                  switchSettings
                                                                    .ischecked
                                                                ] || false
                                                              }
                                                              {...switchSettings}
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                  </td>
                                                )
                                              } else {
                                                return (
                                                  <td
                                                    key={`${Math.random()}`}
                                                  ></td>
                                                )
                                              }
                                            }
                                          )}
                                        </tr>
                                      )
                                    }
                                  })}
                              </>
                            ))}
                        </React.Fragment>
                      )
                    }
                    return (
                      <tr
                        key={`${Math.random()}`}
                        className={
                          isHighlight && item.column1 === selectedUser.name
                            ? 'activeCustom'
                            : ''
                        }
                        onClick={event => handleGetData(event, item, false)}
                      >
                        {tableHead.map((tableColumn, index) => {
                          if (item[`column${index + 1}`] !== undefined) {
                            return (
                              <td key={`${Math.random()}`}>
                                <div
                                  className={
                                    !stylesChild[`column${index + 1}`]?.isOnly
                                      ? stylesChild[`column${index + 1}`]
                                          ?.classname || ''
                                      : indexOnlySettings.includes(indexItem)
                                      ? stylesChild[`column${index + 1}`]
                                          ?.onlyClassname
                                      : stylesChild[`column${index + 1}`]
                                          ?.classname || ''
                                  }
                                  style={{
                                    color: !stylesChild[`column${index + 1}`]
                                      ?.overAndUnder
                                      ? stylesChild[`column${index + 1}`]
                                          ?.isHighlight &&
                                        isHighlightOver &&
                                        (item[`column${index + 1}`].includes(
                                          '-'
                                        ) ||
                                          ((!stylesChild[`column${index + 1}`]
                                            .notValidateSymbol
                                            ? item[
                                                `column${index + 1}`
                                              ].includes(
                                                stylesChild[
                                                  `column${index + 1}`
                                                ].validateSymbol || '%'
                                              )
                                            : true) &&
                                            Number(
                                              item[`column${index + 1}`]
                                                .replaceAll(',', '')
                                                .replace('%', '')
                                                .replaceAll('.', '')
                                            ) >
                                              (stylesChild[`column${index + 1}`]
                                                .highlightOverNumber !==
                                              undefined
                                                ? stylesChild[
                                                    `column${index + 1}`
                                                  ].highlightOverNumber
                                                : 10000))) &&
                                        stylesChild.highlightColor
                                      : stylesChild[`column${index + 1}`]
                                          ?.isHighlight &&
                                        isHighlightOver &&
                                        item[`column${index + 1}`].includes('-')
                                      ? stylesChild.highlightUnderColor
                                      : (!stylesChild[`column${index + 1}`]
                                          .notValidateSymbol
                                          ? item[`column${index + 1}`].includes(
                                              stylesChild[`column${index + 1}`]
                                                .validateSymbol || '%'
                                            )
                                          : true) &&
                                        Number(
                                          item[`column${index + 1}`]
                                            .replaceAll(',', '')
                                            .replace('%', '')
                                            .replaceAll('.', '')
                                        ) >
                                          (stylesChild[`column${index + 1}`]
                                            .highlightOverNumber !== undefined
                                            ? stylesChild[`column${index + 1}`]
                                                .highlightOverNumber
                                            : 10000)
                                      ? stylesChild.highlightOverColor
                                      : '#a0a0a0'
                                  }}
                                >
                                  {item[`column${index + 1}`]}
                                  {index + 1 === 1 &&
                                    hasDropdown &&
                                    item.subItems?.length > 0 && (
                                      <FaChevronDown
                                        style={{
                                          marginLeft: '22px'
                                        }}
                                        size={13}
                                        className={
                                          toggleSubItems.name ===
                                            item.column1 &&
                                          !toggleSubItems.isShow
                                            ? 'RotateDown'
                                            : ''
                                        }
                                        onClick={event =>
                                          handleGetData(event, item, false)
                                        }
                                      />
                                    )}
                                  {hasToggle &&
                                    indexColumnToggle.includes(index) && (
                                      <div
                                        onClick={event =>
                                          event.stopPropagation()
                                        }
                                      >
                                        {switchSettings.hasTooltip &&
                                          item[
                                            switchSettings.approved_role
                                          ] && (
                                            <TooltipComponent
                                              label={
                                                switchSettings.tooltip.label
                                              }
                                              message={
                                                switchSettings.tooltip.message
                                              }
                                              bold={switchSettings.tooltip.bold}
                                              key={`${Math.random()}`}
                                            />
                                          )}
                                        <Switch
                                          onChange={() =>
                                            handleToggleSwitch(item)
                                          }
                                          disabled={
                                            !!item[switchSettings.approved_role]
                                          }
                                          checked={
                                            item[switchSettings.ischecked] ||
                                            false
                                          }
                                          {...switchSettings}
                                        />
                                      </div>
                                    )}
                                </div>
                              </td>
                            )
                          } else {
                            return <td key={`${Math.random()}`}></td>
                          }
                        })}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={tableHead.length}
                      style={{
                        textAlign: 'center',
                        fontSize: 20,
                        color: '#000'
                      }}
                    >
                      Sem dados para análise no momento
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { TablePortlet }
