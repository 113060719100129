import React from 'react'
import { TooltipComponent } from '../../../../../../../../../components/TooltipComponent'
import { ContainerInput } from './style'
import { Input } from '../../../../../../../../../components/Form'

export const Table = (): JSX.Element => {
  return (
    <>
      <h4 style={{ marginBottom: '20px', marginTop: '25px' }}>LUCRATIVIDADE</h4>
      <div className="row">
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="Margem de lucro"
            message="A margem de lucro é uma medida de lucratividade. É calculado encontrando o lucro como uma porcentagem da receita. Existem 3 tipos de margens de lucro: margem de lucro bruto, margem de lucro operacional e margem de lucro líquido. A Margem de Lucro Bruto é calculada como o lucro bruto dividido pelas vendas líquidas."
          />
          <Input
            name="price_composition.margin_profit"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
      </div>

      <h4 style={{ marginBottom: '20px', marginTop: '25px' }}>CUSTO FIXO</h4>
      <div className="row">
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="Custo operacional"
            message="Custo operacional de um produto é toda a despesa gerada para se produzir o produto."
          />
          <Input
            name="price_composition.fixed_cost"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="Diferencial de aliquota na compra"
            message="É um instrumento utilizado para equilibrar a arrecadação desse imposto entre os estados."
          />
          <Input
            name="price_composition.aliq_dif"
            className="form-control"
            type="text"
            placeholder="0,00"
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
      </div>
      <h4 style={{ marginBottom: '20px', marginTop: '25px' }}>
        CUSTOS FISCAIS
      </h4>
      <div className="row">
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="Contribuição social"
            message="É a atribuição da capacidade ou titularidade de certos tributos a certas pessoas, que não são o próprio Estado, em benefício das próprias finalidades."
          />
          <Input
            name="price_composition.social_contrib"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="ICMS"
            message="Imposto sobre Operações relativas à Circulação de Mercadorias e sobre Prestações de Serviços de Transporte Interestadual e Intermunicipal e de Comunicação é um imposto cobrado pela movimentação de mercadorias já embutido nos produtos."
          />
          <Input
            name="price_composition.icms"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="IPI"
            message="Imposto sobre Produtos Industrializados. É um tributo federal, aplicado sempre que uma mercadoria sai da fábrica. No entanto, nem todos os produtos sofrem incidência do IPI"
          />
          <Input
            name="price_composition.ipi"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="PIS"
            message="É a abreviação de Programa de Integração Social e é cobrado sobre os seguintes itens: faturamento, importação e folha de pagamento"
          />
          <Input
            name="price_composition.pis"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="COFINS"
            message="Significa Contribuição para Financiamento da Seguridade Social, São contribuintes deste imposto empresas em geral. O fato gerador da COFINS é o lucro bruto de uma empresa"
          />
          <Input
            name="price_composition.cofins"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="Imposto de Renda"
            message="Imposto de Renda Pessoa Jurídica é um tributo federal que deve ser pago por todas as empresas com CNPJ ativo, sua base de cálculo considera o regime tributário da pessoa jurídica."
          />
          <Input
            name="price_composition.income_tax"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
        <ContainerInput className="form-content col-md-2">
          <TooltipComponent
            label="Adicional de Imposto de Renda"
            message="As empresas do Luco Presumido e Lucro Real farão o cálculo do adicional de IR de 10% sobre o valor excedente, caso a base de IRPJ ultrapasse R$ 240.000,00 anual, o limite trimestral é de R$ 60.000,00, onde calculamos R$ 20.000,00 mensal, quando o usuário opta por antecipar o imposto."
          />
          <Input
            name="price_composition.income_tax_aditional"
            className="form-control"
            type="text"
            placeholder="0,00"
            rules={{ required: true }}
            price
          />
          <span style={{ position: 'absolute', right: '23px', top: '33px' }}>
            %
          </span>
        </ContainerInput>
      </div>
    </>
  )
}
