import styled from 'styled-components'

export const Container = styled.div`
  .disable {
    display: none;
  }
  margin-top: 20px !important;
`

export const RadioInput = styled.input`
  margin-left: 15px;
  margin-right: 5px;
`
