import React from 'react'
import List from '../../pages/Financial/ProductCategoryBudget/List'
import ProductCategoryBudget from '../../pages/Financial/ProductCategoryBudget'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ProductCategoryBudgetRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/financial/productCategoryBudget" exact component={List} />
    <Route
      path="/financial/productCategoryBudget/update"
      component={ProductCategoryBudget}
      exact
    />
    <Route
      path="/financial/productCategoryBudget/create"
      component={ProductCategoryBudget}
      exact
    />
  </CustomSwitch>
)
