type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

const nameEntity = 'InvoiceIssuedForCnpj'
const nameSource = 'financial/invoicesReceived'
const namePageTitle = 'Notas Fiscais Recebidas'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Recebidas',
  to: '/financial/invoicesReceived',
  icon: ''
}

const destinations = [
  {
    id: 1,
    value: 'Revenda'
  },
  {
    id: 2,
    value: 'Produção'
  },
  {
    id: 3,
    value: 'Patrimônio'
  },
  {
    id: 4,
    value: 'Uso e Consumo'
  },
  {
    id: 5,
    value: 'Remessa'
  }
]
export const situations = [
  { id: 1, value: 'NORMAL' },
  { id: 2, value: 'DENEGADA' },
  { id: 3, value: 'NF-E CANCELADA' }
]

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  destinations
}
