import React from 'react'

import { Container, ContainerFooter } from './styles'

type TypeFooterCreateProduct = {
  onClickButtonBack?: (params: any) => any
  onClickButtonNext?: (params: any) => any
  onClickButtonSave?: (params: any) => any
  onSave?: (params: any) => void
}

export const Footer = ({
  onClickButtonBack,
  onClickButtonNext,
  onClickButtonSave
}: TypeFooterCreateProduct): JSX.Element => {
  return (
    <>
      <Container>
        <button
          type="button"
          onClick={onClickButtonBack}
          className={
            onClickButtonBack !== undefined
              ? 'btn dark btn-sm sbold uppercase'
              : 'btn disabled dark btn-sm sbold uppercase'
          }
        >
          <span
            className="fa fa-arrow-left"
            aria-hidden="true"
            style={{ marginRight: '5px' }}
          />
          Voltar
        </button>
        <button
          type="button"
          onClick={onClickButtonNext}
          className={
            onClickButtonNext !== undefined
              ? 'btn dark btn-sm sbold uppercase'
              : 'btn disabled dark btn-sm sbold uppercase'
          }
        >
          Continuar
          <span
            className="fa fa-arrow-right"
            aria-hidden="true"
            style={{ marginLeft: '5px' }}
          />
        </button>
      </Container>
      <hr style={{ marginTop: '25px' }} />
      <ContainerFooter>
        <button
          onClick={onClickButtonSave}
          className="btn dark btn-sm bold uppercase"
        >
          salvar
        </button>
      </ContainerFooter>
    </>
  )
}
