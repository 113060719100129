import React from 'react'
import List from '../../pages/Commercial/Agent/Appointments'
import PrivateRoutes from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const AgentsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/commercial/clientsAppointments/listByUser"
      exact
      component={List}
    />
  </CustomSwitch>
)
