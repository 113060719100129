import React from 'react'
import UsersList from '../../pages/Config/Users/List'
import UsersUpdate from '../../pages/Config/Users/Update'
import UsersCreate from '../../pages/Config/Users/Create'
import UsersView from '../../pages/Config/Users/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const UsersRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/users" exact component={UsersList} />
    <Route path="/users/create" component={UsersCreate} exact />
    <Route path="/users/view/:id" component={UsersView} exact />
    <Route path="/users/update/:id" component={UsersUpdate} exact />
  </CustomSwitch>
)
