import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { FormCategory } from '../components/Form'
import Container from '../../../../components/Container'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import Modal from '../../../../components/Modal'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import {
  FreightLocalizationPercentage,
  nameActions,
  nameEntity,
  namePageTitle,
  nameSource
} from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { viewHaders } from '../domain/headers'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { genericMaskWithTwoZero } from '../../../../utlis/mask'

interface FreightLocalizationtData extends FreightLocalizationPercentage {
  id: number
  created_at: string
  updated_at: string
}

const FreightLocalizationView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [freighLocalization, setFreightLocalization] =
    useState<FreightLocalizationtData | null>(null)
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])
  const searchFreightLocalization = useRef([{ parent_id: id }])
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    localization: string
  }>({
    id: 0,
    isActive: false,
    localization: ''
  })

  const [currentItemUpdate, setCurrentItemUpdate] =
    useState<FreightLocalizationtData>({} as FreightLocalizationtData)

  const [modalEdit, setModalEdit] = useState(false)
  const [modalCreate, setModalCreate] = useState(false)

  const handleClickOnClose = () => {
    setModalCreate(false)
    setModalEdit(false)
  }
  const handlerOnClickButtonEditInCurrentRow = (
    currentValue: FreightLocalizationtData
  ) => {
    setCurrentItemUpdate(currentValue)
    setModalEdit(true)
  }

  const handleClickOnOpenModalCreate = () => {
    setModalCreate(true)
  }

  const refModal = useRef(null)
  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadCategory(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<FreightLocalizationtData>(apiList(id))
        const { data } = response
        setFreightLocalization(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a categoria',
          description:
            'Houve um error ao carregar a categoria, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadCategory()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const handlerOnClickButtonRemoveInCurrentRow = ({
    id,
    localization
  }: FreightLocalizationtData) => {
    setIsActiveAlert({ id, localization, isActive: true })
  }

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      localization: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        localization: ''
      })
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      updateDataTable()
    } catch (err: any) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        localization: ''
      })
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{freighLocalization?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{freighLocalization?.localization}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  UF
                </label>
                <p>{freighLocalization?.code}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Percentual
                </label>
                <p>{genericMaskWithTwoZero(freighLocalization?.percentage)}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{freighLocalization?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{freighLocalization?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab title="Localidades">
                  <div className="portlet light">
                    <div className="portlet-title">
                      <div className="caption">Listagem</div>
                      <div className="tools">
                        <div
                          onClick={handleClickOnOpenModalCreate}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="fa fa-plus" /> Adicionar
                        </div>
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        format={{ orderBy: 'localization' }}
                        source={nameSource}
                        entity={nameEntity}
                        headers={viewHaders}
                        parentId={id}
                        searchParameters={searchFreightLocalization.current}
                        onActions={{
                          onClickButtonEdit:
                            handlerOnClickButtonEditInCurrentRow,
                          onClickButtonRemove:
                            handlerOnClickButtonRemoveInCurrentRow
                        }}
                        customHeaders={[
                          {
                            name: 'Percentual',
                            field: 'percentage',
                            sortable: false,
                            element: e => (
                              <span>
                                {genericMaskWithTwoZero(e.percentage)}
                              </span>
                            )
                          }
                        ]}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab title="Histórico">
                  <div className="portlet light">
                    <div className="portlet-title">
                      <div className="caption">Listagem</div>
                      <div className="tools"></div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalCreate}
        pageTitle={'Adicionar'}
        Children={
          <FormCategory
            typeForm={'create'}
            parent={freighLocalization}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(id)
            }}
          />
        }
        sectionStyle={{ width: '400px' }}
      />
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEdit}
        pageTitle={'Editar'}
        Children={
          <FormCategory
            typeForm={'update'}
            parent={freighLocalization}
            freightLocalizationPercentage={currentItemUpdate}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(id)
            }}
          />
        }
        sectionStyle={{ width: '400px' }}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.localization} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${freighLocalization?.localization} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(
            Number(freighLocalization?.id)
          )
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default FreightLocalizationView
