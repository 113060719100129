import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'
import DataTable from '../components/DataTable'

interface CompetitorsData {
  competitor: Competitor
  competitorProducts: CompetitorProductsData[]
}

interface CompetitorsDataRequest {
  competitor: Competitor
  competitorProducts: CompetitorProductsDataRequest[]
}

interface Competitor {
  id: number
  name: string
  email: string
  telephone: string
  comments: string
  active: boolean
  created_at: string
  updated_at: string
}
interface CompetitorProductsDataRequest {
  id: number
  name: string
  prices: string
  history: HistoryProduct[]
  updated_at: string
}

interface HistoryProduct {
  old_price: string
  updated_at: string
  name: string
}
interface CompetitorProductsData {
  id: number
  name: string
  prices: string
  updated_at: string
  history: HistoryProduct[]
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [competitor, setCompetitor] = useState<CompetitorsData>({
    competitor: {
      active: true,
      comments: '',
      created_at: '',
      email: '',
      id: 0,
      name: '',
      telephone: '',
      updated_at: ''
    },
    competitorProducts: []
  })

  const { addToast } = useToast()

  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadData(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<CompetitorsDataRequest>(apiList(id))
        const { data } = response
        setCompetitor(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o concorrente',
          description:
            'Houve um error ao carregar o concorrente, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [id, addToast, activeLoading, disableLoading, location.pathname, history])
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Concorrente removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Concorrente não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Concorrente removido com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Concorrente não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(id),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{competitor?.competitor.name}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="email" className="control-label">
                  Email
                </label>
                <p>{competitor?.competitor.email}</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="telephone" className="control-label">
                  Telefone
                </label>
                <p>{competitor?.competitor.telephone}</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="comments" className="control-label">
                  Comentários
                </label>
                <p>{competitor?.competitor.comments}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="active" className="control-label">
                  Ativo
                </label>
                <p>{competitor?.competitor.active ? 'SIM' : 'NÃO'}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{competitor?.competitor.created_at}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{competitor?.competitor.updated_at}</p>
              </div>
            </div>
          </div>

          {competitor.competitorProducts.length > 0 && (
            <DataTable
              source={'nameSource'}
              products={competitor.competitorProducts}
              format={{ orderBy: 'name' }}
              notHasChildren
              headers={[
                { name: 'Produto', field: 'name', sortable: true },
                { name: 'Preço', field: 'prices', sortable: false },
                {
                  name: 'Atualizado em',
                  field: 'updated_at',
                  sortable: false
                },
                { name: 'Histórico', field: 'actions', sortable: false }
              ]}
            />
          )}

          <p>&nbsp;</p>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o concorrente ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o concorrente ${competitor?.competitor.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(
            Number(competitor?.competitor.id)
          )
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}
export default View
