type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'ClientEconomicGroup'
const nameSource = 'commercial/clientsEconomicGroups'
const namePageTitle = 'Clientes Origens'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Clientes Grupos econômicos',
  to: '/commercial/clientsEconomicGroups',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/clientsEconomicGroups/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/clientsEconomicGroups/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/clientsEconomicGroups/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions
}
