import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../../components/Container'
import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import { FormCategory } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions, namePageTitle } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../../hooks/toast'

const ProductAtributeUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [categoryItem, setCategoryItem] = useState<{
    id: string
    name: string
  }>({ id: '', name: '' })

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setCategoryItem({ id: data.id, name: data.name })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a categoria',
          description:
            'Houve um error ao carregar a categoria, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }

    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={[toolsUpdate]}
    >
      <div className="form-body">
        <FormCategory
          valueInput={categoryItem.name}
          typeForm={{
            idUpdate: Number(categoryItem.id),
            inputValue: categoryItem.name
          }}
        />
      </div>
    </Container>
  )
}

export default ProductAtributeUpdate
