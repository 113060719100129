import React from 'react'
import Route from '../../Route'
import ListProductPage from '../../../pages/Warehouse/product/ProductRegister_temp/pages/List'
import CreateProductPage from '../../../pages/Warehouse/product/ProductRegister_temp/pages/create'
import UpdateProductPage from '../../../pages/Warehouse/product/ProductRegister_temp/pages/update'
import ViewProductPage from '../../../pages/Warehouse/product/ProductRegister_temp/pages/View'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const ProductRegisterRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/products" exact component={ListProductPage} />
    <Route
      path="/warehouse/products/create"
      component={CreateProductPage}
      exact
    />
    <Route
      path="/warehouse/products/update/:id"
      component={UpdateProductPage}
      exact
    />
    <Route
      path="/warehouse/products/view/:id"
      component={ViewProductPage}
      exact
    />
  </CustomSwitch>
)
