import React, { useEffect, useState } from 'react'
import Container from '../../../components/Container'
import { namePageTitle, nameActions } from '../domain/info'
import { apiList } from '../domain/api'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FormCategory } from '../components/Form'
import api from '../../../services/api'
import { useLoading } from '../../../hooks/loading'
import { useToast } from '../../../hooks/toast'

interface PermissionData {
  id: number
  name: string
  description: string
  method: string
  base_url: string
  path?: string
  grouped: string
  function: string
}

const Update: React.FC = () => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [permission, setPermission] = useState<PermissionData>({
    base_url: '',
    description: '',
    id: 0,
    method: '',
    name: '',
    path: '',
    grouped: '',
    function: ''
  })
  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get<PermissionData>(apiList(id))
        const { data } = response
        setPermission({
          ...data,
          id: Number(id)
        })
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a permissão',
          description:
            'Houve um error ao carregar a permissão, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }

    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.update.name}
        breadcrumb={breadcrumbUpdate}
        tools={[toolsUpdate]}
      >
        <div className="form body">
          <FormCategory
            valueInput={{
              ...permission
            }}
            typeForm={{
              idUpdate: permission.id,
              inputValue: permission.name
            }}
          />
        </div>
      </Container>
    </>
  )
}
export default Update
