import React, { useEffect, useState } from 'react'
import { Container, FooterStyled, IconRemove } from './style'

import { useProduct } from '../../../providers/product/ProductProvider'
import { SearchComponentProduct } from '../SearchComponentProduct'
import api from '../../../../../../services/api'
import { Input, Select } from '../../../../../../components/Form'
import Button from '../../../../../../components/Button'
import { useToast } from '../../../../../../hooks/toast'

type ProductListFetch = {
  id: number
  name: string
  prices: string
  current_stock?: number
  product?: {
    id: number
    name: string
  }
}

type ProductListType = {
  id: number
  name: string
}
export const Table = ({
  typeForm
}: {
  typeForm?: 'create' | 'update'
}): JSX.Element => {
  const {
    warehouseRequests,
    warehouseRequestsProducts,
    setWarehouseRequestsProducts,
    oldWarehouseRequestsProducts,
    stocks,
    setStocks
  } = useProduct()
  const [productList, setProductList] = useState<ProductListType[]>([])
  const [selected, setSelected] = useState(false)
  const { addToast } = useToast()
  useEffect(() => {
    setSelected(typeForm === 'update')
  }, [typeForm])
  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<ProductListFetch[]>('warehouse/stocks')

      setStocks(data)

      setWarehouseRequestsProducts(prev => {
        const copy = [...prev]

        const final = copy.map(c => {
          const stock = data?.find(s => Number(s.id) === Number(c.stock_id))
          return {
            ...c,
            current_stock: stock?.current_stock
          }
        })
        return final
      })

      const products = data.map(s => ({
        id: s.product.id,
        name: s.product.name
      }))
      const filtered = products?.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      )

      setProductList(filtered)
    })()
  }, [setStocks, setWarehouseRequestsProducts])

  return (
    <Container className="table-responsive">
      <div className="table-content-relative">
        <table className="table table-bordered margin-bottom-0">
          <tbody>
            <tr>
              <th style={{ width: '2000px' }}>Produto</th>
              <th style={{ width: '2000px' }}>Variação</th>
              <th style={{ width: '500px' }}>Estoque atual</th>
              <th style={{ width: '200px' }}>Quantidade</th>
              <th>Ações</th>
            </tr>
            {warehouseRequestsProducts &&
              warehouseRequestsProducts.map((item, index) => {
                let currentStock = item?.stock
                  ? item?.stock.current_stock
                  : item.current_stock
                const oldQuantity = oldWarehouseRequestsProducts.find(
                  o => o.id === item.id
                )?.quantity
                if (oldQuantity && currentStock !== undefined) {
                  currentStock = Number(currentStock) + Number(oldQuantity)
                }
                return (
                  <tr
                    key={index}
                    style={{
                      height: '10px'
                    }}
                  >
                    <td>
                      {SearchComponentProduct({
                        index,
                        warehouseRequests,
                        warehouseRequestsProducts,
                        setWarehouseRequestsProducts,
                        productList,
                        selected,
                        setSelected
                      })}
                    </td>
                    <td>
                      <Select
                        name={`warehouseRequestsProducts.${index}.stock_id`}
                        className="form-control"
                        options={[
                          { name: 'SELECIONE', value: '' },
                          ...stocks
                            .filter(
                              a =>
                                a.product.id ===
                                warehouseRequestsProducts[index].product_id
                            )
                            .map(a => ({
                              name: a.name,
                              value: a.id
                            }))
                            .sort((currentItem, nextItem) =>
                              currentItem.name.localeCompare(nextItem.name)
                            )
                        ]}
                        onChange={({ target }) =>
                          setWarehouseRequestsProducts(prev => {
                            const copy = [...prev]
                            copy[index].stock_id = String(target.value)
                            const stock = stocks.find(
                              s => s.id === Number(target.value)
                            )
                            copy[index].current_stock = stock?.current_stock
                            copy[index].quantity = ''
                            return copy
                          })
                        }
                        style={{
                          pointerEvents:
                            !!warehouseRequests?.situation &&
                            warehouseRequests?.situation !== 'new'
                              ? 'none'
                              : 'auto',
                          background:
                            !!warehouseRequests?.situation &&
                            warehouseRequests?.situation !== 'new'
                              ? '#eef1f5'
                              : 'auto'
                        }}
                        value={warehouseRequestsProducts[index].stock_id}
                        rules={{ required: true }}
                        blank
                        defaultValue={''}
                        controlled
                      />
                    </td>
                    <td>
                      <Input
                        name="current_stock"
                        className="form-control"
                        value={currentStock}
                        disabled
                      />
                    </td>
                    <td>
                      <Input
                        labelError="Quantidade"
                        name={`warehouseRequestsProducts.${index}.quantity`}
                        className="form-control"
                        type="text"
                        onKeyPress={event => {
                          const regex = /^[0-9.]+$/
                          if (!regex.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={({ target }) => {
                          let quantity = Number(target.value)

                          if (currentStock < quantity) {
                            addToast({
                              type: 'error',
                              title: 'Quantidade insuficiente',
                              description: `Quantidade dessa variação é ${currentStock}`,
                              style: {
                                width: '350px',
                                padding: '20px',
                                fontSize: '16px'
                              },
                              styleContainer: {
                                top: '40%'
                              },
                              time: 8
                            })
                            quantity = undefined
                          }
                          if (currentStock === undefined) {
                            addToast({
                              type: 'error',
                              title: 'Selecione uma variação'
                            })
                            quantity = undefined
                          }
                          setWarehouseRequestsProducts(prev => {
                            const copy = [...prev]
                            copy[index].quantity = quantity
                              ? String(quantity)
                              : ''
                            return copy
                          })
                        }}
                        readOnly={
                          !!warehouseRequests?.situation &&
                          warehouseRequests?.situation !== 'new'
                        }
                        value={warehouseRequestsProducts[index].quantity}
                        rules={{ required: true }}
                        fullControlled
                      />
                      <Input
                        name={`warehouseRequestsProducts.${index}.id`}
                        className="form-control"
                        value={warehouseRequestsProducts[index].id}
                        type="hidden"
                        controlled
                      />
                    </td>
                    <td className="actions">
                      <IconRemove
                        onClick={() => {
                          if (warehouseRequestsProducts.length <= 1) {
                            return
                          }
                          setWarehouseRequestsProducts(prev => {
                            const copy = JSON.parse(JSON.stringify(prev))
                            copy.splice(index, 1)
                            return copy
                          })
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      {!(
        !!warehouseRequests?.situation && warehouseRequests?.situation !== 'new'
      ) && (
        <>
          <hr />
          <FooterStyled>
            <a
              onClick={() =>
                setWarehouseRequestsProducts(prev => {
                  const copy = [...prev]
                  const final = [
                    ...copy,
                    { id: '0', stock_id: '', quantity: '' }
                  ]
                  return final
                })
              }
              className="btn dark btn-sm sbold uppercase"
            >
              <span
                className="fa fa-plus"
                aria-hidden="true"
                style={{ marginRight: '5px' }}
              />
              produto
            </a>
          </FooterStyled>
        </>
      )}
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
      <div style={{ height: '200px' }}></div>
    </Container>
  )
}
