import React from 'react'
import Route from '../../Route'
import ListStockPage from '../../../pages/Warehouse/Stocks/List'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const StocksRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/stocks" exact component={ListStockPage} />
  </CustomSwitch>
)
