import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { FormClientEconomicGroup } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions, namePageTitle } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../hooks/toast'

const ClientEconomicGroup = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  const [clientEconomicGroup, setClientEconomicGroup] = useState<{
    id: number
    name: string
  }>()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setClientEconomicGroup({ id: data.id, name: data.name })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o registro',
          description:
            'Houve um error ao carregar o registro, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={[toolsUpdate]}
    >
      <div className="form-body">
        <FormClientEconomicGroup
          typeForm="update"
          clientEconomicGroup={clientEconomicGroup}
        />
      </div>
    </Container>
  )
}

export default ClientEconomicGroup
