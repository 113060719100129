import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Warehouse/PickOrder/List'
import View from '../../pages/Warehouse/PickOrder/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PickOrderRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/warehouse/pickOrder" exact component={List} />
    <PrivateRoutes
      path="/warehouse/pickOrder/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
