import React from 'react'
import { Container } from './style'

export type DetailsType = {
  name: string
  width: string
  height: string
  length: string
  weight: string
  thickness: string
  measure: string
  way_use: string
  old_product: string
  old_product_name: string
  measure_weight: string
  description_details: string
  technical_specification: string
  dimensions: string
  dimensions_open: string
  dimensions_closed: string
  dimensions_calota: string
  dimensions_tape: string
  dimensions_rope: string
}

type DetailsProps = {
  detail: DetailsType
}

export const Details = ({ detail }: DetailsProps): JSX.Element => {
  return (
    <Container>
      <div className="section">
        {(detail.dimensions ||
          detail.dimensions_open ||
          detail.dimensions_closed ||
          detail.dimensions_tape ||
          detail.dimensions_calota) && (
          <div className="text-area row">
            {detail.dimensions && (
              <div className="form-content col-md-2">
                <label htmlFor="tipo do produto">Medida do produto</label>
                <p>{detail.dimensions}</p>
              </div>
            )}
            {detail.dimensions_open && (
              <div className="form-content col-md-2">
                <label htmlFor="tipo do produto">
                  Medida do produto aberto
                </label>
                <p>{detail.dimensions_open}</p>
              </div>
            )}
            {detail.dimensions_closed && (
              <div className="form-content col-md-2">
                <label htmlFor="tipo do produto">
                  Medida do produto fechado
                </label>
                <p>{detail.dimensions_closed}</p>
              </div>
            )}
            {detail.dimensions_calota && (
              <div className="form-content col-md-2">
                <label htmlFor="tipo do produto">Medida calota</label>
                <p>{detail.dimensions_calota}</p>
              </div>
            )}
            {detail.dimensions_tape && (
              <div className="form-content col-md-2">
                <label htmlFor="tipo do produto">Medida da fita</label>
                <p>{detail.dimensions_tape}</p>
              </div>
            )}
            {detail.dimensions_rope && (
              <div className="form-content col-md-2">
                <label htmlFor="tipo do produto">Medida da corda</label>
                <p>{detail.dimensions_rope}</p>
              </div>
            )}
          </div>
        )}
        <div className="text-area row">
          <div className="form-content col-md-12 item">
            <label htmlFor="tipo do produto">Descrição e detalhes</label>
            <p>{detail.description_details}</p>
          </div>
        </div>
        <div className="text-area row">
          <div className="form-content col-md-12 item">
            <label htmlFor="tipo do produto">Especificação Técnica</label>
            <p>{detail.technical_specification}</p>
          </div>
        </div>
        <div className="text-area row">
          <div className="form-content col-md-12 item">
            <label htmlFor="tipo do produto">Forma de utilização</label>
            <p>{detail.way_use}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export const labelDetails = 'Detalhe e medida'
export const nameDetails = '@@tabs-view-details'
