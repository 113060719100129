import styled from 'styled-components'

export const Container = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};

  hr {
    margin: 15px 0;
  }

  &:first-child {
    margin-bottom: 5rem;
  }
`

export const TabsContainer = styled.ul`
  display: flex;
  margin: 0;
  margin-bottom: 3rem;
  list-style: none;
  border-bottom: 1px solid #ddd;
`
export const TabItem = styled.li<{ active: boolean }>`
  margin-bottom: -1px;
  padding: 1rem 2rem;
  border-radius: 4px 4px 0 0;
  border: ${({ active }) => (active ? '1px solid #ddd' : 'none')};
  border-bottom-color: #fff;
`
