import React, { useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import moment from 'moment'
import { FaDownload } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { useToast } from '../../../../hooks/toast'
import currency from 'currency.js'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utlis/mask'
import { useLoading } from '../../../../hooks/loading'
import { IResponseTransfer } from '../View'
import transferIcon from '../../../../assets/image/svg/transfer.svg'
const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })

const PaymentRemittanceFileList = (): JSX.Element => {
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
  }>({
    id: 0,
    isActive: false
  })
  const [alertPay, setIsActiveAlertPay] = useState<{
    isActive: boolean
    id: number
  }>({
    id: 0,
    isActive: false
  })
  const { updateDataTable } = useUpdateDataTable()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[toolsList]}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        orderBySort="DESC"
        notHasChildren
        headers={headers}
        customHeaders={[
          {
            name: 'Data',
            field: 'created_at',
            sortable: false,
            element: item => (
              <span>
                {moment(item.created_at, 'DD/MM/YYYY HH:mm:ss').format(
                  'DD/MM/YYYY'
                )}
              </span>
            )
          },
          {
            name: 'Empresa',
            field: 'company_id',
            sortable: false,
            element: item => (
              <span>{item.company_id === 1 ? 'COMERCIO' : 'INDUSTRIA'}</span>
            )
          },
          {
            name: 'Conta',
            field: 'bank_account_id',
            sortable: false,
            element: item => <span>{item.bank_account_name}</span>
          },
          {
            name: 'Transferências',
            field: 'value_transfers',
            sortable: false,
            element: item => (
              <span>{`${BRL(
                genericMaskWithTwoZeroWithPoint(item.value_transfers)
              ).format()} (${item.quantity_tranfers || '0'})`}</span>
            )
          },
          {
            name: 'Títulos',

            field: 'value_titles',
            sortable: false,
            element: item => (
              <span>{`${BRL(
                genericMaskWithTwoZeroWithPoint(item.value_titles)
              ).format()} (${item.quantity_titles || '0'})`}</span>
            )
          },
          {
            name: 'Convênios/Tributos',
            field: 'value_taxes',
            sortable: false,
            element: item => (
              <span>{`${BRL(
                genericMaskWithTwoZeroWithPoint(item.value_taxes)
              ).format()} (${item.quantity_taxes || '0'})`}</span>
            )
          },
          {
            name: 'Valor Total',
            field: 'total_value',
            sortable: false,
            element: item => (
              <span>{`${BRL(
                genericMaskWithTwoZeroWithPoint(item.total_value)
              ).format()}`}</span>
            )
          },
          {
            name: 'Tipo',
            field: 'type',
            sortable: false,
            element: item => (
              <span>
                {item.type === 'PAYROLL'
                  ? 'FOLHA DE PAGAMENTO'
                  : 'PAGAMENTOS EM GERAIS'}
              </span>
            )
          }
        ]}
        actions={[
          {
            name: 'Baixar',
            title: 'Baixar',
            htmlIcon: <FaDownload />,
            onClick: async item => {
              let response
              if (item.bank_account_id !== 13) {
                response = await api.get(
                  `financial/paymentRemittanceFiles/getOldRemittanceFile/${item.id}`
                )
              } else if (item.type === 'PAYROLL') {
                response = await api.post('financial/payrolls/generate', {
                  id: item.id
                })
              } else {
                response = await api.post(
                  'financial/paymentRemittanceFiles/generate',
                  {
                    id: item.id
                  }
                )
              }
              const blob = new Blob([response.data], {
                type: 'text/plain'
              })
              console.log(response.headers)

              const name = response.headers['content-disposition']
                .split('filename=')[1]
                .split('.')[0]
              const extension = response.headers['content-disposition']
                .split('.')[1]
                .split(';')[0]

              const filename = `${name.substring(
                1,
                name.length
              )}.${extension.substring(0, extension.length - 1)}`
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = filename
              a.click()
            }
          },
          {
            name: 'Transmitir',
            title: 'Transmitir',
            element: item =>
              item.bank_account_id === 13 ? (
                <img src={transferIcon} width={13} style={{ opacity: 0.5 }} />
              ) : (
                <></>
              ),
            onClick: async item => {
              setIsActiveAlertPay({
                id: item.id,
                isActive: true
              })
            }
          },
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: item => `/financial/paymentRemittanceFiles/view/${item.id}`
          },
          {
            name: 'Deletar',
            title: 'Deletar',
            element: item =>
              item.type === 'PAYROLL' ? <MdClose opacity={0.4} /> : <MdClose />,
            onClick: async item => {
              if (item.type === 'PAYROLL') {
                return
              }
              setIsActiveAlert({
                id: item.id,
                isActive: true
              })
            }
          }
        ]}
      />
      <Alert
        message={'Tem certeza que deseja excluir esse arquivo?'}
        onClickCancellButton={() =>
          setIsActiveAlert({
            id: 0,
            isActive: false
          })
        }
        onClickConfirmButton={async () => {
          try {
            await api.delete(`financial/paymentRemittanceFiles/${alert.id}`)
            addToast({
              type: 'success',
              title: 'Arquivo removido!',
              description: 'Arquivo foi removido com sucesso!'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Error ao excluir arquivo.',
              description:
                'Houve um error ao tentar remover o arquivo, tente novamente mais tarde'
            })
          }
          updateDataTable()
          setIsActiveAlert({
            id: 0,
            isActive: false
          })
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={'Tem certeza que deseja enviar esse pagamento?'}
        onClickCancellButton={() =>
          setIsActiveAlertPay({
            id: 0,
            isActive: false
          })
        }
        onClickConfirmButton={async () => {
          activeLoading()
          try {
            const { data } = await api.post<IResponseTransfer>(
              `financial/paymentRemittanceFiles/transfer/${alertPay.id}`
            )
            disableLoading()
            let type: 'success' | 'info' | 'error' = 'success'
            let title = 'Transmissão realizada com sucesso.'
            let message = ''
            if (data.errors?.length) {
              type = 'info'
              title =
                'Transmissão realizada parcialmente. Por favor, verificar a visualização do arquivo.'
              const financialIds = data.errors.map(erro => erro.id).join(', ')
              message = `Movimentações com erro: ${financialIds} `
            }
            addToast({
              time: 10,
              type,
              title,
              description: message
            })
          } catch (error) {
            disableLoading()
            addToast({
              type: 'error',
              title: 'Error ao transmitir arquivo.'
            })
          }
          updateDataTable()
          setIsActiveAlertPay({
            id: 0,
            isActive: false
          })
        }}
        isActive={alertPay.isActive}
      />
    </Container>
  )
}

export default PaymentRemittanceFileList
