import styled from 'styled-components'
import search from '../../../../../assets/image/svg/search-solid.svg'
export const SearchContainer = styled.div<{ erro?: boolean }>`
  position: relative;
  margin-bottom: 2rem;

  ul {
    width: calc(100% - 30px);
    max-height: 350px;
    margin: 0;
    padding: 0;

    position: absolute;

    border: ${props => props.erro && '1px solid red'};
    color: ${props => (props.erro ? 'red' : '#000')};
    background: #fff;

    overflow: auto;
    z-index: 999;

    li {
      font-size: 14px;
      padding: 3px;
      cursor: pointer;

      &:hover {
        background: #f1f2f3;
      }
      input {
        cursor: pointer;
      }
    }
  }
  input {
    background-image: url(${search});
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 15px;
    border: 1px solid #ccc;

    text-indent: 30px;
  }
`
