import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  Container,
  DropdownDate,
  DateButton,
  WrapperDates,
  ContainerInput,
  ContainerDateYear
} from './styles'
import {
  FaTimes,
  FaCalendarAlt,
  FaArrowLeft,
  FaArrowRight
} from 'react-icons/fa'

interface DateRangeProps {
  minDate: Date
  maxDate: Date
  minRange: number | undefined
  maxRange: number | undefined
  firstDate: string
  secondDate: string
  firstDateFull: string
  secondDateFull: string
}

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  handleSwitchPeriod(index: number): void
  handleResetData(): void
  handleSetDate(date: string, update?: boolean): void
  isReset: boolean
}
const DatePicker: React.FC<DatePickerProps> = ({
  className,
  handleSwitchPeriod,
  handleResetData,
  isReset,
  handleSetDate,
  ...rest
}) => {
  const [months] = useState([
    {
      name: 'January',
      shortName: 'JAN'
    },
    {
      name: 'February',
      shortName: 'FEV'
    },
    {
      name: 'March',
      shortName: 'MAR'
    },
    {
      name: 'April',
      shortName: 'ABR'
    },
    {
      name: 'May',
      shortName: 'MAI'
    },
    {
      name: 'June',
      shortName: 'JUN'
    },
    {
      name: 'July',
      shortName: 'JUL'
    },
    {
      name: 'August',
      shortName: 'AGO'
    },
    {
      name: 'September',
      shortName: 'SET'
    },
    {
      name: 'October',
      shortName: 'OUT'
    },
    {
      name: 'November',
      shortName: 'NOV'
    },
    {
      name: 'December',
      shortName: 'DEZ'
    }
  ])
  const [currentMonth, setCurrentMonth] = useState('Selecione o Mês')
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [toggleModal, setToggleModal] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeProps>({
    minDate: new Date(),
    maxDate: new Date(),
    maxRange: undefined,
    minRange: undefined,
    firstDate: '',
    secondDate: '',
    firstDateFull: '',
    secondDateFull: ''
  })

  const verifyRangeDate = (index: number) => {
    if (
      selectedDateRange.minRange === index ||
      index === selectedDateRange.maxRange
    ) {
      return 'selectedDate activeDate'
    } else if (selectedDateRange.maxRange) {
      if (
        selectedDateRange.minRange > index ||
        index < selectedDateRange.maxRange
      ) {
        return 'activeDate'
      }
    }
    return ''
  }

  const handleToggleModal = useCallback(() => {
    handleSwitchPeriod(4)
    setToggleModal(!toggleModal)
  }, [handleSwitchPeriod, toggleModal])
  const handleSetCurrentMonth = useCallback(
    (month: string, monthFullName: string, index: number) => {
      setSelectedDateRange({
        minRange: index,
        maxRange: undefined,
        minDate: new Date(`${currentYear}/${monthFullName}`),
        maxDate: new Date(`${currentYear}/${monthFullName}`),
        firstDate: month,
        secondDate: month,
        firstDateFull: monthFullName,
        secondDateFull: monthFullName
      })
      setCurrentMonth(`Mês selecionado: ${month}/${currentYear}`)
      handleSetDate(
        `period ${monthFullName} ${currentYear} até ${monthFullName} ${currentYear}`
      )
      handleToggleModal()
    },
    [currentYear, handleSetDate, handleToggleModal]
  )
  const handleClearCurrentMonth = useCallback(() => {
    setToggleModal(false)
    handleSetDate('Mes')
    handleResetData()
    setCurrentMonth('Selecione o Mês')
    setSelectedDateRange({
      minDate: new Date(),
      maxDate: new Date(),
      maxRange: -1,
      minRange: -1,
      firstDate: '',
      secondDate: '',
      firstDateFull: '',
      secondDateFull: ''
    })
    setCurrentYear(new Date().getFullYear())
  }, [handleResetData, handleSetDate])
  const handleClearInput = useCallback(() => {
    if (currentMonth === 'Selecione o Mês') {
      setToggleModal(false)
      setCurrentMonth('Selecione o Mês')
      setCurrentYear(new Date().getFullYear())
    }
  }, [currentMonth])

  const handleNextYear = useCallback(() => {
    if (currentYear === new Date().getFullYear()) {
      return
    }
    setCurrentYear(currentYear + 1)
  }, [currentYear])
  const handlePreviousYear = useCallback(() => {
    setCurrentYear(currentYear - 1)
  }, [currentYear])

  useEffect(() => {
    if (isReset) {
      if (currentMonth !== 'Selecione o Mês') {
        setToggleModal(false)
        setCurrentMonth('Selecione o Mês')
        setCurrentYear(new Date().getFullYear())
        setSelectedDateRange({
          minDate: new Date(),
          maxDate: new Date(),
          maxRange: -1,
          minRange: -1,
          firstDate: '',
          secondDate: '',
          firstDateFull: '',
          secondDateFull: ''
        })
      }
    }
  }, [currentMonth, isReset])
  return (
    <Container onMouseLeave={handleClearInput}>
      <ContainerInput className={className}>
        <FaCalendarAlt
          onClick={handleToggleModal}
          style={{ marginRight: '5px', marginBottom: '2px' }}
          size={13}
          className="FaCalendar"
        />
        <input
          readOnly
          className={currentMonth.length > 10 ? 'InputGrowth' : ''}
          type="text"
          value={currentMonth}
          onClick={handleToggleModal}
          style={{
            color: toggleModal && '#fff'
          }}
          {...rest}
        />
        {currentMonth !== 'Selecione o Mês' && (
          <FaTimes onClick={handleClearCurrentMonth} />
        )}
      </ContainerInput>
      {toggleModal && (
        <DropdownDate>
          <ContainerDateYear>
            <FaArrowLeft onClick={handlePreviousYear} color="#a0a0a0" />
            <p>{currentYear}</p>
            <FaArrowRight
              onClick={handleNextYear}
              color={
                currentYear === new Date().getFullYear() ? '#ccc' : '#a0a0a0'
              }
            />
          </ContainerDateYear>
          <WrapperDates>
            {months.map((month, index) => (
              <div key={month.name} className={verifyRangeDate(index)}>
                <DateButton
                  type="button"
                  className={verifyRangeDate(index)}
                  isSelected={
                    selectedDateRange.minRange === index ||
                    index === selectedDateRange.maxRange
                  }
                  onClick={() =>
                    handleSetCurrentMonth(month.shortName, month.name, index)
                  }
                >
                  {month.shortName}
                </DateButton>
              </div>
            ))}
          </WrapperDates>
        </DropdownDate>
      )}
    </Container>
  )
}

export { DatePicker }
