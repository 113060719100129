import React from 'react'
import { Table } from './Table'

export const HasCompositionTab = ({
  typeForm
}: {
  typeForm?: 'create' | 'update'
}): JSX.Element => {
  const table = Table({ typeForm })

  return <div>{table}</div>
}

export const labelHasComposition = 'Composição'
export const nameHasComposition = '@@tabs-has-composition'
