import React from 'react'
import List from '../../pages/Commercial/ClientsTypes/List'
import Update from '../../pages/Commercial/ClientsTypes/Update'
import Create from '../../pages/Commercial/ClientsTypes/Create'
import View from '../../pages/Commercial/ClientsTypes/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsTypesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/clientsTypes" exact component={List} />
    <Route
      path="/commercial/clientsTypes/update/:id"
      component={Update}
      exact
    />
    <Route path="/commercial/clientsTypes/create" component={Create} exact />
    <Route path="/commercial/clientsTypes/view/:id" component={View} exact />
  </CustomSwitch>
)
