import React, { ReactNode, HTMLAttributes } from 'react'
import { Container } from './styles'

interface RootCustoSummaryProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export function RootCustomSummary({
  children,
  ...rest
}: RootCustoSummaryProps) {
  return (
    <Container {...rest}>
      <div className="portlet light ">{children}</div>
    </Container>
  )
}
