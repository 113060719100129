import React from 'react'
import List from '../../pages/Commercial/Clients/List'
import Update from '../../pages/Commercial/Clients/Update'
import Create from '../../pages/Commercial/Clients/Create'
import View from '../../pages/Commercial/Clients/View'

import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/clients" exact component={List} />
    <Route path="/commercial/clients/update/:id" component={Update} exact />
    <Route path="/commercial/clients/create" component={Create} exact />
    <Route path="/commercial/clients/view/:id" component={View} exact />
  </CustomSwitch>
)
