import React, { useState, useEffect } from 'react'
import { Container, TabItem, TabsContainer } from './style'
import { TooltipComponent } from '../../../../../../../../components/TooltipComponent'

import { SearchComponentNcm } from './SearchComponent/SearchComponentNcm'
import { SearchComponentCfop } from './SearchComponent/SearchComponentCfop'
import { Select } from '../../../../../../../../components/Form'
import api from '../../../../../../../../services/api'
import { useProduct } from '../../../providers/product/ProductProvider'

export const labelFiscal = 'Fiscal'
export const nameFiscal = '@@tabs-fiscal'

type TaxSituations = {
  id: number
  descriptions: string
  reference: string
  code: string
}
type TaxOrigem = {
  id: number
  name: string
}

export const FiscalTab = (): JSX.Element => {
  const { fiscal, setFiscal } = useProduct()
  const [taxSituations, setTaxSituations] = useState<TaxSituations[]>([])
  const [taxIcms, setTaxIcms] = useState<TaxSituations[]>([])
  const [taxOrigem, setTaxOrigem] = useState<TaxOrigem[]>([])
  const [tabs, setTabs] = useState<{ active: boolean }[]>([
    { active: true },
    { active: false },
    { active: false },
    { active: false }
  ])

  const ncms = SearchComponentNcm()
  const cfop = SearchComponentCfop()

  const activeTab = (tabIndex: number) => {
    setTabs(prev => {
      const newTabs = prev.map((i, index) => ({ active: index === tabIndex }))
      return newTabs
    })
  }
  useEffect(() => {
    ;(async () => {
      const situations = await api.get<any>('/taxes/taxSituation')
      setTaxSituations(situations.data.situations)
      setTaxIcms(situations.data.icms)
      const origem = await api.get<TaxOrigem[]>('/taxes/taxNatureOperation')
      setTaxOrigem(origem.data)
    })()
  }, [])

  return (
    <>
      <h4 style={{ marginBottom: '20px', marginTop: '25px' }}>
        CLASSIFICAÇÃO DE VENDA DO PRODUTO
      </h4>
      <Container active={true} className="row">
        <div className="form-content col-md-4">
          <TooltipComponent
            label="NCM"
            message="Ela também pode ser chamada de Nomenclatura Comum do Mercosul (NCM). Trata-se de uma codificação de oito dígitos definida pelos governos dos países do Mercosul, para identificar a natureza das mercadorias. Posterior a isso o governo deve analisar a NCM para fins de se verificar quais tributos e regimes tributários podem advir daquela classificação fiscal."
          />
          {ncms}
        </div>
        {cfop}
      </Container>
      <TabsContainer>
        <TabItem active={tabs[0].active} onClick={() => activeTab(0)}>
          ICMS
        </TabItem>
        <TabItem active={tabs[1].active} onClick={() => activeTab(1)}>
          IPI
        </TabItem>
        <TabItem active={tabs[2].active} onClick={() => activeTab(2)}>
          PIS
        </TabItem>
        <TabItem active={tabs[3].active} onClick={() => activeTab(3)}>
          COFINS
        </TabItem>
      </TabsContainer>
      <Container active={tabs[0].active} className="row">
        <div className="form-content col-md-6">
          <TooltipComponent
            label="Situação Tributária"
            message="Código de Situação Tributária, ou apenas CST, é um classificador que determina a incidência do ICMS sobre um produto ou serviço. Ele serve para orientar os contribuintes no processo de comercialização de mercadorias e auxiliar as entidades federativas no processo de fiscalização tributária."
          />
          <Select
            name="fiscal.icms_tax_situation"
            className="form-control"
            options={[
              { name: 'NÃO USAR', value: '0' },
              ...taxIcms
                .filter(f => f.reference === 'icms')
                .map(({ id, descriptions, code }) => ({
                  value: id,
                  name: `${code} - ${descriptions}`
                }))
            ]}
            value={
              !taxIcms
                .filter(f => f.reference === 'icms')
                .map(m => String(m.id))
                .includes(String(fiscal.icms_tax_situation))
                ? '0'
                : fiscal.icms_tax_situation
            }
            onChange={e => {
              setFiscal((prev: any) => {
                const copy = { ...prev }
                copy.icms_tax_situation = Number(e.target.value)
                return copy
              })
            }}
            controlled
            blank
          />
        </div>
        <div className="form-content col-md-6">
          <TooltipComponent
            label="Origem"
            message="Código de Situação Tributária, ou apenas CST, é um classificador que determina a incidência do ICMS sobre um produto ou serviço. Ele serve para orientar os contribuintes no processo de comercialização de mercadorias e auxiliar as entidades federativas no processo de fiscalização tributária."
          />
          <Select
            name="fiscal.icms_tax_origem"
            className="form-control"
            options={[
              { name: 'NÃO USAR', value: '0' },
              ...taxOrigem.map(({ id, name }) => ({
                value: id,
                name: name
              }))
            ]}
            value={
              !taxOrigem
                .map(m => String(m.id))
                .includes(String(fiscal.icms_tax_origem))
                ? '0'
                : fiscal.icms_tax_origem
            }
            onChange={e => {
              setFiscal((prev: any) => {
                const copy = { ...prev }
                copy.icms_tax_origem = Number(e.target.value)
                return copy
              })
            }}
            controlled
            blank
          />
        </div>
      </Container>
      <Container active={tabs[1].active} className="row">
        <div className="form-content col-md-6">
          <TooltipComponent
            label="Situação Tributária"
            message="Código da situação tributária referente ao impostos industrializados (CST-IPI)"
          />
          <Select
            name="fiscal.ipi_tax_situation"
            className="form-control"
            options={[
              { name: 'NÃO USAR', value: '0' },
              ...taxSituations
                .filter(f => f.reference === 'ipi')
                .map(({ id, descriptions, code }) => ({
                  value: id,
                  name: `${code} - ${descriptions}`
                }))
            ]}
            value={
              !taxSituations
                .filter(f => f.reference === 'ipi')
                .map(m => String(m.id))
                .includes(String(fiscal.ipi_tax_situation))
                ? '0'
                : fiscal.ipi_tax_situation
            }
            onChange={e => {
              setFiscal((prev: any) => {
                const copy = { ...prev }
                copy.ipi_tax_situation = Number(e.target.value)
                return copy
              })
            }}
            controlled
            blank
          />
        </div>
      </Container>
      <Container active={tabs[2].active} className="row">
        <div className="form-content col-md-6">
          <TooltipComponent
            label="Situação Tributária"
            message="PIS, é designado à promoção da integração social dos trabalhadores: seus recursos são para pagamento do seguro-desemprego, abono salarial e participação na receita dos órgãos e entidades para os trabalhadores públicos e de empresas privadas.  Deve ser recolhidos sempre que uma organização obtém receitas durante o mês."
          />
          <Select
            name="fiscal.pis_tax_situation"
            className="form-control"
            options={[
              { name: 'NÃO USAR', value: '0' },
              ...taxSituations
                .filter(f => f.reference === 'pis')
                .map(({ id, descriptions, code }) => ({
                  value: id,
                  name: `${code} - ${descriptions}`
                }))
            ]}
            value={
              !taxSituations
                .filter(f => f.reference === 'pis')
                .map(m => String(m.id))
                .includes(String(fiscal.pis_tax_situation))
                ? '0'
                : fiscal.pis_tax_situation
            }
            onChange={e => {
              setFiscal((prev: any) => {
                const copy = { ...prev }
                copy.pis_tax_situation = Number(e.target.value)
                return copy
              })
            }}
            controlled
            blank
          />
        </div>
      </Container>
      <Container active={tabs[3].active} className="row">
        <div className="form-content col-md-6">
          <TooltipComponent
            label="Situação Tributária"
            message="O Cofins é utilizado para o recolhimento de fundos principalmente para a área da saúde pública e seguridade social do país, incluindo dispositivos como Previdência Social e  Assistência Social."
          />
          <Select
            name="fiscal.cofins_tax_situation"
            className="form-control"
            options={[
              { name: 'NÃO USAR', value: '0' },
              ...taxSituations
                .filter(f => f.reference === 'cofins')
                .map(({ id, descriptions, code }) => ({
                  value: id,
                  name: `${code} - ${descriptions}`
                }))
            ]}
            value={
              !taxSituations
                .filter(f => f.reference === 'cofins')
                .map(m => String(m.id))
                .includes(String(fiscal.cofins_tax_situation))
                ? '0'
                : fiscal.cofins_tax_situation
            }
            onChange={e => {
              setFiscal((prev: any) => {
                const copy = { ...prev }
                copy.cofins_tax_situation = Number(e.target.value)
                return copy
              })
            }}
            controlled
            blank
          />
        </div>
      </Container>
    </>
  )
}
