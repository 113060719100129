import Tooltip from '../../../../components/Tooltip'
import styled from 'styled-components'

export const ContainerWrapper = styled.div`
  @media (min-width: 991px) {
    margin: 0 -40px;
  }
  .disabled {
    input:not(input[type='checkbox']) {
      display: none !important;
    }
    select {
      display: none !important;
    }
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
`
export const ControlPanel = styled.div`
  h4 {
    margin-bottom: 0;
  }
`

export const Container = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  display: flex;
  align-items: center;

  > label {
    font-weight: normal;
  }

  & > div {
    position: relative;
  }

  input {
    /* flex: 1; */
    background: transparent;
    border: 1px solid #c2cad8;
    padding-left: 10px;
    /* margin: 0 auto; */
    padding: 5px 10px;
    background: ${props => props.erro && '#FFCCCC'};
    border: ${props => props.erro && '1px solid #ff3366'};
    &::placeholder {
      color: #666360;
    }
    &:disabled {
      color: rgba(170, 170, 170, 1) !important;
    }
  }

  svg {
    margin-right: 16px;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  width: fit-content;
  position: absolute;
  right: 25px;
  margin: 0;
  /* top: 50%;
  transform: translateY(-50%); */
  svg {
    margin: 0;
  }

  span {
    width: max-content;
    background: #c53030;
    color: #fff;
    right: -10px;

    &::before {
      right: 5px;
      transform: translateX(-80%);
      border-color: #c53030 transparent;
    }
  }
`

export const LinkContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #666;

  div {
    display: flex !important;
    align-items: center;
  }

  .link {
    margin-left: 5px;
    cursor: pointer;
  }
`

export const ListTitle = styled.div`
  p {
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 10px;
    color: #666;
  }

  hr {
    margin-bottom: 50px;
  }
`
export const ContainerButton = styled.div`
  margin: 20px 0;
  button {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;

    background: #fff;
    border: solid 1px #ccc;
    font-size: 12px;
    transition: all 0.2s;

    &:hover {
      background: #f2f2f2;
    }
  }
`

export const SelectContainer = styled.div<{ erro?: boolean }>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
  }

  select {
    width: 100%;
    padding: 5px;
    border: 1px solid #c2cad8;
    border: ${props => props.erro && '1px solid #ff3366'};
    color: ${props => (props.erro ? '#555' : '#000')};
    background-color: ${props => props.erro && '#FFCCCC'};

    option:disabled {
      display: none;
    }
    &:disabled {
      color: rgba(170, 170, 170, 1) !important;
    }
  }
`
