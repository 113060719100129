import React, { useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { situations } from '../../domain/info'

interface TypesFormProps {
  setModal: any
  loadWarehouseRequest: any
  situation: string
  received: boolean
  id: number
}

export const FormSituation = ({
  setModal,
  loadWarehouseRequest,
  situation,
  received,
  id
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [newSituation, setNewSituation] = useState('')

  const onSubmitForm = async (data: any) => {
    activeLoading()
    let url = 'warehouse/warehouseRequests/situation'
    if (received) {
      url = 'warehouse/warehouseRequests/received'
    }
    try {
      await api.post(url, { id, ...data })
      addToast({
        type: 'success',
        title: 'Registro atualizado',
        description: 'Registro alterado com sucesso'
      })
      loadWarehouseRequest()
      setModal(false)
    } catch (error: any) {
      let description =
        'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
      if (error?.response?.data?.message === 'Token Incorreto!') {
        description = 'Token informado está incorreto!'
      } else if (error?.response?.data?.message?.includes('Senha Incorreta')) {
        description = error?.response?.data?.message
      }
      console.log(error?.response?.data?.message)
      addToast({
        type: 'error',
        title: 'Erro ao atualizar o registro',
        description
      })
    }
    disableLoading()
  }

  if (received) {
    return (
      <Form onSubmit={onSubmitForm}>
        <div style={{ width: '500px' }}>
          <div style={{ display: 'none' }}>
            <Input
              name="situation"
              className="form-control"
              label="Situação"
              value={'delivered'}
              controlled
            />
          </div>
          <div className="col-md-10">
            <Input
              name="password"
              type="password"
              className="form-control"
              label="Senha"
              rules={{ required: true }}
            />
          </div>
          <div className="form-actions right">
            <Button type="submit" className="btn dark btn-sm sbold uppercase">
              Salvar
            </Button>
          </div>
        </div>
      </Form>
    )
  }

  let situationsFiltered: any[] = []
  if (situation === 'new') {
    situationsFiltered = situations.filter(
      s => s.value === 'canceled' || s.value === 'separate'
    )
  } else if (situation === 'separate') {
    situationsFiltered = situations.filter(
      s => s.value === 'canceled' || s.value === 'delivered'
    )
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div style={{ width: '800px' }}>
          <div className="row" style={{ marginTop: '0px' }}>
            <div className="col-md-6">
              <Select
                name="situation"
                className="form-control"
                label="Situação"
                options={situationsFiltered}
                rules={{ required: true }}
                onChange={e => setNewSituation(e.target.value)}
                blank
                defaultValue={''}
              />
            </div>
            {newSituation === 'delivered' && (
              <div className="col-md-4">
                <Input
                  name="token"
                  className="form-control"
                  label="Token"
                  rules={{ required: true }}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="form-content col-md-12">
              <div className="form-group">
                <Textarea
                  label="Observação"
                  className="form-control"
                  rules={{ required: true }}
                  name="description"
                  rows={6}
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="form-actions right">
        <Button type="submit" className="btn dark btn-sm sbold uppercase">
          Salvar
        </Button>
      </div>
    </Form>
  )
}
