import React from 'react'
import GoalAnalysis from '../../pages/Commercial/GoalAnalysis'
import PrivateRoutes from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const CommercialRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/commercial/goalAnalysis"
      component={GoalAnalysis}
      exact
    />
  </CustomSwitch>
)
