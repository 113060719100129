import React from 'react'
import { Input } from '../../../../../../../../../../components/Form'
import { TypeProductStock } from '../../../../../../domain/models/typeProduct'
import { SearchContainer } from './styles'

type ProductListType = {
  id: number
  name: string
}
type SearchComponentParams = {
  index: number
  stockIndex: number
  stock: TypeProductStock[]
  setStock: React.Dispatch<React.SetStateAction<TypeProductStock[]>>
  productList: ProductListType[]
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  usedProducts: number[]
}

export function SearchComponent({
  index,
  stockIndex,
  productList,
  stock,
  setStock,
  selected,
  setSelected,
  usedProducts
}: SearchComponentParams) {
  const handleBlur = () => {
    setTimeout(() => {
      !stock[stockIndex].composition[index].product_id &&
        setStock(prev => {
          const copy = [...prev]
          copy[stockIndex].composition[index].name = ''
          return copy
        })
    }, 500)
  }
  return (
    <SearchContainer onBlur={handleBlur}>
      <div>
        <Input
          className="form-control"
          name={`stock.${stockIndex}.composition.${index}.product_name`}
          id="option"
          onChange={({ target }) => {
            setStock(prev => {
              const copy = [...prev]
              copy[stockIndex].composition[index].product_name = target.value
              copy[stockIndex].composition[index].product_id = 0
              return copy
            })
            setSelected(false)
          }}
          value={stock[stockIndex].composition[index].product_name}
          fullControlled
        />
        <Input
          name={`stock.${stockIndex}.composition.${index}.product_id`}
          value={stock[stockIndex].composition[index].product_id}
          type="hidden"
          controlled
        />
        <ul>
          {stock[stockIndex].composition[index].product_name !== '' &&
            !selected &&
            productList
              .filter(
                f =>
                  f.name
                    ?.toLowerCase()
                    .includes(
                      stock[stockIndex].composition[index].product_name
                    ) && !usedProducts.includes(f.id)
              )
              .map(item => (
                <li
                  key={item.id}
                  onClick={() => {
                    setStock(prev => {
                      const copy = [...prev]
                      copy[stockIndex].composition[index].product_name =
                        item.name
                      copy[stockIndex].composition[index].product_id = item.id
                      return copy
                    })
                    setSelected(true)
                  }}
                >
                  {item.name}
                </li>
              ))}
        </ul>
      </div>
    </SearchContainer>
  )
}
