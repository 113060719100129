import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { Alert } from '../../../../components/Alert'

interface WarehouseDetailsData {
  name: string
  value: string
  price: string
}

interface WarehouseDataRequest {
  id: number
  details: string
  name: string
  active: boolean
  created_at: string
  updated_at: string
}
interface WarehouseData {
  id: number
  details: WarehouseDetailsData
  name: string
  active: string
  created_at: string
  updated_at: string
}

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const history = useHistory()
  const [warehouse, setWarehouse] = useState<WarehouseData | null>(null)
  const { addToast } = useToast()

  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadWarehouse(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<WarehouseDataRequest>(apiList(id))
        const { data } = response
        setWarehouse({
          ...data,
          active: data.active ? 'Sim' : 'Não',
          details: JSON.parse(data.details)
        })
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o armazém',
          description:
            'Houve um error ao carregar o armazém, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadWarehouse()
  }, [id, addToast, activeLoading, disableLoading, location.pathname, history])
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Armazém removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Armazém não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Armazém removido com sucesso.'
      })
      history.goBack()
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Armazém não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={'Visualizar'}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(id),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Nome
                </label>
                <p>{warehouse?.name}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="DetailsName" className="control-label">
                  Teste Detalhe Nome
                </label>
                <p>{warehouse?.details.name}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Detailsvalue" className="control-label">
                  Teste Detalhe Valor
                </label>
                <p>{warehouse?.details.value}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Detailsprice" className="control-label">
                  Teste Detalhe Preço
                </label>
                <p>{warehouse?.details.price}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="active" className="control-label">
                  Ativo
                </label>
                <p>{warehouse?.active}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Cadastrado em
                </label>
                <p>{warehouse?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Atualizado em
                </label>
                <p>{warehouse?.updated_at}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o armazém ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o armazém ${warehouse?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(warehouse?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}
export default View
