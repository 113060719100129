import styled from 'styled-components'

export const ContainerInput = styled.div`
  position: relative;
  .form-control {
    -moz-placeholder {
      text-align: right;
    }
    ::-webkit-input-placeholder {
      text-align: right;
    }
    text-align: end;
  }
`
