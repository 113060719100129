import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Warehouse/WarehouseRequests/List'
import Update from '../../pages/Warehouse/WarehouseRequests/Update'
import Create from '../../pages/Warehouse/WarehouseRequests/Create'
import View from '../../pages/Warehouse/WarehouseRequests/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const WarehouseRequestsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/warehouse/warehouseRequests" exact component={List} />
    <PrivateRoutes
      path="/warehouse/warehouseRequests/update/:id"
      exact
      component={Update}
    />
    <PrivateRoutes
      path="/warehouse/warehouseRequests/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/warehouse/warehouseRequests/view/:id"
      exact
      component={View}
    />
  </CustomSwitch>
)
