import React from 'react'
import Container from '../../../../components/Container'
import { nameActions, namePageTitle } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsViewList } from '../domain/tools'
import { FormCategory } from '../components/Form'

const Create: React.FC = () => {
  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.create.name}
        breadcrumb={breadcrumbCreate}
        tools={[toolsViewList()]}
      >
        <div className="form body">
          <FormCategory typeForm="create" />
        </div>
      </Container>
    </>
  )
}
export default Create
