export const headers = [
  { name: 'Período', field: 'year', sortable: true, custom: true },
  {
    name: 'Nº Funcionários',
    field: 'total_employees',
    sortable: true
  },
  {
    name: 'Salários dos Funcionários',
    field: 'total_salary',
    sortable: true,
    custom: true
  },
  {
    name: 'Proventos Adicionais',
    field: 'total_adicional_earnings',
    sortable: true,
    custom: true
  },
  {
    name: 'Descontos',
    field: 'total_discounts',
    sortable: true,
    custom: true
  },
  {
    name: 'Total Líquido',
    field: 'total',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersCreate = [
  { name: 'Área', field: 'company_id', sortable: true, custom: true },
  { name: 'Cargo', field: 'category_id', sortable: true },
  { name: 'Funcionário', field: 'values_transfers', sortable: true },
  { name: 'Salário', field: 'value_titles', sortable: true },
  { name: 'Outros proventos', field: 'value_taxes', sortable: true },
  { name: 'Desconto', field: 'total_value', sortable: true },
  { name: 'Total', field: 'total_value', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersView = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Categoria', field: 'category_name', sortable: true },
  { name: 'Fornecedor', field: 'supplier_name', sortable: true },
  { name: 'Valor', field: 'value', sortable: true },
  { name: 'Vencimento', field: 'due_date', sortable: true },
  { name: 'Forma', field: 'payment_form', sortable: true }
]
