import React from 'react'
import List from '../../../pages/Warehouse/product/ProductAttributes/List'
import Update from '../../../pages/Warehouse/product/ProductAttributes/Update'
import Create from '../../../pages/Warehouse/product/ProductAttributes/Create'
import View from '../../../pages/Warehouse/product/ProductAttributes/View'
import Route from '../../Route'
import { CustomSwitch } from '../../../components/CustomSwitch'

export const ProductAttributesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/warehouse/productAttributes" exact component={List} />
    <Route
      path="/warehouse/productAttributes/update/:id"
      component={Update}
      exact
    />
    <Route
      path="/warehouse/productAttributes/create"
      component={Create}
      exact
    />
    <Route
      path="/warehouse/productAttributes/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
