import React from 'react'
import { ControlPanel } from './style'
import { TablePortletWithInputs } from './components/TablePortletWithInputs'
import Container from '../../../components/Container'

import { toolsCreate } from './domain/tools'

const ExpenseBudget: React.FC = () => {
  return (
    <Container
      pageTitle={'Ranges de comissões'}
      breadcrumb={[
        {
          name: 'Início',
          to: '/'
        },
        {
          name: 'Ranges de comissões'
        },
        {
          name: 'Editar'
        }
      ]}
      tools={[toolsCreate]}
    >
      <ControlPanel>
        <TablePortletWithInputs
          className=""
          subtitle="Quadro de lançamento de percentual de comissão"
          realData={[]}
          isHighlight={true}
          isHighlightOver={true}
          lastChildStyle="boldGrey"
          stylesChild={{
            th: {
              classname: 'hasBottomBorder'
            },
            column4: {
              classname: ''
            }
          }}
          hasDropdown={true}
        />
      </ControlPanel>
    </Container>
  )
}

export default ExpenseBudget
