export const headers = [
  { name: 'Tipo', field: 'type', sortable: true, custom: true },
  { name: 'Nome', field: 'name', sortable: true },
  {
    name: 'Vigência',
    field: 'start_date',
    custom: true,
    sortable: true,
    type: 'date'
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
