import React from 'react'
import TaxNatureOperationList from '../../pages/Taxes/NatureOperation/List'
import TaxNatureOperationCreate from '../../pages/Taxes/NatureOperation/Create'
import TaxNatureOperationUpdate from '../../pages/Taxes/NatureOperation/Update'
import TaxNatureOperationView from '../../pages/Taxes/NatureOperation/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const TaxNatureOperationRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/taxes/taxNatureOperation"
      exact
      component={TaxNatureOperationList}
    />
    <Route
      path="/taxes/taxNatureOperation/create"
      component={TaxNatureOperationCreate}
      exact
    />
    <Route
      path="/taxes/taxNatureOperation/view/:id"
      component={TaxNatureOperationView}
      exact
    />
    <Route
      path="/taxes/taxNatureOperation/update/:id"
      component={TaxNatureOperationUpdate}
      exact
    />
  </CustomSwitch>
)
