import React from 'react'
import PrivateRoutes from '../Route'
import FinancialIncome from '../../pages/Financial/FinanceIncome'
import { CustomSwitch } from '../../components/CustomSwitch'

export const IncomeAnalysesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/financial/income" component={FinancialIncome} exact />
  </CustomSwitch>
)
