import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'
import Button from '../../../../../components/Button'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { Alert } from '../../../../../components/Alert'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: OrderData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

interface OrderData {
  status: string
  id: number
  clientName: string
  freight_value: string
  total_value: string
  important: string
  delivery_date: string
  pick_up_date: string
  history: string
  location_extended?: number
  created_at: string
  updated_at: string
}

export const FormOrder = ({
  isOpenInModal,
  typeForm,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<OrderData>()
  const [pickUpDate, setPickUpDate] = useState<Date>()
  const [formData, setFormData] = useState<any>()
  const { activeLoading, disableLoading } = useLoading()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
    title: string
  }>({
    id: 0,
    isActive: false,
    name: '',
    title: ''
  })
  const [id, setId] = useState<number>()
  const onSubmitForm = async (data: {
    pick_up_date: Date | string
    pick_up_time: Date | string
    location_extended?: number
    hasCancel?: boolean
    hasUpdate?: boolean
  }) => {
    setFormData({
      ...data,
      hasUpdate: true
    })
    setIsActiveAlert({
      ...alert,
      name: '',
      isActive: true,
      title: 'Cancelamento de Locação'
    })
    if (!data.hasUpdate) return
    data.hasCancel = true
    if (typeof pickUpDate === 'object') {
      data.pick_up_date = pickUpDate.toISOString().slice(0, 10)
    }
    data.location_extended = 2
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar a Pedido',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar a Pedido',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Pedido atualizado',
              description: 'Pedido alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o pedido',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Pedido atualizado',
              description: 'Pedido alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o pedido',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  useEffect(() => {
    if (initialValues?.id) {
      setId(initialValues.idUpdate)
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      title: '',
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async () => {
    setIsActiveAlert({
      title: '',
      id: 0,
      isActive: false,
      name: ''
    })
    await onSubmitForm(formData)
  }

  return (
    <>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <>
          <div className="form-body">
            <div className="row" style={{ marginTop: '-30px' }}>
              <div className="col-md-12">
                <label htmlFor="order" className="control-label">
                  Pedido
                </label>
                <p>
                  {initialValues?.id} - {initialValues?.clientName}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <DatePicker
                  label="Data Retirada"
                  className="form-control"
                  rules={{ required: true }}
                  name="pick_up_date"
                  selected={pickUpDate}
                  onChange={event => setPickUpDate(event)}
                  controlled
                />
              </div>

              <div className="col-md-6">
                <Input
                  label="Hora Retirada"
                  className="form-control"
                  rules={{ required: true }}
                  name="pick_up_time"
                />
              </div>
            </div>
          </div>
          {isOpenInModal && typeForm === 'create' ? (
            <hr className="divider" />
          ) : (
            <div style={{ margin: '10px 0' }} />
          )}
          <div className="form-actions right">
            {isOpenInModal && (
              <button
                onClick={isOpenInModal.handleOnClose}
                type="reset"
                className="btn btn-default btn-sm sbold uppercase"
              >
                Fechar
              </button>
            )}
            <Button type="submit" className="btn dark btn-sm sbold uppercase">
              Salvar
            </Button>
          </div>
        </>
      </Form>
      <Alert
        message={'Deseja realmente cancelar a locação ?'}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert()
        }}
        isActive={alert.isActive}
      />
    </>
  )
}
