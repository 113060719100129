import React from 'react'
import List from '../../pages/Commercial/ClientsEconomicGroups/List'
import Update from '../../pages/Commercial/ClientsEconomicGroups/Update'
import Create from '../../pages/Commercial/ClientsEconomicGroups/Create'
import View from '../../pages/Commercial/ClientsEconomicGroups/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsEconomicGroupsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/clientsEconomicGroups" exact component={List} />
    <Route
      path="/commercial/clientsEconomicGroups/update/:id"
      component={Update}
      exact
    />
    <Route
      path="/commercial/clientsEconomicGroups/create"
      component={Create}
      exact
    />
    <Route
      path="/commercial/clientsEconomicGroups/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
