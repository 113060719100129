import styled, { css, keyframes } from 'styled-components'

const RotateDownToUp = keyframes`
  from {
    transform: rotateZ(0)
  } to {
    transform: rotateZ(-180deg)
  }
`
const RotateUpToDown = keyframes`
  from {
    transform: rotateZ(-180deg)
  } to {
    transform: rotateZ(0)
  }
`
export const Container = styled.div`
  will-change: auto;
  table {
    border-collapse: collapse;
    table-layout: auto;
    th {
      width: 176px;
    }
  }
  .table thead tr th.actions,
  .table thead tr td.actions,
  .table tbody tr td:last-child {
    text-align: left;
  }
  .table > thead > tr > th {
    border: 0;
  }
  .table > thead > tr > th.hasBottomBorder {
    border-bottom: 1px solid #f2f5f8;
  }
  .table > tbody > tr > td {
    color: #a0a0a0;
  }
  .bold {
    color: rgb(224, 142, 32);
  }
  .boldGrey {
    color: #a0a0a0;
    font-weight: 700;
  }
  .LastChildSpace {
    /* display: flex;
    justify-content: space-between; */
    display: flex;
    align-items: center;
    svg {
      margin-left: 22px;
    }
  }
  tr {
    cursor: pointer;
  }
  tr.activeCustom {
    > td {
      background: #ebebeb;
      color: #333;
      height: 84px;
    }
  }
  span.maintitle {
    color: #697882;
    font-family: 'Font', sans-serif;
    font-weight: 400;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 20px 0px 20px 20px;
    vertical-align: middle;
  }

  .table > thead.TableHead > tr > th {
    height: 60px;
    padding: 5px;
    vertical-align: middle;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #a0a0a0;
    width: 100%;
    height: 100%;
    font-size: 13px;
  }
  .btn.btn-outline.active,
  .btn.btn-outline:active,
  .btn.btn-outline:active:focus,
  .btn.btn-outline:active:hover,
  .btn.btn-outline:focus,
  .btn.btn-outline:hover {
    background: #a0a0a0;
    color: #fff;
  }
  .table.table-light > thead > tr > th {
    color: #a0a0a0;
  }
  .table.table-light > tbody > tr.trChild {
    background: #f5f5f5;
    td {
      border-bottom: 0px;
    }
    div.firstChild {
      padding-left: 20px;
    }
  }
  .table.table-light > tbody > tr.trChildSubItems {
    background: #fbfbfb;
    td {
      border-bottom: 0px;
    }
    div.firstChild {
      padding-left: 20px;
    }
    div.firstSubChild {
      padding-left: 30px;
    }
  }
  .table.table-light > tbody > tr.activeCustom {
    td {
      border-bottom: 0px;
    }
  }
  div {
    border-radius: 14px !important;
  }

  .RotateUp {
    animation: ${RotateDownToUp} 0.5s ease-in-out;
    transform: Rotate(180deg);
  }
  .RotateDown {
    animation: ${RotateUpToDown} 0.5s ease-in-out;
  }
`
