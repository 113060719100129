import React from 'react'
import { Table } from './Table'

export const HasCompositionTab = (): JSX.Element => {
  const table = Table()

  return <div>{table}</div>
}

export const labelHasComposition = 'Composição'
export const nameHasComposition = '@@tabs-has-composition'
