export const headers = [
  { name: 'Nota Fiscal', field: 'nNF', sortable: true },
  { name: 'Data', field: 'dhEmi', sortable: true },
  { name: 'Razão social', field: 'xNome', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Valor da NF', field: 'vNF', sortable: true },
  {
    name: 'Requisição',
    field: 'purchase_request_id',
    sortable: false,
    custom: true
  },
  { name: 'Situação da NF', field: 'cSitNFe', sortable: true },
  { name: 'Destinação', field: 'destination', sortable: false, custom: true },
  { name: 'Verificada', field: 'verified', sortable: true, custom: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
