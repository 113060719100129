import React, { useEffect, useState } from 'react'
import { FaPaperclip } from 'react-icons/fa'
import avatarAnon from '../../../../../assets/layouts/layout3/img/avatar.png'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import { Avatar, MessageContainer } from './style'
import moment from 'moment'
import { DATE_MASK_LOCALE_WITH_TIME } from '../../../../../common/constants'
import { FILE_PATH_COMMENT_ATTACHMENTS } from '../../constants/pathToFile'

type TaskMessageProps = {
  avatarUrl?: string
  taskId?: number
  date?: string
  userName?: string
  message: string
  newMessage?: boolean
  attachments?: any[]
}

export const TaskMessage = ({
  avatarUrl,
  date,
  userName,
  message,
  newMessage,
  taskId,
  attachments
}: TaskMessageProps): JSX.Element => {
  const { disableLoading, activeLoading } = useLoading()
  const { addToast } = useToast()
  const [messageCopy, setMessageCopy] = useState(message)
  const [messageSeeMore, setMessageSeeMore] = useState('')
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    if (message.length >= 300) {
      setMessageCopy(message.slice(0, 300))
      setMessageSeeMore(message.slice(300))
      setShowMore(true)
    }
  }, [message])

  return (
    <MessageContainer>
      {userName && (
        <p
          style={{
            fontStyle: 'italic'
          }}
        >
          <Avatar src={avatarUrl || avatarAnon} />
          {userName}{' '}
          {moment(date, DATE_MASK_LOCALE_WITH_TIME)
            .add(3, 'h')
            .format(DATE_MASK_LOCALE_WITH_TIME)}
        </p>
      )}
      <p
        style={{
          padding: '5px 0px',
          fontWeight: newMessage ? 'bold' : 'inherit'
        }}
      >
        {messageCopy}
        {showMore && (
          <a style={{ color: 'blue' }} onClick={() => setShowMore(false)}>
            {' ...ler mais'}
          </a>
        )}
        <span>{!showMore && messageSeeMore}</span>
        {!showMore && messageSeeMore && (
          <a style={{ color: 'blue' }} onClick={() => setShowMore(true)}>
            {' ler menos'}
          </a>
        )}
      </p>
      {attachments?.map(att => (
        <a
          key={att.id}
          onClick={() => {
            activeLoading()
            const a = document.createElement('a')
            api
              .post(
                'personelDepartment/tasks/file',
                {
                  name: FILE_PATH_COMMENT_ATTACHMENTS({
                    filename: att.filename,
                    taskId
                  })
                },
                {
                  responseType: 'arraybuffer'
                }
              )
              .then(response => {
                const blob = new Blob([response.data], {
                  type: a.type.toLowerCase()
                })
                const url = window.URL.createObjectURL(blob)
                a.href = url
                a.download = att.filename.substring(
                  att.filename.indexOf('-') + 1
                )
                a.click()
                disableLoading()
              })
              .catch(() => {
                disableLoading()
                addToast({
                  type: 'error',
                  title: 'Arquivo não encontrado'
                })
              })
          }}
        >
          <FaPaperclip
            title={att.filename.substring(att.filename.indexOf('-') + 1)}
          />
        </a>
      ))}
    </MessageContainer>
  )
}
