import React from 'react'

type LabelTextProps = {
  label: string
  text?: string
  htmlText?: JSX.Element
  col?: number
}

const LabelText = ({ label, text, htmlText, col = 3 }: LabelTextProps) => {
  return (
    <div className={`col-md-${col}`}>
      <div className="form-group">
        <label htmlFor="name" className="control-label">
          {label}
        </label>
        {text && <p>{text}</p>}
        {htmlText}
      </div>
    </div>
  )
}

export default LabelText
