export const FILE_PATH_TASK_ATTACHMENTS = ({
  taskId,
  filename
}: {
  filename: string
  taskId: number | string
}): string[] => ['tasks', taskId.toString(), 'attachments', filename]
export const FILE_PATH_COMMENT_ATTACHMENTS = ({
  taskId,
  filename
}: {
  filename: string
  taskId: number | string
}): string[] => ['tasks', taskId.toString(), 'comments', filename]
