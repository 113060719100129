import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useLoading } from '../../../../../../../hooks/loading'
import { useTabs } from '../../../../../../../hooks/tabs'
import { useToast } from '../../../../../../../hooks/toast'
import api from '../../../../../../../services/api'
import { listById } from '../../../domain/data/api'
import { ProductResponse } from '../../../domain/models/productResponse'
import { TypeProductStock } from '../../../domain/models/typeProduct'

import {
  FinancialCategory,
  ProductCategory,
  ProductType,
  UnitMensured,
  Attributes,
  loadFinancialCategories,
  loadProductCategories,
  loadProductTypes,
  loadUnitMensured,
  loadAtributes
} from '../../../services/api'

type ProductContextType = {
  getProduct: (id: string) => any
  typesProduct: ProductType[]
  productType: string
  setProductType: React.Dispatch<React.SetStateAction<string>>
  stock: any[]
  setStock: React.Dispatch<React.SetStateAction<any[]>>
  hasVariation: string
  setHasVariation: React.Dispatch<React.SetStateAction<string>>
  groupsProduct: ProductCategory[]
  categoriesCost: FinancialCategory[]
  subCategoriesCost: FinancialCategory[]
  loadSubCategoriesCost: (parentId: string) => void
  unitMensured: UnitMensured[]
  attributes: Attributes[]
  fiscal: any
  setFiscal: any
  setAttributes: React.Dispatch<React.SetStateAction<Attributes[]>>
  saveProduct: (data: any, id?: string) => Promise<string>
}

const ProductContext = createContext<ProductContextType>(
  {} as ProductContextType
)

type ProductProviderParams = {
  children: JSX.Element
}

type ProductListFetch = {
  id: number
  name: string
  prices: string
  product?: {
    id: number
    name: string
  }
}
export const ProductProvider = ({ children }: ProductProviderParams) => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const [typesProduct, setTypesProduct] = useState<ProductType[]>([])
  const [productType, setProductType] = useState('')
  const [groupsProduct, setGroupsProduct] = useState<ProductCategory[]>([])
  const [categoriesCost, setCategoriesCost] = useState<FinancialCategory[]>([])
  const [subCategoriesCost, setSubCategoriesCost] = useState<
    FinancialCategory[]
  >([])
  const [unitMensured, setUnitMensured] = useState<UnitMensured[]>([])
  const [attributes, setAttributes] = useState<Attributes[]>([])
  const [hasVariation, setHasVariation] = useState<string>('YES')
  const [fiscal, setFiscal] = useState<any>({})
  const [stock, setStock] = useState<TypeProductStock[]>([
    {
      open: true,
      active: true,
      atributes: [{ key: 1, value: 2 }],
      composition: [
        {
          amount: 0,
          cost: 0,
          name: '',
          product_name: '',
          subtotal: 0,
          stock_id: '',
          product_id: 0
        }
      ]
    }
  ])

  const { activeLoading, disableLoading } = useLoading()
  const { activeTab, disableTab, changeTabLabel } = useTabs()

  useEffect(() => {
    setTimeout(async () => {
      const productsType = loadProductTypes()
      setTypesProduct(productsType)
      const groupsProduct = await loadProductCategories()
      setGroupsProduct(groupsProduct)
      const categoriesCost = await loadFinancialCategories()
      setCategoriesCost(categoriesCost)
      const mensureds = await loadUnitMensured()
      setUnitMensured(mensureds)
      const attributes = await loadAtributes()
      setAttributes(attributes)
      const stockAtributes = attributes.map(att => ({
        key: Number(att.id),
        value: 0
      }))
      setStock([
        {
          open: true,
          active: true,
          atributes: stockAtributes,
          composition: [
            {
              amount: 0,
              cost: 0,
              name: '',
              product_name: '',
              subtotal: 0,
              stock_id: '',
              product_id: 0
            }
          ]
        }
      ])
    }, 1000)
  }, [activeLoading, disableLoading])

  useEffect(() => {
    if (hasVariation === 'NO') {
      changeTabLabel('hasVariation', 'Estoque')
    } else {
      changeTabLabel('hasVariation', 'Estoque/Variação')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasVariation])

  useEffect(() => {
    if (!productType) return
    if (['materia-prima', 'locacao', 'consumo'].includes(productType)) {
      disableTab('priceComposition')
      disableTab('fiscal')
    } else if (productType === 'revenda') {
      activeTab('priceComposition')
      activeTab('fiscal')
    } else if (productType === 'projeto-especial') {
      activeTab('priceComposition')
      activeTab('fiscal')
    } else if (productType === 'semi-acabado') {
      disableTab('fiscal')
      disableTab('priceComposition')
    } else {
      activeTab('fiscal')
      activeTab('priceComposition')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType])

  const loadSubCategoriesCost = useCallback(
    (parentId: string) => {
      if (parentId) {
        const subcategoryCost = categoriesCost?.filter(
          ({ parent_id }) => parent_id?.toString() === parentId
        )
        setSubCategoriesCost(subcategoryCost)
      }
    },
    [categoriesCost]
  )

  const getProduct = useCallback(
    async (id: string) => {
      activeLoading()
      if (!attributes.length || !categoriesCost.length) return
      try {
        const { data } = await api.get<ProductResponse>(listById(id))
        const stocksListFetch = await api.get<ProductListFetch[]>(
          'warehouse/stocks'
        )
        const stocksList = stocksListFetch.data
        setProductType(data.type)
        loadSubCategoriesCost(String(data.financial_category.id))
        let hasVariation = 'YES'
        const stocksCopy = data.stocks.map(stock => {
          const currentAttributes = JSON.parse(
            String(stock.atributes).toLowerCase()
          )
          let newAttributes: any[] = []
          attributes.forEach((a, index) => {
            currentAttributes?.forEach((att: any) => {
              if (att) {
                if (Number(a.id) === Number(att.key) && Number(att.value)) {
                  newAttributes[index] = {
                    key: Number(att.key),
                    value: Number(att.value)
                  }
                  setAttributes(prev => {
                    prev[index].isChecked = true
                    return prev
                  })
                } else {
                  newAttributes[index] = newAttributes[index]
                    ? newAttributes[index]
                    : {}
                }
              }
            })
          })
          if (!currentAttributes || currentAttributes.length === 0) {
            hasVariation = 'NO'
            setHasVariation(hasVariation)
            const stockAtributes = attributes.map(att => ({
              key: Number(att.id),
              value: undefined
            }))
            newAttributes = stockAtributes
          }
          return {
            ...stock,
            open: false,
            active: false,
            details: stock.details ? JSON.parse(String(stock.details)) : {},
            atributes: [...newAttributes],
            edit: true,
            composition: stock.composition
              ? JSON.parse(String(stock.composition))
                  .filter((c: any) => c)
                  .map((c: any) => ({
                    ...c,
                    product_name: stocksList.find(
                      s => s.id === Number(c.stock_id)
                    )?.product?.name,
                    product_id: stocksList.find(
                      s => s.id === Number(c.stock_id)
                    )?.product?.id
                  }))
              : [
                  {
                    amount: 0,
                    cost: 0,
                    name: '',
                    subtotal: 0,
                    stock_id: '',
                    product_name: ''
                  }
                ]
          }
        })
        data.stocks && setStock(stocksCopy)
        data.fiscal ? setFiscal(JSON.parse(String(data.fiscal))) : setFiscal({})
        const value = {
          details_overview: {
            type: data.type,
            product_category_id: data.product_category_id,
            name: data.name,
            category_cost_id: data.financial_category.id,
            subcategory_cost_id: data.subfinancial_category.id,
            hasVariation
          },
          stock: stocksCopy,
          price_composition: data.price_composition
            ? JSON.parse(String(data.price_composition))
            : {},
          fiscal: data.fiscal ? JSON.parse(String(data.fiscal)) : {}
        }
        disableLoading()
        return value
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a categoria',
          description:
            'Houve um error ao carregar a categoria, tente novamente mais tarde!'
        })
        if (path.includes('view') || path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    },
    [
      activeLoading,
      addToast,
      attributes,
      categoriesCost.length,
      disableLoading,
      history,
      loadSubCategoriesCost,
      location.pathname
    ]
  )

  const saveProduct = useCallback(async (product: any, id?: string) => {
    try {
      if (id) {
        const { data } = await api.put(`warehouse/products/update/${id}`, {
          params: {
            ...product,
            stock:
              product.stock?.map((item: any) => ({
                ...item,
                accept_art: Number(item.accept_art)
              })) || []
          }
        })
        if (data.error) {
          return false
        }
        return data.id
      }
      const { data } = await api.post('warehouse/products/create', {
        params: {
          ...product,
          stock: product.stock || []
        }
      })
      if (data.error) {
        return false
      }
      return data.id
    } catch (error) {
      return false
    }
  }, [])

  return (
    <ProductContext.Provider
      value={{
        getProduct,
        typesProduct,
        productType,
        setProductType,
        stock,
        setStock,
        hasVariation,
        setHasVariation,
        groupsProduct,
        categoriesCost,
        subCategoriesCost,
        loadSubCategoriesCost,
        unitMensured,
        attributes,
        setAttributes,
        saveProduct,
        fiscal,
        setFiscal
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const useProduct = (): ProductContextType => {
  const context = useContext(ProductContext)
  if (!context) throw new Error('Context Product context not found')
  return context
}
