type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

const nameEntity = 'Stock'
const nameSource = 'stocks'
const namePageTitle = 'Estoques'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Estoques',
  to: '/stocks',
  icon: ''
}

export { nameEntity, nameSource, namePageTitle, nameActionPageMain }
