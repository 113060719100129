export const apiCreate = (): string =>
  '/commercial/freightLocalizationPercentages/create/'
export const apiList = (id?: string): string =>
  !id
    ? '/commercial/freightLocalizationPercentages/'
    : `/commercial/freightLocalizationPercentages/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/freightLocalizationPercentages/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/freightLocalizationPercentages/delete/${id}`
