type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

const nameEntity = 'InvoiceIssuedForCnpj'
const nameSource = 'financial/invoicesReceived'
const namePageTitle = 'Conciliação bancária'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Conciliação bancária',
  to: '/financial/bankReconciliation',
  icon: ''
}

export { nameEntity, nameSource, namePageTitle, nameActionPageMain }
