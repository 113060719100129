import React, { useEffect, useState, useCallback } from 'react'
import Form from '../../../../../../../components/Form'
import { useTabs } from '../../../../../../../hooks/tabs'
import { TabsModel } from '../../../domain/models/tabs'
import { OverviewTab } from './Overview'
import { StockTab } from './Stock'
import { FiscalTab } from './Fiscal'
import { PriceCompositionTab } from './PriceComposition'

import {
  Container,
  ContentItem,
  TabHeaderContainer,
  TabName,
  TabPanelContainer
} from './styles'
import { useProduct } from '../../providers/product/ProductProvider'
import { Footer } from '../footer'
import { useToast } from '../../../../../../../hooks/toast'
import { useHistory } from 'react-router'
import Modal from '../../../../../../../components/Modal'

type TypeContentProps = {
  tabList: TabsModel[]
  id?: string
}

export const Tab = ({ tabList, id }: TypeContentProps): JSX.Element => {
  const {
    addTab,
    changeCurrentTab,
    currentTab,
    tabs,
    changeCurrentTabForNext,
    changeCurrentTabForPrevious
  } = useTabs()

  const { productType, getProduct, saveProduct } = useProduct()
  const { addToast } = useToast()
  const history = useHistory()

  const overviewFields = OverviewTab({ id })
  const hasVariationFields = StockTab()
  const priceCompositionFields = PriceCompositionTab()
  const fiscalFields = FiscalTab()

  const [defaultValues, setDefaultValues] = useState<any>()
  const [errors, setErrors] = useState('')
  const [errorsModal, setErrorsModal] = useState(false)
  const [submited, setSubmited] = useState(0)

  const handleClickErrorsModal = () => {
    setErrorsModal(false)
  }

  useEffect(() => {
    tabList.forEach(({ name, isDefault, label, isEnable }) => {
      addTab({ default: isDefault, name, label, isEnable })
    })
  }, [tabList, addTab])

  useEffect(() => {
    ;(async () => {
      if (id) {
        const product = await getProduct(id)
        setDefaultValues(product)
      }
    })()
  }, [id, getProduct])

  const onClickNameTab = (name: string) => changeCurrentTab(name)

  async function handleSubmit(data: any) {
    console.log(data, 'data')
    const productId = await saveProduct(data, id)
    if (productId) {
      addToast({
        type: 'success',
        title: 'Produto adicionado',
        description: 'Produto adicionado com sucesso!.'
      })
      history.push(`/warehouse/products/view/${productId}`)
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o produto',
        description:
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
    }
  }

  const handleErrors = useCallback(
    (data: any) => {
      const errorsList = Object.keys(data)
      if (errorsList.length !== 0) {
        let errorTabList = ''
        errorsList.forEach(e => {
          const tabs = [
            { key: 'details_overview', tab: 'Dados' },
            { key: 'stock', tab: 'Estoque' },
            { key: 'fiscal', tab: 'Fiscal' },
            { key: 'price_composition', tab: 'Formação de preço' },
            { key: 'composition', tab: 'Composição' }
          ]
          const tab = tabs.find(t => t.key === e)
          errorTabList += `${tab?.tab}+`
        })
        setErrors(errorTabList)
        if (submited === 1) {
          setErrorsModal(true)
          setSubmited(submited + 1)
        }
      } else {
        setErrors('')
      }
    },
    [submited]
  )

  const handleSaveButton = () => {
    setSubmited(submited + 1)
    errors.length && setErrorsModal(true)
  }

  return (
    <Container>
      <ContentItem>
        <TabHeaderContainer>
          {tabs.map(
            ({ label, isEnable, name }) =>
              isEnable && (
                <TabName
                  onClick={() => onClickNameTab(name)}
                  key={name}
                  isActive={currentTab.key === name}
                >
                  {label}
                </TabName>
              )
          )}
        </TabHeaderContainer>
        <TabPanelContainer>
          <Form
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            hasErrors={handleErrors}
          >
            <div className={`${currentTab.key !== 'overview' && 'hidden'}`}>
              {overviewFields}
            </div>
            <div className={`${currentTab.key !== 'hasVariation' && 'hidden'}`}>
              {hasVariationFields}
            </div>
            {['revenda', 'venda', 'projeto-especial'].includes(productType) && (
              <>
                <div
                  className={`${
                    currentTab.key !== 'priceComposition' && 'hidden'
                  }`}
                >
                  {priceCompositionFields}
                </div>
                <div className={`${currentTab.key !== 'fiscal' && 'hidden'}`}>
                  {fiscalFields}
                </div>
              </>
            )}
            <div className="form-actions right">
              <Footer
                onClickButtonNext={changeCurrentTabForNext}
                onClickButtonBack={changeCurrentTabForPrevious}
                onClickButtonSave={handleSaveButton}
              />
            </div>
          </Form>
        </TabPanelContainer>
      </ContentItem>
      <Modal
        isOpenModal={errorsModal}
        Children={
          <div>
            <h4>Os campos destacados na(s) aba(s):</h4>
            {errors.split('+').map(e => (
              <h4 key={e} style={{ fontWeight: 'bold' }}>
                {e && `- ${e}`}
              </h4>
            ))}
            <h4>são de preenchimento obrigatório</h4>
          </div>
        }
        pageTitle={'Atenção'}
        onClickButtonCancel={handleClickErrorsModal}
      />
    </Container>
  )
}
