import React, { createContext, useContext, useCallback, useState } from 'react'

import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import moment from 'moment'
import { useAuth } from '../../../../../hooks/auth'

type Contact = {
  open: boolean
  show: boolean
  name: string
  id?: number
  email?: string
  phone_number?: string
  cellphone_number1?: string
  deleted_at?: string
}

type ClientContextType = {
  getClient: (id: string) => any
  saveClient: (data: any, id?: string) => Promise<string>
  getOptions: () => Promise<void>
  setSelectedTypes: React.Dispatch<React.SetStateAction<Record<number, any>>>
  selectedTypesEmail: Record<number, any>
  initialValues: any
  agentsOptions: { name: string; value: string }[]
  carriersOptions: { name: string; value: string }[]
  originsOptions: { name: string; value: string }[]
  segmentsOptions: { name: string; value: string }[]
  subSegmentsOptions: { name: string; value: string; parent_id?: number }[]
  allSegmentsOptions: { name: string; value: string }[]
  economicGroupsOptions: { name: string; value: string }[]
  clientsTypesOptions: { name: string; value: string }[]
  clientQueryData: any
  setClientQueryData: React.Dispatch<any>
  contacts: Contact[]
  setContacts: React.Dispatch<React.SetStateAction<Contact[]>>
}

const ClientContext = createContext<ClientContextType>({} as ClientContextType)

type ClientProviderParams = {
  children: JSX.Element
}

export const ClientProvider = ({ children }: ClientProviderParams) => {
  const { activeLoading, disableLoading } = useLoading()
  const [initialValues, setInitialValues] = useState<any>()
  const [agentsOptions, setAgentsOptions] = useState([])
  const [carriersOptions, setCarriersOptions] = useState([])
  const [originsOptions, setOriginsOptions] = useState([])
  const [segmentsOptions, setSegmentsOptions] = useState([])
  const [subSegmentsOptions, setSubSegmentsOptions] = useState([])
  const [allSegmentsOptions, setAllSegmentsOptions] = useState([])
  const [economicGroupsOptions, setEconomicGroupsOptions] = useState([])
  const [clientsTypesOptions, setClientsTypesOptions] = useState([])
  const [clientQueryData, setClientQueryData] = useState<any>()
  const [contacts, setContacts] = useState<any[]>([
    {
      open: true,
      show: true,
      name: ''
    }
  ])
  const [selectedTypesEmail, setSelectedTypes] = useState<Record<number, any>>(
    {}
  )

  const { segments } = useAuth()

  const getOptions = useCallback(async () => {
    const { data } = await api.get('commercial/clients/selectOptions')
    setAgentsOptions(
      data.agents?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setCarriersOptions(
      data.carriers?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setOriginsOptions(
      data.origins?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setAllSegmentsOptions(
      data.segments?.map((type: any) => ({
        value: type.id,
        parent_id: type.parent_id,
        name: type.name
      }))
    )
    setSegmentsOptions(
      data.segments
        ?.filter((segment: any) => {
          if (segments.length === 0) {
            return true
          }
          return segments?.includes(String(segment.id))
        })
        .filter((segment: any) => !segment.parent_id)
        ?.map((type: any) => ({
          value: type.id,
          parent_id: type.parent_id,
          name: type.name
        }))
    )
    setSubSegmentsOptions(
      data.segments
        .filter((segment: any) => !!segment.parent_id)
        ?.map((type: any) => ({
          value: type.id,
          parent_id: type.parent_id,
          name: type.name
        }))
    )
    setEconomicGroupsOptions(
      data.clientEconomicGroups?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setClientsTypesOptions(
      data.clientsTypes?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
  }, [segments])

  const getClient = useCallback(
    async (id: string) => {
      activeLoading()
      await getOptions()
      const { data } = await api.get(`commercial/clients/view/${id}`)
      const initialValues = {
        client: {
          ...data,
          adicional_informations_portals: JSON.parse(
            data.adicional_informations_portals
          )
        },
        person: {
          ...data.person,
          state_registration_free: data.person.state_registration_free
            ? 'true'
            : 'false',
          city_registration_free: data.person.city_registration_free
            ? 'true'
            : 'false',
          suframa_registration_free: data.person.suframa_registration_free
            ? 'true'
            : 'false',
          date_opening_birth: data.person.date_opening_birth
            ? moment(data.person.date_opening_birth, 'DD/MM/YYYY HH:mm:ss')
                .add(3, 'h')
                .toDate()
            : undefined
        },
        payment: [
          data.paymentMethod.find(
            (payment: { type: string }) => payment.type === 'sale'
          ),
          data.paymentMethod.find(
            (payment: { type: string }) => payment.type === 'location'
          )
        ],
        address: data.person.addresses[0],
        contact: data.person.contacts,
        email: data.emails,
        freight: data.freightOptions
      }
      setInitialValues(initialValues)
      if (initialValues?.contact) {
        initialValues?.contact.forEach(
          (contact: { type_send_email: any }, index: number) => {
            setSelectedTypes(oldData => {
              const copy = { ...oldData }
              copy[index] = contact.type_send_email
              return copy
            })
          }
        )
        setContacts(
          initialValues.contact.map((contact: any) => ({
            ...contact,
            show: !contact.deleted_at
          }))
        )
      }

      disableLoading()
      return initialValues
    },
    [activeLoading, disableLoading, getOptions]
  )

  const saveClient = useCallback(
    async (client: any, id?: string) => {
      const copyClient = { ...JSON.parse(JSON.stringify(client)) }
      if (
        copyClient?.client?.sub_segment_id === '' ||
        copyClient?.client?.sub_segment_id === ' '
      ) {
        copyClient.client.sub_segment_id = null
      }
      try {
        activeLoading()
        if (id) {
          const { data } = await api.put(
            `commercial/clients/update/${id}`,
            copyClient
          )
          if (data.error) {
            disableLoading()
            return false
          }
          disableLoading()
          return data.id
        }
        const { data } = await api.post('commercial/clients/create', copyClient)
        if (data.error) {
          disableLoading()
          return false
        }
        disableLoading()
        return data.id
      } catch (error) {
        disableLoading()
        return false
      }
    },
    [activeLoading, disableLoading]
  )

  return (
    <ClientContext.Provider
      value={{
        getClient,
        saveClient,
        getOptions,
        initialValues,
        agentsOptions,
        carriersOptions,
        originsOptions,
        segmentsOptions,
        subSegmentsOptions,
        allSegmentsOptions,
        economicGroupsOptions,
        clientsTypesOptions,
        clientQueryData,
        setSelectedTypes,
        selectedTypesEmail,
        setClientQueryData,
        contacts,
        setContacts
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClient = (): ClientContextType => {
  const context = useContext(ClientContext)
  if (!context) throw new Error('Context Client context not found')
  return context
}
