/* eslint-disable array-callback-return */
import React, { useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import '../../../../../assets/global/plugins/datatables/datatables.min.css'
import { LinkContainer } from './style'
import { apiDelete, apiList } from '../../domain/api'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { Alert } from '../../../../../components/Alert'
interface Action {
  name: string
  icon: string
  title: string
}

interface Header {
  name: string
  field: string
  sortable: boolean
}

interface SearchParameters {
  [key: string]: string
}
interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  products: any[]
  source: string
  headers?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  onlyParent?: boolean
  parentId?: string
  entityId?: string
  searchParameters?: SearchParameters[]
  format: {
    orderBy: string
  }
}

const DataTable = ({
  onActions,
  products,
  source,
  notHasChildren,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  actions
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>(products)
  const [filterItems, setFilterItems] = useState(products)
  const [totalItems] = useState(items.length)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(200)
  const [alertRemoveParent, setAlertRemoveParent] = useState(false)
  const [currentItem, setCurrentItem] = useState('')
  const [minItems, setMinItems] = useState(1)
  const [maxItems, setMaxItems] = useState(50)
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const history = useHistory()
  const { addToast } = useToast()

  const handlerOnClickButtonList = (currentValue: any) => {
    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      history.push(`/${source}/view/${currentValue.id}`, {
        id: currentValue.id,
        value: currentValue.name
      })
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    if (onActions?.onClickButtonEdit) {
      onActions.onClickButtonEdit(currentValue)
    } else {
      history.push(`/${source}/update/${currentValue.id}`, {
        id: currentValue.id,
        value: currentValue.name
      })
    }
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      const response = await api.get(apiList())
      setItems(response.data)
      setFilterItems(response.data)
      addToast({
        type: 'success',
        title: 'Chave de cache removido com sucesso.'
      })
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Chave de cache não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handleOnClickRemoveParent = (item: string) => {
    setCurrentItem(item)
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: string) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      const response = await api.get(apiList())
      setItems(response.data)
      setFilterItems(response.data)
      addToast({
        type: 'success',
        title: 'Chave de cache removido com sucesso.'
      })
    } catch (err) {
      setAlertRemoveParent(false)
      addToast({
        type: 'error',
        title: 'Chave de cache não removido, pois ainda está sendo usada.'
      })
    }
  }

  // const firstItem =
  //   totalItems === 0 ? totalItems : ItemsPerPage * (currentPage - 1) + 1

  // const getTotalItems = (initialValue: number): number => {
  //   let sum = 0
  //   if (initialValue > 1) {
  //     return items.length + initialValue - 1
  //   } else {
  //     if (notHasChildren) {
  //       sum = items.reduce((sum, value) => {
  //         if (!value.parent_id) {
  //           return sum + 1
  //         }
  //         return sum
  //       }, 0)
  //     } else {
  //       sum = items.length
  //     }
  //   }
  //   return sum
  // }

  const onSearchItem = (value: string) => {
    if (value.length === 0) {
      setItems(items)
      return
    }
    if (items && items.length > 0) {
      const itemsFilter = items.filter((key: string) =>
        key.toLowerCase().includes(value.toLowerCase())
      )
      console.log(itemsFilter)
      setFilterItems(itemsFilter)
      return
    }
    setFilterItems(filterItems)
  }
  const changeData = useCallback(
    page => {
      if (page < currentPage) {
        setMaxItems(ItemsPerPage * page)
        setMinItems(ItemsPerPage * page - (ItemsPerPage - 1))
        setCurrentPage(page)
        return
      }

      setMinItems(page * ItemsPerPage - ItemsPerPage + 1)
      setMaxItems(
        ItemsPerPage * page > totalItems ? totalItems : page * ItemsPerPage
      )
      setCurrentPage(page)
    },
    [ItemsPerPage, currentPage, totalItems]
  )

  return (
    <div className="dataTables_wrapper no-footer">
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_length">
            <label>
              <select
                onChange={e => {
                  setItemsPerPage(Number(e.target.value))
                  setMaxItems(Number(e.target.value))
                }}
                className="form-control input-sm input-xsmall input-inline"
                defaultValue="200"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              resultados por página
            </label>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_filter">
            <label>
              Pesquisar
              <Search onSearch={value => onSearchItem(value)} />
            </label>
          </div>
        </div>
      </div>
      <div className="table-scrollable">
        <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
          <TableHeader
            headers={headers}
            onSorting={() => {
              const itemSorted = items.sort(() => -1)
              setFilterItems([...itemSorted])
            }}
          />
          <tbody>
            {(filterItems.length > 0 &&
              filterItems.map((item, index) => {
                if (index >= minItems && index <= maxItems) {
                  return !notHasChildren ? (
                    <tr key={item}>
                      {headers.map(
                        header =>
                          (header.field !== 'actions' && (
                            <td key={`${header.field}-${item}`}>
                              <p
                                style={{
                                  textAlign: 'left'
                                }}
                              >
                                {typeof item[header.field] === 'boolean'
                                  ? item[header.field]
                                    ? 'Sim'
                                    : 'Não'
                                  : item}
                              </p>
                            </td>
                          )) || (
                            <td key={`actions-${item}`} className="actions">
                              {(actions &&
                                actions.map(action => (
                                  <Link
                                    key={Math.random()}
                                    title="Visualizar"
                                    to={`/${source}/${action.name}/${item}`}
                                  >
                                    <span className={action.icon} />
                                  </Link>
                                ))) || (
                                <LinkContainer>
                                  {!2 && (
                                    <div
                                      className="link"
                                      key={Math.random()}
                                      title="Visualizar"
                                      onClick={() =>
                                        handlerOnClickButtonList(item)
                                      }
                                    >
                                      <span className="fa fa-search" />
                                    </div>
                                  )}
                                  <div>
                                    <div
                                      className="link"
                                      key={Math.random()}
                                      title="Editar"
                                      onClick={() => {
                                        handlerOnClickButtonEdit(item)
                                      }}
                                    >
                                      <span className="fa fa-edit" />
                                    </div>
                                    <div
                                      key={Math.random()}
                                      title="Remover"
                                      className="link"
                                      onClick={() => {
                                        handleOnClickRemoveParent(item)
                                      }}
                                    >
                                      <span className="fa fa-remove" />
                                    </div>
                                  </div>
                                </LinkContainer>
                              )}
                            </td>
                          )
                      )}
                    </tr>
                  ) : (
                    true && (
                      <tr key={item}>
                        {headers.map(
                          header =>
                            (header.field !== 'actions' && (
                              <td key={`${header.field}-${item}`}>
                                <p
                                  style={{
                                    textAlign: 'left'
                                  }}
                                >
                                  {typeof item[header.field] === 'boolean'
                                    ? item[header.field]
                                      ? 'Sim'
                                      : 'Nâo'
                                    : item}
                                </p>
                              </td>
                            )) || (
                              <td key={`actions-${item}`} className="actions">
                                {(actions &&
                                  actions.map(action => (
                                    <Link
                                      key={Math.random()}
                                      title="Remover"
                                      to={`/${source}/${action.name}/${1}`}
                                    >
                                      <span className={action.icon} />
                                    </Link>
                                  ))) || (
                                  <LinkContainer>
                                    {true && (
                                      <div
                                        className="link"
                                        key={Math.random()}
                                        title="Remover"
                                        onClick={() => {
                                          handleOnClickRemoveParent(item)
                                        }}
                                      >
                                        <span className="fa fa-remove" />
                                      </div>
                                    )}
                                  </LinkContainer>
                                )}
                              </td>
                            )
                        )}
                      </tr>
                    )
                  )
                }
              })) || (
              <tr>
                <td colSpan={headers.length}>Nenhum registro encontrado</td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-5">
          <div className="dataTables_info">
            Mostrando de {minItems} até {maxItems} de {totalItems} registros
          </div>
        </div>
        <div className="col-md-7 col-sm-7">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => changeData(page)}
            />
          </div>
        </div>
      </div>
      <Alert
        message={`Tem certeza que deseja excluir a chave ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir a chave ${currentItem} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(currentItem)
        }
        isActive={alertRemoveParent}
      />
    </div>
  )
}

export default DataTable
