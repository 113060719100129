import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList,
  toolsViewTranfer,
  toolsViewUpdatePayments
} from '../domain/tools'
import CustomDataTable from '../../../../components/CustomDataTable'
import { headersView } from '../domain/headers'
import moment from 'moment'
import { FaSync } from 'react-icons/fa'
import transferIcon from '../../../../assets/image/svg/transfer.svg'

interface PaymentRemittanceFileData {
  id: number
  company_id: number
  bankAccount: {
    nome: string
    agencia?: string
    conta?: string
  }
  bank_account_id: number
  quantity_tranfers: number
  quantity_titles: number
  quantity_taxes: number
  value_transfers: string
  value_titles: string
  value_taxes: string
  total_value: string
  processed: boolean
  user_id: number
  user_name: string
  created_at: string
  updated_at: string
  transactions: any[]
}

export interface IResponseTransfer {
  errors: { id: number }[]
}

function getErrorJsonFinancialTransaction({ data }: { data: any }) {
  if (!data) return ''
  data = JSON.parse(data)
  let errors = ''
  if (Array.isArray(data)) {
    errors = data?.[0]?.erros
      ?.map((error: any) => error.descricaoErro)
      .join(',')
  }
  if (!Array.isArray(data)) {
    errors = data?.erros?.map((error: any) => error.descricaoErro).join(',')
  }
  return errors
}

const PaymentRemittanceFileView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [paymentRemittanceFile, setPaymentRemittanceFile] =
    useState<PaymentRemittanceFileData | null>(null)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)
  const [alertTransfer, setAlertTransfer] = useState(false)
  const [alertUpdatePayments, setAlertUpdatePayments] = useState(false)

  const loadPaymentRemittanceFile = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<PaymentRemittanceFileData>(apiList(id))
      const { data } = response
      setPaymentRemittanceFile(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o arquivo remessa',
        description:
          'Houve um error ao carregar o arquivo remessa, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading, id])

  const handlerOnClickButtonConfirmTransfer = async (id: number) => {
    activeLoading()
    try {
      const { data } = await api.post<IResponseTransfer>(
        `financial/paymentRemittanceFiles/transfer/${id}`
      )
      disableLoading()
      let type: 'success' | 'info' | 'error' = 'success'
      let title = 'Transmissão realizada com sucesso.'
      let message = ''
      if (data.errors?.length) {
        type = 'info'
        title =
          'Transmissão realizada parcialmente. Por favor, verificar a visualização do arquivo.'
        const financialIds = data.errors.map(erro => erro.id).join(', ')
        message = `Movimentações com erro: ${financialIds} `
      }
      addToast({
        time: 10,
        type,
        title,
        description: message
      })
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao transmitir arquivo.'
      })
    }
    setAlertTransfer(false)
  }

  const handlerOnClickButtonConfirmUpdatePayments = async () => {
    activeLoading()
    try {
      await api.post<IResponseTransfer>(
        'financial/paymentRemittanceFiles/transferUpdatePayments'
      )
      await loadPaymentRemittanceFile()
      disableLoading()
      addToast({
        type: 'success',
        title: 'Pagamentos atualizados.'
      })
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao atualizar pagamentos.'
      })
    }
    setAlertUpdatePayments(false)
  }

  useEffect(() => {
    loadPaymentRemittanceFile()
  }, [loadPaymentRemittanceFile])

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }
  const handleOnClickTransfer = () => {
    setAlertTransfer(true)
  }
  const handleOnClickUpdatePayments = () => {
    setAlertUpdatePayments(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  const handlerOnClickButtonCancelTransfer = () => {
    setAlertTransfer(false)
  }
  const handlerOnClickButtonCancelUpdatePayments = () => {
    setAlertUpdatePayments(false)
  }

  const tools =
    paymentRemittanceFile?.bank_account_id === 13
      ? [
          toolsViewTranfer(() => {
            handleOnClickTransfer()
          }, <img src={transferIcon} width={13} style={{ marginRight: '3px' }} />),
          toolsViewUpdatePayments(() => {
            handleOnClickUpdatePayments()
          }, <FaSync style={{ marginRight: '3px' }} />),
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]
      : [
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={tools}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{paymentRemittanceFile?.id}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Empresa
                </label>
                <p>
                  {paymentRemittanceFile?.company_id === 1
                    ? 'COMERCIO'
                    : 'INDUSTRIA'}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Conta
                </label>
                <p>{`${paymentRemittanceFile?.bankAccount?.nome} - AG${paymentRemittanceFile?.bankAccount?.agencia} - C${paymentRemittanceFile?.bankAccount?.conta}`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Transferencias
                </label>
                <p>{`${paymentRemittanceFile?.value_transfers || '0,00'} (${
                  paymentRemittanceFile?.quantity_tranfers || '0'
                })`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Títulos
                </label>
                <p>{`${paymentRemittanceFile?.value_titles || '0,00'} (${
                  paymentRemittanceFile?.quantity_titles || '0'
                })`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Convênios/Tributos
                </label>
                <p>{`${paymentRemittanceFile?.value_taxes || '0,00'} (${
                  paymentRemittanceFile?.quantity_taxes || '0'
                })`}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Valor total
                </label>
                <p>R$ {paymentRemittanceFile?.total_value}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Período
                </label>
                <p>{paymentRemittanceFile?.created_at?.substring(0, 10)}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">Pagamentos</div>
          </div>
          <div className="portlet-body form">
            <CustomDataTable
              customItems={paymentRemittanceFile?.transactions || []}
              headers={headersView}
              customHeaders={[
                {
                  name: 'Vencimento',
                  field: 'due_date',
                  sortable: true,
                  element: item => (
                    <span>
                      {moment(item.due_date, 'DD/MM/YYYY HH:mm:ss')
                        .add({ hours: 3 })
                        .format('DD/MM/YYYY')}
                    </span>
                  )
                },
                {
                  name: 'Status',
                  field: 'transmission_status',
                  sortable: false,
                  element: item => (
                    <span>{item.transmission_status || 'NAO TRANSMITIDA'}</span>
                  )
                },
                {
                  name: 'Status',
                  field: 'transmission_erro',
                  sortable: false,
                  element: item => (
                    <span>
                      {item.transmission_erro ||
                        getErrorJsonFinancialTransaction({
                          data: item.transmission_notification_response
                        })}
                    </span>
                  )
                }
              ]}
            />
          </div>
        </div>
        <div className="portlet light">
          <div className="portlet-title">
            <div className="caption">Sistema</div>
          </div>
          <div className="portlet-body form">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="type" className="control-label">
                    Cadastrado por
                  </label>
                  <p>{paymentRemittanceFile?.user_name}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="type" className="control-label">
                    Cadastrado em
                  </label>
                  <p>{paymentRemittanceFile?.created_at}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${paymentRemittanceFile?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(
            Number(paymentRemittanceFile?.id)
          )
        }
        isActive={alertRemoveParent}
      />
      <Alert
        message={'Tem certeza que deseja enviar esse pagamento?'}
        onClickCancellButton={handlerOnClickButtonCancelTransfer}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmTransfer(Number(paymentRemittanceFile?.id))
        }
        isActive={alertTransfer}
      />
      <Alert
        message={'Tem certeza que deseja atualizar os pagamentos?'}
        onClickCancellButton={handlerOnClickButtonCancelUpdatePayments}
        onClickConfirmButton={() => handlerOnClickButtonConfirmUpdatePayments()}
        isActive={alertUpdatePayments}
      />
    </>
  )
}

export default PaymentRemittanceFileView
