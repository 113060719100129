import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 30px;
  label {
    font-weight: bold;
    color: #a0a0a0;
  }

  .text-area {
    margin-top: 20px;
  }

  hr {
    margin: 40px 0;
  }
`
