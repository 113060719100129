import styled from 'styled-components'
import { Input } from '../../../../../../../components/FormProvider'

export const Container = styled.div`
  .disable {
    display: none;
  }
  .rw-widget-picker {
    border: 1px solid #c2cad8;
  }

  .rw-state-focus {
    border: 1px solid #93a1bb;
    box-shadow: none !important;
  }
  .rw-state-focus .rw-widget-picker.rw-widget-input {
    border: none !important;
    box-shadow: none !important;
  }
  .rw-widget-input {
    box-shadow: none !important;
  }
  .max-height-34px .rw-widget-picker {
    height: 34px;
    min-height: 34px;
  }
  margin-top: 20px !important;

  .container-sub-segment-error {
    border: 1px solid red;
  }
`

export const RadioInput = styled(Input)`
  display: inline-block;
  margin-right: 5px !important;
  margin-bottom: 20px !important;
  &:nth-of-type(2) {
    margin-left: 15px !important;
  }
`
