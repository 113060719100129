import React from 'react'
import { Input } from '../../../../../../components/Form'
import { TypeComposition } from '../../../domain/models/typeProduct'
import { SearchContainer } from './styles'

type ProductListType = {
  id: number
  name: string
}
type SearchComponentParams = {
  index: number
  composition: TypeComposition[]
  setComposition: React.Dispatch<React.SetStateAction<TypeComposition[]>>
  productList: ProductListType[]
  selected: boolean
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
}

export function SearchComponent({
  index,
  productList,
  composition,
  setComposition,
  selected,
  setSelected
}: SearchComponentParams) {
  return (
    <SearchContainer>
      <div>
        <Input
          className="form-control"
          name={`composition.${index}.name`}
          id="option"
          onChange={({ target }) => {
            setComposition(prev => {
              const copy = [...prev]
              copy[index].name = target.value
              return copy
            })
            setSelected(false)
          }}
          value={composition[index].name}
          controlled
        />
        <ul>
          {composition[index].name !== '' &&
            !selected &&
            productList
              .filter(f =>
                f.name?.toLowerCase().includes(composition[index].name)
              )
              .map(item => (
                <li
                  key={item.id}
                  onClick={() => {
                    setComposition(prev => {
                      const copy = [...prev]
                      copy[index].name = item.name
                      copy[index].id = item.id
                      return copy
                    })
                    setSelected(true)
                  }}
                >
                  {item.name}
                </li>
              ))}
        </ul>
      </div>
    </SearchContainer>
  )
}
