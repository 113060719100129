import React, { useCallback, useEffect, useState } from 'react'
import { Container } from './styles'
import api from '../../../../../services/api'
import Button from '../../../../../components/Button'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import { Input } from '../../../../../components/Form'
import { genericMaskWithFourZeroWithPoint } from '../../../../../utlis/mask'
import currency from 'currency.js'

const BRL_FOUR_DIGITS = (value: any) =>
  currency(value, {
    precision: 4,
    symbol: '',
    decimal: ',',
    separator: '.'
  })

interface StylesChildProps {
  th?: {
    classname: string
  }
  column4?: {
    classname: string
  }
}

interface ActionTab {
  id: number
  name: string
  active: boolean
  rowsData: {
    name: string
    percentage: {
      [key: string]: {
        id: number
        value: string
        field:
          | 'commission_percentage_coordinator'
          | 'commission_percentage_seller'
        type: string
      }
    }
  }[]
}

interface TablePortletWithInputsProps {
  realData?: any[]
  rowsData?: any[]
  maintitle?: string
  subtitle?: string
  actions?: string[]
  tableHead?: string[]
  isHighlight?: boolean
  isHighlightOver?: boolean
  hasDropdown?: boolean
  className?: string
  lastChildStyle?: string
  stylesChild?: StylesChildProps
}

const TablePortletWithInputs: React.FC<TablePortletWithInputsProps> = ({
  subtitle,
  className,
  stylesChild
}) => {
  const [selectedItem, setSelectedItem] = useState({
    index: 0,
    name: 'VENDA'
  })

  const [tableHead, setTableHead] = useState<string[]>([])
  const [actionTabs, setActionTabs] = useState<ActionTab[]>([])
  const [actionTabsInitial, setActionTabsInitial] = useState<ActionTab[]>([])

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const handleSwitchPeriod = useCallback((index: number, name: string) => {
    setSelectedItem({
      index,
      name
    })
  }, [])

  const loadCategories = useCallback(async () => {
    const rangesResponse = await api.get<GoalsRangesCommissionsResponse>(
      'commercial/goalsRangesCommissions'
    )
    const costCenters = [
      { id: 2, name: 'VENDA', type: 'sale' },
      { id: 1, name: 'LOCAÇÃO', type: 'location' }
    ]

    const actionsTab = costCenters.map(action => {
      const final: ActionTab['rowsData'] = [
        {
          name: 'coordenador',
          percentage: {}
        },
        {
          name: 'vendedor',
          percentage: {}
        }
      ]
      const ranges = rangesResponse.data.goalsRangesCommissions
      const rowsData = ranges.filter(a => a.type === action.type)
      if (action.type === 'sale') {
        const percentages = rowsData.map(jj =>
          String(Number(jj.range_percentage))
        )
        setTableHead(percentages)
      }

      rowsData.forEach(b => {
        console.log(String(Number(b.range_percentage)))
        final[0].percentage[String(Number(b.range_percentage))] = {
          id: b.id,
          value: genericMaskWithFourZeroWithPoint(
            b.commission_percentage_coordinator
          ),
          field: 'commission_percentage_coordinator',
          type: b.type
        }
        final[1].percentage[String(Number(b.range_percentage))] = {
          id: b.id,
          value: genericMaskWithFourZeroWithPoint(
            b.commission_percentage_seller
          ),
          field: 'commission_percentage_seller',
          type: b.type
        }
      })

      return {
        id: action.id,
        name: action.name,
        active: false,
        rowsData: final
      }
    })
    console.log(actionsTab)
    if (actionsTab.length > 0) actionsTab[0].active = true
    setActionTabs(actionsTab)
    const copyActionsTab = JSON.parse(JSON.stringify(actionsTab))
    setActionTabsInitial(copyActionsTab)
    disableLoading()
  }, [disableLoading])

  useEffect(() => {
    activeLoading()
    loadCategories()
  }, [activeLoading, loadCategories])

  const activeActionTab = (name: string, index: number) => {
    handleSwitchPeriod(index, name)
    setActionTabs(prev => {
      const newTabs = prev.map(newTab => ({
        ...newTab,
        active: newTab.name === name
      }))
      return newTabs
    })
  }

  function updateValue({
    indexTab,
    indexRow,
    percentage,
    value
  }: {
    indexTab: number
    indexRow: number
    percentage: string
    value: string
  }) {
    const copy = [...actionTabs]
    copy[indexTab].rowsData[indexRow].percentage[percentage] = {
      ...copy[indexTab].rowsData[indexRow].percentage[percentage],
      value: genericMaskWithFourZeroWithPoint(value)
    }
    setActionTabs(copy)
  }

  async function handleSaveButton() {
    activeLoading()
    console.log(actionTabs)
    console.log(actionTabsInitial)
    const goalsRangesCommissionsToUpdate: GoalsRangesCommissionsRequest['goalsRangesCommissions'] =
      []

    actionTabs.forEach((tab, indexTab) => {
      tab.rowsData.forEach((row, indexRow) => {
        Object.entries(row.percentage).forEach(([percentage, values]) => {
          const isUpdated =
            actionTabsInitial[indexTab].rowsData[indexRow].percentage[
              percentage
            ].value !== values.value

          if (isUpdated) {
            const goalRangeCommission = goalsRangesCommissionsToUpdate.find(
              goalRangeCommissionToUpdate =>
                goalRangeCommissionToUpdate.id === values.id
            )
            if (!goalRangeCommission) {
              goalsRangesCommissionsToUpdate.push({
                id: values.id,
                [values.field]: BRL_FOUR_DIGITS(values.value).value
              })
            } else {
              goalRangeCommission[values.field] = BRL_FOUR_DIGITS(
                values.value
              ).value
            }
          }
        })
      })
    })

    try {
      await api.put('commercial/goalsRangesCommissions/updateMany', {
        goalsRangesCommissions: goalsRangesCommissionsToUpdate
      })
      addToast({
        type: 'success',
        title: 'Percentual de Comissões salvas com sucesso!'
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao salvar Percentual de comissões.',
        description: 'Por favor, tente novamente.'
      })
    }
    disableLoading()
    console.log('update', goalsRangesCommissionsToUpdate)
  }

  return (
    <>
      <Container className={className}>
        <div className="portlet light ">
          <div className="portlet-body">
            {actionTabs.length > 0 && (
              <div className="row">
                <table className="">
                  <thead>
                    <tr className="TableHeaderActions">
                      {actionTabs.map(({ name, active }, index) => (
                        <th
                          key={name}
                          onClick={() => activeActionTab(name, index)}
                          className="TableHeadActions"
                        >
                          <div
                            className={`btn btn-transparent btn-no-border btn-outline cost-center-btn btn-sm ${
                              active && 'active'
                            }`}
                          >
                            {name}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                </table>
              </div>
            )}
            <div
              className="portlet light custom"
              style={{ marginTop: '50px', marginBottom: '0px' }}
            >
              <div className="portlet-title" style={{ marginBottom: '0px' }}>
                <div className="caption caption-md">
                  <i className="icon-bar-chart font-dark hide"></i>
                  <span className="subtitle">{subtitle}</span>
                </div>
              </div>
            </div>
            {actionTabs.map(
              ({ name, active, rowsData }, indexTab) =>
                active && (
                  <div key={name}>
                    <table className="table table-light">
                      <thead className="tableHead">
                        <tr className="table-head-tr">
                          <th className={'firstTableHead'}>
                            <b>{selectedItem.name}</b>
                          </th>

                          {tableHead.map(item => (
                            <th
                              key={`${item}${className}`}
                              className={stylesChild.th?.classname}
                              style={{ textAlign: 'center' }}
                            >
                              <b>{item}%</b>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="tableBody">
                        {rowsData.length > 0 &&
                          rowsData.map((item, indexRow) => (
                            <React.Fragment key={item.name}>
                              <tr key={item.name} className={'title'}>
                                <td>
                                  <div className="LastChildSpace">
                                    {item.name}
                                  </div>
                                </td>
                                {tableHead.map(percentage => (
                                  <td key={percentage}>
                                    <Input
                                      name="mar"
                                      className="form-control input-percentage"
                                      value={item.percentage[percentage]?.value}
                                      onChange={e => {
                                        updateValue({
                                          indexTab,
                                          indexRow,
                                          percentage,
                                          value: e.target.value
                                        })
                                      }}
                                    />
                                  </td>
                                ))}
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="ContainerButton">
          <Button
            onClick={handleSaveButton}
            type="submit"
            className="btn dark btn-sm sbold uppercase"
          >
            Salvar
          </Button>
        </div>
      </Container>
    </>
  )
}

export { TablePortletWithInputs }
