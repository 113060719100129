import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Font', sans-serif;
  span.maintitle {
    color: #697882;
    font-weight: 400;
  }
  div.WrapperItem {
    height: 64px;
  }
  .portlet.light > .portlet-title {
    margin-bottom: 0;
  }
  .container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`
