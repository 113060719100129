export const historyHeaders = [
  { name: 'Data/Hora', field: 'created_at', sortable: true, type: 'date' },
  { name: 'Informação', field: 'description', sortable: true },
  { name: 'Ação', field: 'action', sortable: true },
  {
    name: 'Situação',
    field: 'orderHistorySituation.name',
    sortable: true
  },
  { name: 'Agendamento', field: 'date_schedule', sortable: true },
  { name: 'Usuário', field: 'user_name', sortable: true }
]
export const headers = [
  {
    name: 'Pedido',
    field: 'id',
    sortable: true
  },
  {
    name: 'Cliente',
    field: 'clientName',
    sortable: true
  },
  {
    name: 'Valor Total',
    field: 'total_value',
    sortable: true,
    type: 'monetary'
  },
  {
    name: 'Data de Vencimento',
    field: 'due_date',
    sortable: false
  },
  {
    name: 'Forma de Pagamento',
    field: 'payment_form',
    sortable: false
  },
  {
    name: 'Data de entrega',
    field: 'total_value',
    sortable: true,
    type: 'date'
  },
  {
    name: 'Expira Em',
    field: 'total_value',
    sortable: true,
    type: 'date'
  },
  {
    name: 'Ultima renovação',
    field: 'created_at',
    sortable: true
  }
]
