import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import Form, { Input, Select } from '../../../../components/Form'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import TableHeader from '../components/Header'
import { breadcrumbList } from '../domain/breadcrumb'
import { headersCreate } from '../domain/headers'
import { toolsCreate } from '../domain/tools'
import { FilterContaier, ListTitle, Table } from './styles'
import currency from 'currency.js'
import { genericMaskWithTwoZeroWithPoint } from '../../../../utlis/mask'
import { departments } from '../domain/info'
const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })

type Employee = {
  isChecked: boolean
  id: number
  name: string
  department?: string
  position: {
    name: string
  }
  salary?: string
  total?: string
  adicional_earning: string
  discount: string
}

type PayrollPeriod = {
  yean: string
  month: string
}

const Payrolls = (): JSX.Element => {
  const [employees, setEmployees] = useState<Employee[]>([])
  const [year, setYear] = useState(String(new Date().getFullYear()))
  const [month, setMonth] = useState('')

  const history = useHistory()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()

  const [months] = useState([
    {
      name: 'Janeiro',
      value: '01'
    },
    {
      name: 'Fevereiro',
      value: '02'
    },
    {
      name: 'Março',
      value: '03'
    },
    {
      name: 'Abril',
      value: '04'
    },
    {
      name: 'Maio',
      value: '05'
    },
    {
      name: 'Junho',
      value: '06'
    },
    {
      name: 'Julho',
      value: '07'
    },
    {
      name: 'Agosto',
      value: '08'
    },
    {
      name: 'Setembro',
      value: '09'
    },
    {
      name: 'Outubro',
      value: '10'
    },
    {
      name: 'Novembro',
      value: '11'
    },
    {
      name: 'Dezembro',
      value: '12'
    }
  ])

  const loadEmployees = async (data: PayrollPeriod) => {
    const result = await api.get('employee/listNoPayroll', {
      params: {
        ...data
      }
    })
    setEmployees(result.data)
  }

  const generateFile = async () => {
    const ids = employees.filter(e => e.isChecked)
    activeLoading()
    try {
      await api
        .post('financial/payrolls/generate', {
          employees: ids,
          year,
          month
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'text/plain'
          })
          console.log(response.headers)

          const name = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0]
          const extension = response.headers['content-disposition']
            .split('.')[1]
            .split(';')[0]

          const filename = `${name.substring(
            1,
            name.length
          )}.${extension.substring(0, extension.length - 1)}`
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        })
      disableLoading()

      addToast({
        type: 'success',
        title: 'Folha de pagamento gerada!',
        description: 'A Folha de pagamento foi gerada com sucessoo!'
      })
      disableLoading()
      history.push('/financial/payrolls')
    } catch (error: any) {
      const responseMessage: string = error?.response?.data?.message
      const userMessage = responseMessage?.includes('Internal server error')
        ? 'Houve um error ao gerar a Folha de pagamento, tente novamente mais tarde'
        : responseMessage

      addToast({
        type: 'error',
        title: 'Error ao gerar Folha de pagamento.',
        description: userMessage
      })
      disableLoading()
    }
  }

  return (
    <Container
      pageTitle={'Folha de pagamentos'}
      portletTitle={'Folha de pagamentos'}
      breadcrumb={breadcrumbList}
      tools={[toolsCreate]}
    >
      <FilterContaier>
        <Form onSubmit={loadEmployees}>
          <div
            className="row"
            style={{ alignItems: 'flex-end', display: 'flex' }}
          >
            <div className="col-md-2">
              <Input
                label="Ano"
                className="form-control"
                name="year"
                onChange={e => {
                  setEmployees([])
                  setYear(e.target.value)
                }}
                value={year}
                controlled
                maxLength={4}
                rules={{ required: true, minLength: 4, maxLength: 4 }}
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Mês"
                className="form-control"
                name="month"
                options={months.map(c => ({
                  name: c.name,
                  value: c.value
                }))}
                onChange={e => {
                  setEmployees([])
                  setMonth(e.target.value)
                }}
                blank
                defaultValue={''}
                controlled
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-2">
              <button type="submit" className="btn btn-sm sbold uppercase">
                Pesquisar
              </button>
            </div>
          </div>
        </Form>
        <hr />
      </FilterContaier>
      <ListTitle>
        <p>Funcionários</p>
        <hr style={{ paddingBottom: 10 }} />
      </ListTitle>
      <Table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="all"
                id="all"
                onChange={({ target }) => {
                  setEmployees(prev => {
                    const copy = JSON.parse(JSON.stringify(prev))
                    const checked = copy.map((c: Employee) => ({
                      ...c,
                      isChecked: target.checked
                    }))
                    return checked
                  })
                }}
              />
            </th>
            <TableHeader
              headers={headersCreate}
              onSorting={(field, order, type) => {
                const itemSorted = employees.sort((a: any, b: any) => {
                  const fields = field.split('.')
                  let currentFieldA
                  let currentFieldB
                  if (fields.length === 1) {
                    currentFieldA = a[fields[0]]
                    currentFieldB = b[fields[0]]
                  }
                  if (fields.length === 2) {
                    currentFieldA = a[fields[0]]?.[fields[1]]
                    currentFieldB = b[fields[0]]?.[fields[1]]
                  }
                  if (fields.length === 3) {
                    currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                    currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                  }
                  if (type === 'monetary') {
                    return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                      Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                      order === 'ASC'
                      ? 1
                      : -1
                  }
                  return currentFieldA > currentFieldB && order === 'ASC'
                    ? 1
                    : -1
                })
                setEmployees([...itemSorted])
              }}
            />
          </tr>
        </thead>
        <tbody>
          {employees?.map((t, index) => (
            <tr key={t.id}>
              <td>
                <input
                  type="checkbox"
                  name="all"
                  id="all"
                  checked={t.isChecked}
                  onChange={() => {
                    setEmployees(prev => {
                      const copy = JSON.parse(JSON.stringify(prev))
                      copy[index].isChecked = !copy[index].isChecked
                      return copy
                    })
                  }}
                />
              </td>
              <td>{departments.find(d => d.value === t.department)?.name}</td>
              <td>{t.position?.name}</td>
              <td>{t.name}</td>
              <td>{`R$ ${t.salary}`}</td>
              <td style={{ width: '150px' }}>
                <Input
                  style={{ width: '150px' }}
                  name="adicional_earning"
                  value={t.adicional_earning}
                  className="form-control"
                  onChange={e =>
                    setEmployees(prev => {
                      const newValue = genericMaskWithTwoZeroWithPoint(
                        e.target.value
                      )
                      const copy = [...prev]
                      copy[index].adicional_earning = newValue
                      copy[index].total = BRL(t.salary)
                        .add(newValue)
                        .subtract(t.discount)
                        .format()
                      return copy
                    })
                  }
                  controlled
                />
              </td>
              <td style={{ width: '150px' }}>
                <Input
                  style={{ width: '150px' }}
                  name="discount"
                  value={t.discount}
                  className="form-control"
                  onChange={e =>
                    setEmployees(prev => {
                      const newValue = genericMaskWithTwoZeroWithPoint(
                        e.target.value
                      )
                      console.log(t.salary, t.adicional_earning, t.discount)
                      const copy = [...prev]
                      copy[index].discount = newValue
                      copy[index].total = BRL(t.salary)
                        .add(t.adicional_earning)
                        .subtract(newValue)
                        .format()
                      return copy
                    })
                  }
                  controlled
                />
              </td>
              <td>{t.total || t.salary}</td>
              <td>
                <a
                  href={`https://multfluxosistema.com.br/Funcionarios/view/${t.id}`}
                >
                  <span className="fa fa-search" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="form-actions right">
        <Button
          type="submit"
          className="btn dark btn-sm sbold uppercase"
          onClick={generateFile}
          disabled={!employees.filter(e => e.isChecked).length}
        >
          Gerar Folha
        </Button>
      </div>
    </Container>
  )
}

export default Payrolls
