export const headers = [
  { name: 'Cod.', field: 'id', sortable: false },
  { name: 'Vencimento', field: 'due_date', sortable: false, custom: true },
  { name: 'Dias de atraso', field: 'late_days', sortable: false, custom: true },
  { name: 'Forma de pagamento', field: 'payment_form', sortable: false },
  { name: 'Valor total', field: 'value', sortable: false },
  { name: 'Situação', field: 'situation', sortable: false, custom: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]
