import React from 'react'
import Container from '../../../../components/Container'
import { nameActions, namePageTitle } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsViewList } from '../domain/tools'
import { FormWarehouseRequest } from '../components/Form'
import { ProductProvider } from '../providers/product/ProductProvider'

const Create: React.FC = () => {
  return (
    <>
      <ProductProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.create.name}
          breadcrumb={breadcrumbCreate}
          tools={[toolsViewList()]}
        >
          <div className="form body">
            <FormWarehouseRequest typeForm="create" />
          </div>
        </Container>
      </ProductProvider>
    </>
  )
}
export default Create
