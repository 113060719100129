import React, { useCallback, useState } from 'react'
import { Container } from './styles'
import currency from 'currency.js'
import { IconBaseProps } from 'react-icons'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

interface UsersProps {
  name: string
  agent_id: number
  teammates?: any[]
  avatar_url: string | React.ComponentType<IconBaseProps>
  goalAccomplished: number
  cases: number
  closed: number
  discount: number
  rate: number
}
interface GetUsersProps {
  username: string
  agent_id: number
  isTeammate?: boolean
  parentName?: string
  parentAgentId?: number
}

interface BoardOfMembersProps {
  getUserData(): void
  setInitialData(date?: string, agent_id?: number): void
  handleSetDate(date: string, agent_id?: number): void
  activeMembersData: UsersProps[]
  tableHead?: any[]
}

const BoardOfMembers: React.FC<BoardOfMembersProps> = ({
  getUserData,
  setInitialData,
  activeMembersData,
  handleSetDate,
  tableHead
}) => {
  const [toggleSubItems, setToggleSubItems] = useState<any>({})
  const [selectedUser, setSelectedUser] = useState('')
  const handleGetUser = useCallback(
    async ({
      username,
      agent_id,
      isTeammate,
      parentName,
      parentAgentId
    }: GetUsersProps) => {
      if (username === selectedUser) {
        setSelectedUser('')
        setInitialData('', 0)
        if (isTeammate) {
          handleSetDate('', parentAgentId)
          setSelectedUser(parentName)
        }
      } else {
        handleSetDate('', agent_id)
        setSelectedUser(username)
        getUserData()
      }
    },
    [getUserData, handleSetDate, selectedUser, setInitialData]
  )

  const handleToggleModal = useCallback(
    (event, item) => {
      event.stopPropagation()
      if (!toggleSubItems[item]) {
        setToggleSubItems({
          ...toggleSubItems,
          [item]: {
            name: item,
            isShow: true,
            isSelected: true
          }
        })
      }
      if (toggleSubItems?.[item]?.isShow) {
        setToggleSubItems({
          ...toggleSubItems,
          [item]: {
            name: item,
            isShow: false,
            isSelected: true
          }
        })

        return
      }
      if (!toggleSubItems?.[item]?.isShow) {
        setToggleSubItems({
          ...toggleSubItems,
          [item]: {
            name: item,
            isShow: true,
            isSelected: true
          }
        })
      }
    },
    [toggleSubItems]
  )

  return (
    <Container className="col-md-6">
      <div className="portlet light ">
        <div className="portlet-title">
          <div className="caption caption-md">
            <i className="icon-bar-chart font-dark hide"></i>
            <span className="maintitle">Quadro de Meta</span>
          </div>
        </div>
        <div className="portlet-body">
          <div className="table-responsive" style={{ border: 0 }}>
            <table className="table table-light">
              <thead>
                <tr className="TableRowHead">
                  {tableHead?.length ? (
                    tableHead.map(tableHead => (
                      <th
                        colSpan={tableHead.colSpan || 0}
                        className={tableHead.classname || ''}
                        key={tableHead.name}
                      >
                        {tableHead.name}
                      </th>
                    ))
                  ) : (
                    <>
                      <th colSpan={2}> Membro </th>
                      <th> Meta </th>
                      <th> Meta Realizada </th>
                      <th className="ThBalance"> Balanço </th>
                      <th className="ThPercente"> Percentual </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {activeMembersData.length ? (
                  activeMembersData.map(user => {
                    return (
                      <React.Fragment key={user.name}>
                        <tr
                          className={
                            user.name === selectedUser
                              ? 'activeCustom'
                              : toggleSubItems?.[user.name]?.isShow
                              ? 'trChild'
                              : ''
                          }
                          onClick={() => {
                            handleGetUser({
                              username: user.name,
                              agent_id: user.agent_id
                            })
                          }}
                        >
                          <td className="fit">
                            {typeof user.avatar_url === 'string' ? (
                              <img
                                className="user-pic rounded"
                                src={user.avatar_url}
                              />
                            ) : (
                              <user.avatar_url size={30} color="#d4d4d4" />
                            )}
                          </td>
                          <td>
                            <a>{user.name}</a>
                          </td>
                          <td>{user.goalAccomplished}</td>
                          <td>{user.cases}</td>
                          <td>{user.closed}</td>
                          {user.discount && <td>{user.discount}</td>}
                          <td>
                            <span
                              className="bold"
                              style={{
                                color:
                                  currency(user.rate).dollars() >= 100
                                    ? 'rgb(224, 142, 32)'
                                    : '#a0a0a0'
                              }}
                            >
                              {user.rate}
                            </span>
                          </td>
                          <td>
                            {user?.teammates?.length &&
                              (user.name === toggleSubItems[user.name]?.name ? (
                                !toggleSubItems[user.name]?.isShow ? (
                                  <FaChevronDown
                                    size={13}
                                    onClick={event => {
                                      event.stopPropagation()
                                      if (user.name !== selectedUser) {
                                        setSelectedUser(user.name)
                                        handleGetUser({
                                          username: user.name,
                                          agent_id: user.agent_id
                                        })
                                      }

                                      handleToggleModal(event, user.name)
                                    }}
                                  />
                                ) : (
                                  <FaChevronUp
                                    size={13}
                                    onClick={event => {
                                      event.stopPropagation()
                                      handleToggleModal(event, user.name)
                                    }}
                                  />
                                )
                              ) : (
                                <FaChevronDown
                                  size={13}
                                  onClick={event => {
                                    event.stopPropagation()
                                    if (user.name !== selectedUser) {
                                      setSelectedUser(user.name)
                                      handleGetUser({
                                        username: user.name,
                                        agent_id: user.agent_id
                                      })
                                    }

                                    handleToggleModal(event, user.name)
                                  }}
                                />
                              ))}
                          </td>
                        </tr>
                        {toggleSubItems[user.name]?.isShow &&
                          user.name === toggleSubItems[user.name]?.name &&
                          user.teammates.map((teammate: any) => {
                            return (
                              <tr
                                key={teammate.id}
                                className={
                                  teammate.name === selectedUser
                                    ? 'activeCustom trChild'
                                    : 'trChild'
                                }
                                onClick={() => {
                                  handleGetUser({
                                    username: teammate.name,
                                    agent_id: teammate.agent_id,
                                    isTeammate: true,
                                    parentName: user.name,
                                    parentAgentId: user.agent_id
                                  })
                                }}
                              >
                                <td className="fit firstChild">
                                  {typeof teammate.avatar_url === 'string' ? (
                                    <img
                                      className="user-pic rounded"
                                      src={teammate.avatar_url}
                                    />
                                  ) : (
                                    <user.avatar_url
                                      size={30}
                                      color="#d4d4d4"
                                    />
                                  )}
                                </td>
                                <td>
                                  <a>{teammate.name}</a>
                                </td>
                                <td>{teammate.goalAccomplished}</td>
                                <td>{teammate.cases}</td>
                                <td>{teammate.closed}</td>
                                {teammate.discount && (
                                  <td>{teammate.discount}</td>
                                )}
                                <td>
                                  <span
                                    className="bold"
                                    style={{
                                      color:
                                        currency(teammate.rate).dollars() >= 100
                                          ? 'rgb(224, 142, 32)'
                                          : '#a0a0a0'
                                    }}
                                  >
                                    {teammate.rate}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            )
                          })}
                      </React.Fragment>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { BoardOfMembers }
