import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import '../../assets/global/plugins/datatables/datatables.min.css'
import { LinkContainer } from './style'
import { useUpdateDataTable } from '../../hooks/dataTable'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Loading } from '../Loading'

interface Action {
  name: string
  icon?: IconDefinition
  spanIcon?: string
  htmlIcon?: JSX.Element
  element?: (item: any) => JSX.Element
  title: string
  link?: string
  onClick?: (params: any) => void
  linkTo?: (params: any) => string
}

interface Header {
  name: string
  field: string
  sortable: boolean
  custom?: boolean
  element?: (item: any) => JSX.Element
  tdStyle?: React.CSSProperties
}

interface SearchParameters {
  [key: string]: string
}

interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  entity: string
  source: string
  headers?: Header[]
  customHeaders?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  rowStyled?: boolean
  onlyParent?: boolean
  onlyView?: boolean
  parentId?: string
  viewField?: { source: string; field: string }
  entityId?: string
  searchParameters?: SearchParameters[]
  format: {
    orderBy: string
  }
  orderByField?: string
  orderBySort?: string
  selectFields?: string[]
}

const DataTable = ({
  onActions,
  entity,
  source,
  notHasChildren,
  viewField,
  onlyView,
  onlyParent,
  rowStyled,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  customHeaders,
  actions,
  format,
  orderByField,
  orderBySort,
  parentId,
  entityId,
  searchParameters,
  selectFields
}: DataTableProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [items, setItems] = useState<any[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [ItemsPerPage, setItemsPerPage] = useState(200)
  const { isUpdated, handleSetExportOptions } = useUpdateDataTable()

  const keywordValue = useRef<string>('')

  const handlerOnClickButtonList = (currentValue: any) => {
    if (viewField) {
      return `/${viewField.source}/view/${currentValue[viewField.field]}?tab=${
        currentValue.id
      }`
    }

    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      return `/${source}/view/${currentValue.id}`
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    return `/${source}/update/${currentValue.id}`
  }

  const handlerOnClickButtonRemove = (currentValue: any) => {
    if (onActions?.onClickButtonRemove) {
      onActions.onClickButtonRemove(currentValue)
    }
  }

  const loadParams = useCallback(() => {
    const params = {
      entity,
      source,
      keyword: '',
      page: currentPage,
      perPage: ItemsPerPage,
      orderByField,
      orderBySort,
      searchParameters,
      onlyParent,
      orderBy: format.orderBy,
      parentId,
      entityId,
      selectFields
    }

    if (!parentId) Object.assign(params, { parentId: '' })
    if (!entityId) Object.assign(params, { entityId: '' })

    return params
  }, [
    ItemsPerPage,
    currentPage,
    entity,
    entityId,
    format.orderBy,
    orderByField,
    orderBySort,
    onlyParent,
    parentId,
    searchParameters,
    source,
    selectFields
  ])

  useEffect(() => {
    ;(async () => {
      try {
        const params = loadParams()
        setIsLoading(true)
        const response = await api.get('dataTable', {
          params: {
            ...params,
            keyword: { [params.orderBy]: keywordValue.current }
          }
        })

        setItems(response.data.items)
        setTotalItems(response.data.totalItens)
        setCurrentPage(response.data.page)
        handleSetExportOptions({
          url: response.request.responseURL,
          totalItems: response.data.totalItens
        })
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error(error)
      }
    })()
  }, [loadParams, isUpdated, handleSetExportOptions])

  const firstItem =
    totalItems === 0 ? totalItems : ItemsPerPage * (currentPage - 1) + 1

  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      if (notHasChildren) {
        sum = items.reduce((sum, value) => {
          if (!value.parent_id) {
            return sum + 1
          }
          return sum
        }, 0)
      } else {
        sum = items.length
      }
    }
    return sum
  }

  const getSearched = async (value: string) => {
    const params = loadParams()
    const response = await api.get('dataTable', {
      params: {
        ...params,
        keyword: value ? { [params.orderBy]: value } : undefined
      }
    })
    setItems(response.data.items)
    setTotalItems(response.data.totalItens)
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    keywordValue.current = value

    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => getSearched(value), 500)
  }

  return (
    <div className="dataTables_wrapper no-footer">
      <Loading isActive={isLoading} />
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_length">
            <label>
              <select
                onChange={e => setItemsPerPage(Number(e.target.value))}
                className="form-control input-sm input-xsmall input-inline"
                defaultValue="200"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              resultados por página
            </label>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="dataTables_filter">
            <label>
              Pesquisar
              <Search onSearch={value => onSearchItem(value)} />
            </label>
          </div>
        </div>
      </div>
      <div className="table-scrollable">
        <table className="dataTable table table-striped table-bordered table-hover dt-responsive dtr-inline">
          <TableHeader
            headers={headers}
            onSorting={(field, order, type) => {
              const itemSorted = items.sort((a, b) => {
                const fields = field.split('.')
                let currentFieldA
                let currentFieldB
                if (fields.length === 1) {
                  currentFieldA = a[fields[0]]
                  currentFieldB = b[fields[0]]
                }
                if (fields.length === 2) {
                  currentFieldA = a[fields[0]]?.[fields[1]]
                  currentFieldB = b[fields[0]]?.[fields[1]]
                }
                if (fields.length === 3) {
                  currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
                  currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
                }
                if (type === 'date') {
                  if (
                    moment(currentFieldA, 'DD/MM/YYYY').isValid() &&
                    moment(currentFieldB, 'DD/MM/YYYY').isValid()
                  ) {
                    const dateIsAfter =
                      order === 'ASC'
                        ? moment(currentFieldA, 'DD/MM/YYYY').isAfter(
                            moment(currentFieldB, 'DD/MM/YYYY')
                          )
                        : moment(currentFieldA, 'DD/MM/YYYY').isBefore(
                            moment(currentFieldB, 'DD/MM/YYYY')
                          )

                    return dateIsAfter ? 1 : -1
                  }
                }
                if (type === 'monetary') {
                  return Number(currentFieldA.replaceAll(/[',','.']/gi, '')) >
                    Number(currentFieldB.replaceAll(/[',','.']/gi, '')) &&
                    order === 'ASC'
                    ? 1
                    : -1
                }
                return currentFieldA > currentFieldB && order === 'ASC' ? 1 : -1
              })
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                const types = [
                  { key: 'locacao', label: 'LOCAÇÃO' },
                  { key: 'L', label: 'LOCAÇÃO' },
                  { key: 'materia-prima', label: 'MATERIA PRIMA' },
                  { key: 'revenda', label: 'REVENDA' },
                  { key: 'semi-acabado', label: 'SEMI ACABADO' },
                  { key: 'consumo', label: 'USO E CONSUMO' },
                  { key: 'CONSUMO', label: 'USO E CONSUMO' },
                  { key: 'venda', label: 'VENDA' },
                  { key: 'V', label: 'VENDA' },
                  { key: 'M', label: 'MANUTENÇÃO' }
                ]
                const typeProduct = types.find(
                  t => t.key === item.product?.type
                )
                if (typeProduct) {
                  item.product.type = typeProduct.label
                }
                const type = types.find(t => t.key === item.type)
                if (type) {
                  item.type = type.label
                }
                let style
                if (rowStyled) {
                  const limit = item.expeditionLogs?.[0]?.expiration
                  const realized = item.expeditionLogs?.[0]?.realized_at

                  if (limit) {
                    const limitDate = moment(limit, 'DD/MM/YYYY HH:mm:ss')
                      .add({ hours: 3 })
                      .startOf('D')
                    if (moment().diff(limitDate, 'days') > 0 && !realized) {
                      style = {
                        color: 'red'
                      }
                    }
                    if (realized) {
                      const realizedDate = moment(
                        realized,
                        'DD/MM/YYYY HH:mm:ss'
                      )
                        .add({ hours: 3 })
                        .startOf('D')
                      if (realizedDate.diff(limitDate, 'days') > 0) {
                        style = {
                          color: 'red'
                        }
                      }
                    }
                  }
                  if (
                    moment().diff(
                      moment(
                        item.situation_expiration,
                        'DD/MM/YYYY HH:m:ss'
                      ).startOf('day'),
                      'days'
                    ) > 0 &&
                    item?.situation !== 'delivered' &&
                    item?.situation !== 'canceled'
                  ) {
                    style = {
                      color: 'red'
                    }
                  }
                }
                return (
                  <tr key={item.id} style={style}>
                    {headers.map(header =>
                      header?.field !== 'actions' && !header.custom ? (
                        <td
                          key={`${header?.field}-${item.id}`}
                          style={header.tdStyle}
                        >
                          <p
                            style={{
                              textAlign: 'left'
                            }}
                          >
                            {typeof item[header?.field] === 'boolean' &&
                              (item[header?.field] ? 'Sim' : 'Nâo')}
                            {header?.field.split('.').length === 3 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]?.[header?.field.split('.')[2]]}
                            {header?.field.split('.').length === 2 &&
                              item[header?.field.split('.')[0]]?.[
                                header?.field.split('.')[1]
                              ]}
                            {header?.field.split('.').length === 1 &&
                              item[header?.field]}
                          </p>
                        </td>
                      ) : header.custom ? (
                        <td style={header.tdStyle}>
                          {customHeaders
                            .find(h => h.field === header.field)
                            .element(item)}
                        </td>
                      ) : (
                        <td key={`actions-${item.id}`} className="actions">
                          {(actions && (
                            <LinkContainer>
                              {actions.map(action => (
                                <Link
                                  to={
                                    action.link ||
                                    (action.linkTo && action.linkTo(item)) ||
                                    '#'
                                  }
                                  key={action.name}
                                  title={action.title}
                                  onClick={e => {
                                    if (action.onClick) {
                                      e.preventDefault()
                                      action.onClick(item)
                                    }
                                  }}
                                >
                                  {action.element && action.element(item)}
                                  {action.spanIcon && (
                                    <span className={action.spanIcon} />
                                  )}
                                  {action.icon && (
                                    <FontAwesomeIcon icon={action.icon} />
                                  )}
                                  {action.htmlIcon && action.htmlIcon}
                                </Link>
                              ))}
                            </LinkContainer>
                          )) || (
                            <LinkContainer>
                              {!item.parent_id && (
                                <Link
                                  className="link"
                                  key={Math.random()}
                                  title="Visualizar"
                                  to={() => handlerOnClickButtonList(item)}
                                >
                                  <span className="fa fa-search" />
                                </Link>
                              )}
                              <div>
                                {!onlyView && (
                                  <Link
                                    className="link"
                                    key={Math.random()}
                                    title="Editar"
                                    onClick={e => {
                                      if (onActions?.onClickButtonEdit) {
                                        e.preventDefault()
                                        onActions.onClickButtonEdit(item)
                                      }
                                    }}
                                    to={() => handlerOnClickButtonEdit(item)}
                                  >
                                    <span className="fa fa-edit" />
                                  </Link>
                                )}
                                {!notHasChildren && (
                                  <div
                                    key={Math.random()}
                                    title="Remover"
                                    className="link"
                                    onClick={() => {
                                      handlerOnClickButtonRemove(item)
                                    }}
                                  >
                                    <span className="fa fa-remove" />
                                  </div>
                                )}
                              </div>
                            </LinkContainer>
                          )}
                        </td>
                      )
                    )}
                  </tr>
                )
              })) || (
              <tr>
                <td colSpan={headers.length}>Nenhum registro encontrado</td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-5">
          <div className="dataTables_info">
            Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
            {totalItems} registros
          </div>
        </div>
        <div className="col-md-7 col-sm-7">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataTable
