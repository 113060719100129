import React, { useEffect, useState, useCallback } from 'react'
import { Input } from '../../../../../../../../../../components/Form'
import api from '../../../../../../../../../../services/api'
import { useProduct } from '../../../../../providers/product/ProductProvider'
import { SearchContainer } from './styles'

type NcmListType = {
  id: number
  code: string
  descriptions: string
}
export function SearchComponentNcm() {
  const [value, setValue] = useState('')
  const [ncmList, setNcmList] = useState<NcmListType[]>([])
  const [selected, setSelected] = useState(true)
  const [ncm, setNcm] = useState('')
  const { fiscal } = useProduct()

  useEffect(() => {
    if (fiscal.ncm) {
      setValue(fiscal.ncm)
      setNcm(fiscal.ncm)
    }
  }, [fiscal?.ncm])

  const getNcmList = useCallback(async () => {
    let code = isNaN(parseInt(value)) ? '' : value
    let descriptions = isNaN(parseInt(value)) ? value : ''
    const filter = value.replace(/[0-9]+[^\w]+/, '')
    if (isNaN(parseInt(filter))) {
      descriptions = filter
      if (value !== ncm) {
        code = ''
      }
    }

    const { data } = await api.get<NcmListType[]>('/taxes/taxNcm', {
      params: {
        searchParameters: { code, descriptions }
      }
    })
    setNcmList(data)
  }, [ncm, value])

  useEffect(() => {
    if (!value) return
    const timeOutId = setTimeout(() => getNcmList(), 500)
    return () => clearTimeout(timeOutId)
  }, [getNcmList, value])

  return (
    <SearchContainer>
      <div>
        <Input
          className="form-control"
          name="fiscal.ncm"
          id="option"
          onChange={({ target }) => {
            setValue(target.value)
            setSelected(false)
          }}
          value={value}
          controlled
          rules={{ required: true }}
        />
        <ul>
          {value !== '' &&
            !selected &&
            ncmList.map(item => (
              <li
                key={item.id}
                onClick={() => {
                  setValue(`${item.code} - ${item.descriptions}`)
                  setSelected(true)
                }}
              >
                {`${item.code} - ${item.descriptions}`}
              </li>
            ))}
        </ul>
      </div>
    </SearchContainer>
  )
}
