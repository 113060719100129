import React, { useCallback, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { FilterContaier, ListTitle } from './styles'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import Button from '../../../../components/Button'
import { Alert } from '../../../../components/Alert'
import { useLoading } from '../../../../hooks/loading'
import { cnpjMask } from '../../../../utlis/mask'

const DefaultedOrdersList = (): JSX.Element => {
  const [parameters, setParameters] = useState<any[]>()
  const [cnpj, setCnpj] = useState<string>()
  const [orderId, setOrderId] = useState<string>()
  const [type, setType] = useState<string>()
  const [paid, setPaid] = useState<number | string>()
  const [reconsidered, setReconsidered] = useState<number | string>()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const { updateDataTable } = useUpdateDataTable()
  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleRefreshData = useCallback(async () => {
    setAlertRemoveParent(false)
    activeLoading()
    try {
      await api.post('/financial/defaultedOrders/generate')
      disableLoading()
      updateDataTable()
      addToast({
        type: 'success',
        title: 'Registros atualizados',
        description: 'Registros dos clientes bloqueados atualizados'
      })
    } catch (error) {
      disableLoading()
      addToast({
        title: 'Erro ao atualizar registros',
        description: 'Erro ao atualizar os regisotrs dos clientes bloqueados',
        type: 'error'
      })
    }
  }, [activeLoading, addToast, disableLoading, updateDataTable])

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[]}
    >
      <FilterContaier>
        <div>
          <div className="col-md-2">
            <label htmlFor="order">Pedido</label>
            <input
              className="form-control"
              id="order"
              value={orderId}
              onChange={({ target }) => setOrderId(target.value)}
            ></input>
          </div>
          <div className="col-md-2">
            <label htmlFor="cnpj">CNPJ</label>
            <input
              className="form-control"
              id="cnpj"
              value={cnpj}
              onChange={({ target }) => setCnpj(cnpjMask(target.value))}
              maxLength={18}
            ></input>
          </div>
          <div className="col-md-2">
            <label htmlFor="product-group">Tipo</label>
            <select
              className="form-control"
              id="product-group"
              value={type}
              onChange={({ target }) => setType(target.value)}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              <option value="V">VENDA</option>
              <option value="L">LOCAÇÃO</option>
              <option value="M">MANUTENÇÃO</option>
            </select>
          </div>
          <div className="col-md-2">
            <label htmlFor="product-group">Pago</label>
            <select
              className="form-control"
              id="product-group"
              value={paid}
              onChange={({ target }) => setPaid(Number(target.value))}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </select>
          </div>
          <div className="col-md-2">
            <label htmlFor="product-group">Liberado</label>
            <select
              className="form-control"
              id="product-group"
              value={reconsidered}
              defaultValue={0}
              onChange={({ target }) => setReconsidered(Number(target.value))}
            >
              <option value="" style={{ display: 'none' }}>
                Selecione
              </option>
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </select>
          </div>
        </div>
        <footer>
          <button
            onClick={() => {
              setCnpj('')
              setOrderId('')
              setPaid('')
              setReconsidered('')
              setType('')
            }}
          >
            LIMPAR
          </button>
          <button
            onClick={() => {
              setParameters([
                { order_id: orderId, cnpj, paid, type, reconsidered }
              ])
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr />
      </FilterContaier>
      <ListTitle>
        <div>
          <p>Listagem</p>
          <hr style={{ paddingBottom: 10 }} />
        </div>
        <div>
          <Button type="button" onClick={() => setAlertRemoveParent(true)}>
            ATUALIZAR REGISTROS
          </Button>
        </div>
      </ListTitle>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'client_name' }}
        notHasChildren
        onlyView
        searchParameters={parameters}
        headers={headers}
      />
      <Alert
        message={
          'O sistema ira analisar novos registros essa operação pode levar alguns segundos'
        }
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() => handleRefreshData()}
        isActive={alertRemoveParent}
      />
    </Container>
  )
}

export default DefaultedOrdersList
